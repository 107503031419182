import React from 'react';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import {cl, globs} from '../../components/utils/utils';

class SaveCancel extends React.Component{


  constructor(props) {
    super(props);
    // this.state={
    // }
    this.props.getValue(props.ind, {type: "saveCancel"})
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveCancel)
  }

  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
  saveCancel=(cmd)=>{
//     cl(cmd)
    this.props.onChange(
      this.props.ind,
      {value: cmd});
  }
  
  onButtonClick=(e)=>{
    // cl(e.target.id)
    // let vo
    this.props.onChange(
      this.props.ind,
      {value: e.target.id});
      // {
      // type: "saveCancel",
      // value: e.target.id,
    // })
  }

  render(){
    return null
//     return(
//       <div>
//         <form>
//           <button type="button" id="save" onClick={this.onButtonClick}>Save</button>
//           <button type="button" id="cancel" onClick={this.onButtonClick}>Cancel</button>
//         </form>
//       </div>
//     );
  }

}

  export default SaveCancel ;
