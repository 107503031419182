import React from 'react';
import Position00 from '../../usa/components/Position00';
import Defaults00 from '../../usa/components/Defaults00';
import Parameters00 from '../../usa/components/Parameters00';
import UsaIcon from '../../usa/components/UsaIcon';
import UsaSelect00 from '../../usa/components/UsaSelect00';
import UsaColorPicker00 from '../../usa/components/UsaColorPicker00';
import {dbVals} from '../../components/utils/http'
import {cl, globs} from '../../components/utils/utils';
import {pInd,pi} from '../../components/utils/paramIds'
import {colorIsDark} from '../utils/utils';
import {wsTrans} from '../../usa/utils/utils'
import Info from '../../images/info.png'
import history from '../../history'

class EquipmentListWidget00 extends React.Component{
/* this gets the size in parms w, h. assume a 5px padding*/  
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      name: "none",
      multCol: "false"
    }
    if(props.parms){
      this.zone=props.parms.wdg.parms.zci[0]
//       cl(this.zone)
      props.notify({id: "drag", func: this.onDrag})
    }
  }
  
  componentWillUnmount=()=>{
    this.props.notify({id: "drag", func: this.onDrag, unMount: true})
  }
  
  showIt=()=>{
    cl("here!")
  }
  
/********************* These are the new Widget functions, to make them freestanding**********************/
widgetType={v: "equipList", t: "Equipment List"}

editType=(that)=>{
  let pos=Object.assign({}, that.state.position)
  let def=Object.assign({}, that.state.defaults)
  let parm=Object.assign({}, that.state.parameters)
  return(
    <div>
    <Position00 parms={{vals: pos}} onChange={(vals)=>{that.onChange("position", vals)}}/>
    <Defaults00 parms={{vals: def}} onChange={(vals)=>{that.onChange("defaults", vals)}}/>
    <Parameters00 parms={{
      vals: parm,
      wdg: this,
    }} type="equipList" onChange={(vals)=>{that.onChange("parameters", vals)}}/>
    </div>
  )
}

editParameters=(that)=>{
//   cl(that.state.title)
  return(
    <div>
    <UsaIcon icon="parameters-OK" result={that.result} inline/>
    <UsaIcon icon="parameters-Cancel" result={that.result} inline/>
    <span><h3 style={{display: "inline-block"}}>Parameters:</h3></span>
    <UsaColorPicker00 parms={{
      title: "Background\u00A0Color",
      width: 100,
      inline: true,
      value: that.state.bgColor,
      valueId: "bgColor",
      onChange: o=>that.setState(o),
    }}/>
    <UsaColorPicker00 parms={{
      title: "Light\u00A0\u00A0ColorOn",
      width: 100,
      inline: true,
      value: that.state.lightOn,
      valueId: "lightOn",
      onChange: o=>that.setState(o),
    }}/>
    <UsaColorPicker00 parms={{
      title: "Dark\u00A0On\u00A0Color",
      width: 100,
      inline: true,
      value: that.state.darkOn,
      valueId: "darkOn",
      onChange: o=>that.setState(o),
    }}/>
    <UsaColorPicker00 parms={{
      title: "Light\u00A0Off\u00A0Color",
      width: 100,
      inline: true,
      value: that.state.lightOff,
      valueId: "lightOff",
      onChange: o=>that.setState(o),
    }}/>
    <UsaColorPicker00 parms={{
      title: "Dark\u00A0Off\u00A0Color",
      width: 100,
      inline: true,
      value: that.state.darkOff,
      valueId: "darkOff",
      onChange: o=>that.setState(o),
    }}/>
    </div>
  )
}

parameterChange=(v)=>{
  cl(v)
  return{bgColor: v.bgColor, nameType: v.nameType, lightOn: v.lightOn, darkOn: v.darkOn, lightOff: v.lightOff, darkOff: v.darkOff, 
    title: "Equipment List"}
}

makeParms=(wdg)=>{
//   cl(wdg)
  return{
    parms:{
      bgColor: wdg.parameters.bgColor,
      lightOn: wdg.parameters.lightOn,
      darkOn: wdg.parameters.darkOn,
      lightOff: wdg.parameters.lightOff,
      darkOff: wdg.parameters.darkOff,
//       nameType: wdg.parameters.nameType,
      title: "Equipment List",
    },
  }
}

showWidget=(that, x, y, w, h, wdg, i)=>{
//   cl(i)
//   cl(wdg)
  let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i, e: that.state.edit, cm: that.cm, md: that.md};
  let Cont=this.constructor
  return(
    <Cont key={i} parms={parms} notify={that.notify}/>
  );
}

/********************* End of new Widget functions **********************/

/******************** Drag, Context Menu, Mouse Down Functions *********************/

onDrag=(o)=>{// o has the updated info
//     cl("od")
//     cl(o)
    if("dragStart" in o){
      this.setState(o)
    }else{
      if(this.state.dragStart.i==this.props.parms.i){
//         cl(o)
        this.setState(o)
      }
    }
  }
  
  cm=(e)=>{
    this.props.parms.cm({e: e, i: this.props.parms.i})
  }
  
  md=(e)=>{
    this.props.parms.md({e: e, i: this.props.parms.i})
  }
  
  onClick=()=>{
    if(this.props.parms.e) {return}// edit mode
    let url=this.props.parms.wdg.parms.link
    history.push(url)
  }
  
/******************** End Drag, Context Menu, Mouse Down Functions *********************/

// getAccountName=async()=>{
//       let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/accounts", method: "retrieve", 
//         sessionId: globs.userData.session.sessionId,
//         body: {accountId: globs.userData.session.accountId}})
// //       cl(res)
//       return res.data.name
//   }
//   
//   getSiteName=async()=>{
//       let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "retrieve", 
//         sessionId: globs.userData.session.sessionId,
//         body: {siteId: globs.userData.session.siteId}})
// //       cl(res)
//       return res.data[0].name
//   }
//   
//   getZoneName=async()=>{
// //     cl(this.props)
// //     cl("get zone")
//     this.zone=this.props.parms.wdg.parms.zci[0]
// //     cl(this.zone)
//       let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "retrieve", 
//         sessionId: globs.userData.session.sessionId,
//         body: {siteId: globs.userData.session.siteId, siteZoneIndex: this.zone}})
// //       cl(res)
//       for(let i=0;i<res.data.length;i++){
// //         cl([res.data[i].siteZoneIndex,this.zone])
//         if(res.data[i].siteZoneIndex==this.zone){return res.data[i].zoneName}
//       }
//       return ""
// //       return res.data[0].name
//   }
  
//   getName=async(props)=>{
// //     cl(props)
//     var name
//     switch(props.parms.wdg.parms.nameType){
//       case "account":
//         name=await this.getAccountName()
//         break
//       case "site":
//         name=await this.getSiteName()
//         break
//       case "zone":
//         name=await this.getZoneName()
//         cl(name)
//         break
//         
//     }
//     this.setState({name: name})
//     globs.events.publish("setMainBarTitle", name)
// //     if(this?.state?.name)return
//   }

  showBubble=(text)=>{
// on the figma demo, the type is 9px tall, the bubble is 21 x 37px
    let h=21
    let fontSize=0.6*h
    return(
      <div style={{height: h, borderRadius: h/2, padding: "0px 10px 0px 10px", lineHeight: `${h}px`, fontSize: fontSize, 
        backgroundColor: "#CCCCFF"}}>{text}</div>
    )
  }
  
  showEquipmentList=()=>{
//     cl(this.state)
//     cl(this.props)
//     cl(dbVals.z)
    let z=dbVals.z[this.zone]
    let equip=[]
    for(let i=0;i < 192;i++){
      let ch=z[i]
      if(ch){
        let ty=+ch[pInd[1800].channels_configuration[0]+pi[1800].channels_configuration["channelType"]]
        if(ty){
          equip.push({
            ty:ty,
            name:ch[pInd[1800].channels_configuration[0]+pi[1800].channels_configuration["channelName"]],
            pos:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["position"]],
            relay:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["relay"]],
            ovr:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["channelOverride"]],
            analog:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["analogOutput"]],
            auxOutStatus:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["auxOutputStatus"]],
            auxOutValue:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["auxOutputValue"]],
            auxEquipStatus:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["auxEquipmentStatus"]],
            auxEquipValue:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["auxEquipmentValue"]],
          })
        }
      }
    }
//     cl(equip)
    let lines=equip.map((e,i)=>{
      return (
        <tr key={i}><td>{e.name}</td><td>{this.showBubble("ON")}</td><td><img style={{paddingTop: 4}} src={Info}/></td>
        </tr>
      )
    })
    return(
      <table><tbody>
      {lines}
      </tbody></table>
    )
  }

  showMultCol = (i, equip) => {
      if ((i + Math.ceil(equip.length/2)) < equip.length)
        return (
          <>
            <td>{i + Math.ceil(equip.length/2) + 1}</td>
            <td>{equip[i + Math.ceil(equip.length/2)].name}</td>
            <td>{this.showBubble("ON")}</td>
            <td><img width="24" src="/info.png"/></td>
           </>
          )
  }

  showEquipmentList2=()=>{
    cl("show equipment list 2")
    let z=dbVals.z[this.zone]
    let equip=[]
    for(let i=0;i < 192;i++){
      let ch=z[i]
      if(ch){
        let ty=+ch[pInd[1800].channels_configuration[0]+pi[1800].channels_configuration["channelType"]]
        if(ty){
          equip.push({
            ty:ty,
            name:ch[pInd[1800].channels_configuration[0]+pi[1800].channels_configuration["channelName"]],
            pos:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["position"]],
            relay:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["relay"]],
            ovr:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["channelOverride"]],
            analog:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["analogOutput"]],
            auxOutStatus:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["auxOutputStatus"]],
            auxOutValue:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["auxOutputValue"]],
            auxEquipStatus:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["auxEquipmentStatus"]],
            auxEquipValue:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["auxEquipmentValue"]],
          })
        }
      }
    }
    cl(this.state.multCol)
    let lines = []
    if (this.state.multCol == true) {
      for (let i=0; i < Math.ceil(equip.length/2); i++) {
        lines.push(
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{equip[i].name}</td>
              <td>{this.showBubble("ON")}</td>
              <td><img width="24" src="/info.png"/></td>
              { this.showMultCol(i, equip) }
            </tr>
          );
        }
    } else {
      lines=equip.map((e,i)=>{
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{e.name}</td>
            <td>{this.showBubble("ON")}</td>
            <td><img width="24" src="/info.png"/></td>
          </tr>
        )
      })
    }
    
    return(
      <table><tbody>
      <tr>
        <td>Name</td><td>System</td>
      </tr>
      {lines}
      </tbody></table>
    )
  }
  
  render2() {
//     cl(this.props)
//     this.getName()
    let p = this.props.parms;
    let p2=p.wdg.parms
    let dm=5
    let color=p2.bgColor
    let x=p.x+dm
    let y=p.y+dm
    if(this?.state?.dragStart?.i==this.props.parms.i){
      x+=this.state.drag.x-this.state.dragStart.x
      y+=this.state.drag.y-this.state.dragStart.y
    }
    return(
        <div style={{position: "absolute", left: x, top: y, width: p.w-2*dm, height: p.h-2*dm, 
          paddingTop: 0, borderRadius: 10, backgroundColor: color, cursor: "pointer"}} onClick={this.onClick}
          onContextMenu={this.cm} onMouseDown={this.md}>
          {this.showEquipmentList()}
        </div>
      );
  }

  render() {
    cl("new equip render")
    let p = this.props.parms;
    let p2=p.wdg.parms
    let dm=5
    let color=p2.bgColor
    let x=p.x+dm
    let y=p.y+dm
    if(this?.state?.dragStart?.i==this.props.parms.i){
      x+=this.state.drag.x-this.state.dragStart.x
      y+=this.state.drag.y-this.state.dragStart.y
    }
    return(
      <div style={{position: "absolute", left: x, top: y, width: p.w-2*dm, height: p.h-2*dm, 
        paddingTop: 0, borderRadius: 10, backgroundColor: color, cursor: "pointer"}} onClick={this.onClick}
        onContextMenu={this.cm} onMouseDown={this.md}>
        {this.showEquipmentList2()}
      </div>
    );
  }
}

export default EquipmentListWidget00;
