import React from 'react';
import UsaSelect00 from './UsaSelect00';
import {loadSiteData, loadDevicesInfo} from './C18utils'
import {wsTrans, checkLoggedIn} from '../utils/utils'
import {cl, globs,constant,dateToYrMoDa,dateToLocalHrMinSecString, setRestTimeout, getTime, validAppVersions, tsToDate, dateToDisplayDate} from '../../components/utils/utils';
import {lc} from '../../components/utils/landruConstants';
import history from "../../history"
import {init, dbVals} from '../../components/utils/http'

class Status extends React.Component{
  constructor(props) {
    super(props);
//     cl(`time: ${getTime()}`)
    this.state={
      clientId: "31373730353851040023004a",
      loaded: false,
    }
//     this.makeCmdToRange()
//     this.makeLNames()
//     this.loadStatus()
    this.initAll()
//     cl(lc)
    this.setBreadCrumbs()
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"System Staus", url:`/usa/c18/admin/systemStatus`},
            ]},
        },
      )
    }
  }
  
  makeCmdToRange=()=>{
  this.cmdIds={
    MSG_CMD_NACK: -1,
    MSG_CMD_ACK: 0,
    MSG_CMD_WRITE_SPECIFIC_DEVICE_CONFIG: 1,
    MSG_CMD_READ_SPECIFIC_DEVICE_CONFIG: 2,
    MSG_CMD_REPORT_CHANGED_DEVICE_CONFIG: 3,
    MSG_CMD_READ_SPECIFIC_DEVICE_STATUS: 4,
    MSG_CMD_REPORT_CHANGED_DEVICE_STATUS: 5,
    MSG_CMD_WRITE_SPECIFIC_AUX_CONFIG: 6,
    MSG_CMD_READ_SPECIFIC_AUX_CONFIG: 7,
    MSG_CMD_REPORT_CHANGED_AUX_CONFIG: 8,
    MSG_CMD_READ_SPECIFIC_AUX_STATUS: 9,
    MSG_CMD_REPORT_CHANGED_AUX_STATUS: 10,
  }
  this.lCmdToRange=[];
    this.lCmdToRange[this.cmdIds.MSG_CMD_WRITE_SPECIFIC_DEVICE_CONFIG] = 0;
    this.lCmdToRange[this.cmdIds.MSG_CMD_READ_SPECIFIC_DEVICE_CONFIG] = 0;
    this.lCmdToRange[this.cmdIds.MSG_CMD_REPORT_CHANGED_DEVICE_CONFIG] = 0;
    this.lCmdToRange[this.cmdIds.MSG_CMD_READ_SPECIFIC_DEVICE_STATUS] = 2000;
    this.lCmdToRange[this.cmdIds.MSG_CMD_REPORT_CHANGED_DEVICE_STATUS] = 2000;
    this.lCmdToRange[this.cmdIds.MSG_CMD_WRITE_SPECIFIC_AUX_CONFIG] = 4000;
    this.lCmdToRange[this.cmdIds.MSG_CMD_READ_SPECIFIC_AUX_CONFIG] = 4000;
    this.lCmdToRange[this.cmdIds.MSG_CMD_READ_SPECIFIC_AUX_STATUS] = 6000;
    this.lCmdToRange[this.cmdIds.MSG_CMD_REPORT_CHANGED_AUX_STATUS] = 6000;
  }
  
  getIdName=(cmd, id)=>{
//     cl([cmd, id])
    let id2=this.lCmdToRange[cmd]+id
//     cl(this.lNames[id2])
    return this.lNames[id2]
  }


  makeLNames=()=>{
    let keys=Object.keys(lc);
    this.lNames={}
    for(let i = 0 ; i < keys.length ; i++){
      let k=keys[i];
      if(lc[k] < 0) return;
      this.lNames[lc[k]]=k;
    }
  }
  
  loadStatus=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/status", method: "retrieve", sessionId:                   
      globs.userData.session.sessionId, body: {}})
//     cl(res)
    return res.data
//     this.setState({loaded: true})
  }


  loadStatusO=async()=>{
    cl("load1")
    let clientNames={
      "31373730353851040023004c": "Yen",
      "31373730353851040023004a": "Gene",
      "393632353438511200300018": "Site 1",
      "393632353438511200330018": "Site 2",
      "393632353438511200360018": "Site 3",
      "39363235343851120023002e": "Site 4",
      "39363235343851120022002e": "Site 5",
    }

    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/status", method: "retrieve", sessionId:                   
      globs.userData.session.sessionId, body: {}})
    cl(res)
    let clients={}
    let contacts=res.data
    contacts.forEach(c=>{
      if(c.dir=="up")clients[c.clientId]=1
    })
    let opts={}
    let events={}
    cl("load1")
    Object.keys(clients).forEach(c=>{
      opts[c]=clientNames[c]
      events[c]=[]
    })
//     cl(opts)
    contacts.forEach(c=>{
      if(events[c.clientId]){events[c.clientId].push(c)}
    })
    cl(events)
    Object.keys(events).forEach(k=>{
//       cl(k)
      let ar=events[k]
//       cl(ar)
      ar.sort((a,b)=>{
        if(a.t<b.t){return 1}
        if(a.t>b.t){return -1}
        if(a.dir<b.dir){return 1}// comparing "up" "dn"
        if(a.dir>b.dir){return -1}
        return 0
      })
//       cl(ar)
    })
    this.setState({loaded:true, clientOpts:opts, events: events})
  }
  
  onChange=(type, vals)=>{
    switch(type){
      case "clientId":
        cl(vals)
        this.setState(vals)
        break
      default:
        cl([type, vals])
        break;
    }
  }

  makeDefaultReports = () => {
    wsTrans("usa", {cmd: "cRest", uri: "/s/reports", method: "default", 
      sessionId: globs.userData.session.sessionId, body: {
        accountId: globs.userData.session.accountId,
    }})
  }

  deleteDevices = () => {
    cl("deleting all devices")
    wsTrans("usa", {cmd: "cRest", uri: "/s/devices", method: "delete", 
      sessionId: globs.userData.session.sessionId, body: {
        accountId: globs.userData.session.accountId, userId: globs.userData.session.userId,
    }})
  }
  
  isRead=(cmd)=>{
    let reads=[
      this.cmdIds.MSG_CMD_READ_SPECIFIC_DEVICE_CONFIG,
      this.cmdIds.MSG_CMD_READ_SPECIFIC_DEVICE_STATUS,
      this.cmdIds.MSG_CMD_READ_SPECIFIC_AUX_CONFIG,
      this.cmdIds.MSG_CMD_READ_SPECIFIC_AUX_STATUS,
    ]
    return reads.includes(cmd)
  }
  
  showMessages=()=>{
    if(!this?.state?.events?.length){return}
    cl(this.state)
    if(!this.state.events[this.state.clientId]){
      this.state.clientId=Object.keys(this.state.events)[0]
      cl(this.state.clientId)
    }
    return(
      this.state.events[this.state.clientId].map((e,i)=>{
        let da=new Date(1000*e.t)
        let hms=dateToLocalHrMinSecString(da)
        let data=((e.dir=="dn")&&this.isRead(e.cmd))?"":`, d: ${e.d}`
        let pref=(data=="")?"read_":""
        if(e.dir=="up"){
          return (
            <div key={i}>
            {`${hms}: ${pref}${this.getIdName(e.cmd, e.id)}, ch: ${e.ch}${data}`}
            </div>
          )
        }else{
          return (
            <div key={i} style={{backgroundColor: "#FFFFCC"}}>
            {`${hms}: ${pref}${this.getIdName(e.cmd, e.id)}, ch: ${e.ch}${data}`}
            </div>
          )
        }
      })
    )
  }
  
  update=()=>{
    cl("update")
    this.loadStatus()
  }
  
  showOldStatus=()=>{
    return(
        <div>
          <UsaSelect00 key={0} parms={{
            title: "Connected Clients",
            select: true,
            width: 200,
            valueId: "clientId",//valueId, // "widgetSel",
            value: this.state.clientId, //value, // this.state.widgetSel,
            opts: this.state.clientOpts,
            onChange: o=>{this.onChange("clientId", o)},
          }}/>
          <h3>Recent Messages</h3>
          <div style={{width: 700, height: 200, backgroundColor: "#CCFFCC", overflowY: "auto", margin: 10, padding: 10}}>
          {this.showMessages()}
          </div>
          <div style={{margin: 10}}>
            <button onClick={this.update}>Update</button>
          </div>
        </div>
    )
  }
  
  initAll=async()=>{
//     cl(`time: ${getTime()}`)
    let logged=await checkLoggedIn()
//     cl("status")
//     cl(`time: ${getTime()}`)
    if(!logged){history.push("/usa/login"); return}
    let in0=init()
//     cl(dbVals.z)
    let ra=await Promise.all([in0])
//     cl("status")
//     cl(`time: ${getTime()}`)
//     cl(ra);
//     cl(dbVals);
//     cl(globs.userData.session)
//     cl(globs.userData)
    await loadSiteData(globs.userData.session.siteId)
//     cl("status")
    let gateways=await this.getGateways()
//     cl(`time: ${getTime()}`)
    let zones=await this.getZones()
//     cl(`time: ${getTime()}`)
    let status=await this.loadStatus()
//     cl(`time: ${getTime()}`)
    await loadDevicesInfo()
//     cl("status")
//     cl(globs.devicesInfo)
    // let gdi=globs.devicesInfo.info
    let devices = globs.devicesInfo.info.filter((d) => d.userId == globs.userData.session.userId)
    // this.setState({loaded: true, gateways: gateways, zones: zones, status: status, devices: gdi})
    this.setState({loaded: true, gateways: gateways, zones: zones, status: status, devices: devices})
  }
  
  getGateways=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "retrieve2", sessionId:                   
      globs.userData.session.sessionId, body: {siteId: globs.userData.session.siteId}})
    return res.data
  }
  
  getZones=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "retrieve", sessionId:                   
      globs.userData.session.sessionId, body: {siteId: globs.userData.session.siteId}})
    return res.data
  }
  
  showGateways=()=>{
//     cl(this.state)
    return(
      this.state.gateways.map((g,i)=>{
        let conn=(g.connected)?"yes":"no"
        let now=Math.floor(getTime())
        let upTime=now-g.updateTime;
        let lastRead=now-this.state.status.gatewayReadTimes[g.gatewayId]
        let lastWrite=now-this.state.status.gatewayWriteTimes[g.gatewayId]
        let retArr=[]
        retArr.push(<tr key={retArr.length}><td>GatewayId</td><td>{g.gatewayId}</td></tr>)
        retArr.push(<tr key={retArr.length}><td>Name</td><td>{g.name}</td></tr>)
        retArr.push(<tr key={retArr.length}><td>Connected</td><td>{conn}</td></tr>)
        retArr.push(<tr key={retArr.length}><td>ClientId</td><td>{g.clientId}</td></tr>)
        retArr.push(<tr key={retArr.length}><td>My Queue</td><td>{g.queue}</td></tr>)
        retArr.push(<tr key={retArr.length}><td>UpdateTime</td><td>{upTime} secs ago</td></tr>)
        retArr.push(<tr key={retArr.length}><td>Last Read</td><td>{lastRead} secs ago</td></tr>)
        retArr.push(<tr key={retArr.length}><td>Last Write</td><td>{lastWrite} secs ago</td></tr>)
        retArr.push(<tr key={retArr.length}><td height="10"></td><td></td></tr>)
        return retArr
      })
    )
  }
  
  showZones=()=>{
    let mins=Math.floor(getTime()/60)%1440
    return(
      this.state.zones.map((g,i)=>{
        var zoneUpdDiff
//         cl(g)
//         cl(dbVals)
        if(!dbVals.z[g.siteZoneIndex]){return null}
//         if(dbVals.z[g.siteZoneIndex][192]){
//           zoneUpdDiff=(1440+mins-dbVals.z[g.siteZoneIndex][192][4574])%1440
//         }
//         cl(zoneUpdDiff)
        let conn=(g.connected)?"yes":"no"
        let upTime=Math.floor(getTime())-g.updateTime;
        return(
          [
          <tr key={7*i+1}><td>ZoneId</td><td>{g.zoneId}</td></tr>,
          <tr key={7*i+5}><td>Name</td><td>{g.zoneName}</td></tr>,
          <tr key={7*i+2}><td>Connected</td><td>{conn}</td></tr>,
          <tr key={7*i+3}><td>Gateway Zone Index</td><td>{g.gatewayZoneIndex}</td></tr>,
          <tr key={7*i+4}><td>Site Zone Index</td><td>{g.siteZoneIndex}</td></tr>,
          <tr key={7*i+6}><td>UpdateTime</td><td>{upTime} secs ago</td></tr>,
          <tr key={7*i+7}><td>Cloud to LC Update</td><td>{zoneUpdDiff} mins ago</td></tr>,
          <tr key={7*i+8}><td height="10"></td><td></td></tr>
          ]
        )
      })
    )
  }
  
  showWebSocket=()=>{
//     cl(this.state.status.wsGatewayQueues)
    let retArr=
      [
      <tr key={1}><td>Socket ID</td><td>{dbVals.wsInfo.id}</td></tr>,
      <tr key={2}><td>My Queue</td><td>{dbVals.wsInfo.queue}</td></tr>,
      ]
      Object.keys(this.state.status.wsGatewayQueues).forEach((k,i)=>{
        let q=this.state.status.wsGatewayQueues[k]
        retArr.push(
          <tr key={4+i}><td>{`GW: ${k}`}</td><td>{q.queue}</td></tr>
        )
      })
      retArr.push(<tr key={3}><td height="10"></td><td></td></tr>)
    return retArr
  }
  
  showControllers=()=>{
//     cl(this.state.status.contSocketClients)
    let retArr=[]
//     cl(this.state)
    Object.keys(this.state.status.contSocketClients).forEach(k=>{
      retArr.push(
        <tr key={retArr.length}><td>Gateway</td><td>{k}</td></tr>
      )
      let clients=this.state.status.contSocketClients[k]
      clients.forEach(c=>{
        retArr.push(
          <tr key={retArr.length}><td>{`socket ${c.socketId}`}</td><td>{`queue ${c.queue}`}</td></tr>
        )
      })
    })
    return retArr
  }

  testMessage= async (deviceId, deviceName)=>{
    cl("sending test msg")
    let buttons = []
    buttons[0] = {text: "Log in GJ", cmd: "gj", data: {msg: "This is an example of a GJ created from a push notification.\n Good for logging important alarms.",
    tags: ["sensors", "other"]}}
    buttons[1] = {text: "View", cmd: "url", data: "http://ryanelk.link4cloud.com:3134/usa/c18/admin/manageSubscription"}
    buttons[2] = {text: "Dismiss", cmd: "", data: {}}
    await wsTrans("usa", {cmd: "cRest", uri: "/s/pushNote", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {
        deviceIds: [deviceId],
        title: `Test Push from ${deviceName}`,
        body: {msg: "Hello", buttons: buttons},
        topic: "alarm",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJiMe2sF84-H1gwFr7qbUNcyBE2i7jQrPg4Q&usqp=CAU",
      }})
  }

  deleteDeviceInfo=async(deviceId)=>{
    cl("deleting device info")
    let deleteDevice={cmd: "cRest", uri: "/s/devices", method: "delete", sessionId: globs.userData.session.sessionId,
              body: {deviceId: deviceId}}
    await wsTrans("usa", deleteDevice)

    // update devices 
    globs.devicesInfo={}
    await loadDevicesInfo()
    let devices = globs.devicesInfo.info.filter((d) => d.userId == globs.userData.session.userId)
    this.setState({devices: devices})
  }

  showDeviceInfo=(info,i)=>{
    let retArr = []
//     cl(info)
    let data
    try {
      data = JSON.parse(info)
      Object.entries(data).map(([key, value], i)=> {
        retArr.push(<tr key={13 * i + 1}><td>{key}</td><td>{value}</td></tr>)
      })
    } catch (e) {
      data = info
      retArr.push(<tr key={13 * i + 1}><td>{data}</td></tr>)
    }
    return retArr
  }


  showDevices=()=>{
    let retArr=[]
    this.state.devices.map((d,i)=>{
      cl(d)
      retArr.push(<tr key={13 * i + 1}><td>Device ID</td><td>{d.deviceId}</td></tr>)
      retArr.push(<tr key={13 * i + 2}><td>Device Name</td><td>{d.deviceName}</td></tr>)
      retArr.push(
        <tr key={13 * i + 3}>
        <td>Device Info</td>
          <td>
            <table><tbody>
            {(d.deviceInfo) ? this.showDeviceInfo(d.deviceInfo,i) : 
              <tr><td></td></tr>}
            </tbody></table>
          </td>
        </tr>)
      if (d.deviceToken) {
        retArr.push(<tr key={13 * i + 4}><td>Device Token</td>
            <td>
              {d.deviceToken}
            </td>
          </tr>)
      }
      retArr.push(<tr key={13 * i + 5}><td>User ID</td><td>{d.userId}</td></tr>)
      retArr.push(<tr key={13 * i + 6}><td>Device Theme</td><td>{d.deviceTheme}</td></tr>)
      retArr.push(<tr key={13 * i + 7}><td>Biometric Login</td><td>{(d.bioEnabled == 2) ? "Enabled" : (d.bioEnabled == 1) ? "Disabled" : "Prompt"}</td></tr>)
      if (d.deviceToken) {
        retArr.push(<tr key={13 * i + 8}><td>Test Push Notification</td><td><button type="button" onClick={async()=>this.testMessage(d.deviceId, d.deviceName)} className="outlined">Test Push</button></td></tr>)
      }
      retArr.push(<tr key={13 * i + 9}><td>Delete Device</td>
          <td>
            <button onClick={async()=>this.deleteDeviceInfo(d.deviceId)}type="button">
              <span className="alarm-on material-icons-outlined">
              delete
              </span>
            </button>
          </td>
        </tr>)

      retArr.push(<tr key={13 * i + 10}><td>Created At</td><td>{dateToDisplayDate(tsToDate(d.createdAt), "mm/dd/yyyy h:mm:ss ap")}</td></tr>)
      retArr.push(<tr key={13 * i + 11}><td>Updated At</td><td>{dateToDisplayDate(tsToDate(d.updatedAt), "mm/dd/yyyy h:mm:ss ap")}</td></tr>)
      retArr.push(<tr key={13 * i + 12}><td></td><td></td></tr>)

    })
    return retArr
  }

  showWebsites = () => {
    return (
      [
      <tr key={1}>
        <td>Current Website</td>
        <td><a href={window.location}>{window.location + ""}</a></td>
      </tr>,
      <tr key={2}>
        <td>Cloud Portal</td>
        <td><a href='http://c2ju.link4cloud.com/'>http://c2ju.link4cloud.com/</a></td>
      </tr>
      ]
    )
  }

  render(){
//     cl(this.state)
//     cl(`time: ${getTime()}`)
    let now=new Date()
//     cl(this.state)
    let dt=dateToYrMoDa(now)
    let ti=dateToLocalHrMinSecString(now)
    if(this.state.loaded){
      let privs=(globs.userData.session?.privs?.dashboard) ? `Dashboard: ${globs.userData.session.privs.dashboard}` : ""
      return(
        <div className="table-container">
        <table className="list"><tbody>
        <tr><td>Account</td><td>{globs.userData.session.accountId}</td></tr>
        <tr><td>Time</td><td>{`${dt} ${ti}`}</td></tr>
        <tr><td>React Client Version:</td><td>{globs.version}</td></tr>
        <tr><td>Node Server Version</td><td>{this.state.status.version}</td></tr>
        <tr><td>Websites</td><td></td></tr>
        {this.showWebsites()}
        <tr><td>Privs</td><td>{privs}</td></tr>
        <tr><td>Site</td><td>{globs.userData.session.siteId}</td></tr>
        <tr><td>UserId</td><td>{globs.userData.session.userId}</td></tr>
        <tr><td height="10"></td><td></td></tr>
        <tr><td>Gateways</td><td></td></tr>
        {this.showGateways()}
        <tr><td>Zones</td><td></td></tr>
        {this.showZones()}
        <tr><td>WebSocket</td><td></td></tr>
        {this.showWebSocket()}
        
        <tr><td>Reports</td><td><button type="button" className="outlined" onClick={this.makeDefaultReports}>Generate Default Reports</button></td></tr>
        <tr><td>Devices</td><td><button type="button" className="outlined" onClick={this.deleteDevices}>Delete All Devices</button></td></tr>
        <tr><td>Controllers</td><td></td></tr>
        {this.showControllers()}
        <tr><td>Devices</td><td></td></tr>
        {this.showDevices()}
        </tbody></table>
        </div>
      );
    }else{
      return <div>loading...</div>
    }
  }
}

export default Status ;

