import React from 'react';
// import MainBar from '../../components/MainBar';
import UsaSelect00 from './UsaSelect00';
import UsaIcon from './UsaIcon';
import Alarms00 from './Alarms00';
import {wsTrans, checkLoggedIn} from '../utils/utils'
import {cl, globs, userSpecificHome} from '../../components/utils/utils';

class ManageAlarms extends React.Component{
  constructor(props) {
    super(props);
    document.title="Manage Alarms";
    this.state={
      loaded: false,
      showSiteAlarms: false,
    }
    this.alarmIds=["E1Sn", "E1Sr", "E1Ca", "E2Sn", "E2Sr", "E2Ca", "E3Sn", "E3Sr", "E3Ca", "ECLo", "ECHi", "ECDe", "", "P1Sn", "P1Sr", "P1Ca", "P2Sn", "P2Sr", "P2Ca", "P3Sn", "P3Sr", "P3Ca", "PhLo", "PhHi", "PhDe", "", "AlLo", "AlHi", "ITLo", "ITHi", "ITSn", "AuxA", "TmOt"] 
    this.getSites()
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Alarms", url:`/usa/c18/admin/manageAlarms`},
            ]},
        },
      )
    }
}
  
//   space=(n)=>{
//     return <span style={{marginLeft: `${n}em`}}></span>
//   }
  
  makeAlarmEnables=(userId)=>{// for each user
    let alarmEnables={}
    this.alarmIds.forEach(a=>{
      alarmEnables[a]=false
    })
    return alarmEnables
  }
  
  makeUserEnables=()=>{// for all users of an alarm description
    let userEnables={}
//     cl(this.users)
    this.users.forEach(u=>{
      userEnables[u.userId]=this.makeAlarmEnables(u.userId)
    })
      return userEnables
  }
  
  setEnables=()=>{
//     alarms=["HiInTemp", "LoInTemp", "ErrInTemp", "HiEC", "LoEC", "HIpH", "LOpH", "ErrEC", 
//       "ERRpH", "DevEC", "DEVpH"] 
//     let userEnables={}
//     this.users.forEach(u=>{
//       userEnables[u.userId]=Object.assign({},alarmEnables)
//     })
    this.account={enables: this.makeUserEnables()}
    this.sites.forEach(s=>{
      this.account[s.siteId]={
        enables: this.makeUserEnables()
      }
    })
    
    this.zones.forEach(z=>{
      this.account[z.zoneIndex]={
        enables: this.makeUserEnables()
      }
    })
    
    this.alarms.forEach(a=>{
      cl(this.account)
      cl(a)
      if(a.siteId==-1){// account
        this.account.enables[a.userId][a.alarm]=true
      }else{
        if(a.zoneIndex==-1){
          this.account[a.siteId].enables[a.userId][a.alarm]=true
        }else{
          this.account[a.zoneIndex].enables[a.userId][a.alarm]=true
        }
      }
    })
    cl(this.account.enables)
//     cl(this.account)
  }
  
  makeZoneOpts=(site)=>{
    let zoneOpts={}
    this.zones.forEach(z=>{
      if(z.siteId==site){
        zoneOpts[z.zoneIndex]=z.zoneName
      }
    })
    return zoneOpts
  }

  getSites=async()=>{
    let siteP=wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
    let usP=wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
    let zoneP=wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
    let alarmsP=wsTrans("usa", {cmd: "cRest", uri: "/s/alarmNotifications", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
    let [siteR, usR, zoneR, alarmsR]=await Promise.all([siteP, usP, zoneP, alarmsP])
    this.sites=siteR.data
    this.users=usR.data
    cl(usR)
    cl(this.users)
    this.zones=zoneR.data
    this.alarms=alarmsR.data
    this.alarms.forEach(a=>{if(a.zoneId){a.zoneIndex=a.zoneId}})
    this.setEnables()
    let siteOpts={}
    this.sites.forEach(s=>{
      siteOpts[s.siteId]=s.name
    })
    let site=this.sites[0].siteId
    let zoneOpts=this.makeZoneOpts(site)
    let zone=Object.keys(zoneOpts)[0]
    this.setState({siteOpts: siteOpts, site: site,
      zoneOpts: zoneOpts, zone: zone,
      loaded: true})
  }
  
  getAlarms=(arr, base, enables)=>{
// return an array of {alarm: id, user: id}
    let ret=[]
    Object.keys(enables).forEach(userId=>{
      let user=enables[userId]
      Object.keys(user).forEach(alarm=>{
        if(user[alarm]){
          arr.push(Object.assign({alarm: alarm, userId: userId}, base))
        }
      })
    })
  }
  
  saveAlarms=async()=>{
    let ret=[]
    let base={accountId: globs.userData.session.accountId, siteId: -1, zoneIndex: -1, tankIndex: -1}
    this.getAlarms(ret, base, this.account.enables)
    this.sites.forEach(s=>{
      base.siteId=s.siteId
      this.getAlarms(ret, base, this.account[s.siteId].enables)
    })
    this.zones.forEach(z=>{
      base.siteId=z.siteId
      base.zoneIndex=z.zoneIndex
      this.getAlarms(ret, base, this.account[z.zoneIndex].enables)
    })
    await wsTrans("usa", {cmd: "cRest", uri: "/s/alarmNotifications", method: "update", 
      sessionId: globs.userData.session.sessionId, body: ret})
  }
  
  result=(type)=>{
    cl(type)
    switch(type){
      case "ManageAlarms-OK":// this should copy a whole user/alarm array to Alarms00
        this.saveAlarms()
        break
      case "AccountAlarm-Edit":// this should copy a whole user/alarm array to Alarms00
        this.setState({showAccountAlarms: true})
        break
      case "SiteAlarm-Edit":// this should copy a whole user/alarm array to Alarms00
        this.setState({showSiteAlarms: true})
        break
      case "ZoneAlarm-Edit":// this should copy a whole user/alarm array to Alarms00
        this.setState({showZoneAlarms: true})
        break
      default:
        break
    }
  }
  
  onChange=(type,vals)=>{
    cl(type)
    switch(type){
      case "zone":
        vals=Object.assign({showZoneAlarms: false},vals)
        cl(vals)
        this.setState(vals)
        break
      case "site":
        let zoneOpts=this.makeZoneOpts(vals.site)
        let zone=Object.keys(zoneOpts)[0]
        vals=Object.assign({showSiteAlarms: false, zoneOpts: zoneOpts, zone: zone},vals)
        this.setState(vals)
        break
      case "alarmEnables":
        cl(vals.result)
        switch(vals.result){
          case "Alarms-OK":
            cl(vals.enables)
            this.account.enables=Object.assign({},vals.enables)
            break
          default:
            break
        }
        this.setState({"showAccountAlarms": false})
        break

      case "siteEnables":
        cl(vals.result)
        switch(vals.result){
          case "Alarms-OK":
            cl(vals.enables)
            let site=this.state.site
            this.account[site].enables=Object.assign({},vals.enables)
            break
          default:
            break
        }
        this.setState({"showSiteAlarms": false})
        break

      case "zoneEnables":
        cl(vals.result)
        switch(vals.result){
          case "Alarms-OK":
            cl(vals.enables)
            let zone=this.state.zone
            this.account[zone].enables=Object.assign({},vals.enables)
            break
          default:
            break
        }
        this.setState({"showZoneAlarms": false})
        break
//       case "Alarms-OK":
//         cl(vals)
//         break
      default:
        break
    }
  }
  
  showSelectZone=()=>{
//     return null
//     cl(this.state.zoneOpts)
    if(Object.keys(this.state.zoneOpts).length){
      return(
        <div>
          <div style={{height: 10}}/>
          <UsaSelect00 parms={{
            title: "Select\u00A0Zone",
            width: 200,
            select: true, // !this.state.editMode,
            valueId: "zone",//valueId, // "widgetSel",
            value: this.state.zone, //value, // this.state.widgetSel,
            opts: this.state.zoneOpts,
            onChange: (vals)=>this.onChange("zone", vals),
          }}/>
          <div style={{height: 10}}/>
        </div>
      )
    }else{
      return <div>No Zones</div>
    }
  }
  
  showZoneAlarms=(zone)=>{
//     cl(this.account[site].enables)
    if(this.state.showZoneAlarms){
      return <Alarms00 parms={{isTank: false, 
        title: "Zone Alarms2",
        onChange: o=>this.onChange("zoneEnables",o),
        users: this.users, enables: this.account[zone].enables}}/>
    }else{
      return (
        <div>
          <h3>Zone Alarms
            <UsaIcon space="1" icon={`ZoneAlarm-Edit`} result={this.result} inline/>
          </h3>
          <div style={{height: 10}}/>
        </div>
      )
    }
  }
  
  showSiteAlarms=(site)=>{
//     cl(this.account[site].enables)
    if(this.state.showSiteAlarms){
      return <Alarms00 parms={{isTank: false, 
        title: "Site Alarms2",
        onChange: o=>this.onChange("siteEnables",o),
        users: this.users, enables: this.account[site].enables}}/>
    }else{
      return (
        <div>
          <h3>Site Alarms
            <UsaIcon space="1" icon={`SiteAlarm-Edit`} result={this.result} inline/>
          </h3>
          <div style={{height: 10}}/>
        </div>
      )
    }
  }
  
  showAccountAlarms=(site)=>{
//     cl(this.account[site].enables)
    if(this.state.showAccountAlarms){
      return <Alarms00 parms={{isTank: false, 
        title: "Account Alarms",
        onChange: o=>this.onChange("alarmEnables",o),
        users: this.users, enables: this.account.enables}}/>
    }else{
      return (
        <div>
          <h3>Account Alarms
            <UsaIcon space="1" icon={`AccountAlarm-Edit`} result={this.result} inline/>
          </h3>
          <div style={{height: 10}}/>
        </div>
      )
    }
  }
  
  showSelectSite=()=>{
    return(
      <div>
        <div style={{height: 10}}/>
        <UsaSelect00 parms={{
          title: "Select\u00A0Site",
          width: 200,
          select: true, // !this.state.editMode,
          valueId: "site",//valueId, // "widgetSel",
          value: this.state.site, //value, // this.state.widgetSel,
          opts: this.state.siteOpts,
          onChange: (vals)=>this.onChange("site", vals),
        }}/>
        <div style={{height: 10}}/>
      </div>
    )
  }
  
  render(){
    if(this.state.loaded){
      return(
        <div style={{padding: 10}}>
        <h3>Manage Alarms
            <UsaIcon space="1" icon={`ManageAlarms-OK`} result={this.result} inline/>
            <UsaIcon icon={`ManageAlarms-Cancel`} result={this.result} inline/>
        </h3>
        {this.showAccountAlarms()}
        {this.showSelectSite()}
        {this.showSiteAlarms(this.state.site)}
        {this.showSelectZone()}
        {this.showZoneAlarms(this.state.zone)}
        </div>
      )
    }else{
      return <div>loading...</div>
    }
  }
}

export default ManageAlarms ;

