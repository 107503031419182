import React from 'react';
import VidTimeline00 from '../../visualization/components/VidTimeline00'
// import Graph02 from '../../visualization/components/Graph02'
import {loadSitesInfo,loadZonesInfo,getSiteName,getZoneName} from './C18utils'
import {cl,globs} from '../../components/utils/utils';

import history from "../../history"

class C18VideoDetail00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      mode:"sites",
    }
    this.setBreadcrumbs()
  }

  setBreadcrumbs=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    let siteName=getSiteName(this.props.parms.site)
    let zoneName=getZoneName(this.props.parms.zone)
    this.props.parms.onChange(
      {
        cmd: "breadcrumbs",
        data:
          {breadcrumbs: [
            {t:"Sites", url:"/usa/c18/sites"},
            {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
            {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
          ]},
      },
    )
  }

  render(){
//     cl(this.props)
    return (
      <div>
        <VidTimeline00/>
      </div>
    )
  }
}

export default C18VideoDetail00;
