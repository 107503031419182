import React from 'react';
import UsaIcon from '../UsaIcon';
import UsaSelect00 from '../UsaSelect00'
import {wsTrans} from '../../../usa/utils/utils'
import {loadZonesInfo,getZoneInfo,saveTable,getSiteName,getZoneName} from '../C18utils'
import {cl,globs,az,getTime,getRandomString} from '../../../components/utils/utils';

const verticalSpace=<div style={{height: 10}}/>

class Nutrients extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      nutrientId: null,
//       gatewayId:getZoneInfo(props.parms.zone).gatewayId,
      name: "Nutrient 1",
      description: "This is the nutrient description",
      manufacturer: "Monsanto",
      notes: "These are the notes",
      nutrientOpts: {},
      nutrientSel: null,
      loaded: false,
      editMode: false,
    }
    this.colors=[
      "#800000", "#804000", "#808000", "#408000", "#008000", "#008040", "#008080", "#004080", 
      "#000080", "#400080", "#800080", "#800040", "#404040", "#FF9999", "#FFCC99", "#FFFF99", 
      "#CCFF99", "#99FF99", "#99FFCC", "#99FFFF", "#99CCFF", "#9999FF", "#CC99FF", "#FF99FF", 
      "#FF99CC", "#CCCCCC" ]
    this.loadNutrients()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.savePage)
    if(this.props.parms.saveOK){this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})}
//     this.loadInfo()
    this.setBreadCrumbs()
  }
  
  componentWillUnmount=()=>{
    if(this.subscribe_savePageEvent){this.subscribe_savePageEvent.remove()}
  }
  
  setBreadCrumbs=()=>{
    cl(this.props.parms)
    let p=this.props.parms
    let siteName=getSiteName(p.site)
    let zoneName=getZoneName(p.zone)
    if(p){
      p.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
              {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
//               {t:"iDoser", url:`/usa/c18/idoser/nutrients`},
              {t:"Nutrients", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/idoser/nutrients`},
            ]},
        },
      )
    }
  }
  
  savePage=(cmd)=>{
    this.saveNutrient()
//     cl(cmd)
  }
  
  onChange=(type, vals)=>{
//     cl(type,vals)
//     cl(type)
//     cl(vals)
    switch(type){
      case "nute":
        this.setState(vals)
        break
      case "upd":
        globs.events.publish("savePageEnable",true)
        let nutes=Object.assign({},this.state.nutrientOpts)
        if(!nutes[this.state.nutrientSel]){return}
        Object.assign(nutes[this.state.nutrientSel],vals)
        this.setState({nutrientOpts:nutes})
//         this.setState(vals)
        break
      case "nutrient":
        this.setState(Object.assign({editMode: false},vals))
        break
      case "fields":
        this.setState(vals)
        break
      case "delete":
//         globs.events.publish("savePageEnable",true)
        this.deleteNutrient()
        break
      case "add":
        this.addNutrient()
        break
    }
  }
  
  loadNutrients=async()=>{
    await loadZonesInfo()
    this.gatewayId=getZoneInfo(this.props.parms.zone).gatewayId
//     cl(this.props.parms)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/nutrients", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})// now for gatewayId
    this.nutrients={}
    let nutrientSel=(r.data[0])?r.data[0].nutrientId:0
    r.data.forEach(n=>{
      delete n["_id"]
      delete n.t
//       n.t=n.name// for the select Opts
      this.nutrients[n.nutrientId]=n
    })
//     cl(this.nutrients)
    this.setState({nutrientOpts: this.nutrients, nutrientSel: nutrientSel, loaded: true})
  }
  
  getANutrient=(nutrientId)=>{
    this.setState(
      Object.assign({editMode: true},this.state.nutrientOpts[nutrientId]))
  }
  
  newNutrient=()=>{
    this.setState({
      nutrientId: getRandomString(16),
      name:"",
      description:"",
      manufacturer:"",
      notes:"",
      editMode:true,
    })
  }
  
  saveNutrient=async()=>{
    let s=this.state
//     let nutes=Object.assign(this.state.nutrientOpts)
//     nutes[s.nutrientId]={
//       nutrientId: s.nutrientId,
//       name:s.name,
//       t:s.name,
//       description:s.description,
//       manufacturer:s.manufacturer,
//       notes:s.notes,
//     }
//     this.setState({nutrientOpts: nutes, editMode: false})
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/nutrients", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: s.nutrientOpts[s.nutrientSel]})
//     cl(s.nutrientOpts[s.nutrientSel])
    globs.events.publish("savePageEnable",false)
    await saveTable("nutrients",this.gatewayId)
    globs.events.publish("saveOK",true)
}
  
  deleteNutrient=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Nutrient?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let nutes=Object.assign(this.state.nutrientOpts)
      delete nutes[this.state.nutrientSel]
      let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/nutrients", method: "delete", 
        sessionId: globs.userData.session.sessionId,
        body: {nutrientId: +this.state.nutrientSel,gatewayId:this.gatewayId}})
      this.setState({nutrientOpts: nutes,nutrientSel:Object.keys(nutes)[0]})
    }
  }
  
  addNutrient=()=>{
    let nutes=Object.assign(this.state.nutrientOpts)
    cl(nutes)
    let max=1
    Object.keys(nutes).forEach(k=>{
      if(!isNaN(k)){
        if(max<+k){max=+k}
      }
    })
    cl(max)
    let nutrientSel=+max+1//getRandomString(16)
    cl(nutrientSel)
    nutes[nutrientSel]={
      description:"",
      manufacturer:"",
      name:"New Nutrient",
      notes:"",
      nutrientId:nutrientSel,
//       siteId:globs.userData.session.siteId,
      gatewayId:this.gatewayId,
    }
    this.setState({nutrientOpts: nutes,nutrientSel:nutrientSel})
  }
  
  showNutrientSelectO=()=>{
//     cl(this.state)
    if(Object.keys(this.state.nutrientOpts).length){
    return(
      <UsaSelect00 parms={{
        title: "Select\u00A0Nutrient",
        select: true, // !this.state.editMode,
        inline: true,
        valueId: "nutrientSel",//valueId, // "widgetSel",
        value: this.state.nutrientSel, //value, // this.state.widgetSel,
        opts: this.state.nutrientOpts,
        onChange: x=>this.onChange("nutrient",x)
      }}/>
    )
    }else{
      return null
    }
  }
  
  showName=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Name",
        width: 400,
        select: false, // !this.state.editMode,
        valueId: "name",//valueId, // "widgetSel",
        value: this.state.name, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
    )
  }
  
  showDescription=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Description",
        multiline: true,
        rowsMax: 8,
        width: 400,
        select: false, // !this.state.editMode,
        valueId: "description",//valueId, // "widgetSel",
        value: this.state.description, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
    )
  }
  
  showManufacturer=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Manufacturer",
        width: 400,
        select: false, // !this.state.editMode,
        valueId: "manufacturer",//valueId, // "widgetSel",
        value: this.state.manufacturer, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
    )
  }
  
  showNotes=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Notes",
        multiline: true,
        rowsMax: 8,
        width: 400,
        select: false, // !this.state.editMode,
        valueId: "notes",//valueId, // "widgetSel",
        value: this.state.notes, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
    )
  }
  
  result=(type)=>{
    cl(type)
    switch(type){
      case "Nutrients-OK":
        this.saveNutrient()
        break
      case "Nutrients-Cancel":
        this.setState({editMode: false})
        break
      case "Nutrients-Edit":
        this.getANutrient(this.state.nutrientSel)
        break
      case "Nutrients-Add":
        this.newNutrient()
        break
      case "Nutrients-Delete":
        this.deleteNutrient()
        break
      default:
        break
    }
  }
  
  showEditFields=()=>{
    if(this.state.editMode){
      return(
      <div>
        {verticalSpace}
        {this.showName()}
        {verticalSpace}
        {this.showDescription()}
        {verticalSpace}
        {this.showManufacturer()}
        {verticalSpace}
        {this.showNotes()}
      </div>
      )
    }else{
      return null
    }
  }
  
  showMainIcons=()=>{
    if(this.state.editMode){
      return([
        <UsaIcon key={0} space="0.5" icon="Nutrients-OK" result={this.result} inline/>,
        <UsaIcon key={1} icon="Nutrients-Cancel" result={this.result} inline/>,
      ])
    }else{
      return([
        <UsaIcon key={0} space="0.5" icon="Nutrients-Add" result={this.result} inline/>,
        <UsaIcon key={1} icon="Nutrients-Edit" result={this.result} inline/>,
        <UsaIcon key={2} icon="Nutrients-Delete" result={this.result} inline/>,
      ])
    }
  }
  
  renderO(){
    if(this.state.loaded){
      return(
        <div style={{padding: 10}}>
        <h3>Nutrients
        {this.showMainIcons()}
        </h3>
        {this.showNutrientSelect()}
        {this.showEditFields()}
        
        </div>
      )
    }else{
      return <div>loading...</div>
    }
  }
  
  showNutrientSelect=()=>{
//     cl(this.state)
    let nutes=this.state.nutrientOpts
//     cl(nutes)
    let x=Object.keys(nutes)
    return(
      <span className="custom-select">
        <label htmlFor="idoser-nutrient">Select Nutrient</label>
        <select id="idoser-nutrient"
          value={this.state.nutrientSel}
          onChange={e=>this.onChange("nute",{nutrientSel: e.currentTarget.value})}
        >
        {Object.keys(nutes).map((nutrientId,i)=>{
          return <option key={i} value={nutrientId}>{nutes[nutrientId].name}</option>
          })
        }
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }
  
  showNutrientColor=(nute)=>{
    let st=this.state
//     cl(this.state)
    let rng=[...Array(13).keys()]
    return(
      <div>
        <label>Color</label>
        <table><tbody>
        <tr><td width="30">
          <div style={{width:30,height:30,backgroundColor:nute?.color}}/>
          </td>
          <td>
            <table style={{width:130}}><tbody>
            {
            [...Array(2).keys()].map(ro=>{
//               cl(ro)
              return(
                <tr key={ro} width="130">
                {rng.map(i=>{
                  return(
                    <td key={i} width="10"><div style={{width:10,height:10,
                      cursor:"pointer",
                      backgroundColor:this.colors[13*ro+i]}}
                      onClick={e=>{this.onChange("upd",{color:this.colors[13*ro+i]})}}
                    />
                    </td>
                  )
                })}
                </tr>
              )
            })
              
            }
            </tbody></table>
          </td>
        </tr>
        </tbody></table>
        
        <br/>
      </div>
    )
  }
  
  showNutrientEdit=()=>{
//     cl(this.state.nutrientOpts)
//     cl(this.state)
    let nute=this.state.nutrientOpts[this.state.nutrientSel]
    return(
      <div id="iDoser_nutrient_edit">
        <label htmlFor="idoser-name">Name</label>
        <input id="idoser-name" type="text" value={nute?.name||""}
        disabled={!this.props.parms.saveOK}
        onChange={e=>this.onChange("upd",{name:e.currentTarget.value})}/>

        <br />

        {this.showNutrientColor(nute)}
        
        <label htmlFor="idoser-description">Description</label>
        <textarea id="idoser-description"
        value={nute?.description||""}
        disabled={!this.props.parms.saveOK}
        onChange={e=>this.onChange("upd",{description:e.currentTarget.value})}/>

        <br />

        <label htmlFor="idoser-mfg">Manufacturer</label>
        <input id="idoser-mfg" type="text" 
        value={nute?.manufacturer||""}
        disabled={!this.props.parms.saveOK}
        onChange={e=>this.onChange("upd",{manufacturer:e.currentTarget.value})}/>

        <br />

        <label htmlFor="idoser-notes">Notes</label>
        <textarea id="idoser-notes"
        value={nute?.notes||""}
        disabled={!this.props.parms.saveOK}
        onChange={e=>this.onChange("upd",{notes:e.currentTarget.value})}/>

      </div>
    )
  }
  
  render(){
//     cl(this.state)
    if(this.state.loaded){
//               <a href="" className="material-icons-outlined add" aria-label="add nutrient">
//                 add
//               </a>
      return(
        <div id="iDoser_nutrients">
          <div className="clearfloat"></div>

          {this.showNutrientSelect()}
              <button type="button" className="material-icons trash after-selector" aria-label="delete nutrient"
              disabled={!this.props.parms.saveOK}
              onClick={e=>this.onChange("delete")}>
                delete_outline
              </button>

              <button type="button" className="material-icons-outlined add after-selector" aria-label="add nutrient"
              disabled={!this.props.parms.saveOK}
              onClick={e=>this.onChange("add")}>
                add
              </button>
          <div className="clearfloat"></div>
          <br /><hr /><br />
          {this.showNutrientEdit()}
        </div>
      )
    }else{return<div>loading. . .</div>}
  }
}

export default Nutrients ;

