import React from 'react';
import {cl, globs} from '../../components/utils/utils';
import Gauge2 from './Gauge';
import Graph from './Graph';

class Demo1 extends React.Component{
  constructor(props) {
    super(props);
    this.gauge={
      value: 3,
      width: 200,// sets size
      height: 170,
      bgColor: '#C0FFFF',
      ringInset: 20,
      ringWidth: 80,
      min: 0,
      minAngle: -120,
      max: 10,
      maxAngle: 120,
      ticks: [0, 2, 4, 6, 8, 10],// these must be values, in scale min-max
      colors: [
      [0, "#FF0000"],
      [2, "#FFFF00"],
      [4, "#00FF00"],
      [6, "#00FFFF"],
      [8, "#0000FF"],
      [10, "#FF0000"],
      ],
    }
    this.graph={
      margin: 5,
      xTicks: -3,
      yTicks: 3,
      width: 200,// sets size
      height: 150,
      bgColor: '#CCCCCC',
      data: [
      {date: "Tue May 01 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 58.13},
      {date: "Mon Apr 30 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 53.98},
      {date: "Fri Apr 27 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 67},
      {date: "Thu Apr 26 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 89.7},
      {date: "Wed Apr 25 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 99},
      {date: "Tue Apr 24 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 130.28},
      {date: "Mon Apr 23 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 166.7},
      {date: "Fri Apr 20 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 234.98},
      {date: "Thu Apr 19 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 345.44},
      {date: "Wed Apr 18 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 443.34},
      {date: "Tue Apr 17 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 543.7},
      {date: "Mon Apr 16 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 580.13},
      {date: "Fri Apr 13 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 605.23},
      {date: "Thu Apr 12 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 622.77},
      {date: "Wed Apr 11 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 626.2},
      {date: "Tue Apr 10 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 628.44},
      {date: "Mon Apr 09 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 636.23},
      {date: "Thu Apr 05 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 633.68},
      {date: "Wed Apr 04 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 624.31},
      {date: "Tue Apr 03 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 629.32},
      {date: "Mon Apr 02 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 618.63},
      {date: "Fri Mar 30 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 599.55},
      {date: "Thu Mar 29 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 609.86},
      {date: "Wed Mar 28 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 617.62},
      {date: "Tue Mar 27 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 614.48},
      {date: "Mon Mar 26 2012 00:00:00 GMT-0700 (Pacific Daylight Time)", close: 606.98},
      ],
    }
  }

  render() {
    return(
      <div>
        <Gauge2 config={this.gauge}/>
        <Graph config={this.graph}/>
      </div>
    );
  }
}

export default Demo1;
