import React from 'react';
import {cl, constant, globs} from '../../components/utils/utils';
import Graph2 from './Graph';
// import Graph from './Graph';
import {wsTrans} from '../../usa/utils/utils'
import {getTime} from '../../components/utils/utils'
import {getParmInfo} from '../../usa/utils/utils'

class VisualizationTimeSeries00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    let dm=5
    this.state={loaded: false}
    this.graph={data:[]}
    this.getData()
    this.dataVersion=0
//     this.dpList=props.parms.graph.dpList
//     cl(this.dpList)
  }
  
  shouldComponentUpdate=async()=>{
//     cl("should")
    let p = this.props.parms;
//     cl(p)
//     cl(p.graph.dataVersion)
    if(p.graph.dataVersion){
      if(this.dataVersion!=p.graph.dataVersion){
        this.dataVersion=p.graph.dataVersion
//         cl("new data")
        await this.getAllData()
//         cl("got")
      }
    }
//     cl("true")
    return true
  }

  getOneData=async(z, c, i, b, e)=>{// b,e: fromTime, toTime
//     cl("getone")
//     cl([z, c, i, b, e])
    let body={fromTime: b, toTime: e, z: z, c: c, i: i}// get one dashboard, by id
    let getHistory={cmd: "cRest", uri: "/s/history", method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: body};
    let res=await wsTrans("usa", getHistory)
//     cl(res)
    return res
  }
  
  getAllData=async()=>{
    await this.getData()
//     cl("done")
  }
  
  getData=()=>{
/* this needs to convert the zci, pid to z, c, i
also, this needs to use the dashboard zci, and the widget zci overrides*/
//   cl("getData")
    return new Promise((r,er)=>{
      let p=this.props.parms.graph
      var b,e
      if(p.flags&constant.TIME_PERIOD_LIVE){
        e=Math.floor(getTime())
        b=e-p.duration*p.period
      }else{
        b=p.beginTime
        e=p.endTime
      }
//       cl(p.flags)
      cl([b,e])
      let proms=[]
//       cl([p,e,b])
//       cl(p)
      if(!p.dpList){r()}
      p.dpList.forEach(async dp=>{
        let z0=(dp.zone!=="")?dp.zone : p.zone
        let c0=(dp.channel!=="")?dp.channel : p.chan
        let ix=(dp.index!=="")?dp.index : p.indx
//         cl([z0,c0,ix,dp.pid])
        let [z,c,i]=getParmInfo(z0,c0,ix,dp.pid)
        proms.push(this.getOneData(z, c, i, b, e))
//         dp.data= (await this.getOneData(z, c, i, b, e)).data
//         cl(dp.data)
      })
      Promise.all(proms).then(vals=>{
        p.dpList.forEach((dp,i)=>{
          dp.data=vals[i].data
        })
//         cl(p.dpList)
//         cl("done")
        this.graph.data=p.dpList
        this.setState({loaded: true})
        r()// return
      })
      
    })
    return
  }
  
  onClick=()=>{
    if(this.props.parms.e) {return}// edit mode
    
  }

  onDrag=(o)=>{// o has the updated info
    if("dragStart" in o){
      this.setState(o)
    }else{
      if(this.state.dragStart.i==this.props.parms.i){
        this.setState(o)
      }
    }
  }
  
  cm=(e)=>{
    e.stopPropagation()
    this.props.parms.cm(e)
  }
  
  md=(e)=>{
    this.props.parms.md({e: e, i: this.props.parms.i})
  }
  
  render=()=> {
    let p = this.props.parms;
//     cl(p)
    let dm=5
    let x=p.pos.x+dm
    let y=p.pos.y+dm
//     cl(p.graph)
//     if(!p.graph.dataVersion
//     cl(p)
//     cl(this.dpList)
//     cl(this.props.parms.graph.dpList)
//     if(this.dpList!=this.props.parms.graph.dpList){
//       this.dpList=this.props.parms.graph.dpList
//       cl("new data")
//     }
    this.graph=Object.assign(this.graph, {
      left: this.props.parms.pos.x,
      top: this.props.parms.pos.y,
      width: this.props.parms.pos.w-2*dm,
      height: this.props.parms.pos.h-2*dm,
      radius: 10,
      margin: 5,
      xTicks: this.props.parms.graph.xTicks,
      yTicks: 2,
      title: this.props.parms.graph.title,
      bgColor: this.props.parms.graph.bgColor,
    })
    return(
        <div style={{position: "absolute", left: x, top: y, width: p.pos.w-2*dm, height: p.pos.h-2*dm, 
          borderRadius: 10, boxShadow: "10px 10px 20px #808080", backgroundColor: p.graph.bgColor}} onContextMenu={this.cm} onMouseDown={this.md}>
          {this.state.loaded &&
          <Graph2 config={this.graph}/>}
          </div>
      );
  }
}

export default VisualizationTimeSeries00;
