import React from 'react';
import {sendArray, dbVals, putZValue} from '../../components/utils/http';
import {pi,pInd} from '../../components/utils/paramIds';
import {cl, globs, leadZeros, deepCopy, getTime} from '../../components/utils/utils';

class AuxVariables00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    let p=props.parms
//     let vars={zone_Aux_Variables:p.variables, zone_Aux_PVariables:p.pVariables, zone_Aux_Alarms:p.alarms, }[p.pageType]
    this.varArr={zone_Aux_Variables:"variables", zone_Aux_PVariables:"pVariables", zone_Aux_Alarms:"alarms", }
//     this.varArr={zone_Aux_Variables:p.variables, zone_Aux_PVariables:p.pVariables, zone_Aux_Alarms:p.alarms, }
    
    this.state={
//       vars:vars,
    }
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveCancel)
  }
  
  componentWillUnmount=()=>{
    cl("unMount")
    this.subscribe_savePageEvent.remove()
  }
  
  saveCancel=(cmd)=>{
    if(cmd=="save"){
      cl(this.props.parms)
//       cl(this.state.pageChange)
      let zone=+this.props.parms.zuci.split("-")[0]
      let unit=this.props.parms.unit
      let parms=[]
      switch(this.props.parms.pageChange){
        case "variables":
          this.saveVariableNames(this.props.parms.variables, zone,unit, pInd[1800].config_aux_variables, pi[1800].config_aux_variables["variableName"], 
            "Variable", parms)
          break
        case "pVariables":
          this.saveVariableNames(this.props.parms.pVariables, zone,unit, pInd[1800].config_aux_persistent_variables,
            pi[1800].config_aux_persistent_variables["variableName"], "Persistent Variable", parms)
          break
        case "alarms":
          this.saveVariableNames(this.props.parms.alarms, zone,unit, pInd[1800].config_aux_alarms, pi[1800].config_aux_alarms["alarmName"], 
            "Alarm", parms)
          break
      }
      cl(parms)
      sendArray(parms).then(r=>{globs.events.publish("saveOK",true)})
    }
//     cl(cmd)
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "upd":
      case "addVar":
        let p=this.props.parms
//         vals.vars=p[this.varArr[p.pageType]]
        vals.vars=this.varArr[p.pageType]
        this.props.parms.onChange(type,vals)
        break
//       case "addVar":
//         vals.vars=this.varArr[p.pageType]
//         
//         cl("add")
//         break
      default:
        break
    }
  }
  
  saveVariableNames=(varData, zone, unit, vars, ofs, fillIn, parms)=>{// this needs to remove the fillIn
//     this.saveVariableNames(this.state.variables, zone, pInd[1800].config_aux_variables, pi[1800].config_aux_variables["variableName"], 
//       "Variable", parms)
//     this.saveVariableNames(this.state.pVariables, zone, pInd[1800].config_aux_persistent_variables,
//       pi[1800].config_aux_persistent_variables["variableName"], "Persistent Variable", parms)
//     this.saveVariableNames(this.state.alarms, zone, pInd[1800].config_aux_alarms, pi[1800].config_aux_alarms["alarmName"], 
//       "Alarm", parms)

    let vn=varData.slice(0)
    vn.forEach((vd,i)=>{
      if(vd.n==``){
        vn[i].n=null
      }
    })
    let base=vars[0]
    let siz=vars[2]
    let cnt=vars[3]
    let ret=[]
    let t=Math.floor(getTime())
    let z=zone
    let u=unit||0
    cl(`unit: ${u}`)
    for(let i=0;i<cnt;i++){
      let iOfs=base+i*siz
      let pid=iOfs+ofs
      let data=[vn[i].n,vn[i].g,vn[i].c]
      for(let j=0;j<3;j++){
        if(putZValue(z,240+u,pid+j,data[j])){
          parms.push({
            c:240+u,// zone wide - not! unit-specific
            d:data[j],//varNames[i],
            f:1,
            i:pid+j,//iOfs+ofs,
            t:t,
            z:z,
          })
        }
      }
    }
  }
  
  showVarLines=()=>{
//     let vars=this.state.vars
    let p=this.props.parms
//     let vars=this.varArr[p.pageType]
    let vars=p[this.varArr[p.pageType]]
//     cl(vars)
    
    return vars.map((v,i)=>{
      if(v.n||(v.n=="")){
//         cl(v)
        return(
          <tr key={i}>
            <td><input 
              type="text" 
              value={v.n} 
              onChange={e=>this.onChange("upd",{n:e.currentTarget.value, i:i})}
              aria-label="name"/>
            </td>
            <td><input 
              type="checkbox"
              checked={(v.g||0)!=0}
              onChange={e=>this.onChange("upd",{g:(e.currentTarget.checked)?1:0, i:i})}
              aria-label="enable graphing" />
            </td>
            <td><textarea 
              aria-label="comment"
              value={v.c}
              onChange={e=>this.onChange("upd",{c:e.currentTarget.value, i:i})}
              />
            </td>
          </tr>
        )
      }else{
        return null
      }
    })
  }
  
  showVars=()=>{
//     cl(this.props.parms.variables)
//     cl(this.props.parms)
    return(
          <div id="content-area">

              <div className="aux">

                <table className="list"><tbody>
                  <tr>
                    <th>Name</th><th>Enable<br />Graphing</th><th>Comments</th>
                  </tr>
                  {this.showVarLines()}

                </tbody></table>
                <button className="material-icons-outlined add" aria-label="add nutrient"
                  disabled={!this.props.parms.saveOK}
                  onClick={e=>this.onChange("addVar",{})}
                >add</button>
              </div>
          </div>
    )
  }
  
  render(){
    return(
      <div>
      {this.showVars()}
      </div>
    )
  }
}

export default AuxVariables00 ;
