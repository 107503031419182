import React from "react";
// import TextField from "@material-ui/core/TextField";
// import FormControl from "@material-ui/core/FormControl";
import {cl,globs,interpolateColor,normalize} from "../../components/utils/utils";
// import Input from "@material-ui/core/Input";
// import C18MuiTextField from '../../usa/components/C18MuiTextField'
// import C18MuiFormControl from '../../usa/components/C18MuiFormControl'
import C18MuiInput from '../../usa/components/C18MuiInput'
import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
import {ArrayExpression as state} from "@babel/types";
// import InputLabel from "@material-ui/core/InputLabel";

class FUINumerical extends React.Component{
    /**
     * width - width of the temperature box
     * min - Minimum allowed temperature (-200)
     * max - Maximum allowed temperature (200)
     * step - Step per arrow press (0.1)
     * unit - The degree type, assumes given temp is in right units (F)
     * @param props -
     */
    constructor(props) {
        super(props);
//         cl(props)
        this.props.getValue(props.ind, {type: "scalar"})
        this.tempWidth = 100;
        this.unit = "";
        this.min = -5000;
        this.max = 5000;
        this.logMin = 0
        this.logMax = 100
        this.mult=1
        this.step = .1;
        this.decimal = 2;
        if ("custom" in props){
            if ("min" in props.custom && !isNaN(props.custom.min))
                this.min = Number(props.custom.min);
            if ("max" in props.custom && !isNaN(props.custom.max))
                this.max = Number(props.custom.max);
            if ("mult" in props.custom && !isNaN(props.custom.mult))
                this.mult = Number(props.custom.mult);
            if ("step" in props.custom && !isNaN(props.custom.step))
                this.step = Number(props.custom.step);
            if ("unit" in props.custom) 
                this.unit = this.getUnit();
            if ("width" in props.custom && !isNaN(props.custom.width))
                this.tempWidth = Number(props.custom.width);
            if ("decimal" in props.custom && !isNaN(props.custom.decimal))
                this.decimal = Number(props.custom.decimal);
            if ("logMin" in props.custom && !isNaN(props.custom.logMin))
                this.logMin = Number(props.custom.logMin)
            if ("logMax" in props.custom && !isNaN(props.custom.logMax))
                this.logMax = Number(props.custom.logMax)
        }
//         cl(this.mult)
        this.state={
            type: "tel",
            isFocus: false,
        }
        this.handleBlur = this.handleBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onChange = this.onChange.bind(this);
        this.canBlur = false;
        this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
        this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
        this.changed=false
        this.setLogicalLimits()
    }
    
    updateFui=()=>{
      if(!this.changed){this.props.getValue(this.props.ind, {type: "scalar"})}
    }
    
    savePageEvent=()=>{
      this.changed=false
    }

    componentWillUnmount=()=>{
      this.subscribe_updateFui.remove()
      this.subscribe_savePageEvent.remove()
    }
  
    getUnit=()=>{
//       cl(this.props.custom.unit)
//       cl(this.props.current?.units)
        let unitTab = {
            degf: [0, "\u00B0F", "\u00B0C"],
            mph: [1, "mph", "kph"],
            wpm2: [2, "w/m²", "klux", "uMol/m²/s"],
            liter: [3, "L", "gal", "gal"],
            ml: [3, "ml", "oz", "ml"],
            uS: [4, "uS", "CF", "PPM"],
            kPa: [5, "kPa"],
            deg: [6, "\u00B0"],
            kwHRpm2: [2, "kW Hr/m²", "klux Hr", "Mol/m²"],
        }
        let unitType = unitTab[this.props.custom.unit]
        if (unitType === undefined){
//             cl(this.props.custom.unit)
            return this.props.custom.unit;
        }else{
            let unitSel = 1 + ((+(this.props?.current?.units||{})[unitType[0]])||0);
//             cl(unitType[unitSel])
            return unitType[unitSel];
        }
    }

    getColor=(val)=>{
        let startCol = [0, 0, 0, 0]
        let endCol
        let curve = "linear"
        switch (this.props.custom.unit) {
            // blue to red
            case "degf":
                startCol = [85, 112, 235, .7]
                endCol = [235, 85, 85, .7]
                break
            // clear to orange
            case "mph":
                startCol = [212, 165, 121, .01]
                endCol = [212, 165, 121, .7]
                break
            // clear to yellow
            case "wpm2":
                endCol = [212, 206, 121, .01]
                endCol = [212, 206, 121, .7]
                break
            // clear to blue
            case "liter":
                startCol = [108, 147, 184, .01]
                endCol = [108, 147, 184, .7]
                curve = "log"
                break
            // clear to magenta 
            case "ml":
                startCol = [196, 108, 174, .01]
                endCol = [196, 108, 174, .7]
                curve = "log"
                break
            // clear to green 
            case "uS":
                startCol = [130, 196, 108, .01]
                endCol = [130, 196, 108, .7]
                break
            // clear to brown 
            case "kPa":
                startCol = [186, 122, 86, .01]
                endCol = [186, 122, 86, .7]
                break    
            // clear to light blue 
            case "deg":
                startCol = [167, 172, 196, .01]
                endCol = [167, 172, 196, .7]
                break
            default:
                startCol = [167, 172, 196, .01]
                endCol = [167, 172, 196, .7]
                break
        }
        val = Math.max(Math.min(val, this.logMax), this.logMin)
        let normval = normalize((this.logMin) < 0 ? 0 : this.logMin, (this.logMin) < 0 ? this.logMax - this.logMin : this.logMax, 
            (this.logMin) < 0 ?  val - this.logMin : val)
        // cl([val, normval, curve])
        return interpolateColor(startCol, endCol, normval, curve)
    }

    setLogicalLimits=()=>{
        switch (this.unit) {
            case "\u00B0F":
                this.logMin = 50
                this.logMax = 105
                break
            case "\u00B0C":
                this.logMin = 10
                this.logMax = 40.56
                break
            case "mph":
                this.logMin = 5
                this.logMax = 30
                break
            case"kph":
                this.logMin = 3.2
                this.logMax = 24.14
                break
            case "w/m²":
            case "W/m²":
                this.logMin = 500
                this.logMax = 1200
                break
            case "klux": 
                this.logMin = 60
                this.logMax = 144
                break
            case "kW/M² Hr":
            case "uMol":
                this.logMin = 1000
                this.logMax = 2400
                break
            case "L":
                this.logMin = 0
                this.logMax = 100
                break
            case "gal":
                this.logMin = 0
                this.logMax = 26.4
                break
            case "ml":
                this.logMin = 0
                this.logMax = 10
                break
            case "oz":
                this.logMin = 0
                this.logMax = .34
                break
            case "uS":
                this.logMin = 500
                this.logMax = 2000
                break
            case "CF":
                this.logMin = 500
                this.logMax = 2000
                break
            case "PPM":
                this.logMin = 100
                this.logMax = 275
                break
            case "kPa":
                this.logMin = 0.5
                this.logMax = 1.5
                break
            case "\u00B0":
                this.logMin = 20
                this.logMax = 80
                break
            default:
                this.logMin = 0
                this.logMax = 100
        }

    }

    handleBlur = e => {
        if (this.canBlur) {
            this.nameInput.type = "tel"
            // this.setState({
            //     type: "number",
            //     isFocus: false,
            // });
            let tValue = parseFloat(e.target.value);
            if (isNaN(tValue)) {
                tValue = this.min;
              } else {
                  tValue = tValue > this.max ? this.max : tValue; //Ensure it handles max value on blur
                  tValue = tValue < this.min ? this.min : tValue; //Ensure it handles min value on blur
                }
            let obj = {type: "scalar", value: this.mult*tValue};
//             cl(obj)
            this.props.onChange(this.props.ind, obj)

        }
    };

    onFocus = e => {
//       cl("on focus")
        this.canBlur = false;
        this.nameInput.type = "tel"
        this.nameInput.focus()
	    this.nameInput.select()
        // this.setState({
        //     type: "tel",
        //     isFocus: true,
        // });
        
        setTimeout( () => {
            this.canBlur = true;
        },10);
    };

    onChange = e =>{
    //   cl("on change")
    //   cl(e.target.value)
      const value = e.target.value;
      const parsedValue = value === '-' || (typeof value === "string" && value.includes(".")) ? value : parseFloat(value);
      let obj = {type: "scalar", value: parsedValue/**this.mult*/};
    //   cl(obj)
      this.props.onChange(this.props.ind, obj)
    //   cl(obj)
      this.changed=true// blocks updating
    };

    render() {
      var val,type
    //   cl(this.props.value)
      let negative = false
      if (typeof this.props.value === "string" && this.props.value[0] === "-") {
        // cl("negative detected")
        val = this.props.value.split("-")[1] || 0
        negative = true
      } else {
        val = this.props.value
      }
    // //   cl(val)
    //   if (this.step){
    //     let dec=Math.round(Math.log(this.step)/-2.3)
    //     val=+(this.step*Math.round(val/this.step)).toFixed(dec)
    //   }
//       cl(["a",val])
    //  cl(val)
    //  type="number"
//       if(this.state.isFocus){
//         val=this.props.value
//         type="number"
//       }else{
// //         val=`${this.props.value} ${this.unit}`
//         val=this.props.value
// //         type="text"
//         type="number"
//       }
//       cl(this.state)
//         this.props.getValue(this.props.ind, {type: "scalar"})
//       cl(this.state.type)
//                     value={this.state.type === "number" //If input's type is a number
//                         ? this.props.value/this.mult //Display just number but rounded Number(this.props.value).toFixed(this.decimal)
//                         : ( Number(this.props.value/this.mult).toFixed(this.decimal) +" "+this.unit)} //Display text with units
        // assign color based on unit
    //   cl(val)
      let bgColor = this.getColor((negative ? val * -1 : val))
    //   cl(bgColor)
      if(isNaN(val)){val=0}
      if(typeof this.props.value === "string" && (this.props.value[0] === "-" || this.props.value.includes("."))){val=this.props.value}
      let pr=this.props
        return (
            <div id={pr?.custom?.infoId}>
                <C18MuiInputLabel htmlFor="age-simple">{pr.cont.title}</C18MuiInputLabel>
                <C18MuiInput
                    id={`{"cmd":"watch","type":"${pr.type}","id":${pr.cont.pid},"zuci":"${pr.zuci}"}`}
                    title={(this.props?.rest?.parent||(e=>{}))({uri:"pid",pr:pr})}
                    key="temperature"
                    margin="dense"
                    value={val/*pr.value*/}
                    refx={(input) => {this.nameInput = input;}}
                    onChange={this.onChange}
                    onBlur={this.handleBlur}
                    onFocus={this.onFocus}
                    saveOK={pr.saveOK}
                    saved={this.changed}
                    type={this.state.type}
                    unit={this.unit}
                    bgColor={bgColor}
                    inputProps={{
                        step: this.step,
                        min: this.min,
                        max: this.max,
                        inputMode:"text",
                        type: this.state.type,
                        style: {textAlign: 'center'}
                    }}
                    // pattern="[0-9]*"
                />
            </div>
        );
    }
}

export default FUINumerical ;
