import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import C18Button00 from './C18Button00'
import C18TabsHeader00 from './C18TabsHeader00'
import C18SaveCancel00 from './C18SaveCancel00'
import UsaCheckbox00 from './UsaCheckbox00';
import {loadZonesInfo,getZoneInfo, loadSiteData,getSiteName,getZoneName,
  loadSensorsInfo,getSensorsZone,privs,loadCamerasInfo,intToBase64} from './C18utils'
import {wsTrans,getParamId,getChannelsInfo,loadAddedSensors} from '../utils/utils'
import {pi,pInd} from '../../components/utils/paramIds';
import {dbVals,getZValue,getZoneControllers} from '../../components/utils/http';
import {cl,globs,constant} from '../../components/utils/utils';
import history from '../../history'
import {makeSensorList,getSetSensorName,fixSensorList} from '../utils/sensorNames'


class SelectSensors extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
//     cl(globs.userData);
    this.state={
      loaded: false,
      mode: "selection",
      sensorOrder:["inT", "stT", "ouH"],
      lastMoved: null,
      sensorList:makeSensorList(props.parms.adminPage),
    }
//     cl(this.state.sensorList)
//     cl(this.state.sensorList[0].s[0])
//     cl(this.state.sensorList[0].s[0].v)
//     this.defaultNames=[
//       {v:"zoS",t:"Zone Setpoint"},
//       {v:"inT",t:"Inside Temperature"},
//       {v:"inH",t:"Inside Humidity"},
//       {v:"inL",t:"Inside Light"},
//       {v:"inC",t:"Inside CO2"},
//       {v:"vpd",t:"VPD"},
//       {v:"ouT",t:"Outside Temperature"},
//       {v:"ouH",t:"Outside Humidity"},
//       {v:"ouL",t:"Outside Light"},
//       {v:"bpT",t:"Black Plate Temp"},
//       {v:"at0", t:"Analog Temperature 1"},
//       {v:"at1", t:"Analog Temperature 2"},
//       {v:"at2", t:"Analog Temperature 3"},
//       {v:"at3", t:"Analog Temperature 4"},
//       {v:"at4", t:"Analog Temperature 5"},
//       {v:"vp0", t:"Vent Position 1"},
//       {v:"vp1", t:"Vent Position 2"},
//       {v:"vp2", t:"Vent Position 3"},
//       {v:"vp3", t:"Vent Position 4"},
//       {v:"vp4", t:"Vent Position 5"},
//       {v:"sm0", t:"Soil Moisture 1"},
//       {v:"sm1", t:"Soil Moisture 2"},
//       {v:"sm2", t:"Soil Moisture 3"},
//       {v:"sm3", t:"Soil Moisture 4"},
//       {v:"sm4", t:"Soil Moisture 5"},
//       {v:"c00",t:"Channel 1"},
//       {v:"c01",t:"Channel 2"},
//       {v:"c02",t:"Channel 3"},
//       {v:"c03",t:"Channel 4"},
//       {v:"c04",t:"Channel 5"},
//       {v:"c05",t:"Channel 6"},
//       {v:"c06",t:"Channel 7"},
//       {v:"c07",t:"Channel 8"},
//       {v:"c08",t:"Channel 9"},
//       {v:"c09",t:"Channel 10"},
//       {v:"c10",t:"Channel 11"},
//       {v:"c11",t:"Channel 12"},
//       {v:"ec0", t:"EC1"},
//       {v:"ph0", t:"pH1"},
//       {v:"tp0", t:"Temp1"},
//       {v:"ec1", t:"EC2"},
//       {v:"ph1", t:"pH2"},
//       {v:"tp1", t:"Temp2"},
//       {v:"ec2", t:"EC3"},
//       {v:"ph2", t:"pH3"},
//       {v:"tp2", t:"Temp3"},
//       {v:"ec3", t:"EC4"},
//       {v:"ph3", t:"pH4"},
//       {v:"tp3", t:"Temp4"},
//       {v:"ec4", t:"EC5"},
//       {v:"ph4", t:"pH5"},
//       {v:"tp4", t:"Temp5"},
//       {v:"ec5", t:"EC6"},
//       {v:"ph5", t:"pH6"},
//       {v:"tp5", t:"Temp6"},
//       {v:"ec6", t:"EC7"},
//       {v:"ph6", t:"pH7"},
//       {v:"tp6", t:"Temp7"},
//       {v:"ec7", t:"EC8"},
//       {v:"ph7", t:"pH8"},
//       {v:"tp7", t:"Temp8"},
//     ]
//     cl(this.state.sensorList)
    this.sensorIds={}
    this.state.checks={}
    this.loadSensors()
//     cl(this.props)
    this.saveOK=privs("zone",this.props.parms.zone,constant.AREA_PRIVS_WRITE)
    if(this.saveOK){
      this.props.parms.onChange({cmd: "savePage", data:{savePage: true}})//,saveEnable:false
    }
//     props.parms.onChange({cmd: "savePage", data:{savePage: true,saveEnable:false}})
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveCancel)
//     this.getSetSensorName("inT","not In Temp",true)
    this.subscribe_renameSensorEvent=globs.events.subscribe("renameSensor",this.renameSensor)
    this.subscribe_keyUp=globs.events.subscribe("keyUp",this.keyUp)
  }

//   makeSensorList=(adminPage)=>{
//     let sensorList={
//       selectSensors:[
//         {v:"INS",t:"Inside Sensors 1800",l:0,o:0,s:[
//           {v:"zoS",t:"Zone Setpoint",p:-1},
//           {v:"inT",t:"Inside Temperature",p:-1},
//           {v:"inH",t:"Inside Humidity",p:-1},
//           {v:"inL",t:"Inside Light",p:-1},
//           {v:"inC",t:"Inside CO2",p:-1},
//           {v:"vpd",t:"VPD",p:-1},
//
//           {v:"stT",t:"Temperature Stage",p:-1},
//           {v:"stH",t:"Humidity Stage",p:-1},
//           {v:"spH",t:"Low Temperature Setpoint",p:-1},
//           {v:"spC",t:"High Temperature Setpoint",p:-1},
//           {v:"spU",t:"Low Humidity Setpoint",p:-1},
//           {v:"spD",t:"High Humidity Setpoint",p:-1},
//           {v:"alL",t:"Low Alarm Relay",p:-1},
//           {v:"alH",t:"High Alarm Relay",p:-1},
//         ]},
//         {v:"OTS",t:"Outside Sensors",l:0,o:0,s:[
//           {v:"ouT",t:"Outside Temperature",p:-1},
//           {v:"ouH",t:"Outside Humidity",p:-1},
//           {v:"ouL",t:"Outside Light",p:-1},
//           {v:"bpT",t:"Black Plate Temp",p:-1},
//
//           {v:"oWs",t:"Wind Speed",p:-1},
//           {v:"oWd",t:"Wind Direction",p:-1},
//           {v:"dPr",t:"Differential Pressure",p:-1},
//           {v:"bPr",t:"Barometric Pressure",p:-1},
//           {v:"ran",t:"Rain",p:-1},
//           {v:"sno",t:"Snow",p:-1},
//         ]},
//         {v:"GNS",t:"Generic Sensors",l:0,o:0,s:[
//           {v:"gn0",t:"Generic Sensor 1",l:1,p:-1},
//           {v:"gn1",t:"Generic Sensor 2",l:1,p:-1},
//           {v:"gn2",t:"Generic Sensor 3",l:1,p:-1},
//           {v:"gn3",t:"Generic Sensor 4",l:1,p:-1},
//           {v:"gn4",t:"Generic Sensor 5",l:1,p:-1},
//           {v:"gn5",t:"Generic Sensor 6",l:1,p:-1},
//           {v:"gn6",t:"Generic Sensor 7",l:1,p:-1},
//           {v:"gn7",t:"Generic Sensor 8",l:1,p:-1},
//           {v:"gn8",t:"Generic Sensor 9",l:1,p:-1},
//           {v:"gn9",t:"Generic Sensor 10",l:1,p:-1},
//           {v:"gn10",t:"Generic Sensor 11",l:1,p:-1},
//           {v:"gn11",t:"Generic Sensor 12",l:1,p:-1},
//           {v:"gn12",t:"Generic Sensor 13",l:1,p:-1},
//           {v:"gn13",t:"Generic Sensor 14",l:1,p:-1},
//           {v:"gn14",t:"Generic Sensor 15",l:1,p:-1},
//           {v:"gn15",t:"Generic Sensor 16",l:1,p:-1},
//           {v:"gn16",t:"Generic Sensor 17",l:1,p:-1},
//           {v:"gn17",t:"Generic Sensor 18",l:1,p:-1},
//           {v:"gn18",t:"Generic Sensor 19",l:1,p:-1},
//           {v:"gn19",t:"Generic Sensor 20",l:1,p:-1},
//           {v:"gn20",t:"Generic Sensor 21",l:1,p:-1},
//           {v:"gn21",t:"Generic Sensor 22",l:1,p:-1},
//           {v:"gn22",t:"Generic Sensor 23",l:1,p:-1},
//           {v:"gn23",t:"Generic Sensor 24",l:1,p:-1},
//           {v:"gn24",t:"Generic Sensor 25",l:1,p:-1},
//           {v:"gn25",t:"Generic Sensor 26",l:1,p:-1},
//           {v:"gn26",t:"Generic Sensor 27",l:1,p:-1},
//           {v:"gn27",t:"Generic Sensor 28",l:1,p:-1},
//           {v:"gn28",t:"Generic Sensor 29",l:1,p:-1},
//           {v:"gn29",t:"Generic Sensor 30",l:1,p:-1},
//           {v:"gn30",t:"Generic Sensor 31",l:1,p:-1},
//           {v:"gn31",t:"Generic Sensor 32",l:1,p:-1},
//           {v:"gn32",t:"Generic Sensor 33",l:1,p:-1},
//           {v:"gn33",t:"Generic Sensor 34",l:1,p:-1},
//           {v:"gn34",t:"Generic Sensor 35",l:1,p:-1},
//           {v:"gn35",t:"Generic Sensor 36",l:1,p:-1},
//           {v:"gn36",t:"Generic Sensor 37",l:1,p:-1},
//           {v:"gn37",t:"Generic Sensor 38",l:1,p:-1},
//           {v:"gn38",t:"Generic Sensor 39",l:1,p:-1},
//           {v:"gn39",t:"Generic Sensor 40",l:1,p:-1},
//           {v:"gn40",t:"Generic Sensor 41",l:1,p:-1},
//           {v:"gn41",t:"Generic Sensor 42",l:1,p:-1},
//           {v:"gn42",t:"Generic Sensor 43",l:1,p:-1},
//           {v:"gn43",t:"Generic Sensor 44",l:1,p:-1},
//           {v:"gn44",t:"Generic Sensor 45",l:1,p:-1},
//           {v:"gn45",t:"Generic Sensor 46",l:1,p:-1},
//           {v:"gn46",t:"Generic Sensor 47",l:1,p:-1},
//           {v:"gn47",t:"Generic Sensor 48",l:1,p:-1},
//           {v:"gn48",t:"Generic Sensor 49",l:1,p:-1},
//           {v:"gn49",t:"Generic Sensor 50",l:1,p:-1},
//           {v:"gn50",t:"Generic Sensor 51",l:1,p:-1},
//           {v:"gn51",t:"Generic Sensor 52",l:1,p:-1},
//           {v:"gn52",t:"Generic Sensor 53",l:1,p:-1},
//           {v:"gn53",t:"Generic Sensor 54",l:1,p:-1},
//           {v:"gn54",t:"Generic Sensor 55",l:1,p:-1},
//           {v:"gn55",t:"Generic Sensor 56",l:1,p:-1},
//           {v:"gn56",t:"Generic Sensor 57",l:1,p:-1},
//           {v:"gn57",t:"Generic Sensor 58",l:1,p:-1},
//           {v:"gn58",t:"Generic Sensor 59",l:1,p:-1},
//           {v:"gn59",t:"Generic Sensor 60",l:1,p:-1},
//           {v:"gn60",t:"Generic Sensor 61",l:1,p:-1},
//           {v:"gn61",t:"Generic Sensor 62",l:1,p:-1},
//           {v:"gn62",t:"Generic Sensor 63",l:1,p:-1},
//           {v:"gn63",t:"Generic Sensor 64",l:1,p:-1},
//           {v:"gn64",t:"Generic Sensor 65",l:1,p:-1},
//           {v:"gn65",t:"Generic Sensor 66",l:1,p:-1},
//           {v:"gn66",t:"Generic Sensor 67",l:1,p:-1},
//           {v:"gn67",t:"Generic Sensor 68",l:1,p:-1},
//           {v:"gn68",t:"Generic Sensor 69",l:1,p:-1},
//           {v:"gn69",t:"Generic Sensor 70",l:1,p:-1},
//           {v:"gn70",t:"Generic Sensor 71",l:1,p:-1},
//           {v:"gn71",t:"Generic Sensor 72",l:1,p:-1},
//           {v:"gn72",t:"Generic Sensor 73",l:1,p:-1},
//           {v:"gn73",t:"Generic Sensor 74",l:1,p:-1},
//           {v:"gn74",t:"Generic Sensor 75",l:1,p:-1},
//           {v:"gn75",t:"Generic Sensor 76",l:1,p:-1},
//           {v:"gn76",t:"Generic Sensor 77",l:1,p:-1},
//           {v:"gn77",t:"Generic Sensor 78",l:1,p:-1},
//           {v:"gn78",t:"Generic Sensor 79",l:1,p:-1},
//           {v:"gn79",t:"Generic Sensor 80",l:1,p:-1},
//           {v:"gn80",t:"Generic Sensor 81",l:1,p:-1},
//           {v:"gn81",t:"Generic Sensor 82",l:1,p:-1},
//           {v:"gn82",t:"Generic Sensor 83",l:1,p:-1},
//           {v:"gn83",t:"Generic Sensor 84",l:1,p:-1},
//           {v:"gn84",t:"Generic Sensor 85",l:1,p:-1},
//           {v:"gn85",t:"Generic Sensor 86",l:1,p:-1},
//           {v:"gn86",t:"Generic Sensor 87",l:1,p:-1},
//           {v:"gn87",t:"Generic Sensor 88",l:1,p:-1},
//           {v:"gn88",t:"Generic Sensor 89",l:1,p:-1},
//           {v:"gn89",t:"Generic Sensor 90",l:1,p:-1},
//           {v:"gn90",t:"Generic Sensor 91",l:1,p:-1},
//           {v:"gn91",t:"Generic Sensor 92",l:1,p:-1},
//           {v:"gn92",t:"Generic Sensor 93",l:1,p:-1},
//           {v:"gn93",t:"Generic Sensor 94",l:1,p:-1},
//           {v:"gn94",t:"Generic Sensor 95",l:1,p:-1},
//           {v:"gn95",t:"Generic Sensor 96",l:1,p:-1},
//           {v:"gn96",t:"Generic Sensor 97",l:1,p:-1},
//           {v:"gn97",t:"Generic Sensor 98",l:1,p:-1},
//           {v:"gn98",t:"Generic Sensor 99",l:1,p:-1},
//           {v:"gn99",t:"Generic Sensor 100",l:1,p:-1},
//           {v:"gn100",t:"Generic Sensor 101",l:1,p:-1},
//           {v:"gn101",t:"Generic Sensor 102",l:1,p:-1},
//           {v:"gn102",t:"Generic Sensor 103",l:1,p:-1},
//           {v:"gn103",t:"Generic Sensor 104",l:1,p:-1},
//           {v:"gn104",t:"Generic Sensor 105",l:1,p:-1},
//           {v:"gn105",t:"Generic Sensor 106",l:1,p:-1},
//           {v:"gn106",t:"Generic Sensor 107",l:1,p:-1},
//           {v:"gn107",t:"Generic Sensor 108",l:1,p:-1},
//           {v:"gn108",t:"Generic Sensor 109",l:1,p:-1},
//           {v:"gn109",t:"Generic Sensor 110",l:1,p:-1},
//           {v:"gn110",t:"Generic Sensor 111",l:1,p:-1},
//           {v:"gn111",t:"Generic Sensor 112",l:1,p:-1},
//           {v:"gn112",t:"Generic Sensor 113",l:1,p:-1},
//           {v:"gn113",t:"Generic Sensor 114",l:1,p:-1},
//           {v:"gn114",t:"Generic Sensor 115",l:1,p:-1},
//           {v:"gn115",t:"Generic Sensor 116",l:1,p:-1},
//           {v:"gn116",t:"Generic Sensor 117",l:1,p:-1},
//           {v:"gn117",t:"Generic Sensor 118",l:1,p:-1},
//           {v:"gn118",t:"Generic Sensor 119",l:1,p:-1},
//           {v:"gn119",t:"Generic Sensor 120",l:1,p:-1},
//           {v:"gn120",t:"Generic Sensor 121",l:1,p:-1},
//           {v:"gn121",t:"Generic Sensor 122",l:1,p:-1},
//           {v:"gn122",t:"Generic Sensor 123",l:1,p:-1},
//           {v:"gn123",t:"Generic Sensor 124",l:1,p:-1},
//           {v:"gn124",t:"Generic Sensor 125",l:1,p:-1},
//           {v:"gn125",t:"Generic Sensor 126",l:1,p:-1},
//           {v:"gn126",t:"Generic Sensor 127",l:1,p:-1},
//           {v:"gn127",t:"Generic Sensor 128",l:1,p:-1},
//         ]},
//
//         {v:"ANS",t:"Analog Sensors",l:0,o:0, s:
//           [
//             {v:"ANT",t:"Analog Temperatures",l:1,o:0, s:
//               [
//                 {v:"at0", t:"Analog Temperature 1",l: 1, p:-1},
//                 {v:"at1", t:"Analog Temperature 2",l: 1, p:-1},
//                 {v:"at2", t:"Analog Temperature 3",l: 1, p:-1},
//                 {v:"at3", t:"Analog Temperature 4",l: 1, p:-1},
//                 {v:"at4", t:"Analog Temperature 5",l: 1, p:-1},
//
//                 {v:"at5", t:"Analog Temperature 6",l: 1, p:-1},
//                 {v:"at6", t:"Analog Temperature 7",l: 1, p:-1},
//                 {v:"at7", t:"Analog Temperature 8",l: 1, p:-1},
//                 {v:"at8", t:"Analog Temperature 9",l: 1, p:-1},
//                 {v:"at9", t:"Analog Temperature 10",l: 1, p:-1},
//                 {v:"at10", t:"Analog Temperature 11",l: 1, p:-1},
//                 {v:"at11", t:"Analog Temperature 12",l: 1, p:-1},
//                 {v:"at12", t:"Analog Temperature 13",l: 1, p:-1},
//                 {v:"at13", t:"Analog Temperature 14",l: 1, p:-1},
//                 {v:"at14", t:"Analog Temperature 15",l: 1, p:-1},
//                 {v:"at15", t:"Analog Temperature 16",l: 1, p:-1},
//                 {v:"at16", t:"Analog Temperature 17",l: 1, p:-1},
//                 {v:"at17", t:"Analog Temperature 18",l: 1, p:-1},
//                 {v:"at18", t:"Analog Temperature 19",l: 1, p:-1},
//                 {v:"at19", t:"Analog Temperature 20",l: 1, p:-1},
//                 {v:"at20", t:"Analog Temperature 21",l: 1, p:-1},
//                 {v:"at21", t:"Analog Temperature 22",l: 1, p:-1},
//                 {v:"at22", t:"Analog Temperature 23",l: 1, p:-1},
//                 {v:"at23", t:"Analog Temperature 24",l: 1, p:-1},
//               ]
//             },
//             {v:"AVO",t:"Voltage",l:1,o:0, s:
//               [
//                 {v:"vo0", t:"Voltage 1",l: 1, p:-1},
//                 {v:"vo1", t:"Voltage 2",l: 1, p:-1},
//                 {v:"vo2", t:"Voltage 3",l: 1, p:-1},
//                 {v:"vo3", t:"Voltage 4",l: 1, p:-1},
//                 {v:"vo4", t:"Voltage 5",l: 1, p:-1},
//                 {v:"vo5", t:"Voltage 6",l: 1, p:-1},
//                 {v:"vo6", t:"Voltage 7",l: 1, p:-1},
//                 {v:"vo7", t:"Voltage 8",l: 1, p:-1},
//               ]
//             },
//             {v:"DVO",t:"Digital Voltage",l:1,o:0, s:
//               [
//                 {v:"dv0", t:"DigVolt 1",l: 1, p:-1},
//                 {v:"dv1", t:"DigVolt 2",l: 1, p:-1},
//                 {v:"dv2", t:"DigVolt 3",l: 1, p:-1},
//                 {v:"dv3", t:"DigVolt 4",l: 1, p:-1},
//                 {v:"dv4", t:"DigVolt 5",l: 1, p:-1},
//                 {v:"dv5", t:"DigVolt 6",l: 1, p:-1},
//                 {v:"dv6", t:"DigVolt 7",l: 1, p:-1},
//                 {v:"dv7", t:"DigVolt 8",l: 1, p:-1},
//               ]
//             },
//             {v:"AVP",t:"Vent Position",l:1,o:0, s:
//               [
//                 {v:"vp0", t:"Vent Position 1",l: 1, p:-1},
//                 {v:"vp1", t:"Vent Position 2",l: 1, p:-1},
//                 {v:"vp2", t:"Vent Position 3",l: 1, p:-1},
//                 {v:"vp3", t:"Vent Position 4",l: 1, p:-1},
//                 {v:"vp4", t:"Vent Position 5",l: 1, p:-1},
//               ]
//             },
//             {v:"ASM",t:"Soil Moisture",l:1,o:0, s:
//               [
//                 {v:"sm0", t:"Soil Moisture 1",l: 1, p:-1},
//                 {v:"sm1", t:"Soil Moisture 2",l: 1, p:-1},
//                 {v:"sm2", t:"Soil Moisture 3",l: 1, p:-1},
//                 {v:"sm3", t:"Soil Moisture 4",l: 1, p:-1},
//                 {v:"sm4", t:"Soil Moisture 5",l: 1, p:-1},
//               ]
//             },
//           ]
//         },
//         {v:"ADS",t:"Added Sensors",l:0,o:0,s:[
//           {v:"zoS",t:"Zone Setpoint",p:-1},
//         ]},
// //         {v:"AXC",t:"Auxiliary Values",l:0,o:0, s:
// //           [
// //             {v:"AXV",t:"Aux Variables",l:1,o:0, s:
// //               [
// //                 {v:"at0", t:"Analog Temperature 1",l: 1, p:-1},
// //               ]
// //             },
// //             {v:"AXP",t:"Aux Persistent Variables",l:1,o:0, s:
// //               [
// //                 {v:"vp0", t:"Vent Position 1",l: 1, p:-1},
// //               ]
// //             },
// //             {v:"AXA",t:"Aux Alarms",l:1,o:0, s:
// //               [
// //                 {v:"sm0", t:"Soil Moisture 1",l: 1, p:-1},
// //               ]
// //             },
// //           ]
// //         },
// //         {v:"STG",t:"Stages",l:0,o:0,s:[
// //           {v:"stT",t:"Temperature Stage",p:-1},
// //           {v:"stH",t:"Humidity Stage",p:-1},
// //         ]},
// //         {v:"STP",t:"Setpoints",l:0,o:0,s:[
// //           {v:"spH",t:"Heat Setpoint",p:-1},
// //           {v:"spC",t:"Cool Setpoint",p:-1},
// //           {v:"spU",t:"Humidify Setpoint",p:-1},
// //           {v:"spD",t:"DeHum Setpoint",p:-1},
// //         ]},
// //         {v:"HLA",t:"High / Low Alarms",l:0,o:0,s:[
// //           {v:"alH",t:"High Alarm",p:-1},
// //           {v:"alL",t:"Low Alarm",p:-1},
// //         ]},
//         {v:"CHP",t:"Channel Position",l:0,o:0,s:[
//           {v:"c00",t:"Channel 1",p:-1},
//           {v:"c01",t:"Channel 2",p:-1},
//           {v:"c02",t:"Channel 3",p:-1},
//           {v:"c03",t:"Channel 4",p:-1},
//           {v:"c04",t:"Channel 5",p:-1},
//           {v:"c05",t:"Channel 6",p:-1},
//           {v:"c06",t:"Channel 7",p:-1},
//           {v:"c07",t:"Channel 8",p:-1},
//           {v:"c08",t:"Channel 9",p:-1},
//           {v:"c09",t:"Channel 10",p:-1},
//           {v:"c10",t:"Channel 11",p:-1},
//           {v:"c11",t:"Channel 12",p:-1},
//         ]},
//         {v:"MXT",t:"Mixing Tanks",l:0,o:0, s:
//           [
//             {v:"MX0",t:"Mixing Tank 1",l:1,o:0, s:
//               [
//                 {v:"ec0", t:"EC",l: 1, p:-1},
//                 {v:"ph0", t:"pH", l: 1, p:-1},
//                 {v:"tp0", t:"Temp", l: 1, p:-1},
//               ]
//             },
//             {v:"MX1",t:"Mixing Tank 2",l:1,o:0, s:
//               [
//                 {v:"ec1", t:"EC", l: 1, p:-1},
//                 {v:"ph1", t:"pH", l: 1, p:-1},
//                 {v:"tp1", t:"Temp", l: 1, p:-1},
//               ]
//             },
//             {v:"MX2",t:"Mixing Tank 3",l:1,o:0, s:
//               [
//                 {v:"ec2", t:"EC", l: 1, p:-1},
//                 {v:"ph2", t:"pH", l: 1, p:-1},
//                 {v:"tp2", t:"Temp", l: 1, p:-1},
//               ]
//             },
//             {v:"MX3",t:"Mixing Tank 4",l:1,o:0, s:
//               [
//                 {v:"ec3", t:"EC", l: 1, p:-1},
//                 {v:"ph3", t:"pH", l: 1, p:-1},
//                 {v:"tp3", t:"Temp", l: 1, p:-1},
//               ]
//             },
//             {v:"MX4",t:"Mixing Tank 5",l:1,o:0, s:
//               [
//                 {v:"ec4", t:"EC", l: 1, p:-1},
//                 {v:"ph4", t:"pH", l: 1, p:-1},
//                 {v:"tp4", t:"Temp", l: 1, p:-1},
//               ]
//             },
//             {v:"MX5",t:"Mixing Tank 6",l:1,o:0, s:
//               [
//                 {v:"ec5", t:"EC", l: 1, p:-1},
//                 {v:"ph5", t:"pH", l: 1, p:-1},
//                 {v:"tp5", t:"Temp", l: 1, p:-1},
//               ]
//             },
//             {v:"MX6",t:"Mixing Tank 7",l:1,o:0, s:
//               [
//                 {v:"ec6", t:"EC", l: 1, p:-1},
//                 {v:"ph6", t:"pH", l: 1, p:-1},
//                 {v:"tp6", t:"Temp", l: 1, p:-1},
//               ]
//             },
//             {v:"MX7",t:"Mixing Tank 8",l:1,o:0, s:
//               [
//                 {v:"ec7", t:"EC", l: 1, p:-1},
//                 {v:"ph7", t:"pH", l: 1, p:-1},
//                 {v:"tp7", t:"Temp", l: 1, p:-1},
//               ]
//             },
//           ]
//         },
//         {v:"CAM",t:"Cameras",l:0,o:0,s:[
//           {v:"a00",t:"Camera 1",p:-1},
//         ]},
//         {v:"MOD",t:"Modbus Devices",l:0,o:0,s:[
//           {v:"mb00",t:"Modbus 1",p:-1},
//         ]},
//
//       ],
//
//       selectSensors800:[
//         {v:"INS",t:"Inside Sensors 1800",l:0,o:0,s:[
//           {v:"zoS",t:"Zone Setpoint",p:-1},
//           {v:"inT",t:"Inside Temperature",p:-1},
//           {v:"inH",t:"Inside Humidity",p:-1},
//           {v:"inL",t:"Inside Light",p:-1},
//           {v:"inC",t:"Inside CO2",p:-1},
//         ]},
//         {v:"OTS",t:"Outside Sensors",l:0,o:0,s:[
//           {v:"ouT",t:"Outside Temperature",p:-1},
//           {v:"ouL",t:"Outside Light",p:-1},
//           {v:"oWs",t:"Wind Speed",p:-1},
//           {v:"oWd",t:"Wind Direction",p:-1},
//           {v:"ran",t:"Rain",p:-1},
//         ]},
//       ],
//     }
//     return sensorList[adminPage]
//   }
//
//   procSensorName=(se0,id,name,set,arr)=>{
// //     cl(se0)
// //     cl(arr)
//     if(se0.s){
//       se0.s.forEach(se=>{
//         return this.procSensorName(se,id,name,set,arr)
//       })
//     }else{
// //       cl(se0)
// //       cl(arr[se0.v])
//       if(set){
//         if(arr[se0.v]){se0.t=arr[se0.v]}
//       }else{
//         arr[se0.v]=se0.t
//       }
//     }
//   }
//
//   getSetSensorName=(id,name,set,arr,sl)=>{
// //     cl(id,name)
//     if(!arr){return}
// //     cl(arr)
// //     let sl=this.state.sensorList
// //     cl(sl)
//     sl.forEach(se0=>{
//       this.procSensorName(se0,id,name,set,arr)
//     })
//   }
//
  renameSensor=(cmd)=>{
    cl(cmd)
    this.setState({editName:cmd.type})
  }
  
  keyUp=(e)=>{
    let key=e.key
//     if((this.state.saveEnable)&&(key=="Enter")){this.onClick("save")}
    if((this.state.editName)&&(["Escape","Enter"].includes(key))){
      cl(key)
      this.setState({editName:""})
    }
  }
  
  setBreadcrumbs=()=>{
//     cl(this.props)
    let zoneName=getZoneName(this.props.parms.zone)
    let siteName=getSiteName(this.props.parms.site)
    let siteId=this.props.parms.site
    let zoneId=this.props.parms.zone
    let zoneBCI=[
      {t:"Sites", url:"/usa/c18/sites"},
      {t:siteName, url:`/usa/c18/sites/${siteId}`},
      {t:zoneName, url:`/usa/c18/sites/${siteId}/zones/${zoneId}`},
      {t:"Sensor Settings", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/sensor1800`},
      {t:"Select Sensors", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/sensor1800`},
    ]
    this.props.parms.onChange(
      {
        cmd: "breadcrumbs",
        data:
          {breadcrumbs: zoneBCI},
      },
    )
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
    this.subscribe_renameSensorEvent.remove()
    this.subscribe_keyUp.remove()
  }

  saveCancel=async(cmd)=>{
//     cl(cmd)
    let order=this.state.sensorOrder
//     cl(order)
    if(cmd=="save"){
      if(this.state.mode=="selection"){
        order=this.makeNewOrderSensors()
      }
      await this.saveSensors(order)
      await this.saveSensorNames()
      globs.events.publish("saveOK",true)
      this.setState({editName:""})
    }
//     let url=`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`
//     history.push(url)
  }
  
//   getSensorListValue=(list, val)=>{
//     for(let i=0;i<list.length;i++){
//       if(list[i].v==val){return list[i].s}
//     }
//   }
//   
//   insertVar=(list,headArr,val)=>{
//     headArr.forEach(h=>{
//       list=this.getSensorListValue(list,h)
//     })
//     list.push(val)
// //     cl(list)
// //     cl(this.state.sensorList[3])
//   }
//   
//   loadAuxVars=async()=>{
//     await loadSiteData(this.props.parms.site)
// //     cl(this.props.parms.site)
//     let auxVarNameId=getParamId("configuration_aux_variables","variableName")//64
//     let auxVarTab=pInd[1800].config_aux_variables// base, type, size, count
//     let auxPerVarNameId=getParamId("configuration_aux_persistent_variables","variableName")//32
//     let auxPerVarTab=pInd[1800].config_aux_persistent_variables
//     let auxAlarmNameId=getParamId("configuration_aux_alarms","alarmName")//32
//     let auxAlarmTab=pInd[1800].config_aux_alarms
//     let zInd=this.zone.siteZoneIndex
// //     cl(zInd)
// //     cl(dbVals)
// //     cl(dbVals.z[zInd])
//     cl(this.state.sensorList)
//     let list=this.state.sensorList.slice(0)
//     for(let i=0;i<auxVarTab[3];i++){
//       let id=auxVarNameId+i*auxVarTab[2]
// //       cl(id)
//       let name=dbVals.z[zInd][240][id]
//       if(name){
//         cl(name)
// //         cl(i)
//         this.insertVar(list,["AXC","AXV"],{v:`av${i}`,t:name,p:-1})
//       }
//     }
//     cl(list)
//     this.setState({sensorList: list})
//   }
//   
  makeCurrentMixTanks=(zInd)=>{// from LiveFud
    let tab = "config_ecph"
    let col = "name"
    let base = pInd[1800][tab][0];
    let coef = pInd[1800][tab][2];
    let count = pInd[1800][tab][3];
    // cl(base, coef, count)
//     cl(this.props.parms)
    
//     let z = this.zone ;
    let c = 192 ;
    let ofs = pi[1800][tab][col];
    let pid = base + ofs;
    let ret = [];
    for (let i = 0 ; i < count ; i ++){
//       cl(zInd,c,pid)
      ret.push(getZValue(zInd, c + i, pid))
      // cl([z, c+i, pid])
    }
    return ret
  }
  
  addTankNames=(tankNames)=>{
    let sensorList=this.state.sensorList.slice(0)
//     cl(sensorList)
//     cl(sensorList)
    for(let i=0;i<sensorList.length;i++){
      if(sensorList[i].v=="MXT"){
        let mxt=sensorList[i]
//         cl(sensorList[i])
        for(let j=0;j<8;j++){
          mxt.s[j].t=tankNames[j]||`Mixing Tank ${j+1}`
        }
      }
    }
    return sensorList
    
  }
  
//   getZoneControllers=(zInd)=>{
//     cl(zInd)
//     let tab="config_controllers"
//     let col="isInNetwork"
//     let base = pInd[1800][tab][0];
//     let pid = base+pi[1800][tab][col];
//     let conts=[]
//     for (let i=0;i<4;i++){
//       conts.push(getZValue(zInd, 240 + i, pid)||0)
//     }
//     return conts
// //     cl(conts)
//   }
  
  fixSensorOrder=(zone)=>{// temp, to update sensor ids for expansion controllers
    if(!zone.sensorOrder){return}
    zone.sensorOrder.forEach((so,i)=>{
      if(!["e0","e1","e2","e3","ca","MB","ST"].includes(so.substring(0,2))){
        zone.sensorOrder[i]=`e0${so}`
      }
    })
  }
  
//   fixSensorList=(sensorList)=>{// update this.state.sensorList to handle expansion controllers
//     sensorList.forEach(sl=>{
//       if(sl.s){
//         this.fixSensorList(sl.s)
//       }else{
//         for(let i=1;i<4;i++){
//           if(this.zoneControllers[i]){
//             let se=Object.assign({},sl)
//             se.v=`e${i}${se.v}`
//             se.t=`E${i}-${se.t}`
//             sensorList.push(se)
//           }
//         }
//         sl.v=`e0${sl.v}`
//       }
//     })
//   }
  
  addChannelNames=(sensorNames,ci)=>{
//     cl(sensorNames)
//     cl(ci)
//     cl(this.state.sensorList)
    ci.forEach(c=>{
      let exp=Math.floor(c.index/40)// expansion unit
      let ind=`000${c.index%40}`.slice(-2)
      let id=`e${exp}c${ind}`
      sensorNames[id]=c.name
    })
// SensorList:
//     19: {v: 'e1c07', t: 'E1-Channel 8', p: -1}
  }
  
  addAddedSensors=(addSensors,sensorList,sensorNames)=>{
    let asNames={temp:"Temperature",hum:"Humidity"}
    for(let i=0;i<sensorList.length;i++){
      if(sensorList[i].v=="ADS"){
        if(!addSensors.length){
          sensorList.splice(i,1)// remove the ADS entry
        }else{
          let addS=sensorList[i]
          addS.s=[]
          addSensors.forEach(s=>{
            let sId=`e0as${s.ch}`
            addS.s.push({
              v:sId,//s.type,
              t:sensorNames[sId]||`AS ${asNames[s.type]}`,//s.unit,
              p:-1
            })
//             cl(this.sensorIds[`e0as${s.ch}`])
//             cl(s)
          })
//           cl(addS)
          
        }
        return
      }
    }
  }

  addCameras=(cams,sensorList,sensorNames)=>{
//     cl(cams)
//     let asNames={temp:"Temperature",hum:"Humidity"}
    for(let i=0;i<sensorList.length;i++){
      if(sensorList[i].v=="CAM"){
        if(!cams.length){
          sensorList.splice(i,1)// remove the ADS entry
        }else{
          let camList=sensorList[i]
          camList.s=[]
          cams.forEach(c=>{
            let id=`cam_${c.cameraId}`
            camList.s.push({
              v:id,//s.type,
              t:c.name,
              p:-1
            })
          })
        }
        return
      }
    }
  }
  
  getMBDevices=async(zone)=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusDevices", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:zone.gatewayId}})
    let mbDevices=res.data
    let deviceTypes=mbDevices.filter(mb=>{return mb.zone==zone.siteZoneIndex}).map(mbm=>{return +mbm.typeId})
//     cl(zone)
//     cl(mbDevices)
//     cl(deviceTypes)
    res=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusTypes", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {typeId:{$in:deviceTypes}}})
    let mbTypes=res.data
//     cl(mbTypes)
    res=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusRegisters", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {typeId:{$in:deviceTypes}}})
    let mbRegisters=res.data
//     cl(mbRegisters)
    let ret=[]
//     cl(mbDevices)
    mbDevices.forEach(mbd=>{
      mbRegisters.forEach(re=>{
        if((re.type&0xFF00)!=0){// not "Just View"
          if(re.typeId==mbd.typeId){
//             cl(re)
            let addr=(zone.siteZoneIndex<<24)|(mbd.addr<<16)|(re.addr)
            let id=`MB${intToBase64(addr)}`
            ret.push({
              name:`${mbd.name}-${re.name}`,
              id:id,
            })
          }
        }
      })
    })
    return ret
  }
  
  addModbus=(mbDevices,sensorList,sensorNames)=>{
    for(let i=0;i<sensorList.length;i++){
      if(sensorList[i].v=="MOD"){
        if(!mbDevices.length){
          sensorList.splice(i,1)// remove the ADS entry
        }else{
          let mbList=sensorList[i]
          mbList.s=[]
          mbDevices.forEach(c=>{
            mbList.s.push({
              v:c.id,//s.type,
              t:c.name,
              p:-1
            })
          })
        }
        return
      }
    }
    
  }
  
  addHeaStations=async()=>{
    let pa=this.props.parms
//     cl(this.props)
    let zi=globs.zonesInfo.info.filter(z=>{return z.zoneId==pa.zone})[0]
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/stations", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:zi.gatewayId}})
    let stations=res.data
    return stations.map(st=>{
      let id=`ST${st.stationId}`
      return{
        name:st.name,
        id:id,
      }
    })
  }

  loadSensors=async()=>{
    let pa=this.props.parms
//     cl(pa)
//     cl(pa.zone)
    await loadZonesInfo()
    await loadSensorsInfo()
//     cl(globs.sensorsInfo.info)
    await loadCamerasInfo()
//     cl(globs.camerasInfo.info)
//     cl(pa)
//     cl(globs.sensorsInfo)
    await loadSiteData(pa.site)
    
    this.zone=getZoneInfo(pa.zone)
//     cl(this.state.sensorList)
    this.fixSensorOrder(this.zone)
    let zi=getZoneInfo(pa.zone)
    let zInd=zi.siteZoneIndex
    let addSensors= loadAddedSensors(zInd)
//     cl(this.state.sensorList)
    this.zoneControllers=getZoneControllers(zInd)
    let ci=getChannelsInfo(zInd,this.zoneControllers)
//     cl(ci)
//     cl(this.state.sensorList)
    fixSensorList(this.zoneControllers,this.state.sensorList)

//     cl(this.state.sensorList)
    
    let sensorNames=(getSensorsZone(pa.zone)||{}).sensorNames
//     cl(sensorNames)
//     cl(this.state.sensorList)
    if(sensorNames){
//       cl(sensorNames)
      this.addChannelNames(sensorNames,ci)
      getSetSensorName("","",true,sensorNames,this.state.sensorList)
    }
    
    let mixTanks=this.makeCurrentMixTanks(zInd)
    let sensorList=this.addTankNames(mixTanks)
//     cl(sensorList)
//     cl(sensorList[0].s[0])
    let sensorOrder=this.zone.sensorOrder||[]
    this.addAddedSensors(addSensors,sensorList,sensorNames)
    let cams=globs.camerasInfo.info.filter(c=>{return c.zoneId==pa.zone})
    this.addCameras(cams,sensorList,sensorNames)
    let mbDevices=await this.getMBDevices(this.zone)
    let stations=await this.addHeaStations()
    this.addModbus(mbDevices.concat(stations),sensorList,sensorNames)
    this.makeSensorMap(this.sensorIds,this.state.sensorList,this.state.checks,sensorOrder)
//     cl(sensorOrder)
//     cl(this.state.checks)
    this.setState({loaded:true,
      sensorOrder:sensorOrder,
      sensorList:sensorList,
      stations:stations,
    })
    this.setBreadcrumbs()
}

  saveSensorNames=async()=>{
    let arr={}
    let pa=this.props.parms
    getSetSensorName("","",false,arr,this.state.sensorList)
//     cl(arr)
//     cl(pa.zone)
//     cl(pa.site)
//     return
    // save sensor names locally
    getSensorsZone(pa.zone).sensorNames = arr
    await wsTrans("usa", {cmd: "cRest", uri: "/s/sensors", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {zoneId: pa.zone, siteId:pa.site, sensorNames:arr}})
  }
  
  saveSensors=async(sensorOrder)=>{
//     let zone=getZoneInfo(this.props.parms.zone)
    this.zone.sensorOrder=sensorOrder
//     cl(sensorOrder)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {zoneId: this.props.parms.zone, sensorOrder:sensorOrder}})
  }
  
  makeSensorMap=(ids,sensors,checks,sensorOrder)=>{// sensors is an array
//     cl(sensorOrder)
    sensors.forEach(s=>{
//       cl(s)
      ids[s.v]=s
      if(checks){
        if(!s.s){ checks[s.v]=sensorOrder.includes(s.v) }
      }
      if(s.s){return this.makeSensorMap(ids,s.s,checks,sensorOrder)}
    })
  }
  
  openClose=(id,cmd)=>{
    let sensors=this.state.sensorList.slice(0)
    switch(cmd){
      case "Show":
        this.sensorIds[id].o=1
        break
      case "Hide":
        this.sensorIds[id].o=0
        break
      default:
        break
    }
    this.setState({sensorList:sensors})
  }
  
  showIcon=(id,MyCon,result)=>{
//     return null
    return(
      <IconButton 
        onClick={()=>result(id)} 
        style={{display: "inline", padding: 0}}>
          <MyCon/>
      </IconButton>
    )
  }
  
  result=(type)=>{
//     cl(type)
    let parts=type.split("-")
    switch(parts[1]){
      case "Show":
        this.openClose(parts[0],parts[1])
        break
      case "Hide":
        this.openClose(parts[0],parts[1])
        break
      case "check":
        globs.events.publish("savePageEnable",true)
        let checks=Object.assign({},this.state.checks)
        let id=parts[0]
        checks[id]=!checks[id]
        this.setState({checks:checks})
        break
      case "button":
        if(parts[0]=="order"){
          let newOrder=this.makeNewOrderSensors()
          this.setState({mode:parts[0], sensorOrder:newOrder})
        }else{
          this.setState({mode:parts[0]})
        }
        break
//       case "ArrowUp":
//       case "ArrowDn":
//         this.arrowUpDn(parts[0],parts[1])
//         break
      default:
        break
    }
//     switch(p
//     cl(type)
  }
  
  showSensorList=(sensorList,sensors)=>{// adds lines to sensorList
//     cl(sensors)
    sensors.forEach(s=>{
      sensorList.push(this.showOneSensor(s,this.key++))
    })
  }
  
  showSpaceHideShow=(id,l,cmd,Icon)=>{
    if(l==1){
      return(
        [
            <td key={this.key++}></td>,
            <td key={this.key++}>{this.showIcon(`${id}-${cmd}`,Icon,this.result)}</td>,
            <td key={this.key++}></td>,
        ]
      )
    }else{
      return(
        [
            <td key={this.key++}>{this.showIcon(`${id}-${cmd}`,Icon,this.result)}</td>,
            <td key={this.key++}></td>,
            <td key={this.key++}></td>,
        ]
      )
    }
  }
  
  showSpaceCheck=(id,l)=>{
    if(l==1){
      return[
          <td key={this.key++}></td>,
          <td key={this.key++}></td>,
          <td key={this.key++}><input type="checkbox" 
            checked={this.state.checks[id]}
            onChange={e=>this.result(`${id}-check`)}
            /></td>,
      ]
    }else{
//       cl(this.state.checks[id])
      return[
          <td key={this.key++}></td>,
          <td key={this.key++}><input type="checkbox"
            checked={this.state.checks[id]}
            onChange={e=>this.result(`${id}-check`)}
          /></td>,
          <td key={this.key++}></td>,
      ]
    }
    
  }
  
//   showOneSensor=(s,i)=>{// there are *two* of these!
//     let id=s.v
//     if(s.s){// show a list 
// //       if(s.o){cl(s)}
//       if(s.o){// open, show children
//         let ret=[]
//         ret.push(
//           <tr key={i}>
//             {this.showSpaceHideShow(id,s.l,"Hide",ExpandLess)}
//           <td>{s.t}</td></tr>
//         )
// //         cl(s)
//         this.showSensorList(ret,s.s,i)
//         return ret
//       }else{
//         return(// closed
//           <tr key={i}>
//           {this.showSpaceHideShow(id,s.l,"Show",ExpandMore)}
//           <td>{s.t}</td></tr>
//         )
//       }
//     }else{// just show one
//       return(
//         <tr key={i}>
//         {this.showSpaceCheck(id,s.l)}
//         <td>{s.t}</td></tr>
//       )
//     }
//   }
  
  showSelectSensors=()=>{
//     cl(this.state.sensorList)
    let sensorList=[]
    this.key=0// used for React keys
    this.showSensorList(sensorList,this.state.sensorList)

//     let sensors=this.state.sensorList
//     let sensorList=[]
//     sensors.forEach((s,i)=>{
//       sensorList.push(this.showOneSensor(s,i))
//     })
    return (
      <table width="200"><tbody>
      {sensorList}
      </tbody></table>
    )
//     cl(sensors)
  }
  
  showSelectOrder=()=>{
    return(
      <div>
      <C18Button00 type="button" onClick={()=>this.result("select-button")}>Select</C18Button00>
      <C18Button00 type="button" onClick={()=>this.result("order-button")}>Order</C18Button00>
      </div>
    )
  }
    
  showOrderSensors=()=>{
    let sensorOrder=[]
    this.state.sensorOrder.forEach(s=>{
      let s2=this.sensorIds[s]
//       cl(s)
//       cl(s2.t)
      sensorOrder.push(
        <tr key={this.key++}>
          <td>{this.showIcon(`${s2.v}-${"ArrowUp"}`,ArrowUpward,this.result)}</td>
          <td>{this.showIcon(`${s2.v}-${"ArrowDn"}`,ArrowDownward,this.result)}</td>
          <td>{s2.t}</td>
        </tr>
      )
    })
    return(
      <table><tbody>
      {sensorOrder}
      </tbody></table>
    )
  }
  
  showSensorOrder=()=>{
    let sensorOrder=[]
    let min=1
    let max=this.state.sensorOrder.length-1
    return(
      <div className="list-wrapper">
        <ul className="ordering-list">
        {this.state.sensorOrder.map((s,i)=>{
          let s2=this.sensorIds[s]
          let lastMoved=(this.state.lastMoved==s)?" last-moved":""
          if(!s2){return null}
//           cl(s2)
          return(
            <li key={i} className={lastMoved}>
              {i>=min&&
                <C18Button00 type="button" tabIndex="0" className="material-icons up-arrow" onClick={()=>this.arrowUpDn(s2.v,"ArrowUp")}>
                  arrow_upward
                </C18Button00>
                
              }
              {i<max&&
                <C18Button00 type="button" tabIndex="0" className="material-icons down-arrow" onClick={()=>this.arrowUpDn(s2.v,"ArrowDn")}>
                  arrow_downward
                </C18Button00>
              }
              <span className="sensor-name">{s2.t}</span>
            </li>
          )
        })}

        </ul>
      </div>
    )
  }
  
  arrowUpDn=(id,dir)=>{
    globs.events.publish("savePageEnable",true)
//     cl([id,dir])
    let order=this.state.sensorOrder.slice(0)
    let pos=order.indexOf(id)
    if(dir=="ArrowUp"){
      if(pos>0){
        [order[pos-1],order[pos]]=[order[pos],order[pos-1]]
      }
    }else{
      if(pos<order.length-1){
        [order[pos+1],order[pos]]=[order[pos],order[pos+1]]
      }
    }
    this.setState({sensorOrder:order,lastMoved:id})
//     cl(pos)
    
  }
  
  makeNewOrderSensors=()=>{
    let newOrder=[]
    this.state.sensorOrder.forEach(s=>{
      if(this.state.checks[s]){ newOrder.push(s) }
    })
    Object.entries(this.state.checks).forEach(s=>{
      if(s[1]){
        if(!newOrder.includes(s[0])){newOrder.push(s[0])}
      }
    })
//     this.setState({sensorOrder:newOrder})
    return newOrder
//     cl(newOrder)
  }
  
  showSensors=()=>{
    if(this.state.mode=="order"){
      return this.showOrderSensors()
    }else{
      return this.showSelectSensors()
    }
  }
  
  selectAll=(sl, all)=>{
//     cl([sl,all])
    globs.events.publish("savePageEnable",true)
    let checks=Object.assign({},this.state.checks)
    sl.s.forEach(s=>{
      checks[s.v]=!all
    })
    this.setState({checks:checks})
  }
  
  showOneSensor=(s0,j)=>{
//     cl(s0)
    let editMode=(s0.v==this.state.editName)?true:false
//     cl(s0)
    let isChannel=(s0.v.substring(2,3)=="c")&&(!isNaN(s0.v.substring(3,5)))
//     cl(s0.v.substring(2,3))
//     cl(s0.v.substring(3,5))
//     cl(isChannel)
    let id=(isChannel)?"":`{"cmd":"sensor","type":"${s0.v}"}`
//     cl(editMode)
    return(
      <span id={id} key={j}><input 
        type="checkbox" 
        id={s0.v} 
        checked={this.state.checks[s0.v]}
        disabled={!this.saveOK}
        onChange={()=>{this.result(`${s0.v}-check`)}}/>
        {(editMode)?
          <input 
          value={s0.t}
          onChange={e=>this.onChange("renameSensor",  {s0:s0,t:e.currentTarget.value})}
          />
          :
          <label htmlFor={s0.v}>{s0.t}</label>
        }
        </span>
    )
  }
  
  showSelectBox=(sl,i)=>{
//     cl(sl)
    let gotAll=true
    sl.s.forEach(s=>{gotAll&=this.state.checks[s.v]})
//     cl(sl)
//     cl(gotAll)
//     cl(sl.t)
//     cl(this.saveOK);
    return(
      <details id="sensorSelectBox" key={i}>
        <summary>
          <h2>{sl.t}</h2>
        </summary>
        <div className="details checkbox-field">
          {sl.s.map((s0,j)=>{
            if(s0.s){
              return(
                [
                <h3 key={j}>{s0.t}</h3>,
                s0.s.map((s1,k)=>{
                  return this.showOneSensor(s1,k)
                })
                
                ]
              )
            }else{
              return this.showOneSensor(s0,j)
            }
            })
          }
          {((this.saveOK!=0)&&(sl.v!="MXT"))&&
            <C18Button00 type="button" className="select-all" onClick={()=>this.selectAll(sl,gotAll)}>{(gotAll)?"DeSelect":"Select"} All</C18Button00>
          }
        </div>
      </details>
    )
  }
  
  onChange=(type,vals)=>{
//     cl([type,vals])
    switch(type){
      case "tabs":
//         cl(vals)
        if(vals.tab=="order"){
          let newOrder=this.makeNewOrderSensors()
          this.setState({mode:vals.tab, sensorOrder:newOrder,lastMoved:null})
        }else{
          this.setState({mode:vals.tab})
        }
        break
//       case "saveCancel":
//         let order=this.state.sensorOrder
//         if(vals.saveType=="save"){
//           if(this.state.mode=="selection"){
//             order=this.makeNewOrderSensors()
//           }
//           this.saveSensors(order)
// //           let pos=this.props.parms.url.indexOf("/admin")
//           let url=`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`
//           history.push(url)
// //           cl(this.props.parms.url.substr(0,pos))
//         }
// //         cl(vals.saveType)
//         break
      case "renameSensor":
//         cl(vals)
        vals.s0.t=vals.t
        globs.events.publish("savePageEnable",true)
        this.setState({sensorList:this.state.sensorList.slice(0)})
        break
      default:
        break
    }
  }
  
  render(){
//     cl(this.state)
    let tabs=[
      {v:"order",t:"Order"},
      {v:"selection",t:"Selection"},
    ]
    let isSelect=this.state.mode=="selection"
    if(this.state.loaded){
//               <C18SaveCancel00
//                 onChange={o=>{this.onChange("saveCancel",o)}}
//               />
//               <C18SaveCancel00
//               onChange={o=>{this.onChange("saveCancel",o)}}/>
      return(
        <div className="tabs">
          <C18TabsHeader00
            tabId={this.state.mode}
            tabs={tabs}
            onChange={o=>this.onChange("tabs",o)}
          />
          <div className="tab-panels">
            <div id="sensorSelectionTab" className={`tab-panel${(isSelect)?" selected":""}`} role="tabpanel">
              <h2>Sensor Selection</h2>
              {this.state.sensorList.map((sl,i)=>{
//                 cl(sl)
                return this.showSelectBox(sl,i)
              })}
            </div>
            <div id="sensorOrderTab" className={`tab-panel${(isSelect)?"":" selected"}`} role="tabpanel">
              <h2>Sensor Order</h2>
              {this.showSensorOrder()}
            </div>
          </div>
        </div>
      )
    }else{
      return <div>loading. . .</div>
    }
  }
}

export default SelectSensors ;

