import React from 'react';
import Position00 from '../../usa/components/Position00';
import Defaults00 from '../../usa/components/Defaults00';
import Parameters00 from '../../usa/components/Parameters00';
import UsaIcon from '../../usa/components/UsaIcon';
import UsaSelect00 from '../../usa/components/UsaSelect00';
import UsaColorPicker00 from '../../usa/components/UsaColorPicker00';
import {cl, globs} from '../../components/utils/utils';
import {colorIsDark} from '../utils/utils';
import {wsTrans} from '../../usa/utils/utils'
import history from '../../history'

class DashboardWidget00 extends React.Component{
/* this gets the size in parms w, h. assume a 5px padding*/  
  constructor(props) {
    super(props);
    this.state={
      name: "none",
    }
//     cl(props)
//     if(props.parms){this.getName(props)}
    props.notify({id: "drag", func: this.onDrag})
  }
  
  componentWillUnmount=()=>{
    this.props.notify({id: "drag", func: this.onDrag, unMount: true})
  }
  
/********************* These are the new Widget functions, to make them freestanding**********************/
widgetType={v: "dashboard", t: "Dashboard"}

editType=(that)=>{
  let pos=Object.assign({}, that.state.position)
  let def=Object.assign({}, that.state.defaults)
  let parm=Object.assign({}, that.state.parameters)
  return(
    <div>
    <Position00 parms={{vals: pos}} onChange={(vals)=>{that.onChange("position", vals)}}/>
    <Parameters00 parms={{
      vals: parm,
      wdg: this,
    }} type="dashboard" onChange={(vals)=>{that.onChange("parameters", vals)}}/>
    </div>
  )
}

editParameters=(that)=>{
//   cl(that.state.title)
  return(
    <div>
    <UsaIcon icon="parameters-OK" result={that.result} inline/>
    <UsaIcon icon="parameters-Cancel" result={that.result} inline/>
    <span><h3 style={{display: "inline-block"}}>Parameters:</h3></span>
    <UsaColorPicker00 parms={{
      title: "Background\u00A0Color",
      width: 100,
      inline: true,
      value: that.state.bgColor,
      valueId: "bgColor",
      onChange: o=>that.setState(o),
    }}/>
    <UsaSelect00 parms={{
      title: "Dashboard",
      width: 100,
      value: that.state.dashboard,
      valueId: "dashboard",
      onChange: o=>that.setState(o),
    }}/>
    </div>
  )
}

parameterChange=(v)=>{
  cl(v)
  return{bgColor: v.bgColor, dashboard: v.dashboard, title: "Dashboard"}
}

makeParms=(wdg)=>{
//   cl(wdg)
  return{
    parms:{
      bgColor: wdg.parameters.bgColor,
      dashboard: wdg.parameters.dashboard,
      title: "Dashboard",
    },
  }
}

showWidget=(that, x, y, w, h, wdg, i)=>{
//   cl(i)
//   cl(wdg)
  let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i, e: that.state.edit, cm: that.cm, md: that.md};
  let Cont=this.constructor
  return(
    <Cont key={i} parms={parms} notify={that.notify}/>
  );
}

/********************* End of new Widget functions **********************/

/******************** Drag, Context Menu, Mouse Down Functions *********************/

onDrag=(o)=>{// o has the updated info
//     cl("od")
//     cl(o)
    if("dragStart" in o){
      this.setState(o)
    }else{
      if(this.state.dragStart.i==this.props.parms.i){
//         cl(o)
        this.setState(o)
      }
    }
  }
  
  cm=(e)=>{
    this.props.parms.cm({e: e, i: this.props.parms.i})
  }
  
  md=(e)=>{
    this.props.parms.md({e: e, i: this.props.parms.i})
  }
  
  onClick=()=>{
    if(this.props.parms.e) {return}// edit mode
    let url=this.props.parms.wdg.parms.link
    history.push(url)
  }
  
/******************** End Drag, Context Menu, Mouse Down Functions *********************/

  render() {
    let p = this.props.parms;
    let p2=p.wdg.parms
    let dm=5
    let color=p2.bgColor
    let x=p.x+dm
    let y=p.y+dm
    if(this?.state?.dragStart?.i==this.props.parms.i){
      x+=this.state.drag.x-this.state.dragStart.x
      y+=this.state.drag.y-this.state.dragStart.y
    }
    let titleLen=this.state.name.length
    let fontSize=1.7*p.w/titleLen
    if (fontSize>p.h*0.5){fontSize=p.h*0.5}
    let textColor=colorIsDark(p2.bgColor)?"white":"black"
    return(
        <div style={{position: "absolute", left: x, top: y, width: p.w-2*dm, height: p.h-2*dm, textAlign: "center",
          paddingTop: 0, borderRadius: 10, backgroundColor: color}} onClick={this.onClick}
          onContextMenu={this.cm} onMouseDown={this.md}>
        </div>
      );
  }
}

export default DashboardWidget00;
