import React from 'react';
import CravoInput from './CravoInput'
import {cl, globs, leadZeros} from '../../components/utils/utils';

const blue="#55b4d2"
const white="#FFFFFF"

class StructuralProtection extends React.Component{
  constructor(props) {
    super(props);
//     this.props.getValue(props.ind, {type: "scalar"})
    this.state=Object.assign({},this.props.values)
//     this.state={
//       value: "",
//       wind: 0,
//       position: 0,
//       delay: 0,
//       digital: 0,
//       open: 0,
//       delay2: 0,
//       max: 0,
//     }
  }
  
  onChange=(valueId, ind, e)=>{
    let val=e.currentTarget.value
    let vals={}
    vals[valueId]=val
    this.props.onChange(vals)
    this.setState(vals)
  }
  
  makeTable=()=>{
    var row, rows
    rows=[]
    row=<tr key={0} align="center"><td></td><td>Wind</td><td>Position</td><td>Delay</td></tr>
    rows.push(row)
    row=<tr key={1}><td>Structural Protection</td>
    <td>
      <CravoInput
        label={"mph"}
        color={blue}
        value={this.state.wind}
        valueId={"wind"}
        ind={0}
        onChange={(valueId, ind, e)=>{this.onChange(valueId, ind, e)}}
      />
    </td>
    <td>
      <CravoInput
        label={"%"}
        color={blue}
        value={this.state.position}
        valueId={"position"}
        ind={0}
        onChange={(valueId, ind, e)=>{this.onChange(valueId, ind, e)}}
      />
    </td>
    <td>
      <CravoInput
        label={"minutes"}
        color={blue}
        value={this.state.delay}
        valueId={"delay"}
        ind={0}
        onChange={(valueId, ind, e)=>{this.onChange(valueId, ind, e)}}
      />
    </td></tr>
    rows.push(row)
    return(
      <table><tbody>
      {rows}
      </tbody></table>
    )
  }
  
  render(){
    return(
      <div className="structural-protection-wrapper">
        <div style={{height: 10}}/>
        {this.makeTable()}
        <div style={{height: 10}}/>
        <div className="fire-rain-wrapper">When fire is detected on Digital
        <CravoInput
          label={"none"}
          color={white}
          value={this.state.digital}
          valueId={"digital"}
          ind={0}
          onChange={(valueId, ind, e)=>{this.onChange(valueId, ind, e)}}
        />
        then open at least
        <CravoInput
          label={"%"}
          color={white}
          value={this.state.open}
          valueId={"open"}
          ind={0}
          onChange={(valueId, ind, e)=>{this.onChange(valueId, ind, e)}}
        />
        <span style={{marginLeft: "0.5em"}}>with delay of</span>
        <CravoInput
          label={"min"}
          color={white}
          value={this.state.delay2}
          valueId={"delay2"}
          ind={0}
          onChange={(valueId, ind, e)=>{this.onChange(valueId, ind, e)}}
        />
        <div style={{height: 10}}/>
        If raining, then MAX open is
        <CravoInput
          label={"%"}
          color={white}
          value={this.state.max}
          valueId={"max"}
          ind={0}
          onChange={(valueId, ind, e)=>{this.onChange(valueId, ind, e)}}
        />
        
        </div>
      </div>
    )
  }
}

export default StructuralProtection ;
