import React from 'react';
import TextInput from './TextInput'
import {cl, globs} from '../../components/utils/utils';

class EditDashboard extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state=Object.assign({}, props.parms.state)
  }
  
  setStateValue=(id, value)=>{
    let val={};
    val[id]=value;
    this.setState(val);
  }
  
  render(){
    this.props.parms.onChange(this.state)
    return(
      <div style={{width: this.props.parms.w, height: 100,}}>
      <TextInput
      title="Grid Size"
      onChange={this.setStateValue}
      valueId="gridSize"
      initValue={this.props.parms.state.gridSize}
      />
      <TextInput
      title="Grid Width"
      onChange={this.setStateValue}
      valueId="gridWidth"
      initValue={this.props.parms.state.gridWidth}
      />
      <TextInput
      title="Grid Height"
      onChange={this.setStateValue}
      valueId="gridHeight"
      initValue={this.props.parms.state.gridHeight}
      />
      </div>
    );
  }
}

export default EditDashboard ;

