import React from 'react';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import C18MuiSelect from '../../usa/components/C18MuiSelect'
import C18MuiMenuItem from '../../usa/components/C18MuiMenuItem'
import C18MuiFormControl from '../../usa/components/C18MuiFormControl'
// import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'

import Numerical from './Numerical';
import Duration from './Duration';
import {cl, globs} from '../../components/utils/utils';

class Triggers18 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      startType: 0,
      startIndex: 0,
      endType: 0,
      endIndex: 0,
      waterVolume: 0,
      endTime: 0,
      duration: 100,
      volume: 200,
    };
    this.makeAuxVarOptions();
    this.makeFValveOptions();
    this.props.getValue(props.ind, {type: "scalar"})
  }
  trigger = [
    {v: 0, t: "Disabled"},
    {v: 1, t: "Digital Input (Normally Open)"},
    {v: 2, t: "Digital Input (Normally Closed)"},
    {v: 3, t: "Aux Control Variables"},
    {v: 4, t: "Fill Valve"},
  ]
  digIn = [
    {v: 0, t: "Digital In 1"},
    {v: 1, t: "Digital In 2"},
    {v: 2, t: "Digital In 3"},
    {v: 3, t: "Digital In 4"},
    {v: 4, t: "Digital In 5"},
    {v: 5, t: "Digital In 6"},
    {v: 6, t: "Digital In 7"},
    {v: 7, t: "Digital In 8"},
  ]

  makeAuxVarOptions=()=>{
    // cl(this.props.current.auxNames)
    this.auxVars=this.props.current.auxNames.map((n, i)=>{
      if (n === undefined) n="Variable " + (i + 1);
      return {v: i, t: n}
    })
    // cl(this.auxVars)
  }

  makeFValveOptions=()=>{
    // cl(this.props.current)
    this.fillValves=this.props.current.fillValves.map((n, i)=>{
      // cl(n)
      // cl(this.props.current.channelNames[n])
      if (n === undefined) n="Variable " + (i + 1);
      return {v: n, t: this.props.current.channelNames[n]}
    })
    // cl(this.fillValves)
  }

  fvStartIndexOptions=(val)=>{
/*0: disabled, 1, 2: digin, 3: auxvars */
    // cl(this.state)
    switch(val.type){
      case 0:
        return [];
      case 1:
        return this.digIn;
      case 2:
        return this.digIn;
      case 3:
        return this.auxVars;
      case 5:
        return this.fillValves;
      default:
        break;
    }
    let ret = [
      {v: 0, t: "Disabled"},
      {v: 1, t: "Digital Input (Normally Open)"},
      {v: 2, t: "Digital Input (Normally Closed)"},
      {v: 3, t: "Aux Control Variables"},
    ];
    return ret;


  }

  fvendIndexOptions=(val)=>{
/*0: disabled, 1, 2: digin, 3: auxvars */
    // cl(this.state)
    switch(val.endType){
      case 0:
        return this.digIn;
      case 1:
        return this.digIn;
      case 2:
        return this.auxVars;
    }
    let ret = [
      {v: 0, t: "Disabled"},
      {v: 1, t: "Digital Input (Normally Open)"},
      {v: 2, t: "Digital Input (Normally Closed)"},
      {v: 3, t: "Aux Control Variables"},
    ];
    return ret;
  }

  makeSelect=(type, val)=>{
/* this can make many kinds:
fValve startType, endType, pPump startType,
digital, auxVar, fValves
types:
fvStart, fvStop, ppStart
digital, auxVar, fValves
*/
    let options = {
      fvStartType: [
        {v: 0, t: "Disabled"},
        {v: 1, t: "Digital Input (Normally Open)"},
        {v: 2, t: "Digital Input (Normally Closed)"},
        {v: 3, t: "Aux Control Variables"},
      ],
      fvendType: [
        {v: 0, t: "Digital Input (Normally Open)"},
        {v: 1, t: "Digital Input (Normally Closed)"},
        {v: 2, t: "Aux Control Variables"},
        {v: 3, t: "Timer"},
      ],
      pStartType: [
        {v: 0, t: "Disabled"},
        {v: 1, t: "Digital Input (Normally Open)"},
        {v: 2, t: "Digital Input (Normally Closed)"},
        {v: 3, t: "Aux Control Variables"},
        {v: 5, t: "Fill Valve"},
      ],
    }
    let optionsCustom = {
      fvStartIndex: this.fvStartIndexOptions,
      fvendIndex: this.fvendIndexOptions,
      pStartIndex: this.fvStartIndexOptions
    }
    if(options[type] === undefined){
      // cl(type)
      // cl(optionsCustom[type])
      options[type]=optionsCustom[type](val);
    }
    return(
      options[type].map((o, i)=>{
        return(
          <C18MuiMenuItem key={i} value={o.v}>{o.t}</C18MuiMenuItem>
        )
      })

    );
  }

  // onChange=(e)=>{
  //   this.props.onChange(this.props.ind,
  //     {value: e.target.value})
  // }

  onChange=(id, type, value)=>{
    cl(id, type, value)
    // let vals = this.props.value
    var valArr;
    // cl(e)
    // cl(id, type, value)
    try{
      valArr = Object.assign(JSON.parse(this.props.value.replace(/'/g, '"')));
    }catch{
      return;
    }
    // cl(valArr)
    switch(type){
      case "fValveVol":
        valArr[id].waterVolume = this.localToDBVol(value, true);
        break;
      case "fValveDur":
        valArr[id].endTime = value;
        break;
      case "fvStartType":
        valArr[id].startType = value;
        valArr[id].startIndex = 0;
        break;
      case "fvStartIndex":
        valArr[id].startIndex = value;
        // this.setState({startIndex: value})
        break;
      case "fvendType":
        valArr[id].endType = value;
        valArr[id].endIndex = 0;
        // this.setState({endType: value, endIndex: 0})
        break;
      case "fvendIndex":
        valArr[id].endIndex = value;
        // this.setState({endIndex: value})
        break;
      case "fvWaterVolume":
        break;
      case "fvEndTime":
        break;
      case "pStartType":
        valArr[id].type = value;
        // this.setState({startType: value, startIndex: 0})
        break;
      case "pStartIndex":
        valArr[id].startIndex = value;
        // this.setState({startIndex: value})
        break;
      default:
        break;
    }
    // cl(valArr)
    this.props.onChange(this.props.ind,
      {value: JSON.stringify(valArr)})
  }

  showTriggerType=(id, type, val)=>{
    cl(type, val)
/*types: "fvStartType"*/
  // let vals = {fvStartType: this.state.startType,
  //   fvStartIndex: this.state.startIndex,
  //   fvendType: this.state.endType,
  //   fvendIndex: this.state.endIndex,
  //   pStartType: this.state.startType,
  //   pStartIndex: this.state.startIndex,
  // }
  let vals = {fvStartType: val.startType,
    fvStartIndex: val.startIndex,
    fvendType: val.endType,
    fvendIndex: val.endIndex,
    pStartType: val.type,
    pStartIndex: val.index,
  }
  // cl(type)
  // cl(val)
  cl(vals[type])
    let curVal = vals[type];
    if (curVal === undefined) curVal = 0;

    return (
      <div>
      <C18MuiFormControl
      style={{minWidth: 150}}
      >
        <C18MuiSelect
          value={curVal*1}
          onChange={(e)=>{this.onChange(id, type, e.target.value)}}
          >
          {this.makeSelect(type, val)}
        </C18MuiSelect>
      </C18MuiFormControl>
      </div>

    );
  }


  showOneTrigger=(type, id, val)=>{
    cl(val)
/*this shows one row of trigger: fvStart, stop, or pump
the vals are these:
startType, startIndex
startType
endType
startIndex
endIndex
waterVolume
endTime*/
    // let startType = this.props.
    // let val = this.props.value.replace("'", "\"")
    // cl(val)
    // cl(JSON.parse(this.props.value))
    // try{
    //   let val = JSON.parse(this.props.value);
    //   cl(val[id])
    // }catch{}
    switch (type){
      case "fvStart":
        return(
          <tr><td>Start Trigger </td>
          <td>{this.showTriggerType(id, "fvStartType", val)}</td>
          <td>{(val.startType != 0) &&
            this.showTriggerType(id, "fvStartIndex", val)}</td>
          </tr>
        );
        case "fvStop":
          return(
            <tr><td>Stop Trigger </td>
            <td>{this.showTriggerType(id, "fvendType", val)}</td>
            <td>{(val.endType != 3) &&
              this.showTriggerType(id, "fvendIndex", val)}</td>
            </tr>
          );
        case "pStart":
        cl("pump2")
          return(
            <tr><td>Start Trigger </td>
            <td>{this.showTriggerType(id, "pStartType", val)}</td>
            <td>{(val.type != 0) &&
              this.showTriggerType(id, "pStartIndex", val)}</td>
            </tr>
          );
      default:
        return (<div>unknown</div>)
    }
  }

  lfGetValue=()=>{
  }

  lfOnChange=(index, value)=>{
    cl(index, value)
    switch(index){
      case 0:// pump Volume
        this.setState({volume: value.value*1})
        break;
      case 1:// duration
        this.setState({duration: value.value*1})
        break;
      default:
        break;
    }
  }

  localToDBVol=(vol, to)=>{
/* if the units are set to gallons, then they have to
be converted coming and going*/
    let unit = this.props.current.units[3]; // volume
    if (unit === 0){// liters
      return vol;
    }else{
      let conv = (to)? 3.78 : 1/3.78;
      return Math.round(conv*vol);
    }
    // if (unit > 0) vol = Math.round(vol / 3.78)
  }

  showVolume=(id, type, val)=>{
// this needs to do the gal / L conversion
// 3 is volume, L, gal, gal
// cl(this.props.current.units)
cl(id, type, val)
    if ((type === "pPump") && (val.type*1 === 5)) return
    let curVal = (type === "pPump") ? val.volume : val.waterVolume;
    if (curVal === undefined) curVal = 0;
    let i = 0;
//     let vol = val.waterVolume;
//     let unit = this.props.current.units[3]; // volume
//     if (unit > 0) vol = Math.round(vol / 3.78)
    // let val = this.state.volume;
    return(
      <Numerical key={i} ind={i}
        value={this.localToDBVol(curVal, false)}
        current={this.props.current}
        custom={{unit: "gal", step: 1, min: 0, max: 1000}}
        getValue={this.lfGetValue}
        title={"Tank Volume"}
        onChange={(i, v)=>{this.onChange(i, type+"Vol", v.value)}}/>
    );
  }

  showDuration=(id, type, val)=>{
/*
current={this.props.current}
custom={{unit: "gal", step: 1, min: 0, max: 1000}}
*/
    let i = 1;
    // let val = this.state.duration;
    return(
      <Duration key={i} ind={i}
        value={val.endTime}
        getValue={this.lfGetValue}
        title={"Max Water Time"}
        onChange={(i, v)=>{this.onChange(id, type+"Dur", v.value)}}/>
    );

  }

  getValue=(id)=>{
    let val = {
      startType: 0,
      endType: 0,
      startIndex: 0,
      endIndex: 0,
      waterVolume: 0,
      endTime: 0,
    }
    try{
      cl(this.props.value)
      val = JSON.parse(this.props.value.replace(/'/g, '"'))[id]
      cl(val)
    }catch{}
    return val;
  }

  showTriggers=(type, id)=>{
/*this shows all the triggers for fValve or pump*/
cl(id)
    let val = this.getValue(id)
    if(type === "fValve"){
      return(
        <div key={id}>
        <h3>{"Trigger " + (id + 1)}</h3>
        <table><tbody>
        {this.showOneTrigger("fvStart", id, val)}
        {(val.startType != 0) &&
          this.showOneTrigger("fvStop", id, val)}
        </tbody></table>
        {this.showDuration(id, type, val)}
        {this.showVolume(id, type, val)}
        </div>
      );
    }else{
      cl("pump")
      return(
        <div key={id}>
        <table><tbody>
        {this.showOneTrigger("pStart", id, val)}
        </tbody></table>
        {this.showVolume(id, type, val)}
        </div>
      );
    }
  }

  render(){
    // cl(this.trigger)
    // cl(this.props);
    // let triggers=[0, 1, 2];
    // cl(this.props)
    return(
      <div>
        {this.props.title}<br />
        <input type="text"
        value={this.props.value}
        onChange={this.onChange}
        />
        {this.showTriggers(this.props.custom.type,
          this.props.custom.trigger)}
      </div>
    );
  }
}

  export default Triggers18 ;
