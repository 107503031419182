import React from 'react';
import C18Button00 from './C18Button00'
import UsaIcon from './UsaIcon';
import Feed01 from '../../visualization/components/Feed01';
import GrowJournal00 from './GrowJournal00';
import CreateMessage00 from './CreateMessage00';
import CreateToDo00 from './CreateToDo00';
import {loadSitesInfo,loadZonesInfo,getZoneIndex,loadUsersInfo} from './C18utils'
import {cl,globs,constant} from '../../components/utils/utils';

class C18RightSidebar00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      sideBarOpen: false,
      showCreateGrowJournal: false,
      loaded:false,
      mode:"growJournal",
      loadEvent: '',
      loading: false
    }
    this.loadingMore = false
    this.loadInfo()
  }
  
  componentDidMount=()=>{
    this.mounted=true
  }
  
  componentWillUnmount=()=>{
    this.mounted=false
  }
  
  loadInfo=async()=>{
//     cl(this.props)
    await loadSitesInfo()
    await loadZonesInfo()
    await loadUsersInfo()
    let zInd=getZoneIndex(this.props.parms.zone)
    this.zone=globs.zonesInfo.info[zInd]
//     cl(this.zoneInfo)
//     cl(zInd)
//     cl("load")
    this.mySetState("loadInfo", {loaded:true})
//     this.zone={
//       zoneName: "name",
//     }
  }
  
  getWidth=()=>{
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }
  
  onClick=()=>{
    let open=!this.props.parms.sideBarOpen
    let vals={sideBarOpen:open}
    console.log(vals)
    // let mobile know status
    this.props.parms.onChange({cmd: "sideBarOpen",vals: vals})
    this.setState(vals)
    globs.events.publish("sideBarClicked")
    //if (this.getWidth() < 964){window.scrollTo(0, 0)}
  }
  
  notify=(msg)=>{
//     cl(msg)
  }
  
  md=(vals)=>{
    cl(vals)
  }
  
  mySetState=(source,vals)=>{
    if(this.mounted){
      this.setState(vals)
    }else{
//       cl(vals)
      Object.assign(this.state,vals)
//       cl(this.state)
    }
  }
  
onChange=(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "feed":
//         cl(type,vals)
        switch(vals.type){
          case "image":
            this.props.parms.onChange({cmd:"showImage", vals: vals})
//             cl(vals)
//             return this.showImage(vals)
            break
          case "savePage":
            this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
            break
          case "sidebarMode":
            this.props.parms.onChange({cmd:"sidebarMode", data:{sidebarMode:vals.mode}})
            break
          default:
            this.mySetState("onChange1",vals)
            break
        }
//         cl(vals)
        break
      case "gjCreate":
        this.mySetState("onChange1",vals)
        break
      case "messageCreate":
        this.mySetState("onChange1",vals)
        break
      case "header":
        cl(vals)
        this.mySetState("header",vals)
        break
//       case "imageIcon":
//         this.props.parms.onChange({type:"imageIcon", vals: vals})
//         cl(vals)
//         cl(this.state)
// //         doImageIcon(
//         break
      default:
        break
    }
  }
  
  showFeed=()=>{
//     cl(this.props)
    let parms={
      i:1,
      wdg:{
        parms:{
          inc:["GJ","Msg","Tasks","Alarms","Activity","Notification","Admin","IDoser"],
        }
      },
      zone:this.zone,
      level:this.props.parms.level,
      siteId:this.props.parms.site,
      zoneId:this.props.parms.zone,
      sensor:this.props.parms.sensor,
      sideBarNoteId:this.props.parms.sideBarNoteId,
      mode:this.props.parms.mode||"growJournal",// mode, zuci, pageType are just for fui pages
      zuci:this.props.parms.zuci,
      pageType:this.props.parms.pageType,
      md: this.md,
      onChange:(type,vals)=>this.onChange(type,vals),
      getPopup:this.props.parms.getPopup,
      loadMore: this.state.loadMore,
      loadEvent: this.state.loadEvent,
      handleScroll:this.handleScroll
    }
//     cl(parms)
//     cl(this.props)
    return(
      <Feed01
        parms={parms}
        notify={this.notify}
        onChange={v=>this.onChange("feed",v)}/>
    )
  }
  
  showGJCreate=()=>{
    return(
      <GrowJournal00
        onChange={v=>{this.onChange("gjCreate",v)}}
        parms={{
          zuci: this.props.zuci,
          zones: this.zones,
          zone: this.zone,
        }}
        />
    )
  }
  
  showMsgCreate=()=>{
    return(
      <CreateMessage00
        onChange={v=>{this.onChange("messageCreate",v)}}
        parms={{
          zuci: this.props.zuci,
          zones: this.zones,
          zone: this.zone,
        }}
        />
    )
  }
  
  showToDoCreate=()=>{
    return(
      <CreateToDo00
        onChange={v=>{this.onChange("todoCreate",v)}}
        parms={{
          zuci: this.props.zuci,
          zones: this.zones,
          zone: this.zone,
        }}
        />
    )
  }
  
  showPanel=()=>{
    if(this.state.showCreateGrowJournal){
      return this.showGJCreate()
    }
    if(this.state.showCreateMessage){
      return this.showMsgCreate()
    }
    if(this.state.showCreateToDo){
      return this.showToDoCreate()
    }
    
    else{
      return this.showFeed()
    }
  }

  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop < (e.target.clientHeight + 1.0);
    // replace target of scroll
    // cl(e)
    if (bottom && !this.loadingMore) { 
      cl("loading more")
      this.doLoadMore(e)
    }
  }

  doLoadMore = (e)=> {
    this.loadingMore = true
    this.setState({loadMore: true, loadEvent: e})
    setTimeout(()=>{this.loadingMore = false},2000)
  }
    
  render(){
    if(this.state.loaded){
//       cl(this.state)
    let zInd=getZoneIndex(this.props.parms.zone)
    this.zone=globs.zonesInfo.info[zInd]
//       cl("render side bar")
    return(
        <div id="right-sidebar">
          <C18Button00 type="button" id="sidebar-arrow" className="material-icons-outlined" aria-expanded={this.props.parms.sideBarOpen} aria-label="sidebar"
          style={{zIndex:5}}
            onClick={this.onClick}>{(this.props.parms.sideBarOpen) ? ((window.ReactNativeWebView) ? "arrow_forward_ios" : "arrow_forward_ios") :  "arrow_back_ios" }</C18Button00>
          <div id="sidebar-content" style={{zIndex:4}}>
            {this.props.parms.sideBarOpen&&
              <div className="inner" style={{
                height:"100%",
                overflowY:"scroll"
              }}
              >
                {this.showPanel()}
              </div>
            }
          </div>
        </div>
      )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18RightSidebar00;
