import React from 'react';
// import Input from "@material-ui/core/Input";
// import InputLabel from '@material-ui/core/InputLabel';
// import IconButton from '@material-ui/core/IconButton';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import history from '../../history';
import {cl, globs, constant} from '../../components/utils/utils';
import {dbVals} from '../../components/utils/http'
import {pi} from '../../components/utils/paramIds'
/* first of all, this is going to do the math to look stuff up in the database.
start with the pid,
use it to find what table, and type
*/

/*this is all copied from paramIds, in no00, on 20201120*/
var p={}
var pInd={};
pInd[1800]={};

p.PID_BASE_SNAPSHOTS = 0 // 81
p.PID_BASE_SNAP_CHANNELS = 100;
p.PID_BASE_SNAP_ECPHS = 200;
p.PID_BASE_SNAP_CHAN_DATA = 400;
p.PID_BASE_CONF_CHANNELS = 500;
p.PID_BASE_CONF_CHAN_DATA = 600;
p.PID_BASE_AUX_ALARMS = 1024 // 32
p.PID_BASE_AUX_PERSISTENT_VARIABLE = 1056 // 32
p.PID_BASE_AUX_VARIABLE = 1088 // 64
p.PID_BASE_EXPANSION_BOARDS = 1152 // 64
p.PID_BASE_CONFIG_AUX_ALARMS = 1216 // 4 * 32
p.PID_BASE_CONFIG_AUX_CONTROLS = 1344 // 17 * 64 no! 128!
p.PID_BASE_CONFIG_AUX_VARIABLES = 3640 // 4 * 64
p.PID_BASE_CONFIG_EXPANSION_BOARDS = 3896 // 5 * 64
p.PID_BASE_CONFIG_CONTROLLER_SETTINGS = 4216 // 145 * 2, 170, actually, 166, now
p.PID_BASE_CONFIG_ECPH = 4556 // 11
p.PID_BASE_CONFIG_ECPH_SENSORS = 4567 // 3 * 23 = 69
p.PID_BASE_CONFIG_SETPOINTS = 4636 // 8 * 10
p.PID_BASE_CONFIG_ZONE_SETTINGS = 4716 // 153 * 2!
p.PID_BASE_CONFIG_ZONES = 5022 // 2
p.PID_BASE_CONFIG_CONTROLLERS = 5024 // 4
p.PID_BASE_CONFIG_COMM_STAT = 5028 // 3
p.PID_BASE_CONFIG_TIME = 5031 // 1 - current time at the server
p.PID_BASE_CONFIG_AUX_PERSISTENT_VARIABLES = 5040; // 3520 // 4 * 32 moved on 20201028

pInd[1800].snapshots = [p.PID_BASE_SNAPSHOTS, 0, 0, 0] // 1-81: base, channel type, index size, index count. channel type 0 = "unit"
pInd[1800].snapshot_channels = [p.PID_BASE_SNAP_CHANNELS, 1, 0, 0] // 101-120 channel type 1 = "channel" - zone, unit, chan
pInd[1800].snapshot_ecphs = [p.PID_BASE_SNAP_ECPHS, 2, 0, 0] // 201-235// ecph channel type - put in 192-199, on ecphIndex
pInd[1800].snapshot_chan_Snapshot = [p.PID_BASE_SNAP_CHAN_DATA, 1, 0, 0] // 401-448 channeldata channel type
pInd[1800].channels_configuration = [p.PID_BASE_CONF_CHANNELS, 1, 0, 0] // 501-520 channel type
pInd[1800].config_channels_configuration = [p.PID_BASE_CONF_CHAN_DATA, 1, 0, 0] // 600-796 channeldata
pInd[1800].snapshot_aux_alarms = [p.PID_BASE_AUX_ALARMS, 0, 1, 32]; // 1024 + 32
pInd[1800].snapshot_aux_persistent_variables = [p.PID_BASE_AUX_PERSISTENT_VARIABLE, 0, 1, 32];//1056 + 32
pInd[1800].snapshot_aux_variables = [p.PID_BASE_AUX_VARIABLE, 0, 1, 64];//1088 + 64
pInd[1800].snapshot_expansion_boards = [p.PID_BASE_EXPANSION_BOARDS, 0, 1, 64];//1152 + 64
pInd[1800].config_aux_alarms = [p.PID_BASE_CONFIG_AUX_ALARMS, 0, 4, 32];//1216 4 * 32
pInd[1800].config_aux_controls = [p.PID_BASE_CONFIG_AUX_CONTROLS, 0, 17, 128];//1344 17 * 128
pInd[1800].config_aux_variables = [p.PID_BASE_CONFIG_AUX_VARIABLES, 0, 4, 64];//3640 4 * 64
pInd[1800].config_expansion_boards = [p.PID_BASE_CONFIG_EXPANSION_BOARDS, 0, 5, 64];//3896 5 * 64
pInd[1800].controller_configuration_settings = [p.PID_BASE_CONFIG_CONTROLLER_SETTINGS, 7, 2, 170];//4216 170 * 2
pInd[1800].config_ecph = [p.PID_BASE_CONFIG_ECPH, 2, 0, 0];//4506 11
pInd[1800].config_ecph_sensors = [p.PID_BASE_CONFIG_ECPH_SENSORS, 3, 23, 3];//4517 - channel type 3 - ecph double indexed
pInd[1800].config_setpoints = [p.PID_BASE_CONFIG_SETPOINTS, 4, 10, 8];//4586 type 4 = zone wide
pInd[1800].zone_configuration_settings = [p.PID_BASE_CONFIG_ZONE_SETTINGS, 6, 2, 153];//4666 zone *config* type
pInd[1800].config_zones = [p.PID_BASE_CONFIG_ZONES, 4, 0, 0];//4972
pInd[1800].config_controllers = [p.PID_BASE_CONFIG_CONTROLLERS, 0, 0, 0];//4974
pInd[1800].config_communication_status = [p.PID_BASE_CONFIG_COMM_STAT, 5, 0, 0];//4978 type 5 - site wide
pInd[1800].config_aux_persistent_variables = [p.PID_BASE_CONFIG_AUX_PERSISTENT_VARIABLES, 0, 4, 32];//3520 4 * 32

var tableBases2 = [
  pInd[1800].snapshots,
  pInd[1800].snapshot_channels,
  pInd[1800].snapshot_ecphs,
  pInd[1800].snapshot_chan_Snapshot,
  pInd[1800].channels_configuration,
  pInd[1800].config_channels_configuration,
  pInd[1800].snapshot_aux_alarms,
  pInd[1800].snapshot_aux_persistent_variables,
  pInd[1800].snapshot_aux_variables,
  pInd[1800].snapshot_expansion_boards,
  pInd[1800].config_aux_alarms,
  pInd[1800].config_aux_controls,
  pInd[1800].config_aux_variables,
  pInd[1800].config_expansion_boards,
  pInd[1800].controller_configuration_settings,
  pInd[1800].config_ecph,
  pInd[1800].config_ecph_sensors,
  pInd[1800].config_setpoints,
  pInd[1800].zone_configuration_settings,
  pInd[1800].config_zones,
  pInd[1800].config_controllers,
  pInd[1800].config_communication_status,
  pInd[1800].config_aux_persistent_variables,
  100000
];

var getTable=(z, c, ix, pid)=>{
//   cl(tableBases2);
  var z1, c1, i1
  for (let i = 0 ; i < tableBases2.length - 1 ; i++){
    if(pid < tableBases2[i+1][0]){
      let tab = tableBases2[i]
//       cl(tab)
      let u = 0
      let k=tab[2]
      switch(tab[1]){
        case 0:
          z1 = z;
          c1 = 240 + u; // zone wide
          i1 = pid;
          break;
        case 1:// snap chan data
          z1 = z;
          c1 = 40 * u + c; // zone wide
          i1 = pid;
          break;
        case 2:// snap ecph
          z1 = z;
          // c1 = 192 + 8 * u + idx; // zone wide
          c1 = c + 8 * u// + idx; // zone wide
          i1 = pid;
          break;
        case 3:// conf ecph sensors - idx is tank
          z1 = z;
          // c1 = 192 + 8 * u + idx; // zone wide
          c1 = c + 8 * u; // + idx; // zone wide
          i1 = pid + ix * k;
          break;
        case 4:// zone wide, let setpoints
          z1 = z;
          c1 = 255; // zone wide
          i1 = pid + ix * k;
          break;
        case 5:// sitewide
          z1 = 255;
          c1 = 0;
          i1 = pid;
          break;
        case 6:// zone type
          z1 = z;
          c1 = 255; // zone wide
          i1 = pid;
          break;
        case 7:// controller settings
          z1 = z;
          c1 = 240 + u; // zone wide
          i1 = pid;
          break;
        default:
          break;
      }
//       cl(z1, c1, i1);
//       cl(tableBases2[i]);
      return [z1, c1, i1];
    }
  }
  
}

var getParmInfo=(z, c, ix, pid)=>{
//   cl("get parm");
  return getTable(z, c, ix, pid);
//   cl(tab)
}

var getParmValue=(z, c, ix, pid)=>{
  var p=getParmInfo(z, c, ix, pid)
  try{
    if((typeof dbVals.z[p[0]][p[1]][p[2]]) == "object"){
      return dbVals.z[p[0]][p[1]][p[2]].val
    }else{
      return dbVals.z[p[0]][p[1]][p[2]]
    }
  }catch{
    return 0
  }
}

var setParmValue=(z, c, ix, pid, val)=>{
  var p=getParmInfo(z, c, ix, pid)
  try{
    if((typeof dbVals.z[p[0]][p[1]][p[2]]) == "object"){
      dbVals.z[p[0]][p[1]][p[2]].val = val
//       dbVals.z[p[0]][p[1]][p[2]].upd.forEach(upd=>{upd(p.d)})// update notifications
    }else{
      dbVals.z[p[0]][p[1]][p[2]] = val;
    }
  }catch{
    return 0
  }
}

var readValObjType=(val)=>{// only works for numbers@
  if((typeof val == "object")&&(val!=null)){
    return +val.val
  }else{
    return +val
  }
}

var getChannelType=(z, c)=>{
//   cl(pi)
//   cl(dbVals.z.length)
//   cl([z, c])
  if(!dbVals.z.length) return
  let chType = {};
  chType[0] = "channel_None";
  chType[10] = "channel_On_Off";
  chType[20] = "channel_Irrigation_Scheduled";
  chType[21] = "channel_Irrigation_Accumulated_Light";
  chType[22] = "channel_Irrigation_Cycle";
  chType[23] = "channel_Irrigation_Trigger";
  chType[24] = "channel_Irrigation_Soil_Trigger";
  chType[25] = "channel_Irrigation_VPD";
  chType[30] = "channel_CO2";
  chType[40] = "channel_Light_Supplemental";
  chType[41] = "channel_Light_Scheduled";
  chType[42] = "channel_Light_Cyclic";
  chType[43] = "channel_Light_DLI";
  chType[50] = "channel_Microzone";
  chType[60] = "channel_Supply_Pump";
  chType[61] = "channel_Peristaltic_Recirculating_Pump";
  chType[62] = "channel_Peristaltic_Batch_Pump";
  chType[63] = "channel_Peristaltic_Balance_Pump";
  chType[70] = "channel_Fill_Valve";
  chType[80] = "channel_Vent_Roof";
  chType[81] = "channel_Vent_Retractable_Roof";
  chType[82] = "channel_Vent_Side_Wall";
  chType[90] = "channel_Curtain";
  chType[100] = "channel_Mix_Valve";
  chType[110] = "channel_Proportional_Microzone";
  chType[120] = "channel_PID";
  chType[180] = "channel_Variable_Out";
  chType[190] = "channel_Vent2";
  chType[200] = "channel_Curtain2";
  chType[210] = "channel_Mix_Valve2";
  chType[220] = "channel_Microzone2";
  chType[230] = "channel_PID2";
  chType[240] = "channel_Cravo2";
  
  let z0 = z;
  let c0 = (c*1);// 40 * (u*1) + 
//   if (!dbVals.z[z0][c0]){
//     dbVals.z[z0][c0] = dbVals.z[z0][0];
//   }
//   cl(dbVals.z)
//   cl([dbVals,z0,c0])
//   cl(dbVals.z)
//   cl(dbVals.z[z0])
//   cl(dbVals.z[z0][c0])
//   cl([z0,c0])
//   cl(dbVals)
  let ch = dbVals.z[z0][c0];
  if(!ch) return
//   cl(ch)
  var dif
//   cl(ch)
  let pid = p.PID_BASE_CONF_CHANNELS + pi[1800].channels_configuration["channelType"];
//   let ty1 = 10 * (typeof ch[pid] == "object") ? ch[pid].val : ch[pid]
  let ty1 = 10 * readValObjType(ch[pid]);
//       cl([pid, ty1]);
  switch(ty1){
    case 20:
      pid = p.PID_BASE_CONF_CHAN_DATA + pi[1800].config_channels_configuration["irrigation_mode"];
      dif=readValObjType(ch[pid])
      ty1 += (dif)?dif:0 //readValObjType(ch[pid])
      break;
    case 40:
      pid = p.PID_BASE_CONF_CHAN_DATA + pi[1800].config_channels_configuration["light_mode"];
      dif=readValObjType(ch[pid])
      ty1 += (dif)?dif:0 //readValObjType(ch[pid])
      break;
    case 60:
      pid = p.PID_BASE_CONF_CHAN_DATA + pi[1800].config_channels_configuration["pump_type"];
      dif=readValObjType(ch[pid])
      ty1 += (dif)?dif:0 //readValObjType(ch[pid])
      break;
    case 80:
      pid = p.PID_BASE_CONF_CHAN_DATA + pi[1800].config_channels_configuration["vent_type"];// was vent_mode
      dif=readValObjType(ch[pid])
      ty1 += (dif)?dif:0 //readValObjType(ch[pid])
      break;
    default:
      break;
  }
//   cl(ty1)
  return chType[ty1];
}

var stages=["Heat 6", "Heat 5", "Heat 4", "Heat 3", "Heat 2", "Heat 1", "Normal", 
  "Cool 1", "Cool 2", "Cool 3", "Cool 4", "Cool 5", "Cool 6" ]
var eqTypes=["None", "On Off", "Irr", "Co2", "HID", "Mzone", "Pump", "Fvalve", "Vent", "Curt", "MixV", "MZoneP", "GenPid", "", "", "", "", "", "VarOut", 
  "Vent2", "Curt2", "Mixv2", "MZoneP2", "GenPid2", "Cravo2"]


  var colorToRGB=(col)=>{ // #112233
    if(!col){return [255, 255, 255]}
    let col2=parseInt("0x"+col.substring(1))
    return [col2>>16, (col2>>8)%256, col2%256] 
  }
  
  var colorIsDark=(col)=>{
    let [r,g,b]=colorToRGB(col)
    return r*r+g*g+b*b<100000
  }
  
  var blendColor=(color,color2,k)=>{
    let[r,g,b]=colorToRGB(color)
    let[rb,gb,bb]=colorToRGB(color2)
    r=Math.floor(r*k+rb*(1-k))
    g=Math.floor(g*k+gb*(1-k))
    b=Math.floor(b*k+bb*(1-k))
    let col2=(r<<16)+(g<<8)+b
    let str="000000"+col2.toString(16)
    return "#"+str.substring(str.length-6)
  }
  
  var splitStringInHalf=(str)=>{
    let len=str.length
    let start=0
    var pos
    let i=0
    let minDist=100
    var breakPos=0
    while ((pos=str.indexOf(' ',start))>=0){
//       cl(pos)
//       cl(start)
//       cl(str.substr(pos))
      let dist=Math.abs(pos-(len/2))
      if (dist>minDist){
        cl(str.substr(breakPos))
        
      }
      breakPos=pos
      minDist=dist
      start=pos+1
      if(i++>10)break
    }
  }
    
  

  export { getParmValue, setParmValue, stages, eqTypes, getChannelType, colorToRGB, colorIsDark, blendColor, splitStringInHalf
}
