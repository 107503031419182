import React from 'react';
import CravoInput from './CravoInput'
import greenhouse from './greenhouse.png'
// import TextField from '@material-ui/core/TextField';
// import InputLabel from '@material-ui/core/InputLabel';
import {cl, globs, leadZeros} from '../../components/utils/utils';
// import { TimePicker } from "@material-ui/pickers";
// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   // KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';

const blue="#55b4d2"
const bluGrn="#71e0b6"
const green="#71e0b6"

class CropProtection extends React.Component{
  constructor(props) {
    super(props);
//     this.props.getValue(props.ind, {type: "scalar"})
    this.state=Object.assign({},this.props.values)
//     this.state={
//       windSpeed: [1,2,3],
//       position: [4,5,6],
//       delayOut: [7,8,9],
//     }
  }
  
//   cravoInput=(label, color, valueId, ind)=>{
//     var val
// //     if(this.isArray(valueId)){
//       val=this.state[valueId][ind]
// //     }else{
// //       val=this.state[valueId]
// //     }
//     return (
//       <CravoInput
//         label={label}
//         color={color}
//         value={val}
//         valueId={valueId}
//         ind={ind}
//         onChange={(valueId,ind, e)=>{this.onChange(valueId,ind, e)}}
//       />
//     )
//   }
  
  onChange=(valueId, ind, e)=>{
    let val=e.currentTarget.value
    let vals={}
    let arr=this.state[valueId].slice(0)
    arr[ind]=val
    vals[valueId]=arr
    this.props.onChange(vals)
    this.setState(vals)
  }
  
  makeTable=()=>{
    let heads=["Wind Protection", "Wind Speed", "Position", "Delay Out"]
    let colors=[blue, bluGrn, green]
    var row, rows
    rows=[]
    row=<tr key={0}>{heads.map((h,i)=>{return <td key={i}>{h}</td>})}</tr>
//     row=<tr><td>try</td></tr>
//     row=
    rows.push(row)
    for(let i=0;i<3;i++){
      row=(
        <tr key={i+1}><td>{`Crop Protection ${i+1}`}</td>
        <td>
          <CravoInput
            label={"mph"}
            color={colors[i]}
            value={this.state.windSpeed[i]}
            valueId={"windSpeed"}
            ind={i}
            onChange={(valueId,ind, e)=>{this.onChange(valueId,ind, e)}}
          />
        </td>
        <td>
          <CravoInput
            label={"%"}
            color={colors[i]}
            value={this.state.position[i]}
            valueId={"position"}
            ind={i}
            onChange={(valueId,ind, e)=>{this.onChange(valueId,ind, e)}}
          />
        </td>
        <td>
          <CravoInput
            label={"minutes"}
            color={colors[i]}
            value={this.state.delayOut[i]}
            valueId={"delayOut"}
            ind={i}
            onChange={(valueId,ind, e)=>{this.onChange(valueId,ind, e)}}
          />
        </td>
        </tr>
      )
      rows.push(row)
    }
    
    let tab0=(
      <table><tbody>
      {rows}
      </tbody></table>
    )
    rows=[]
    row=<tr key={0}><td colSpan="2" align="left" style={{fontSize: 32}}>{`Vent Overrides`}</td></tr>
    rows.push(row)
    let img=<img src={greenhouse}/>
    row=<tr key={1}><td>{img}</td><td>{tab0}</td></tr>
    rows.push(row)
    return(
      <table><tbody>
      {rows}
      </tbody></table>
    )
  }
  
  render(){
    return(
      <div className="crop-protection-wrapper">
        <div style={{height: 10}}/>
        {this.makeTable()}
      </div>
    )
  }
}

export default CropProtection ;
