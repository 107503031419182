import React from 'react';
import * as d3 from 'd3';
import {cl, constant} from '../../components/utils/utils';

class Graph extends React.Component{
  constructor(props) {
    super(props);
//     let d=props.config.data[0].data.slice(0)
//     cl(d)
//     cl(props);
/* the data to graph is in config.data which is in an array:
channel, color, index, pid, yTicks, zone, *and* data, which is an array of points: t and d
*/
    this.svgDiv=React.createRef();
    this.svg=null
  }
  
  componentDidMount() {
    this.graph=this.Graph3(this.svgDiv.current);
  }
  
  colorToRGB=(col)=>{ // #112233
    let col2=parseInt("0x"+col.substring(1))
    return [col2>>16, (col2>>8)%256, col2%256] 
  }
  
  colorIsDark=(col)=>{
    let [r,g,b]=this.colorToRGB(col)
    return r*r+g*g+b*b<100000
  }

  Graph3=(container)=>{// returns this object
    var mar, xAdd, yAdd, margin, width, height, parseTime, x
    var data, cnt, svg, myContainer, singleColors
    var myContainer=container
    var barWidth=5
    var barSpace=5
    var bsParms={}
    
    let o={}
    let ya=[]// array of y objects
    let valuelineA=[]
    o.gotY=()=>{
      let gotY=false
      this.props.config.data.forEach((dataP,i)=>{// data is an array of lines
        gotY|=dataP.yTicks
      })
      return gotY
    }
    
    o.makeLiteColor=(color,color2,k)=>{// actually, should fade to *background* color, not white
      cl([color, color2, k])
      let[r,g,b]=this.colorToRGB(color)
      let[rb,gb,bb]=this.colorToRGB(color2/*this.props.config.bgColor*/)
//       let col=parseInt("0x" + color.substring(1))
//       let r=col>>16
//       let g=(col>>8)%256
//       let b=col%256
//       
//       let m=255
      r=Math.floor(r*k+rb*(1-k))
      g=Math.floor(g*k+gb*(1-k))
      b=Math.floor(b*k+bb*(1-k))
      let col2=(r<<16)+(g<<8)+b
      return "#"+col2.toString(16)
    }
    
    o.fillLine=(dataP, min, max)=>{
//       cl(dataP)
      let first=Object.assign({},dataP.data[0])
      let last=Object.assign({},dataP.data[dataP.data.length-1])
      min-=(dataP.verticalOffset||0)//+dataP.ofsMinMax.max-dataP.ofsMinMax.min
      first.d=min
      last.d=min
//       cl(first)
//       cl(last)
      dataP.data.push(last)
      dataP.data.push(first)
      dataP.fillColor=o.makeLiteColor(dataP.color, this.props.config.bgColor,0.2)
    }
    
    o.removeLine=(dataP)=>{
      dataP.data.pop()
      dataP.data.pop()
    }
    
    o.initVars=()=>{
//       cl("init vars")
//       cl(this.props)
      mar = this.props.config.margin;
      if(this.props.config.periodType=="barSingle"){
//         cl("mags")
        xAdd=0
        yAdd=0
      }else{
        xAdd = (this.props.config.xTicks > 0) ? 20 : 0;
        yAdd = (o.gotY()) ? 27 : 0;
      }
      xAdd+=(!!this.props.config.title) ? 15 : 0
//       cl(this.props.config.showValueNames)
      xAdd+=(this.props.config.showValueNames) ? 10 : 0
      margin = {top: mar, right: mar, bottom: mar + xAdd, left: mar + yAdd}
      width = this.props.config.width - margin.left - margin.right - barWidth
      height = this.props.config.height - margin.top - margin.bottom
      parseTime = d3.timeParse("%d-%b-%y");
      if(this.props.config.periodType=="barInterval"){
        let barCount=this.props.config.data[0].data.length
        barWidth=Math.floor(width/barCount)
        barSpace=Math.floor(0.2*barWidth)
        barWidth-=barSpace
      }
      
      
      // set the ranges
      x = d3.scaleTime().range([0, width]);
      if(this.props.config.periodType=="barSingle"){
        width+=barWidth+1
//         cl("bar single")
        let cnt=this.props.config.data.length
//         cl(width)
//         cl(barWidth)
        bsParms={
          space: 5,
          count: cnt,
          width: Math.floor((width+barWidth)/cnt),
        }
        singleColors={}// colors for the single bar display
        singleColors[constant.SN_inTemperature]=[
          [40, "#556379"],
          [60, "#5c6b5d"],
          [80, "#776353"],
          [100, "#77555c"],
          [120, "#FFFFFF"],
        ]
      }
    }
    
    o.appendSingleBarPath=(dataP, i)=>{// need
//       if(isNaN(height))return
      cl(dataP)
//       let isDark=colorIsDark(this.props.config.bgColor)
      cl(this.props.config)
      let textFill=(this.colorIsDark(this.props.config.bgColor))?"white":"black"
      let textSize=Math.floor((bsParms.width)/12)
      cl(textFill)
      this.svgPath=this.svgMargins //.append("path")
      let cols=singleColors[dataP[2]]
      let cnt=cols.length
      let min=cols[0][0]
      let max=cols[cnt-1][0]
//       cl([min,max])
      var j
      cl(dataP)
      cl(dataP[1])
      for(j=0;j<cnt;j++){
        if(cols[j][0]>dataP[1]){break}
      }
      let fill=cols[j-1][1]
      let val=Math.floor(height*(dataP[1]-min)/(max-min))
//       cl(val)
      let rectPar=this.svgPath
      if(this.props.config.graphType=="3dBar"){
        cl("3dbar")
        let lite=o.makeLiteColor(cols[j-1][1], "#FFFFFF",0.6)
        cl(lite)
//         cl(height)
//         cl(val)
//         cl(-5+height-val)
        let y0=-5+height-val
        cl([height, val])
        if(y0<0)y0=0
        rectPar.append("rect")
        .attr("class", "bar")
        .attr("x", bsParms.width*i)
        .attr("y", y0)
        .attr("width", bsParms.width-barSpace)
        .attr("height", val)
        .attr("fill", lite)
        rectPar.append("rect")
        .attr("class", "bar")
        .attr("x", bsParms.width*i+1)
        .attr("y", -5+height-val+1)
        .attr("width", bsParms.width-barSpace-1)
        .attr("height", val-1)
        .attr("fill", fill)
      }else{
        rectPar.append("rect")
        .attr("class", "bar")
        .attr("x", bsParms.width*i)
        .attr("y", -5+height-val)
        .attr("width", bsParms.width-barSpace)
        .attr("height", val)
        .attr("fill", fill)
      }
      if(this.props.config.showValueNames){
        rectPar.append("text")
        .attr("style", `font-size: ${textSize}px; text-anchor: middle; fill: ${textFill};`)
        .attr("x", bsParms.width*(i+0.5)-2)
        .attr("y", height+textSize-2)
        .text(dataP[0])
      }
      if(this.props.config.showValues){
        rectPar.append("text")
        .attr("style", `font-size: ${textSize}px; text-anchor: middle; fill: ${textFill};`)
        .attr("x", bsParms.width*(i+0.5)-2)
        .attr("y", height-val-8)
        .text(dataP[1])
      }
    }
    
    o.appendBarPath=(dataP,i)=>{// needs to use offset values, too
      cl(dataP)
      let vo=+dataP.verticalOffset
      let lite=o.makeLiteColor(dataP.color, "#FFFFFF",0.2)
      this.svgPath=this.svgMargins //.append("path")
      let fill=dataP.fillColor || "none"
      let rectPar=this.svgPath
      .selectAll(".bar"+i)
      .data(dataP.data)
      .enter()
      
      if(this.props.config.graphType=="3dBar"){
        rectPar.append("rect")
        .attr("class", "bar"+i)
        .attr("x", (d)=>{return x(d.date)})
        .attr("y", (d)=>{return ya[i](d.d)})
        .attr("width", barWidth)
        .attr("height", d=>{return ya[i](dataP.min)-ya[i](d.d)})
        .attr("fill", lite)
        rectPar.append("rect")
        .attr("class", "bar")
        .attr("x", (d)=>{return 1+x(d.date)})
        .attr("y", (d)=>{return 1+ya[i](d.d)})
        .attr("width", barWidth-1)
        .attr("height", d=>{return ya[i](dataP.min)-ya[i](d.d)-1})
        .attr("fill", dataP.color)      
      }else{
        rectPar.append("rect")
        .attr("class", "bar+1")
        .attr("x", (d)=>{
//           cl(ya[i](vo+d.d))
//           cl(ya[i](dataP.min)-ya[i](d.d))
          
//           cl(d)
          return x(d.date)})
        .attr("y", (d)=>{
          return ya[i](vo+d.d)})
        .attr("width", barWidth)
        .attr("height", d=>{
          let h=ya[i](dataP.min)-ya[i](d.d)
          if(h<0)h=0
          return h})
        .attr("fill", dataP.color)      
      }
      
      
//       .attr("class", "line")
//       .attr("style", `fill: ${fill}; stroke-width: 2px; stroke: ${dataP.color};'`)
//       .attr("d", valuelineA[i]);
//       cl(this.props.config.graphType)
    }
    
    o.appendCurrentValuePath=()=>{
      let da0=this.props.config.data[0].data
//       cl(da0)
      var curValue
      if(da0.length){
        curValue=da0[da0.length-1].d
      } else{
        curValue=75
      }
//       cl(curValue)
//       cl([width,height])
      let y0=Math.floor((height-28)/2)
      y0+=(!!this.props.config.title) ? 15 : 0      
//       cl(y0)
      let fill=this.props.config.currentValueColor || "black"
      
      this.svgMargins
      .append("text")
      .attr("style", `font-size: 28px; font-weight: 1000; text-anchor: middle; fill: ${fill}`)
      .attr("x", this.props.config.width/2)
      .attr("y", (this.props.config.height-28)/2)
      .text(curValue)
    }
    
    o.appendPath=(dataP,i)=>{// this actually draws the line
      let gt=this.props.config.graphType
//       cl(gt)
      if((gt=="bar")||(gt=="3dBar")){return o.appendBarPath(dataP,i)}
      this.svgPath=this.svgMargins.append("path")
      let fill=dataP.fillColor || "none"
      this.svgPath
      .data([dataP.data])
      .attr("class", "line")
      .attr("style", `fill: ${fill}; stroke-width: 2px; stroke: ${dataP.color};'`)
      .attr("d", valuelineA[i]);
    }
    
    o.getVertOffsets=()=>{
      let offsets={}
      this.props.config.data.forEach((dataP,i)=>{
        let pid=dataP.pid
        if(!offsets[pid]){offsets[pid]={min:0, max:0}}
        let ofs=+(dataP.verticalOffset || 0)
        if(offsets[pid].min>ofs)offsets[pid].min=ofs
        if(offsets[pid].max<ofs)offsets[pid].max=ofs
      })
      this.props.config.data.forEach((dataP,i)=>{
        dataP.ofsMinMax=offsets[dataP.pid]
      })
    }
    
    o.configData=()=>{
      o.getVertOffsets()
      cnt=this.props.config.data.length
      ya=[]
//       let da0=this.props.config.data[0].data
//       let curValue=da0[da0.length-1].d
//       cl(curValue)
//       cl(da0)
      this.props.config.data.forEach((dataP,i)=>{
//         cl(dataP)
//         cl(this.props.config)
        let isBar=((this.props.config.graphType=="bar")||(this.props.config.graphType=="3dBar"))
        if(isBar&&(this.props.config.periodType=="barSingle")){
          o.appendSingleBarPath(dataP, i)
        }else{
          if(dataP.data){
            let min=d3.min(dataP.data, function(d) { return +d.d; })
            let max=d3.max(dataP.data, function(d) { return +d.d; })
            let range=max-min
            let avg=(max+min)/2
            let dev=1.2*(max-min)/2
            if(range<avg*0.1){
              dev=0.05*max
            }
            max=avg+dev+dataP.ofsMinMax.max
            min=avg-dev+dataP.ofsMinMax.min
            dataP.min=min
            let ofs=+(dataP.verticalOffset || 0)
            if(dataP.fillBelow){o.fillLine(dataP, min, max)}
            ya[i]=d3.scaleLinear().range([height, 0]).domain([min, max])
            dataP.data.forEach(function(d) {
              d.date = new Date(1000*d.t);// (d.date);
              d.close = +d.d;
            });
          // Scale the range of the data
            x.domain(d3.extent(dataP.data, function(d) { return d.date; }));
            valuelineA[i]=d3.line()
              .x(function(d) { return x(d.date); })
              .y(function(d) { return ya[i](d.d+ofs); });
            o.appendPath(dataP, i)
            if(dataP.fillBelow){o.removeLine(dataP)}
          }
        }
      })
      if(this.props.config.showCurrentValue){return o.appendCurrentValuePath()}
    }
    
    o.addXAxis=()=>{
      // Add the X Axis
//       cl(this.props.config)
      if(this.props.config.xTicks > 0){
        this.svgXAxis=this.svgMargins.append("g")
        this.svgXAxis
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x)
          .ticks(this.props.config.xTicks)
        );
      }
    }
    
    o.addYAxis=(i, pos, color)=>{
      
      // Add the Y Axis
        this.svgYAxis=this.svgMargins.append("g")
        let tickSize=(i==0)?-width:0
        let style=`fill: none; color: ${color}; stroke: currentcolor'`
        this.svgYAxis
          .attr("transform",`translate(${pos},0)`)
          .attr("style", style)
          .call(d3.axisLeft(ya[i])
          .tickSize(tickSize)
          .ticks(this.props.config.data[i].yTicks)
          .tickSizeOuter(0)
        );
    }
    
    o.createSvg=()=>{
    // parse the date / time
    // append the svg obgect to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
      if(this.svg){
        this.svg.remove()// supposed to remove all existing svg elements
      }
      this.svg = d3.select(myContainer).append('svg:svg')// if there's a title, this get appended after it
        this.svg
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
        this.svgMargins=this.svg.append("g")
//         cl(margin)
        this.svgMargins
          .attr("transform",
              "translate(" + margin.left + "," + margin.top + ")");
    }
    
    o.render=()=>{
      o.initVars()
      o.createSvg()
      o.configData()
      let isBar=((this.props.config.graphType=="bar")||(this.props.config.graphType=="3dBar"))
      if(!isBar || (this.props.config.periodType!="barSingle")){o.addXAxis()}
      let j=0
      this.props.config.data.forEach((dp,i)=>{
        if(+dp.yTicks){
          o.addYAxis(i, 20*j++, dp.color)
        }
      })
    }
    
    o.render()
    return o
  }
  
    render(){
      let dm=5
      let p={w: 100, title: this.props.config.title}
      if(this.graph){
        this.graph.render()
      }
//       cl(this.props.config.bgColor)
      this.colorIsDark(this.props.config.bgColor)
      let textCol=(this.colorIsDark(this.props.config.bgColor)) ? "white" : "black"
    return(
      <div ref={this.svgDiv} 
      style={{
        width: +this.props.config.width, 
        height: +this.props.config.height, 
        margin: "auto",
        borderRadius: this.props.config.radius,
        backgroundColor: this.props.config.bgColor}} >
          <div style={{width: this.props.config.width,
            textAlign: "center", fontSize: .12*p.w, padding: .02*p.w, color: textCol}}>{p.title}</div>
        </div>
    );
  }
}

export default Graph ;
