import React from 'react';
// import CravoInput from './CravoInput'
import CravoSetpoint1 from './CravoSetpoint1'
import {cl, globs, leadZeros} from '../../components/utils/utils';

const cream="#f5f5dc"
const aqua="#00ffff"
const yellow="#fffacd"
const green="#98fb98"

class CravoSetpoint0 extends React.Component{
  constructor(props) {
    super(props);
//     this.props.getValue(props.ind, {type: "scalar"})
    this.state=Object.assign(this.props.parms,this.props.values)//[props.parms.chan]
//     cl(this.state)
//     this.state={
//       value: "",
//       inTime: 23,
//       pos: [],
//       thresh: [],
//       deadband: 0,
//       outTime: 33,
//     }
//     for(let i=0;i<8;i++){this.state.pos[i]=i; this.state.thresh[i]=5+i}
  }
/*
5 columns: WB->GH, Position, Threshold, Deadband, WB->OF
8 rows: Setpoint 1-8
units: min, % degF
<input style="padding-right:20px; text-align:right;" value="50"></input><span style="margin-left:-20px;">lb</span>
Fiddle

colors blue1: 55b4d2
blgr: 71e0b6
grn: 71e0b6
cream: f5f5dc
aqua: 00ffff
yellow: fffacd
grn2: 98fb98

#4ce743
cool1: #42dc4e
2: #3ad25f
3: #33c774
4: #2ebb91
5: #29a8ac
6: #2274a4

*/  

  onChange=(valueId, ind, e)=>{// this is called from CravoInput as "that".onChange
    let val=e.currentTarget.value
    let vals={}
    let dgInd=this.props.parms.chan
    let obj=Object.assign({},this.state[dgInd])
    if(this.isArray(valueId)){
      let arr=obj[valueId].slice(0)
      arr[ind]=val
      vals[valueId]=arr
    }else{
      vals[valueId]=val
    }
    obj=Object.assign(obj,vals)
    let vals2={}
    vals2[dgInd]=obj
    this.setState(vals2)
    this.props.onChange(vals)
  }
  
  isArray=(valueId)=>{
    return ["pos","thresh"].includes(valueId)
  }
  
//   cravoInput2=(label, color, valueId, ind)=>{
//     let wids={"min": 40, "%": 30, "\u00B0F": 30}
//     let pRight={"min": 30, "%": 20, "\u00B0F": 20}
//     var val
//     if(this.isArray(valueId)){
//       val=this.state[valueId][ind]
//     }else{
//       val=this.state[valueId]
//     }
//     return[
//       <input key={0}
//       type="number"
//       style={{marginLeft: "0.6em", width: wids[label], paddingRight: pRight[label], textAlign: "right", backgroundColor: color}}
//       value={val}
//       onChange={e=>this.onChange(valueId, e, ind)}
//       />,
//       <span key={1} style={{marginLeft: 0-pRight[label]}}>{label}</span>
//     ]
//   }
  
//   cravoInput=(label, color, valueId, ind)=>{
//     var val
//     if(this.isArray(valueId)){
//       val=this.state[valueId][ind]
//     }else{
//       val=this.state[valueId]
//     }
//     return (
//       <CravoInput
//         label={label}
//         color={color}
//         value={val}
//         valueId={valueId}
//         ind={ind}
//         onChange={(valueId, e, ind)=>{this.onChange(valueId, e, ind)}}
//       />
//     )
//   }
  
  makeTable=(c, color, chan)=>{
//     cl(chan)
    let titles={WB: "Wind Break", OF: "Open Field", SH: "Shade House", HI: "Humidity Increase"}
    let rows=[]
    var row
    row=<tr key={-2}><td colSpan="5" align="center">{`${titles[c[1]]} (${c[1]})`}</td></tr>
    rows.push(row)
    row=(
      <tr key={-1} align="center">
      <td></td>
      <td>{`${c[0]}->${c[1]}`}</td>
      <td>{`${c[1]}->${c[0]}`}</td>
      <td>Position</td><td>Threshold</td><td>Deadband</td>
      </tr>
    )
    rows.push(row)
    for(let i=0;i<8;i++){
      switch(i){
        case 0:
          row=(
            <tr key={i}>
            <td>{"Setpoint "+(i+1)}</td>
            <CravoSetpoint1 parms={{
              that: this,
              dataGroup: "state",
              dgInd: this.props.parms.chan,
              color: color,
              i: i,
            }}/>
            </tr>
          )
          break
        default:
          row=(
            <tr key={i}>
            <td>{"Setpoint "+(i+1)}</td>
            <CravoSetpoint1 parms={{
              that: this,
              dataGroup: "state",
              dgInd: this.props.parms.chan,
              color: color,
              i: i,
            }}/>
            </tr>
          )
          break
      }
      rows.push(row)
    }
    return(
      <table><tbody>
      {rows}
      </tbody></table>
    )
  }
  
  render(){
//     cl(this.state)
//     cl(this.props.parms.trans)
    let classes={GH:"setpoint-wrapper-1", WB:"setpoint-wrapper-2", OF:"setpoint-wrapper-3", SH:"setpoint-wrapper-4"}
    return(
      <div className={classes[this.props.parms.trans[0]]}>
      <div/>
      {this.makeTable(this.state.trans, this.state.color, this.state.chan)}
      </div>
    )
  }
}

export default CravoSetpoint0 ;
