import React from 'react';
import Graph01 from '../../visualization/components/Graph01.js'
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18Button00 from './C18Button00'
import C18TabsHeader00 from './C18TabsHeader00'
import C18SiteZoneView00 from './C18SiteZoneView00'
import C18Anchor00 from './C18Anchor00'
import GrowJournalWidgetEntry01 from '../../visualization/components/GrowJournalWidgetEntry01'
import Select from 'react-select'
import { DateRangePicker } from 'react-date-range';
import {dbVals,getZoneControllers} from '../../components/utils/http'
import {wsTrans,sensorIds,chanPosIds,doGetPostBasic,setSensorIdNames,getChannelsInfo,
  initSensorIds,getPe} from '../utils/utils'
import {cl, globs,constant,getTime,dateToShortMonth,dateToDisplayDate,az,
  getRandomString,secondChannels} from '../../components/utils/utils';
import {loadSitesInfo,loadSiteData,loadSensorsInfo,getSensorsZone,loadZonesInfo,getZoneInfo,
  getSiteIndex,loadPrivsInfo,loadMBInfo,privs,
  getZoneIndex,loadPresetsInfo,getPresetIndex,getSiteName,getZoneName,
  getZoneId,loadTagsInfo,getChannelType,getChannelUnit,getChannelDefault,getAlarmInfo,getSiteInfo,getZoneInfoFromSI,
  getUserIndex,makeAvatarPath,acctFeature,getChannelUnitGroup, getSetpoints} from './C18utils'
import {getDatapointName} from '../../usa/utils/utils'
import '../../rdr_styles.css'
import '../../rdr_default.css'
import { defaultStaticRanges } from "react-date-range";
import { createStaticRanges } from "react-date-range"
import { addDays, subHours, subMinutes } from "date-fns";

class C18Graphing00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.notifies={}
    let now=getTime()
//     cl(now)
    // what is this for?
    this.history={
      mainGraph:{from: now, to: now},
      guideGraph:{from: now, to: now},
      eventTimeline:{from: now, to: now},
    }
    this.colorArr=[
    "#009fb9", 
    "#df568b", "#9b8610", "#348bed", "#dc5e59", "#679525", 
     "#00a086", 
    "#9478e0", 
      "#c3732e", "#009d52", "#0098df", "#c862bc"]
//     this.mainSvgDiv=React.createRef();
//     this.selectSvgDiv=React.createRef();
//     this.chartElements=[],
    let toDate=new Date()// now
    var fromDate
    (fromDate=new Date()).setDate((toDate.getDate()-7))
//     cl(fromDate)
    let title="New Graph"
    let p=props.parms

    p.onChange({cmd:"pageTitle",data:{pageTitle:title}})
    this.state={
      pageTitle: title,
      presetLevel:"zone",
      saveViewTime: "relative",
      saveViewName: "New Graph",
      currentPreset:null,
      chartElements:[
        {value:"inT",label:"Inside Temperature"},
      ],
      chartEvents:[
        {value:"grj",label:"Grow Journal"},
      ],
      showTimePeriod: "none",
      timePeriod: {
        startDate: fromDate,
        endDate: toDate,
        key: 'selection',
      },
      saveMsg:"Save",
      axisOptions: -1,
      axisOptionsMode:"default",
      axisOptionsColor:"#009fb9",
      axisOptionsMin:-40,
      axisOptionsMax:140,
      axisOptionsUnit:"%",//"\u00B0F",
      eventEntry:[],
      site:p.site,
      zone:p.zone,
      showEventOptions: -1,
      className: 'filled',
      loading: {},
      saveEnabled: true,
//       axisOptionsSel:0,
//       presets:[
//         {level: "zone", site:"0sna8jVYIh0xw6oF", zone:"Cw07JvgkDXlrvGnQ", name:"Current", time:"l24", sensors:["inT"], mod:false}
//       ],
    }
//     if(p.pageType=="editGraph"){this.setEditSiteZone()}
    let level=this.getPresetLevel()
    this.mobile = (globs.userData?.winSize.length > 0 ? globs.userData?.winSize[0] : 1000)<460
//     this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveGateways)
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveView)
    this.subscribe_createGrowJournalEvent=globs.events.subscribe("createGrowJournal",this.createGrowJournal)
    this.loadInfo()
    if(this.props.notify){this.props.notify({id: "newSensor", func: this.onNewSensor})}
    this.dark=((globs.device?.deviceTheme||"").toLowerCase().indexOf("dark")>=0)?1:0
    this.qc = p.site != null && p.zone != null
//     cl(globs.presetsInfo)
//     this.setBreadCrumbs()
  }

  componentWillUnmount=()=>{
//     cl("component unmounted")
    this.mounted=false
    clearInterval(this.saveInt)
    this.saveCurrentPreset()
    this.subscribe_createGrowJournalEvent.remove()
    this.subscribe_savePageEvent.remove()
    document.removeEventListener("mouseup", this.handleEventClose)
    document.removeEventListener("keyup", this.handleEventClose)
    if(this.props.notify){this.props.notify({id: "newSensor", func: this.onNewSensor, unMount: true})}
  }

  onNewSensor=(sensor)=>{
//     cl(sensor)
    this.handleNewSensor(sensor.data)
  }
  
//   setEditSiteZone=()=>{
//     
//   }
  
  setBreadCrumbs=async()=>{
// the breadcrumbs can be /graphing, /sites/xxx/graph, /sites/xxx/zones/xxx/graph, /sites/xxx/zones/xxx/sensor/xxx
// props can contain site: zone: sensor: equip:
    await loadSitesInfo()
    await loadZonesInfo()
    let siteName=getSiteName(this.props.parms.site)
    let zoneName=getZoneName(this.props.parms.zone)
    let presetName=globs.presetsInfo.info[getPresetIndex(this.props.parms.presetId)]?.name||"New Graph"
    let p=this.props.parms
    if(p){
//       cl(this.props)
      let bc=[
        {t:"Sites", url:"/usa/c18/sites"},
      ]
      if(p.site){
        bc.push(
          {t:siteName, url:`/usa/c18/sites/${p.site}`},
        )
      }
      if(p.zone){
        bc.push(
          {t:zoneName, url:`/usa/c18/sites/${p.site}/zones/${p.zone}`},
        )
      }
      if(["viewGraph","editGraph"].includes(p.pageType) && p.presetId){
        bc.push({t:"Graphs", url:`/usa/c18/reports/graphList`})
        if(p.pageType=="viewGraph"){
          bc.push({t:presetName, url:`/usa/c18/reports/viewGraph/${p.presetId}`})
        }else{
          bc.push({t:presetName, url:`/usa/c18/reports/editGraph/${p.presetId}`})
        }
      }else{
        if (p.site && p.zone) {
          bc.push(
            {t:"Graphing", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/graphing`},
          )
        } else {
          bc.push({t:"Graphs", url:`/usa/c18/reports/graphList`})
        }
      }
//       cl(bc)
      p.onChange(
        {
          cmd: "breadcrumbs",
          data: {breadcrumbs:bc},
        },
      )
    }
  }

  getParentId=(node)=>{
    var id = ""
    do{
      let lc=node.tagName.toLowerCase()
      if(lc=="html"||id=="eventEntry"){return id}
      if(node.id){id = node.id}
    }while(node=node.parentNode)
    return id
  }

  handleEventClose=(e)=>{
    if(this.state.eventEntry.length) {
      if (e instanceof MouseEvent) {
        let parentId = this.getParentId(e.target)
        if(parentId != 'eventEntry'){
          this.onChange("entryClose",{})
        }
      } else if (e instanceof KeyboardEvent && e.key == "Escape") {
          this.onChange("entryClose",{})
      }
    }
  }
  
  componentDidMount=()=>{
    window.onbeforeunload=x=>{
      this.saveCurrentPreset()
//       x.preventDefault()
    }
    // add onclick to close any event box that's open
    document.addEventListener("mouseup", this.handleEventClose)
    document.addEventListener("keyup", this.handleEventClose)
    this.mounted=true
  }
  

//   routerWillLeave=(next)=>{
//     cl("leave")
//     alert("hey")
//   }
  
  mySetState=(source,vals)=>{
//     cl(source,vals.currentPreset.sensors)
//     cl(vals)
    if(this.mounted){
//       cl("state")
      // check against current preset to see if save needs to be made
      var saveEnabled = false
      if (this.state.currentPreset != null && ((vals.currentPreset 
        && (JSON.stringify(this.state.currentPreset) != JSON.stringify(vals.currentPreset)))
        || ((vals.saveViewTime) && (this.state.saveViewTime != vals.saveViewTime))
        || ((vals.saveViewName) && (this.state.saveViewName != vals.saveViewName)))
        ) {
          saveEnabled = true
          // only enable save dialog on leave for non-qc graphs
          if (!this.qc) {
            this.props.parms.onChange({cmd:"saveHeader",data:{saveEnabled:saveEnabled}})
          }
      }
      Object.assign(vals, {saveEnabled: saveEnabled})
      this.setState(vals)
    }else{
      Object.assign(this.state,vals)
    }
  }
  
  formatTime=(ts)=>{
    let da=new Date(ts*1000)
    let mm=da.getMonth()// 0 based
    let mmm=dateToShortMonth(da)
    let dd=da.getDate()
    let yyyy=da.getFullYear()
    let HR=da.getHours()
    let hr=1+(HR+11)%12
    let ap=(HR>=12)?"PM":"AM"
    let mn=da.getMinutes()
//     cl(mmm)
    return `${mmm} ${dd} ${hr}:${az(mn,2)}${ap}`
  }
  
  getPresetLevel=()=>{
    var level="account"
    if(this.state.site){level="site"}
    if(this.state.zone){level="zone"}
//     cl(level)
    return level
  }
  
  makeCurrentPreset=(p=null)=>{
//     cl("make current")
/* the preset format should allow for readings from different zones and sites*/
// { 
// "_id" : ObjectId("6124e9ab6a4c77b12b4fd113"), 
// "presetId" : "KnZEXee9OtlbX8af", 
// "level" : "zone", 
// "site" : "0sna8jVYIh0xw6oF", 
// "zone" : "0SuR7ufdLEkMlTlh", 
// "name" : "Current", 
// "time" : "l24", 
//   "sensors" : [ { "v" : "inT", "z" : "0SuR7ufdLEkMlTlh" } ], 
// "sensors" : [ "inT" ], 
// "mod" : false, 
// "fromTime" : 1630016259.7604475, 
// "toTime" : 1630150524.7604475, 
// "fromSelect" : 1630099582, 
// "toSelect" : 1630131962, 
// "accountId" : "a036uzDCxohZ7ovD" }
/* we don't have a Current preset for this Level, Site, Zone*/
//     cl(this.props)
//     var site,zone
//     var level="account"
    var now=getTime()
    var fromTime=now-86400
    let level=this.getPresetLevel()

//     cl(this.props.parms)
//     if(this.props.parms.site){level="site"}
//     if(this.props.parms.zone){
//       level="zone"
//       zone=this.props.parms.zone
//     }else{
//       zone=globs.zonesInfo.info[0].zoneId
//     }
    // default events and sensors
    let events=["grj", "adl", "cte", "ala"].map(s=>{
      return({v:s,z:p.zone})
    })
    let sensors=(this.qc) ? ["inT"].map(s=>{
      return({v:s,z:p.zone})
    })
    :
      []
    let cp={
      presetId:getRandomString(16),
      level:level,
      site:p.site,
      zone:p.zone,
      name:"Current",
      sensors: sensors,
      mod:false,
      fromTime:fromTime,
      fromSelect:fromTime+(now-fromTime)/2,
      toTime:now,
      toSelect:now,
      events: events,
      userId:globs.userData.session.userId,
      dataMarkers:false,
      vBar:true,
      combinedYAxis:true,
      mobileView:false,
      zoomBar:true,
      showEvents: true,
      t:"Current",
    }
    return cp
  }
  
  checkSelectTimes=(p)=>{
  }
  
//   findCurrentPresetO=()=>{
//     
//     let gpi=globs.presetsInfo.info
//     cl(this.state.presetLevel)
//     for(let i=0;i<gpi.length;i++){
//       let p=gpi[i]
//       switch(this.state.presetLevel){
//         case "zone":
//         if((p.level==this.state.presetLevel)&&(p.zone==this.props.parms.zone)&&(p.name=="Current")){
//           return p
//         }
//         case "site":
//           if((p.level==this.state.presetLevel)&&(p.site==this.props.parms.site)&&(p.name=="Current")){
//             return p
//           }
//         case "account":
//           if((p.level==this.state.presetLevel)&&(p.name=="Current")){
//             return p
//           }
//       }
//     }
//   }
  
  getCurrentPreset=(p=null)=>{// find a preset for this level and user
// { "_id" : ObjectId("612a3c753b3f691738ab756b"), 
//   "presetId" : "fGnvc1wUTmr8IMuJ", 
//   "level" : "zone", 
//   "site" : "0sna8jVYIh0xw6oF", 
//   "zone" : "0SuR7ufdLEkMlTlh", 
//   "name" : "Aug 24", 
//   "time" : "re", 
//   "sensors" : [ { "v" : "inT", "z" : "0SuR7ufdLEkMlTlh" } ], 
//   "mod" : false, 
//   "fromTime" : 1629788400, 
//   "toTime" : 1629874800, 
//   "fromSelect" : 1629795524.6474445, 
//   "toSelect" : 1629864831.6474445, 
//   "accountId" : "a036uzDCxohZ7ovD", 
//   "t" : "Aug 17", 
//   "v" : "eYtLIZwMe3gU8mbI" }
    
    let preset=this.findCurrentPreset(this.state.presetLevel, this.state.site, this.state.zone,
      globs.userData.session.userId
    )
    if(preset && preset.fromTime && preset.toTime){
      if((preset.fromSelect>preset.toTime)||(preset.fromSelect<preset.fromTime)){preset.fromSelect=preset.fromTime}
      if((preset.toSelect>preset.toTime)||(preset.toSelect<preset.fromTime)){preset.toSelect=preset.toTime}
      if(preset.toSelect-preset.fromSelect<100){preset.toSelect=preset.fromSelect+100}
      return preset
    }else{
      return this.makeCurrentPreset(p||{})
    }
    
//     let gpi=globs.presetsInfo.info
//     for(let i=0;i<gpi.length;i++){
//       let p=gpi[i]
//       switch(this.state.presetLevel){
//         case "zone":
//         if((p.level==this.state.presetLevel)&&(p.zone==this.props.parms.zone)&&(p.name=="Current")){
//           this.checkSelectTimes(p)
//           cl(p)
//           return p
//         }
//           break
//         case "site":
//           if((p.level==this.state.presetLevel)&&(p.site==this.props.parms.site)&&(p.name=="Current")){
//             cl(p)
//             return p
//           }
//           break
//         case "account":
//           if((p.level==this.state.presetLevel)&&(p.name=="Current")){
//             cl(p)
//             return p
//           }
//           break
//       }
//     }
//     return this.makeCurrentPreset()
  }
  
  getPresetIndex=(presetId)=>{
    let gpi=globs.presetsInfo.info
    for(let i=0;i<gpi.length;i++){
      if(gpi[i].presetId==presetId){ return i }
    }
    return -1// not found
  }
  
  savePresetLocal=(pr)=>{// if presetId is found, then update, else insert
    if(!pr){return}
    if((pr.t=="Custom")&&(pr.name=="Custom")){return}
    // cl(pr)
    let gpi=globs.presetsInfo.info
    let i=this.getPresetIndex(pr.presetId)
//     cl(gpi)
//     cl(pr)
    if(i>=0){
      Object.assign(gpi[i],pr)
    }else{
//       cl("push")
      gpi.push(Object.assign({},pr))
    }
//     for(let i=0;i<gpi.length;i++){
//       let p=gpi[i]
//       if(p.presetId==pr.presetId){//this.state.currentPreset
//         Object.assign(gpi[i],pr)
//         return
//       }
//     }
//     gpi.push(pr)// if presetId is not found
  }
  
  loadPreset=(presetId)=>{
    let i=this.getPresetIndex(presetId)
    var cpx
    if(i>=0){// if found
      let gpi=globs.presetsInfo.info
      let pr=Object.assign({},gpi[i])
//       cl(pr)
      let name=pr.name
//       delete pr.name
//       delete pr.presetId
      cpx=Object.assign({},this.state.currentPreset)
      Object.assign(cpx,pr)
//       cl(cpx.t)
//       cl(cpx)
      if(cpx.time=="re"){
        let diff=getTime()-cpx.toTime
        cpx.fromTime+=diff
        cpx.fromSelect+=diff
        cpx.toTime+=diff
        cpx.toSelect+=diff
      }
//       cl(cpx)
      this.makeGraphSensors(cpx)
      this.makeGraphEvents(cpx)
      let pageTitle=`${name} Graph`
//       cl(cpx.sensors)
//       cl(this.state.presetName)
//       cl(name)
      this.mySetState("a",{
        preset: presetId, 
        presetName: name, 
        saveViewName: name,
        saveViewTime:(cpx.time=="re")?"relative":"absolute", 
        currentPreset:cpx,
        pageTitle:pageTitle})
//       cl(this.state.currentPreset.t)
//       cl(this.state.presetName)
//       cl(cpx.t)
//       cl("loadPreset")
      this.props.parms.onChange({cmd:"pageTitle",data:{pageTitle:pageTitle}})
//       this.props.parms.onChange({pageTitle:pageTitle})
//       cl(this.state.currentPreset.sensors)
//       cl(this.sensors[0]?.options?.color)
      this.doNotify({type:"newTimePeriod", 
        vals:{
          sensors: this.sensors,
          fromTime:cpx.fromTime,
          toTime:cpx.toTime,
          fromSelect:cpx.fromSelect,
          toSelect:cpx.toSelect,
          dataMarkers:cpx.dataMarkers,
          vBar:cpx.vBar,
          combinedYAxis:cpx.combinedYAxis,
          mobileView:cpx.mobileView,
          zoomBar:cpx.zoomBar,
        }})
//       cl(this.sensors)
//       this.doNotify({type:"newSensors", vals:this.sensors})
//       cl(presetId)
    }else{
//       cl("no preset")
      this.mySetState("b",{preset: presetId, presetName: "", saveViewName: ""})
    }
//     cl(cpx.t)
    return cpx
//     cl("load preset done")
  }
  
  handleNewSensor=(sensorId)=>{// we have a sensorId
    let site=globs.userData.session.siteId
    let zone=this.props.parms.zone||getZoneId(site,0)
    let sensors=[{v:sensorId,z:zone,}]
    let cp={
      time:"l24",
      site:site,
      zone:zone,
      mod:false,
      level:"none",
      name:"Custom",
      dataMarkers:false,
      vBar:true,
      combinedYAxis:true,
      mobileView:false,
      zoomBar:true,
      t:"Custom",
      sensors:sensors,
      userId:globs.userData.session.userId,
      showEvents:true,
    }
    this.makeTimePeriod(cp)// handles 'l24',etc.
    let diff=cp.toTime-cp.fromTime
    cp.fromSelect=cp.fromTime+diff/2
    cp.toSelect=cp.toTime
    this.makeGraphSensors(cp)
    this.makeGraphEvents(cp)
    this.doNotify({type:"newSensors", vals:this.sensors})// send to Graph01
    this.doNotify({type:"newEvents", vals:this.events})// send to Graph01
  }
  
  setChannelInfo=(zoneId)=>{
//     cl(zoneId)
//     let site=globs.userData.session.siteId
//     let zoneId=this.props.parms.zone||getZoneId(site,0)
    let zone=globs.zonesInfo.info[getZoneIndex(zoneId)]// ||cp.zone is a hack until we can     
//     cl(zoneId)
//     cl(zone.gatewayType)
    let res=getChannelsInfo(zone.siteZoneIndex,this.zoneControllers,zone.gatewayType)// gets for all channels, including expansion controller
//     cl(res)
    let ci={}
    res.forEach(ch=>{
//       cl(ch)
//       if(ch.type<constant.EQ_2ND_VENT){
      if(!secondChannels.includes(ch.type)){
        let u=0//Math.floor(ch.index/40)
        let id=`e${u}c`+(`00${ch.index}`).slice(-2)
        ci[id]=ch
      }
    })
    Object.keys(ci).forEach(k=>{
      let c=ci[k]
//       cl([k, c])
      this.sensorOptions.push({
        value:k,label:c.name,default:c.default
      })
    })
//     ci.forEach(c=>{
//       this.sensorOptions.push({
//       value:c.value,label:c.name
//       })
//     })
//     this.sensorOptions.forEach(so=>{
//       let na=ci[so.value]
//       if(na){
//         so.label=na.name
// //         cl(so.label)
//       }
//     })
//     cl(this.sensorOptions)
//     cl(ci)
    
  }
  
  loadInfo=async()=>{
/*load presets: for this level (zone), get all presets for this user, and for public
 save new presets as level and userId, unless having "save public" privileges
time: the standard ones: lw, ly, etc, also re: relative, ab: absolute: use the from to times*/
//     cl("loadinfo")
//     cl(this.props)
/*time periods:
today
yesterday
this week
last week 
this month
last month 
this year
last year
last hour
last 24 hours
last 7 days
last 31 days
last 365 days
to, ye, tw, lw, tm, lm, ty, ly, l1, l24, l7, l31, l365
preset:
  fromSelect
  fromTime
  level
  mod
  name
presetId
sensors
  site
  time
  toSelect
  toTime
  userId
v
  zone



 */      

    await loadSitesInfo()
    await loadZonesInfo()
    await loadPresetsInfo()
    await loadPrivsInfo()
    await loadTagsInfo()
    await loadMBInfo()// gets devices, indexes, and registers
//     cl(this.props.parms)
    let writePriv=(privs("account",0,constant.AREA_PRIVS_WRITE)!=0)
    let defaultZone=globs.zonesInfo.info.filter(z=>{return z.inNet})[0]
    if(!defaultZone){
      defaultZone=globs.zonesInfo.info[0]
    }
//     cl(globs.zonesInfo)
//     await setSensorIdNames(this.state.zone)
//     await this.setSensorOptionNames(this.state.zone)
//     cl(this.props.parms)
//     cl(globs.presetsInfo.info)
    var cp
    let p=Object.assign({},this.props.parms)
    let pr=globs.presetsInfo.info.filter(pr=>{return pr.presetId==p.presetId})[0]
    if((!this.state.site)&&(!pr)){
      pr={site:defaultZone?.siteId, zone:defaultZone?.zoneId}
//     if(!pr){
//       let z=globs.zonesInfo.info.filter(z=>{return (z.inNet!=0)||z.connected})[0]
//       pr={site:z.siteId,zone:z.zoneId}
    }
    p.site=this.state.site||pr.site// if pr has a site, and state doesn't'
    p.zone=this.state.zone||pr.zone||getZoneId(p.site,0)// zoneId
    if (!p.zone) return
//     cl(this.state)
    await loadSiteData(p.site)
    let zInd=getZoneInfo(p.zone)?.siteZoneIndex
    this.zoneControllers=getZoneControllers(zInd)
    await this.makeSensorOptions(p.zone,zInd)
//     await setSensorIdNames(this.state.zone)
//     await this.setSensorOptionNames(this.state.zone)

    this.setChannelInfo(p.zone)
    if((!p.presetId)&&(!p.sensors)&&(p.sensorId)){// this was blocking the Current preset
      // cl("has preset id")
      // do preset sensor if accessed through quick charts
      // p.sensors= (this.props.parms.zone) ? [p.sensorId||"inT"] : []
      p.sensors=[p.sensorId]
      p.period="l24"
      p.site=globs.userData.session.siteId
      p.events=["grj", "adl", "cte", "ala"]
    }// creating new graph
    if(p.sensors){// custom graph in url what does this mean!!!!
      this.state.presetLevel="none"
      let sensors=p.sensors.map(s=>{
        return({v:s,z:p.zone,})
      })
      let events=p.events.map(s=>{
        return({v:s,z:p.zone})
      })
      cp={
        time:p.period,
        site:p.site,
        zone:p.zone,
        mod:false,
        level:"none",
        name:"Custom",
        dataMarkers:false,
        vBar:true,
        combinedYAxis:true,
        mobileView:false,
        zoomBar:true,
        t:"Custom",
        sensors:sensors,
        userId:globs.userData.session.userId,
        showEvents:true,
        events:events,
      }
      this.makeTimePeriod(cp)// handles 'l24',etc.
      let diff=cp.toTime-cp.fromTime
      cp.fromSelect=cp.fromTime+diff/2
      cp.toSelect=cp.toTime

    }else{
      // cl("no sensors")
//       cl(this.props)
      // p.sensors= (this.props.parms.zone) ? [p.sensorId||"inT"] : []
      this.state.presetLevel="account"// assumes not mounted
      if(p.site){
        if(p.zone){
          this.state.presetLevel="zone"
        }else{
          this.state.presetLevel="site"
        }
      }
      // cl(p)
      if(["viewGraph","editGraph"].includes(p.pageType)){//this.props.parms.pageType=="viewGraph"){
//         cl("view/edit graph")
        if(p.presetId){
          cp=this.loadPreset(p.presetId)
        }else{
          cp=this.getCurrentPreset(p)
          // cp=this.makeCurrentPreset()
        }
//         cp=globs.presetsInfo.info[getPresetIndex(p.presetId)]
//         cl(cp)
      }else{
        cl("get current")
        cp=this.getCurrentPreset(p)
      }
    }
    this.makeGraphSensors(cp)
    this.makeGraphEvents(cp)
    this.mySetState("c",{loaded:true, /*presets:presets, */currentPreset:cp,site:p.site,zone:p.zone,
      writePriv:writePriv
    })
    this.setBreadCrumbs()
//     cl(globs.presetsInfo.info)
//     cl(this.state)
  }
  
//   addGraphSensors=(cp)=>{
// //     cl(cp)
// //     cl(this.props)
// //     cl(cp.sensors)
//     cp.gs=[]
//     cp.sensors.forEach(s=>{
// //       cl([s.v, s.z])
//       if(!s.z){// temporary to handle old-style presets without zone info for each sensor
//         let sensor=s
//         s={
//           v:sensor,
//           z:this.props.parms.zone,
//         }
//       }
//       let zone=globs.zonesInfo.info[getZoneIndex(s.z)]
// //       cl(s)
// //       cl(getZoneIndex(s.z))
//       cp.gs.push({
//         s:zone.siteId,
//         z:zone.siteZoneIndex,
//         c:sensorIds[s.v].ch,
//         i:sensorIds[s.v].id,
//       })
// //       cl(zone)
//     })
// //     cl(cp)
// // make graph sensor list for currentPreset
//     
//   }

  fixSensorId=(s)=>{
//     cl(s)
    if(!["e0","e1","e2","e3"].includes(s.v.substring(0,2))){
      s.v=`e0${s.v}`
    }
//     cl(s.v)
    s.v2=s.v
    let str=s.v.substring(2,4)
//     cl(str)
    if(["ec","ph"].includes(str)){
      s.ofs=+s.v.substring(4)
      s.v2=s.v.substring(0,4)
    }
  }

  // getDefaultOptions=(s)=>{
  //   let sensorId=s.id.substring(2)
  //   cl(sensorId)
  //   if (["inT","inH"].includes(sensorId)){
  //     if(min>20){min=20}
  //     if(max<100){max=100}
  //   }
  //   s.options = {mode: "default", }
  // }

  fixDliId=(zone,sensor)=>{
// this has to search the channels, looking for the dli equipment type
    for(let i=0;i<128;i++){
// need to detect the DLI mode of HID: 4
      if([4,29].includes(+(dbVals.z[zone.siteZoneIndex][i]||{})[508])){
        let chType=+dbVals.z[zone.siteZoneIndex][i][508]
        sensor.ch=i
        sensor.id=(chType==4)?413:23553// dli rtd from equipment
        return
      }
    }
  }
  
  makeGraphSensors=(cp)=>{// should be done whenever the sensor list changes?
//     console.trace()
//     cl("make graph")
//     cl(dbVals)
//     let cp=this.state.currentPreset
//     cl(this.sensors)
    this.sensors=[]
//     cl(sensorIds)
//     cl(cp)
    if(!cp){return}
    if(!cp.sensors){return}
    // cl(cp.sensors[0]?.options?.color)
    cp.sensors.forEach((s,i)=>{// "c00" is the "sensor" for channel 0
//       cl(s)
      if(!s.v){// filling in zone info if *not* there
        let tmp=s
        s={
          v:tmp,
          z:cp.zone,
        }
      }
      this.fixSensorId(s)
//       cl(sensorIds)
//       cl(s)
      var sensor
//       cl(s)
//       cl(isNaN(s.v2.substr(1)))
//       cl(s)
      if((s.v2.substring(2,3)=="c")&&(!isNaN(s.v2.substring(4,6)))){
        sensor=chanPosIds(+s.v2.substr(3))
//         cl(sensor)
//         cl(this.sensorOptions)
        let so=(this.sensorOptions.filter(so=>{return so.value==s.v2}))[0]
        if(so?.label){sensor.name=so.label}
        // assign default values for channel
      }else{
        sensor=sensorIds[s.v2]
      }
//       cl(s)
//       cl(sensor)
//       cl(sensorIds)
//       cl([s.z,cp.zone])
      let zone=globs.zonesInfo.info[getZoneIndex(s.z||cp.zone)]// ||cp.zone is a hack until we can creat multi-zone graphs
//       cl(zone)
      var unit
      var unitGroup
      var unitIdx
      var setpoints = getSetpoints(zone?.siteZoneIndex) || []
      // cl(sensor)
      if(!sensor){return}
      if(sensor && sensor.type=="addedSensor"){
        // unit=sensor.unit
        unit=sensor.unit().t
        unitIdx = sensor.unit().v || 0
        unitGroup = sensor.unitGroup
      }else{
        unit=sensor.unit(zone.siteZoneIndex).t
        unitIdx = sensor.unit(zone.siteZoneIndex).v || 0
        unitGroup = sensor.unitGroup
      }
//       cl(unitIdx)
      // cl([sensor,unit, sensor.unit(), sensor.unit(zone.siteZoneIndex)])
      // if (!s.options) this.getDefaultOptions()

      // cl(sensor.default[unitIdx])
      // cl(s)
      let isEq = new RegExp("e[0-9]+c[0-9]+").test(s.v)
//       cl(isEq)
      if (zone && isEq) {
        let zInfo=globs.zonesInfo.sz2z[zone.siteId][zone.siteZoneIndex]
        // use ts from datapoint
//         cl(zInfo)
        let channelType = getChannelType(zInfo.gatewayType, zone.siteZoneIndex, sensor.ch+(s.ofs||0))
        unit = getChannelUnit(channelType)
        sensor.default = getChannelDefault(channelType)
        // TODO assign unitGroup for exceptions to grouping rules
        unitGroup = getChannelUnitGroup(channelType)
      }
      if (s.v == "e0zoS") {
        unitGroup = `${zone.zoneName} - ${sensor.name}`
        sensor.default = [[0, setpoints.length||1]]
      }
//       cl(unitIdx)
//       cl(sensor.default)
//       cl(sensor.default[unitIdx])
//       cl(sensor.default[unitIdx][0])
      if(zone){
        // cl(zone)
//         cl(s)
        if(s.v2.substr(2)=="dli"){this.fixDliId(zone,sensor)}
        this.sensors.push({
          s:zone.siteId,
          z:zone.siteZoneIndex,
          c:sensor.ch+(s.ofs||0),
          i:sensor.id,
          id:s.v,//+((s.ofs!=undefined)?s.ofs:""),//+s.ofs,
          name:`${zone.zoneName} - ${sensor.name}`,
          options:s.options,
          type:sensor.type,
          mult:sensor.mult,
          unit:unit || "",//sensor.unit(zone.siteZoneIndex),
          unitGroup:unitGroup || sensor.name,
          color: this.colorArr[i],
          min: (sensor.default) ? sensor.default[unitIdx][0] : 0, 
          max: (sensor.default) ? sensor.default[unitIdx][1] : 100,
          defaultMin: (sensor.default) ? sensor.default[unitIdx][0] : 0, 
          defaultMax: (sensor.default) ? sensor.default[unitIdx][1] : 100,
          yShow: true,
          setpoints:getSetpoints(zone.siteZoneIndex),
        })
      }
    })
//     cl(cp)
//     cl(this.sensors[1]?.yShow)
    
  }

  makeGraphEvents=(cp)=>{// events selected by user
//     console.trace()
//     cl("make graph")
//     cl(dbVals)
//     let cp=this.state.currentPreset
//     cl(this.sensors)
//     cl(sensorIds)
//     cl(cp)
//     cl(cp.sensors[0]?.options?.color)
//     cl(cp)
    this.events = []
    if(!cp || !cp.events || !cp.events.length){return}
    cp.events.forEach((s,i)=>{// "c00" is the "sensor" for channel 0
      if(!s.v){return}
      let zone=globs.zonesInfo.info[getZoneIndex(s.z||cp.zone)]// ||cp.zone is a hack until we can creat multi-zone graphs
      // how to get chan?
//       cl(zone)
      if(zone){
        this.events.push({
          s:zone.siteId,
          z:zone.siteZoneIndex,
          // c:sensor.ch+(s.ofs||0),
          // i:sensor.id,
          id:s.v,//+((s.ofs!=undefined)?s.ofs:""),//+s.ofs,
          // name:`${zone.zoneName} - ${sensor.name}`,
          options:s.options,
          // type:sensor.type,
          // mult:sensor.mult,
          // unit:unit,//sensor.unit(zone.siteZoneIndex),
          color:this.colorArr[i],
          min:0, 
          max:0, 
          yShow:true,
        })
      }
    })
    // cl(JSON.stringify(this.events))
  }
  
  findCurrentPreset=(level,siteId,zoneId,userId)=>{
    var match
    let gpi=globs.presetsInfo.info
    for(let i=0;i<gpi.length;i++){
      let pr=gpi[i]
      switch(level){
        case "account":
          match=((level==pr.level)&&(pr.name=="Current"))
          break
        case "site":
          match=((level==pr.level)&&(pr.site==siteId)&&(pr.name=="Current"))
          break
        case "zone":
          match=((level==pr.level)&&(pr.zone==zoneId)&&(pr.name=="Current"))
          break
      }
      match=match&&(pr.userId==globs.userData.session.userId)
      if (match) return pr
    }
    return null
  }
  
  saveCurrentPreset=async()=>{// has to also save to local memory - called when we're leaving the page
    // cl("save current preset")
//     cl(this.props.parms)
//     cl(this.state.currentPreset)
    let cp=Object.assign({},this.state.currentPreset)
//     let pa=this.props.parms
    let st=this.state
    cp.level=this.getPresetLevel()
    let pr=this.findCurrentPreset(cp.level,st.site,st.zone,globs.userData.session.userId)
    cp.presetId=(pr)?pr.presetId:getRandomString(16)
    cp.name="Current"
    cp.userId=globs.userData.session.userId
    cp.time=(this.state.saveViewTime=="absolute")?"ab":"re"

    if (this.state.currentPreset?.time) {
      this.state.currentPreset.time=(this.state.saveViewTime=="absolute")?"ab":"re"
    }

//     cp.presetId=||getRandomString(16)
//     cl(cp.presetId)
    // only do for quick charts
    if(this.qc){
      this.savePresetLocal(this.state.currentPreset)
      let hist=await wsTrans("usa", {cmd: "cRest", uri: "/s/graphingPresets", method: "update", 
      sessionId: globs.userData.session.sessionId, body: cp})
    }
  }
  
  saveView=async()=>{
/*how do we know if this is a new preset, or an updated one?
Select a current preset: fill in presetName field, and saveViewName
*/
    let st=this.state
    if(this.state.saveViewName){
      let pr=Object.assign({},this.state.currentPreset)
      if(this.state.presetName!=this.state.saveViewName){// create a new preset
        pr.presetId=getRandomString(16)// save as a new preset
        pr.name=this.state.saveViewName
        pr.userId=globs.userData.session.userId
      }
      pr.ts=Math.round(getTime())
      pr.time=(this.state.saveViewTime=="absolute")?"ab":"re"
      pr.site=st.site
      this.savePresetLocal(pr)
      globs.events.publish("viewsUpdated",pr.presetId)
      await wsTrans("usa", {cmd: "cRest", uri: "/s/graphingPresets", method: "update", 
        sessionId: globs.userData.session.sessionId, body:pr})
      this.mySetState("d",{presetName:this.state.saveViewName,saveMsg:"Saving",preset:pr.presetId, className: "outlined"})
      this.saveInt=setInterval(()=>this.mySetState("ClearSave",{saveMsg:"Save", className: "filled"}),3000 )
      this.props.parms.onChange({cmd:"saveHeader",data:{saveEnabled:false}})
    }
  }
  
  deleteViewLocal=(presetId)=>{
    let index=this.getPresetIndex(presetId)
//     cl(presetId)
//     cl(index)
//     cl(globs.presetsInfo.info)
    if(index>=0){
      // cl("slice")
      globs.presetsInfo.info.splice(index,1)
    }
    // cl(globs.presetsInfo.info)
  }
  
  deleteView=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this view?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      if(this.state.preset){
        // cl("deleteView view")
        this.deleteViewLocal(this.state.preset)
        globs.events.publish("viewsUpdated")
        await wsTrans("usa", {cmd: "cRest", uri: "/s/graphingPresets", method: "delete", 
          sessionId: globs.userData.session.sessionId, body:{presetId:this.state.preset}})
      }
    }
  }

  eventOptions=[
    {value:"grj",label:"Grow Journal"},//23
    {value:"adl",label:"Audit Log"},//27
    {value:"cte",label:"Controller Events"},//27
    {value:"ala",label:"Alarms"},//27
    {value:"tmo",label:"Timeouts"},//27
    // {value:"alr",label:"Alarm Resolutions"},//27
  ]
  
  sensorOptions=[
    {value:"inT",label:"Inside Temperature"},//23
    {value:"inH",label:"Inside Humidity"},//27
    {value:"inL",label:"Inside Light"},//32
    {value:"dli",label:"Daily Light Integral"},//32
    {value:"inC",label:"Inside CO2"},//31
    {value:"ouT",label:"Outside Temperature"},//24
    {value:"ouH",label:"Outside Humidity"},//28
    {value:"ouL",label:"Outside Light"},//33
    {value:"bpT",label:"Black Plate Temp"},//80
    
    {value:"oWs",label:"Wind Speed"},//80// new
    {value:"oWd",label:"Wind Direction"},//80
    {value:"dPr",label:"Differential Pressure"},//80
    {value:"bPr",label:"Barometric Pressure"},//80
    {value:"ran",label:"Rain"},//80
    {value:"sno",label:"Snow"},//80
    {value:"vpd",label:"VPD"},//80

    {value:"stT",label:"Temperature Stage"},//6
    {value:"stH",label:"Humidity Stage"},//7
    {value:"spH",label:"Heat Setpoint"},//8
    {value:"spC",label:"Cool Setpoint"},//9
    {value:"spU",label:"Humidify Setpoint"},//10
    {value:"spD",label:"DeHum Setpoint"},//11
    {value:"alH",label:"High Alarm"},//19
    {value:"alL",label:"Low Alarm"},//18
    {value:"c00",label:"Channel 1 Position"},//104+channel snap offset *and* channel
    {value:"c01",label:"Channel 2 Position"},
    {value:"c02",label:"Channel 3 Position"},
    {value:"c03",label:"Channel 4 Position"},
    {value:"c04",label:"Channel 5 Position"},
    {value:"c05",label:"Channel 6 Position"},
    {value:"c06",label:"Channel 7 Position"},
    {value:"c07",label:"Channel 8 Position"},
    {value:"c08",label:"Channel 9 Position"},
    {value:"c09",label:"Channel 10 Position"},
    {value:"c10",label:"Channel 11 Position"},
    {value:"c11",label:"Channel 12 Position"},
    {value:"at0",label:"Analog Temperature 1"},
    {value:"at1",label:"Analog Temperature 2"},
    {value:"at2",label:"Analog Temperature 3"},
    {value:"at3",label:"Analog Temperature 4"},
    {value:"at4",label:"Analog Temperature 5"},
    {value:"vp0",label:"Vent Position 1"},
    {value:"vp1",label:"Vent Position 2"},
    {value:"vp2",label:"Vent Position 3"},
    {value:"vp3",label:"Vent Position 4"},
    {value:"vp4",label:"Vent Position 5"},
    {value:"sm0",label:"Soil Moisture 1"},
    {value:"sm1",label:"Soil Moisture 2"},
    {value:"sm2",label:"Soil Moisture 3"},
    {value:"sm3",label:"Soil Moisture 4"},
    {value:"sm4",label:"Soil Moisture 5"},
    {value:"ec0", label:"EC Tank 1"},//203 + offset + tank
    {value:"ph0", label:"pH Tank 1"},//218
    {value:"tp0", label:"Temp Tank 1"},//233
    {value:"ec1", label:"EC Tank 2"},
    {value:"ph1", label:"pH Tank 2"},
    {value:"tp1", label:"Temp Tank 2"},
    {value:"ec2", label:"EC Tank 3"},
    {value:"ph2", label:"pH Tank 3"},
    {value:"tp2", label:"Temp Tank 3"},
    {value:"ec3", label:"EC Tank 4"},
    {value:"ph3", label:"pH Tank 4"},
    {value:"tp3", label:"Temp Tank 4"},
    {value:"ec4", label:"EC Tank 5"},
    {value:"ph4", label:"pH Tank 5"},
    {value:"tp4", label:"Temp Tank 5"},
    {value:"ec5", label:"EC Tank 6"},
    {value:"ph5", label:"pH Tank 6"},
    {value:"tp5", label:"Temp Tank 6"},
    {value:"ec6", label:"EC Tank 7"},
    {value:"ph6", label:"pH Tank 7"},
    {value:"tp6", label:"Temp Tank 7"},
    {value:"ec7", label:"EC Tank 8"},
    {value:"ph7", label:"pH Tank 8"},
    {value:"tp7", label:"Temp Tank 8"},
  ]

  makeSensorReference = (sensors) => {
    let keys = Object.keys(sensors)
    let baseSensorNames = {}
    let sensorIds = {}
    keys.forEach((k) => {
      baseSensorNames[k.substring(2)] = sensors[k].name
      sensorIds[sensors[k].id] = k.substring(2)
    })
    // cl(baseSensorNames)
    // cl(sensorIds)
  }
  
  makeSensorOptions=async(zone,zInd)=>{
//     cl(zone,zInd)
//     cl(zone)
//     let zInd=getZoneInfo(zone).siteZoneIndex
    initSensorIds(zInd,zone)
//     cl(sensorIds)
//     cl(Object.keys(sensorIds))
//     cl(zone)
    if(zone?.zone=="allZones"){}else{
      await setSensorIdNames(zone)
    }
//     cl(sensorIds)
    this.sensorOptions=[]
    // cl(sensorIds)

    // make list of sensor ids and names for backend to reference
    // this.makeSensorReference(sensorIds)

//     cl(Object.keys(sensorIds).length)
    Object.keys(sensorIds).forEach(k=>{
      let s=sensorIds[k]
//       cl([k,s])
      this.sensorOptions.push({
        value:k,
        label:s.name,
        default: s.default
      })
    })
    this.sensorOptions.sort((a,b)=>{
      if(a.value>b.value){return 1}
      if(a.value<b.value){return -1}
      return 0
    })
    
//     cl(this.sensorOptions.length)
//     cl(this.sensorOptions)
//     cl(this.sensorOptions)
//     sensorIds.forEach(s=>{
//     })
// 
//     let zInd=getZoneInfo(this.state.zone).siteZoneIndex
//     initSensorIds(zInd)
//     cl(sensorIds)
//     cl(this.sensorOptions)
  }
  
//   setSensorOptionNames=async(zoneId)=>{// obsolete
//     await loadSensorsInfo()
// //     cl(zoneId)
//     let sensorNames=(getSensorsZone(zoneId)||{}).sensorNames
//     if(sensorNames){
// //       cl(sensorNames)
// //       cl(this.sensorOptions)
//       this.sensorOptions.forEach(so=>{
//         let na=sensorNames[so.value]
//         if(na){so.label=na}
//       })
// //       cl(sensorNames)
//     }
//   }

  
  showTitle=()=>{
    // cl(this.state)
//     let level=this.getPresetLevel()
    
    return(
      <>
        <h2>{this.state.pageTitle}</h2>
        <div className="clearfloat"></div>
      </>
    )
  }
  
//   showSiteZoneView=()=>{
//     return(
//       [
//       <div key="1" className="custom-select">
//         <label htmlFor="graph-site">Site</label>
//         <C18Select00 id="graph-site">
//           <option>San Luis Obisbo</option>
//           <option>Mos Eisley</option>
//           <option>Tatooine</option>
//           <option>Endor</option>
//           <option>Alderaan</option>
//         </C18Select00>
//         <span className="material-icons down-arrow">
//           keyboard_arrow_down
//         </span>
//       </div>,
//       
//       <div  key="2" className="custom-select">
//         <label htmlFor="graph-zone">Zone</label>
//         <C18Select00 id="graph-zone">
//           <option>Room 1</option>
//           <option>Greenhouse 03</option>
//           <option>Greenhouse 04</option>
//           <option>Fun Room</option>
//           <option>Twilight Zone</option>
//           <option>Zone 1</option>
//         </C18Select00>
//         <span className="material-icons down-arrow">
//           keyboard_arrow_down
//         </span>
//       </div>,
//       
//       <div  key="3" className="custom-select">
//         <label htmlFor="graph-view">Select Saved View</label>
//         <C18Select00 id="graph_view">
//           <option>None</option>
//           <option>Room 1 Temperature Graph</option>
//           <option>My Graph</option>
//           <option>Room 2 Graph</option>
//           <option>Last 7 Days Graph</option>
//           <option>My Custom Graph</option>
//         </C18Select00>
//         <span className="material-icons down-arrow">
//           keyboard_arrow_down
//         </span>
//       </div>,
//       ]
//     )
//   }

//   sendImages=()=>{
//     let data = new FormData()
//     this.state.images.forEach((img,i)=>{
//       data.append(`id${i}`, img.id)
//       data.append(`file${i}`, img.image)
//     })
//     let url=`${constant.expressUrl}/usa/images`
//     let method="POST"
//     let type="multipart/form-data"
//     cl(url)
//     return doGetPostBasic(url, method, data, type)// pro  mise
//   }
  


  downloadCsv=async()=>{
//from=1630399532&to=1630421614}&v0=inT&z0=0SuR7ufdLEkMlTlh    
//     cl(this.sensors)
    let cp=this.state.currentPreset
    let da0=new Date()
    let tzo=da0.getTimezoneOffset()
    // cl(tzo)
    let query=`session=${globs.userData.session.sessionId}&type=graph&from=${cp.fromTime}&to=${cp.toTime}&tzo=${tzo}`
    this.sensors.forEach((s,i)=>{
      query+=`&s${i}=${s.s}&z${i}=${s.z}&c${i}=${s.c}&i${i}=${s.i}`
    })
    // cl(query)
    let da=dateToDisplayDate(da0,"yyyy_mm_dd_hh_mm_ss",tzo)
    // cl(da)
    let url=`${constant.expressUrl}/usa/csv/download_${da}.csv?${query}`
    window.location.href=url
  }
  
  toggleShowTimePeriod=()=>{
//     cl(this.state.showTimePeriod)
    let show=(this.state.showTimePeriod=="none")?"block":"none"
    this.mySetState("e",{showTimePeriod:show})
  }
  
  showTimePeriod2=(key)=>{
    let tpOpts=[
      {t:"Today",v:"td"},
      {t:"Yesterday",v:"yd"},
      {t:"This Week",v:"tw"},
      {t:"Last Week",v:"lw"},
      {t:"This Month",v:"tm"},
      {t:"Last Month",v:"lm"},
      {t:"This Year",v:"ty"},
      {t:"Last Year",v:"lh"},
      {t:"Last Hour",v:"l1"},
      {t:"Last 24 Hours",v:"l24"},
      {t:"Last 7 Days",v:"l7"},
      {t:"Last 30 Days",v:"l30"},
      {t:"Last 90 Days",v:"l90"},
      {t:"Last 365 Days",v:"l365"},
      {t:"Custom",v:"cu"},
    ]
//     cl(this.state.currentPreset.time)
//       <div style={{position: "relative"}}>
//         <div style={{position: "absolute", display: this.state.showTimePeriod}}>
//           <DateRangePicker
//             ranges={[this.state.timePeriod]}
//             onChange={o=>{this.onChange("dateRange",o)}}
//             inputRanges={[]}
//           />
//         </div>
//       </div>
    return(
      <div className="time-period-container" onClick={e=>{e.preventDefault(); e.stopPropagation()}}>
        <div key={key} className="custom-select">
          <label htmlFor="graph-site">Time Period</label>
          <C18Select00 
            id="graph-site"
            onChange={e=>this.onChange("timePeriod2",{time: e.currentTarget.value})}
            value={this.state.currentPreset.time}
          >
          {tpOpts.map((t,i)=>{
            return(
              <option key={i} value={t.v}>{t.t}</option>
            )
          })}
          </C18Select00>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </div>
      </div>
    )
  }
  
  
  makeTimePeriod=(cp)=>{
    let h1=Math.floor((new Date()).getTime()/1000)
    let h0=h1-24*3600
    switch(cp.time){
      case "l24":
        cp.fromTime=h0
        cp.toTime=h1
        break
    }
    
  }
  
  showTimePeriod=()=>{
//     cl(this.state.timePeriod)
//     const selectionRange = {
//       startDate: new Date(),
//       endDate: new Date(),
//       key: 'selection',
//     }
    
//         <DateRangePicker
//           ranges={[this.state.timePeriod]}
//           onChange={o=>{this.onChange("timePeriod",o)}}
//           inputRanges={[]}
//         />
//     cl(this.state.timePeriod)
//     cl(this.state.currentPreset)
    let cp=Object.assign({},this.state.currentPreset)
//     this.makeTimePeriod(cp)
//     cl(cp)
//     cl(this.state.currentPreset)
//     cl(this.state.currentPreset.fromTime)
//     cl(cp.fromTime)
    let tp={
      startDate: new Date(1000*cp.fromTime),
      endDate: new Date(1000*cp.toTime),
      key:'selection',
    }
//     cl(tp.startDate)
//     cl(startDate)
    let startDateText=this.formatTime(cp.fromTime)
    let endDateText=this.formatTime(cp.toTime)
//     cl(cp.fromTime)
//     cl(startDateText)
//     cl(startDateText)
//     cl(tp)

  
  let myValue5= { 
  label: 'Last 24 hours',
  range: () => ({
    startDate: subMinutes(subHours(new Date(), 24),0),
    endDate: new Date()
  }),
  };
  
  let myValue1= { 
    label: 'Last 3 days',
    range: () => ({
    startDate: addDays(new Date(), -2),
    endDate: addDays(new Date(), 0),
  }),
  };

  let myValue2= { 
    label: 'Last 7 days',
    range: () => ({
    startDate: addDays(new Date(), -6),
    endDate: addDays(new Date(), 0),
  }),
  };

  let myValue3= { 
    label: 'Last 14 days',
    range: () => ({
    startDate: addDays(new Date(), -13),
    endDate: addDays(new Date(), 0),
  }),
  };

  let myValue4= { 
    label: 'Last 30 days',
    range: () => ({
    startDate: addDays(new Date(), -29),
    endDate: addDays(new Date(), -0),
  }),
  };
  
  let customDateRange1= createStaticRanges([myValue1]);
  let customDateRange2= createStaticRanges([myValue2]);
  let customDateRange3= createStaticRanges([myValue3]);
  let customDateRange4= createStaticRanges([myValue4]);
  let customDateRange5= createStaticRanges([myValue5]);

  return (
    <div className="time-period-container">
    <div>
      <div className="title">Custom Period</div>
      <C18Button00 type="button" className="icon" onClick={this.toggleShowTimePeriod}>
      {`${startDateText} to ${endDateText}`}
        
        <span className="material-icons">
          keyboard_arrow_down
          </span>
      </C18Button00>
    </div>
    <div style={{position: "relative"}} onClick={e=>{e.preventDefault(); e.stopPropagation()}}>
      <div style={{position: "absolute", display: this.state.showTimePeriod, zIndex:"1"}}>
        <DateRangePicker
          ranges={[tp]}
          onChange={o=>{this.onChange("timePeriod",o)}}
          inputRanges={[]}
          staticRanges = {[...defaultStaticRanges, ...customDateRange5, ...customDateRange1, ...customDateRange2, ...customDateRange3, ...customDateRange4]}
//             onClick={this.onDateClick}
        />
      </div>
    </div>
    </div>
    
  )
  }
  
  getSensorName=(id)=>{// this is done in the render routine
    let so=this.sensorOptions
//     cl(id,so)
    for(let i=0;i<so.length;i++){
      if(so[i].value==id){return so[i].label}
    }
  }
  
  makeChartElements=()=>{
//     cl(this.props)
//     cl(this.state.zone)
    let ce=[]
//     cl(this.state.currentPreset.sensors)
    if (!this.state.currentPreset?.sensors){return ce}
    this.state.currentPreset.sensors.forEach(s=>{
//       cl(s)
      if(s.z==this.state.zone){
//         cl("push")
        if(s.v){
          ce.push({value:s.v, label:this.getSensorName(s.v)})
        }else{
          ce.push({value:s, label:this.getSensorName(s)})
        }
      }
    })
//     cl(ce)
    return ce
    
  }
  
  showChartElements=()=>{
//     let options = [
//       { value: 'chocolate', label: 'Chocolate' },
//       { value: 'strawberry', label: 'Strawberry' },
//       { value: 'chocolate2', label: 'Chocolate' },
//       { value: 'strawberry2', label: 'Strawberry' },
//       { value: 'chocolate3', label: 'Chocolate' },
//       { value: 'strawberry3', label: 'Strawberry' },
//       { value: 'chocolate4', label: 'Chocolate' },
//       { value: 'strawberry4', label: 'Strawberry' },
//       { value: 'chocolate5', label: 'Chocolate' },
//       { value: 'strawberry5', label: 'Strawberry' },
//       { value: 'vanilla', label: 'Vanilla' }
//     ]
// //     let {selectedOption}=this.state//"vanilla"
// //     let {selectedOption}=this.state//"vanilla"
// //     cl(selectedOption)
//     let selectedOption=[
//       { value: 'chocolate4', label: 'Chocolate' },
//       { value: 'strawberry4', label: 'Strawberry' },
//     ]

//         <C18Button00 type="button" className="icon">Inside Temperature
//           <span className="material-icons">
//             keyboard_arrow_down
//           </span>
//         </C18Button00>
//     this.makeChartElements()
//     cl(this.state)
    // cl(this.sensorOptions)
    // cl(this.makeChartElements())
    return(
      <div className="chart-elements-container">
        <div className="title">Chart Elements (Y-Axis)</div>
        <Select
          value={this.makeChartElements()}
          onChange={o=>{this.onChange("chartElements",o)}}
          options={this.sensorOptions}
          isMulti={true}
          isClearable={false}
        />        
      </div>
    )
  }
  
//     loadGJ=async(cmd)=>{
// //       cl(cmd)
      
//       // loading gj should be possible without any sensors selected
//       cmd.sensors.forEach(s=>{// each sensor has site and siteZoneIndex
// //         cl(s)
//         sites.push(globs.zonesInfo.sz2z[s.s][s.z].siteId)
//         zones.push(globs.zonesInfo.sz2z[s.s][s.z].zoneId)
//       })
//       cl(sites)
//       cl(zones)// array of zoneIds-
//       let query={dispTime:{$gt:cmd.from,$lte:cmd.to},original:true}
//       // only check sites if level is site
//       query["$or"]=[{$and:[{siteId:{$in:sites}},{level: "site"}]},{zoneId:{$in:zones}}]
// //       cl(query)
//       let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "retrieve2", 
//         sessionId: globs.userData.session.sessionId, body: query})
// //       cl(res)
// //       let gj={}
// //       res.data.forEach(r=>{gj[r.zoneId]=r})
//       return res.data
//   }

    loadCTE=async(cmd)=>{
//       let gateways = []
      let sites = []
      // TODO more ways to specify which controller we want events from?
      // load controller events
      cmd.sensors.forEach(s=>{// each sensor has gatewayId and siteId for querying
//         gateways.push(globs.zonesInfo.sz2z[s.s][s.z].gatewayId)
//         sites.push(globs.zonesInfo.sz2z[s.s][s.z].siteId)
        let z=globs.zonesInfo.sz2z[s.s][s.z]
        sites.push(z.siteId)
      })
      if(cmd.guideGraph||!sites.length){return[]}
      // cl(gateways)
      // cl(sites)
//       let now=getTime()
//       let query={s:{$in: sites},from:cmd.from,to:cmd.to}
      let query={s:{$in:sites},from:cmd.from,to:cmd.to}
//       cl(query)
      let rtdEvents=await wsTrans("usa", {cmd: "cRest", uri: "/s/rtdEvents", 
        method: "retrieve", sessionId: globs.userData.session.sessionId, 
        body: query})
      let events = []
      rtdEvents.data.forEach(ev=>{
        let event = {}
        if (ev.d) {
          Object.assign(event, JSON.parse(ev.d))
          // process event text for line breaks?
          // cl(event.eventText)
          // if (event.eventText) event.eventText = event.eventText.replaceAll("\n", "</br>")
          event.dispTime = ev.t
          // cl(event)
          events.push(event)
        }
      })
      // cl(events)
      return events
    }    

    loadGJ2=async(cmd)=>{
      // cl(cmd)
//       let sites=[]
//       let zones=[]
      let ors=[]
      // loading gj should be possible without any sensors selected???
      // cmd.events.forEach(s=>{// each sensor has site and siteZoneIndex
      //   cl(s)
      //   // check if s.z is an index or a zone id
      //   zones.push(globs.zonesInfo.sz2z[s.s][s.z].zoneId)
      // })
      // get zones from sensors as well
      cmd.sensors.forEach(s=>{// each sensor has site and siteZoneIndex
        let z=globs.zonesInfo.sz2z[s.s][s.z]
        ors.push({siteId:z.siteId,level:"site"})
        ors.push({zoneId:z.zoneId})
        // cl(s)
        // check if s.z is an index or a zone id
//         sites.push(globs.zonesInfo.sz2z[s.s][s.z].siteId)
//         zones.push(globs.zonesInfo.sz2z[s.s][s.z].zoneId)
      })
      if(cmd.guideGraph||!ors.length){return[]}
      // cl(zones)// array of zoneIds-
//       let query={dispTime:{$gt:cmd.from,$lte:cmd.to},original:true}
      let query={dispTime:{$gt:cmd.from,$lte:cmd.to},original:true,$or:ors}
      // only check sites if level is site
//       query["$or"]=[{$and:[{siteId:{$in:sites}},{level: "site"}]},{zoneId:{$in:zones}}]
      // let query={zoneId:{$in:zones},dispTime:{$gt:cmd.from,$lte:cmd.to},original:true}
//       cl(query)
      let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "retrieve2", 
        sessionId: globs.userData.session.sessionId, body: query})
      // cl(res)
//       let gj={}
//       res.data.forEach(r=>{gj[r.zoneId]=r})
      return res.data
  }

    loadAlarms=async(cmd)=>{
//       let sites=[]
//       let zones=[]
      let ors=[]
      // loading gj should be possible without any sensors selected???
      // cmd.events.forEach(s=>{// each sensor has site and siteZoneIndex
      //   cl(s)
      //   // check if s.z is an index or a zone id
      //   zones.push(globs.zonesInfo.sz2z[s.s][s.z].zoneId)
      // })
      // get zones from sensors as well
      cmd.sensors.forEach(s=>{// each sensor has site and siteZoneIndex
        // check if s.z is an index or a zone id
//         sites.push(globs.zonesInfo.sz2z[s.s][s.z].siteId)
//         if (!globs.zonesInfo.sz2z[s.s][s.z].virtual) zones.push(globs.zonesInfo.sz2z[s.s][s.z].siteZoneIndex)
        let z=globs.zonesInfo.sz2z[s.s][s.z]
        ors.push({s:z.siteId,z:z.siteZoneIndex})
        // TODO add sensor/config lvl search?
      })
      if(cmd.guideGraph||!ors.length){return[]}
      // zones need to be of zone idx
      // cl(zones)// array of zoneIds-
      // between time and active
      // let query=resolution ? {e:{$gt:cmd.from,$lte:cmd.to}} : {b:{$gt:cmd.from,$lte:cmd.to}}
//       let query = {}
//       query["$or"]=[{e:{$gt:cmd.from,$lte:cmd.to}},{b:{$gt:cmd.from,$lte:cmd.to}}]
      // only check sites if level is site
//       query["$and"]=[{s:{$in:sites}},{z:{$in:zones}}]

      let query={
        $and:[
          {$or:[
            {b:{$gt:cmd.from,$lte:cmd.to}},
            {e:{$gt:cmd.from,$lte:cmd.to}}
          ]},
          {$or:ors}
        ]
      }
      if(!cmd.events.some((e) => e.id == "tmo")){
        query.a={$not: {$regex: ".*TmOt.*"}}
      }
//       cl(query)
      // include timeout alarms in query?
//       if (!cmd.events.some((e) => e.id == "tmo")){
//         query["$and"].push({a: {$not: {$regex: ".*TmOt.*"}}})
//       }
      // let query={zoneId:{$in:zones},dispTime:{$gt:cmd.from,$lte:cmd.to},original:true}
//       cl(query)
      let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/alarmLog", method: "retrieve", 
        sessionId: globs.userData.session.sessionId, body: query})
//       let gj={}
//       res.data.forEach(r=>{gj[r.zoneId]=r})
      return res.data
    }

    loadAuditLogs=async(cmd)=>{
//       let sites=[]
//       let zones=[]
//       cl(cmd)
      if(cmd.guideGraph){return[]}
      let ors=[]
      // loading gj should be possible without any sensors selected???
      // cmd.events.forEach(s=>{// each sensor has site and siteZoneIndex
      //   cl(s)
      //   // check if s.z is an index or a zone id
      //   zones.push(globs.zonesInfo.sz2z[s.s][s.z].zoneId)
      // })
      // get zones from sensors as well
//       cl(globs.zonesInfo.sz2z)
      cmd.sensors.forEach(s=>{// each sensor has site and siteZoneIndex
        // check if s.z is an index or a zone id
//         cl(s)
//         cl(globs.zonesInfo.sz2z[s.s][s.z])
        let z=globs.zonesInfo.sz2z[s.s][s.z]
        ors.push({s:z.siteId,z:z.siteZoneIndex})
//         sites.push(globs.zonesInfo.sz2z[s.s][s.z].siteId)
//         zones.push(globs.zonesInfo.sz2z[s.s][s.z].siteZoneIndex)
        // TODO add sensor/config lvl search?
      })
      if(!ors.length){return[]}
      let query={t:{$gt:cmd.from,$lte:cmd.to},$or:ors}
//       query["$and"]=[{s:{$in:sites}},{z:{$in:zones}}]
//       cl(query)
      let stTime=getTime()
      let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/configLog", method: "retrieve", 
        sessionId: globs.userData.session.sessionId, body: query, page:{limit:100,sort:{t:-1, _id:-1}}})
//       cl(`get audit: ${getTime()-stTime}`)
      return res.data
    }

  // pass mouse event so we know where to put the newly created grow journal
  createGrowJournal=async(cmd)=>{
    // cl("make grow journal")
    let pos = cmd.pos
    cmd = cmd.cmd
    // make grow journal entry with data from selected sensor/time, then bring it up for view/edit
    // cl(pos)
    // cl(cmd)
    // determine whether equipment
    let level = "zone"
    let tags = []
    let da=Math.floor(getTime())//new Date().getTime()/1000
    let isEq = new RegExp("e[0-9]+c[0-9]+").test(cmd.id)
    // cl(isEq)
    if (isEq) {
      let zInfo=globs.zonesInfo.sz2z[cmd.s][cmd.zInd]
      // use ts from datapoint
      // cl(zInfo)
      let pageType = getChannelType(zInfo.gatewayType, cmd.zInd, cmd.c)
      // cl(pageType)
      if (pageType) {
        let unit = Math.floor(cmd.c/40)
        let zuci = `${cmd.zInd}-${unit}-${cmd.c}-0`
        tags.push(`${pageType}-${zuci}`)
        level = "config"

      }
    } else {
      tags.push(`${cmd.type}-${cmd.id}`)
      level = "sensor"
    }
    if (cmd.id == "cte") level = "site"
    // find tag if equipment
    // level is config if equipment
    let gjEntry={
      threadId: getRandomString(16),
      growJournalId: getRandomString(16),
      // level:p.level,
      level: level, // zone if sensor, channel if eq, site if doser
      siteId: cmd.s,
      zoneId: globs.zonesInfo.sz2z[cmd.s][cmd.zInd]?.zoneId || "",
      userId: globs.userData.session.userId,
      body: "",//this.state.note,
      subject: "",//this.state.gjSubject,
      modTime: da,//this.state.modTime,//Math.floor(this.state.dispTime.getTime()/1000),
      dispTime: cmd.t/1000,//this.state.dispTime,//Math.floor(this.state.modTime.getTime()/1000),
      tags: tags,
      images: [],
      original: true,
    }
    // cl(gjEntry)
    // this.setState({images:[],tags:[],note:""})
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "create", 
      sessionId: globs.userData.session.sessionId,
      body: gjEntry})
    // cl(res)
    if (res.result == "ok") {
      let res2=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "retrieve2", 
        sessionId: globs.userData.session.sessionId, body: {growJournalId: gjEntry.growJournalId}})
      // cl(res2.data)
      let data = res2.data[0]
      // cl(data)
      if (data) {
        // refresh event timeline?
        // add gj event to timeline if not on
        let ev = this.events.find(e=>e.id == "grj")
        if (ev == null) {
          // cl("adding gj to event timeline")
          await this.onChange("chartEvents",{id: "grj"})
        } else {
          // cl("refreshing event timeline with new gj entry")
          await this.onChange("showEvents",{showEvents: true})
          this.doNotify({type:"newEvents", vals:this.events})// notify graph of new event
        }
        let entry = {}
        entry.data = data
        Object.assign(entry, {top: pos.top, left: pos.left, type: "grj"})
        this.setState({eventEntry:[entry], gjEdit: true})
        // this.setState({gjEntry:data, gjEdit: true})
      }
    } else {
      cl("error making gj entry")
    }
  }
  
  fixAddedSensorUnits=(cmd,hist)=>{
//     cl(cmd)
//     cl(hist)
    if(!hist.data){return}
    cmd.sensors.forEach((s,i)=>{
//       cl(s)
      if(s.type=="addedSensor"){
        let key=`${s.s}-${s.z}-${s.c}-${s.i}`
        hist.data.sensors[key].forEach((d,i)=>{
          d.d=d.d/s.mult
        })
      }
      if(s.type=="dli"){
        s.mult=1e6// just more uMol to Mol
        let key=`${s.s}-${s.z}-${s.c}-${s.i}`
        hist.data.sensors[key].forEach((d,i)=>{
          d.d=d.d/s.mult
        })
      }
    })
  }

  fixMbSensors=(cmd)=>{
//     cl(cmd)
    cmd.sensors.forEach(s=>{if(s.type=="mbPoint"){s.z=s.z|0x040}})
  }
  
  cleanZeroData=(hist)=>{
//     cl(hist)
    let sensors=hist.data.sensors
    let totDif=1
    let cntDif=0
    Object.keys(sensors).forEach(k=>{
      let se=sensors[k]
      for(let i=1;i<se.length-1;i++){
        let dif=Math.abs(se[i].d-se[i-1].d)
        let avgDif=totDif/cntDif
        if((se[i].d==0)||(dif/avgDif>10)){
          se[i].d=((se[i+1]?.d||0)+(se[i-1]?.d||0))/2
          i+=1
        }else{
          totDif+=dif
          cntDif+=1
        }
      }
//       cl(k)
    })
    
  }

  setEvents=(events)=>{
    // cl("getting event data from graph")
    // cl(JSON.stringify(events))
    // cl(JSON.stringify(this.events))
    this.events.forEach(s=>{// when new data is put in, need to rescale Y axis
      let event = events.find(e => e.id == s.id)
      // cl(event)
      s.data = event.data
    })
    // cl(this.events)
    // this.events = events
  }

  clean10KSensors=(hist)=>{
    // cl(hist.data.sensors)
    Object.keys(hist?.data?.sensors||{}).forEach(k=>{
      // let tenKs=[]
      let sensorStr = k.split("-")
      let sensorId = sensorStr[sensorStr.length - 1]
      // cl(sensorId)
      hist.data.sensors[k]=hist.data.sensors[k].filter((v,i)=>{
        return !(["23", "27"].includes(sensorId) && ((v.d==10000)||(v.d==32)||(v.d==0)||((v.d>774)&&(v.d<780))))
      })
      // hist.data.sensors[k]=hist.data.sensors[k].filter((v,i)=>{
        // return !tenKs.includes(i)
      // })
    })
  }
  
  getHistory=(cmd)=>{
    // cl(cmd)
    let th=this.history[cmd.mainGraph?"mainGraph":"guideGraph"]

//     cl("get history")
    return new Promise(async(r,e)=>{// first, this needs to check to see if we already *have* the data
      // now, this only makes sense if we're not getting lower period data
      // the breaks are at 1000, 2000 seconds, etc.
      let oldPe=getPe(th?.from||0,th?.to||0)
      let newPe=getPe(cmd.from,cmd.to)
//       cl(cmd)
//       cl(newPe)
      if(
        (
          (newPe>=oldPe)&&// zooming out
          (cmd.from>=th?.from||0)&&
          (cmd.to<=th?.to||0)&&
          (cmd.sensors.length<=Object.keys(th.data?.sensors||{}).length)&&
          (cmd.events.length<=Object.keys(th.data?.events||{}).length)
        )&&
//           !th.pending&&// setting to true was already commented out
          !cmd.eventTimeline&&
          this.state.currentPreset.showEvents&&
          Object.keys(this.state.currentPreset.events)
         )
      {
        cl("already got")
        cl([newPe,oldPe,cmd.from,th.from,cmd.to,th.to])
        r(th.data)
      }
//       cl(cmd.eventTimeline,newPe,oldPe)
//       if(!cmd.guideGraph){
//         if(th.res){th.res.push(r); return}// add it to the list of responses, and done
//         th.res=[r]// create the list of responses
//         return
//       }else{}
      this.fixMbSensors(cmd)
      this.mySetState("getHistory",
        {loading: Object.assign(this.state.loading, {"Graph": true})}
      )
      let start=getTime()
      let hist=await wsTrans("usa", {cmd: "cRest", uri: "/s/history", method: "retrieve", 
        sessionId: globs.userData.session.sessionId, body: cmd})
//       cl({elapsed: getTime()-start, cmd:cmd})
//       cl(hist)
      this.clean10KSensors(hist)
//       cl(hist)
      let ep = await wsTrans("usa", {
        cmd: "cRest",
        uri: "/s/historyEndpoints",
        method: "retrieve",
        sessionId: globs.userData.session.sessionId,
        body: cmd}
      )

      this.mySetState("getHistory", {
        loading: Object.assign(this.state.loading, {"Graph": false})
      })
      this.fixAddedSensorUnits(cmd,hist)
      this.fixAddedSensorUnits(cmd,ep)
      th.data=hist.data
      th.data.ep=ep.data
      // cl(this.state.currentPreset.showEvents)
      let eventData = (this.state.currentPreset.showEvents)?
        await this.getEventHistory(cmd):
        []
//       let eventData =
//       cl(eventData)
      th.data.events = eventData
//       if(th.res){th.res.forEach(re=>{re(th.data)})}// activate the list of responses
//       delete th.res
      th.from=cmd.from
      th.to=cmd.to
//       th.pending = false
      // cl(th.data)
      r(th.data)
    })
  }

  getHistoryO=(cmd)=>{
// this needs to be wrapped so only called once
// also, should never be called from main graph
// the main graph should check to see what *limits* the guide graph have placed
// this needs to handle a request coming in while it's fulfilling the previous request!
/* this is called from the graph, and should return the data requested, from cache, if possible 
*/    
// {from: 1624614320, to: 1624619500, type: "arr", sensors: Array(1)}
// from: 1624614320
// sensors: Array(1)
// 0: {s: "0sna8jVYIh0xw6oF", z: 0, c: 240, i: 23}
// to: 1624619500
// type: "arr"
    // needs to check to see if we've already got it!
//     cl("get history")
//     cl(cmd)
/*try again:
if this is guide-graph, then get the data
otherwise, add the callback to the list*/



    let th=this.history
//     cl(cmd)
//     cl(th.from)
    return new Promise(async(r,e)=>{// first, this needs to check to see if we already *have* the data
//       cl(cmd)
//       cl(th)
//       if(th.data){
//         cl(cmd.sensors.length)
//         cl(Object.keys(th.data.sensors).length)
//       }
//       cl("gh")
      // cl([(cmd.from>=th.from), (cmd.to<=th.to), (cmd.sensors.length<=Object.keys(th.data?.sensors||{}).length), (cmd.events.length<=Object.keys(th.data?.events||{}).length), !th.pending])
      if((cmd.from>=th.from)&&(cmd.to<=th.to)&&(cmd.sensors.length<=Object.keys(th.data?.sensors||{}).length)&&
        // (cmd.events.length<=Object.keys(th.data?.events||{}).length)){
        (cmd.events.length<=Object.keys(th.data?.events||{}).length)&&!th.pending&&!cmd.eventTimeline){
        // already got it
        cl("already got")
        r(th.data)
      }
//       cl("gh")
      if(!cmd.guideGraph){
//         cl("main or event")
        // th.pending = true
        if(th.res){th.res.push(r); return}// add it to the list of responses, and done
        th.res=[r]// create the list of responses
        return
//         if(cmd.mainGraph){r(th.data); return}
      }else{}
//         cl("guide")
//       if((cmd.from>=th.from)&&(cmd.to<=th.to)&&(cmd.sensors.length<=Object.keys(th.data.sensors).length)){
//         cl(cmd.sensors.length)
//         cl(Object.keys(th.data.sensors).length)
//         cl("already got it")
//         r(th.data)
//       }// already got it
//       cl("getting")
//       cl(cmd)
      // add other event data here
      // getEventHistory()
      // phase out later
      // let growJournals=await this.loadGJ(cmd)

//       cl(growJournals)
//       cl("get history")
//       cl(cmd)
      this.fixMbSensors(cmd)
      this.mySetState("getHistory", {loading: Object.assign(this.state.loading, {"Graph": true})})
      let start=getTime()
      let hist=await wsTrans("usa", {cmd: "cRest", uri: "/s/history", method: "retrieve", 
        sessionId: globs.userData.session.sessionId, body: cmd})
      // cl({elapsed: getTime()-start, cmd:cmd})
      this.clean10KSensors(hist)
      // cl(hist)
      let ep = await wsTrans("usa", {cmd: "cRest", uri: "/s/historyEndpoints", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: cmd})
      // get first sensor data before and after range of cmd (for all data points involved)
      // cl(hist)
      // cl(ep)

      this.mySetState("getHistory", {loading: Object.assign(this.state.loading, {"Graph": false})})
//       cl("got")
      this.fixAddedSensorUnits(cmd,hist)
      this.fixAddedSensorUnits(cmd,ep)

//       this.cleanZeroData(hist)
      th.data=hist.data
      th.data.ep=ep.data
//       let se=th.data.sensors
// //       cl(se)
//       Object.keys(se).forEach(k=>{// each of the sensors in the graph
//         let site=k.substring(0,16)// k is: LrN_lFcYgsK9S2-Q-0-240-23
//         let [zone,chan,id]=k.substring(17).split("-")
// // //         cl(parts)
// //         cl(globs.zonesInfo)
// //         cl(site)
// //         cl(zone)
//         let zoneId=globs.zonesInfo.sz2z[site][zone].zoneId
//         growJournals.forEach(gj=>{
//           for(let i=0;i<se[k].length;i++){
//             if(se[k][i].t>gj.dispTime){
// //               cl(gj)
//               se[k][i].gj=gj
//               break
//             }
//           }
//         })
//       })

      // load other event data if requested
      // cl("getting event data")
      let eventData = await this.getEventHistory(cmd)
      th.data.events = eventData
      // cl(th.data.events)
      // cl("event timeline data should be refreshed")
      // cl(JSON.stringify(this.events))
      // cl(th.data)
      // cl(th.res)
      if(th.res){th.res.forEach(re=>{re(th.data)})}// activate the list of responses
      delete th.res
      // cl(th.data)
      th.from=cmd.from
      th.to=cmd.to
      // cl("got data")
      th.pending = false
      r(th.data)
//       cl(th.from)
    })

//     return new Promise(async(r,e)=>{
//       if((cmd.from>=this.history.from)&&(cmd.to<=this.history.to)){r(this.history.data)}
//       let hist=await wsTrans("usa", {cmd: "cRest", uri: "/s/history", method: "retrieve", 
//         sessionId: globs.userData.session.sessionId, body: cmd})
//       this.history.from=cmd.from 
//       this.history.to=cmd.to
//       this.history.data=hist.data
//       r(this.history.data)
//     })
  }

  // based on event data passed into cmd, retrieve events
  getEventHistory=async(cmd)=>{
//       cl("get event history")
    // cl(cmd)
    if(cmd.guideGraph){return[]}
      let ret = {}
      let eventCmds = []
      let cpEvents=this.state.currentPreset.events
      for (let e of cmd.events) {
//         cl(e)
//         cl(cmd)
        var stTime
        switch (e.id) {
          case "grj":
            if(cpEvents.filter(e=>{return e.v=="grj"}).length){
              eventCmds.push(this.loadGJ2(cmd))
            }
//             cl("getting gjes")
            // this.mySetState("getHistory", {loading: Object.assign(this.state.loading, {"Grow Journal": true})})
            // let growJournals = await this.loadGJ2(cmd)
            // this.mySetState("getHistory", {loading: Object.assign(this.state.loading, {"Grow Journal": false})})
            // cl(growJournals)
            // ret.grj = growJournals
            break
          case "adl":
            if(cpEvents.filter(e=>{return e.v=="adl"}).length){
              eventCmds.push(this.loadAuditLogs(cmd))
            }
            // cl("getting audit log")
            // this.mySetState("getHistory", {loading: Object.assign(this.state.loading, {"Audit Logs": true})})
            // let auditLogs = await this.loadAuditLogs(cmd)
            // this.mySetState("getHistory", {loading: Object.assign(this.state.loading, {"Audit Logs": false})})
            // let auditLogsInfo = this.getAuditLogsInfo(auditLogs)
            // ret.adl = auditLogsInfo
            break
          case "cte":
            if(cpEvents.filter(e=>{return e.v=="cte"}).length){
              eventCmds.push(this.loadCTE(cmd))
            }
//             cl("getting controller events")
            // this.mySetState("getHistory", {loading: Object.assign(this.state.loading, {"Controller Events": true})})
            // let controllerEvents = await this.loadCTE(cmd)
            // this.mySetState("getHistory", {loading: Object.assign(this.state.loading, {"Controller Events": false})})
            // cl(controllerEvents)
            // ret.cte = controllerEvents
            break
          case "ala":
            if(cpEvents.filter(e=>{return e.v=="ala"}).length){
              eventCmds.push(this.loadAlarms(cmd))
            }
            // cl("getting alarms")
            // this.mySetState("getHistory", {loading: Object.assign(this.state.loading, {"Alarms": true})})
            // let alarms = await this.loadAlarms(cmd)
            // cl(alarms)
            // get alarm info
            // let alarmsInfo = this.getAlarmsInfo(alarms)
            // cl(alarmsInfo)
            // this.mySetState("getHistory", {loading: Object.assign(this.state.loading, {"Alarms": false})})
            // ret.ala = alarmsInfo
            break
          case "alr":
            // cl("getting alarms")
            // let alarms2 = await this.loadAlarms(cmd)
            // cl(alarms)
            // get alarm info
            // let alarmsInfo2 = this.getAlarmsInfo(alarms2)
            // cl(alarmsInfo)
            // ret.alr = alarmsInfo2
            break
        }
      }
      // do promise all
      this.mySetState("getHistory", {loading: Object.assign(this.state.loading, {"Events": true})})
      stTime=getTime()
      let data = await Promise.allSettled(eventCmds)
//       cl(`elapsed: ${getTime()-stTime}`)
      // cl(data)
      for (let i = 0; i < data.length; i++) {
        let id = cmd.events[i].id
        if (data[i].status == "fulfilled") {
          // save data to variable
          if (id == "ala") {
            ret[id] = this.getAlarmsInfo(data[i].value)
          } else if (id == "adl") {
            ret[id] = this.getAuditLogsInfo(data[i].value)
          }
          else {
            ret[id] = data[i].value
          }
        } else {
          cl(id + ": " + data[i].reason)
        }
      }
      this.mySetState("getHistory", {loading: Object.assign(this.state.loading, {"Events": false})})
      // cl(ret)
      return ret
  }
  
//   notify=(cmd)=>{
//     cl(cmd)
//   }
  
  notify=(note)=>{// register a notify function from child component, Graph01
//     cl(note)
    if(!(note.id in this.notifies)){this.notifies[note.id]=[]}
    this.notifies[note.id].push(note.func)
//     cl(this.notifies)
  }
  
  doNotify=(o)=>{
    if(o.type in this.notifies){
      this.notifies[o.type].forEach(f=>{
        f(o.vals)
      })
    }
  }
  
  getElements=(vals)=>{
    let ce=[]
    vals.forEach(v=>{
      ce.push({v:v.value,z:this.state.zone}/*v.value*/)
    })
//     cl(ce)
    return{sensors:ce}
    
  }
    
  onChange=async(type, vals)=>{
//     cl([type,vals])
    var cp
    var v
    if(vals){v=vals.vals} 
    var sensorUnits={inT:"\u00B0F",inH:"%"}
//     let v=vals.vals
    switch(type){
      case "graph":
        switch(vals.type){
          case "selectChange":
//             cl("selectChange")
            this.doNotify(vals)
            cp=Object.assign({},this.state.currentPreset,{fromSelect:v.fromVal, toSelect:v.toVal})
//             cl("select change")
            this.mySetState("selectChange",{currentPreset:cp})
//               cp=this.state.currentPreset
//               cl(cp)
//             cl("change")
            break
          case "selectZoom":// zooming on the main graph
            // cl("selectZoom")
            cp=Object.assign({},this.state.currentPreset,{fromSelect:v.fromVal, toSelect:v.toVal})// update select area
            if(cp.fromSelect<cp.fromTime){
              vals.vals.fromVal=cp.fromTime
              cp.fromSelect=cp.fromTime
            }
            if(cp.toSelect>cp.toTime){
              vals.vals.ToVal=cp.toTime
              cp.toSelect=cp.toTime
            }
            
            this.doNotify(vals)// notify other graph of change
            this.mySetState("selectZoom",{currentPreset:cp})
            break
          case "guideZoom":
            // cl("guideZoom")
            cp=Object.assign({},this.state.currentPreset,{fromTime:v.fromVal, toTime:v.toVal})
            vals.type="selectChange"// used if select has overflowed the guide graph
            if(cp.fromSelect<v.fromVal){
              vals.vals.toVal=cp.toSelect
              cp.fromSelect=v.fromVal
              this.doNotify(vals)
            }
            if(cp.toSelect>v.toVal){
              vals.vals.fromVal=cp.fromSelect
              cp.toSelect=v.toVal
              this.doNotify(vals)
            }
            this.mySetState("guideZoom",{currentPreset:cp})
            break
          case "yAxis":// opening Axis Options
//             cl(this.state.currentPreset)
//             cl(vals.vals.axisOptions)
//             cl((this.state.currentPreset||{}).sensors||[])
            if(this.props.parms.pageType!="viewGraph"){
              if (vals.vals.divId == "main-graph") {
                let sensor=((this.state.currentPreset||{}).sensors||[])[vals.vals.axisOptions]
    //             cl(vals)
                let o=sensor.options
                var opt
                if(o){
                  opt={
                    axisOptionsMode:o.mode,
                    axisOptionsColor:o.color,
                    axisOptionsMin:o.min,
                    axisOptionsMax:o.max,
                    axisOptionsUnit:this.sensors[vals.vals.axisOptions].unit,
                    axisOptionsShowGrid:o.showGrid||false,
                  }
                }else{
                  opt={
                    axisOptionsMode:"default",
                    axisOptionsColor:this.colorArr[vals.vals.axisOptions],
                    axisOptionsMin:this.sensors[vals.vals.axisOptions].min||0,
                    axisOptionsMax:this.sensors[vals.vals.axisOptions].max||30,
                    axisOptionsUnit:(this.sensors[vals.vals.axisOptions].unit)||(sensorUnits[sensor.v]),
                    axisOptionsShowGrid:this.sensors[vals.vals.axisOptions].showGrid||false,
                  }
                }
              Object.assign(vals.vals,opt)
              }
              delete vals.vals.divId
              // cl(vals.vals)
              this.mySetState("yAxis", Object.assign({eventEntry:[], gjEdit: false}, vals.vals))
            }
            if (this.props.parms.pageType == "viewGraph" && vals.vals.divId == "event-timeline") {
              delete vals.vals.divId
              // cl(vals.vals)
              this.mySetState("yAxis", Object.assign({eventEntry:[], gjEdit: false}, vals.vals))
            }
            break
          case "gjClick":
            // cl("gjClick")
            // show pop-up with gj info
            // let gj=vals.vals.gj
            // if(gj){
            //   let pe=vals.vals.e
            //   gj.left=pe.pageX
            //   gj.top=pe.pageY
            //   this.setState({gjEntry:vals.vals.gj})
            //   cl(vals)
            // }
            break
          case "eventClick":
            let data = vals.vals.data
            let pe=vals.vals.e
            data[0].left=pe.clientX
            data[0].top=pe.clientY
            data[0].type=vals.vals.type
            // cl(vals.vals)
            this.setState({eventEntry: data})
          default:
            break
        }
          break
      case "chartElements":
//         this.chartElements=vals
//         let newSensors=this.state.currentPreset.sensors.concat(this.getElements(vals).sensors)
        let newSensors=this.getElements(vals).sensors
//         let oldSensors=this.state.currentPreset.sensors.slice(0)
        var oldSensors=[]
        this.state.currentPreset.sensors.forEach(s=>{
          if(s.z!=this.state.zone){oldSensors.push(s)}
        })
        cp=Object.assign({},this.state.currentPreset,{sensors:oldSensors.concat(newSensors)})
        this.makeGraphSensors(cp)
        this.doNotify({type:"newSensors", vals:this.sensors})// notify other graph of change
        this.doNotify({type:"newEvents", vals:this.events})// notify other graph of change
//         cl("chart elements")
        this.mySetState("i",{currentPreset:cp})
//         this.getElements(vals)
//         this.mySetState("j",{chartElements:vals})
        
//         cl(this.chartElements)
        break
      case "chartEvents":
        // cl("chart events")
//         this.chartElements=vals
        // add or remove events from list

        // cl(vals)
        // cl(this.events)
//         let newSensors=this.state.currentPreset.sensors.concat(this.getElements(vals).sensors)
        var newEvents = []
        // let zInd=getZoneInfo(this.state.zone).siteZoneIndex
        // cl([this.state.zone, zInd])
        let ev = this.events.find(e=>e.id == vals.id)
        if (ev == null) {
          newEvents.push({v: vals.id, z: this.state.zone})
        }
//         let oldSensors=this.state.currentPreset.sensors.slice(0)
        var oldEvents=[]
        cl(this.state.currentPreset.events)
        // cl(this.state.currentPreset.events.length)
        if (this.state.currentPreset.events && this.state.currentPreset.events.length) {
          this.state.currentPreset.events.forEach(s=>{
            // don't include vals.id
            if(s.v != vals.id && !(vals.id == "ala" && s.v == "tmo")){oldEvents.push(s)}
          })
        }
        cp=Object.assign({},this.state.currentPreset,{events:oldEvents.concat(newEvents), showEvents: true})
        this.makeGraphEvents(cp)
        this.doNotify({type:"newEvents", vals:this.events})// notify other graph of change
        this.mySetState("i",{currentPreset:cp})
        break
      case "dataMarkers":
        cp=Object.assign({},this.state.currentPreset,vals)
        // cl(cp)
        // cl(vals)
        this.doNotify({type:"newSensors", vals:vals})// notify other graph of change
        this.mySetState("i",{currentPreset:cp})
        break
      case "vBar":
        cp=Object.assign({},this.state.currentPreset,vals)
        this.doNotify({type:"newSensors", vals:vals})// notify other graph of change
        this.mySetState("i",{currentPreset:cp})
        break
      case "combinedYAxis":
        let sensors=this.sensors.slice(0)
        let s=this.state
        if(vals.combinedYAxis){
          if (!this.state.currentPreset.vBar) Object.assign(vals, {vBar: true})
          // make sensor groups
          var sGroups = sensors.reduce((arr, curr) => {
            if (!arr[curr["unitGroup"]]) { 
              arr[curr["unitGroup"]] = []
            }
            arr[curr["unitGroup"]].push(curr)
            return arr
          }, {})
          for (var type in sGroups){
            let group = sGroups[type]
            group.forEach((se, i)=> {
              se.options = {
                mode:group[0].axisOptionsMode,
                color:se.color,
                min: group[0].defaultMin,
                max: group[0].defaultMax,
                // unit:s.axisOptionsUnit,
                showGrid:group[0].axisOptionsShowGrid,
              }
            })
          }
        }
        this.doNotify({type:"newSensors", vals:{ind:s.axisOptions, sensors:sensors}})// notify other graph of change

        cp=Object.assign({},this.state.currentPreset,vals)
        this.doNotify({type:"newSensors", vals:vals})// notify other graph of change
        this.mySetState("i",{currentPreset:cp})
        break
      case "mobileView":
        cp=Object.assign({},this.state.currentPreset,vals)
        this.doNotify({type:"newSensors", vals:vals})// notify other graph of change
        this.mySetState("i",{currentPreset:cp})
        break
      case "zoomBar":
        cp=Object.assign({},this.state.currentPreset,vals)
        this.doNotify({type:"newSensors", vals:vals})// notify other graph of change
        this.mySetState("i",{currentPreset:cp})
        break
      case "zoomControls":
          cp=Object.assign({},this.state.currentPreset,vals)
          this.doNotify({type:"newSensors", vals:vals})// notify other graph of change
          this.mySetState("i",{currentPreset:cp})
          break
      case "dateRange":
        // cl("dateRange",vals)
        break
      case "timePeriod2":
        // cl(type,vals)
        cp=Object.assign({},this.state.currentPreset,vals)
        this.mySetState("timePeriod",{currentPreset:cp})
        break
      case "timePeriod":
        // cl("time period")
        //vals.selection.startDate.setHours(0, 0, 0, 0)
        let fromTime=Math.floor(vals.selection.startDate.getTime()/1000)
        let toTime=Math.floor(vals.selection.endDate.getTime()/1000)
        if (toTime<fromTime) {
          toTime=fromTime+3600
        } else if (toTime==fromTime) {
          toTime=this.state.currentPreset.toTime
          if (toTime < fromTime) {
            let temp = toTime;
            toTime = fromTime;
            fromTime = temp;
          }
        } else {
        //vals.selection.endDate.setHours(23, 59, 59, 999)
          toTime=Math.floor(vals.selection.endDate.getTime()/1000)
        }

        const date1 = new Date(fromTime * 1000)
        date1.setHours(12);
        date1.setMinutes(0);
        let epochTimeInMilliseconds1 = date1.getTime();
        epochTimeInMilliseconds1 = epochTimeInMilliseconds1 - (12 * 60 * 60 * 1000);
        const epochTimeInSeconds1 = Math.floor(epochTimeInMilliseconds1 / 1000);
        fromTime = epochTimeInSeconds1

        const date2 = new Date(toTime * 1000)
        date2.setHours(11);
        date2.setMinutes(59);
        let epochTimeInMilliseconds2 = date2.getTime();
        epochTimeInMilliseconds2 = epochTimeInMilliseconds2 + (12 * 60 * 60 * 1000)
        const epochTimeInSeconds2 = Math.floor(epochTimeInMilliseconds2 / 1000);
        toTime = epochTimeInSeconds2
        
        let cp2=Object.assign({},this.state.currentPreset,{fromTime: fromTime, toTime: toTime})
        cp2.fromSelect=cp2.fromTime
        cp2.toSelect=cp2.toTime
//         cl(cp2.toTime-cp2.fromTime)
//         cl(cp2.toSelect-cp2.fromSelect)
//         cl(cp2)
//         cl(fromTime,toTime)
        this.doNotify({type:"newTimePeriod", vals:{fromTime:fromTime,toTime:toTime,
          dataMarkers:this.state.currentPreset.dataMarkers,vBar:this.state.currentPreset.vBar,
          combinedYAxis:this.state.currentPreset.combinedYAxis,mobileView:this.state.currentPreset.mobileView,zoomBar:this.state.currentPreset.zoomBar,
        }})
        // cl(this.state)
        
        this.mySetState("set Time Period",{currentPreset:cp2})
//         cl(cp2.fromTime)
//         cl(this.state.currentPreset.fromTime)
//         cl(this.state.currentPreset)
        break
      case "szv":
        // cl(type,vals)
        switch(vals.cmd) {
          case "deleteView":
            this.deleteView()
            break
          case "newPreset":
//             cl(vals)
            this.loadPreset(vals.vals.preset)
//             cl("loaded preset")
//             cl(this.state.currentPreset)
            break
          case "newSiteZone":
//             cl(vals)
            await loadSiteData(vals.site)
//             await this.makeSensorOptions(vals.zone)
            let zi=globs.zonesInfo.info.filter(
              z=>{return z.zoneId==vals.zone})[0]
//             cl(zi)

            if (zi) {
              await this.makeSensorOptions(vals.zone,zi.siteZoneIndex)

  //             cl(vals.zone)
  //             initSensorIds
  //             this.setSensorOptionNames(vals.zone)
              this.setChannelInfo(vals.zone)

              // cl("is this being called?")
              // cl(vals)

              this.mySetState("newSiteZone",{site:vals.site,zone:vals.zone})
              // cl(this.state)
            }
            break
        }
//         cl([type,vals])
        break
      case "saveViewTime":
      case "saveViewName":
        this.mySetState("l",vals)
        break
//         this.mySetState("m",vals)
//         break
      case "saveView":
        this.saveView()
        break
      case "downloadCsv":
        this.downloadCsv()
        break
      case "axisOptions":
        cp=Object.assign({},this.state.currentPreset)
        switch(vals.type){// saving options
          case "OK":
            let sensors=cp.sensors.slice(0)
            let s=this.state
            if(s.axisOptionsShowGrid){
              sensors.forEach(se=>{
//                   cl(se.options)
                  if(se.options){se.options.showGrid=false}
              })
            }
            sensors[s.axisOptions].options={
              mode:s.axisOptionsMode,
              color:s.axisOptionsColor,
              min:(s.axisOptionsMode == "default") ? this.sensors[s.axisOptions].defaultMin: s.axisOptionsMin,
              max:(s.axisOptionsMode == "default") ? this.sensors[s.axisOptions].defaultMax: s.axisOptionsMax,
              // unit:s.axisOptionsUnit,
              showGrid:s.axisOptionsShowGrid,
            }
            if(cp.combinedYAxis){
              sensors.forEach((se,i)=>{
                cl([this.sensors[s.axisOptions].unitGroup, this.sensors[i].unitGroup])
                if (s.axisOptions != i && this.sensors[s.axisOptions].unitGroup == this.sensors[i].unitGroup && this.sensors[s.axisOptions].unit == this.sensors[i].unit) {
                  se.options = {
                    mode:s.axisOptionsMode,
                    color:this.sensors[i].color,
                    min:(s.axisOptionsMode == "default") ? this.sensors[s.axisOptions].defaultMin: s.axisOptionsMin,
                    max:(s.axisOptionsMode == "default") ? this.sensors[s.axisOptions].defaultMax: s.axisOptionsMax,
                    // unit:s.axisOptionsUnit,
                    showGrid:s.axisOptionsShowGrid,
                  }
                }
              })
            }
            cl(sensors)
//             cl(sensors[0].options.showGrid)
            this.doNotify({type:"newSensors", vals:{ind:s.axisOptions, sensors:sensors}})// notify other graph of change
//             cl(sensors)
            Object.assign(vals,{currentPreset:cp})
          case "Cancel":
          default:
            this.mySetState("axis Opts",vals)
            break
        }
        break
      case "entryClose":
        this.setState({eventEntry:[], gjEdit: false})
        break
      case "gjClose":
        this.setState({gjEntry:null, gjEdit: false})
        break
      case "cteClose":
        this.setState({cteEntry:null,})
        break
      case "mainDiv":
        if(this.state.showTimePeriod=="block"){this.toggleShowTimePeriod()}
//         cl("close")
        break
      case "showEvents":
        cp=Object.assign({},this.state.currentPreset,vals)
        cl(cp.showEvents)
//         cl(vals)
        // notify about new events if showing
        // cl(JSON.stringify(this.events))
        if (cp.showEvents) this.doNotify({type:"newEvents", vals:this.events})// send to guide-graph
        this.mySetState("i",{currentPreset:cp, eventEntry:[], gjEdit: false, cteEntry: null})
        break
      case "eventOptions":
        cp=Object.assign({},this.state.currentPreset)
        cl(vals)
        switch(vals.type){// saving options
          case "OK":
            // let events=cp.events?.slice(0) || {}
//             cl(sensors)
            // let s=this.state
            // set event shown  based
//             cl(sensors[0].options.showGrid)
            // events.options={
              // showGrowJournal:s.eventOptionsShowGrowJournal,
            // }
//             cl(sensors[0].options.showGrid)
//             cl(sensors)
            // Object.assign(vals,{currentPreset:cp})
          case "Cancel":
          default:
            this.mySetState("event Opts",vals)
            break
        }
        break
      case "gjEntry":
        // cl(vals)
        let entries
        switch (vals.cmd) {
          case "deleteEntry":
            // cl("delete entry")
            // cl(JSON.stringify(this.events))
            entries = this.events.find(e => e.id == "grj").data
            if (entries && entries.length) {
              entries = entries.slice(0)
              // cl(entries)
              this.deleteGJEntry(entries, vals)
              this.events.find(e => e.id == "grj").data = entries
              this.setState({eventEntry:[], gjEdit: false})
              this.doNotify({type:"newEvents", vals:this.events})// send to Graph01
            }
            break
          case "addGJEntry":// when an entry is edited
            // cl("add entry")
            // cl(JSON.stringify(this.events))
            // sometimes events doesn't have data - when is it given data
            entries = this.events.find(e => e.id == "grj").data
            // cl(entries)
            if (entries && entries.length) {
              entries = entries.slice(0)
              // cl(entries)
              this.updateGJEntry(entries,vals.gjEntry)
              this.events.find(e => e.id == "grj").data = entries
              let entry = {}
              entry.data = vals.gjEntry
              Object.assign(entry, {top: this.state.eventEntry[0].top, left: this.state.eventEntry[0].left, type: "grj"})
              this.setState({eventEntry:[entry]})
              this.doNotify({type:"newEvents", vals:this.events})// send to Graph01
            }
            break
          // case "tagClick":
          //   if(st.filter.tags?.length){
          //     vals.filter={}
          //   }
          //   vals.entries=await loadGJ(st.gjSkip,st.gjLimit,vals.filter,pa)
          //   this.setState(vals)
          //   break
          case "image":
            this.props.parms.onChange({cmd:"showImage", vals: vals})
            break
        }
      default:
        break
    }
  }

  updateGJEntry=(entries,entry)=>{
    for(let i=0;i<entries.length;i++){
      if(entries[i].data.growJournalId==entry.growJournalId){
        entries[i].data=entry
        break
      }
    }
  }

  deleteGJEntry=(entries,vals) => {
    var query
    switch(vals.type){
      case "main":
        query={threadId:vals.id}
        for(let i=0;i<entries.length;i++){
          if(entries[i].data.threadId==vals.id){
            entries.splice(i,1)
          }
        }
        break
      case "reply":
        query={growJournalId:vals.id}
        break
    }
    // cl(query)
    wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "delete", 
      sessionId: globs.userData.session.sessionId,
      body: query})
  }

  showLoading=()=>{
    let st = this.state
    let txt = ""
    for (const [key, val] of Object.entries(st.loading)) {
      if (val) {
        txt += `${key} loading... `
      }
    }
    if (txt != "") {
      return (
        <div style={{display: "flex", position:"absolute", left: "50%", top: "50%", transform: "translate(-50%, 0)"}}>
          <span className="spinnerGraph"><img style={{height:12,width:12}} src="/img/spinner.gif" /></span>
          <label>{txt}</label>
        </div>
       )
    }
  }

  showEventTimeline=()=>{
    // cl("show event timeline")
    let cp=Object.assign({},this.state.currentPreset)
    // sensors used to find sites/zones
    let sensors=[]
    this.sensors.forEach(s=>{
      sensors.push(Object.assign({},s,{yShow: true}))
    })
    // events that were selected for search
    let events=[]
    // cl(this.events)
    this.events.forEach(s=>{
      // cl([this.state.site, this.state.zone])
      if (this.state.site && s.s != this.state.site) s.s = this.state.site
      if (this.state.zone) {
        let zInd=getZoneInfo(this.state.zone).siteZoneIndex
        if(s.z != zInd) s.z = zInd
      }
      s.yShow = true
      events.push(Object.assign({},s,{yShow: true}))
    })
    cp.fromSelect=cp.fromSelect||cp.fromTime
    cp.toSelect=cp.toSelect||cp.toTime
//     cl(sensors)
    // cl(cp)
    return(
        <Graph01 graph={{
          divId:"event-timeline",
          showTimePeriod:this.state.showTimePeriod=="block",
          isMain:true,
          from:cp.fromSelect,//1624614321,
          to:cp.toSelect,//1624619500,
          gFrom:cp.fromTime,// the overall guid limits
          gTo:cp.toTime,
          pageType:this.props.parms.pageType,
//           sFrom:1624615320,
//           sTo:1624618500,
          showGrid: true,
          showSelect: false,
          showDM:false,
          showVBar:false,
          showCombinedYAxis:cp.combinedYAxis||false,
          showMobileView:cp.mobileView||false,
          showZoomBar:true,
          // showZoomBar:cp.zoomBar||false,
          sensors:sensors,
          events: events,
//           [
//             {s:"0sna8jVYIh0xw6oF", z:0, c:240, i:23, color: this.colorArr[0], name: "Inside Temperaturex", unit: "degF", min: 0, max: 30, yShow: true},
//           ],
          getHistory: this.getHistory,
          setEvents: this.setEvents,
//           notify: this.notify,
          onChange: o=>this.onChange("graph",o),
        }}
        notify={this.notify}/>
    )
    
  }
  
  showMainGraph=()=>{
//     cl("show main graph")
/* props for graph:
width, height
array of sensors to show, by code: inT for inTemperature, etc.
for each sensor: site, zone, sensorId, min, max values, axisShow flag, colors

array: {site: zone: id: color: min: max: show:}
ref:
width:
height:
from:
to:
sFrom: - for Select box
sTo:
showGrid:
showBox:

getHistory function:
takes an array of sensors, with site, zone, id, and from and to times: {from: to: sensors:[{site: zone: id:}]}
returns an array of data, with site, zone, id, and array of points, time and value {site: zone: id: points:[{t: v:}]}
or an AA: {from: to: site-zone-id: [{t: v:}]}

for each sensor, min and max values for y axis, show flag for axis
colors are assigned by order
time period, zoom period (for lower)
  show YAxis (off for lower graph) - already have
show X Grid (off for lower)
show SelectBox (on for lower graph)

to render one sensor:
site-zone-id key
name, unit, min, max, position for y axis
color
array of time, value data
render functions:
render x axis and grid, given from and to, position, and width, and ticks
render one y axis - min, max, name, unit, color, position, fontsize, ticks
render line and shadow
render select box

on zooming:
the the x mapping and ticks for the x axis should be recalculated - not until we have the new data




props for page:
Site, Zone
Preset ID

*/
//       <div id="main-graph" ref={this.mainSvgDiv}>
//         <p>GRAPH GOES HERE (USE UP AS MUCH VERTICAL SPACE AS YOU NEED)</p>
// 
//       </div>
//     cl(this.state.currentPreset)
    let cp=Object.assign({},this.state.currentPreset)
    // copy sensors:
    let sensors=[]
//     cl(this.sensors)
    this.sensors.forEach(s=>{
      sensors.push(Object.assign({},s,{yShow: true}))
    })
    let events=[]
    this.events.forEach(s=>{
      if (this.state.site && s.s != this.state.site) s.s = this.state.site
      if (this.state.zone) {
        let zInd=getZoneInfo(this.state.zone).siteZoneIndex
        if(s.z != zInd) s.z = zInd
      }
      events.push(Object.assign({},s,{yShow: false}))
    })
//       cl(sensors)
//     cl(cp)
    cp.fromSelect=cp.fromSelect||cp.fromTime
    cp.toSelect=cp.toSelect||cp.toTime
//     cl(sensors)
//     cl(cp)
    return(
      <div>
        <Graph01 graph={{
          divId:"main-graph",
          showTimePeriod:this.state.showTimePeriod=="block",
          isMain:true,
          from:cp.fromSelect,//1624614321,
          to:cp.toSelect,//1624619500,
          gFrom:cp.fromTime,// the overall guid limits
          gTo:cp.toTime,
          pageType:this.props.parms.pageType,
//           sFrom:1624615320,
//           sTo:1624618500,
          showGrid: true,
          showSelect: false,
          showDM:cp.dataMarkers,
          showVBar:cp.vBar,
          showCombinedYAxis:cp.combinedYAxis||false,
          showMobileView:cp.mobileView||false,
          showZoomBar:true,
          sensors:sensors,
          events:events,
          loading:this.showLoading,
//           [
//             {s:"0sna8jVYIh0xw6oF", z:0, c:240, i:23, color: this.colorArr[0], name: "Inside Temperaturex", unit: "degF", min: 0, max: 30, yShow: true},
//           ],
          getHistory: this.getHistory,
//           notify: this.notify,
          onChange: o=>this.onChange("graph",o),
        }}
        notify={this.notify}>
        </Graph01>
      </div>
    )
  }
  
  showGuideGraph=()=>{
// return null
//     cl(this.state)
    let cp=this.state.currentPreset
    
//     let sensors=[]
//     cp.sensors.forEach(s=>{
//       let sensor=sensorIds[s.v]
//       let zone=globs.zonesInfo.info[getZoneIndex(s.z)]
//       sensors.push({
//         s:zone.siteId,
//         z:zone.siteZoneIndex,
//         c:sensor.ch,
//         i:sensor.id,
//         name:sensor.name,
//         unit
//         
//       })
//     })
    return(
        <Graph01 graph={{
          divId:"guide-graph",
          isMain:false,
          showTimePeriod:this.state.showTimePeriod=="block",
//           from:1626206400,
//           to:1626177600,
//           sFrom:1626213600,
//           sTo:1626214200,
           
          from:cp.fromTime,//1624614320,
          to:cp.toTime,//1624619500,
          sFrom:cp.fromSelect,//cp.fromTime+(0.2*(cp.toTime-cp.fromTime)),// show the select box on guide graph
          sTo:cp.toSelect,//cp.fromTime+(0.8*(cp.toTime-cp.fromTime)),
          showGrid: false,
          showSelect: true,
          sensors: this.sensors,
          events:this.events,
//           [
//             {s:"0sna8jVYIh0xw6oF", z:0, c:240, i:23, color: this.colorArr[0], name: "Inside Temperature", unit: "degF", min: 0, max: 30, yShow: false},
//           ],
          getHistory: this.getHistory,
          setEvents: this.setEvents,
//           notify: this.notify,
          onChange: o=>this.onChange("graph",o),
        }}
        notify={this.notify}
        />
    )
  }
  
  showDownloadCsv=()=>{
//     cl("download csv")
    return(
      <div className="float-left-box bottom-margin">
        <C18Button00 id="downloadCsv" className="icon"
        onClick={e=>this.onChange("downloadCsv")}>
          Download CSV
          <span className="material-icons">
          file_download
          </span>
        </C18Button00>
      </div>
    )
  }
  
//   onChange=()=>{
//     cl("change")
//   }
//   
  showSaveThisView=()=>{
    return(
      <div className="float-right-box">
        <h3>Save this view</h3>
        <C18Input00 id="graphingPreset" type="text" placeholder="Name" 
          value={this.state.saveViewName}
          onChange={e=>this.onChange("saveViewName",{saveViewName:e.currentTarget.value})}
        />
        
        <C18Input00 type="radio" id="time-absolute" name="timeselect" value="absolute"
          checked={this.state.saveViewTime=="absolute"}
          onChange={e=>this.onChange("saveViewTime",{saveViewTime:e.target.value})}
        ></C18Input00>
        <label htmlFor="time-absolute">Absolute Time</label>

        <C18Input00 type="radio" id="time-relative" name="timeselect" value="relative"
          checked={this.state.saveViewTime=="relative"}
          onChange={e=>this.onChange("saveViewTime",{saveViewTime:e.target.value})}
        ></C18Input00>
        <label htmlFor="time-relative">Relative Time</label>

        <div className="spacer"></div>
        <C18Button00 type="button" className={this.state.className} onClick={o=>{this.onChange("saveView")}} disabled={!this.state.saveEnabled}>
          {this.state.saveMsg}
        </C18Button00>
      </div>
    )
  }
  
  onDateClick=(e)=>{
    cl(e)
    e.preventDefault()
    e.stopPropagation()
  }
  
  onClick=(type)=>{
//     cl(type)
//     cl(this.state.showTimePeriod)
//     cl("on click")
    switch(type){
      case "content":
        if(this.state.showTimePeriod=="block"){
//           cl("content time period block")
//           this.mySetState("n",{showTimePeriod:"none", currentPreset:Object.assign({},this.state.currentPreset)})
          
        }
        
        break
      default:
        break
    }
  }

  showCTEEntry=(entry)=>{
    return (
      <div>
        {entry.eventText && 
          entry.eventText.split("\n").map((item, idx)=> {
            return (
              <span key={idx}>
                {item}
                <br/>
              </span>
            )
          })
        }
        </div>
      )
  }

  showAlarmEntry=(entry)=> {
    // cl(entry)
    let da=(entry.ended) ? new Date(entry.end*1000) : new Date(entry.start*1000)
    let dispDate=dateToDisplayDate(da,"mm/dd/yyyy h:mm ap")
//         let page=(alarmToSensor(a.aId))?`/sensor/${alarmToSensor[a.aId]}`:""
    let url=`/usa/c18/sites/${entry.siteId}/zones/${entry.zoneId}/SPalarms`//${page}
        return (
          <table>
          <tbody>
          <tr>
          <td style={{whiteSpace:"nowrap"}}>
            <span className={
              `small-alarm-icon${(entry.ended ? " resolved-alarm" : (entry.level<=3)?" urgent-alarm":"")}`}>
            <span className="material-icons-outlined">notifications_active</span> <span className="alarm-level">{entry.level||""}</span>
            </span>
          </td>
          {(entry.active) ?
            <td><C18Anchor00 to={url}>{entry.name} (active)</C18Anchor00></td>
          :
            <td>{entry.name}<i>{(entry.ended ? "(ended)" : "(began)")}</i></td>
          }
          <td>{entry.site}</td><td>{entry.zone}</td><td>{dispDate}</td>
          </tr>
          </tbody>
          </table>
        )   
  }

  showGJEntry=(entry)=>{
      return (
        <GrowJournalWidgetEntry01 
          key={entry.growJournalId} 
          parms={{
            entry: entry,
            level: "zone",//p.level,
            avatars: this.avatars,
            getPopup: this.props.parms.getPopup,
            editing: this.state.gjEdit || false,
            ViewEntry:true
          }}
          onChange={vals=>this.onChange("gjEntry", vals)}
          />
      )
  }

  showEventBody=()=>{
    // let entry = this.state.eventEntry
    // iterate through entries in eventEntry and display in container
    return this.state.eventEntry.map((entry, i) => {
      switch (this.state.eventEntry[0].type) {
        case "grj":
          return this.showGJEntry(entry.data)
        case "cte":
          return this.showCTEEntry(entry.data)
        case "adl":
          return this.showAuditLogEntry(entry.data)
        case "ala":
          return this.showAlarmEntry(entry.data)
        case "alr":
          // return this.showAlarmEntry(entry)
      }
    })
  }

  showAuditLogEntry=(a)=>{
    let src=(a.src==1)?"(lc)":""
//       let dpName=getDatapointName(a.i)
    return(
      <div id="sideBarAuditLogEntry">
        <div className="info-section">
          <div className="floatleft">
            <img src={a.avatar} alt={a.name}/><span className="name">{a.name+src}</span>
          </div>
          <div className="floatright time-date">
            <span className="time">{a.time}</span> | <span className="date">{a.date}</span>
          </div>
          <div className="clearfloat"></div><br />
          <div className="floatleft">
            <span id="sideBarAuditLogContent" className="log-entry">{a.parmName}</span><br />
            <div className="change"><span>{`${a.oldVal}${a.unit}`}</span> -> <span>{`${a.newVal}${a.unit}`}</span></div>
          </div>
        </div>

        <div className="clearfloat"></div>
        <br /><hr /><br />
      </div>
      
    )
  }

  getAuditLogsInfo=(data)=> {
    let entries = []
    data.forEach((a)=> {
      entries.push(this.makeAuditLogEntry(a))
    })
    return entries
  }

  makeAuditLogEntry=(c)=>{
    let da=new Date(1000*c.t)
    let ui=globs.usersInfo.info[getUserIndex(c.u)]||{name:"LinkConn User"}
    return {
      parmName:getDatapointName(c?.i,globs.siteZoneTypes[c.z]),
      oldVal:c.o||"--",
      newVal:c.d,
      src:c.f,
      unit:"",
      name:ui.name,
      avatar:makeAvatarPath(ui.avatar),
      time:dateToDisplayDate(da,"h:mm AP"),
      date:dateToDisplayDate(da,"mm/dd/yyyy"),
      t:c.t
    }    
  }

  getAlarmsInfo=(data)=>{
    let entries = []
    data.forEach((a)=> {
      entries.push(this.getAlarmInfo(a))
    })
    return entries
  }

  getAlarmInfo=(a)=>{
    let alarm={}
    let si=getSiteInfo(a.s)
    let zi=getZoneInfoFromSI(a.s,a.z)// Site-Index
//       cl([si,zi])
    if(si&&zi){
      let ai=getAlarmInfo(a.a,a.s,a.z)||{}
      
//         if(a.a=="e0as0HiSa"){cl(a,ai)}
        // cl(a)
//         cl(ai)
      let level=ai.level
      let name=ai.name
      let site=si.name
      let siteId=si.siteId
      let zone=zi.zoneName||"Unknown"
      let zoneId=zi.zoneId
      alarm ={
        level:level,aId:a.a,name:name,site:site,siteId:siteId,zone:zone,zoneId:zoneId,start:a.b,end:a.e,active:a.d,
      }
    }
    return alarm
    
  }

  showEventEntry=()=>{
    if(this.state.eventEntry.length){
      let entry = this.state.eventEntry[0]
      // cl(entry)
      let leftPosition = entry.left;
      // if(entry.left < 400) {
      //   leftPosition = entry.left + entry.top;
      // }
      if(entry.left > 700) {
        leftPosition = entry.left - (entry.top + 100);
      }
      // cl(gj)
      let backgroundColor = (this.dark)?"#000000":"#FFFFFF"
      // add scroll bar
      return(
        <div style={{position: "fixed", top: 0, left: 0, height: "100%", width: "100%"}}>
          <div id="eventEntry" style={{position:"absolute",left:leftPosition ,top:entry.top, width:entry.top + 100,backgroundColor:backgroundColor,
            borderRadius:10, boxShadow:`10px 10px 10px ${this.dark ? "#202020" : "#888888"}`, maxHeight: 300, overflow: "auto",
            padding:10
          }}>
            {this.showEventBody()}
          </div>
        </div>
      )
      //               getPopup:p.getPopup,
      //               myTag:`${p.pageType}-${p.zuci}`
      //               onChange: e=>this.onChange("gjEntry01",e)
    }else{return null}
  }

  // showGJEntry=()=>{
  //   if(this.state.gjEntry){
  //     let gj=this.state.gjEntry
  //     let leftPosition = gj.left;
  //     if(gj.left < 300) {
  //       leftPosition = gj.left + gj.top;
  //     }
  //     if(gj.left > 1000) {
  //       leftPosition = gj.left - gj.top;
  //     }
  //     // cl(gj)
  //     let backgroundColor = (this.dark)?"#000000":"#FFFFFF"
  //     return(
  //       <div style={{position:"absolute",left:leftPosition ,top:gj.top, width:gj.top,backgroundColor:backgroundColor,
  //         borderRadius:10, boxShadow:`10px 10px 10px ${this.dark ? "#202020" : "#888888"}`, 
  //         padding:10
  //       }}>
  //       <div style={{float:"right",backgroundColor:(this.dark) ? "#202020" : "#EEEEEE",cursor:"pointer", padding: "5px 10px"}}
  //       onClick={e=>this.onChange("gjClose",{})}>x</div>
  //         <GrowJournalWidgetEntry01 
  //           key={gj.growJournalId} 
  //           parms={{
  //             entry: gj,
  //             level: "zone",//p.level,
  //             avatars: this.avatars,
  //             getPopup: this.props.parms.getPopup,
  //             editing: this.state.gjEdit || false
  //           }}
  //           onChange={vals=>this.onChange("gjEntry", vals)}
  //           />
  //       </div>
  //     )
  //     //               getPopup:p.getPopup,
  //     //               myTag:`${p.pageType}-${p.zuci}`
  //     //               onChange: e=>this.onChange("gjEntry01",e)
  //   }else{return null}
  // }

  // showCTEEntry=()=>{
  //   if(this.state.cteEntry){
  //     let cte=this.state.cteEntry
  //     let backgroundColor = (this.dark)?"#000000":"#FFFFFF"
  //     return(
  //       <div style={{position:"absolute",left:cte.left,top:cte.top, width:cte.top,backgroundColor:backgroundColor,
  //         borderRadius:10, boxShadow:`10px 10px 10px ${this.dark ? "#202020" : "#888888"}`, 
  //         padding:10
  //       }}>
  //       <div style={{float:"right",backgroundColor:(this.dark) ? "#202020" : "#EEEEEE",cursor:"pointer", padding: "5px 10px"}}
  //       onClick={e=>this.onChange("cteClose",{})}>x</div>
  //         <div>
  //         {cte.eventText && 
  //           cte.eventText.split("\n").map((item, idx)=> {
  //             return (
  //               <span key={idx}>
  //                 {item}
  //                 <br/>
  //               </span>
  //             )
  //           })
  //         }
  //         </div>
  //       </div>
  //     )
  //     //               getPopup:p.getPopup,
  //     //               myTag:`${p.pageType}-${p.zuci}`
  //     //               onChange: e=>this.onChange("gjEntry01",e)
  //   }else{return null}
  // }
  
  showGridCheckbox=()=>{
    return(
        <div style={{float:"left", display:"inline-block"}}>
          <input type="checkbox" 
          checked={this.state.axisOptionsShowGrid||false}
          onChange={(e)=>this.onChange("axisOptions",{axisOptionsShowGrid:e.currentTarget.checked})}
          />
          <label style={{display:"inline-block",marginLeft:10}}>Show Grid</label>
        </div>
    )
  }

  showEventCheckbox=()=>{
    // load all possible event selections into display checkbox
    return (
      <>
      {
        this.eventOptions.map((s, i)=>{
          let event = this.events.find(e=>e.id == s.value)
          return (
            <div key={i} style={{float:"left", display:"inline-block", width:"100%"}}>
              <input type="checkbox" 
              checked={event!=null || (event!=null && event.id == "tmo" && this.events.find(e=>e.id == "ala") != null)}
              disabled={s.value == "tmo" && this.events.find(e=>e.id == "ala") == null}
              onChange={(e)=>{this.onChange("chartEvents",{id: s.value})}}
              />
              <label style={{display:"inline-block",marginLeft:10, marginRight:10}}>Show {s.label}</label>
              <br></br>
            </div>
          )
        })
      }
      </>
      )
  }
  
  showAxisOptions=()=>{
    let opt=this.state.axisOptions
    let mode=this.state.axisOptionsMode
//     cl(this.sensors)
//     cl(this.state)
    return(
      <div className="popup graph-axis-popup" style={{display:(opt>=0)?"block":"none"}}>
        <h2>Axis Options</h2>
        <h3>{this.sensors[this.state.axisOptions]?.name}</h3>
        {this.showGridCheckbox()}
        <div className="float-left-box">
          <label>Vertical Scale Mode</label>
          <div>
            <input type="radio" id="graph-bounds-default" name="graph-bounds" value="default" checked={mode=="default"}
            onChange={()=>this.onChange("axisOptions",{axisOptionsMode:"default"})}/>
              <label title="Our recommended range will be used for the graph bounds" htmlFor="graph-bounds-default">Default</label>
            <input type="radio" id="graph-bounds-min-max" name="graph-bounds" value="min-max" checked={mode=="minMax"}
              onChange={()=>this.onChange("axisOptions",{axisOptionsMode:"minMax"})}/>
              <label title="Min and max data points will be used for the graph bounds" htmlFor="graph-bounds-min-max">Auto</label>
            <input type="radio" id="graph-bounds-manual" name="graph-bounds" value="manual" checked={mode=="manual"}
              onChange={()=>this.onChange("axisOptions",{axisOptionsMode:"manual"})}/>
              <label title="You set the graph bounds" htmlFor="graph-bounds-manual">Manual</label>
          </div>

          <div className="manual-axis-selector" style={{display:(this.state.axisOptionsMode=="manual")?"block":"none"}}>
            <div className="inline-block">
              <label htmlFor="axis-min">Minimum</label>
              <div></div>
              <input type="number" id="axis-min" value={this.state.axisOptionsMin}
              onChange={e=>this.onChange("axisOptions",{axisOptionsMin:e.currentTarget.value})}/>
              <span className="units">{this.state.axisOptionsUnit}</span>
            </div>
            <div className="inline-block">
              <label htmlFor="axis-max">Maximum</label>
              <div></div>
              <input type="number" id="axis-max" value={this.state.axisOptionsMax} 
                onChange={e=>this.onChange("axisOptions",{axisOptionsMax:e.currentTarget.value})}/>
              <span className="units">{this.state.axisOptionsUnit}</span>
            </div>
            </div>
          {!this.state.currentPreset.combinedYAxis &&
          <div className="color-selector">
            <label>Color</label>
            <button type="button"></button>
            <div className="axis-color-picker">
              <button style={{backgroundColor:"#009fb9"}} aria-label="aqua" aria-pressed="false"
                className={(this.state.axisOptionsColor=="#009fb9")?"selected":""}
                onClick={()=>{this.onChange("axisOptions",{axisOptionsColor:"#009fb9"})}}
              ></button>
              <button style={{backgroundColor:"#df568b"}} aria-label="rose" aria-pressed="false"
                className={(this.state.axisOptionsColor=="#df568b")?"selected":""}
                onClick={()=>{this.onChange("axisOptions",{axisOptionsColor:"#df568b"})}}
              ></button>
              <button style={{backgroundColor:"#9b8610"}} aria-label="brown" aria-pressed="true"
                className={(this.state.axisOptionsColor=="#9b8610")?"selected":""}
                onClick={()=>{this.onChange("axisOptions",{axisOptionsColor:"#9b8610"})}}
              ></button>
              <button style={{backgroundColor:"#348bed"}} aria-label="blue" aria-pressed="false"
                className={(this.state.axisOptionsColor=="#348bed")?"selected":""}
                onClick={()=>{this.onChange("axisOptions",{axisOptionsColor:"#348bed"})}}
              ></button>
              <button style={{backgroundColor:"#dc5e59"}} aria-label="orange" aria-pressed="false"
                className={(this.state.axisOptionsColor=="#dc5e59")?"selected":""}
                onClick={()=>{this.onChange("axisOptions",{axisOptionsColor:"#dc5e59"})}}
              ></button>
              <button style={{backgroundColor:"#679525"}} aria-label="green" aria-pressed="false"
                className={(this.state.axisOptionsColor=="#679525")?"selected":""}
                onClick={()=>{this.onChange("axisOptions",{axisOptionsColor:"#679525"})}}
              ></button>
              <button style={{backgroundColor:"#00a086"}} aria-label="teal" aria-pressed="false"
                className={(this.state.axisOptionsColor=="#00a086")?"selected":""}
                onClick={()=>{this.onChange("axisOptions",{axisOptionsColor:"#00a086"})}}
              ></button>
              <button style={{backgroundColor:"#9478e0"}} aria-label="lavender" aria-pressed="false"
                className={(this.state.axisOptionsColor=="#9478e0")?"selected":""}
                onClick={()=>{this.onChange("axisOptions",{axisOptionsColor:"#9478e0"})}}
              ></button>
            </div>
          </div>
        }
        </div>
        <br /><br />


        <div className="alignright ok-cancel">
          <button type="button" className="outlined "
            onClick={()=>{this.onChange("axisOptions",{type:"Cancel",axisOptions:-1})}}
          >Cancel</button> <button type="button" className="filled left-margin"
            onClick={()=>{this.onChange("axisOptions",{type:"OK",axisOptions:-1})}}
          >OK</button>
        </div>
      </div>
    )
  }

  showEventOptions=()=>{
    let opt=this.state.showEventOptions
//     cl(this.sensors)
//     cl(this.state)
    return(
      <div className="popup graph-axis-popup" style={{display:(opt>=0)?"block":"none"}}>
        <h2>Event Options</h2>
        {this.showEventCheckbox()}
        <div className="float-left-box">
        <br /><br />
          <div className="alignright ok-cancel">
            <button type="button" className="filled left-margin"
              onClick={()=>{this.onChange("eventOptions",{type:"OK",showEventOptions:-1})}}
            >OK</button>
          </div>
        </div>
      </div>
    )
  }
  
  showEvents=()=>{
    if (false) {
      return(
        <>
        <div className="float-left-box data-markers-box">
          <label>Show event timeline</label><input type="checkbox" 
          checked={this.state.currentPreset?.showEvents||false}
          onChange={e=>{this.onChange("showEvents",{showEvents:e.currentTarget.checked})}}/>
        </div>
        <div className="clearfloat"></div>
      </>
    )
  }
  }

  showDataMarkers=()=>{
    return(
      <>
        <div className="float-left-box data-markers-box">
          <label>Show data markers</label><input type="checkbox" 
          checked={this.state.currentPreset?.dataMarkers}
          onChange={e=>{this.onChange("dataMarkers",{dataMarkers:e.currentTarget.checked})}}/>
        </div>
      </>
    )
  }

  showVerticalBar=()=>{
    if (!this.state.currentPreset?.combinedYAxis) {
      return(
        <>
          <div className="float-left-box data-markers-box">
            <label>Show vertical bar</label><input type="checkbox" 
            checked={this.state.currentPreset?.vBar}
            disabled={this.state.currentPreset?.combinedYAxis}
            onChange={e=>{this.onChange("vBar",{vBar:e.currentTarget.checked})}}/>
          </div>
        </>
      )
    }

  }

  showCombinedYAxis=()=>{
    return(
      <>
        <div className="float-left-box data-markers-box">
          <label>Combined Y-Axis</label><input type="checkbox" 
          checked={this.state.currentPreset?.combinedYAxis}
          onChange={e=>{this.onChange("combinedYAxis",{combinedYAxis:e.currentTarget.checked})}}/>
        </div>
      </>
    )
  }

  showMobileView=()=>{
    if (this.mobile) {
      return(
        <>
        <div className="float-left-box data-markers-box">
          <label>Mobile View</label><input type="checkbox" 
          checked={this.state.currentPreset?.mobileView}
          onChange={e=>{this.onChange("mobileView",{mobileView:e.currentTarget.checked})}}/>
        </div>
      </>
    )
  }
  }

  showZoomBar=()=>{
    return(
      <>
        <div className="float-left-box data-markers-box">
          <label>Show Zoom Controls</label><input type="checkbox" 
          checked={this.state.currentPreset?.zoomBar}
          onChange={e=>{this.onChange("zoomBar",{zoomBar:e.currentTarget.checked})}}/>
        </div>
      </>
    )
  }

  showZoomControls=()=>{
      // get levels of zoom between min and max (0-100)
      return (
        <div className="float-right-box">
          <button type="button" className="material-icons-outlined add" aria-label="add comment"
            onClick={o=>this.onChange("zoomControls", {zoomControls: 1.149})}>
            zoom_in
          </button>
          <button type="button" className="material-icons-outlined add" aria-label="add comment"
            onClick={o=>this.onChange("zoomControls", {zoomControls: 0.87})}>
            zoom_out
          </button>
        </div>
      )
      // return (
      //   // <C18MuiSlider
      //   //   label="ZoomBarSlider"
      //   //   onChange={(e, v)=>{
      //   //     this.onChange("zoomBarSlider",{zoomBarSlider:v})
      //   //   }}
      //   // />
      // )
  }
  
  render(){
//     cl(`Render Graphing00`)
//     cl(this.state?.currentPreset?.t)
//     cl(this.state)
    let p=this.props.parms
//     cl(p)
    let cpx=Object.assign({},this.state.currentPreset)
//     cl(`Disp Time: ${Math.floor(cpx?.fromTime)}, to ${Math.floor(cpx?.toTime)
//       }, sel: ${Math.floor(cpx?.fromSelect)} to ${Math.floor(cpx?.toSelect)}`)
    
    let tabs=[
//       {v:"schedule",t:"Schedule"},
//       {v:"reports",t:"Reports"},
      {v:"graphs",t:"Graphs"},
    ]
    if(this.state.loaded){
      let view=(p.pageType=="viewGraph")
//       let edit=(p.pageType=="editGraph")
//       cl(this.state.currentPreset.dataMarkers)
//       this.makeGraphSensors()
//           <div id="content-area" onClick={o=>{this.onClick("content")}}>
//           </div>
      return(
        <div id="quickChart" onClick={e=>this.onChange("mainDiv",{})}>
                <div className="clearfloat"/>
                {!view&&
                  <>
                    {!false&&// was edit
                      <>
                        <C18SiteZoneView00 parms={{
                          level:this.getPresetLevel(),
                          site:this.state.site,
                          zone:this.state.zone,
                          pageType:p.pageType,
                          view:null,
                          getPopup:p.getPopup,
                          onChange:e=>{this.onChange("szv",e)}
                        }}/>
                        <div style={{display:"inline-flex"}}>
                        {(this.state.zone||(p.pageType=="editGraph"))&&this.showChartElements()}
                        </div>
                        <br/><br/>
                      </>
                    }
                    {this.showTimePeriod()}
                  </>
                }
                <div style={{display:"inline-flex"}}>
                  {this.showDataMarkers()}
                  {this.showVerticalBar()}
                  {this.showEvents()}
                  {this.showCombinedYAxis()}
                  {this.showMobileView()}
                  {/* {this.showZoomBar()} */}
                </div>
                <div className="clearfloat"></div>
                {this.state.currentPreset?.showEvents && this.showEventTimeline()}
                {this.showLoading()}
                {this.showZoomControls()}
                {this.showMainGraph()}
                <div className="clearfloat"></div>
                {this.showGuideGraph()}
                {this.showDownloadCsv()}
                {((!view)&&this.state.writePriv) && this.showSaveThisView()}
                <div className="clearfloat"/>
                {this.showAxisOptions()}
                {(this.state.zone||(p.pageType=="editGraph"))&&this.showEventOptions()}
                {this.showEventEntry()}
        </div>
      )
    }else{
      return <div>loading. . .</div>
    }
  }
}

export default C18Graphing00 ;

