import React from 'react';
import UsaSelect00 from '../UsaSelect00'
import UsaArrayFlags00 from '../UsaArrayFlags00'

import {cl, arrToFlags, flagsToArr} from '../../../components/utils/utils';

class RepeatWeekDays extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      weeks: 1,
      days: [false, false, false, false, false, false, false]
    }
    this.repeat=this.props.parms.repeat
  }
  
  onChange=(type, vals)=>{
/* here, we store the info as days and an array of hours. we report that to the parent as period and details(flags)*/
    let s=Object.assign(this.state, vals)
    this.repeat={
      period: s.weeks,
      details: arrToFlags(s.days),
    }
    this.setState(vals)
    this.props.parms.onChange({repeat: this.repeat})
  }
  
//   weeklyEnter=()=>{
//   }
  
  render(){
    let dayOpts=[
      "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat",
    ]
    return(
      <div>
      <UsaSelect00 parms={{
        title: "Repeat\u00A0Every",
        footer: "Weeks",
        footerPlural: true,
        type: "number",
        min: 1,
        width: 50,
        select: false, // !this.state.editMode,
        valueId: "weeks",//valueId, // "widgetSel",
        value: this.state.weeks, //value, // this.state.widgetSel,
        onChange: vals=>this.onChange("parts",vals),
      }}/>
      <div style={{height: 10}}/>
      on these days:
      <UsaArrayFlags00 parms={{
        inline: true,
        opts: dayOpts,
        columns: 7,
        valueId: "days",
        value: this.state.days,
        onChange: (vals)=>this.onChange("parts", vals),
      }}/>
      </div>
    )
  }
}

export default RepeatWeekDays ;

