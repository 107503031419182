import React from 'react';
import {cl, globs} from '../../components/utils/utils';
import {init, dbVals} from '../../components/utils/http'
import InTemp from './InTemp.js';
import {getParmValue, eqTypes, getChannelType, colorIsDark} from '../utils/utils'
import history from '../../history'

class EQ00 extends React.Component{
/* The ZCI information can come from the url, or be overridden by the dashboard or the widget*/
  constructor(props) {
    super(props);
//     cl(props)
    let p=props.parms.wdg.parms
//     cl(p.defaults.zone)
//     cl(p.zone)
    this.zo=+((p.defaults.zone)?p.defaults.zone:p.zone)
    this.ch=+((p.defaults.chan)?p.defaults.chan:p.chan)
    this.in=+((p.defaults.ind)?p.defaults.ind:p.indx)
    if (isNaN(this.zo)) this.zo=0
    if (isNaN(this.ch)) this.ch=0
    if (isNaN(this.in)) this.in=0
//     cl(this.zo, this.ch, this.in)
//     cl(props.parms.wdg.parms.dataPoints)
    props.notify({id: "drag", func: this.onDrag})
    //     cl(props.parms.wdg.parms.chan)
//     this.chTypes=getChannelTypes()
//     cl(this.chTypes)
//     cl(props.parms.wdg.parms.chan)
//     cl(props.parms.wdg.parms.defaults.chan)
//     cl(props)
// <<<<<<< HEAD
    this.chType=getChannelType(this.zo, this.ch)
    if(!this.chType) this.chType="channel_None"
//     cl(this.chType)
//     cl([this.zo, this.ch, this.in])
//     cl(props.parms.wdg.parms.defaults.chan)
//     cl(this.chType)
// =======
//     this.chType=getChannelType(0, props.parms.wdg.parms.defaults.chan)
// >>>>>>> 92d5305dd02a5743e96538650ea599c9ce9b977e
//     cl(this.chType)
//     this.chType=getParmValue(0, props.parms.wdg.parms.chan, 0, 508)
//     cl(this.chType)
//     document.title="Dashboard Display";
    props.parms.wdg.update=this.update
//     cl(props.parms.wdg)
    let pos = props.parms.wdg.parms.dataPoints.Equipment_Snapshot_Position
    if(!pos) pos = 0
    this.state={
      pos: pos,
      loaded: false,
    }
  }
  
  componentWillUnmount=()=>{
    this.props.notify({id: "drag", func: this.onDrag, unMount: true})
  }
  
  onClick=()=>{
    if(this.props.parms.e) {return}// edit mode
//     cl(this.props.parms.wdg.parms.defaults.chan)
//     cl("clicked")
//     let url=`/fui/live/${this.chType}/0-0-${this.props.parms.wdg.parms.defaults.chan}-0`
    let url=`/fui/live/${this.chType}/${this.zo}-0-${this.ch}-${this.in}`
//     cl(url)
    history.push(url)
  }
  
  update=()=>{
    let p=this.props.parms.wdg.parms
//     cl(p)
//     cl (`eq update: ${p.dataPoints.Equipment_Snapshot_Position}`)
//     cl("eq update")
    //     cl(p.dataPoints.Equipment_Snapshot_Position)
    this.setState({pos: p.dataPoints.Equipment_Snapshot_Position})
//     cl(p)
    
  }
    
    onDrag=(o)=>{// o has the updated info
      if("dragStart" in o){
        this.setState(o)
      }else{
        if(this.state.dragStart.i==this.props.parms.i){
          this.setState(o)
        }
      }
    }
    
    cm=(e)=>{
      this.props.parms.cm({e: e, i: this.props.parms.i})
    }
    
    md=(e)=>{
      this.props.parms.md({e: e, i: this.props.parms.i})
    }
    
    render() {
//       cl(this.state)
    let dm=5
    let p = this.props.parms;
    let pw=p.wdg.parms
//     cl(pw)
    let dp=p.wdg.parms.dataPoints
    let pos = dp.Equipment_Snapshot_Position
    if(!pos) pos=0
    let pos2=(pos>1) ? pos : ""
//     let color=(+dp.Equipment_Snapshot_Position == 0) ? "#CCCCCC" : "#CCFFCC"
    let color=(+this.state.pos == 0) ? pw.offColor||"#CCCCCC" : pw.onColor||"CCFFCC"
    let textColor=(colorIsDark(color)) ? "white" : "black"
    let x=p.x+dm
    let y=p.y+dm
    if(this?.state?.dragStart?.i==this.props.parms.i){
      x+=this.state.drag.x-this.state.dragStart.x
      y+=this.state.drag.y-this.state.dragStart.y
    }
//     cl(p)
//     cl(eqTypes[+dp.Equipment_Type])
    return(
      <div style={{position: "absolute", left: x, top: y, width: p.w-2*dm, height: p.h-2*dm, textAlign: "center",
        paddingTop: 0, borderRadius: 10, backgroundColor: color, color: textColor, cursor: "pointer"}} onClick={this.onClick} onContextMenu={this.cm} onMouseDown={this.md}>
        {dp.Equipment_Name}
        {(p.h>40)&&<br/>}
        {(p.h<=40)&&" / "}
        
        {eqTypes[+dp.Equipment_Type]}
        <div style={{fontSize: 40}}>
        {pos2}
        </div>
        </div>
    );
  }
}

export default EQ00;
