import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import IconButton from '@material-ui/core/IconButton';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {cl} from '../../components/utils/utils';

class MySelect extends React.Component{
  constructor(props) {
//     cl("construct");
    super(props);
    this.state={
      value: props.parms.initValue,// (props.parms.initValue) ? props.parms.initValue : "",
      text: props.parms.initText,
    }
  }
  
  setOpts=(opts)=>{
    return Object.keys(opts).map((k,i)=>{
      return(
        <option key={i} value={k}>{opts[k]}</option>
      )
    })
  }
  
  onSelChange=(e)=>{
    if(this.props.parms.select){
      let text=this.props.parms.opts[e.target.value];
      this.setState({value: e.target.value, text: text})
      let p=this.props.parms
      p.onChange(p.valueId, e.target.value)
      p.onChange(p.textId, text)
    }else{
      this.setState({text: e.target.value})
      let p=this.props.parms
      p.onChange(p.textId, e.target.value)
    }
  }
  
  
  render(){
//     cl(this.props)
    let style=(this.props.width) ? {width: this.props.width} : null
    let val = (this.props.parms.select) ? this.props.parms.initValue : this.props.parms.initText
//     cl(val)
//     cl(this.props)
    return(
      <div style={{padding: 10, display: (this.props.inline) ? "inline" : "block"}}>
      <TextField
      label={this.props.parms.title}
      select={this.props.parms.select}
      value={val}
      onChange={this.onSelChange}
      SelectProps={{
        native: true,
      }}
      >
      {this.setOpts(this.props.parms.opts)}
      </TextField>      
      </div>
    );
  }
}

export default MySelect;
