import React from 'react';
import Nutrients from './Nutrients'
import Recipes from './Recipes'
import Areas from './Areas'
import Tanks from './Tanks'
import Schedules from './Schedules'
import {cl,az,getTime} from '../../../components/utils/utils';

class IDoser extends React.Component{
  constructor(props) {// 
    super(props);
    cl(props)
    this.state={
      value: "",
      selectedTab: "Areas",
    }
  }
  
  selectTab=(tab, x)=>{
    this.setState({selectedTab: tab})
  }
  
  tabCell=(title)=>{
    let bgColor=(this.state.selectedTab==title)?"#CCFFFF":"#DDDDDD"
    return(
      <td 
        align="center" 
        style={{backgroundColor: bgColor, height: 30, width: 100, cursor: "pointer"}}
        onClick={x=>{this.selectTab(title,x)}}
      >{title}
      </td>
    )
  }
  
  showContent=()=>{
    switch(this.state.selectedTab){
      case "Nutrients": return <Nutrients/>
      case "Recipes": return <Recipes/>
      case "Areas": return <Areas/>
      case "Tanks": return <Tanks/>
      case "Schedules": return <Schedules/>
      default: return null
    }
  }
  
  
  render(){
    return(
      <div id="iDoser">
      <table><tbody>
      <tr>
      {this.tabCell("Nutrients")}
      {this.tabCell("Recipes")}
      {this.tabCell("Areas")}
      {this.tabCell("Tanks")}
      {this.tabCell("Schedules")}
      </tr>
      </tbody></table>
      {this.showContent()}
      </div>
    )
  }
}

export default IDoser ;

