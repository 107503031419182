import React from 'react';
import C2PhysView00 from './C2PhysView00'
import {cl,checkLoggedIn} from './utils/utils';
import {gdRest} from '../usa/components/C18utils'
// import history from "../../history"

class C2CodeSplit00 extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    checkLoggedIn()
    window.gdRest=gdRest
    this.state={
      loaded: true,
    }
  }
  
  showPhysView=()=>{
    return(
      <C2PhysView00 parms={{
//         urlParts:this.parts,
        url:this.props.match.url
      }}
      />
    )
  }
  
//   procUrl=(url)=>{
//     this.parts=url.split("/")
//     cl(this.parts)
//   }
  
  render(){
    cl("render codesplit")
    let parts=this.props.match.url.split("/")
    switch(parts[2]){
      case "physView":
        return this.showPhysView()
    }
    cl(parts)
    let st=this.state
    if(st.loaded){
      return (
        <div>
        Code Splitting
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
export default C2CodeSplit00;
