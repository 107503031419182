import React from 'react';
import {cl} from '../../components/utils/utils';

class C18EquipmentListCols00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      mode:"sites",
    }
  }
  
  
  render(){
    return (
      <div>C18EquipmentListCols00</div>
    )
  }
}
      
export default C18EquipmentListCols00;
