
import React from 'react';
// import {cl, globs} from '../utils/utils';// ../../components/utils/utils
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from "@material-ui/core/Input";
import InputLabel from '@material-ui/core/InputLabel';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import {cl, doGetPost, constant, openWebSocket, sendWebSocketMessage, webSocketTransaction,
  initWebSocket, saveTokens, wsTrans
} from '../utils/utils';
import {globs} from '../../components/utils/utils';
import ReCAPTCHA from "react-google-recaptcha";
import PostRegister from './PostRegister';
import history from "../../history"

 class Register extends React.Component{
   constructor(props) {
     super(props);
     document.title="Register";
//      cl(props);
//      this.initWebSocket();
     if(props.mode=="activate") this.activateUser(props.token);
     this.timeoutIF = null;
     this.alerts=[
      "Just fill in the fields, and press Register!",//0
      "Company Name Cannot Be Blank",//1
      "Company Name is In Use ",//2
      "Your Name Cannot Be Blank",//3
      "Email Cannot Be Blank",//4
      "Email is In Use ",//5
      "Please Enter a Valid Email",//6
      "Emails Don't Match ",//7
      "Password Cannot Be Blank",//8
      "Password is Too Short",//9
      "Passwords Don't Match",//10
      "What a Super-Duper Password!",//11
      "Checking . . .",//12
      "Password is Too Common ",//13
      "Just Press Register!",//14
     ];
     this.state={
       companyName: "Gene Knight Company",
       name: "Gene Knight",
       email1: "nohel89982@diide.com",
       email2: "nohel89982@diide.com",
       password1: "password",
       password2: "password",
//        companyName: "",
//        name: "",
//        email1: "",
//        email2: "",
//        password1: "",
//        password2: "",
       uc: false,
       ue: false,
       up: false,
       recaptchaResponse: "",
       alertSeverity: "info",
       alertId: 0,
//        alertMessage: "Just fill in the fields, and press Register!",
    }
   }
   
//    initFields=()=>{
//      
//   }
  
   activateUser=async(token)=>{
//      cl("activate");
//      cl(token);
     let body={token: token};
     let msgActivate = {cmd: "cRest", uri: "/o/users/activate", method: "retrieve", body: body};
     let r=await wsTrans("usa", msgActivate)
     saveTokens(r.data.accessToken, r.data.refreshToken, r.session);// should set logged in true
     history.push('/usa/userProfile');
//      wsTrans("usa", msgActivate).then(r=>{
//        saveTokens(r.data.accessToken, r.data.refreshToken, r.session);// should set logged in true
//        history.push('/usa/userProfile');
//      });
//      initWebSocket().then(// this is failing when the socket is *alread* opening
//        r=>{
//          cl(r);
// //          cl(token);
//          let body={token: token};
//          let msgActivate = {cmd: "cRest", uri: "/o/users/activate", method: "retrieve", body: body};
// //          cl(msgActivate);
//          webSocketTransaction("usa", msgActivate).then(// this will return tokens, like login, then redirect
//          // to userProfile
//            r=>{
//              cl(r)
//              saveTokens(r.data.accessToken, r.data.refreshToken, r.session);
//              history.push('/usa/userProfile');
//           },// returns tokens 
//            e=>{cl(e)}
//          );
//        },
//        e=>{}
//     );
   }
   
//    initWebSocket=()=>{
// //      cl("init web socket");
//      return openWebSocket("usa", "wss://usa.c2.link4cloud.com:constant.usaWebSocket");// a promise
// //      .then(
// //        r=>{cl(r);},
// //        e=>{cl(e)}
// //     );
//    }
   
   
//    allBlank=()=>{
//      if (this.state.companyName + this.state.name + this.state.email1 + this.state.email2 + this.state.companyName + this.state.password1 + this.state.password2 === "") {
//        this.setState({});
//     }
//   }
   
   emailsDiffer=()=>{
     return  !((this.state.email1 === "") || (this.state.email2 === "") || (this.state.email1 === this.state.email2));
   }
   
   emailsEmpty=()=>{
     return (this.state.email1 === "");
  }
  
  setAlert=(n)=>{
//     cl("alert: " + n);
    this.setState({alertId: n});
  }
  
  validEmail=(email)=>{
    let atPos = email.indexOf("@");
    let perPos = email.lastIndexOf(".");
    return ((atPos > 0) && (perPos > atPos));
  }
  
  validate=()=>{
/*
alert lines:
That Company Name is Already in Use
Please Fill in Your Name
Please fill in the Email Fields
Email Fields don't Match
That Email is Already in Use
Please Fill in the Password Fields
Password Fields don't Match
That Password is Commonly Used
What a Super Duper Password!
*/
//     let msg = "Please fill in the emails";
    cl("validate");
    if(this.state.companyName==="") return this.setAlert(1);
//     if(!this.state.uc) return this.setAlert(2)
    if(this.state.name==="") return this.setAlert(3);
    if(this.state.email1 + this.state.email2 === "") return this.setAlert(4);
    if(!this.validEmail(this.state.email1)) return this.setAlert(6);
    
    if(this.state.email1 !== this.state.email2) return this.setAlert(7);
    if(this.state.password1 + this.state.password2 === "") return this.setAlert(8);
    if(this.state.password1.length < 6) return this.setAlert(9);
    if(this.state.password1 !== this.state.password2) return this.setAlert(10);
    if(this.state.password1==="password") return this.setAlert(11);
    
//      if(this.emailsDiffer()) this.setState({alertMessage: "The emails are different"});
//      if (this.state.companyName + this.state.name + this.state.email1 + this.state.email2 + this.state.password1 + this.state.password2 === "") return this.setAlert(0);
     return this.setAlert(12);
     
   }
   
  checkFields=()=>{
    this.timeoutIF = null;
    cl("checking");
    initWebSocket().then(
      r=>{
        let body={companyName: this.state.companyName, email: this.state.email1, password: this.state.password1};
        let msgUnique = {cmd: "cRest", uri: "/o/users/unique", method: "retrieve", body: body};
        webSocketTransaction("usa", msgUnique).then(
          r=>{
            cl(r)
            if(!r.uc && (this.state.alertId > 2)) return this.setAlert(2);
            if(!r.ue && (this.state.alertId > 5)) return this.setAlert(5);
            if(!r.up && (this.state.alertId > 11)) return this.setAlert(13);
            cl("newxt");
            if(this.state.alertId === 12) this.setAlert(14);
//             this.setState({uc: r.uc, ue: r.ue, up: r.up});
            
          }, 
          e=>{cl(e)}
        );
      },
      e=>{}
    );
  }
   
   onIFChange=(e)=>{
     if(!this.timeoutIF) this.timeoutIF=setTimeout(this.checkFields, 5000);
     let key = e.currentTarget.id.substr(3);
     let val = e.currentTarget.value;
     this.setState({[key]: val});
     setTimeout(this.validate, 0);
  }
   
   inputField=(props)=>{
     return(
       <div style={{padding: 10}}>
       <InputLabel htmlFor="unknown">{props.title}</InputLabel>
       <Input
       onChange={this.onIFChange}
       value={this.state[props.field]}
       id={"if-" + props.field}
       />
       </div>
     );
   }
   
  showButton=(props)=>{
    return(
      <Button onClick={props.onClick}
      style={{margin: 10}}
      variant="contained"
      color="primary"
      id={props.type}
      className={"button"}>
      {props.text}
      </Button>
    );
  }
  
  sendRegister=()=>{
    let obj = {
      companyName: "the name",
      name: "",
      email: "",
      password: "",
    };
  }
  
  postRegister=(token)=>{
    globs.npi={name: this.state.name, email: this.state.email1, token: token};
//     cl(globs.npi);
    history.push('/usa/postRegister');
  }
  
  butClick=(e)=>{
/*
for now, this is a test of a REST connection to a USA websocket 
this is going to create a user on userAuthorize
cmd: "cRest", uri: "/ua/users", method: "create", body: {email: "new@grr.la", password: "something"}
*/
//     let createUser={email: "new@grr.la", password: "something"};
//     let usaMsg = {cmd: "cRest", uri: "/ua/users", method: "create", body: createUser};
/*
This is a REST over WS request:
POST /o/users/register: company name, user name, email, password, reCaptcha token - not secure
*/

//     let retrieveUser = {email: "new@grr.la", password: "something"};
//     let usaMsg = {cmd: "cRest", uri: "/ua/users", method: "retrieve", body: retrieveUser};
//     
//     webSocketTransaction("usa", usaMsg).then(
// //     webSocketTransaction("usa", {"cmd": "cRest", "msg": "I'm here!"}).then(
//       r=>{cl(r)}, 
//       e=>{cl(e)}
//     );
    let body = {
      companyName: this.state.companyName,
      name: this.state.name,
      email: this.state.email1,
      password: this.state.password1,
      recaptchaResponse: this.state.recaptchaResponse,
    };
    let msgRegister = {cmd: "cRest", uri: "/o/users/register", method: "create", body: body};
    wsTrans("usa", msgRegister).then(r=>{
      if(r.result==="ok") this.postRegister(r.data.token);
    });
// initWebSocket().then(
//       r=>{
//         let body = {
//           companyName: this.state.companyName,
//           name: this.state.name,
//           email: this.state.email1,
//           password: this.state.password1,
//           recaptchaResponse: this.state.recaptchaResponse,
//         };
//         let msgRegister = {cmd: "cRest", uri: "/o/users/register", method: "create", body: body};
//         cl(msgRegister);
//         webSocketTransaction("usa", msgRegister).then(
//           r=>{
//             cl(r)
//             if(r.result==="ok") this.postRegister();
//           }, 
//           e=>{cl(e)}
//         );
//       },
//       e=>{}
//     );
  }
  
  onReCaptcha=(e)=>{

    cl(e)
    this.setState({recaptchaResponse: e});
//     cl(e);
  }
  
   register=()=>{
/*
 Company Name
 User Name
 Email
 Confirm Email
 Password
 Confirm Password
 ReCaptcha
 Register
checking for unique:
with each keystroke, check for new name, email, password
if found, set a 5 sec timeout
on timeout, check uniques, and update Alert:
1) Company Name Cannot Be Blank
2) Company Name is In Use 
3) Your Name Cannot Be Blank
4) Email Cannot Be Blank
5) Emails Don't Match 
6) Please Enter a Valid Email
7) Email is In Use 
8) Password Cannot Be Blank
9) Password is Too Short
10) Password is Too Common 
11) Passwords Don't Match
 */
  return(
    <div style={{width: 350, borderRadius: 20, textAlign: "center", backgroundColor: "lightblue", padding: 20, boxShadow: "5px 5px 10px #9E9E9E"}}>
    <h3>Register</h3>
    {this.inputField({title: "Company Name", field: "companyName"})}
    {this.inputField({title: "Your Name", field: "name"})}
    {this.inputField({title: "Enter Email", field: "email1"})}
    {this.inputField({title: "Confirm Email", field: "email2"})}
    {this.inputField({title: "Enter Password", field: "password1"})}
    {this.inputField({title: "Confirm Password", field: "password2"})}
    <ReCAPTCHA style={{padding: 20}}
    sitekey="6LergcwZAAAAAEZu1hJC5b7s8ar2JSHvgyfgbYpx"
    onChange={e=>{cl("recap")}/*this.onReCaptcha*/}
    />,    
    <Alert severity={this.state.alertSeverity}>{this.alerts[this.state.alertId]}</Alert>
    {this.showButton({onClick: this.butClick, type:"register_button", text: "Register"})}
    </div>
  );
  }
  
   render(){
     return(
       <div>
       {this.state.loadMsg}
       {(this.props.mode == "register") ? this.register() : ""}
       </div>
     );
   }
 }
 
 export default Register ;
 
