import React from 'react';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import C18MuiFormControl from '../../usa/components/C18MuiFormControl'
import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
import C18MuiSelect from '../../usa/components/C18MuiSelect'
import C18MuiMenuItem from '../../usa/components/C18MuiMenuItem'
import {cl,globs} from '../../components/utils/utils';

class FUISelect extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.props.getValue(props.ind, {type: "scalar"})
    this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
//     cl("fui select get value")
  }

  makeInputMappingSelect=()=>{
    if(!this.props.custom.options){
      cl("FUI Select Control is missing Options")
      return
    }
    return this.props.custom.options.map((c, i)=>{
      return(
        <C18MuiMenuItem key={i} value={c.v}>{c.t}</C18MuiMenuItem>
      )
    });
  }

  onChange=(e)=>{
//     cl(e.target.value)
    this.props.onChange(this.props.ind, {value: e.target.value, e:e})
    this.changed=true// blocks updating
  }

    componentWillUnmount=()=>{
      this.subscribe_updateFui.remove()
//       this.subscribe_savePageEvent.remove()
    }

  updateFui=()=>{
//     cl("updateFui")
    if(!this.changed){this.props.getValue(this.props.ind, {type: "scalar"})}
  }

  render(){
//     cl(this.props)
//     if(this.props.ind==5){cl(this.props)}
//     cl(this.props.ind,this.props.value)

    let style={}
    let style2={minWidth: 150, marginTop: 22}
    if(this.props.cont.width){
      style.width=this.props.cont.width
      style2.minWidth=this.props.cont.width
    }
    if(this.props.cont.inline){
      style.display="inline"
    }
    let val=this.props.value
    if(val===true){val=1}// temp!! to handle the 'true' valuse from 800
//     if(this.props.ind==7){cl(val)}
    if(Array.isArray(val)){val=0}
//     cl(this.props.cont.title)
    return(
      <div id={this.props?.custom?.infoId}>
        <C18MuiFormControl
        style={style2}
        title={(this.props?.rest?.parent||(e=>{}))({uri:"pid",pr:this.props})}
        >
          <C18MuiInputLabel>{this.props.cont.title}</C18MuiInputLabel>
          <C18MuiSelect
            value={val}
            title={(this.props?.rest?.parent||(e=>{}))({uri:"pid",pr:this.props})}
            onChange={this.onChange}
            saveOK={this.props.saveOK}
            break={this.props?.custom?.break}
            >
            {this.makeInputMappingSelect()}
          </C18MuiSelect>
        </C18MuiFormControl>
      </div>
    );
  }

}


  export default FUISelect ;
