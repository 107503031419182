import React from 'react';
import {cl,az,getTime} from '../../components/utils/utils';

class UsaDuration00 extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      value: "",
      valueText: this.secsToHMS(this.props.value),
      gotFocus: false,
    }
  }
  
  isNumber=(x)=>{
    let y = x*1;
    return(y >= 0) && (y < 60);
  }

  checkParts=(parts)=>{
    let isNum = true;
    let tot = 0;
    for (let i = 0 ; i < parts.length ; i++){
      let p = parts[i]
      if (this.isNumber(p)){
        tot = tot * 60 + p*1;
      }else{
        return -1;
      }
    }
    return tot;
  }

  tryColons=(str)=>{
    let parts=str.split(":");
    return this.checkParts(parts);
  }
  
//   muiTimeToMins=(txt)=>{
//     let p=txt.split(":")
//     return p[0]*60+(+p[1])
//   }
//   
//   minsToDispTime=(mins)=>{
//     cl(mins)
//     let hr=Math.floor(mins/60)
//     let mn=mins%60
//     if(mn<10)mn="0"+mn
//     let ap=(hr>=12)?"PM":"AM"
//     hr=1+(hr+11)%12// 0->12, 1->1, 11->11, 12->12, 13->1
//     return `${hr}:${mn} ${ap}`
//   }
  
//   minsToMuiTime=(mins)=>{
//     let hr=Math.floor(mins/60)
//     let mn=mins%60
//     let ret=`${az(hr,2)}:${az(mn,2)}`
//     return ret
//   }

  hmsToSecs=(hms)=>{
    // cl(hms)
    let parts = hms.split(":")
    let mults = {h: 3600, m: 60, s: 1}
    let tot = 0;
    let isNum = true;
    // cl(parts)
    parts.forEach(p=>{
      if (p!==""){
        let len = p.length
        let num = p.substr(0, len -1)
        isNum &= this.isNumber(num)
        if (!isNum) return -1;
        let typ = p.substr(len-1, 1)
        tot += num * mults[typ]
      }
    })
    return tot;
  }

  tryHMS=(str)=>{
    let strh = str.replace("h", "h:")
        .replace("m", "m:")
        .replace("s", "s:");
    let secs = this.hmsToSecs(strh)
    return secs;
  }

  parseTimeString=(str)=>{
    let secs = this.tryColons(str)
    if (secs < 0) secs = this.tryHMS(str)
    return secs;
  }

  secsToHMS=(secs)=>{
//     cl(secs)
    let h = Math.floor(secs / 3600);
    let m = Math.floor(secs / 60) % 60;
    let s = secs % 60;
    return `${h}:${az(m,2)}:${az(s,2)}`

  }

  onFocus=()=>{
    this.setState({gotFocus: true})
  }
  
  onBlur=()=>{
    let secs=this.parseTimeString(this.state.valueText)
    let str=this.secsToHMS(secs)
    this.setState({valueText: str})
    
//     this.
//     if(type=="cs"){
//       this.auxControl.conditionSeconds=secs
//       this.setState({conditionSecondsFocus: false, conditionSeconds: secs, conditionSecondsText: str})
//     }else{
//       this.auxControl.actionHoldTime=secs
//       this.setState({actionHoldFocus: false, actionHoldTime: secs, actionHoldText: str})
//     }
  }
  
  onChange=(field, e)=>{
    let vals={}
    let str=e.currentTarget.value
    vals[field]=str
//     cl(e.currentTarget.value)
//     let vals={value: e.currentTarget.value}
    this.setState(vals)
    let vals2={}
    vals2[this.props.valueId]=this.parseTimeString(str)
    this.props.onChange(vals2)
    
  }
  
  render(){
//         value={(type=="cs")?this.state.conditionSecondsText:this.state.actionHoldText}
//         onChange={e=>this.onChange2((type=="cs")?"conditionSecondsText":"actionHoldText",null,e)}


    return(
      <input 
        style={{marginLeft: "0.3em", width: 75}}
        onFocus={()=>this.onFocus()}
        onBlur={()=>this.onBlur()}
        value={this.state.valueText}
        onChange={e=>this.onChange("valueText",e)}
      />
    )
  }
}

export default UsaDuration00 ;

