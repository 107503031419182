import React from 'react';
import CravoInput from './CravoInput'
import {showSelect, showInput} from '../../components/utils/reactUtils';
import {cl} from '../../components/utils/utils';

class PumpAdvancedSettings extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.state={
      show: false,
//       arr: this.props.value,
//       sensorHold:0,
//       lightThresh:0,
//       lightOverride:0,
//       tempSensor:0,
//       tempThresh:0,
//       tempOverride:0,
    }
//     this.props.getValue(props.ind, {type: "scalar"})
    this.props.getValue(props.ind, {type: "array", count: 6})
//     cl(this.props.value)
  }

  onChange=(type, valId, ind, e)=>{
//     cl([type, valId, ind, e])
//     cl(this.state)
    var vals={}
    let arr=this.props.value.slice(0)
    
    switch(type){
      case "fields":
        arr[ind]=e.currentTarget.value
        break
      case "check":
        cl("check")
        this.setState({show: e.currentTarget.checked})
//         arr[ind]=(e.currentTarget.checked)?1:0
        break
    }
    this.setState({arr: arr})
//     cl(arr)
    this.props.onChange(this.props.ind, {value: arr})
  }
  
  showInput=(val, valId, ind, onChange)=>{
    return(
      <input
        value={val}
        style={{width: 50,}}
        type="number"
        onChange={e=>onChange("inp", valId, ind, e)}
      />
    )
  }
  
  showAdvanced=()=>{
    let tankOpts={255:{t:"None"}}
    for(let i=0;i<8;i++){tankOpts[i]={t:`Tank Sensor ${i+1}`}}
    let vals=this.props.value
    if(!vals.length){vals=[0,0,0,0,0,0]}
    vals.forEach((v,i)=>{vals[i]=(v)?v:0})
    return (
      <div>

        <br></br>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flex: 1 }}>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ flex: 1 }}>Advanced Sensor Settings:</div>
              <div style={{ flex: 1 }}></div>
            </div>
          </div>

          <div style={{ flex: 1, marginLeft: "30px" }}>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ flex: 1 }}>Light Override:</div>
              <div style={{ flex: 1 }}></div>
            </div>
          </div>

          <div style={{ flex: 1, marginLeft: "37px" }}>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ flex: 1 }}>Temperature Override:</div>
              <div style={{ flex: 1 }}></div>
            </div>
          </div>
        </div>

        <br></br>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flex: 1 }}>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ flex: 1 }}>Sensor Hold Time:</div>
              <div style={{ flex: 1 }}>
                <CravoInput
                  label={"seconds"}
                  color={"#FFFFFF"}
                  width={"100%"}
                  marginLeft={"0px"}
                  value={vals[0]}
                  valueId={"arr"}
                  ind={0}
                  onChange={(valueId, ind, e) => {
                    this.onChange("fields", valueId, ind, e);
                  }}
                />
              </div>
            </div>
          </div>

          <div style={{ flex: 1, marginLeft: "29px"}}>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ flex: 1 }}>Light Threshold:</div>
              <div style={{ flex: 1 }}>
                <CravoInput
                  label={"W/m\u00B2"}
                  color={"#FFFFFF"}
                  width={"100%"}
                  marginLeft={"0px"}
                  value={vals[1]}
                  valueId={"arr"}
                  ind={1}
                  onChange={(valueId, ind, e) => {
                    this.onChange("fields", valueId, ind, e);
                  }}
                />
              </div>
            </div>
          </div>

          <div style={{ flex: 1, marginLeft: "36px" }}>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ flex: 1 }}>Temperature Sensor:</div>
              <div style={{ flex: 1 }}>
                {showSelect(
                  "fields",
                  vals[3],
                  "arr",
                  3,
                  tankOpts,
                  this.onChange
                )}
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flex: 1 }}>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ flex: 1 }}></div>
              <div style={{ flex: 1 }}></div>
            </div>
          </div>

          <div style={{ flex: 1, marginLeft: "29px" }}>
            <div style={{ display: "flex", width: "100%"  }}>
              <div style={{ flex: 1 }}>Override Setpoint:</div>
              <div style={{ flex: 1 }}>
                <CravoInput
                  label={"W/m\u00B2"}
                  color={"#FFFFFF"}
                  width={"100%"}
                  marginLeft={"0px"}
                  value={vals[2]}
                  valueId={"arr"}
                  ind={2}
                  onChange={(valueId, ind, e) => {
                    this.onChange("fields", valueId, ind, e);
                  }}
                />
              </div>
            </div>
          </div>

          <div style={{ flex: 1, marginLeft: "36px" }}>
            <div style={{ display: "flex", width: "100%"  }}>
              <div style={{ flex: 1 }}>Temperature Threshold:</div>
              <div style={{ flex: 1 }}>
                {" "}
                <CravoInput
                  label={"W/m\u00B2"}
                  color={"#FFFFFF"}
                  width={"100%"}
                  marginLeft={"0px"}
                  value={vals[4]}
                  valueId={"arr"}
                  ind={4}
                  onChange={(valueId, ind, e) => {
                    this.onChange("fields", valueId, ind, e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flex: 1 }}>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ flex: 1 }}></div>
              <div style={{ flex: 1 }}></div>
            </div>
          </div>

          <div style={{ flex: 1, marginLeft: "29px" }}>
            <div style={{ display: "flex", width: "100%"  }}>
              <div style={{ flex: 1 }}>:</div>
              <div style={{ flex: 1 }}></div>
            </div>
          </div>

          <div style={{ flex: 1, marginLeft: "36px" }}>
            <div style={{ display: "flex", width: "100%"  }}>
              <div style={{ flex: 1 }}>Override Setpoint</div>
              <div style={{ flex: 1 }}>
                <CravoInput
                  label={"W/m\u00B2"}
                  color={"#FFFFFF"}
                  width={"100%"}
                  marginLeft={"0px"}
                  value={vals[5]}
                  valueId={"arr"}
                  ind={5}
                  onChange={(valueId, ind, e) => {
                    this.onChange("fields", valueId, ind, e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  
  render(){
    return(
      <div className="checkbox-field">
      <span>
        <input 
          type="checkbox"
          checked={this.state.show}
          onChange={e=>this.onChange("check", "show", 0, e)}
          />
          <label>Show Advanced</label>
        {this.state.show&&this.showAdvanced()}
      </span>
      </div>
    )
  }

}

export default PumpAdvancedSettings ;
