import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import {cl,globs} from '../../components/utils/utils';

class C18MuiInputAdornment extends React.Component{
  constructor(props) {
    super(props);
    this.state={
    }
  }
  
  render(){
    if(globs.noMui){
      return(
        <div>
          {this.props.children}
        </div>
      )
    }else{
      return React.createElement(InputAdornment,this.props)
    }
  }
}
      
export default C18MuiInputAdornment;
 
