import React from 'react';
import OkCancel from './OkCancel';
import Select from './Select'
import UsaButton from './UsaButton';
import TextInput from './TextInput';
import UsaIcon from './UsaIcon';
import EditDashboard from './EditDashboard';
import EditWidget00 from './EditWidget00';
import Dashboard00 from './Dashboard00';

import IconButton from '@material-ui/core/IconButton';
import Refresh from '@material-ui/icons/Refresh';
// import {wsTrans, saveTokens, logout } from '../utils/utils';
import {cl, globs} from '../../components/utils/utils';
// import history from "../../history"
import {wsTrans} from '../utils/utils'
import {checkLoggedIn} from '../utils/utils';
import history from "../../history"

class EditWidget extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
//     cl("construct")
    this.notifies={}
//     cl(this.props.parms.widgetId);
    this.state={
      w: 600,
      h: 700,
      pageTitle: "Edit Dashboards",
      pageMode: "widget",
//       dashboard: "3",
      newDashboard: false,
      dashboardSel: this.props.parms.dashboardId,
      dashboardName: "",
      widgetSel: this.props.parms.widgetId,
//       dashboardId: 3,
//       dashboardName: "threex",
//       dashboardTitle: "Select\u00A0Dashboard",
//       dashboardIcon1: "dashboard-New",
//       dashboardIcon2: "dashboard-Edit",
//       dashboardIcon3: "dashboard-Delete",
      
//       siteId: globs.userData.session.siteId,
    }
//     this.dashboardState={
//       gridSize: 105,
//       gridWidth: 5,
//       gridHeight: 5,
//     }
// //     this.dashboardOpts=[
// 
// //       {v: "1", t: "one"},
// //       {v: "2", t: "two"},
// //       {v: "3", t: "three"},
// //     ]
//     this.dashboardOpts={
//       "0": "",
// //       "1": "one",
// //       "2": "two",
// //       "3": "three",
//     }
//     this.loadDashboards()
    this.login()
  }
  
  login=async()=>{
    let logged=await checkLoggedIn()
    if(!logged)history.push("/usa/login")
  }
  
  doResult=(result)=>{
    let cmds = {"ok": this.sendInfo};
//     if(cmds[result]) cmds[result]();
//     cl(result);
//     cl(this.state)
    this.props.done("setSiteDone");
  }
  
//   setDashboardStateFromId=(id)=>{
// //     cl(id)
//     let db=this.dashboards[id]
//     if(!db){
//       [id, db]=Object.entries(this.dashboards)[0]
//       this.setState({dashboardId: id, dashboardName: db.n})
//       cl(db)
// //       db=this.dashboards[Object.keys(this.dashboards)[0]
//     }
//     this.dashboardState={gridSize: db.s, gridHeight: db.h, gridWidth: db.w}
//   }
  
//   setStateValue=(id, value)=>{
// //     cl([id, value])
//     let val={};
//     val[id]=value;
//     if(id=="dashboardId"){
//       this.setDashboardStateFromId(value)
//     }
//     this.setState(val);
//   }
  
//   toggleNewDashboard=()=>{
//     cl("toggle")
//     this.setState({newDashboard: !this.state.newDashboard})
//   }
  
//   dashboardSelect2=()=>{
//     if(this.state.newDashboard){
//       return(
//         <div>
//         <UsaButton text="Create" onClick={this.toggleNewDashboard} inline/>
//         <TextInput title="New Dashboard" valueId="dashboardName" onChange={this.setStateValue}
//         initValue={this.state.dashboardName}/>
//         </div>
//       )
//     }else{
//       return(
//         <div>
//         <UsaButton text="New" onClick={this.toggleNewDashboard} inline/>
//         <Select parms={{
//           opts: this.dashboards,
//           title: this.state.dashboardTitle,
//           initValue: this.state.dashboard,
//           valueId: "dashboard",
//           onChange: this.setStateValue,
//         }} inline/>
//         </div>
//       )
//     }
//   }

//   loadDashboards=()=>{
// //     cl(globs.userData.session)
//     let getDashboard={cmd: "cRest", uri: "/s/dashboards", method: "retrieve", sessionId: globs.userData.session.sessionId,
//       body: {}};
//       wsTrans("usa", getDashboard).then(r=>{
// //         cl(r)
//         let dbs={}
//         let dbsf={}
// //         cl(r)
//         r.data.forEach(db=>{
//           dbsf[db.i]=db
//           dbs[db.i]=db.n
//         })
//         this.dashboards=dbsf
//         this.dashboardOpts=dbs
//         this.setState({dashboardSel: r.data[0].i, dashboardName: r.data[0].n})
// //         cl(r.data);
//       });
//   }

//   saveDashboard=()=>{
//     this.dashboardState.name=this.state.dashboardName
//     this.dashboardState.id=this.state.dashboardId
//     this.dashboardOpts[this.state.dashboardId]=this.state.dashboardName
//     let db = this.dashboards[this.state.dashboardId]
//     db.s=this.dashboardState.gridSize
//     db.w=this.dashboardState.gridWidth
//     db.h=this.dashboardState.gridHeight
//     cl(this.dashboardState)
//     let body=this.dashboardState
//     let putDashboard={cmd: "cRest", uri: "/s/dashboards", method: "update", sessionId: globs.userData.session.sessionId,
//       body: body};
//     wsTrans("usa", putDashboard).then(r=>{
//       cl(r);
//     });
//   }
  
//   deleteDashboard=()=>{
//     let body={id: this.state.dashboardId}
//     delete this.dashboardOpts[this.state.dashboardId]
//     delete this.dashboards[this.state.dashboardId]
//     let key=Object.keys(this.dashboardOpts)[0]
//     this.setState({dashboardId: key, dashboardName: this.dashboardOpts[key]})
//     let deleteDashboard={cmd: "cRest", uri: "/s/dashboards", method: "delete", sessionId: globs.userData.session.sessionId,
//       body: body};
//     wsTrans("usa", deleteDashboard).then(r=>{
//       cl(r);
//     });
//   }
  
  result=(type)=>{
//     switch(type){
//       case "dashboard-New":
//         this.dashboardState={gridSize: 100, gridWidth: 4, gridHeight: 4,}
//         this.setState({dashboardId: -1, dashboardName: "",
//           pageTitle: "Create Dashboard", pageMode: "dashboard",
//           newDashboard: true, dashboardIcon1: "dashboard-OK", dashboardIcon2: "dashboard-Cancel", dashboardIcon3: "dashboard-Blank", 
//           dashboardTitle: "Create\u00A0Dashboard"})
//         break
//       case "dashboard-Edit":
//         this.setDashboardStateFromId(this.state.dashboardId)
//         this.setState({pageTitle: "Edit Dashboard", pageMode: "dashboard",
//           newDashboard: true, dashboardIcon1: "dashboard-OK", dashboardIcon2: "dashboard-Cancel", dashboardIcon3: "dashboard-Blank", 
//           dashboardTitle: "Dashboard\u00A0Name"})
//         break
//       case "dashboard-OK":// save the settings for the selected dashboard
//         this.saveDashboard()
//         this.setState({pageTitle: "Edit Widget", pageMode: "widget",
//           newDashboard: false, dashboardIcon1: "dashboard-New", dashboardIcon2: "dashboard-Edit", dashboardIcon3: "dashboard-Delete", 
//           dashboardTitle: "Select\u00A0Dashboard"})
//         break
//       case "dashboard-Cancel":
//         this.setState({pageTitle: "Edit Widget", pageMode: "widget",
//           newDashboard: false, dashboardIcon1: "dashboard-New", dashboardIcon2: "dashboard-Edit", dashboardIcon3: "dashboard-Delete", 
//           dashboardTitle: "Select\u00A0Dashboard"})
//         break
//       case "dashboard-Delete":// delete the selected dashboard
//         this.deleteDashboard()
//         this.setState({pageTitle: "Edit Widget", pageMode: "widget",
//           newDashboard: false, dashboardIcon1: "dashboard-New", dashboardIcon2: "dashboard-Edit", dashboardIcon3: "dashboard-Delete", 
//           dashboardTitle: "Select\u00A0Dashboard"})
//         break
//       default:
//         break
//     }
//     cl(type)
  }
  
//   dashboardSelect=()=>{
// //     cl(this.state.dashboardName)
//       return(
//       <div>
//       <UsaIcon icon={this.state.dashboardIcon1} result={this.result} inline/>
//       <UsaIcon icon={this.state.dashboardIcon2} result={this.result} inline/>
//       <UsaIcon icon={this.state.dashboardIcon3} result={this.result} inline/>
//       
//       <Select parms={{
//         opts: this.dashboardOpts,
//         title: this.state.dashboardTitle,
//         initValue: this.state.dashboardId,
//         initText: this.state.dashboardName,
//         valueId: "dashboardId",
//         textId: "dashboardName",
//         select: !this.state.newDashboard,
//         onChange: this.setStateValue,
//       }} inline/>
//       </div>
//     )
//   }
  
//   setDashboardState=(state)=>{
//     this.dashboardState=state
// //     cl(state)
//   }
  
  
//   dashboardScreens=()=>{
//     switch(this.state.pageMode){
// //       case "dashboard":
// //         return <EditDashboard parms={{
// //           dashboard: this.state.dashboard, 
// //           w: this.state.w,
// //           state: this.dashboardState,
// //           onChange: this.setDashboardState}}/>
//       case "widget":
//         return <EditWidget00 parms={{dashboard: this.state.dashboardSel}} notify={this.notify}/>
//       default:
//         return(
//           <div>Select Widget</div>
//         )
//     }
//   }
  
  notify=(note)=>{
    if(note.id in this.notifies){}else{
      this.notifies[note.id]=[]
//       cl(note)
    }
    this.notifies[note.id].push(note.func)
  }
  
  setMyState=(o)=>{
//     cl(o)
    this.setState(o)
    for(let k in o){
      if(k in this.notifies){
        this.notifies[k].forEach(f=>{
          f(o)
        })
      }
    }
  }
  
 
  render(){
//     cl("render")
//     cl(this.state)
//     cl(this.props)
//     cl(this.props.parms.dashboardAcct)
//     cl(this.state.widgetSel)
    return(
      <div style={{position: "absolute", borderRadius: 20, textAlign: "left", left: 100, top: 100, 
        width:this.state.w, /*height: this.state.h, */overflow: "auto", backgroundColor: "bisque", padding: 10}}>
        <h3>{this.state.pageTitle}<OkCancel result={this.doResult}/></h3>
        <Dashboard00 onChange={this.setMyState} parms={{dashboardId: this.state.dashboardSel, dashboardAcct: this.props.parms.dashboardAcct}}/>
        <div style={{height: 20}}></div>
        <EditWidget00 parms={{dashboard: this.state.dashboardSel,
          widget: this.state.widgetSel}} notify={this.notify}/>
        </div>
    );
  }
}

export default EditWidget ;

