import React from 'react';
import C18Button00 from './C18Button00'
import {cl} from '../../components/utils/utils';

class C18SaveCancel00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
  }
  
  onClick=(type)=>{
    this.props.onChange({saveType:type})
  }
  
  render(){
    return(
      [
        <div key={1} className="save-cancel-box float-right-box">
          <C18Button00 type="button" className="filled" onClick={()=>{this.onClick("save")}}>
            Save
          </C18Button00>
          <C18Button00 type="button" onClick={()=>{this.onClick("cancel")}}>
            Cancel
          </C18Button00>
        </div>,
        <div key={2} className="clearfloat"></div>
      ]
    )
  }
}
      
export default C18SaveCancel00;
