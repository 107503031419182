import React from 'react';
// import IconButton from '@material-ui/core/IconButton';
// import Icon from '@material-ui/core/Icon';
import SvgIcon from "@material-ui/core/SvgIcon";
// import Leaf from '../icons/leaf.svg'
import { ReactComponent as BellIcon } from '../icons/bell.svg';
import { ReactComponent as Chemical } from '../icons/chemical.svg';
import { ReactComponent as Drop } from '../icons/drop-silhouette.svg';
import { ReactComponent as Fertilizer } from '../icons/fertilizer.svg';
import { ReactComponent as Leaf } from '../icons/leaf.svg';
import { ReactComponent as Sun } from '../icons/sun.svg';
import { ReactComponent as Thermometer } from '../icons/thermometer.svg';
// import Remove from '@material-ui/icons/Remove';
// import Settings from '@material-ui/icons/Settings';
import {wsTrans} from '../../usa/utils/utils'
import {cl, globs} from '../../components/utils/utils';
import {dbVals} from '../../components/utils/http';
import {p,pi} from '../../components/utils/paramIds';
import {blendColor} from '../utils/utils'

import {colorIsDark} from '../utils/utils';

import history from '../../history'

class Zone00 extends React.Component{
/* this gets the size in parms w, h. assume a 5px padding*/  
  constructor(props) {
    super(props);
//     cl(props)
    props.notify({id: "drag", func: this.onDrag})
    this.parts=["tmp", "hum", "co2", "lit", "ec", "ph", "alm", ]
    let seb=p.PID_BASE_SNAP_ECPHS//snapshot_ecph base
    this.sensorInfo={
      tmp:{typ: 0, pid: pi[1800].snapshots["inTemperature"], spl:pi[1800].snapshots["heatSetpoint"], sph:pi[1800].snapshots["coolSetpoint"], },
      hum:{typ: 0, pid: pi[1800].snapshots["inHumidity"], spl:pi[1800].snapshots["humidifySetpoint"], sph:pi[1800].snapshots["dehumidifySetpoint"], },
      co2:{typ: 1, pid: pi[1800].snapshots["co2"], spl:1200, sph:1800, },
      lit:{typ: 1, pid: pi[1800].snapshots["inLight"], spl:800, sph:1200, },
      ec:{typ: 2, pid: seb+pi[1800].snapshot_ecphs["ec1"], spl:seb+pi[1800].snapshot_ecphs["ecLowAlarm"], sph:seb+pi[1800].snapshot_ecphs["ecHighAlarm"], },
      ph:{typ: 2, pid: seb+pi[1800].snapshot_ecphs["ph1"], spl:seb+pi[1800].snapshot_ecphs["phLowAlarm"], sph:seb+pi[1800].snapshot_ecphs["phHighAlarm"], },
      alm:{typ: 3},// alarm
    }
//     cl(this.sensorInfo)
//     cl(seb)
    this.units={
      tmp:{name: "TEMP", icon: Thermometer, unit: "\u00B0F", color: "#00FF00", box: "0 0 24 24"},
      hum:{name: "HUM", icon: Drop, unit: "%", color: "#4080FF", box: "0 0 24 24"},
      co2:{name: "CO2", icon: Leaf, unit: "PPM", color: "#FF00FF", box: "0 0 24 24"},
      lit:{name: "LIGHT", icon: Sun, unit: "W/m\u00B2", color: "#FFFF00", box: "0 0 3 3"},
      ec:{name: "EC", icon: Fertilizer, unit: "uS", color: "#808000", box: "0 0 24 24"},
      ph:{name: "pH", icon: Chemical, unit: "", color: "#00FFFF", box: "-29 0 24 24"},
      alm:{name: "ALARM", icon: null, unit: "", color: "#FF0000", box: "0 0 24 24"},
    }
//     this.wp=this.props.parms.wdg.parms
//     this.makeLayout()
//     this.getZoneValues()
  }
  
  componentWillUnmount=()=>{
    this.props.notify({id: "drag", func: this.onDrag, unMount: true})
  }
  
  
  onDrag=(o)=>{// o has the updated info
    if("dragStart" in o){
      this.setState(o)
    }else{
      if(this.state.dragStart.i==this.props.parms.i){
        this.setState(o)
      }
    }
  }
  
  cm=(e)=>{
    this.props.parms.cm({e: e, i: this.props.parms.i})
  }
  
  md=(e)=>{
    this.props.parms.md({e: e, i: this.props.parms.i})
  }
  
  mov=(e)=>{
//     cl(e)
    this.setState({mov: true})
  }
  
  mot=(e)=>{
//     cl(e)
    this.setState({mov: false})
  }
  
  onClick=()=>{
    cl(this.props)
    if(this.props.parms.e) {return}// edit mode
//     cl(this.props.parms.wdg.parms.site)
//     let siteId=this.props.parms.wdg.parms.site
//     globs.userData.session.siteId = siteId
//     wsTrans("usa", {cmd: "cRest", uri: "/s/sessions", method: "update", 
//       sessionId: globs.userData.session.sessionId,
//       body: {siteId: siteId}})
    let url=this.props.parms.wdg.parms.link
    cl(url)
    history.push(url)
  }
  
  makeLayout=()=>{
/* calculates the number of cols to show
columns: ar=aspectRatio=w/h
1: 1
2: 2 if ar>1, or 1
3: 3 if ar>2.5 or 2
4: 4 if ar>2, or 2
5: 5 if ar>4 or 3
6: 6 if ar>4 or 3
7: 7 if ar>4 or 4
 */
//     let parts=["tmpFlag", "humFlag", "co2Flag", "litFlag", "eccFlag", "phFlag", "almFlag", ]
    let divs=[0, 1, 2, 2, 4, 4, 4]
    let cnt=0
//     let p=this.props.parms
    let wp=this.props.parms.wdg.parms
    wp.sensors=[]
    this.parts.forEach(p=>{
      if(wp[p+"Flag"]){
        cnt+=1
//         cl(p)
        wp.sensors.push(
          {type: p, setPoints: this.getSetpoints(p)}
        )
      }
//       cnt+=(wp[p])?1:0
      
    })
//     let w=this.props.parms.w
//     let h=this.props.parms.h
    let ar=this.props.parms.w/this.props.parms.h
//     cl(ar)
    if(ar<0.5){
      wp.cols=1
      wp.rows=cnt
      
    }else{
      wp.cols=(ar>divs[cnt-1])?cnt:Math.floor((cnt+1)/2)
      wp.rows=Math.ceil(cnt/wp.cols)
    }
//     cl(wp)
  }
  
  getZoneValues=(type, z)=>{
/*
   pi[1800].snapshots["inTemperature"] =	23
   pi[1800].snapshots["inHumidity"] =	27
   pi[1800].snapshots["co2"] =	31
   pi[1800].snapshots["inLight"] =	32
   pi[1800].snapshot_ecphs["ec1"] = 3
   pi[1800].snapshot_ecphs["ph1"] = 18
units:
degF degC % ppm w/m2 uS 

(big number), in color blue, green, yellow, red
icon/units - color scheme of sensor
temp green setpoints
   pi[1800].snapshots["heatSetpoint"] =	8
   pi[1800].snapshots["coolSetpoint"] =	9

hum blue
   pi[1800].snapshots["humidifySetpoint"] =	10
   pi[1800].snapshots["dehumidifySetpoint"] =	11

co2 magenta
   pi[1800].snapshot_channels["co2Setpoint"] = 8 - for co2 channel,default to 1500
lit yellow
   pi[1800].config_channels_configuration["off_threshold"] = 113 - for light channels - default to 1000
   pi[1800].config_channels_configuration["on_threshold"] = 117
ec brown
   pi[1800].snapshot_ecphs["ecLowAlarm"] = 15
   pi[1800].snapshot_ecphs["ecHighAlarm"] = 16
ph cyan
   pi[1800].snapshot_ecphs["phLowAlarm"] = 30
   pi[1800].snapshot_ecphs["phHighAlarm"] = 31
alarm red

1w/m2 = 2.1 umole/m2

temp:
40
50
60 spl
80 sph
90
100



*/
//     let pids={tmp: 23, hum: 27, co2: 31, lit: 32, ec:3, ph: 18}
    let sensorInfo={
      tmp:{typ: 0, pid: 23, spl:8, sph:9, },
      hum:{typ: 0, pid: 27, spl:10, sph:11, },
      co2:{typ: 1, pid: 31, spl:1200, sph:1800, },
      lit:{typ: 1, pid: 32, spl:800, sph:1200, },
      ec:{typ: 2, pid: 3, spl:15, sph:16, },
      ph:{typ: 2, pid: 18, spl:30, sph:31, },
    }
    switch(type){
      case "tmp":
        break
      default:
        break
    }
  }
  
  getVal=(val)=>{
    return (typeof(val)=="object")? +val.val : val
  }
  
  getSetpoints=(type)=>{
//     cl(type)
//     let type2=type.substr(0,type.length-4)
//     cl(type2)
//     cl(this.sensorInfo)
    let s=this.sensorInfo[type]
    let spType=s.typ
    let z=this.props.parms.wdg.parms.zone
    z=z||0
    if(!dbVals.z[z][240]){return [0,0,0]}
//     cl(z)
//     cl(s)
//     cl(dbVals)
    var v,spl,sph
//     cl(dbVals)
    switch(spType){
      case 0://temp/hum 
        v=this.getVal(dbVals.z[z][240][s.pid])
        spl=this.getVal(dbVals.z[z][240][s.spl])
        sph=this.getVal(dbVals.z[z][240][s.sph])
//         cl([v,spl,sph])
        break
      case 1:
//         v="1500"//dbVals.z[z][240][s.pid]
        v=this.getVal(dbVals.z[z][240][s.pid])
        spl=s.spl
        sph=s.sph
        break
      case 2:
//         cl(dbVals.z[z][240])
//         cl(dbVals.z[z][192])
//         cl([s.pid,s.spl,s.sph])
        v=+this.getVal(dbVals.z[z][192][s.pid])
        spl=+dbVals.z[z][192][s.spl]
        sph=+dbVals.z[z][192][s.sph]
        cl([v,spl,sph])
        if(type=="ec"){
          v=(v)?v:1200
          spl=(+spl)?spl:1000
          sph=(+sph)?sph:1500
        }else{
          v=(v)?v:7
          spl=(+spl)?spl:5
          sph=(+sph)?sph:9
        }
        break
      default:
        break
    }
//     cl([v,spl,sph])
    return [v,spl,sph]
  }
  
  showTempGauge=(w, h, v, type, spl, sph)=>{
//     cl([w, h, v, type, spl, sph])
    let minMax={
      tmp:[40,100],
      hum:[0,100],
      co2:[0,2000],
      lit:[0,2000],
      ec:[0,5000],
      ph:[2,12],
    }
    
    let colors=[
      "#0000FF","#0080FF","#00D0D0","#00E080","#00FF40",
      "#80FF00","#C0FF00","#D0D000","#FF8000","#FF0000",
    ]
    
    let divs=[]
    let min=minMax[type][0]
    let max=minMax[type][1]
    for(let i=0;i<4;i++){
      divs[i]=Math.floor(min+(i/4)*(spl-min))// 0-3
      divs[i+6]=Math.floor(sph+(i/4)*(max-sph))// 6-9
      divs[4]=spl
      divs[5]=Math.floor(spl+sph)/2
    }
//     cl(divs)
    var sVal, cVal// scaled value
    for(let i=0;i<10;i++){
      if(divs[i]>v){
        let frac=(v-divs[i-1])/(divs[i]-divs[i-1])
//         cl([i, frac])
        sVal=i-1+frac
//         cl(sVal)
        cVal=i-1// the color index
        break
      }
    }
//     cl(sVal)
    let widVal=+(sVal*w/10)
    let widVal2=+w
//     cl(widVal)
//     cl(widVal2)
    return(
      [
      <tr><td>
      <div style={{width: widVal, height: .10*h, overflowX: "hidden"}}>
        <table cellSpacing="0" cellPadding="0" width={widVal2}><tbody>
        <tr>
        
        {colors.map((c,i)=>{return( <td key={i} height={.10*h} style={{width: 10, backgroundColor: c}}></td> )})}
        </tr>
        </tbody></table>
      </div>
      </td></tr>,
      colors[cVal]
      ]
    )
  }
  
//   getZone
  
  showZoneValue=(x, y, w, h, i, sens)=>{
/* each sensor shown needs a current value, and a *scaled* value to show on the bar graph
0-4 is too low, 6-10 is too high, 4-6 is in the correct range
for temp and hum, get the setpoints
*/
// TEMP dF HUM % CO2 PPM LITE W/M2 ECC uS pH ALRM
//     cl([type, w, h])// 150x120
    let type=sens.type
//     cl([type, x, y, w, h, sens])
    let unit=this.units[sens.type]
//     cl(unit)
//     cl(sens)
//     let SensorIcon=unit.icon
    let bgColor=blendColor(unit.color, "#202020", 0.2)
//     cl(bgColor)
    let val=+sens.setPoints[0]
    let valLength=val.toString().length
//     cl([val, valLength])
    if(valLength<4)valLength=4
    let unitLength=unit.name.length+unit.unit.length;
//     cl(unitLength)
//     cl(val)
    let gotBar=this.props.parms.wdg.parms.graphBar
    let footerMult=(gotBar) ? ((unitLength > 6)? .10 : .15) : ((unitLength > 6)? .15 : .20)
    let footerHeight=(h>w)?footerMult*w:footerMult*h
//     let footerHeight=(gotBar) ? (h>w)?0.15*w:0.15*h : (h>w)?0.20*w:0.20*h
    let topHeight=(gotBar)?0.7*h:0.3*h
    let topFontSize=(gotBar)? ((h>w)?0.40*w:0.40*h)*4/valLength : ((h>w)?0.50*w:0.50*h)*4/valLength
//     cl([h,w,footerHeight])
//     cl(this.props)
    let tempGauge=this.showTempGauge(w, h, val, sens.type, +sens.setPoints[1], +sens.setPoints[2])// returns the gauge and the color
    return(
      <table key={i} width={w} height={h} cellSpacing="0" cellPadding="0" style={{
        position: "absolute", left: x, top: y,
        borderRadius: .1*h, backgroundColor: bgColor}}><tbody>

      <tr style={{height: 0.5*h}}><td style={{fontSize: topFontSize, fontWeight: 700, color: tempGauge[1], fontStretch: "condensed"}}>{val}</td></tr>
      
      {gotBar&&tempGauge[0]}
      <tr>
      <td>
      <span style={{fontSize: footerHeight, fontWeight: 700, color: unit.color}}>{unit.name}
      <SvgIcon style={{padding: 0, color: unit.color, width: footerHeight, height: footerHeight, marginTop: -.05*h, verticalAlign: "middle"}} 
        component={unit.icon} viewBox={unit.box} />  
      {unit.unit}</span>
      </td></tr>
      </tbody></table>
    )
  }
  
  showZoneValues=()=>{
    
    let wp=this.props.parms.wdg.parms
//     let x=0
//     let y=0
    let dm=5
    let dx=(wp.sensorWidth+dm) / wp.cols
    let dy=(wp.sensorHeight+dm) / wp.rows
    let w=dx-5
    let h=dy-5
//     cl([dx, dy])
    let rets=[]
    wp.sensors.forEach((s,i)=>{
      let x=dx*(i%wp.cols)
      let y=dy*(Math.floor(i/wp.cols))
      rets.push(this.showZoneValue(x, y, w, h, i, s))
//       cl(s)
    })
//     this.parts.forEach(p=>{
//       if(wp[p]){
//         cl(p)
//       }
//       
//     })
//     cl(wp)
    return rets
  }
  
  render() {
    this.makeLayout()
    this.getZoneValues()
    let p = this.props.parms;
    let wp=p.wdg.parms
    let fontSize=.05*p.w
    let padding=.02*p.w
    wp.topMargin=(wp.title)?1.15*fontSize+2*padding:0
//     cl(wp)
    let dm=5
    let transparent=p.wdg.parms.transparent
    var color, titleColor
    if(!transparent){
      color=wp.bgColor
      titleColor=(colorIsDark(color))?"white":"black"
    }
//     color=wp.bgColor
    let x=p.x+dm
    let y=p.y+dm
    let w=p.w-2*dm
    let h=p.h-2*dm
    let x2=dm
    let y2=dm
    let w2=w-2*dm
    let h2=h-2*dm-wp.topMargin
    wp.sensorLeft=x2
    wp.sensorLeft=x2
    wp.sensorWidth=w2
    wp.sensorHeight=h2
    if(this?.state?.dragStart?.i==this.props.parms.i){
      x+=this.state.drag.x-this.state.dragStart.x
      y+=this.state.drag.y-this.state.dragStart.y
    }
    return(
        <div style={{position: "absolute", left: x, top: y, width: w, height: h, textAlign: "center",
          paddingTop: 0, borderRadius: 10, backgroundColor: color}}>
          {(!transparent || this?.state?.mov)&&
            <>
              <div style={{textAlign: "center", fontSize: fontSize, padding: padding, color: titleColor}}>{wp.title}</div>
              <div style={{marginLeft: dm, marginTop: dm, width: w2, height: h2, position: "relative"}}>
                {this.showZoneValues()}
              </div>
            </>
          }
        <div style={{position: "absolute", left: 0, top: 0, width: w, height: h,
          paddingTop: 0, borderRadius: 10}} onClick={this.onClick}
          onContextMenu={this.cm} onMouseDown={this.md} onMouseOver={this.mov} onMouseOut={this.mot}/>
        </div>
      );
  }
}

export default Zone00;
