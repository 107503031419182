import React from 'react';
import {cl, globs, showVersion} from '../../components/utils/utils';
import Gauge2 from './Gauge';
// import {init, dbVals} from '../../components/utils/http'
// import InTemp from './InTemp.js';
// import {getParmValue, eqTypes, getChannelType} from '../utils/utils'
import history from '../../history'
import {colorIsDark} from '../utils/utils';

class GenericWidget extends React.Component{
/* this gets the size in parms w, h. assume a 5px padding*/  
  constructor(props) {
    super(props);
//     this.props.notify({id: "dashboardSel", func: this.onDashboardSel})
    props.notify({id: "drag", func: this.onDrag})
//     cl(props)
    let p=props.parms.wdg.parms
//     cl(props.parms)
    let val=(p.dataPoints)?p.dataPoints.val : 0
    this.gauge={}
    this.state={
//       value: Math.round(p.dataPoints.val * p.dec) / (p.dec * p.mult),
      value: Math.round(val * p.dec) / (p.dec * p.mult),
//       dragId: -1,
//       dragStartX: 0,
//       dragStartY: 0,
//       dragX: 0,
//       dragY: 0,
      //       props: props,
      loaded: false,
    }
    props.parms.wdg.update=this.update
//     cl(props.parms.wdg)
//     cl(p)
//     let minTemp=50
//     let maxTemp=90
//     let val=70
//     this.val=Math.round(p.val * p.dec) / p.dec
//     this.val=Math.round(p.dataPoints.val * p.dec) / (p.dec * p.mult)
//     this.title=p.title
//     this.color="#0000FF"
//     this.bgColor=p.bgColor
//     this.note=p.note
//     this.titlePadding= .02*size
//     this.titleFontSize=.12*size
//     cl(size)
//   this.setGauge()
//   let size=this.props.parms.w
//   this.gauge={
//       value: Math.round(p.dataPoints.val * p.dec) / (p.dec * p.mult),
//       width: 0.8*size-10,//p.w-10,// sets size
//       height: 0.6*size,
//       fontSize: 0.08*size,//0.6*size,//12,
//       bgColor: p.bgColor,
//       ringInset: 0.08*size, //15,
//       ringWidth: 0.15*size, //30,
//       min: p.min,
//       minAngle: -120,
//       max: p.max,
//       maxAngle: 120,
// //       ticks: ticks,
// //       colors: colors,
//       ticks: p.ticks, //[50, 70, 90],
//       colors:p.colors,//
// //       [
// //       [50, "#FF0000"],
// //       [60, "#0000FF"],
// //       [90, "#FF0000"],
// //       ]
//     }
//     setInterval(this.testUpdates, 3000)
//   cl(this.gauge)
  }
  
  componentWillUnmount=()=>{
    this.props.notify({id: "drag", func: this.onDrag, unMount: true})
  }
  
  setGauge=()=>{
    let p=this.props.parms.wdg.parms
    let size=this.props.parms.w
    let val=(p?.dataPoints?.val) ? p.dataPoints.val : 0
//     cl("set Gauge")
    this.gauge.value=Math.round(val * p.dec) / (p.dec * p.mult)
    this.gauge.width=0.8*size-10//p.w-10,// sets size
    this.gauge.height=0.6*size
    this.gauge.fontSize=0.08*size//0.6*size,//12,
    this.gauge.bgColor=p.bgColor
    this.gauge.ringInset=0.08*size //15,
    this.gauge.ringWidth=0.15*size //30,
    this.gauge.min=p.min
    this.gauge.minAngle=-120
    this.gauge.max=p.max
    this.gauge.maxAngle=120
    this.gauge.ticks=p.ticks //[50, 70, 90],
    this.gauge.colors=p.colors//
    
  }
  
  update=(wdg, paramId, isRender)=>{
    showVersion(this.gauge)
//     showVersion(wdg)
    if(wdg==this.gauge){cl("the same")}
//     cl(this.gauge)
//     cl(isRender)
    let p=this.props.parms.wdg.parms
    let val = Math.round(p.dataPoints.val * p.dec) / (p.dec * p.mult)
    this.gauge.value=val
//     cl(`update ${val}`)
//     cl(val)
//     cl(this.gauge.update)
//     cl(this.gauge)
    this.gauge.update(isRender)
//     cl(isRender)
    if(!isRender){
//       cl("setting state")
      this.setState({value: val})
    }
  }
  

  testUpdates=()=>{
    this.updVal=(this.updVal==72) ? 74 : 72
//     cl(`update ${this.updVal}`)
    this.gauge.value=this.updVal
    
  }
  
  onClick=()=>{
    if(this.props.parms.e) {return}// edit mode
    //     cl(this.props.parms.wdg)
//     cl("clicked")
//     let url=`/fui/live/${this.chType}/0-0-${this.props.parms.wdg.parms.chan}-0`
    let url=this.props.parms.wdg.parms.link
//     cl(url)
    history.push(url)
  }
  
  onDrag=(o)=>{// o has the updated info
    if("dragStart" in o){
      this.setState(o)
    }else{
      if(this.state.dragStart.i==this.props.parms.i){
        this.setState(o)
      }
    }
  }
  
  cm=(e)=>{
    this.props.parms.cm({e: e, i: this.props.parms.i})
  }
  
  md=(e)=>{
    this.props.parms.md({e: e, i: this.props.parms.i})
  }
  
  render() {
//     cl("render")
    this.setGauge()
//     cl(this.gauge)
//     cl("generic render")
//     cl(this.props)
//     cl(this.props.parms.wdg.parms.dataPoints.val)
//     cl(this.props.config.value)
    let dm=5
    let p = this.props.parms;
    let p2=p.wdg.parms
//     let size=props.parms.w
//     cl(p2)
    this.gauge.value=this.state.value // p2.dataPoints.val/p2.mult
//     if(!p?.wdg?.parms?.dataPoints?.val) return(
//       <div>No Data</div>
//     )
    if(!p?.wdg?.parms?.dataPoints?.val) {p.wdg.parms.dataPoints.val=0}
//     cl(p)
    let color= p2.bgColor
    let x=p.x+dm
    let y=p.y+dm
    if(this?.state?.dragStart?.i==this.props.parms.i){
      x+=this.state.drag.x-this.state.dragStart.x
      y+=this.state.drag.y-this.state.dragStart.y
    }
    let textColor=colorIsDark(p2.bgColor)?"white":"black"
//     cl(this.color)
//     cl(this.props)
//     cl([x, y])
    return(
        <div style={{position: "absolute", left: x, top: y, width: p.w-2*dm, height: p.h-2*dm, textAlign: "center",
          paddingTop: 0, borderRadius: 10, backgroundColor: color}} onClick={this.onClick}
          onContextMenu={this.cm} onMouseDown={this.md}>
          <div style={{textAlign: "center", fontSize: .12*p.w, padding: .02*p.w, color: textColor}}>{p2.title}</div>
          <Gauge2 config={this.gauge}/>
          <div style={{textAlign: "center", fontSize: .12*p.w, marginTop: 0-(1*.12*p.w), color: textColor}}>
            {Math.round(p2.dataPoints.val * p2.dec) / (p2.dec * p2.mult)}</div>
            <div style={{textAlign: "center", fontSize: 0.5*.12*p.w, padding: 0, color: textColor}}>{p2.note}</div>
          </div>
      );
  }
}

export default GenericWidget;
