import React from 'react';
import UsaSelect00 from './UsaSelect00'
import UsaIcon from './UsaIcon';
import UsaCheckbox00 from './UsaCheckbox00';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {cl, globs, constant, getRandomString} from '../../components/utils/utils';// ,globs,az,getTime, getRandomString
import {wsTrans, checkLoggedIn} from '../utils/utils'
import history from "../../history"

class ManageSiteAccess extends React.Component{
  constructor(props) {
    super(props);
    document.title="Manage Site Access";
    this.state={
      siteEdit: false,
      site: globs.userData.session.siteId,
      loaded: false,
      userSite: {// rows are sites, columns are users
        "WexjdbLVbro_v13Z": [false,false],
        "5GCiyYvQUVpWUyA8": [false,false],
      }
    }
    cl(this.state)
    this.initAll()
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Site Access", url:`/usa/c18/admin/manageSiteAccess`},
            ]},
        },
      )
    }
  }
/*
userSite has a row for each site, and a column for each user*/
  
//   getSiteIndex=(siteId)=>{
//     for(let i=0; i<this.state.siteOpts.length; i++){
//       if(this.state.siteOpts[i].v==siteId){return i}
//     }
//     return -1
//   }

//   loadSiteAccess=async(users)=>{// makes the userSite array
//     let sa=(await wsTrans("usa", {cmd: "cRest", uri: "/s/siteAccess", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId,
//       body: {}})).data// accountId will be added as a search parameter by the server
//     cl(sa)
//     cl(users)
//   }

  saveSiteAccessO=()=>{
/* this should send a single array. the rows are /site, and the columns are users
 Actually, this will be saved as a list of rows:
 accountId, siteId, userId, privs*/
    let saves=[]
    let s=this.state.userSite
    let u=this.state.users
    cl(u)
    cl(s)
    Object.keys(s).forEach((k,i)=>{
      s[k].forEach((e,j)=>{
        if(e){
          saves.push({siteId: k,userId: u[j].userId})
        }
      })
    })
    cl(saves)
    wsTrans("usa", {cmd: "cRest", uri: "/s/siteAccess", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: saves})
  }

//   getAccountUsers=async()=>{
// //     cl("get account")
//     let usersReq=(await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId,
//       body: {accountId: globs.userData.session.accountId}})).data
// //     cl(usersReq)
//     return usersReq
//   }

  initAll=async()=>{
    let usP=wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {accountId: globs.userData.session.accountId}})
//     let aaP=this.getAccountAccess()
    let aaP=wsTrans("usa", {cmd: "cRest", uri: "/s/areaPrivs", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {accountId: globs.userData.session.accountId, level: "account"}})

    let vals=await Promise.all([usP, aaP])
    let [usR, aaR]=vals
    cl(aaR)
    this.users={}
    usR.data.forEach(u=>{
      this.users[u.userId]=u
    })
//     cl(this.users)
    let account={}
    let users=usR.data
    users.forEach(u=>{
      account[u.userId]= {read: false, write: false, name: u.name, userId: u.userId}
    })
    aaR.data.forEach(a=>{
      account[a.userId]=a
//       cl(a)
//       account.push({
//         read:((a.flags&constant.AREA_PRIVS_READ)!=0),
//         write:((a.flags&constant.AREA_PRIVS_WRITE)!=0),
//         name: this.users[a.userId].name,
//         userId: a.userId,
//       })
    })
    
//     cl(account)
//     let account=[]
//     users.forEach(u=>{
// //         cl(u)
//       account.push(
//         {read: false, write: false, force: false, name: u.name, userId: u.userId}
//       )
//     })
    this.setState({account: account, users: users, loaded: true})
  }
  
//   getSitesO=async()=>{// different 
//     let logged=await checkLoggedIn()
//     if(!logged){history.push("/usa/login"); return}
// 
//     let srP=wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId,
//       body: {}})
//     let saP=wsTrans("usa", {cmd: "cRest", uri: "/s/siteAccess", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId,
//       body: {}})// accountId will be added as a search parameter by the server
//     let usP=wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId,
//       body: {accountId: globs.userData.session.accountId}})
//     let vals=await Promise.all([srP, saP, usP])
// //     Promise.all([srP, saP, usP]).then(vals=>{
//       let [srR, saR, usR]=vals
//       let sitesReq=srR.data
//       let siteAccess=saR.data
//       let users=usR.data
//       cl(srR)
//       let siteSel=srR.data[0].siteId
//   // make userSite array:
//       let rowBase=[]
//       let userIds={}
//       users.forEach((u,i)=>{// make rowBase and UserIds
//         rowBase.push(false)
//         userIds[u.userId]=i
//       })
//       let userSite={}
//       sitesReq.forEach(s=>{// init userSite to false
//         userSite[s.siteId]=rowBase.slice(0)
//       })
//       siteAccess.forEach(sa=>{// fill in the true values
//         let ind=userIds[sa.userId]
//         userSite[sa.siteId][ind]=true
//       })
//       let sites={}
//       sitesReq.forEach(s=>{// make sites object
//         sites[s.siteId]=s.name
//       })
// //       let site=""
// //       if(sitesReq.length){// 
// //         site=(this.state.site)?this.state.site:sitesReq[0].siteId
// //       }
// //       cl(site)
//       let account=[]
//       users.forEach(u=>{
//         account.push(
//           {read: false, write: false, force: false, name: u.name}
//         )
//       })
// //       cl(account)
//       this.setState({siteOpts: sites, users: users, siteSel:siteSel, userSite: userSite, 
//         account: account})
// //     })
//       let site=await this.getSite(siteSel)
//       cl(site)
//       this.setState({site: site, loaded: true})
//   }
  
  getSite=async (siteId)=>{
    cl("get site")
//     cl(siteId)
    let site=[]
    this.state.users.forEach(u=>{// this needs to actually read the data
      site.push(
        {read: false, write: false, name: u.name, userId: u.userId}
      )
    })
    return site
  }
  
  getZone=async (zoneId)=>{
    let zone=[]
    this.state.users.forEach(u=>{// this needs to actually read the data
      zone.push(
        {read: false, write: false, name: u.name, userId: u.userId}
      )
    })
    return zone
  }
  
  onChange=async(type, vals, ind)=>{
//     cl(type)
//     cl(vals)
    var site
    var zone
    var vals2
    switch(type){
      case "account":
        let account=this.state.account.slice(0)
        account[ind]=Object.assign(account[ind], vals)
        this.setState({account: account})
//         cl(account[ind])
        break
      case "siteSel":
        site=await this.makeSiteAccess(vals.siteSel)
        vals2=Object.assign(vals, {site: site, zoneEdit: false})
//         cl(vals2)
        this.setState(vals2)
        break
      case "zoneSel":
        zone=await this.makeZoneAccess(vals.zoneSel)
        vals2=Object.assign(vals, {zone: zone})
//         cl(vals2)
        this.setState(vals2)
        break
      case "site":
        site=this.state.site.slice(0)
        site[ind]=Object.assign(site[ind], vals)
        this.setState({site:site})
        break
      case "zone":
        zone=this.state.zone.slice(0)
        zone[ind]=Object.assign(zone[ind], vals)
        this.setState({zone:zone})
        break
        
//       case "enable":
//         let userSites=Object.assign({},this.state.userSite)
//         userSites[this.state.site][ind]=vals.enable
//         this.setState({userSites: userSites})
//         break
//       case "site":
//         cl(vals)
//         this.setState(vals)
//         break
      default:
        break
    }
//     this.setState(vals)
  }
  
  getSites=async()=>{
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {accountId: globs.userData.session.accountId}})
//     cl(r)
    let sites=r.data
    let siteOpts={}
    sites.forEach(s=>{
      siteOpts[s.siteId]=s.name
    })
    this.siteOpts=siteOpts
    this.siteSel=sites[0].siteId
    return sites
//     cl(siteOpts)
  }
  
  getZones=async()=>{
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId: this.state.siteSel}})
    let zones=r.data
    if(!zones.length)return
//     cl(zones)
    let zoneOpts={}
    zones.forEach(z=>{
      zoneOpts[z.zoneId]=z.zoneName
    })
    this.zoneOpts=zoneOpts
    this.zoneSel=zones[0].zoneId
//     cl(this.zoneOpts)
  }
  
//   getAccountAccess=async()=>{
//     cl("get aa")
//   }
  
//   makeAccountAccess=async()=>{
//     cl("make account")
//   }
//   
  makeSiteAccess=async(siteSel)=>{
//     cl("make site")
//     cl(this.state)
    
    let siteAccess=await wsTrans("usa", {cmd: "cRest", uri: "/s/areaPrivs", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId: siteSel, level: "site"}})
    let site=[]
    if(siteAccess.data.length){
      siteAccess.data.forEach(s=>{
        site.push({
          read:((s.flags&constant.AREA_PRIVS_READ)!=0),
          write:((s.flags&constant.AREA_PRIVS_WRITE)!=0),
          name: this.users[s.userId].name,
          userId: s.userId,
        })
      })
    }else{
      this.state.users.forEach(u=>{// this needs to actually read the data
        site.push(
          {read: false, write: false, name: u.name, userId: u.userId}
        )
      })
    }
    return site
//     this.setState({siteEdit: true, siteSel: this.siteSel, site: site})
  }
  
  makeZoneAccess=async(zoneSel)=>{
    let zoneAccess=await wsTrans("usa", {cmd: "cRest", uri: "/s/areaPrivs", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {zoneId: zoneSel, level: "zone"}})
    let zone=[]
    if(zoneAccess.data.length){
      zoneAccess.data.forEach(s=>{
        zone.push({
          read:((s.flags&constant.AREA_PRIVS_READ)!=0),
          write:((s.flags&constant.AREA_PRIVS_WRITE)!=0),
          name: this.users[s.userId].name,
          userId: s.userId,
        })
      })
    }else{
      this.state.users.forEach(u=>{// this needs to actually read the data
        zone.push(
          {read: false, write: false, name: u.name, userId: u.userId}
        )
      })
    }
    return zone
  }
  
//   makeZoneAccess=async()=>{
//     cl("make zone")
//     await this.getZones()
//     let zone=await this.getZone(this.zoneSel)
//     cl(zone)
//     this.setState({zoneEdit: true, zoneSel: this.zoneSel, zone: zone})
//   }
  
  saveZoneAccess=async()=>{
    let privs=[]
    let zoneId=this.state.zoneSel
    var flags
    this.state.zone.forEach(u=>{
      flags=0
      if(u.read)flags|=constant.AREA_PRIVS_READ
      if(u.write)flags|=constant.AREA_PRIVS_WRITE
      privs.push({level: "zone", userId: u.userId, zoneId: zoneId, flags: flags})
    })
    cl(privs)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/areaPrivs", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {privs: privs, level: "zone", zoneId: zoneId}})
    cl("zone save")
  }
  
  saveSiteAccess=async()=>{
//     cl("save it")
//     cl(this.state)
    let privs=[]
//     cl(globs)
    let siteId=this.state.siteSel
    var flags
    this.state.site.forEach(u=>{
      flags=0
      if(u.read)flags|=constant.AREA_PRIVS_READ
      if(u.write)flags|=constant.AREA_PRIVS_WRITE
      privs.push({level: "site", userId: u.userId, siteId: siteId, flags: flags})
    })
//     cl(privs)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/areaPrivs", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {privs: privs, level: "site", siteId: siteId}})
  }
  
  saveAccountAccess=async()=>{
/*
this makes an array of userId, accountId, priv Flags
the server will erase *all* entries for this accountId, and then insert these*/
    let privs=[]
    cl(globs)
    let accountId=globs.userData.session.accountId
    var flags
    this.state.account.forEach(u=>{
      flags=0
      if(u.read)flags|=constant.AREA_PRIVS_READ
      if(u.write)flags|=constant.AREA_PRIVS_WRITE
      privs.push({level: "account", userId: u.userId, accountId: accountId, flags: flags})
    })
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/areaPrivs", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {privs: privs, level: "account", accountId: accountId}})
  }
  
  result=async(type)=>{
//     cl(type)
    switch(type){
      case "Account-OK":
        this.saveAccountAccess()
      case "Account-Cancel":
        break;
      case "Site-Edit":
        if(!this.siteOpts) await this.getSites()// this doesn't need to get sites every time
        let site=await this.makeSiteAccess(this.siteSel)
        this.setState({siteEdit: true, siteSel: this.siteSel, site: site})
        break;
      case "Site-OK":
        this.saveSiteAccess()
      case "Site-Cancel":
        this.setState({siteEdit: false, zoneEdit: false})
        break;
      case "Zone-Edit":
        await this.getZones()
        let zone=await this.makeZoneAccess(this.zoneSel)
        this.setState({zoneEdit: true, zoneSel: this.zoneSel, zone: zone})
        break;
      case "Zone-OK":
        this.saveZoneAccess()
      case "Zone-Cancel":
        this.setState({zoneEdit: false})
        break;
//       case "Access-OK":
//         cl("access")
//         this.saveSiteAccess()
//         break;
    }
    
  }
  
//   showUserList=()=>{
// //     cl(this.state)
//     let row=this.state.userSite[this.state.site]
//     if(row){
//       return(
//         <div style={{width: 400, height: 100, backgroundColor: "#DDDDDD", padding: 0,
//           overflowY: "auto",
//         }}>
//           {this.state.users.map((u,i)=>{
//             return(
//               <div key={i}>
//                 <div/>
//                 <UsaCheckbox00 key={3} parms={{
//                   title: u.name,
//                   value: row[i],
//                   valueId: "enable",
//                   onChange: o=>this.onChange("enable", o, i),
//                 }}
//                 />
//               </div>
//             )
//           })}
//         </div>
//       )
//     }
//   }
  
//   showUsers=()=>{
//     return (
//       <div style={{padding: 0}}>
//       <h3>Manage Site Access</h3>
//       <UsaIcon icon="Access-OK" result={this.result} inline/>
//       <UsaIcon icon="Access-Cancel" result={this.result} inline/>
//       <UsaSelect00 parms={{
//         title: "Select\u00A0Site",
//         inline: true,
//         width: 200,
//         select: true, // !this.state.editMode,
//         valueId: "site",//valueId, // "widgetSel",
//         value: this.state.site, //value, // this.state.widgetSel,
//         opts: this.state.siteOpts,
//         onChange: (vals)=>this.onChange("site", vals),
//       }}/>
//       <div style={{height: 20}}/>
//       {this.showUserList()}
//       </div>
//     )
//   }
  
  showUserAccess=(userArray, type)=>{
    cl(userArray)
    let row=this.state.userSite[this.state.site]
//     cl(userArray)
    if(true || row){
      return(
        <div style={{width: 600, height: 100, backgroundColor: "#DDEEEE", padding: 0,
          overflowY: "auto", paddingLeft: 20,
        }}>
          {Object.values(userArray).map((u,i)=>{
//             u.force=true
//             cl(u)
            return(
              <div key={i}>
                <div/>
                <UsaCheckbox00 key={1} parms={{
                  title: "Read",
                  inline: true,
                  value: u.read,
                  valueId: "read",
                  onChange: o=>this.onChange(type, o, i),
                }}
                />
                <UsaCheckbox00 key={2} parms={{
                  title: "Write",
                  inline: true,
                  value: u.write,
                  valueId: "write",
                  onChange: o=>{
//                     cl(o)
                    this.onChange(type, o, i)
                  }
                }}
                />
                <span style={{marginLeft: "2.5em"}}></span>{u.name}
              </div>
            )
          })}
        </div>
      )
    }
    
  }
  
  accountAccess=()=>{
    return (
      <div>
      <h3>
        <UsaIcon icon="Account-OK" result={this.result} inline/>
        <UsaIcon icon="Account-Cancel" result={this.result} inline/>
      Account Access</h3>
      {this.showUserAccess(this.state.account, "account")}
      </div>
    )
  }
  
  showSiteEdit=()=>{
    if(this.state.siteEdit){
//       cl(this.state.site)
      return(
        <div>
          <UsaSelect00 parms={{
            title: "Select\u00A0Site",
            select: true, // !this.state.editMode,
            inline: true,
            valueId: "siteSel",//valueId, // "widgetSel",
            value: this.state.siteSel, //value, // this.state.widgetSel,
            opts: this.siteOpts,
            onChange: o=>{this.onChange("siteSel", o)},
          }}/>
          <div style={{height: 10}}/>
          {this.showUserAccess(this.state.site, "site")}
        </div>
      )
    }else{
      return null
    }
  }
  
  showZoneEdit=()=>{
//     cl(this.zoneOpts)
    if(this.state.zoneEdit&&this.zoneOpts){
//       cl(this.state.site)
      return(
        <div>
          <UsaSelect00 parms={{
            title: "Select\u00A0Zone",
            select: true, // !this.state.editMode,
            inline: true,
            valueId: "zoneSel",//valueId, // "widgetSel",
            value: this.state.zoneSel, //value, // this.state.widgetSel,
            opts: this.zoneOpts,
            onChange: o=>{this.onChange("zoneSel", o)},
          }}/>
          <div style={{height: 10}}/>
          {this.showUserAccess(this.state.zone, "zone")}
        </div>
      )
    }else{
      return null
    }
  }
  
  showSiteHead=()=>{
    if(this.state.siteEdit){
      return(
        <h3>
        <UsaIcon icon="Site-OK" result={this.result} inline/>
        <UsaIcon icon="Site-Cancel" result={this.result} inline/>
        <span style={{marginLeft: "1em"}}></span>Site Access</h3>
      )
    }else{
      return(
        <h3>
        <UsaIcon icon="Site-Edit" result={this.result} inline/>
        <span style={{marginLeft: "1em"}}></span>Site Access</h3>
      )
    }
  }
  
  showZoneHead=()=>{
    if(this.state.zoneEdit){
      return(
        <h3>
        <UsaIcon icon="Zone-OK" result={this.result} inline/>
        <UsaIcon icon="Zone-Cancel" result={this.result} inline/>
        <span style={{marginLeft: "1em"}}></span>Zone Access</h3>
      )
    }else{
      return(
        <h3>
        <UsaIcon icon="Zone-Edit" result={this.result} inline/>
        <span style={{marginLeft: "1em"}}></span>Zone Access</h3>
      )
    }
  }
  
  siteAccess=()=>{
    return (
      <div>
      {this.showSiteHead()}
      {this.showSiteEdit()}
      </div>
    )
  }
  
  zoneAccess=()=>{
    return (
      <div>
      {this.showZoneHead()}
      {this.showZoneEdit()}
      </div>
    )
  }
  
  render(){
    if(this.state.loaded){
      return (
        <div>
          {this.accountAccess()}
          {this.siteAccess()}
          {this.zoneAccess()}
        </div>
      )
    }else{
      return <div>loading...</div>
    }
  }
    
}

export default ManageSiteAccess ;
 
 
