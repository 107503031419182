import React from 'react';
import UsaSelect00 from './UsaSelect00'
import UsaIcon from './UsaIcon';
import {cl, globs, getRandomString, getTime} from '../../components/utils/utils';// ,globs,az,getTime, getRandomString
import {wsTrans, checkLoggedIn} from '../utils/utils'
import {checkSendArray, openCurSite} from '../../components/utils/http'
import {checkNewSite} from '../../components/utils/ws'
import {pInd, pi} from '../../components/utils/paramIds'
// import history from "../../history"

class ManageZones extends React.Component{
  constructor(props) {
    super(props);
    document.title="Manage Zones";
    this.state={
      loaded: false
    }
//     this.getZones()
    this.getSites()
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Zones", url:`/usa/c18/admin/manageZones`},
            ]},
        },
      )
    }
  }
  
  makeZoneOpts=(site)=>{
    let zoneOpts={}
    let zone=null
    let gateways=[]
    this.gateways.forEach(g=>{
      if(g.siteId==site){gateways.push(g.gatewayId)}
    })
    this.zones.forEach(z=>{
//       if(z.siteId==site){
      if(gateways.includes(z.gatewayId)){
//         cl(z)
        if(!zone){zone=z.zoneId}
        let name=(z.zoneName)?z.zoneName : `New Site Zone ${z.siteZoneIndex+1}`
        zoneOpts[z.zoneId]=name
      }
    })
    return [zoneOpts, zone]
//     cl(this.zones)
  }
  
  getSites=async()=>{// this is currently system-wide, and needs to be made account-wide
    
    checkLoggedIn()
    openCurSite()
//     checkNewSite()
    
    var resp
    resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
    let sites=resp.data
    cl(sites)
    let siteOpts={}
    sites.forEach(s=>{
      siteOpts[s.siteId]=s.name
    })
    let site=sites[0].siteId
    let siteArr=[]
    sites.forEach(s=>{
      siteArr.push(s.siteId)
    })
    cl(siteArr)
    resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "retrieve2", 
      sessionId: globs.userData.session.sessionId, body: {siteId: {$in:siteArr}}})
    this.gateways=resp.data
    let gatewayArr=[]
    this.gateways.forEach(g=>{
      gatewayArr.push(g.gatewayId)
    })
    cl(gatewayArr)
    resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {gatewayId:{$in:gatewayArr}}})
    cl(resp)

    this.zones=resp.data
    cl(this.zones)
    let [zoneOpts, zone]=this.makeZoneOpts(site)
    cl(zoneOpts)
    this.setState({siteOpts: siteOpts, site: site, loaded: true,
      zoneOpts: zoneOpts, zone: zone, zoneName: zoneOpts[zone]
    })
//     let zones=resp.data
//     zones.forEach(z=>{
//       cl(z.zoneId)
//     })
  }
  
//   getZones=async()=>{
// /* the site's zones are defined to be all the zones that belong to all the gateways
// that are in the site*/
//     let siteP=wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId, body: {}})
//     let zoP=wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId, body: {}})
//     let [siteR, zoR]=await Promise.all([siteP, zoP])
//     cl(zoR)
//     let sites=siteR.data
// //     cl(sites)
//     let siteOpts={}
//     sites.forEach(s=>{
//       siteOpts[s.siteId]=s.name
//     })
//     let site=sites[0].siteId
//     this.zones=zoR.data
//     cl(this.zones)
//     let [zoneOpts, zone]=this.makeZoneOpts(site)
//     this.setState({siteOpts: siteOpts, site: site, loaded: true,
//       zoneOpts: zoneOpts, zone: zone, zoneName: zoneOpts[zone]
//     })
//   }

  getSiteZoneIndex=(zoneId)=>{
    for(let i=0;i<this.zones.length;i++){
//       cl([zoneId,this.zones[i].zoneId])
      if(zoneId==this.zones[i].zoneId){return this.zones[i].siteZoneIndex}
    }
    return -1;
  }
  
  saveControllerZoneName=(siteId, zoneId, name)=>{
    cl([siteId, zoneId, name])
    let siteZoneIndex=this.getSiteZoneIndex(zoneId)
    let ind=pInd[1800].config_zones[0]+pi[1800].config_zones["name"]
    let now=Math.floor(getTime())
    let msg={z:siteZoneIndex, c:255, i:ind, f:1, t:now, d:name}// s:siteId, 
    checkSendArray([msg])
  }
  
  updateZone=async()=>{
    cl(this.state)
    this.saveControllerZoneName(this.state.site, this.state.zone, this.state.zoneName);
    await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update", 
      sessionId: globs.userData.session.sessionId, body: {
        zoneId: this.state.zone,
        siteId: this.state.site,
        zoneName: this.state.zoneName,
      }})
  }
  
  onChange=(type, vals)=>{
//     cl(type)
    var zoneOpts, zone
    switch(type){
      case "site":
        [zoneOpts, zone]=this.makeZoneOpts(vals.site)
//         cl(zoneOpts)
//         cl(zone)
        vals=Object.assign({zoneOpts: zoneOpts, zone: zone, zoneName: zoneOpts[zone]}, vals)
//         cl(vals)
        this.setState(vals)
        break
      case "zone":
//         zone=this.state.zoneOpts[vals.zone]
        vals=Object.assign({zoneName: this.state.zoneOpts[vals.zone]}, vals)
//         cl(vals)
//         cl(zone)
//         cl(this.state.zoneOpts)
        this.setState(vals)
        break
      case "zoneName":
        this.setState(vals)
        break
      default:
        break
    }
  }
  
  result=(type)=>{
//     cl(type)
    switch(type){
      case "ZoneName-OK":
        let zoneOpts=Object.assign({}, this.state.zoneOpts)
        zoneOpts[this.state.zone]=this.state.zoneName
        this.zones.forEach(z=>{
          if(z.zoneId==this.state.zone){
            z.zoneName=this.state.zoneName
          }
        })
        this.updateZone()
        this.setState({zoneOpts: zoneOpts})
        break
      default:
        break
    }
  }
  
  showZoneSel=()=>{
//     cl(this.state.zoneOpts)
    if(Object.keys(this.state.zoneOpts).length){
      return(
        <div>
        <div style={{height: 10}}/>
        <UsaSelect00 parms={{
          title: "Select\u00A0Zone",
          inline: true,
          width: 200,
          select: true, // !this.state.editMode,
          valueId: "zone",//valueId, // "widgetSel",
          value: this.state.zone, //value, // this.state.widgetSel,
          opts: this.state.zoneOpts,
          onChange: (vals)=>this.onChange("zone", vals),
        }}/>
        {this.showZoneEdit()}
        </div>
      )
    }else{
      return(
        <div>
        <div style={{height: 10}}/>
        No Zones</div>
      )
    }
  }
  
  getZoneInfo=(zone)=>{
    for(let i=0; i<this.zones.length; i++){
      let z=this.zones[i]
      if(z.zoneId==zone) return z
    }
  }
  
  showZoneEdit=()=>{
//     cl(this.state.zone)
    let z=this.getZoneInfo(this.state.zone)
    cl(z)
    return(
      <div>
      <h3>Edit Zone
        <UsaIcon icon={`ZoneName-OK`} result={this.result} inline/>
        <UsaIcon icon={`ZoneName-Cancel`} result={this.result} inline/>
      </h3>
      Gateway ID: {z.gatewayId}<br/>
      Zone ID: {z.zoneId}<br/>
      <div style={{height: 10}}/>
      
      <UsaSelect00 parms={{
        title: "Zone\u00A0Name",
        inline: true,
        width: 200,
        valueId: "zoneName",//valueId, // "widgetSel",
        value: this.state.zoneName, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("zoneName", vals),
      }}/>
      </div>
    )
  }
  
  showSiteSel=()=>{
    return(
      <div>
      <h3>Manage Zones</h3>
      <UsaSelect00 parms={{
        title: "Select\u00A0Site",
        inline: true,
        width: 200,
        select: true, // !this.state.editMode,
        valueId: "site",//valueId, // "widgetSel",
        value: this.state.site, //value, // this.state.widgetSel,
        opts: this.state.siteOpts,
        onChange: (vals)=>this.onChange("site", vals),
      }}/>
      </div>
    )
  }
  
  render(){
    if(this.state.loaded){
      return(
        <div style={{padding: 10}}>
          {this.showSiteSel()}
          {this.showZoneSel()}
        </div>
      )
      
    }else{
      return <div>loading...</div>
    }
  }
}

export default ManageZones ;
 
 
