import React from 'react';
import UsaIcon from './UsaIcon';
import UsaSelect00 from './UsaSelect00';
import UsaCheckbox00 from './UsaCheckbox00';
import UsaColorPicker00 from './UsaColorPicker00';
import IconButton from '@material-ui/core/IconButton';
import Camera from '@material-ui/icons/Camera';
import {cl, globs, constant, getRandomString} from '../../components/utils/utils';
import {doGetPostBasic} from '../utils/utils'

class Parameters00 extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    var base={}
    if(props.type=="feed"){base=this.getFeedProps()}
    if(props.type=="zone"){this.setZoneFlags(base)}
//     if(inc.includes("GJ"))base.incGJ=
    this.state=Object.assign(base, props.parms.vals)
    this.state.editMode=false
    this.state.backImage=false
    this.state.site=this.props.parms.vals.site
    
    
//     this.state.type="now"
//     this.state.xTicks=false
//     this.state.yTicks=false
    this.typeOpts=[
      {v: "now", t: "Most Recent"},
      {v: "day", t: "Today"},
      {v: "week", t: "This Week"},
      {v: "month", t: "This Month"},
    ]
  }
  
  setZoneFlags=(base)=>{
    let v=this.props.parms.vals
    base.tmpFlag=v.tmpFlag || false
    base.humFlag=v.humFlag || false
    base.litFlag=v.litFlag || false
    base.co2Flag=v.co2Flag || false
    base.ecFlag=v.ecFlag || false
    base.phFlag=v.phFlag || false
    base.almFlag=v.almFlag || false
    
  }
  
  getFeedProps=()=>{// this has to match the list in EditWidget00 / changeFeed
    let inc=this.props.parms.vals.inc
    if(!inc){inc=[]}
    let base={
      incGJ: inc.includes("GJ"),
      incMsg: inc.includes("Msg"),
      incTasks: inc.includes("Tasks"),
      incAlarms: inc.includes("Alarms"),
      incActivity: inc.includes("Activity"),
      incNotification: inc.includes("Notification"),
      incAdmin: inc.includes("Admin"),
      incIDoser: inc.includes("IDoser"),
    }
    return base
  }
  
  saveRestoreParams=(cmd)=>{
    switch(cmd){
      case "save":
        this.back=this.state
        break
      default:
        let b=this.back
        this.setState({title: b.title, bgColor: b.bgColor, dec: b.dec, mult: b.mult, note: b.note, link: b.link})
        break
    }
  }
  
  colorSquare=(col, size)=>{
    return(
      <div style={{width: size, height: size, marginLeft: 10, display: "inline-block", verticalAlign: "middle", backgroundColor: col}}/>
    )
  }
  
  editGenericType=()=>{
    return(
      <div>
      <UsaIcon icon="parameters-OK" result={this.result} inline/>
      <UsaIcon icon="parameters-Cancel" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Parameters:</h3></span>
      <UsaSelect00 parms={{
        title: "Title",
        width: 100,
        value: this.state.title,
        valueId: "title",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Decimal\u00A0Places",
        width: 100,
        value: this.state.dec,
        valueId: "dec",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Multiplier",
        width: 100,
        value: this.state.mult,
        valueId: "mult",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Note",
        width: 100,
        value: this.state.note,
        valueId: "note",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Link",
        width: 100,
        value: this.state.link,
        valueId: "link",
        onChange: o=>this.setState(o),
      }}/>
      </div>
    )
  }
  
  editVisualization=()=>{
    return(
      <div>
      <UsaIcon icon="parameters-OK" result={this.result} inline/>
      <UsaIcon icon="parameters-Cancel" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Parameters:</h3></span>
      <UsaSelect00 parms={{
        title: "Title",
        width: 100,
        value: this.state.title,
        valueId: "title",
        onChange: o=>this.setState(o),
      }}/>
      <UsaColorPicker00 parms={{
        title: "Background\u00A0Color",
        width: 100,
        inline: true,
        value: this.state.bgColor,
        valueId: "bgColor",
        onChange: o=>this.setState(o),
      }}/>
      <div style={{height: 10}}/>
      <UsaSelect00 parms={{
        title: "X-Tick Count",
        width: 100,
        inline: true,
        value: this.state.xTicks,
        valueId: "xTicks",
        onChange: o=>this.setState(o),
      }}/>
      </div>
    )
  }
  
  editTimeSeries=()=>{
    let graphType=this.state.graphType || "line"
    let graphTypeOpts=[
      {v:"line", t:"Line"},
      {v:"bar", t:"Bar"},
      {v:"3dBar", t:"3d Bar"},
    ]
    return(
      <div>
      <UsaIcon icon="parameters-OK" result={this.result} inline/>
      <UsaIcon icon="parameters-Cancel" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Parameters:</h3></span>
      <UsaSelect00 parms={{
        title: "Title",
        width: 100,
        value: this.state.title,
        valueId: "title",
        onChange: o=>this.setState(o),
      }}/>
          <UsaColorPicker00 parms={{
            title: "Background\u00A0Color",
            width: 100,
            inline: true,
            value: this.state.bgColor,
            valueId: "bgColor",
            onChange: o=>this.setState(o),
          }}/>
      <div style={{height:10}}/>
      <UsaSelect00 parms={{
        title: "Type",
        inline: true,
        select: true, // !this.state.editMode,
        valueId: "graphType",//valueId, // "widgetSel",
        value: graphType, //value, // this.state.widgetSel,
        opts: graphTypeOpts,
        onChange: o=>this.setState(o),
      }}/>
      {((graphType=="line")||(graphType=="fillLine"))&&
        <>
        <div style={{height:10}}/>
        <UsaCheckbox00 parms={{
          title: "Show Current Value",
          inline: true,
          value: this.state.showCurrentValue || false,
          valueId: "showCurrentValue",
          onChange: o=>this.setState(o),
        }}/>
        {this.state.showCurrentValue&&
          <table><tbody>
          <tr>
            <td>
              <UsaSelect00 parms={{
                title: "Current\u00A0Value\u00A0Color",
                width: 100,
                inline: true,
                value: this.state.currentValueColor || "#000000",
                valueId: "currentValueColor",
                onChange: o=>this.setState(o),
              }}/>
            </td>
            <td>
              {this.colorSquare(this.state.currentValueColor, 50)}
            </td>
          </tr>
          </tbody></table>
        }
        </>
        
      }
      <UsaCheckbox00 parms={{
        title: "Show Grid Lines",
        inline: true,
        value: this.state.showGridLines || false,
        valueId: "showGridLines",
        onChange: o=>this.setState(o),
      }}/>
      <div style={{height:10}}/>
      <UsaSelect00 parms={{
        title: "Link",
        width: 100,
        value: this.state.link,
        valueId: "link",
        onChange: o=>this.setState(o),
      }}/>
      <div style={{height:10}}/>
      <UsaCheckbox00 parms={{
        title: "Show X-Ticks",
        inline: true,
        value: this.state.xTicks,
        valueId: "xTicks",
        onChange: o=>this.setState(o),
      }}
      />
      </div>
    )
  }
  
  editParameters=()=>{
//     cl(this.state.title)
    if(this.props.parms.wdg){return this.props.parms.wdg.editParameters(this)}
    switch(this.props.type){
      case "generic":
        return this.editGenericType()
        break
      case "timeSeries":
        return this.editTimeSeries()
        break
      case "link":
        return this.editLink()
        break
      case "equipment":
        return this.editEquipment()
        break
      case "site":
        return this.editSite()
        break
      case "zone":
        return this.editZone()
        break
      case "feed":
        return this.editFeed()
        break
      case "visualization":
        return this.editVisualization()
        break
      default:
        break
    }
  }
  
  editFeed=()=>{
    return(
      <div>
      <UsaIcon icon="parameters-OK" result={this.result} inline/>
      <UsaIcon icon="parameters-Cancel" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Parameters:</h3></span>
      <UsaSelect00 parms={{
        title: "Title",
        width: 100,
        value: this.state.title,
        valueId: "title",
        onChange: o=>this.setState(o),
      }}/>
      <UsaColorPicker00 parms={{
        title: "Background\u00A0Color",
        width: 100,
        inline: true,
        value: this.state.bgColor,
        valueId: "bgColor",
        onChange: o=>this.setState(o),
      }}/>
      <div/>
      <h4>Items to include in Feed</h4>
      <UsaCheckbox00 parms={{
        title: "Grow Journal",
        inline: true,
        value: this.state.incGJ,
        valueId: "incGJ",
        onChange: o=>this.setState(o),
      }}
      />
      <UsaCheckbox00 parms={{
        title: "Messaging",
        inline: true,
        value: this.state.incMsg,
        valueId: "incMsg",
        onChange: o=>this.setState(o),
      }}
      />
      <UsaCheckbox00 parms={{
        title: "Tasks",
        inline: true,
        value: this.state.incTasks,
        valueId: "incTasks",
        onChange: o=>this.setState(o),
      }}
      />
      <div style={{height: 10}}/>
      <UsaCheckbox00 parms={{
        title: "Real Time Alarms",
        inline: true,
        value: this.state.incAlarms,
        valueId: "incAlarms",
        onChange: o=>this.setState(o),
      }}/>
      <UsaCheckbox00 parms={{
        title: "Activity Log",
        inline: true,
        value: this.state.incActivity,
        valueId: "incActivity",
        onChange: o=>this.setState(o),
      }}/>
      <UsaCheckbox00 parms={{
        title: "Notification Log",
        inline: true,
        value: this.state.incNotification,
        valueId: "incNotification",
        onChange: o=>this.setState(o),
      }}/>
      <UsaCheckbox00 parms={{
        title: "Admin Log",
        inline: true,
        value: this.state.incAdmin,
        valueId: "incAdmin",
        onChange: o=>this.setState(o),
      }}/>
      <UsaCheckbox00 parms={{
        title: "iDoser Events",
        inline: true,
        value: this.state.incIDoser,
        valueId: "incIDoser",
        onChange: o=>this.setState(o),
      }}/>
      </div>
    )
  }
  
  editLink=()=>{
    return(
      <div>
      <UsaIcon icon="parameters-OK" result={this.result} inline/>
      <UsaIcon icon="parameters-Cancel" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Parameters:</h3></span>
      <UsaSelect00 parms={{
        title: "Title",
        width: 100,
        value: this.state.title,
        valueId: "title",
        onChange: o=>this.setState(o),
      }}/>
      <UsaColorPicker00 parms={{
        title: "Background\u00A0Color",
        width: 100,
        inline: true,
        value: this.state.bgColor,
        valueId: "bgColor",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Link",
        width: 100,
        value: this.state.link,
        valueId: "link",
        onChange: o=>this.setState(o),
      }}/>
      </div>
    )
  }
  
  editEquipment=()=>{
    return(
      <div>
      <UsaIcon icon="parameters-OK" result={this.result} inline/>
      <UsaIcon icon="parameters-Cancel" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Parameters:</h3></span>
      <UsaSelect00 parms={{
        title: "Title",
        width: 100,
        value: this.state.title,
        valueId: "title",
        onChange: o=>this.setState(o),
      }}/>
      <table><tbody>
      <tr>
      <td>
        <UsaSelect00 parms={{
          title: "Off\u00A0Color",
          width: 100,
          inline: true,
          value: this.state.offColor,
          valueId: "offColor",
          onChange: o=>this.setState(o),
        }}/>
      </td>
      <td>
        {this.colorSquare(this.state.offColor, 50)}
      </td>
      </tr>
      </tbody></table>
      <table><tbody>
      <tr>
      <td>
        <UsaSelect00 parms={{
          title: "On\u00A0Color",
          width: 100,
          inline: true,
          value: this.state.onColor,
          valueId: "onColor",
          onChange: o=>this.setState(o),
        }}/>
      </td>
      <td>
        {this.colorSquare(this.state.onColor, 50)}
      </td>
      </tr>
      </tbody></table>
      <UsaSelect00 parms={{
        title: "Link",
        width: 100,
        value: this.state.link,
        valueId: "link",
        onChange: o=>this.setState(o),
      }}/>
      </div>
    )
  }
  
  getSiteOpts=()=>{
    this.siteOpts={}
    this.props.parms.sites.forEach(s=>{
//       cl(s)
      this.siteOpts[s.siteId]=s.name
    })
  }
  
  editSite=()=>{
    if(this.props.parms.sites){
      if(!this.siteOpts){this.getSiteOpts()}
      return(
        <div>
        <UsaIcon icon="parameters-OK" result={this.result} inline/>
        <UsaIcon icon="parameters-Cancel" result={this.result} inline/>
        <span><h3 style={{display: "inline-block"}}>Parameters:</h3></span>
        <UsaSelect00 parms={{
          title: "Title",
          width: 100,
          value: this.state.title,
          valueId: "title",
          onChange: o=>this.setState(o),
        }}/>
        <UsaCheckbox00 parms={{
          title: "Background\u00A0Image",
          inline: true,
          value: this.state.backImage || false,
          valueId: "backImage",
          onChange: o=>this.setState(o),
        }}/>
        <div style={{height: 10}}></div>
        {!this.state.backImage&&
            <UsaColorPicker00 parms={{
              title: "Background\u00A0Color",
              width: 100,
              inline: true,
              value: this.state.bgColor,
              valueId: "bgColor",
              onChange: o=>this.setState(o),
            }}/>
        }
        {this.state.backImage&&
          <>
          <IconButton
            variant="contained"
            component="label"
            title="Upload Image"
          >
            <Camera/>
            <input hidden type="file" onChange={this.markImage}/>
          </IconButton>
          <span>Upload Image</span>
          </>
        }
        <UsaSelect00 parms={{
          title: "Site",
          select: true, // !this.state.editMode,
          valueId: "site",//valueId, // "widgetSel",
          value: this.state.site, //value, // this.state.widgetSel,
          opts: this.siteOpts,
          onChange: o=>this.setState(o),
        }}/>
        
        <UsaSelect00 parms={{
          title: "Link",
          width: 100,
          value: this.state.link,
          valueId: "link",
          onChange: o=>this.setState(o),
        }}/>
        </div>
      )
    }else{
      return <div>loading...</div>
    }
  }
  
  editZone=()=>{
    if(this.props.parms.sites){
//       if(!this.siteOpts){this.getSiteOpts()}
      return(
        <div>
        <UsaIcon icon="parameters-OK" result={this.result} inline/>
        <UsaIcon icon="parameters-Cancel" result={this.result} inline/>
        <span><h3 style={{display: "inline-block"}}>Parameters:</h3></span>
        <UsaSelect00 parms={{
          title: "Title",
          width: 100,
          value: this.state.title,
          valueId: "title",
          onChange: o=>this.setState(o),
        }}/>
        <UsaCheckbox00 parms={{
          title: "Transparent",
          inline: true,
          value: this.state.transparent || false,
          valueId: "transparent",
          onChange: o=>this.setState(o),
        }}/>
        <div/>
        <UsaCheckbox00 parms={{
          title: "Graph Bar",
          inline: true,
          value: this.state.graphBar || false,
          valueId: "graphBar",
          onChange: o=>this.setState(o),
        }}/>
        {!this.state.transparent&&
            <UsaColorPicker00 parms={{
              title: "Background\u00A0Color",
              width: 100,
              inline: true,
              value: this.state.bgColor,
              valueId: "bgColor",
              onChange: o=>this.setState(o),
            }}/>
        }
        <h3>What to Show</h3>
        <UsaCheckbox00 parms={{
          title: "Temperature",
          inline: true,
          value: this.state.tmpFlag || false,
          valueId: "tmpFlag",
          onChange: o=>this.setState(o),
        }}/>
        <UsaCheckbox00 parms={{
          title: "Humidity",
          inline: true,
          value: this.state.humFlag || false,
          valueId: "humFlag",
          onChange: o=>this.setState(o),
        }}/>
        <UsaCheckbox00 parms={{
          title: "CO2",
          inline: true,
          value: this.state.co2Flag || false,
          valueId: "co2Flag",
          onChange: o=>this.setState(o),
        }}/>
        <UsaCheckbox00 parms={{
          title: "Light",
          inline: true,
          value: this.state.litFlag || false,
          valueId: "litFlag",
          onChange: o=>this.setState(o),
        }}/>
        <UsaCheckbox00 parms={{
          title: "EC",
          inline: true,
          value: this.state.ecFlag || false,
          valueId: "ecFlag",
          onChange: o=>this.setState(o),
        }}/>
        <UsaCheckbox00 parms={{
          title: "pH",
          inline: true,
          value: this.state.phFlag || false,
          valueId: "phFlag",
          onChange: o=>this.setState(o),
        }}/>
        <UsaCheckbox00 parms={{
          title: "Alarms",
          inline: true,
          value: this.state.almFlag || false,
          valueId: "almFlag",
          onChange: o=>this.setState(o),
        }}/>
        
        
        <UsaSelect00 parms={{
          title: "Link",
          width: 100,
          value: this.state.link,
          valueId: "link",
          onChange: o=>this.setState(o),
        }}/>
        </div>
      )
    }else{
      return <div>loading...</div>
    }
  }
  
  markImage=async(e)=>{
    let files=e.target.files[0]// first file?
    let imageId= getRandomString(16)
    let ext=files.name.substr(files.name.lastIndexOf('.')+1)
    this.setState({imgName: `${imageId}.${ext}`, images: files})
    cl([imageId, files])
  }
  
  result=(type)=>{
    cl(type)
    switch(type){
//       case "parameters-Cancel":
//         this.saveRestoreParams("restore")
//       case "parameters-OK":
//         this.setState({editMode: false})
//         break
      case "parameters-OK":
        this.props.onChange(this.state)
        this.setState({editMode: false})
        break
      case "parameters-Cancel":
        this.saveRestoreParams("restore")
        this.setState({editMode: false})
        break
      case "parameters-Edit":
        this.saveRestoreParams("save")
        this.setState({editMode: true})
        break
    }
  }
  
  displayParameters=()=>{
    return(
      <div>
      <UsaIcon icon="parameters-Edit" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Parameters:</h3></span>
      {` ${this.state.title}`}
      </div>
    )
  }
  
  
  render(){
    if(this.state.editMode){
      return this.editParameters()
    }else{
      return this.displayParameters()
    }
  }
}

export default Parameters00 ;

