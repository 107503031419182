import React from 'react';
import UsaIcon from './UsaIcon';
import Datapoints from './Datapoints';
import UsaSelect00 from './UsaSelect00';
import UsaCheckbox00 from './UsaCheckbox00';
import {getDatapointName, colorSquare} from '../utils/utils'

// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import TextField from '@material-ui/core/TextField';
import {cl} from '../../components/utils/utils';

class DatapointList00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      datapoints: props.parms.vals,//[{zone: 1, channel: 2, index: 3, pid: 23, color: "#FF0000"}],//{},
      editIndex: -1,
      zone: 0,
      channel: 0,
      index: 0,
      timeOffset: 0,
      verticalOffset: 0,
      dp: 0,
      dpColor: "#FFFFFF",
      dpShowName: props.parms.vals.showName,
      dpFillBelow: props.parms.vals.fillBelow,
      editMode: false,
      editDatapoint: false,
      showName: "none",
      yTicks: 5,
    }
    this.saveRestoreState("save")
//     cl(this.state)
  }
  
  onChange=(type, vals)=>{
//     cl(vals)
    switch(type){
      case "datapoint":
        cl(vals)
        this.setState(vals)
        break
      default:
        break
    }
  }
  
  showDatapoints=()=>{
    return(
      this.state.datapoints.map((dp,i)=>{
//         cl(dp)
        let name=getDatapointName(dp.pid)
        return(
          <div key={i}>
          <UsaIcon icon={`dp${i}-Delete`} result={this.result} inline/>
          <UsaIcon icon={`dp${i}-Edit`} result={this.result} inline/>
          {name}
          {colorSquare(dp.color, 10)}
          </div>
        )
      })
    )
  }
  
  setColorVal=(val)=>{
    this.setState(val)
  }
  
  editOneDatapoint=()=>{
    if(!this.state.editDatapoint)return
//     let dp=this.state.datapoints[0]
    let s=this.state
    let dp={zone: s.zone, channel: s.channel, index: s.index, pid: s.dp, timeOffset: s.timeOffset, verticalOffset: s.verticalOffset,}
    return(
      [
      <Datapoints key="1" parms={{vals: dp, mode: 
        "select", width: this.props.parms.width}} onChange={(vals)=>{this.onChange("datapoint", vals)}}/>,
      <UsaSelect00 key="15" parms={{
        title: "Name",
        width: 100,
        inline: true,
        value: this.state.dpShowName || "",
        valueId: `dpShowName`,
        onChange: o=>this.setState(o),
      }}/>,
      <UsaCheckbox00 key="16" parms={{
        title: "Fill\u00A0Graph\u00A0Below\u00A0Line",
        value: this.state.dpFillBelow || false,
        valueId: "dpFillBelow",
        onChange: o=>this.setState(o),
      }}/>,
      <table key="2"><tbody>
      <tr>
      <td>
        <UsaSelect00 key="2" parms={{
          title: "Color",
          width: 100,
          inline: true,
          value: this.state.dpColor,
          valueId: `dpColor`,
          onChange: this.setColorVal,
        }}/>
      </td>
      <td>
        {colorSquare(this.state.dpColor, 20, 3)}
      </td>
      </tr>
      </tbody></table>,
      <UsaSelect00 key="4" parms={{
        title: "Multiplier",
        width: 100,
        value: this.state.multiplier,
        valueId: "multiplier",
        onChange: o=>this.setState(o),
      }}/>,
      
      <UsaSelect00 key="5" parms={{
        title: "yTicks",
        width: 100,
        inline: false,
        value: this.state.yTicks,
        valueId: `yTicks`,
        onChange: o=>this.setState(o),
      }}/>]
    )
  }
  
  showAddDatapoint=()=>{
//     cl(this.state)
    if(this.state.editDatapoint){
      let name=getDatapointName(this.state.dp)
      if(this.state.editDatapoint=="add"){
        return[
          <UsaIcon key="4" icon={"datapointAdd-OK"} result={this.result} inline/>,
          <UsaIcon key="5" icon={"datapointAdd-Cancel"} result={this.result} inline/>,
          <span key="6"><h3 style={{display: "inline-block"}}>Add Datapoint</h3></span>,
          ` ${name}`
        ]
      }else{
        let name=getDatapointName(this.state.dp)
        return[
          <UsaIcon key="4" icon={"datapointAdd-OK"} result={this.result} inline/>,
          <UsaIcon key="5" icon={"datapointAdd-Cancel"} result={this.result} inline/>,
          <span key="6"><h3 style={{display: "inline-block"}}>Edit</h3></span>,
          ` ${name}`
        ]
      }
    }else{
      return[
        <UsaIcon key="4" icon={"datapointAdd-Create"} result={this.result} inline/>,
        <span key="5" ><h3 style={{display: "inline-block"}}>Add Datapoint</h3></span>
      ]
    }
  }
  
  editDatapointList=()=>{
    return(
      <div key="4">
      <UsaIcon icon={"datapoint-OK"} result={this.result} inline/>
      <UsaIcon icon={"datapoint-Cancel"} result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Datapoints:</h3></span>
      {this.showDatapoints()}
      {this.showAddDatapoint()}
      {this.editOneDatapoint()}
      </div>
    )
  }
  
  addDatapoint=()=>{// really does update and add
//     cl(this.state)
    let dpEdit={
        zone: this.state.zone,
        channel: this.state.channel,
        dpName: this.state.dpName,
        index: this.state.index,
        timeOffset: this.state.timeOffset,
        verticalOffset: this.state.verticalOffset,
        pid: this.state.dp,
        color: this.state.dpColor,
        showName: this.state.dpShowName,
        fillBelow: this.state.dpFillBelow,
        multiplier: this.state.multiplier,
        yTicks: this.state.yTicks,
      }
    cl(dpEdit)
    let dp2=this.state.datapoints.slice(0)
    if(this.state.editDatapoint=="add"){
      dp2.push(dpEdit)
    }else{
      dp2[this.state.editIndex]=dpEdit
    }
    return dp2
  }
  
  saveRestoreState=(cmd)=>{
    switch(cmd){
      case "save":
        this.back=this.state
        break
      default:
        this.setState(this.back)
        break
    }
  }
  
  result=(type)=>{
    cl(type)
    if(type.substr(0,2)=="dp"){
      let parts=type.split("-")
      let id=parts[0].substr(2)
//       cl(id)
//       cl("dp")
      switch(parts[1]){
        case "Edit":
          let d=this.state.datapoints[id]
          cl(d)

          this.setState({editDatapoint: "edit", editIndex: id,
            zone: d.zone, channel: d.channel, index: d.index, timeOffset: d.timeOffset, verticalOffset: d.verticalOffset, dp: d.pid, dpColor: d.color, 
            dpShowName: d.showName, dpFillBelow: d.fillBelow,
            yTicks: d.yTicks, multiplier: d.multiplier,// , dpName: d.dpName
          })
//           cl(parts[1])
          break
        case "Delete":
          let d2=this.state.datapoints.slice(0)
          d2.splice(id,1)
//           let d2=this.state.datapoints.splice(id,1)
          this.setState({datapoints: d2})
          break
        default:
          break
      }
    }
//     cl(type)
    switch(type){
      case "datapointAdd-Create":
        this.setState({editDatapoint: "add", zone: 0, channel: 0, index: 0, dp: 0, dpColor: "#000000", dpShowName: "", dpFillBelow: false})
        break
      case "datapointAdd-OK":
        let dps= this.addDatapoint()
        cl(dps)
        this.setState({editDatapoint: false, datapoints: dps})
        break
      case "datapointAdd-Cancel":
        this.setState({editDatapoint: false})
        break
      case "datapoint-Edit":
        this.setState({editMode: true})
        break
      case "datapoint-Cancel":
        this.back.editMode=false
        this.saveRestoreState("restore")
        break
      case "datapoint-OK":
        this.setState({editMode: false})
        this.props.onChange(this.state.datapoints)
        break
      default:
        break
    }
  }
  
  displayDatapointList=()=>{
//     cl("display")
//     let name=this.state.showName
//     cl(this.state.datapoints[0])
//     if(this.state.datapoints[0]){
      let dp=this.state.datapoints[0]
      if(!dp)return
      let name=getDatapointName(dp.pid)
      return(
        <div>
        <UsaIcon icon={"datapoint-Edit"} result={this.result} inline/>
        <span><h3 style={{display: "inline-block"}}>Datapoints:</h3></span>
        {` ${name}`}
        </div>
      )
//     }
  }
  
  render(){
//     cl("render")
    let dp=Object.assign({}, this.state.datapointList)
//     if(!Object.keys(dp).length){return null}
//     cl(dp)
    if(this.state.editMode){
      return this.editDatapointList()
    }else{
      return this.displayDatapointList()
    }
    return(
      <div>
      <Datapoints parms={{vals: dp, mode: "select"}} onChange={(vals)=>{this.onChange("datapoint", vals)}}/>
      
      dp list</div>
    )
  }
}

export default DatapointList00 ;

