import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MainBar from '../components/MainBar';
import history from "../history"
import {getFuiPages, saveFuiPage, deleteFuiPage} from '../components/utils/httpauth';
import {makeFuiPage} from './utilsFui';
import {checkLoggedIn} from '../usa/utils/utils'
import {cl, userSpecificHome} from '../components/utils/utils';
import {globs} from '../components/utils/utils';
import {getHomeDashboard} from '../usa/utils/utils'

class SelectFui extends React.Component{
  constructor(props) {
    super(props);
//     cl(globs)
//     cl(props)
    // let selPage = "_sel";
    // var selPage;
    // if ((globs.backMsg !== undefined)  && (globs.backMsg.type === "fui")){
    //   selPage = globs.backMsg.pageType;
    // }
    this.state = {
      loaded: false,
      loadMsg: "loading",
      fuiPages: [],
      selPage: "_sel",
      selectedType: "",
      type: "", // this.textPlaceholders["type"],
      name: "",
      description: "",
      message: "",
    };
    this.loadData()
    globs.username="none";
    globs.siteName="none";
  }
  
  loadData=async()=>{
    await checkLoggedIn()
    getFuiPages().then(r=>{// let gfp =
      // cl(r);
      // let arr = [];
      // r.forEach(p=>{
      //   arr[p.type] = p;
      // });
      // cl(arr);
      // r = [];
      // r["type"] = {type: "type", name: "name",
      // description: "description",
      // title: "",
      // controls: [],
    // };
//      r = [{type: "type", name: "name", "desc": "descripiton"}];
// cl(r);
      this.setState({
        loaded: true,
        fuiPages: r,
      });
      if(this.props.match.params.pageType != undefined){
        this.setSelValues(this.props.match.params.pageType, r);
      }else{
        if ((globs.backMsg !== undefined) && (globs.backMsg.type === "fui")){
          this.setSelValues(globs.backMsg.pageType, r);
        }
      }

    });
  }



  // textPlaceholders = {
  //   type: "",
  // };

  initFuiPageList=()=>{
    var arr = [];
    if (this.state.selPage === "_sel"){
      arr.push({text: "Select a Page", val: "_sel"});
    }
    if (this.state.selPage === "_new"){
      arr.push({text: "Create a New Page", val: "_new"});
    }
    Object.keys(this.state.fuiPages).forEach((p, i)=>{
      // cl(p);
      let vals = this.state.fuiPages[p];
      arr.push({text: vals.name, val: vals.type});
    });
    return arr.map((e, i)=>{
      // cl(e);
      return(
          <MenuItem key={i} value={e.val}>{e.text}</MenuItem>
      );
    });
  }

  setSelValues=(sel, pages)=>{
    // cl(globs)
    // cl(sel)
    if (pages[sel] === undefined){
      makeFuiPage(sel);
    }
    this.setState({
      selPage: sel,
      selectedType: sel, // pages[sel].type,
      name: pages[sel].name,
      type: pages[sel].type,
      description: pages[sel].description,
      message: "",
    })
  }

  onFuiPageChange=(e)=>{
    // cl(e.target.value)
    let sel = e.target.value;
    if (sel !== "_sel"){
      this.setSelValues(sel, this.state.fuiPages)
    }
  }

  showFuiSelect=()=>{
    return(
      <div style={{margin: 10}}>
        <FormControl className={"formControl"}>
          <InputLabel htmlFor="unknown">Page</InputLabel>
          <Select
            value={this.state.selPage}
            onChange={this.onFuiPageChange}
            inputProps={{
              name: 'fui_page_select',
            }}
            >
            {this.initFuiPageList()}
          </Select>
        </FormControl>
      </div>

    );
  }

  showOneText=(lab, id, place, val, multi)=>{
    return(
      <div style={{margin: 10}}>
        <TextField
          label={lab}
          id={id}
          value={val}
          multiline={multi}
          onChange={this.onChange}
          placeholder={place}
        />
      </div>
    );
  }

  onChange=(e)=>{
    // cl(e.target.id)
    this.setSaveMessage();
    switch(e.target.id){
      case "name":
        this.setState({name: e.target.value});
        break;
      case "type":
        this.setState({type: e.target.value});
        break;
      case "description":
        this.setState({description: e.target.value});
        break;
      default:
        break;
    }
  }

  setSaveMessage=()=>{
    this.setState({message: "Press Save or Changes will be Lost!"})
  }

  onCreate=()=>{
    // cl("do create");
    this.setState({
      name: "",
      type: "",
      description: "",
      selPage: "_new",
      message: "",
    });
  }

  saveNewPage=()=>{
    cl("new page")// add a new page to the list
    cl(this.state)
    let pages = Object.assign({}, this.state.fuiPages) ;
    pages[this.state.type] = {
      name: this.state.name,
      type: this.state.type,
      description: this.state.description,
      title: "",
      controls: [],
    }
    this.setState({
      fuiPages: pages,
      selPage: this.state.type,
    });
    saveFuiPage(this.state.type, pages[this.state.type]);
    // cl(pages);
  }

  saveExistPage=()=>{
    let pages = Object.assign({}, this.state.fuiPages) ;
    let orig = pages[this.state.selectedType];
    delete pages[this.state.selectedType];
    pages[this.state.type] = {
      name: this.state.name,
      type: this.state.type,
      description: this.state.description,
      title: orig.title,
      controls: orig.controls,
    }
    this.setState({
      fuiPages: pages,
      selPage: this.state.type,
    });
    saveFuiPage(this.state.selectedType, pages[this.state.type]);
  }

  onSave=()=>{
    if (this.state.type === ""){
      this.setState({message: "Type must be set to Save."})
    }else{
      if (this.state.selPage === "_new"){
        this.saveNewPage();
      }else{
        this.saveExistPage();
      }
      this.setState({message: ""})
    }
  }

  onDelete=()=>{
    let pages = Object.assign({}, this.state.fuiPages) ;
    delete pages[this.state.selectedType];
    cl(Object.keys(pages))
    let sel = Object.keys(pages)[0];
    this.setState({
      fuiPages: pages,
      // selPage: sel,
      // selectedType: sel
    });
    this.setSelValues(sel, pages);
    deleteFuiPage(this.state.selectedType);
  }

  onCancel=()=>{
    // cl(this.state.selPage)
    var name, type, description;
    if(this.state.selPage === "_new"){
      name = "";
      type = "";
      description = "";
    }else{
      let page = this.state.fuiPages[this.state.selPage];
      name = page["name"];
      type = page["type"];
      description = page["description"];
    }
    this.setState({name: name, type: type, description: description})

  }

  onEdit=()=>{
    // cl(this.state.type)
    globs.fuiPages = this.state.fuiPages;
    history.push("/fui/edit/" + this.state.type);
  }

  onTest=()=>{
    // cl(this.state.type)
//     cl(this.props.match.params)
    let zuci = this.props.match.params.zuci ;
    if (zuci === undefined) zuci = "0-0-0-0";
    let url = "/fui/live/" + this.state.type + "/" + zuci;
    history.push(url);

    // cl(url);
  }

  onClick=(cmd)=>{
    let cmds = {
      "Create": this.onCreate,
      "Delete": this.onDelete,
      "Edit": this.onEdit,
      "Save": this.onSave,
      "Cancel": this.onCancel,
      "Test": this.onTest,
    };
    cmds[cmd]();
    // cl(cmd);
  }

  showButton=(name)=>{
    return(
      <Button onClick={
        ()=>this.onClick(name)}
      style={{margin: 5}}
      variant="contained" color="primary" className={"button"}>
        {name}
      </Button>
    );
  }

  barClick = async (e)=>{
// edit: http://localhost:3000/fui/zone_Fallback
// test: http://localhost:3000/fui/live/zone_Fallback/0-0-0-0
    cl(e.currentTarget.id);
    cl(this.props)
    let pageType = this.props.match.params.pageType ; // "zone_Fallback";
    switch (e.currentTarget.id){
      case "home":
//         userSpecificHome()
        let dash=await getHomeDashboard()
        if(dash){history.push(`/usa/dash/${dash}`)}
        
//         history.push("/usa/dashboard")
//         history.push("/sa")
        break;
      case "edit":
        history.push("/fui/live/" + pageType + "/0-0-0-0")
        break;
      default:
        break;
    }
  }

  render(){
    // showOneText=(lab, id, val, change)=>{
    // cl(this.state)
    if(this.state.loaded){
      document.title="Fui Page Selector";
      return(
        <div>
        <MainBar home edit editColor={"secondary"}
        menu click={this.barClick} title="Fui Page Selector" />
        {this.showFuiSelect()}
        {this.showOneText("Name", "name", "Enter page name",
          this.state.name, false)}
        {this.showOneText("Type", "type", "Enter page type",
          this.state.type, false)}
        {this.showOneText("Description", "description", "Enter page description",
          this.state.description, true)}
          <div style={{margin: 5}}>{this.state.message}</div>
          <table><tbody>
          <tr>
            <td>{this.showButton("Create")}</td>
            <td>{this.showButton("Delete")}</td>
            <td>{this.showButton("Edit")}</td>
          </tr>
          <tr>
            <td>{this.showButton("Save")}</td>
            <td>{this.showButton("Cancel")}</td>
            <td>{this.showButton("Test")}</td>
          </tr>
          </tbody></table>
        </div>
      );
    }else{
      return(
        <div>
        <MainBar home
        menu click={this.barClick} title="FUI Page Selector" />
        {this.state.loadMsg}</div>
      );
    }
  }

}

export default SelectFui ;
