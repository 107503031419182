import React from 'react';

class C18Button00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
    }
  }
  
  render(){
    return React.createElement("button",this.props)
  }
}
      
export default C18Button00;
