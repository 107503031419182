import React from 'react';
import UsaSelect00 from '../UsaSelect00'
import {cl} from '../../../components/utils/utils';

const verticalSpace=<div style={{height: 10}}/>

class RepeatMinutes extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      minutes: 1,
      from: 0,
      to: 10,
    }
    this.repeat=this.props.parms.repeat
  }
  
  onChange=(vals)=>{
//     cl(this.props)
    let s=Object.assign(this.state, vals)
    this.repeat={
      period: s.minutes,
      details: s.from | (s.to << 8)
    }
//     this.repeat.period=vals.minutes
//     this.repeat.details=this.state.from | (this.state.to << 8)
    this.setState(vals)
    this.props.parms.onChange({repeat: Object.assign({},this.repeat)})
  }
  
  render(){
    let hourOpts={
      0:{t:"12 am"},
      1:{t:"1 am"},
      2:{t:"2 am"},
      3:{t:"3 am"},
      4:{t:"4 am"},
      5:{t:"5 am"},
      6:{t:"6 am"},
      7:{t:"7 am"},
      8:{t:"8 am"},
      9:{t:"9 am"},
      10:{t:"10 am"},
      11:{t:"11 am"},
      12:{t:"12 pm"},
      13:{t:"1 pm"},
      14:{t:"2 pm"},
      15:{t:"3 pm"},
      16:{t:"4 pm"},
      17:{t:"5 pm"},
      18:{t:"6 pm"},
      19:{t:"7 pm"},
      20:{t:"8 pm"},
      21:{t:"9 pm"},
      22:{t:"10 pm"},
      23:{t:"11 pm"},
      24:{t:"12 am"},
    }
    return(
      <>
      <UsaSelect00 parms={{
        title: "Repeat\u00A0Every",
        footer: "Minutes",
        footerPlural: true,
        type: "number",
        min: 1,
        width: 50,
        select: false, // !this.state.editMode,
        valueId: "minutes",//valueId, // "widgetSel",
        value: this.state.minutes, //value, // this.state.widgetSel,
        onChange: this.onChange,
      }}/>
      {verticalSpace}
      <UsaSelect00 parms={{
        title: "Between",
        select: true, // !this.state.editMode,
        opts: hourOpts,
        valueId: "from",//valueId, // "widgetSel",
        value: this.state.from, //value, // this.state.widgetSel,
        onChange: this.onChange,
      }}/>
      {verticalSpace}
      <UsaSelect00 parms={{
        title: "And",
        select: true, // !this.state.editMode,
        opts: hourOpts,
        valueId: "to",//valueId, // "widgetSel",
        value: this.state.to, //value, // this.state.widgetSel,
        onChange: this.onChange,
      }}/>
      </>
    )
  }
}

export default RepeatMinutes ;

