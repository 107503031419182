import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Datapoints from './Datapoints'
import DataVisualizationEdit00 from './DataVisualizationEdit00'
import DataVisualizationEditScreen00 from './DataVisualizationEditScreen00'
import VisualizationTimeSeries00 from '../../visualization/components/VisualizationTimeSeries00'
import BarChart from '@material-ui/icons/BarChart';
import {cl, globs, constant, getRandomString, dateToYrMoDa, dateToLocalHrMinSecString} from '../../components/utils/utils';
import {wsTrans,doGetPostBasic, checkLoggedIn, getDatapointName} from '../utils/utils'
import history from "../../history"

class DataVisualization00 extends React.Component{
  constructor(props) {// 
    super(props);
    let w1={f:"#FF00FF"}
    let w2={f:"#8000FF"}
    let w4={f:"#8080FF"}
    let w3={o: "h", p: 50, c:[w1, w4]}
    this.downloadDiv=React.createRef();
    this.state={
//       parent: {c: [{o: "v", p: 50, c: [w2, w3]}]},
//       test1p: {x:0,y:0,w:1000,h:800},
      contextOpen: false,
      menuAnchor: null,
      editMode: false,
      editScreen: false,
      showScreenEdit: false,
      dlMessage: "",
//       screen:{
//         title: "First Visualization Screen",
//         color: "#000080",
//         bgColor: "#80C0FF",
//         width: 1000,
//         height: 600,
//         flags: constant.VISUALIZATION_SCREEN_FAVORITE,
//         paneTree: {c: [{o: "v", p: 50, c: [w2, w3]}]}
// //         favorite: true,
//       },
      loaded: false,
    }
      this.cmX=0
      this.cmY=0
      this.paneGraph={
        title: "Blank Graph Title",
        bgColor: "#DDDDDD",
        xTicks: 3,
        flags: 0,
        duration: 5,
        period: 3600,
        beginTime: 1610028120,
        endTime: 1610038120,
//         datapointList:[
        dpList:[
          {zone: 0, channel: 0, index: 0, pid: 23, color: "#FF0000", yTicks: 0},
        ],
      }
//       w2.f=this.paneGraph
      this.loadData()
}

//   componentDidMount=()=>{cl("componentDidMount")}
  componentWillUnmount=()=>{
    globs.menuBar.removeOneMenu("barChart")
  }
  
  componentDidUpdate=()=>{
    if((this.props.screenSel)&&(this.props.screenSel != this.state.parent.screenId)){
      let s=this.state.screens
      let sel=this.props.screenSel
      cl("did update")
      this.setState({parent: s[sel], selScreen: sel})
    }
  }

  
  cm=(paneParent,k,e)=>{
//     cl("context menu")
    if(!e)return
    this.cmX=e.clientX
    this.cmY=e.clientY
    e.preventDefault()
    e.stopPropagation()
    this.saveWinContext={w:paneParent, k: k, x: e.clientX, y: e.clientY}
    this.setState({contextOpen: true, menuAnchor: e.currentTarget})
  }
  
  
/* this is the hard part!
need to create 2 new windows, with color f functions
and update this window to point at them
this.cmX, cmY is the point where the ContextMenu was opened
showContextMenu sets contextOpen and menuAnchor to open the menu.
it sets contextX, contextY, contextTarget in state - not needeed
the menu itself uses this.cmX, Y for position
*/

  randomColor=()=>{
    let rnd=()=>Math.floor(192+64*Math.random())
    let col=(rnd() * 256 + rnd()) * 256 + rnd()
    return `#${col.toString(16)}`
  }
  
  splitH=()=>{
    let wc=this.saveWinContext
    let w3=wc.w.c[wc.k]
    let w1={f:w3.f}
    let w2={f:this.randomColor()}
    w3.o="h"
    w3.p=50
    w3.c=[w1,w2]
    delete w3.f
  }
  
  splitV=()=>{
    let wc=this.saveWinContext
    let w3=wc.w.c[wc.k]
    let w1={f:w3.f}
    let w2={f:this.randomColor()}
    w3.o="v"
    w3.p=50
    w3.c=[w1,w2]
    delete w3.f
  }
  
  deletePane=()=>{
    cl(this.saveWinContext)
    let wc=this.saveWinContext
    let w1=wc.w.c[(wc.k)?0:1]// the pane to keep
    if(w1.f){
      wc.w.f=w1.f
    }else{
      wc.w.o=w1.o
      wc.w.p=w1.p
      wc.w.c=w1.c
    }
  }
  
  insertVisualization=()=>{
//     cl(this.saveWinContext)
    this.setState({editMode: true, editScreen: true})
  }
  
  makeDownloadName=()=>{
    let da=new Date()
    let date=dateToYrMoDa(da)
    let time=dateToLocalHrMinSecString(da)
    return `Link4_Download_${date}_${time}.csv`
  }
  
  doDownload=()=>{
    cl(this.state.downloadName)
    cl(this.state.download)
    this.downloadDiv.current.click()
  }
  
  downloadData=()=>{
    let wc=this.saveWinContext
    let f=wc.w.c[wc.k].f
    let text=""
    let dp=f.dpList[0]
      let dpName=getDatapointName(dp.pid)
      cl(dpName)
      text+=`Time,${dpName}\n`
      dp.data.forEach(l=>{
        let da=new Date(l.t*1000)
        let date=dateToYrMoDa(da)
        let time=dateToLocalHrMinSecString(da)
        text+=`${date} ${time},${l.d}\n`
      })
    var data = new Blob([text], {type: 'text/plain'});
    let url=window.URL.createObjectURL(data);
    let name=this.makeDownloadName()
    this.setState({downloadName: name, download: url})
    setTimeout(this.doDownload, 0)
  }
  
  editVisualization=()=>{
    let wc=this.saveWinContext
    let f=wc.w.c[wc.k].f
    this.paneGraph=f
//     cl(f)
//     cl(this.saveWinContext)
    this.setState({editMode: true, editScreen: true})
  }
  
  editScreen=()=>{
    this.setState({editScreen: true, showEditScreen: true})
  }
  
  saveScreenCmd=(screenD)=>{
//     let screen2=Object.assign(this.state.parent, vals)
    this.addBarChartMenu(this.state.screens)
//     cl(this.state.screens)
    this.saveScreen(screenD)// writes to db
    this.setState({parent: screenD, editScreen: false, showEditScreen: false})
  }

  cancelScreenEdit=()=>{
    this.reLoadScreen(this.state.selScreen)
//     this.setState({editScreen: false, showEditScreen: false})
  }

  closeContextMenu=(e)=>{
// called after the menu selection    
    switch(e.target.id){
      case "splitH":
        this.splitH()
        break
      case "splitV":
        this.splitV()
        break
      case "deletePane":
        this.deletePane()
        break
      case "insertVisualization":
        this.insertVisualization()
        break
      case "downloadData":
        this.downloadData()
        break
      case "editVisualization":
        this.editVisualization()
        break
      case "editScreen":
        this.editScreen()
        break
      case "saveScreen":
//         this.saveScreenCmd()// writes to db, and leaves edit mode
        this.saveScreenCmd(this.state.parent)// writes to db
//         this.setState({editScreen: false, showEditScreen: false})
        break
      case "cancelScreenEdit":
        this.cancelScreenEdit()
        break
    }
    this.setState({contextOpen: false, menuAnchor: null})// closes menu
  }
  
  showMenu=()=>{// the menu itself
    let isGraph=false
    if(this.saveWinContext){
      let wc=this.saveWinContext
      let f=wc.w.c[wc.k].f
      isGraph=((typeof f)=="object")
//       cl(isGraph)
//       cl(this.saveWinContext)
    }
    
    return(
      <Menu style={{top: 0}}
        anchorReference="anchorPosition"
        anchorPosition={{ top: this.cmY, left: this.cmX }}
        open={this.state.contextOpen}
        onClose={this.closeContextMenu}
        anchorEl={this.state.menuAnchor}
        onClick={this.closeContextMenu}
      >
        <MenuItem key="5" id="editScreen">Edit Screen</MenuItem>
        {this.state.editScreen&&[
          <MenuItem key="6" id="saveScreen">Save Screen</MenuItem>,
          <MenuItem key="7" id="cancelScreenEdit">Cancel Edit</MenuItem>
        ]
        }
        {(isGraph)&&
          <MenuItem key="4" id="editVisualization">Edit Visualization</MenuItem>
        }
        {(isGraph)&&
          <MenuItem key="8" id="downloadData">Download Data</MenuItem>
        }
        {(!isGraph)&&
          <MenuItem key="4" id="insertVisualization">Insert Visualization</MenuItem>
        }
        {this.state.editScreen&&[
          <MenuItem key="1" id="splitH">Split Horizontally</MenuItem>,
          <MenuItem key="2" id="splitV">Split Vertically</MenuItem>,
          <MenuItem key="3" id="deletePane">Delete Pane</MenuItem>,
        ]
        }
      </Menu>
    )
  }
  
  md=(e, w)=>{// this is called by the divider for mouseDown
    if(w&&this.state.editScreen){
      this.selWin=w
      this.selPos0=w.p
      this.setState({selPos: w.p})// this will be updated to force the render
      this.dragStart={x:e.clientX, y:e.clientY}
    }
  }
  
  mouseMove=(e)=>{// need to convert pos to *percentage*
/*this uses selWin, dragStart, selPos0*/
    if(this.dragStart){// if we've clicked on a divider
      if(this.selWin.o=="v"){
        let dx=100*(e.clientX-this.dragStart.x)/this.selWin.pos.w// now pos is a percentage
        let p2=this.selPos0+dx
        this.selWin.p=p2// this is where the position is adjusted in the window object
        this.setState({selPos: p2})
      }else{
        let dy=100*(e.clientY-this.dragStart.y)/this.selWin.pos.h
        let p2=this.selPos0+dy
        this.selWin.p=p2
        this.setState({selPos: p2})
      }
    }
  }
  
  mouseUp=(e)=>{
    this.dragStart=null
    this.setState({selWin: null})
  }
  
//   winContext=(pane,key,e)=>{
//     cl("winContext")
//     this.saveWinContext={w:pane, k: key, x: e.clientX, y: e.clientY}
//   }
    
  myDiv=(p,w,k)=>{// this is for the divider lines
    if(this.state.editScreen){
      return(
        <div key={k} style={{position: "absolute", left: p.x, top: p.y, width: p.w, height: p.h, 
          backgroundColor: "#FFFFFF", borderWidth: 1, borderStyle: "solid", borderColor: "#000000",
          cursor: (w.o=="v")?"ew-resize":"ns-resize",}} onClick={this.onClick}
          onMouseDown={e=>this.md(e,w)}
        />
      )
    }
  }
  
  notify=(note)=>{
    cl(note)
  }
  
//   md=(parms)=>{// called for both right and left buttons
// //     cl(parms)
//   }
  
//   cm=(e)=>{
//     cl(e)
//   }
    
  renderWindow2=(pos,paneParent,k)=>{// position, window spec, key
    let hw=2
    let bw=1
    let pane=paneParent.c[k]
    pane.pos=Object.assign({},pos)
//     cl(pane)
    if(pane.f){// has fill function
      if(typeof pane.f == "object"){
        let paneCopy=pane.f// when is this deep copy necessary?
//         let paneCopy=Object.assign({}, pane.f)// have to create a deeper copy of dpList
//         paneCopy.dpList=[]
//         pane.f.dpList.forEach(dp=>{paneCopy.dpList.push(Object.assign({},dp))})
        return(
          <VisualizationTimeSeries00 key={k} parms={{
            pos: pane.pos,
            graph: paneCopy,//pane.f,
            md: this.md,
            cm: e=>this.cm(paneParent,k,e),
          }}
          notify={this.notify}
          />
        )
        
      }else{
        let dm=5
        return(
          <div key={k} style={{position: "absolute", left: pos.x+dm, top: pos.y+dm, width: pos.w-2*dm, height: pos.h-2*dm, 
            borderRadius: 10, boxShadow: "5px 5px 10px #808080",
            backgroundColor: pane.f}} onContextMenu={e=>{this.cm(paneParent,k,e)}}
          />
        )
      }
    }else{
      if(pane.o=="v"){
        let x=pos.x
        let wid=pos.w
        let position=wid*(pane.p/100)// position in pixels
        pos.w=position-hw
        let r1=this.renderWindow2(pos,pane,0)
        pos.x+=position+hw
        pos.w=wid-pos.w-2*hw
        let r2=this.renderWindow2(pos,pane,1)
        let pos2={x:pos.x-2*hw, y:pos.y, w:2*hw-2*bw, h:pos.h-2*bw}
        let r3=this.myDiv(pos2,pane,3)
        pos.x=x
        pos.w=wid
        return [r1,r2,r3]
      }else{
        let y=pos.y
        let hgt=pos.h
        let position=hgt*(pane.p/100)
        pos.h=position-hw
        let r1=this.renderWindow2(pos,pane,0)
        pos.y+=position+hw
        pos.h=hgt-pos.h-2*hw
        let r2=this.renderWindow2(pos,pane,1)
        let pos2={x:pos.x, y:pos.y-2*hw, w:pos.w-2*bw, h:2*hw-2*bw}
        let r3=this.myDiv(pos2,pane,3)
        pos.y=y
        pos.h=hgt
        return [r1,r2,r3]
      }
    }
  }
  
//   renderWindow2=(p,w,k)=>{
//     let hw=2
//     let bw=1
//     w.pos=Object.assign({},p)
//     if(w.f){// has fill function
//       return(
//         <div key={k} style={{position: "absolute", left: p.x, top: p.y, width: p.w, height: p.h, backgroundColor: w.f}
//         }
//           onContextMenu={e=>{this.winContext(p,w,e)}}
//         />
//       )
//     }else{
//       if(w.o=="v"){
//         let x=p.x
//         let wid=p.w
//         let pos=p.w*(w.p/100)
//         p.w=w.p-hw
//         let r1=this.renderWindow2(p,w.c[0],1)
//         p.x+=w.p+hw
//         p.w=wid-p.w-2*hw
//         let r2=this.renderWindow2(p,w.c[1],2)
//         let p2={x:p.x-2*hw, y:p.y, w:2*hw-2*bw, h:p.h-2*bw}
//         let r3=this.myDiv(p2,w,3)
//         p.x=x
//         p.w=wid
//         return [r1,r2,r3]
//       }else{
//         let y=p.y
//         let hgt=p.h
//         p.h=w.p-hw
//         let r1=this.renderWindow2(p,w.c[0],1)
//         p.y+=w.p+hw
//         p.h=hgt-p.h-2*hw
//         let r2=this.renderWindow2(p,w.c[1],2)
//         let p2={x:p.x, y:p.y-2*hw, w:p.w-2*bw, h:2*hw-2*bw}
//         let r3=this.myDiv(p2,w,3)
// //         let r3=<MyDivider key={3} parms={{p: p2, w: w, k:3}} md={this.md}/>
//         p.y=y
//         p.h=hgt
//         return [r1,r2,r3]
//       }
//     }
//   }
//   
/*
define a window by parent x, y, w, h, orientation (h, v) and position
each child has an orientation and position of its own, defining *its* children *or* it has a fill function, which receives x,y,w,h,c
window objects: either orientation and position, *or* a fill function
so, to render a window object, either render its fill function, or its two children
render function called with parms (x,y,w,h), and window object
*/
  
  showWindows=()=>{
//     return
//     let p=this.state.parent
//     cl(this.state.selScreen)
//     cl(this.state.screens[this.state.selScreen])
    let screen0=this.state.parent//this.state.screens[this.state.selScreen]
    let pos={x: 0, y: 0, w: screen0.width, h: screen0.height}
//     cl(screen0)
    return(
      <div style={{position: "relative", width: +screen0.width, height: +screen0.height}}>
      {this.renderWindow2(pos, screen0.paneTree, 0)}
      </div>
    )
  }
  
  loadScreen=(selScreen)=>{// when the menu item is clicked
    if(selScreen=="newVisualizationScreen"){
//       cl(this.state.screens)
      let parent=this.makeBlankScreen()
      let addScreen={}
      let selScreen=parent.screenId
      addScreen[selScreen]=parent
      let screens=Object.assign(this.state.screens, addScreen)
//       cl(screens)
//       this.addBarChartMenu(screens)
//       cl(parent)
//       cl(selScreen)
      this.setState({parent: parent, screens: screens, selScreen: selScreen, editScreen: true})
      return
    }
    history.push(`/usa/dataVisualization/${selScreen}`)
    let parent=this.state.screens[selScreen]
    this.setState({parent: parent, selScreen: selScreen})
//     cl(selScreen)
  }
  
  addBarChartMenu=(screens)=>{
//     globs.menuBar.removeOneMenu("barChart")
    let menuEntries=[{text: "New Visualization Screen", val: "newVisualizationScreen"}]
    for (let k in screens){
//       cl(k)
      let s=screens[k]
      menuEntries.push({
        text: s.title,
        val: s.screenId,
      })
    }
//     cl(menuEntries)
    globs.menuBar.addOneMenu({
      title: "Data Visualizations",
      id: "barChart",
      func: this.loadScreen,// this is the callback, with the menu val
      icon: BarChart,
      menuEntries:menuEntries,
    })
  }
  
  reLoadScreen=async(screenId)=>{
//     cl(screenId)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/screens", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {
        screenId: screenId}})
//     cl(r.data)
    let vals={}
    let parent=r.data[0]
    vals[screenId]=parent
    
    let screens2=Object.assign(this.state.screens, vals)
//     cl(screens2)
    this.setState({parent: parent, screens: screens2, editScreen: false, showEditScreen: false})
  }
  
  makeBlankScreen=()=>{
    let w1={f:this.randomColor()}
    let parent={
        title: "First Visualization Screen",
        screenId: getRandomString(16),
        color: "#000080",
        bgColor: "#80C0FF",
        width: 1000,
        height: 600,
        flags: constant.VISUALIZATION_SCREEN_FAVORITE,
        paneTree: {c: [w1]}
//         paneTree: {c: [{o: "v", p: 50, c: [w2, w3]}]}
    }
//     cl(`make blank: ${parent.screenId}`)
    return parent
  }
  
  loadData=async()=>{// I really don't know why the session info is already loaded!
//     let screenDesc={accountId: globs.userData.session.accountId}
//     let getScreens={cmd: "cRest", uri: "/s/screens", method: "retrieve", sessionId: globs.userData.session.sessionId,
//       body: screenDesc};
    let logged=await checkLoggedIn()
    if(!logged){history.push("/usa/login"); return}
    let r2=await wsTrans("usa", {cmd: "cRest", uri: "/s/screens", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {
        accountId: globs.userData.session.accountId}})
    
    
    let screens=r2.data
//     cl(screens)
    let screensObj={}
    screens.forEach(s=>{
      screensObj[s.screenId]=s
    })
    let selScreen=(this.props.screenSel) ? this.props.screenSel : Object.keys(screensObj)[0]
    if(!screensObj[selScreen]){// if this screen not available
      selScreen=Object.keys(screensObj)[0];
      if(!selScreen){// if *no* screen available
        let s=this.makeBlankScreen()
//         cl(s)
        selScreen=s.screenId
        screensObj[selScreen]=s
      }
//       cl(selScreen)
    }
//     cl(screensObj)
    this.addBarChartMenu(screensObj)
    let parent=screensObj[selScreen]
//     cl(parent)
//     cl(screensObj)
    this.setState({parent: parent, screens: screensObj, selScreen: selScreen, loaded: true})
//     cl("loaded")
  }
  
  loadScreens=async()=>{
    cl(globs.userData.session)
  }
  
  saveScreen=async(screenData)=>{// save all of the current screen to the database
//     cl(screenData)
//     let saveScreen=Object.assign(this.state.parent, screenData)
// //     cl(saveScreen)
//     let saveScreenPack=;
// //     cl(saveScreen)
    let r2=await wsTrans("usa", {cmd: "cRest", uri: "/s/screens", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: Object.assign(this.state.parent, screenData)}
      
    )
  }
  
  deleteLocalScreen=(id)=>{
//     cl(id)
    let screens=Object.assign({},this.state.screens)
    delete screens[id]
    this.addBarChartMenu(screens)
    let selScreen=Object.keys(screens)[0]
    var parent
    if(selScreen){
      cl(selScreen)
      parent=screens[selScreen]
    }else{
      parent=this.makeBlankScreen()
//       cl(parent)
      selScreen=parent.screenId
      screens[selScreen]=parent
    }
    history.push(`/usa/dataVisualization/${selScreen}`)
//     cl(screens)
//     cl(parent)
    this.setState({editScreen: false, showEditScreen: false, parent: parent, screens: screens, selScreen: selScreen})
  }
  
  deleteScreen=()=>{// needs to handle this is the last screen
//     cl(this.state)
    let selScreen=this.state.selScreen
    this.deleteLocalScreen(selScreen)
    wsTrans("usa", {
      cmd: "cRest", 
      uri: "/s/screens", 
      method: "delete", 
      sessionId: globs.userData.session.sessionId,
      body: {
        screenId: selScreen,
      }
    })
}
  
  onChange=(type, vals)=>{
//     cl(type)
    switch(type){
      case "screen-ok":// from edit screen dialog
//         cl(vals)
        let screen2=Object.assign(this.state.parent, vals)// shouldn't this also save to state.screens?
        this.saveScreenCmd(screen2)// writes to db
//         this.setState({parent: screen2, editScreen: false, showEditScreen: false})
        break;
      case "screen-cancel":
        this.reLoadScreen(this.state.selScreen)
//         this.setState({editScreen: false, showEditScreen: false})
        break
      case "screen-delete":
        this.deleteScreen()
        break
      case "paneEdit":// stay in edit mode, but hide the popups, to edit the panels
        this.setState({showEditScreen: false})
        break
      case "visualization":// have described one graph
// saved in winContext: w is the window, k is the index (0,1), w.c is the array of the two windows
//         cl(vals)
        if(Object.keys(vals).length){// if OK out
          vals.dpList=vals.dpList.slice(0)
//           cl(vals)
          if(vals.dataVersion!=undefined){
            vals.dataVersion++
          }else{
            vals.dataVersion=1
          }
          let wc=this.saveWinContext
          let fp=wc.w.c[wc.k]
          if(typeof fp.f != "object"){fp.f={}}
          let f=fp.f
          for(let k in vals){f[k]=vals[k]}
          let parent=this.state.parent
          this.setState({parent: parent, editMode: false})
        }else{
          this.setState({editMode: false})
        }
        break;
    }
//     this.setState(vals)
//       title: p.title,
//       color: p.color,
//       bgColor: p.bgColor,
//       width: p.width,
//       height: p.height,
//       favorite: p.favorite,
    
  }
  
  showVisualizationScreen=()=>{
//     cl(this.state)
//     cl(this.props)
    let p=this.state.parent
//     cl(this.state)
//     cl(p)
    return(
      <div style={{position: "relative", width: p.width, height: (+p.height)+100, backgroundColor: p.bgColor}}
        onMouseMove={this.mouseMove} onMouseUp={this.mouseUp} onContextMenu={this.cm}
      >
      <div style={{height: 60, textAlign: "center", padding: 20}}><h2 style={{color: p.color}}>{p.title}</h2></div>
      {this.showWindows()}
      {this.showMenu()}
      {this.state.editMode &&
        <DataVisualizationEdit00 parms={{
          paneGraph: this.paneGraph,
        }} onChange={this.onChange}/>
      }
      {this.state.showEditScreen &&
        <DataVisualizationEditScreen00 onChange={this.onChange}
        parms={{
          title: this.state.parent.title,
          color: this.state.parent.color,
          bgColor: this.state.parent.bgColor,
          width: this.state.parent.width,
          height: this.state.parent.height,
          favorite: !!(this.state.parent.flags & constant.VISUALIZATION_SCREEN_FAVORITE),
        }}/>
      }
        <a style={{display: "blank"}} ref={this.downloadDiv}
          download={this.state.downloadName} href={this.state.download} ></a>
      </div>
    )
  }
  
  render(){
//     this.saveCurTimeDownloadString()
//     cl("render")
    if(this.state.loaded){
      return this.showVisualizationScreen()
    }else{
      return(
        <div>loading...</div>
      )
    }
//     cl(Datapoints)
  }
}

export default DataVisualization00 ;

