import React from 'react';
import {p, pi, pInd} from '../../components/utils/paramIds';
import {cl, globs, leadZeros, getTime} from '../../components/utils/utils';
import {dbVals} from '../../components/utils/http';


var setParamIds=(that)=>{
    that.state.ids={
      ventPos:[
        0,"normal",
        0,"cool_1",
        0,"cool_2",
        0,"cool_3",
        0,"cool_4",
        0,"cool_5",
        0,"cool_6",
        0,"activeCool_normal",
        0,"activeCool_cool1",
        0,"activeCool_cool2",
        0,"activeCool_cool3",
        0,"activeCool_cool4",
        0,"activeCool_cool5",
        0,"activeCool_cool6",
      ],
      cropProtection:[
        1, "cpWind",
        1, "cp2Wind",
        1, "cp3Wind",
        1, "cpPos",
        1, "cp2Pos",
        1, "cp3Pos",
        1, "cpDelay",
        1, "cp2Delay",
        1, "cp3Delay",
      ],
      structuralProtection: [
        1, "spWind",
        1, "spPos",
        1, "spDelay",
        1, "fireIn",
        1, "firePos",
        1, "fireDelay",
        0, "max_open_rain",
      ],
// hgUpDelay(2), hgDoDelay(2), hgPcnt_1(1), hgTemp_1(1) hgDeadband(2), 
// acUpDelay(2), acDoDelay(2), acPcnt_1(1), acTemp_1(1), acDeadband(2)
// rhgUpDelay(2), rhgDoDelay(2), rhgPcnt_1(1), rhgTemp_1(1), rhgDeadband(2)
// rtUpDelay(2), rtDoDelay(2), rtPcnt_1(1), rtTemp_1(1), rtDeadbanc(2)
      
      windBreak: [
        1, "hgUpDelay",
        1, "hgDoDelay",
        1, "hgDeadband",
        0, "hgPcnt_1",
        0, "hgPcnt_2",
        0, "hgPcnt_3",
        0, "hgPcnt_4",
        1, "hgPcnt_1",
        1, "hgPcnt_2",
        1, "hgPcnt_3",
        1, "hgPcnt_4",
        0, "hgTemp_1",
        0, "hgTemp_2",
        0, "hgTemp_3",
        0, "hgTemp_4",
        1, "hgTemp_1",
        1, "hgTemp_2",
        1, "hgTemp_3",
        1, "hgTemp_4",
      ],
      openField: [
        1, "acUpDelay",
        1, "acDoDelay",
        1, "acDeadband",
        0, "acPcnt_1",
        0, "acPcnt_2",
        0, "acPcnt_3",
        0, "acPcnt_4",
        1, "acPcnt_1",
        1, "acPcnt_2",
        1, "acPcnt_3",
        1, "acPcnt_4",
        0, "acTemp_1",
        0, "acTemp_2",
        0, "acTemp_3",
        0, "acTemp_4",
        1, "acTemp_1",
        1, "acTemp_2",
        1, "acTemp_3",
        1, "acTemp_4",
      ],
      shadeHouse: [
        1, "rhgUpDelay",
        1, "rhgDoDelay",
        1, "rhgDeadband",
        0, "rhgPcnt_1",
        0, "rhgPcnt_2",
        0, "rhgPcnt_3",
        0, "rhgPcnt_4",
        1, "rhgPcnt_1",
        1, "rhgPcnt_2",
        1, "rhgPcnt_3",
        1, "rhgPcnt_4",
        0, "rhgTemp_1",
        0, "rhgTemp_2",
        0, "rhgTemp_3",
        0, "rhgTemp_4",
        1, "rhgTemp_1",
        1, "rhgTemp_2",
        1, "rhgTemp_3",
        1, "rhgTemp_4",
      ],
      humidityIncrease: [
        1, "rtUpDelay",
        1, "rtDoDelay",
        1, "rtDeadband",
        0, "rtPcnt_1",
        0, "rtPcnt_2",
        0, "rtPcnt_3",
        0, "rtPcnt_4",
        1, "rtPcnt_1",
        1, "rtPcnt_2",
        1, "rtPcnt_3",
        1, "rtPcnt_4",
        0, "rtHum_1",
        0, "rtHum_2",
        0, "rtHum_3",
        0, "rtHum_4",
        1, "rtHum_1",
        1, "rtHum_2",
        1, "rtHum_3",
        1, "rtHum_4",
      ],
      
// hgUpDelay(2), hgDoDelay(2), hgPcnt_1(1), hgTemp_1(1) hgDeadband(2), 
// acUpDelay(2), acDoDelay(2), acPcnt_1(1), acTemp_1(1), acDeadband(2)
// rhgUpDelay(2), rhgDoDelay(2), rhgPcnt_1(1), rhgTemp_1(1), rhgDeadband(2)
// rtUpDelay(2), rtDoDelay(2), rtPcnt_1(1), rtTemp_1(1), rtDeadbanc(2)
      
    }
  }
  
var getParam=(that, z,c,tab,ind)=>{// tab refers to our local (channel, id) tables
    let param=tab[2*ind+1]
    let cOfs=tab[2*ind]
    let id=that.base+pi[1800].config_channels_configuration[param]
    let ret=((dbVals.z[z]||{})[c+cOfs]||{})[id]
    return (ret)?ret:0
  }
  
var putParam=(that, z,c,t,tab,ind,parms,val)=>{// tab refers to our local (channel, id) tables
    let param=tab[2*ind+1]
    let cOfs=tab[2*ind]
    let id=that.base+pi[1800].config_channels_configuration[param]
//     cl(id)
    parms.push({
      c:c+cOfs,// zone wide
      d:val,
      f:1,
      i:id,
      t:t,
      z:z,
    })
  }
  
var getVentPos=(that, z,c)=>{
    let pos=[]
    for(let i=0;i<7;i++){pos.push(getParam(that,z,c,that.state.ids.ventPos,i))}
    let activePos=[]
    for(let i=7;i<14;i++){activePos.push(getParam(that,z,c,that.state.ids.ventPos,i))}
    that.state.ventPos={
      pos: pos,
      activePos: activePos,
    }
  }
  
var saveVentPos=(that, z,c,t,parms)=>{
    let obj=that.state.ventPos
//     cl(obj)
    for(let i=0;i<7;i++){putParam(that,z,c,t,that.state.ids.ventPos,i,parms,obj.pos[i])}
    for(let i=7;i<14;i++){putParam(that,z,c,t,that.state.ids.ventPos,i,parms,obj.activePos[i-7])}
  }
  
var getCropProtection=(that, z,c)=>{
    let windSpeed=[]
    for(let i=0;i<3;i++){windSpeed.push(getParam(that,z,c,that.state.ids.cropProtection,i))}
    let position=[]
    for(let i=3;i<6;i++){position.push(getParam(that,z,c,that.state.ids.cropProtection,i))}
    let delayOut=[]
    for(let i=6;i<9;i++){delayOut.push(getParam(that,z,c,that.state.ids.cropProtection,i))}
    that.state.cropProtection={
      windSpeed: windSpeed,
      position: position,
      delayOut: delayOut,
    }
  }
  
var saveCropProtection=(that, z,c,t,parms)=>{
    let obj=that.state.cropProtection
    for(let i=0;i<3;i++){putParam(that,z,c,t,that.state.ids.cropProtection,i,parms,obj.windSpeed[i])}
    for(let i=0;i<3;i++){putParam(that,z,c,t,that.state.ids.cropProtection,i+3,parms,obj.position[i])}
    for(let i=0;i<3;i++){putParam(that,z,c,t,that.state.ids.cropProtection,i+6,parms,obj.delayOut[i])}
  }
  
var getStructuralProtection=(that, z,c)=>{
    that.state.structuralProtection={
      wind: getParam(that,z,c,that.state.ids.structuralProtection,0),
      position: getParam(that,z,c,that.state.ids.structuralProtection,1),
      delay: getParam(that,z,c,that.state.ids.structuralProtection,2),
      digital: getParam(that,z,c,that.state.ids.structuralProtection,3),
      open: getParam(that,z,c,that.state.ids.structuralProtection,4),
      delay2: getParam(that,z,c,that.state.ids.structuralProtection,5),
      max: getParam(that,z,c,that.state.ids.structuralProtection,6),
    }
  }
  
var saveStructuralProtection=(that, z,c,t,parms)=>{
    let obj=that.state.structuralProtection
    putParam(that,z,c,t,that.state.ids.structuralProtection,0,parms,obj.wind)
    putParam(that,z,c,t,that.state.ids.structuralProtection,1,parms,obj.position)
    putParam(that,z,c,t,that.state.ids.structuralProtection,2,parms,obj.delay)
    putParam(that,z,c,t,that.state.ids.structuralProtection,3,parms,obj.digital)
    putParam(that,z,c,t,that.state.ids.structuralProtection,4,parms,obj.open)
    putParam(that,z,c,t,that.state.ids.structuralProtection,5,parms,obj.delay2)
    putParam(that,z,c,t,that.state.ids.structuralProtection,6,parms,obj.max)
  }
  
var getWindBreak=(that,z,c)=>{
    let pos=[]
    for(let i=3;i<11;i++){pos.push(getParam(that,z,c,that.state.ids.windBreak,i))}
    let thresh=[]
    for(let i=11;i<19;i++){thresh.push(getParam(that,z,c,that.state.ids.windBreak,i))}
    if(!that.state.windBreak){that.state.windBreak={}}
    that.state.windBreak[c]={
      upTime: getParam(that,z,c,that.state.ids.windBreak,0),
      downTime: getParam(that,z,c,that.state.ids.windBreak,1),
      deadband: getParam(that,z,c,that.state.ids.windBreak,2),
      pos: pos,
      thresh: thresh,
    }
  }
  
var saveWindBreak=(that, z,c,t,parms)=>{
    let obj=that.state.windBreak[c]
//     cl(obj)
    putParam(that,z,c,t,that.state.ids.windBreak,0,parms,obj.upTime)
    putParam(that,z,c,t,that.state.ids.windBreak,1,parms,obj.downTime)
    putParam(that,z,c,t,that.state.ids.windBreak,2,parms,obj.deadband)
    for(let i=0;i<8;i++){putParam(that,z,c,t,that.state.ids.windBreak,i+3,parms,obj.pos[i])}
    for(let i=0;i<8;i++){putParam(that,z,c,t,that.state.ids.windBreak,i+11,parms,obj.thresh[i])}
  }

var getOpenField=(that, z,c)=>{
    let pos=[]
    for(let i=3;i<11;i++){pos.push(getParam(that,z,c,that.state.ids.openField,i))}
    let thresh=[]
    for(let i=11;i<19;i++){thresh.push(getParam(that,z,c,that.state.ids.openField,i))}
    if(!that.state.openField){that.state.openField={}}
    that.state.openField[c]={
      upTime: getParam(that,z,c,that.state.ids.openField,0),
      downTime: getParam(that,z,c,that.state.ids.openField,1),
      deadband: getParam(that,z,c,that.state.ids.openField,2),
      pos: pos,
      thresh: thresh,
    }
  }

var saveOpenField=(that, z,c,t,parms)=>{
    let obj=that.state.openField[c]
//     cl(obj)
    putParam(that,z,c,t,that.state.ids.openField,0,parms,obj.upTime)
    putParam(that,z,c,t,that.state.ids.openField,1,parms,obj.downTime)
    putParam(that,z,c,t,that.state.ids.openField,2,parms,obj.deadband)
    for(let i=0;i<8;i++){putParam(that,z,c,t,that.state.ids.openField,i+3,parms,obj.pos[i])}
    for(let i=0;i<8;i++){putParam(that,z,c,t,that.state.ids.openField,i+11,parms,obj.thresh[i])}
  }

var getShadeHouse=(that, z,c)=>{
    let pos=[]
    for(let i=3;i<11;i++){pos.push(getParam(that,z,c,that.state.ids.shadeHouse,i))}
    let thresh=[]
    for(let i=11;i<19;i++){thresh.push(getParam(that,z,c,that.state.ids.shadeHouse,i))}
    if(!that.state.shadeHouse){that.state.shadeHouse={}}
    that.state.shadeHouse[c]={
      upTime: getParam(that,z,c,that.state.ids.shadeHouse,0),
      downTime: getParam(that,z,c,that.state.ids.shadeHouse,1),
      deadband: getParam(that,z,c,that.state.ids.shadeHouse,2),
      pos: pos,
      thresh: thresh,
    }
  }
  
var saveShadeHouse=(that, z,c,t,parms)=>{
    let obj=that.state.shadeHouse[c]
    putParam(that,z,c,t,that.state.ids.shadeHouse,0,parms,obj.upTime)
    putParam(that,z,c,t,that.state.ids.shadeHouse,1,parms,obj.downTime)
    putParam(that,z,c,t,that.state.ids.shadeHouse,2,parms,obj.deadband)
    for(let i=0;i<8;i++){putParam(that,z,c,t,that.state.ids.shadeHouse,i+3,parms,obj.pos[i])}
    for(let i=0;i<8;i++){putParam(that,z,c,t,that.state.ids.shadeHouse,i+11,parms,obj.thresh[i])}
  }

var getHumidityIncrease=(that, z,c)=>{
    let pos=[]
    for(let i=3;i<11;i++){pos.push(getParam(that,z,c,that.state.ids.humidityIncrease,i))}
    let thresh=[]
    for(let i=11;i<19;i++){thresh.push(getParam(that,z,c,that.state.ids.humidityIncrease,i))}
    if(!that.state.humidityIncrease){that.state.humidityIncrease={}}
    that.state.humidityIncrease[c]={
      upTime: getParam(that,z,c,that.state.ids.humidityIncrease,0),
      downTime: getParam(that,z,c,that.state.ids.humidityIncrease,1),
      deadband: getParam(that,z,c,that.state.ids.humidityIncrease,2),
      pos: pos,
      thresh: thresh,
    }
  }
  
var saveHumidityIncrease=(that, z,c,t,parms)=>{
    let obj=that.state.humidityIncrease[c]
    putParam(that,z,c,t,that.state.ids.humidityIncrease,0,parms,obj.upTime)
    putParam(that,z,c,t,that.state.ids.humidityIncrease,1,parms,obj.downTime)
    putParam(that,z,c,t,that.state.ids.humidityIncrease,2,parms,obj.deadband)
    for(let i=0;i<8;i++){putParam(that,z,c,t,that.state.ids.humidityIncrease,i+3,parms,obj.pos[i])}
    for(let i=0;i<8;i++){putParam(that,z,c,t,that.state.ids.humidityIncrease,i+11,parms,obj.thresh[i])}
  }

  export {setParamIds, getParam, putParam, getVentPos, saveVentPos, getCropProtection, saveCropProtection, 
    getStructuralProtection, saveStructuralProtection, getWindBreak, saveWindBreak, getOpenField, saveOpenField, 
    getShadeHouse, saveShadeHouse, getHumidityIncrease, saveHumidityIncrease
}
