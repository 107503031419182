import React from 'react';
import Button from '@material-ui/core/Button';
// import InputLabel from '@material-ui/core/InputLabel';
// import Input from "@material-ui/core/Input";
// import InputAdornment from '@material-ui/core/InputAdornment';
// import IconButton from '@material-ui/core/IconButton';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import {cl} from '../../components/utils/utils';

class UsaButton extends React.Component{
  
  render(){
    return(
      <Button onClick={this.props.onClick}
      style={{margin: 10, display: (this.props.inline) ? "inline" : "block"}}
      variant="contained"
      color="primary"
      id={this.props.type}
      className={"button"}>
      {this.props.text}
      </Button>
    );
  }
}

export default UsaButton;
