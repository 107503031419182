import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Password from './Password';
import TextInput from './TextInput';
import OkCancel from './OkCancel';
import C18Button00 from './C18Button00'
import UsaButton from './UsaButton';
import C18InputError00 from './C18InputError00'
import {wsTrans, saveTokens, logout, getUId } from '../utils/utils';
import {cl, globs,getTZName,initInfos} from '../../components/utils/utils';
import history from "../../history"

class InviteUser extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    document.title="Invite User";
    logout();
    this.state={
      firstName: "",
      lastName: "",
      name:"",
      password1: "",
      password2: "",
      password1Visible:false,
      password2Visible:false,
      loaded:false,
    };
     this.alerts=[
      "Just fill in the fields, and press Register!",//0
      "Company Name Cannot Be Blank",//1
      "Company Name is In Use ",//2
      "Your Name Cannot Be Blank",//3
      "Email Cannot Be Blank",//4
      "Email is In Use ",//5
      "Please Enter a Valid Email",//6
      "Emails Don't Match ",//7
      "Password Cannot Be Blank",//8
      "Password is Too Short",//9
      "Passwords Don't Match",//10
      "What a Super-Duper Password!",//11
      "Checking . . .",//12
      "Password is Too Common ",//13
      "Just Press Register!",//14
     ];
     this.loadInfo()
  }
  
  loadInfo=async()=>{
//     cl("load info")
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/invites", method: "retrieve", 
      body: {token: this.props.token}})
    let user=res.data
    cl(user)
    this.setState({name:user.name,userId:user.userId,loaded:true})
  }
  
  sendInfo=()=>{
// needs to make sure that current user, if any, is removed
//     cl(this.props.token);
    initInfos()
    getUId()
    let da=new Date()
    da.setMonth(0)// to standard time for this year
    let tzo=da.getTimezoneOffset()
    let tz=getTZName(tzo)
    
    let body={
      userId:this.state.userId, 
      name: this.state.name, 
      password: this.state.password1, 
      token: this.props.token, 
      timezone:tz,
      uId: globs.userData.uId};
    let addUser={cmd: "cRest", uri: "/o/invites", method: "create", body: body};
    wsTrans("usa", addUser).then(r=>{
//       cl(r)
      saveTokens(r.data.accessToken, r.data.refreshToken, r.session)
      history.push('/usa/c18/admin/userProfile');
    });
  }
  
  doResult=(result)=>{
    let cmds = {"ok": this.sendInfo};
    if(cmds[result]) cmds[result]();
//     cl(result);
  }
  
  
  checkPasswords=(id, value)=>{
    let pass1=this.state.password1;
    let pass2=this.state.password2;
    if(id == "password1"){
      pass1=value;
    }else{
      pass2=value;
    }
    var msg;
    if (pass2 == ""){
      msg="Please Enter a New Password";
    }else{
      msg=(pass1===pass2) ? "Click OK to Set New Password" : "Passwords don't Match";
    }
    this.setState();
  }
  
  setStateValue=(id, value)=>{
    let val={};
    val[id]=value;
    this.setState(val);
    if((id==="password1") || (id==="password2")) this.checkPasswords(id, value);
  }
  
  setAlert=(n)=>{
//     cl("alert",n)
    let state=this.state
    var companyNameError,nameError,email1Error,email2Error,password1Error,password2Error
    switch(n){
      case 3:
        nameError=this.alerts[n]
        break
      case 8:
      case 9:
      case 13:
        password1Error=this.alerts[n]
        break
      case 10:
      case 11:
        password2Error=this.alerts[n]
        break
    }
    this.setState({
      companyNameError: companyNameError,
      nameError: nameError,
      email1Error: email1Error,
      email2Error: email2Error,
      password1Error: password1Error,
      password2Error: password2Error,
      alertId: n});
  }
  
  checkFields=async(state)=>{
    this.timeoutIF = null;
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/users/unique", method: "retrieve", 
      body: {companyName: state.companyName, email: state.email1, password: state.password1}})
//     if(!res.uc && (this.state.alertId > 2)) return this.setAlert(2);// company name used
//     if(!res.ue && (this.state.alertId > 5)) return this.setAlert(5);// email used
    if(!res.up && (this.state.alertId > 11)) return this.setAlert(13);// password fails
    if(this.state.alertId === 12) this.setAlert(14);
  }
  
  validate=(state)=>{
    if(state.name==="") return this.setAlert(3);
    if(state.password1 + state.password2 === "") return this.setAlert(8);
    if(state.password1.length < 6) return this.setAlert(9);
    if(state.password1 !== state.password2) return this.setAlert(10);
    
    this.checkFields(state)
    if(state.password1==="password") return this.setAlert(11);
    return this.setAlert(12);
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "input":
        let st=Object.assign({},this.state,vals)
        this.validate(st)
        break
      case "visibility":
        let field={"if-password1":"password1Visible","if-password2":"password2Visible"}[vals.id]
        vals={}
        vals[field]=!this.state.field;
        break
    }
    this.setState(vals)
  }
  
  render(){
//                 <TextInput title="First Name" valueId="firstName" onChange={this.setStateValue}/>
//                 <TextInput title="Last Name" valueId="lastName" onChange={this.setStateValue}/>
//                 <Password title="Password" valueId="password1" onChange={this.setStateValue}/>
//                 <Password title="Confirm Password" valueId="password2" onChange={this.setStateValue}/>
    if(this.state.loaded){
      return(
        <div>
          <div className="login-register-new">
            <div className="login-left">
            </div>
            <div className="login-right">
              <header>
                <div className="main-logo floatleft">
                  <a href="https://link4controls.com/" className="main-logo"><img src="/img/link4_logo_blue_trans.png" alt="Link4 Controls"/></a>
                </div>
              </header>

              <form>
                <h1>Join the Link4 Cloud!</h1>
                <div className="join-wrapper">
                  <C18InputError00 parms={{
                    type:'text',
                    title:"Your Name",
                    id:"if-name",
                    required: true,
                    value: this.state.name,
                    valueId: "name",
                    onChange: this.onChange,
                    message:this.state.nameError,
                    messageType: "error"
                  }}/>
                  <C18InputError00 parms={{
                    type:'password',
                    visible:this.state.password1Visible,
                    title:"Your Password",
                    id:"if-password1",
                    required: true,
                    value: this.state.password1,
                    valueId: "password1",
                    onChange: this.onChange,
                    message:this.state.password1Error,
                    messageType: "error"
                  }}/>
                  <C18InputError00 parms={{
                    type:'password',
                    visible:this.state.password2Visible,
                    title:"Confirm Password",
                    id:"if-password2",
                    required: true,
                    value: this.state.password2,
                    valueId: "password2",
                    onChange: this.onChange,
                    message:this.state.password2Error,
                    messageType: "error"
                  }}/>
                </div>
                <div className="button-container">
                  <C18Button00 type="button" id="login-button" className="filled"
                  onClick={()=>this.sendInfo()}>Join</C18Button00>
                </div>
              </form>
              <div id="footer" className="login">
                <p>Cloud-Based Environmental Controls</p>
                <p>Copyright &copy;2021 Link4 Corporation. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      );
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

export default InviteUser ;

