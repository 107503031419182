import React from 'react';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import {cl, globs} from '../../components/utils/utils';
import history from '../../history'


class Link00 extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.props.getValue(props.ind, {type: "scalar"})
//     this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveCancel)
  }

  componentWillUnmount=()=>{
//     this.subscribe_savePageEvent.remove()
  }
  
  render(){
    let url=this.props.custom.url
    let pos=url.indexOf("{z}")
    if(pos>0){
      let z=this.props.zuci.split("-")[0]
      url=url.replace("{z}",z)
//       cl(url)
//       cl(z)
//       cl("got z")
    }
    return(
      <button id={this.props?.custom?.infoId} type="button" className="small"
      onClick={e=>{history.push(url)}}
      >{this.props.custom.title}</button>
// http://ngsg.link4cloud.com:3104/usa/c18/fui/zone_SP_Retractable_Greenhouse/1-0-240-0
    )
  }

}

  export default Link00 ;
