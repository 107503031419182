import React from 'react';
import UsaIcon from '../UsaIcon';
import UsaSelect00 from '../UsaSelect00'
import {loadZonesInfo,getZoneInfo,loadSitesInfo,loadSiteData,saveTable,getSiteName,getZoneName} from '../C18utils'
import {wsTrans,getParamId,getParmValue} from '../../../usa/utils/utils'
import {init, dbVals} from '../../../components/utils/http';
import {cl,globs,az,constant} from '../../../components/utils/utils';
import {pi,pInd} from '../../../components/utils/paramIds';

const verticalSpace=<div style={{height: 10}}/>

class Tanks extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      value: "",
//       gatewayId:getZoneInfo(props.parms.zone).gatewayId,
      editMode: false,
      loaded: false,
      name:"",
      tankOpts: {},
//       nutes: ["yHcJOv76@k284RPa"],
      tankPumpSel: -1,
      tankPumps:[],
//         {addr:0, nutrientId: "yHcJOv76@k284RPa"}// pump addr, and nutrient
//       ],
    }
    
//     this.tankPumps={
//       192:{0:"bY91eSYM5byvttl$", 1:"@OQ8EdIxNmxuH7xT"},
//       193:{3:"yHcJOv76@k284RPa"}
//     }
    this.loadData()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.savePage)
    if(this.props.parms.saveOK){this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})}
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.setBreadCrumbs()
  }
/*
tanks:
get all the tanks in the site
and all the tank names
like for recipes
make tankOpts, and Tank Select
Also, get all the pumps, and the tanks that they use 
Also get all the nutrients
When you select a tank to edit, populate pumpOpts with the pumps for that tank
select tankPumps, for each pump, select a nutrient
Add a pump, Select a pump, Select a nute
tankPumps: siteId, tank addr, pump addr, nutrientId

this.tankPumps is a double object, organized by tank addr, then by pump addr, with nutrientId
as the value. this is the true state of all of the tankPumps
when a tank is selected for editing, copy this to this.state.tankPumps
When a tank is saved, the settings for all of the pumps are written to the db


this.state.tankPumps is the pumps for the currently selected tank

*/

  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
  setBreadCrumbs=()=>{
    let p=this.props.parms
    let siteName=getSiteName(p.site)
    let zoneName=getZoneName(p.zone)
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
              {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
              {t:"iDoser", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/idoser/nutrients`},
              {t:"Tanks", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/idoser/tanks`},
            ]},
        },
      )
    }
  }
  
  savePage=(cmd)=>{
    if(cmd=="save"){
      this.saveTankPumps()
    }
  }
  
  loadPumpsEtc=async()=>{// loads tanks, too
// the valves will be identified by "addr": channel + unit * 256 + zone*1024
    await init()
//     cl("loaded")
    let chTypeInd=pInd[1800].channels_configuration[0]+pi[1800].channels_configuration["channelType"]
    let chNameInd=pInd[1800].channels_configuration[0]+pi[1800].channels_configuration["channelName"]
    let chUsedInd=pInd[1800].channels_configuration[0]+pi[1800].channels_configuration["used"]
    let chMixingTankInd=pInd[1800].config_channels_configuration[0]+pi[1800].config_channels_configuration["CD_tankSensor_fval"]
    let chPumpTypeInd=pInd[1800].config_channels_configuration[0]+pi[1800].config_channels_configuration["pump_type"]
    let chPumpProbeInd=pInd[1800].config_channels_configuration[0]+pi[1800].config_channels_configuration["probeSensorIndex"]
    let ecMixingTankNameInd=pInd[1800].config_ecph[0]+pi[1800].config_ecph["name"]
//     let valveOpts={}//[]//{}
    let tanks=[]
    let tankNames={}
    let pumps={}
    dbVals.z.forEach((z,zInd)=>{// for all the zones
//       cl(zInd)
//       cl(globs.siteZoneTypes[zInd])
      if(globs.siteZoneTypes[zInd]=="1800"){
        
        for(let i=0;i<40;i++){// for all the pumps
          if(z[i]){
            let addr=i | (zInd<<10)// addr of FlV?// this assumes that pump is on zone master, not slave
//             if((z[i][chTypeInd]==constant.EQ_FVALVE)&&(+z[i][chUsedInd])){// getting tanks
//               let ti=+z[i][chMixingTankInd]
//               if(!ti)ti=0
//               let tankAddr=(192+ti) | (zInd<<10)
//               tanks.push({addr: addr, tankAddr: tankAddr})
//             }
            if((z[i][chTypeInd]==constant.EQ_PUMP)&&
              (z[i][chPumpTypeInd]==constant.PUMP_PERISTALTIC_BATCH)){// getting pumps - need to check for batch!
              let tankAddr=192+(+z[i][chPumpProbeInd]) | zInd<<10
              tanks.push({addr: addr, tankAddr: tankAddr})
//               cl(tankAddr)
//               cl(i)
//               cl(z[i][chPumpTypeInd])
              if(!pumps[tankAddr]){pumps[tankAddr]={}}
              pumps[tankAddr][addr]={
                type:z[i][chPumpTypeInd],
                name:z[i][chNameInd],
                addr: addr,
                tank: tankAddr,
              }
            }
          }
        }
      }

      
      for(let i=192;i<199;i++){
        if(z[i]){
          let addr=i | (zInd<<10)
          let name=z[i][ecMixingTankNameInd]
          if(name){tankNames[addr]=name}
        }
      }
    })
//     cl(tanks)
    let tankOpts={}
//     cl(pumps)
//     cl(tanks)
    tanks.forEach(t=>{
//       cl(t)
//       cl(t.tankAddr)
      let zId=1+((t.tankAddr >> 10) & 0x03F)
      let tId=1+((+t.tankAddr)&0xFF)-192
//       cl(t.tankAddr)
      tankOpts[t.tankAddr]=(tankNames[t.tankAddr])?tankNames[t.tankAddr]:`Zone ${zId}, Tank ${tId}`
    })
//     cl(pumps)
//     cl(tankOpts)
    return [pumps, tankOpts]
  }
  
  getTankAddr=(pumpAddr)=>{
    let z=(pumpAddr>>10)&0x1F
    let u=(pumpAddr>>8)&0x03
    let c=pumpAddr&0x7F
    let pid=getParamId("configuration_channel_data","probeSensorIndex")
//     cl([z,u,c,pid])
    
    let tank=+getParmValue(z,c,0,pid)
    return (z<<10)|(192+tank)
//     cl(tank)
  }

  loadData=async()=>{
    let siteId=globs.userData.session.siteId
    await loadZonesInfo()
    this.gatewayId=getZoneInfo(this.props.parms.zone).gatewayId
    let siteDataP=loadSiteData(siteId)
    let nuP=wsTrans("usa", {cmd: "cRest", uri: "/s/nutrients", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    let tpP=wsTrans("usa", {cmd: "cRest", uri: "/s/tankPumps", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    let [nuR,tpR,siteDataR]=await Promise.all([nuP,tpP,siteDataP])
//     cl(tpR)
//     cl(this.state)
    let [pumps,tankOpts]=await this.loadPumpsEtc()
    let tankPumps={}// this needs entries for all the *real* pumps, and with nute filled in if we have it
    Object.keys(pumps).forEach(tankAddr=>{// k is the tankAddr
      let p=pumps[tankAddr]
      Object.keys(p).forEach(pumpAddr=>{
//         cl([tankAddr,pumpAddr])
        if(!tankPumps[tankAddr]){tankPumps[tankAddr]={}}
        tankPumps[tankAddr][pumpAddr]=-1
      })
    })
    
//     let tankPumps={}// this needs entries for all the *real* pumps, and with nute filled in if we have it
// now, syncing with LinkConn, there *is* no tankAddr in the table
//     cl(tankPumps)
    tpR.data.forEach(tp=>{
      let tankAddr=this.getTankAddr(tp.pumpAddr)
//       cl(tankAddr)
      if(!tankPumps[tankAddr]){tankPumps[tankAddr]={}}
//       cl(tp)
      tankPumps[tankAddr][tp.pumpAddr]=tp.nutrientId
    })
    let nuteOpts={}
    nuR.data.forEach(n=>{
      nuteOpts[n.nutrientId]={t:n.name}
    })
    
//     cl(pumps)
//     this.pumps=pumps
//     cl(pumps)
    let tankSel=Object.keys(tankOpts)[0]
    this.setState({loaded: true, tankOpts: tankOpts, tankSel: tankSel, tankPumps: tankPumps, pumpOpts:pumps, nuteOpts: nuteOpts})
  }
  
  saveTankPumps=async()=>{
//     cl(this.state)
    let pumps=[]
    let siteId=globs.userData.session.siteId
    let tankAddr=this.state.tankSel
    cl(tankAddr)
    let tp=this.state.tankPumps[tankAddr]
    Object.keys(this.state.tankPumps[tankAddr]).forEach(pumpId=>{
      let t=tp[pumpId]
//       cl(t)
      pumps.push({
        pumpAddr: +pumpId,
        nutrientId: +tp[pumpId],
        gatewayId:this.gatewayId, 
        tankAddr: +tankAddr-192,
//         siteId: siteId,
      })
      
    })
//     cl(pumps)
    
    await wsTrans("usa", {cmd: "cRest", uri: "/s/tankPumps", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId,tankAddr: tankAddr, pumps: pumps}})
    saveTable("tankPumps",this.gatewayId)
    globs.events.publish("saveOK",true)
  }
  
//   get
  
  getATank=(tankSel)=>{
    let tankPumps=[]
//     cl(tankSel)
//     cl(this.pumps)
//     cl(this.tankPumps)
    let tankRawPumps=this.pumps[tankSel]// this pumps without nutrients
//     cl(tankRawPumps)
    Object.keys(tankRawPumps).forEach(pumpAddr=>{
//       cl(tankRawPumps[pumpAddr])
      let nuteId=this.tankPumps[tankSel][pumpAddr]||-1
      tankPumps.push({addr:pumpAddr,nutrientId:nuteId})
    })
//     cl(tankPumps)
    
//     cl(this.tankPumps)
//     let pumps=this.tankPumps[tankSel]
//     Object.keys(pumps).forEach(k=>{
//       tankPump
//       return{addr:k, nutrientId:pumps[k]}
//       
//     })
    
//     let tankPumps=(pumps)?Object.keys(pumps).map(k=>{return{addr:k, nutrientId:pumps[k]}}):[]
//     cl(tankPumps)
    this.setState({editMode: true, name: this.state.tankOpts[tankSel], tankPumps: tankPumps})
  }
  
  pumpResult=(type)=>{
    let [type2,cmd]=type.split("-")
    let id=+type2.substr(4)
    cl(id)
    switch(cmd){
      case "Edit":// fill in nuteSel, this.tankPumps has the nute assignments
//         let nuteSel=Object.values(this.tankPumps[this.state.tankSel])[id]
        let nuteSel=this.state.tankPumps[id].nutrientId
        this.setState({tankPumpSel: id, nuteSel: nuteSel})
        break
      case "OK":// save nute
        let pumps=this.state.tankPumps.slice(0)
        pumps[id].nutrientId=this.state.nuteSel
//         cl(this.tankPumps[this.state.tankSel][0])
//         this.tankPumps[this.state.tankSel][this.state.tankPumps[id].addr]=this.state.nuteSel
//         cl(this.tankPumps[this.state.tankSel][0])
        this.setState({tankPumpSel: -1, tankPumps: pumps})
        break
      case "Cancel":
        this.setState({tankPumpSel: -1})
        break
      default:
        break
    }
  }
  
  result=(type)=>{
//     cl(type)
    if(type.substr(0,4)=="Pump"){return this.pumpResult(type)}
    switch(type){
      case "Tanks-OK":
        this.saveTankPumps()
        this.setState({editMode: false})
        break
      case "Tanks-Cancel":
        this.setState({editMode: false})
        break
      case "Tanks-Edit":
        this.getATank(this.state.tankSel)
        break
      case "Tanks-Add":
        this.newNutrient()
        break
      case "Tanks-Delete":
        this.deleteNutrient()
        break
      default:
        break
    }
  }
  
  onChange=(type, vals)=>{
//     cl([type,vals])
//     cl(vals)
    switch(type){
      case "tank":// if the nutes change
        globs.events.publish("savePageEnable",true)
//         cl(type,vals)
        let tp=Object.assign({},this.state.tankPumps)
//         cl(tp[this.state.tankSel])
        tp[this.state.tankSel][vals.pumpId]=vals.nute
//         cl(tp[this.state.tankSel])
        this.setState({tankPumps:tp})
        break
      case "tankO":// selection of the tank
        this.setState(vals)
        break
      case "upd":
        let to=this.state.tankOpts
        to[this.state.tankSel]=vals
        this.setState({tankOpts:to})
        break
      default:
        break
    }
  }
  
  onChange2=(key, ind, val)=>{
    let vals={}
    if(ind>=0){// an array
      let valArr=this.state[key].slice(0)
      valArr[ind]=val
      vals[key]=valArr
    }else{// scalar
      vals[key]=val
    }
    this.setState(vals)
  }
  
  showTankSelect=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Select\u00A0Tank",
        select: true, // !this.state.editMode,
        inline: true,
        valueId: "tankSel",//valueId, // "widgetSel",
        value: this.state.tankSel, //value, // this.state.widgetSel,
        opts: this.state.tankOpts,
        onChange: x=>this.onChange("tank",x)
      }}
      />
      
    )
  }
  
  showName=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Name",
        width: 400,
        select: false, // !this.state.editMode,
        valueId: "name",//valueId, // "widgetSel",
        value: this.state.name, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
    )
  }
  
  showNuteSelect=(p)=>{
//     let opts={a:{t:"a"}, b:{t:"b"}}
//     cl(this.state.nuteOpts)
    return(
      <UsaSelect00 parms={{
        mode: "inlineSelect00",
        value: this.state.nuteSel, //value, // this.state.widgetSel,
        opts: this.state.nuteOpts,
        onChange: v=>this.onChange2("nuteSel",-1,v)
      }}
      />
    )
  }
  
  showPumpLine=(name, p, i)=>{
//     cl(p)
    let nuteId=this.state.nuteOpts[p.nutrientId]
    let nute=(nuteId)?nuteId.t:"none"
    if(this.state.tankPumpSel==i){
      return <>
      <td>
        {name}
      </td><td>
        {this.showNuteSelect(p)}
      </td>
      </>
    }else{
      return(
        <><td>{name}</td><td>{`${nute}`}</td></>
      )
    }
  }
  
  showPumpIcons=(i)=>{
    if(this.state.tankPumpSel==i){
      return(
        <>
          <td>
            <UsaIcon icon={`Pump${i}-OK`} result={this.result} inline/>
          </td>
          <td>
            <UsaIcon icon={`Pump${i}-Cancel`} result={this.result} inline/>
          </td>
        </>
      )
    }else{
      return(
        <>
          <td>
            <UsaIcon icon={`Pump${i}-Edit`} result={this.result} inline/>
          </td>
          <td width="30">
          </td>
        </>
      )
    }
  }
  
  showPumps=()=>{
/*this is not showing the pumps unless they have a nutrient in the tankPumps table
 * 
 */
//     cl(this.state)
//     cl(this.pumps)
//     cl(this.state.tankSel)
    let pumps=this.pumps[this.state.tankSel]
    return(
      <div>
      <h3>Pumps</h3>
        <table><tbody>
        {this.state.tankPumps.map((p,i)=>{
//           cl(p)
  //         cl(n)
          let name=pumps[p.addr].name
          return(
            <tr key={i}>
            {this.showPumpIcons(i)}
            {this.showPumpLine(name, p, i)}
            </tr>
          )
        })}
        </tbody></table>
      </div>
    )
  }
  
  showEditFields=()=>{
/*
        {verticalSpace}
        {this.showDescription()}
        {verticalSpace}
        {this.showManufacturer()}
        {verticalSpace}
        {this.showNotes()}
        */
   if(this.state.editMode){
      return(
      <div>
        {verticalSpace}
        {this.showName()}
        {verticalSpace}
        {this.showPumps()}
      </div>
      )
    }else{
      return null
    }
  }
  
  showMainIcons=()=>{
    if(this.state.editMode){
      return([
        <UsaIcon key={0} space="0.5" icon="Tanks-OK" result={this.result} inline/>,
        <UsaIcon key={1} icon="Tanks-Cancel" result={this.result} inline/>,
      ])
    }else{
      return([
        <UsaIcon key={0} space="0.5" icon="Tanks-Edit" result={this.result} inline/>,
      ])
    }
  }
  
  renderO(){
    if(this.state.loaded){
      return(
        <div style={{padding: 10}}>
        <h3>Tanks
        {this.showMainIcons()}
        </h3>
        {this.showTankSelect()}
        {this.showEditFields()}
        
        </div>
      )
    }else{
      return <div>loading...</div>
    }
  }
  
  showTankSelect=()=>{
//     cl(this.state)
    let to=this.state.tankOpts
    return(
              <span className="custom-select">
                <label htmlFor="idoser-tank">Select Tank</label>
                <select id="idoser-tank"
                  value={this.state.tankSel}
                  onChange={e=>this.onChange("tankO",{tankSel:e.currentTarget.value})}
                >
                {Object.keys(to).map((tankId,i)=>{
//                   cl(tankId)
//                   cl(this.state.tankSel)
                  return(
                    <option key={i} value={tankId}>{to[tankId]}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>
      
    )
  }
  
  showTankEdit=()=>{
    return(
      <div>
              <label htmlFor="idoser-name">Name</label>
              <input id="idoser-name" type="text" 
              value="Recipe 1" 
              value={this.state.tankOpts[this.state.tankSel]}
              onChange={e=>this.onChange("upd",e.currentTarget.value)}
              />
      </div>
    )
  }
  
  showPumpSelect=()=>{
//     cl(this.state.pumpOpts)
    let po=this.state.pumpOpts[this.state.tankSel]
    return(
                <span className="custom-select">
                  <label>Select Pump</label>
                  <select>
                  {Object.keys(po).map((pumpId,i)=>{
//                     cl(po)
//                     cl(pumpId)
                    return(
                      <option key={i} value={pumpId}>{po[pumpId].name}</option>
                    )
                  })}
                  </select>
                  <span className="material-icons down-arrow">
                    keyboard_arrow_down
                  </span>
                </span>
    )
  }
  
  showNutrientSelect=(index, pumpId)=>{
//     cl(index,pumpId)
//     cl(this.state)
    let no=this.state.nuteOpts
//     cl(no)
//     cl(this.state.tankPumps)
//     cl(this.state.tankSel)
//     cl(pumpId)
//     cl(this.state.tankPumps[this.state.tankSel][pumpId])
    return(
      <span className="custom-select">
        {(index==0)&&
          <label>Select Nutrient</label>
        }
        <select
          value={this.state.tankPumps[this.state.tankSel][pumpId]}// tankSel is let tankAddr=(192+ti) | (zInd<<10)
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("tank",{pumpId:pumpId,nute:e.currentTarget.value})}
        >
        {Object.keys(no).map((nuteId,i)=>{
          return(
            <option key={i} value={nuteId}>{no[nuteId].t}</option>
          )
        })}
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }
  
  showPumps=()=>{
/*show all the pumps for the selected tank
also, fill in the nute for each, where we have it.*/    
//       {Object.keys(this.state.tankPumps[this.state.tankSel]).map(tp=>{
//         cl(tp)
//       })}
//     cl(this.state.tankSel)
//     let po=this.state.pumpOpts[this.state.tankSel]
    let po=this.state.pumpOpts[this.state.tankSel]
    if(!po){return null}
//     cl(po)
    return(
      <div>
      {Object.keys(po).map((pumpId,i)=>{
//         cl(po[pumpId])
        return(
          <div key={i} className="line">
          {po[pumpId].name}
            {this.showNutrientSelect(i, pumpId)}
          </div>
        )
      })}
      </div>
    )
  }
  
  render(){
    if(this.state.loaded){
    return(
      <div id="iDoser_tanks">
          <div className="clearfloat"></div>
              {this.showTankSelect()}


              <br /><hr /><br />

              <h2>Pumps</h2>
              {this.showPumps()}

              <div className="clearfloat"></div>
              <br /><br /><br />

      </div>
    )
    }else{return<div>loading. . .</div>}
  }
}

export default Tanks ;

