import React from 'react';
import {cl, globs, constant, getTime} from '../../components/utils/utils';

class C18CustomSelect00 extends React.Component{
  constructor(props) {
    super(props);
//     this.state={
//       loaded: false,
//       addEmail:"",
//       update:0,
//       showPopup: false,
//     }
  }
//         <select 
//           value={this.state.operators[ind]}
//           onChange={e=>this.onChange2("operator",ind,e)}
//         >
//         {this.makeOptions(this.operators)}
//         </select>
  
  
  render(){
    return(
      <span className="custom-select">
        {React.createElement("select",this.props)}
        <span className="material-icons down-arrow">keyboard_arrow_down</span>
      </span>
    )
  }
}
      
export default C18CustomSelect00;
