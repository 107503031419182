import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {cl,globs} from '../../components/utils/utils';

class C18MuiPickersUtilsProvider extends React.Component{
  constructor(props) {
    super(props);
// //     cl(props)
    this.state={
    }
  }
  
  render(){
    if(globs.noMui){
      return this.props.children
//       (
//         <div>
//           {this.props.children}
//         </div>
//       )
    }else{
      return React.createElement(MuiPickersUtilsProvider,this.props)
    }
  }
}
      
export default C18MuiPickersUtilsProvider;
 
