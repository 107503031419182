import React from 'react';
import {cl, globs, userSpecificHome} from '../../components/utils/utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';// import ConfTextField from './ConfTextField';
import TextInput from './TextInput';
import Password from './Password';
import UsaButton from './UsaButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Input from "@material-ui/core/Input";
import InputLabel from '@material-ui/core/InputLabel';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import {doGetPost, constant, openWebSocket, sendWebSocketMessage, webSocketTransaction,
  initWebSocket, saveTokens, wsTrans, validEmail, getHomeDashboard,
} from '../utils/utils';
import ReCAPTCHA from "react-google-recaptcha";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {loadSitesInfo,loadZonesInfo,loadUser} from './C18utils'
import {dbVals} from '../../components/utils/http';
import history from "../../history"
// import MainBar from '../../components/MainBar';

class Login extends React.Component{
  constructor(props) {
    super(props);
    cl("login constructor")
    
    this.values={showPassword: true}
    document.title="Login";
    this.state={
      email: "nohel89982@diide.com",//"LabSite2@grr.la",
      password: "password",
//       email: "",
//       password: "",
      passwordType: "password",
      rememberMe: false,
      notActive: false,
      alertSeverity: "info",
      alertMessage: "",
      alreadyLoggedInMessage: "",
    }
  }
  
  doLogin=async(force=false)=>{
    cl("login");
    wsTrans("usa", {cmd: "cRest", uri: "/o/users/login", method: "create", 
      body: {
        email: this.state.email,
        password: this.state.password,
        rememberMe: this.state.rememberMe,
        force: force,// if logged in elsewhere
      }}).then(async r=>{
      cl(r);
      switch(r.result){
        case "ok":
          dbVals=null
          globs=null
          saveTokens(r.data.accessToken, r.data.refreshToken, r.session)
          switch(globs.userData.mode){
            case "c18":
              cl(globs)
              await loadSitesInfo()//.then(r=>{cl(globs.sitesInfo)})
              cl(globs.sitesInfo.info)
              await loadZonesInfo()//.then(r=>{cl(globs.zonesInfo)})
              await loadUser()
              history.push("/usa/c18/sites")

              
              break
            default:
              let dash=await getHomeDashboard()
              if(dash){history.push(`/usa/dash/${dash}`)}
              break
          }
// //           userSpecificHome()
          break
        case "alreadyLoggedIn":
          this.setState({alreadyLoggedInMessage:"You're already logged into this account somewhere else"})
          break
        default:
          break
      }
//       if(r.result=="ok"){
//         switch(globs.userData.mode){
//           case "c18":
//             history.push("")
//             break
//           default:
//             break
//         }
// //         history.push('/usa/dash/vz4sci_m-iHC9nQZ');
//       }

    });
  }
  
  butLogin=()=>{
/* When Login is clicked, we send the info to the server.
this can come back as success or failure: success will return access and refresh tokens, "result"
may be "success", "notFound", "notActivated"
the users endpoint:
RETRIEVE /o/users/activate: receive login tokens on success: result: ok, or result: notFound
CREATE /o/users/register: submit info, result: ok - probably always!
RETRIEVE /o/users/login {email: email, password: password} login tokens, or result: notFound, or notActivated

need to be able to check for Register: unique email, company name, password OK
RETRIEVE /o/users/unique {email: someAddress}, email, companyName, password
*/
  this.doLogin(false)
//     initWebSocket().then(
//       r=>{
//         let body = {
//           email: this.state.email,
//           password: this.state.password,
//           rememberMe: this.state.rememberMe,
//         };
//         let msgLogin = {cmd: "cRest", uri: "/o/users/login", method: "create", body: body};
//         cl(msgLogin);
//         webSocketTransaction("usa", msgLogin).then(
//           r=>{
//             cl(r);
//             saveTokens(r.data.accessToken, r.data.refreshToken, r.session)
//             history.push('/usa/userProfile');
//           }, 
//           e=>{cl(e)}
//         );
//       },
//       e=>{}
//     );
  }

  showButton=(props)=>{
    return(
      <Button onClick={props.onClick}
      style={{margin: 10}}
      variant="contained"
      color="primary"
      id={props.type}
      className={"button"}>
      {props.text}
      </Button>
    );
  }
  
  onCBChange=(e)=>{
//     cl(e.currentTarget.checked);
    this.setState({rememberMe: e.currentTarget.checked});
    
  }
  
  checkBox=(props)=>{
//     cl("check");
    return(
      <div>
        <FormControlLabel
        control={
          <Checkbox
          checked={this.state[props.field]}
          onChange={this.onCBChange}
          name="checkedB"
          color="primary"
          />
        }
        label={props.title}
        />    
      </div>
    );
  }
  
  onIFChange=(e)=>{
    let key = e.currentTarget.id.substr(3);
    let val = e.currentTarget.value;
    this.setState({[key]: val});
    setTimeout(this.validate, 0);
  }
  
  inputField=(props)=>{
    return(
      <div style={{padding: 10}}>
      <InputLabel htmlFor="unknown">{props.title}</InputLabel>
      <Input
      onChange={this.onIFChange}
      value={this.state[props.field]}
      id={"if-" + props.field}
      />
      </div>
    );
  }

  handleClickShowPassword=()=>{
    this.setState({passwordType: (this.state.passwordType === "password") ? "text" : "password"});
  }
  
  inputPassword=(props)=>{
    return(
      <div style={{padding: 10}}>
      <InputLabel htmlFor="unknown">{props.title}</InputLabel>
      <Input
        onChange={this.onIFChange}
        type={this.state.passwordType}
        value={this.state[props.field]}
        id={"if-" + props.field}
        endAdornment={
          <InputAdornment position="end">
          <IconButton
          aria-label="toggle password visibility"
          onClick={this.handleClickShowPassword}
          onMouseDown={this.handleMouseDownPassword}
          edge="end"
          >
          {this.state.passwordType !== "password" ? <Visibility /> : <VisibilityOff />}
          </IconButton>
          </InputAdornment>
        }
      />
      </div>
    );
  }
  
  validEmail=()=>{
    let email = this.state.email;
    let atPos = email.indexOf("@");
    let perPos = email.lastIndexOf(".");
    return ((atPos > 0) && (perPos > atPos));
  }
  
  doForgotPassword=(email)=>{
    return new Promise((r, e)=>{
      initWebSocket().then(
        re=>{
          let body={email: email};
          let msgForgot = {cmd: "cRest", uri: "/o/forgot", method: "create", body: body};
          webSocketTransaction("usa", msgForgot).then(res=>{
            r(res);
          });
        });
    });
  }
  
  forgotPassword=()=>{
    if(this.validEmail()){
      cl("do forgot");
      this.doForgotPassword(this.state.email).then(
        r=>{
          cl(r);
        }
      );;
      this.setState({alertMessage: "A Password Reset Email has been sent.", alertSeverity: "success"});
    }else{
      this.setState({alertMessage: "Please fill in a valid Email Address", alertSeverity: "warning"});
    };
    cl("forgot");
  }
  
  link=(props)=>{
    return(
      <div style={{padding: 10}}>
      <a href="#" onClick={props.onClick}>{props.title}</a>
      </div>
    );
  }
  
  postRegister=(name, email, token)=>{
    globs.npi={name: name, email: email, token: token};
    cl(globs.npi);
    history.push('/usa/postRegister');
  }
  
  resendActivation=()=>{
    cl("resend");
    let body={email: this.state.email};
    let getActivate={cmd: "cRest", uri: "/o/users/register", method: "update", body: body};
    wsTrans("usa", getActivate).then(r=>{
      this.postRegister(r.data.name, r.data.email, r.data.token);
//       postRegister
      cl(r);
    });
  }
  
  checkActivate=()=>{
    if(validEmail(this.state.email)){
      let body={email: this.state.email};
      let getActivate={cmd: "cRest", uri: "/o/users/register", method: "retrieve", body: body};
      wsTrans("usa", getActivate).then(r=>{
        if(r.data){
          this.setState({notActive: true, alertMessage: "That address has not been activated"});
        }else{
          this.setState({notActive: false});
        }
        cl(r);
      });
    }
  }
/* with every keystroke in email, we want to reset the time to wait 1 second, and then
check to see if the email has not been activated*/
  
  
  setStateValue=(id, value)=>{
    let val={};
    val[id]=value;
    this.setState(val);
    if(id==="email"){
      clearTimeout(this?.emailTO);
      this.emailTO = setTimeout(this.checkActivate, 1000);
      if(!validEmail(value)){
        this.setState({alertMessage: "That's not a Valid Email"});
      }else{
        this.setState({alertMessage: ""});
      }
        
    }
//     if((id==="password1") || (id==="password2")) this.checkPasswords(id, value);
  }
  
  
  
  login=()=>{
    return(
      <div style={{width: 350, borderRadius: 20, textAlign: "center", backgroundColor: "lightblue", padding: 20, boxShadow: "5px 5px 10px #9E9E9E"}}>
      <form>
      <h3>Login</h3>
      <TextInput title="Email" valueId="email" onChange={this.setStateValue}
      initValue={this.state.email}/>
      <Password title="Password" valueId="password" onChange={this.setStateValue}
      initValue={this.state.password}/>
      
      {this.checkBox({title: "Remember Me", field: "rememberMe"})}
      {this.link({title: "Forgot Password", onClick: this.forgotPassword})}
      {this.state.alertMessage &&
        <Alert severity={this.state.alertSeverity}>{this.state.alertMessage}</Alert>
      }
      {(!this.state.notActive) && this.showButton({onClick: this.butLogin, type:"login_button", text: "Login"})}
      {(this.state.notActive) && this.showButton({onClick: this.resendActivation, type:"resend_activation", text: "Resend Activation"})}
      </form>
      </div>
    );
  }
  
  doLogout=()=>{
    this.setState({alreadyLoggedInMessage:""})
    this.doLogin(true)
  }
  
  popUp=()=>{
    if(this.state.alreadyLoggedInMessage==""){
      return null
    }else{
      return(
        <div style={{position: "absolute", left: 200, top: 200, width: 200, 
          padding: 20, borderRadius: 20, boxShadow: "20px 20px 20px #808080",
          backgroundColor: "#CCFFCC"}}>{this.state.alreadyLoggedInMessage}
          <div/>
          <UsaButton
          text="OK"
          inline="true"
          onClick={x=>this.setState({alreadyLoggedInMessage:""})}
          />
          <UsaButton
          text="Force"
          inline="true"
          onClick={this.doLogout}
          />
          </div>
      )
    }
    
  }
  
  render(){
    return(
      <div>
      {this.login()}
      {this.popUp()}
      </div>
    );
  }
}
      
export default Login;
