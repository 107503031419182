import React from 'react';
import C18Button00 from '../../usa/components/C18Button00'
import C18TabsHeader00 from '../../usa/components/C18TabsHeader00'
import C18Input00 from '../../usa/components/C18Input00'
import UsaIcon from '../../usa/components/UsaIcon';
import {acctFeature} from '../../usa/components/C18utils'
import {loadUsersInfo} from '../../usa/components/C18utils';
import {wsTrans} from '../../usa/utils/utils'
import {cl, constant,globs,getTime,getRandomString,dateToDisplayDate} 
  from '../../components/utils/utils';

class SetDefaults extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      showSR:false,
      tab:"save",
      saveName:"Defaults",
      loaded:false,
      selConfig:-1,
    }
    this.tabs=[
    {v:"save",t:"Save"},
    {v:"restore",t:"Restore"},
    ]
    this.props.getValue(props.ind, {type: "local"})
  }
  
  loadInfo=async()=>{
//     cl("load Info")
    await loadUsersInfo()
    let user=globs.usersInfo.info.filter(
      u=>{return u.userId==globs.userData.session.userId})[0]
//     cl(user)
    let query={pageType:this.props.type}
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/fuiDefaults", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: query})
    let defConfigs=resp.data
    if(user.p3){
      query.accountId=user.p3AccountId
//       cl(query)
      let resp2=await wsTrans("usa", {cmd: "cRest", uri: "/tp/tpFuiDefaults", 
        method: "retrieve", 
        sessionId: globs.userData.session.sessionId, body: query})
//       cl(resp2)
      defConfigs=defConfigs.concat(resp2.data)
    }
//     cl(defConfigs)
//     cl(resp)
    this.setState({loaded:true,defs:defConfigs})
  }
  
  saveScalar=(defs,v,c)=>{
//     cl([v,c])
    defs.push({i:c.pid,d:v.value})
  }
  
  saveArray=(defs,va,c)=>{
    va.value.forEach((v,i)=>{
      defs.push({i:c.pid+i,d:v})
    })
//     cl("arr")
//     cl([va,c])
  }
  
  saveSettings=async()=>{
// if the user is a 3rd party, then save to *her* account, not current account
//     cl(this.props)
    cl(globs)
    let settings=await this.props.rest.parent({uri:"getSettings"})
//     cl(settings)
    let defs=[]
    settings.values.forEach((v,i)=>{
      let c=settings.controls[i]
      switch(v.type){
        case "scalar":
          this.saveScalar(defs,v,c)
          break
        case "array":
          this.saveArray(defs,v,c)
          break
      }
    })
//     cl(defs)
    let defaultId=getRandomString(16)
    let now=Math.floor(getTime())
    let query={defaultId:defaultId,pageType:this.props.type,name:this.state.saveName,
      ts:now,defs:defs}
    let defs2=this.state.defs.slice(0)
    defs2.push(query)
    let user=globs.usersInfo.info.filter(
      u=>{return u.userId==globs.userData.session.userId})[0]
    cl(user)
    if(user.p3){
      query.accountId=user.p3AccountId
      cl(query)
      let resp=await wsTrans("usa", {cmd: "cRest", uri: "/tp/tpFuiDefaults", method: "create", 
        sessionId: globs.userData.session.sessionId, body: query})
    }else{
//       cl(defs2)
//       cl(query)
      let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/fuiDefaults", method: "create", 
        sessionId: globs.userData.session.sessionId, body: query})
    }
    this.setState({defs:defs2})
  }
  
  deleteConfig=async()=>{
//     cl(this.state)
    let res=await this.props.getPopup({text:"Are you sure you want to delete \
this Configuration?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
      let defs=st.defs.slice(0)
      for(let i=0;i<defs.length;i++){
        if(defs[i].defaultId==st.selConfig){
          defs.splice(i,1)
        }
      }
      let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/fuiDefaults", method: "delete", 
        sessionId: globs.userData.session.sessionId, body: {defaultId:st.selConfig}})
      this.setState({defs:defs,selConfig:-1})
    }
  }
  
  restoreConfig=async()=>{
    cl("do restore config")
    let st=this.state
//     cl(st)
    let def=st.defs.filter(de=>{return de.defaultId==st.selConfig})[0]
//     cl(def)
    let idToVal={}
    def.defs.forEach(de=>{
      idToVal[de.i]=de.d
    })
//     cl(idToVal)
    let settings=await this.props.rest.parent({uri:"getSettings"})
    let vals=settings.values.slice(0)
    cl(vals)
    vals.forEach((v,i)=>{
      let c=settings.controls[i]
      switch(v.type){
        case "scalar":
          v.value=idToVal[c.pid]
          break
        case "array":
          for(let i=0;i<v.value.length;i++){
            v.value[i]=idToVal[c.pid+i]
          }
          break
      }
    })
    cl(vals)
    await this.props.rest.parent({uri:"setValues",values:vals})
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "setDefaults":
        this.loadInfo()
        this.setState({showSR:true})
        break
      case "srClose":
        this.setState({showSR:false})
        break
      case "tabs":
        this.setState(vals)
        break
      case "saveName":
      case "selConfig":
        this.setState(vals)
        break
      case "saveSettings":
        this.saveSettings()
        break
      case "deleteConfig":
        this.deleteConfig()
        break
      case "restoreConfig":
        this.restoreConfig()
        break
    }
  }
  
  showSave=()=>{
    let desc="Save current settings with today's date, and the following name:"
    return(
      <div>
        <p>{desc}</p>
        <label htmlFor="save-name">Configuration Save Name</label>
        <C18Input00 type="text" id="save-name" className="with-right-button"
          value={this.state.saveName}
          onChange={e=>this.onChange("saveName",{saveName:e.currentTarget.value})}
        />
        <C18Button00 type="button" className="filled"
        onClick={()=>this.onChange("saveSettings",{})}>Save</C18Button00>
      </div>
    )
  }
  
  showSaveHead=()=>{
    return(
      <tr>
      <th>Name</th>
      <th>Date</th>
      </tr>
    )
  }
  
  showSaveList=()=>{
    let st=this.state
//     cl(st)
    let lines=st.defs.map((sa,i)=>{
//       cl(sa)
      let da=new Date(1000*sa.ts)
      let da2=`${dateToDisplayDate(da,"mm/dd/yyyy")} ${dateToDisplayDate(da,"hh:mm")}`
      let bgColor=(sa.defaultId==st.selConfig)?"#CCFFFF":"#FFFFFF"
      return(
        <tr key={i} style={{cursor:"pointer",backgroundColor:bgColor}}
          onClick={e=>this.onChange("selConfig",{selConfig: sa.defaultId})}
        >
        <td>{sa.name}</td>
        <td>{da2}</td>
        </tr>
      )
    })
    return(
      <div style={{padding:20,
        borderStyle:"solid",borderWidth:1,borderRadius:10}}>
        <table style={{width:"100%"}}><tbody>
        {this.showSaveHead()}
        {lines}
        </tbody></table>
      </div>
    )
  }
  
  showRestore=()=>{
    let disabled=(this.state.selConfig==-1)
    return(
      <div>
        <h3>Saved Configurations</h3>
        {this.showSaveList()}
        <div className="clearfloat"/><br/>
        <C18Button00 type="button" className="danger"
        disabled={disabled}
        onClick={e=>this.onChange("deleteConfig")}>Delete</C18Button00>
        
        <C18Button00 type="button" className="filled" disabled={disabled}
        style={{marginLeft:20}}
        onClick={()=>this.onChange("restoreConfig",{})}>Restore</C18Button00>
      </div>
    )
  }
  
  showSaveRestore2=()=>{
    if(this.state.tab=="save"){
      return this.showSave()
    }else{
      return this.showRestore()
    }
//     return(
//       <div>SAve Restore</div>
//     )
  }
  
  showSaveRestore=()=>{
    let disp=(this.state.showSR)?"block":"none"
    return(
      <div className="image-popup" style={{
        backgroundColor:"#FFFFFF",
        width:500,
        display:disp,
        borderRadius:10,
        padding:20,
        boxShadow:"0px 4px 24px rgba(0, 0, 0, 0.16)",
      }}>
        <div className="image-close-button">
          <UsaIcon icon={`sr-Cancel`} result={o=>this.onChange("srClose",o)}/>
        </div>
        <h3>Save and Restore Defaults</h3>
          <div className="tabs">
            <C18TabsHeader00
              tabId={this.state.tab}
              tabs={this.tabs}
              onChange={o=>this.onChange("tabs",o)}
            />
            <div className="tab-panels">
              <div className="tab-panel selected" role="tabpanel">
                {this.showSaveRestore2()}
                <div className="clearfloat"></div>
              </div> 
            </div>
          </div>
      </div>
    )
  }

  render(){
    if(!acctFeature("saveDefaults")){return null}
    return(
      <div>
        <C18Button00 type="button" className="filled"
        onClick={()=>this.onChange("setDefaults",{})}>Access Defaults</C18Button00>
        <div className="clearfloat"/><br/>
        {this.showSaveRestore()}
      </div>
    )
  }
}

  export default SetDefaults ;
