import React from 'react';
import {wsTrans} from '../../usa/utils/utils'
import {cl, globs, constant} from '../../components/utils/utils';
import {checkNewSite} from '../../components/utils/ws';
import history from '../../history'

class Site00 extends React.Component{
/* this gets the size in parms w, h. assume a 5px padding*/  
  constructor(props) {
    super(props);
    this.state={fake: 0}
//     cl(props)
    props.notify({id: "drag", func: this.onDrag})
  }
  
//   componentDidMount=()=>{
//     cl("did mount")
//   }
  
  componentWillUnmount=()=>{
    this.props.notify({id: "drag", func: this.onDrag, unMount: true})
  }
  
  
  onDrag=(o)=>{// o has the updated info
//     cl(o)
    if("dragStart" in o){
      this.setState(o)
    }else{
      if(this.state.dragStart.i==this.props.parms.i){
        this.setState(o)
      }
    }
  }
  
  cm=(e)=>{
    this.props.parms.cm({e: e, i: this.props.parms.i})
  }
  
  md=(e)=>{
    this.props.parms.md({e: e, i: this.props.parms.i})
  }
  
  onClick=async()=>{
    if(this.props.parms.e) {return}// edit mode
//     cl(this.props.parms.wdg.parms.site)
    let siteId=this.props.parms.wdg.parms.site
    globs.userData.session.siteId = siteId
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId: siteId}})
    cl(res.data)
    let sessionUpdate={siteId: siteId, weatherStation: res.data[0].weatherStation}

    await wsTrans("usa", {cmd: "cRest", uri: "/s/sessions", method: "update", 
      sessionId: globs.userData.session.sessionId, body: sessionUpdate})
    
    globs.userData.session=Object.assign(globs.userData.session, sessionUpdate)
    cl(globs.userData.session)
    await checkNewSite()
    let url=this.props.parms.wdg.parms.link
//     cl(url)
    history.push(url)
  }
  
  render() {
    let p = this.props.parms;
    let p2=p.wdg.parms
    let dm=5
    let color=p2.bgColor
    let x=p.x+dm
    let y=p.y+dm
    if(this?.state?.dragStart?.i==this.props.parms.i){
      x+=this.state.drag.x-this.state.dragStart.x
      y+=this.state.drag.y-this.state.dragStart.y
    }
    let style={position: "absolute", left: x, top: y, width: p.w-2*dm, height: p.h-2*dm, textAlign: "center",
          paddingTop: 0, borderRadius: 10, backgroundColor: color}
    if(p2.backImage){
      let bg=p2.imgName
      let path=`${constant.expressUrl}/usa/images/backgrounds/uploads/${bg[0]}/${bg[1]}/${bg[2]}/${bg.substr(3)}`
      style.backgroundImage=`url("${path}")`
      style.backgroundSize="100% 100%"
    }else{}
    return(
        <div style={style} onClick={this.onClick}
          onContextMenu={this.cm} onMouseDown={this.md}>
          <div style={{textAlign: "center", fontSize: .12*p.w, padding: .02*p.w}}>{p2.title}</div>
        </div>
      );
  }
}

export default Site00;
