import React from 'react';
import {cl} from '../../components/utils/utils';
import { ResponsiveHeatMap } from '@nivo/heatmap'

class C18HeatMap extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.state={
    }
    this.testData =
    [
      {
        "id": "Japan",
        "data": [
          {
            "x": "Train",
            "y": 48923
          },
          {
            "x": "Subway",
            "y": 3900
          },
          {
            "x": "Bus",
            "y": 8871
          },
          {
            "x": "Car",
            "y": -69950
          },
          {
            "x": "Boat",
            "y": -3189
          },
          {
            "x": "Moto",
            "y": -80623
          },
          {
            "x": "Moped",
            "y": -96123
          },
          {
            "x": "Bicycle",
            "y": 60443
          },
          {
            "x": "Others",
            "y": -93374
          }
        ]
      },
      {
        "id": "France",
        "data": [
          {
            "x": "Train",
            "y": -93404
          },
          {
            "x": "Subway",
            "y": -37524
          },
          {
            "x": "Bus",
            "y": 75464
          },
          {
            "x": "Car",
            "y": 46966
          },
          {
            "x": "Boat",
            "y": -8621
          },
          {
            "x": "Moto",
            "y": 70153
          },
          {
            "x": "Moped",
            "y": 51448
          },
          {
            "x": "Bicycle",
            "y": 29766
          },
          {
            "x": "Others",
            "y": -99808
          }
        ]
      },
      {
        "id": "US",
        "data": [
          {
            "x": "Train",
            "y": 20334
          },
          {
            "x": "Subway",
            "y": 19092
          },
          {
            "x": "Bus",
            "y": 88504
          },
          {
            "x": "Car",
            "y": -57430
          },
          {
            "x": "Boat",
            "y": -81086
          },
          {
            "x": "Moto",
            "y": 95057
          },
          {
            "x": "Moped",
            "y": 24288
          },
          {
            "x": "Bicycle",
            "y": -56249
          },
          {
            "x": "Others",
            "y": 35314
          }
        ]
      },
      {
        "id": "Germany",
        "data": [
          {
            "x": "Train",
            "y": 59971
          },
          {
            "x": "Subway",
            "y": 69853
          },
          {
            "x": "Bus",
            "y": -76673
          },
          {
            "x": "Car",
            "y": -33216
          },
          {
            "x": "Boat",
            "y": 17488
          },
          {
            "x": "Moto",
            "y": 85420
          },
          {
            "x": "Moped",
            "y": -41310
          },
          {
            "x": "Bicycle",
            "y": -30985
          },
          {
            "x": "Others",
            "y": 58958
          }
        ]
      },
      {
        "id": "Norway",
        "data": [
          {
            "x": "Train",
            "y": 30100
          },
          {
            "x": "Subway",
            "y": -85442
          },
          {
            "x": "Bus",
            "y": -30897
          },
          {
            "x": "Car",
            "y": 66736
          },
          {
            "x": "Boat",
            "y": 75767
          },
          {
            "x": "Moto",
            "y": -69954
          },
          {
            "x": "Moped",
            "y": 63636
          },
          {
            "x": "Bicycle",
            "y": -48647
          },
          {
            "x": "Others",
            "y": -32487
          }
        ]
      },
      {
        "id": "Iceland",
        "data": [
          {
            "x": "Train",
            "y": -538
          },
          {
            "x": "Subway",
            "y": 87983
          },
          {
            "x": "Bus",
            "y": -92095
          },
          {
            "x": "Car",
            "y": -35500
          },
          {
            "x": "Boat",
            "y": 65971
          },
          {
            "x": "Moto",
            "y": -61326
          },
          {
            "x": "Moped",
            "y": 99941
          },
          {
            "x": "Bicycle",
            "y": 56760
          },
          {
            "x": "Others",
            "y": 20174
          }
        ]
      },
      {
        "id": "UK",
        "data": [
          {
            "x": "Train",
            "y": 16318
          },
          {
            "x": "Subway",
            "y": -55454
          },
          {
            "x": "Bus",
            "y": -94447
          },
          {
            "x": "Car",
            "y": -88151
          },
          {
            "x": "Boat",
            "y": -66313
          },
          {
            "x": "Moto",
            "y": 15142
          },
          {
            "x": "Moped",
            "y": -84752
          },
          {
            "x": "Bicycle",
            "y": -90461
          },
          {
            "x": "Others",
            "y": -21526
          }
        ]
      },
      {
        "id": "Vietnam",
        "data": [
          {
            "x": "Train",
            "y": -13375
          },
          {
            "x": "Subway",
            "y": -407
          },
          {
            "x": "Bus",
            "y": 43929
          },
          {
            "x": "Car",
            "y": 33807
          },
          {
            "x": "Boat",
            "y": -85578
          },
          {
            "x": "Moto",
            "y": 17973
          },
          {
            "x": "Moped",
            "y": -29853
          },
          {
            "x": "Bicycle",
            "y": -67480
          },
          {
            "x": "Others",
            "y": -99507
          }
        ]
      }
  	]
  }

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
 	showResponsiveHeatMap = ( data /* see data tab */ ) => {
  		data = this.testData
  		return (
	    <ResponsiveHeatMap
        data={data}
        margin={{ top: 60, right: 90, bottom: 60, left: 90 }}
        valueFormat=">-.2s"
        axisTop={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -90,
            legend: '',
            legendOffset: 46
        }}
        axisRight={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'country',
            legendPosition: 'middle',
            legendOffset: 70
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'country',
            legendPosition: 'middle',
            legendOffset: -72
        }}
        colors={{
            type: 'diverging',
            scheme: 'red_yellow_blue',
            divergeAt: 0.5,
            minValue: -100000,
            maxValue: 100000
        }}
        emptyColor="#555555"
        legends={[
            {
                anchor: 'bottom',
                translateX: 0,
                translateY: 30,
                length: 400,
                thickness: 8,
                direction: 'row',
                tickPosition: 'after',
                tickSize: 3,
                tickSpacing: 4,
                tickOverlap: false,
                tickFormat: '>-.2s',
                title: 'Value →',
                titleAlign: 'start',
                titleOffset: 4
            }
        ]}
    />
		)
	}
  
  render(){
    return  (
		<div style={{height:"500px"}}>
    		{this.showResponsiveHeatMap(this.props.data)}
    	</div>
	)
  }
}

export default C18HeatMap;
 
