import React from 'react';
import CravoInput from './CravoInput'
import {cl, globs, leadZeros} from '../../components/utils/utils';

const cream="#f5f5dc"
const aqua="#00ffff"
const yellow="#fffacd"
const green="#98fb98"

class CravoSetpoint1 extends React.Component{
  constructor(props) {
    super(props);
//     that.state=Object.assign(that.props.parms,that.props.values)
  }

//   onChange=(that,valueId, e, ind)=>{
//     let val=e.currentTarget.value
//     let vals={}
//     if(that.isArray(valueId)){
//       let arr=that.state[valueId].slice(0)
//       arr[ind]=val
//       vals[valueId]=arr
//     }else{
//       vals[valueId]=val
//     }
//     that.props.onChange(vals)
//     that.setState(vals)
//   }
  
  isArray=(valueId)=>{
    return ["pos","thresh"].includes(valueId)
  }
  
  cravoInput=(that,dataGroup,dgInd,label, color, valueId, ind)=>{
    var val
//     cl(that)
//     cl(dataGroup)
    if(this.isArray(valueId)){
      val=that[dataGroup][dgInd][valueId][ind]
    }else{
      val=that[dataGroup][dgInd][valueId]
    }
    return (
      <CravoInput
        label={label}
        color={color}
        value={val}
        valueId={valueId}
        ind={ind}
        onChange={(valueId, ind, e)=>{that.onChange(valueId, ind, e, dataGroup, dgInd)}}
      />
    )
  }
  
  showCravoFields=(that,dataGroup,dgInd,color, i)=>{
    let thresh=(dataGroup=="humidityIncrease")?"%":"\u00B0F"
    switch(i){
      case 0:
        return [
          <td key={color+0}>{this.cravoInput(that,dataGroup,dgInd,"min", color, "upTime", 0)}</td>,
          <td key={color+1}>{this.cravoInput(that,dataGroup,dgInd,"min", color, "downTime", 0)}</td>,
          <td key={color+2}>{this.cravoInput(that,dataGroup,dgInd,"%", color, "pos",  i)}</td>,
          <td key={color+3}>{this.cravoInput(that,dataGroup,dgInd,thresh, color, "thresh", i)}</td>,
          <td key={color+4}>{this.cravoInput(that,dataGroup,dgInd,thresh, color, "deadband", 0)}</td>
        ]
      default:
        return [
          <td key={color+0}></td>,
          <td key={color+1}></td>,
          <td key={color+2}>{this.cravoInput(that,dataGroup,dgInd,"%", color, "pos",  i)}</td>,
          <td key={color+3}>{this.cravoInput(that,dataGroup,dgInd,"\u00B0F", color, "thresh", i)}</td>,
          <td key={color+4}></td>
        ]
    }
  }
  
  render(){
//     cl(that.state)
    let p=this.props.parms
    let that=p.that
    let color=p.color
    let dataGroup=p.dataGroup
    let dgInd=p.dgInd
//     cl(dgInd)
    let i=p.i
    return this.showCravoFields(that,dataGroup,dgInd,color,i)
  }
}

export default CravoSetpoint1 ;
