import React from 'react';
// import { Wrapper, Status } from "@googlemaps/react-wrapper";
// import C18Input00 from './C18Input00'
// import C18Select00 from './C18Select00'
// import C18Select01 from './C18Select01'
// import C18Button00 from './C18Button00'
// import {wsTrans,doGetPostBasic} from '../utils/utils'
// // import {gdRest} from './Usa'
// import {/*loadSitesInfo,*/loadZonesInfo,loadAccountInfo,loadGatewaysInfo,getGatewayInfo,
//   getGatewayIndex, addToAdminLog, restGd,handleGdMsg,restRespGd,restKeyGd,restArrGd,
//   gdRest,privs,loadUsersInfo} from './C18utils'
import {cl, globs, constant, getRandomString, getTime} from '../../components/utils/utils';
import history from "../../history"

class C18PhysView00 extends React.Component{
  constructor(props) {
    super(props);
//     this.svgDiv=React.createRef();
    this.state={
      loaded: false,
//       addName:"",
//       addId:"",
//       eqSel:"",
//       diType:"",
//       dp1Sel:"",
//       dp2Sel:"",
//       dpName:"",
    }
//     this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveGateways)
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
//     this.loadInfo()
//     this.setBreadCrumbs()
//     setTimeout(this.setMap,3000)
  }
  
  render(){
    let st=this.state
    if(st.loaded){
      return (
        <div>
        physical view
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
export default C18PhysView00;
