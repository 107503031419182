import React from 'react';
import UsaIcon from './UsaIcon';
import UsaSelect00 from './UsaSelect00';
import {cl} from '../../components/utils/utils';

class Defaults00 extends React.Component{
  constructor(props) {// 
    super(props);
    //     cl(props)
    this.state=Object.assign({}, props.parms.vals)
    this.state.zone=this.state.zone//(this.state.zone) ? this.state.zone : null
    this.state.chan=this.state.chan//(this.state.chan) ? this.state.chan : null
    this.state.ind=this.state.ind//(this.state.ind) ? this.state.ind : null
    this.state.editMode=false
  }
  
  saveRestoreDefaults=(cmd)=>{
    switch(cmd){
      case "save":
        this.back=this.state
        break
      default:
        let b=this.back
        this.setState({zone: b.zone, chan: b.chan, ind: b.ind})
        break
    }
  }
  
  setVals=()=>{
    let ret={}
    ret.zone=this.state.zone//(this.state.zone) ? this.state.zone : null
    ret.chan=this.state.chan//(this.state.chan) ? this.state.chan : null
    ret.ind=this.state.ind//(this.state.ind) ? this.state.ind : null
    this.props.onChange(ret)
  }
  
  result=(type)=>{
    //     cl(type)
    switch(type){
      case "defaults-OK":
        this.props.onChange(this.state)
        this.setVals()
        this.setState({editMode: false})
        break
      case "defaults-Cancel":
        this.saveRestoreDefaults("restore")
        this.setState({editMode: false})
        break
      case "defaults-Edit":
        this.saveRestoreDefaults("save")
        this.setState({editMode: true})
        break
      default:
        break
    }
  }
  
  editPosition=()=>{
    return(
      <div>
      <UsaIcon icon="defaults-OK" result={this.result} inline/>
      <UsaIcon icon="defaults-Cancel" result={this.result} inline/>
      <UsaIcon icon="defaults-Edit" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Defaults:</h3></span>
      <div></div>
      <UsaSelect00 parms={{
        title: "Zone",
        width: 100,
        inline: true,
        value: this.state.zone,
        valueId: "zone",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Channel",
        width: 100,
        inline: true,
        value: this.state.chan,
        valueId: "chan",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Index",
        width: 100,
        inline: true,
        value: this.state.ind,
        valueId: "ind",
        onChange: o=>this.setState(o),
      }}/>
      </div>
    )
  }
  
  displayPosition=()=>{
    let s=this.state
    return(
      <div>
      <UsaIcon icon="defaults-Edit" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Defaults:</h3></span>
      {` zone: ${s.zone}, channel: ${s.chan}, index: ${s.ind}`}
      </div>
    )
  }
  
  render(){
    if(this.state.editMode){
      return this.editPosition()
    }else{
      return this.displayPosition()
    }
  }
}

export default Defaults00 ;

