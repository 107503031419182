import React from 'react';
import TextField from '@material-ui/core/TextField';
import UsaCheckbox00 from './UsaCheckbox00';
import UsaIcon from './UsaIcon';
import {cl,az} from '../../components/utils/utils';

class UsaSelect00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
//     this.state={
//       name: "Gene Knight",
//     };
    if(props.parms.multi){
      this.state=props.parms.value
//       Object.keys(props.parms.opts).forEach(k=>{
//         this.state[k]=props.parms.opts[k].v
//       })
    }else{
      this.state={}
    }
    if(props.parms.mode=="muiDuration00"){
      this.state={
        gotFocus:false,
        valueText:this.secsToHMS(this.props.parms.value),
        
      }
    }
    this.state.gotFocus=false
  }
  
// static getDerivedStateFromProps(){cl("getDerivedStateFromProps")}
// shouldComponentUpdate(){cl("shouldComponentUpdate"); return true}
// getSnapshotBeforeUpdate(){cl("getSnapshotBeforeUpdate")}
// componentDidUpdate(){cl(`componentDidUpdate ${this.props.parms.title}`)}
// 
// static getDerivedStateFromError(){cl("getDerivedStateFromError")}
// componentDidCatch(){cl("componentDidCatch")}
// componentDidMount(){cl(`componentDidMount ${this.props.parms.title}`)}

  setOpts=(opts)=>{
//     return<option>no opts!</option>
//     cl(opts)
    if(this.props.parms.select){
      if(Array.isArray(opts)){
//         cl("not")
        if(!opts || !opts.length){
          return<option>no opts!</option>
        }
        return opts.map((o, i)=>{
          return(
            <option key={i} value={o.v}>{o.t}</option>
          )
        })
      }else{
//         cl("object")
        // cl(opts)
        return Object.keys(opts).map((k, i)=>{
//           cl(k)
//           cl(opts[k])
          let t=opts[k]
//           cl(t)
          if(typeof(t)=="object")t=t.t
          return(
            <option key={i} value={k}>{t}</option>
          )
        })
      }
    }
  }
  
  onSelChange=(e)=>{// assuming select mode, for now
    //     this should return an object: {text: text, value: value}
    //     cl(e.target.value)
    let val={}
//     cl(this.props)
// cl(e.target.value)
    val[this.props.parms.valueId]=e.target.value;
    if(this.props.parms.textId){
      if(this.props.parms.select){
        let o = this.props.parms.opts
        for(let i=0; i<o.length; i++){
          if(o[i].v == e.target.value){
            val[this.props.parms.textId]=o[i].t
            break
          }
        }
      }
    }
//     cl(val)
    this.props.parms.onChange(val)
  }
  
  onMultiChange=(o)=>{
    this.setState(o)
    this.props.parms.onChange(o)
//     cl(o)
  }
  
  showRows=()=>{
//     cl(this.props.parms)
    let opts=this.props.parms.opts
//     cl(opts)
    return Object.keys(opts).map((k,i)=>{
//       cl(k)
      return <div key={i}>
        <UsaCheckbox00 parms={{
          title: " " + opts[k],
          inline: true,
          value: this.state[k],
          valueId: k,
//           onChange: o=>this.setState(o),
          onChange: o=>this.setState(o)
        }}
        />
      </div>
      cl(k)
    })
//     return null
  }
  
  result=(type)=>{
    switch(type){
      case "Select-OK":
        this.props.parms.onChange({result: type, vals: this.state})
        break
      case "Select-Cancel":
        this.props.parms.onChange({result: type})
        break
      default:
        break
    }
//     cl(type)
  }
  
  showMultiSelect=()=>{
//     cl(this.props.parms)
    return (
      <div>
      <h3>{this.props.parms.title}
          <UsaIcon space="1" icon={`Select-OK`} result={this.result} inline/>
          <UsaIcon icon={`Select-Cancel`} result={this.result} inline/>
      </h3>
        {this.showRows()}
      </div>
    )
  }
  
  onInpChange=(e)=>{
//     cl(e.currentTarget.value)
    let val=e.currentTarget.value
    if(this.props.parms.type=="number"){
      val=+val
//       cl("int")
    }
//     cl(val)
    let vals={}
    vals[this.props.parms.valueId]=val
    this.props.parms.onChange(vals)
  }
  
  nativeInput=()=>{
//     cl("native")
    let style={marginLeft: 10, marginRight:10, width: this.props.parms.width}
//     let style=(this.props.parms.width) ? {width: this.props.parms.width} : null
//     cl(this.props.parms.value)
    return(
      <input 
        style={style}
        type="number" 
        value={this.props.parms.value}
        onChange={this.onInpChange}
      />
    )
  }
  
  fixPlural=(count,str,flag)=>{
    return(flag)?((count==1)?str.slice(0,-1):str):str
  }
  
  defaultSelect=()=>{
    let val="none"
    let style=(this.props.parms.width) ? {width: this.props.parms.width} : null
    let inProps=(this.props.parms.min)?{inputProps:{min:this.props.parms.min}}:null
    if(this.props.parms.baseline){return this.nativeInput()}
    if(this.props.parms.multi){
      return this.showMultiSelect()
    }else{
      return(
        <div style={{/*padding: 10, */display: (this.props.parms.inline) ? "inline" : "block"}}>
        <TextField
        label={this.props.parms.title}
        select={this.props.parms.select}
        multiline={this.props.parms.multiline}
        rows={this.props.parms.rows}
        rowsMax={this.props.parms.rowsMax}
        value={this.props.parms.value}
        onChange={this.onSelChange}
        type={this.props.parms.type}
        style={style}
        InputProps={inProps}
        SelectProps={{
          native: true,
        }}
        >
        {this.setOpts(this.props.parms.opts)}
        </TextField>
        <div style={{fontSize: 12, fontFamily: "Arial", opacity: 0.54}}>
        {this.fixPlural(this.props.parms.value, this.props.parms.footer, this.props.parms.footerPlural)}
        </div>
        </div>
      );
    }
  }
  
  makeOptions=(opts)=>{
    return Object.keys(opts).map((k,i)=>{
      return (
        <option key={i} value={k}>{opts[k].t}</option>
      )
    })
  }
  
  showSelect=(p)=>{
//     cl(p)
    return(
      <select style={{marginLeft: "0.3em",}}
        value={p.value} 
        onChange={e=>p.onChange(e.currentTarget.value)}
      >
      {this.makeOptions(p.opts)}
      </select>
    )
  }
  
  showInput=(type, val, valId, ind)=>{
    return(
      <input
        value={val}
        type="number"
        onChange={e=>this.onChange(type, valId, ind, e)}
      />
    )
  }
  
  inlineSelect00=()=>{
    return this.showSelect(this.props.parms)
  }
  
  isNumber=(x)=>{
    let y = x*1;
    return(y >= 0) && (y < 60);
  }

  checkParts=(parts)=>{
    let isNum = true;
    let tot = 0;
    for (let i = 0 ; i < parts.length ; i++){
      let p = parts[i]
      if (this.isNumber(p)){
        tot = tot * 60 + p*1;
      }else{
        return -1;
      }
    }
    return tot;
  }

  tryColons=(str)=>{
    let parts=str.split(":");
    return this.checkParts(parts);
  }
  
  hmsToSecs=(hms)=>{
    // cl(hms)
    let parts = hms.split(":")
    let mults = {h: 3600, m: 60, s: 1}
    let tot = 0;
    let isNum = true;
    // cl(parts)
    parts.forEach(p=>{
      if (p!==""){
        let len = p.length
        let num = p.substr(0, len -1)
        isNum &= this.isNumber(num)
        if (!isNum) return -1;
        let typ = p.substr(len-1, 1)
        tot += num * mults[typ]
      }
    })
    return tot;
  }

  tryHMS=(str)=>{
    let strh = str.replace("h", "h:")
        .replace("m", "m:")
        .replace("s", "s:");
    let secs = this.hmsToSecs(strh)
    return secs;
  }

  parseTimeString=(str)=>{
    let secs = this.tryColons(str)
    if (secs < 0) secs = this.tryHMS(str)
    return secs;
  }

  secsToHMS=(secs)=>{
//     cl(secs)
    let h = Math.floor(secs / 3600);
    let m = Math.floor(secs / 60) % 60;
    let s = secs % 60;
    return `${h}:${az(m,2)}:${az(s,2)}`

  }

  onFocus=()=>{
//     cl("onfocus")
    this.setState({gotFocus: true})
  }
  
  onBlur=()=>{
//   cl("onblur")
//   cl(this.state.valueText)
    let secs=this.parseTimeString(this.state.valueText)
    let str=this.secsToHMS(secs)
    this.setState({valueText: str})
    
  }
  
  onChange=(field, e)=>{
    let vals={}
    let str=e.currentTarget.value
//     cl(field)
//       cl(str)
    vals[field]=str
//     cl(vals)
    this.setState(vals)
    let vals2={}
    vals2[this.props.parms.valueId]=this.parseTimeString(str)
    this.props.parms.onChange(vals2)
    
  }
  
  muiDuration00=()=>{
    return (
      <div>
        <TextField
        label={this.props.parms.title}
        value={this.state.valueText}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onChange={e=>this.onChange("valueText",e)}
        type={this.props.parms.type}
        >
        </TextField>
      </div>
      
    )
  }
  
  render(){
//     cl(`render ${this.props.parms.title}`)
//     cl(this.props)
    switch(this.props.parms.mode){
      case "inlineSelect00":
        return this.inlineSelect00()
      case "muiDuration00":
        return this.muiDuration00()
      default:
        return this.defaultSelect()
        break
    }
  }
}

export default UsaSelect00 ;

