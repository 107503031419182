import React from 'react';
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18DateFormat00 from './C18DateFormat00'
import history from "../../history"
import {cl,globs,constant,dateToDisplayDate,displayDateToDate, calcTimezoneOffset} from '../../components/utils/utils';
import {wsTrans} from '../utils/utils'
import { DateRangePicker } from 'react-date-range';
import {loadSitesInfo,loadZonesInfo,loadUsersInfo,loadReportsInfo,loadReportSchedulesInfo} from './C18utils'


class C18EditSchedule extends React.Component{
  constructor(props) {
    super(props);
    let nextDate= new Date()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.onSubmit)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    
    this.makeUsers()
    this.makeReports()
    this.makeReportSchedule()

    this.state={
      userId: globs.userData.session.userId,
      accountId: globs.userData.session.accountId,
      reportId: 0,
      name: "",
      status: false,
      recipients: [],
      period: "daily",
      nextDate: nextDate, //new Date(),
      nextTime: "00:00",
      email: "",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      all_recipients: [],
      all_reports: [],
    }
    this.setBreadCrumbs()
  }

  setBreadCrumbs=()=>{
//     cl(this.props)
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Schedules", url:`/usa/c18/reports/scheduleList`},
              {t:"Edit Schedule", url:`/usa/c18/reports/editSchedule/${this.props.parms.reportScheduleId}`},
            ]},
        },
      )
    }
  }
  
  onChange=(type, val)=> {
    globs.events.publish("savePageEnable",true)
    cl([type, val])
    switch (type){
      case 'name':
        this.setState({name: val})
        break
      case 'email':
        this.setState({email: val})
        break
      case 'nextTime':
        cl(val)
        this.setState({nextTime: val})
        break
      case 'nextDate':
        let d = displayDateToDate(val, new Date(), "yyyy-mm-dd")
        this.setState({nextDate: d})
        break
      case 'period':
        this.setState({period: val})
        break
      case 'report':
        this.makeReport(val)
        break
      case 'status':
        this.setState({status: val})
        break
    }
  }

  onToggle=(type, val) => {
    cl([type, val])
    globs.events.publish("savePageEnable",true)
    let idx
    let recipients
    let deselect
    switch (type){
      case 'recipients':
        recipients = this.state.recipients
        idx = recipients.findIndex((uVal)=>val==uVal)
        if (idx == -1)  {
          recipients.push(val)
        } else {
          recipients.splice(idx, 1)
        }
        this.setState({recipients: recipients})

        if (val == this.state.email) {
          let all_recipients = this.state.all_recipients
          idx = this.state.all_recipients.findIndex((u)=>u.userId==val)
          if (idx == -1) {
            all_recipients.push({
              name: val,
              avatar: "", // make email avatar?
              userId: val,
            })
          } else {
            cl("already in recipient list")
            // show error?
          }
          this.setState({all_recipients: all_recipients, email: ""})
        }
        break
      case 'allRecipients':
        recipients = this.state.recipients
        deselect = (val.length == 0)
        val.forEach((u, i) => {
          recipients.push(u)
        })
        if (deselect) recipients = []
        this.setState({recipients: recipients})
        break
    }
  }

  makeReportSchedule=async()=> {
    await loadReportSchedulesInfo()
    let grsi=globs.reportSchedulesInfo.info
//     cl(grsi)

    let scheduleId = (this.state.reportScheduleId) ? 
      this.state.reportScheduleId : this.props.parms.reportScheduleId
    let schedule = grsi.find((s) => s.reportScheduleId == scheduleId)

    if (schedule) {
//       cl(schedule)

      this.makeReport(schedule.reportId)

      let d = new Date()
      let next_ts = schedule.nextDateTs - calcTimezoneOffset(this.state.timezone)
      let nextDate = new Date(next_ts)
      let nt = this.formatTime(nextDate)

//       cl([nextDate, nt])

      this.setState(schedule)
      this.setState({nextDate: nextDate, nextTime: nt})

      // add email_json members
      let all_recipients = this.state.all_recipients
      let idx
      schedule.recipients.forEach((r) => {
        idx = this.state.all_recipients.findIndex((u)=>u.userId==r)
        if (idx == -1) {
          all_recipients.push({
            name: r,
            avatar: "", // make email avatar?
            userId: r,
          })
        }
      })
      this.setState({all_recipients: all_recipients})
    }    
  }

  makeReports=async()=> {
    await loadReportsInfo()
    let gri=globs.reportsInfo.info
//     cl(gri)
    let reports = []
    gri.forEach(r=>{
      reports.push(
        {
          title: r.title,
          reportId: r.reportId,
          timezone: r.timezone
        }
      )
    })
    this.setState({loaded:true, all_reports:reports})
  }

  makeReport=(reportId)=> {
    let report = this.state.all_reports.find((r) => r.reportId == reportId)
    if (report) {
      // load data
//       cl("loading data")
//       cl(report)
      this.setState({reportId: report.reportId, timezone: report.timezone})
      return
    }
  }

  makeUsers=async()=>{
    await loadUsersInfo()
    let users=[]
    let gui=globs.usersInfo.info
    gui.forEach(u=>{
      users.push(
        {
          name: u.name,
          avatar: u.avatar,
          userId: u.userId,
        }
      )
    })
    this.setState({loaded:true, all_recipients:users})
  }

  onSubmit=async(cmd)=> {
    if (cmd == "save" || cmd == "clone") {
      cl("saving report")
      // format date and time to utc
      let nextDate = this.formatDateUtcTimestamp(this.state.nextDate, this.state.nextTime)
      cl(nextDate)

      let body = {
        userId: this.state.userId,
        accountId: this.state.accountId,
        name: this.state.name,
        reportId: this.state.reportId,
        recipients: this.state.recipients,
        period: this.state.period,
        status: this.state.status,
        nextDateTs: nextDate
      }

      if (cmd == "save" && this.state.reportScheduleId != "") {
        body.reportScheduleId = this.state.reportScheduleId
      }

      if (cmd == "clone") body.name = body.name + " Copy"

      wsTrans("usa", {cmd: "cRest", uri: "/s/reportSchedules", method: "update", 
      sessionId: globs.userData.session.sessionId, 
      body: body 
      })
    } else if (cmd == "delete") {
      let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this schedule?", buttons:["Cancel","Yes"]})
      if(res=="Yes"){
      cl("deleting schedule" + this.state.reportScheduleId)
        if (this.state.reportScheduleId != "") {
          wsTrans("usa", {cmd: "cRest", uri: "/s/reportSchedules", method: "delete", 
          sessionId: globs.userData.session.sessionId, body: {
            reportScheduleId:this.state.reportScheduleId,
          }})
        }
      }
    }
    globs.events.publish("saveOK",true)
    // cl("cancel?")
    // history.goBack()
  }

  showRecipients=()=>{
    let recipients = []
    let unselectedRecipients = []
    let unselect = true
    this.state.all_recipients.forEach((u, i) => {
      let selected = (this.state.recipients.includes(u.userId)) ? "toggle selected" : "toggle"
      unselect = (selected == "toggle") ? false : unselect
      if (selected == "toggle") unselectedRecipients.push(u.userId)
      let av = u.avatar
      let path
      if(u.avatar){
        path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
      } else {
        path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
      }
      recipients.push(
        <button key={i} onClick={()=>this.onToggle("recipients", u.userId)} type="button" className={selected} id={u.userId}>
          <img src={path}/>
          {u.name}
        </button>
      )
    })
    let selectStr = (unselect) ? "Unselect All" : "Select All"
    return (
      <>
        <div className="user-toggle-field">
        {recipients}
        </div>
        <button onClick={()=>{this.onToggle("allRecipients", unselectedRecipients)}} type="button" className="select-all">{selectStr}</button>
      </>
    )
  }

  showReportSelect=()=> {
    let reports = this.state.all_reports
//     cl(reports)

    if (this.state.reportId == 0 && reports.length > 0) this.setState({reportId: reports[0].reportId})

    return (
      <div className="custom-select float-left-box">
        <label htmlFor="schedule-report">Report</label>
        <C18Select00 
          id="schedule-report"
          onChange={(e)=>this.onChange("report", e.currentTarget.value)}
          value={this.state.reportId}
        >
          {reports.map((r,i)=>{
            return(
              <option key={i} value={r.reportId}>{r.title}</option>
            )
          })}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
    )

  }

  showPeriod=()=>{
    return(
      <div className="float-left-box radio-field">
        <div className="form-title">Period</div>
        <div className="top-margin">
          <C18Input00 type="radio" id="schedule-period-daily" name="schedule-period" 
            value="daily" checked={this.state.period=="daily"} onChange={(e)=> {
            this.onChange("period", e.currentTarget.value)}}>
          </C18Input00>
          <label htmlFor="schedule-period-daily">Daily</label>

          <C18Input00 type="radio" id="schedule-period-weekly" name="schedule-period" 
            value="weekly" checked={this.state.period=="weekly"} onChange={(e)=> {
              this.onChange("period", e.currentTarget.value)}}>
          </C18Input00>
          <label htmlFor="schedule-period-weekly">Weekly</label>

          <C18Input00 type="radio" id="schedule-period-monthly" name="schedule-period" 
            value="monthly" checked={this.state.period=="monthly"} onChange={(e)=> {
              this.onChange("period", e.currentTarget.value)}}>
          </C18Input00>
          <label htmlFor="schedule-period-monthly">Monthly</label>
        </div>
      </div>
    )
  }

  addEmail=()=> {
    if (this.state.email) this.onToggle("recipients", this.state.email)
  }

  sendTestEmail=()=> {
    cl("send test email")
  }

  showEditSchedule=()=> {
    return (
      <>
          <div className="reports-and-graphs">

            <form id="edit-schedule">
              <div className="float-left-box">
                <label htmlFor="schedule-title">Schedule Name</label>
                <input type="text" id="schedule-title" value={this.state.name}
                onChange={(e)=>this.onChange("name", e.target.value)}/>
              </div>
              <div className="checkbox-field float-left-box">
                <div className="form-title">Status</div>
                <div className="top-margin">
                  <input type="checkbox" id="schedule-status" checked={this.state.status}
                  onChange={()=>this.onChange("status", !this.state.status)} />
                  <label className="left-margin" htmlFor="schedule-status">
                    {(this.state.status) ? "Active" : "Inactive"}
                  </label>
                </div>
              </div>
              <div className="clearfloat"></div><br />

              {this.showReportSelect()}

              {this.showPeriod()}

              <div className="clearfloat"></div>

              <div className="float-left-box">
                <div className="form-title">Next Date ({this.state.timezone})</div>
                <input id="picker-schedule-date" type="date" aria-label="next schedule date" 
                onChange={(e)=>this.onChange("nextDate", e.currentTarget.value)}
                value={dateToDisplayDate(this.state.nextDate, "yyyy-mm-dd", 0)}/>
              </div>

              <div className="float-left-box">
                <div className="form-title">&nbsp;</div>
                <input id="picker-schedule-date" type="time" aria-label="next schedule time" 
                value={this.state.nextTime} onChange={(e)=>this.onChange("nextTime", e.currentTarget.value)}/>
              </div>

              <div className="clearfloat"></div><br />

              <div className="form-title">Recipients</div>
              {this.showRecipients()}
              <br />
              <br />

              <label htmlFor="schedule-add-email">Add Email</label>
                <input type="email" id="schedule-add-email" value={this.state.email}
                  onChange={(e)=>this.onChange("email", e.target.value)}/>
                <button onClick={()=>this.addEmail()} type="button" className="filled">Add</button>
              <div className="clearfloat"></div>

              <br />

              <button onClick={()=>this.sendTestEmail()} type="button" className="outlined">
                Send Test Email
              </button>

              <div className="clearfloat"></div>

              <br />
              <br />

              <div className="float-left-box">
                <button type="button" className="danger" onClick={()=>this.onSubmit("delete")}>
                  Delete
                </button>
              </div>
              <div className="float-right-box">
                <button type="button" className="outlined" onClick={()=>this.onSubmit("clone")}>
                  Clone
                </button>
              </div>
              <div className="clearfloat"></div>

              </form>

            </div> 

      <div className="clearfloat"></div><br /><br /><hr /><hr /><br /><br />
      </>
    )
  }

  formatTime=(d)=> {
    let hr = String(d.getHours());
    let min = String(d.getMinutes());

    if (hr.length < 2) hr = '0' + hr;
    if (min.length < 2) min = '0' + min;

    return `${hr}:${min}`;
  }

  formatDateUtcTimestamp=(d, t)=> {
    d.setHours(t.split(":")[0])
    d.setMinutes(t.split(":")[1])
    let tzAdj = calcTimezoneOffset(this.state.timezone);
    let utcDate = new Date(d.getTime() + tzAdj)
    cl([d, utcDate])
    // get utc date adjusted to selected timezone
    return utcDate.getTime()
    // let utcDateStr = dateToDisplayDate(utcDate, "mm/dd/yyyy h:mm ap");
    // return utcDateStr;
  }
  
  render(){
//     cl(this.state)
    return this.showEditSchedule();
  }
    
}

export default C18EditSchedule;
