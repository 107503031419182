import React from 'react';
import TextField from '@material-ui/core/TextField';
import {cl,globs} from '../../components/utils/utils';

class C18MuiTextField extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
    }
  }
  
  render(){
    if(globs.noMui){
      return(
        <input
          value={this.props.value}
          onChange={this.props.onChange}
        >
          {this.props.children}
        </input>
      )
    }else{
      return React.createElement(TextField,this.props)
    }
  }
}
      
export default C18MuiTextField;
 
