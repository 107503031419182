import React from 'react';
import C18Select00 from './C18Select00'
import C18Button00 from './C18Button00'
import C18SubMenuHeader00 from './C18SubMenuHeader00'
import C18Anchor00 from './C18Anchor00'
import LiveFui from '../../fui/LiveFui';
import {dbVals,sendArray} from '../../components/utils/http';
import {wsTrans,getParamId,makeOpts,getChannelTypeId,getPearlUsed} from '../utils/utils'
import {loadSiteData,loadZonesInfo,getSiteName,getZoneName,getChannelType,
  acctFeature,
} from './C18utils'
import {paramIdToTableColumn,groupPidArrs} from '../../fui/utilsFui'

import {cl,globs,constant,dateToDisplayDate,secsToHms,maxLen,getRandomString,
  proportionals
} from '../../components/utils/utils';

class C18GroupsEdit00 extends React.Component{
  constructor(props) {
    super(props);
    // cl(props)
    this.state={
      loaded:false,
//       members:[],
      groups:[
        {t:"Group 1",v:"g1"},
        {t:"Group 2",v:"g2"},
        {t:"Group 3",v:"g3"},
      ],
      selGroup:"",
      selConfig:"",
      groupName:"Group 1",
      groupType:"chan",
      pageType:"edit",
      memberSortMode:3,
      commonControls:[],
      showConflictPopup:false,
      conflictLeft:300,
      conflictTop:300,
      conflictWidth:300,
      conflictHeight:500,
      conflictName:"chan",
      conflictKey:"",
      conflictOverrides:[],
//       selectedKeys:[],
    }
    this.notifies={}
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveGroup)
    this.subscribe_zonesSavedEvent=globs.events.subscribe("zonesSaved",this.zonesSaved)
    this.loadInfo()
//     this.setBreadCrumbs()
    this.controlRefs=[]
    document.addEventListener("mousedown",this.md,false)
//     cl(this.keyUp)
    this.subscribe_keyUpEvent=globs.events.subscribe("keyUp",this.keyUp)
//     cl("key up done")
  }

  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
    this.subscribe_keyUpEvent.remove()
    this.subscribe_zonesSavedEvent.remove()
    document.removeEventListener("mousedown",this.md,false)
  }

  componentDidUpdate(prevProps, prevState) {
    let pa=this.props.parms
    if(this.adminPage==pa.adminPage){return}
//     cl(pa)
    this.adminPage=pa.adminPage
    this.setBreadCrumbs()
//     cl(this.state.groups)
    if(pa.adminPage=="applyGroup"){this.onChange("selGroup",{selGroup:pa.adminInfo})}
//     cl(prevProps)
//     cl(this.props)
  }

  setBreadCrumbs=()=>{
    let siteName=getSiteName(this.props.parms.site)
    let zoneName=getZoneName(this.props.parms.zone)
    let pa=this.props.parms
    if(pa){
      let bc=[
            {t:"Sites", url:"/usa/c18/sites"},
            {t:`${siteName}`, url:`/usa/c18/sites/${pa.site}`},
            {t:`${zoneName}`, url:`/usa/c18/sites/${pa.site}/zones/${pa.zone}`},
            {t:`Admin`,
              url:`/usa/c18/sites/${pa.site}/zones/${pa.zone}/settings/equipment1800`},
            {t:`Select Group`,
              url:`/usa/c18/sites/${pa.site}/zones/${pa.zone}/admin/selectGroup`},
          ]
      if(pa.adminPage=="editGroup"){bc.push(
            {t:`Edit Group`,
              url:`/usa/c18/sites/${pa.site}/zones/${pa.zone}/admin/editGroup/${pa.adminInfo}`}
      )}
      if(pa.adminPage=="applyGroup"){bc.push(
            {t:`Apply Group`,
              url:`/usa/c18/sites/${pa.site}/zones/${pa.zone}/admin/applyGroup/${pa.adminInfo}`}
      )}
//       cl(pa)
      pa.onChange({
        cmd: "breadcrumbs",
        data:
          {breadcrumbs: bc},
      })
    }
  }

  loadChannels=()=>{
    let pa=this.props.parms
    let zones=globs.zonesInfo.info.filter(z=>{return z.siteId==pa.site})
    let chIndId=getParamId("configuration_channels","channelIndex")
    let chNameId=getParamId("configuration_channels","channelName")
    let chTypeId=getParamId("configuration_channels","channelType")
    let chUsedId=getParamId("configuration_channels","used")
//     cl(chIndId)
//     cl(zones)
//     cl(dbVals)
    this.chans=[]//{}
    this.chanMap={}
//     cl(zones)
    zones.forEach(z=>{
//       cl(z)
      var chIndexes
      let zInd=z.siteZoneIndex
      if(z.gatewayType==1900){
        let pearlUsed=getPearlUsed(z.siteZoneIndex)
        chIndexes=pearlUsed.map(p=>{return p.chInd})
// the "type" in pearlUsed is 0=digital, 1=analog, *not* eqType
//         if(z.siteZoneIndex==2){cl(chIndexes)}
      }else{
        chIndexes=[...Array(40).keys()].filter(ch=>{
//           cl(zInd,ch,chUsedId)
          return ((dbVals.z[zInd]||{})[ch]||{})[chUsedId]})
//         let used=dbVals.z[zInd][i][chUsedId]
      }
//       let zoneChans=[]
      if(/*(zInd==1)&&*/dbVals.z[zInd]){// debugging!!!//(zInd==2)&&
//         cl(`doing ${zInd}`)
        var baseType
        chIndexes.forEach(i=>{

          let chInd=i
          let chName=dbVals.z[zInd][i][chNameId]
          let gwType=z.gatewayType||1800
          let typeId=getChannelTypeId(gwType,zInd,i)
          let type=dbVals.z[zInd][i][chTypeId]||0
          let scndChan=chInd%2
          if(!scndChan){baseType=type}
          let scndProp=scndChan&&(proportionals.includes(baseType))// second prop chan
          let used=dbVals.z[zInd][i][chUsedId]
          let chanType=getChannelType(gwType,zInd,i)
//           cl(type)
          if((type!=0)&&!scndProp){
//           if(used&&(type!=0)&&!scndProp){
            let controls=this.fuiPageMap[chanType]?.controls||[]
            let keys=controls.filter(c=>{return c.pid>=0}).map(c=>{
              return `${gwType}-${c.pid}-${c.type}`
            })
            let key=`${z.zoneId}+${i}`// changed from - to +, 20230512
//             cl(i,dbVals.z[zInd][i][chIndId])
            let ind=dbVals.z[zInd][i][chIndId]
            if(!ind&&(ind!=0)){ind=i}
            if(gwType==1900){ind=i}// temp, Pearl is returning 1-based ch indexes
            let chan={
              used:true,//used,
              ind:ind,
              name:dbVals.z[zInd][i][chNameId],
              zName:z.zoneName,
              zInd:z.siteZoneIndex,
              gwType:gwType,
              type:type,
              typeId:typeId,
              chanType:chanType,
              controls:controls,
              keys:keys,
              typeName:constant.CHAN_TYPES[typeId||"0"],
              key:key,
            }
            this.chanMap[key]=chan
            this.chans.push(chan)
          }
        })
      }
    })
//     cl(this.chans)
  }

  loadFuiPages=async()=>{// this actually has to be controllerType-specific
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/fuiPages", method: "retrieve",
      sessionId: globs.userData.session.sessionId, body: {gatewayType:1800}})
    // cl(res)
    this.fuiPages=res.data
//     cl(this.fuiPages)
    this.fuiPageMap={}
    this.controlTabCol={}
    this.fuiPages.forEach(fp=>{
//       if(fp.type=="equipment-options_800"){
//       if(fp.gatewayType==800){cl(fp)}

//       }
      this.fuiPageMap[fp.type]=fp
      fp.controls.forEach(c=>{
//         if(c.type=="channelType"){cl(c)}
        if(c.type=="channelType"){
          c.pid=(fp.gatewayType==800)?152:508
        }
        // has to be here, too.
        if(c.pid>=0){
          let key=`${fp.gatewayType}-${c.pid}-${c.type}`
          this.controlTabCol[key]=paramIdToTableColumn(c.pid,fp.gatewayType)
        }
      })
    })
//     cl(this.fuiPageMap)
//     cl(this.controlTabCol)
  }

  loadZoneGroup=()=>{
//     let siteId=globs.userData.session.siteId// this is actually not set
    let groupId=globs.userData.session.groupId
    let z0=(globs.zonesInfo.info.filter(z=>{
      return z.zoneId==globs.userData.session.groupZoneId})||[])[0]
//     cl(z0)
//     cl(globs)
    let siteGroups=(globs.zonesInfo.groups.filter(g=>{return g.siteId==z0.siteId})||[])[0]
    let group=(siteGroups.groups.filter(g=>{return g.groupId==groupId})||[])[0]
//     cl(group)
//     cl(siteGroups)
    let ret=[{
      groupId:groupId,
      gwType:z0.gatewayType,
      members:group.zones,
      name:group.name,
      siteId:z0.siteId,
      type:"zone",
      v:groupId,
    }]
//     cl(ret)
    return ret
  }

  loadGroups=async()=>{
    let pa=this.props.parms
//     cl(pa)
    if(acctFeature("zoneGroups")&&globs.userData.session.groupId){
      return this.loadZoneGroup()}//pa.adminPage=="zoneGroup"
//     cl(this.state)
//     cl(this.props)
//     cl(this.chans)
    let chanKeys=this.chans.map(c=>{return c.key})
//     cl(chanKeys)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/groups", method: "retrieve",
      sessionId: globs.userData.session.sessionId, body: {siteId:pa.site}})
    let groups=res.data
//     cl(groups)
//     cl(res.data)
    groups.forEach(d=>{
//       cl(d)
//       cl(d.members)
      if(d.members){
        var z0Id=d?.members[0]
        if(d.type=="chan"){
          d.members=(d.members||[]).filter(m=>{return chanKeys.includes(m)})
          z0Id=((d.members||{})[0]?.split("-")||{})[0]
        }
        let z0=globs.zonesInfo.info.filter(z=>{return z.zoneId==z0Id})[0]
        d.v=d.groupId
        d.t=d.name
        d.gwType=z0?.gatewayType||1800
      }
    })
    groups.sort((a,b)=>{
      if(a.name>b.name){return 1}
      if(a.name<b.name){return -1}
      return 0
    })
//     cl(groups)
    return groups
  }

  loadInfo=async()=>{
    let pa=this.props.parms
//     cl(this.props)
    let mpa=this.props.match?.params
//     cl(pa)
    await loadZonesInfo()
    this.zoneMap={}
    globs.zonesInfo.info.forEach(z=>{this.zoneMap[z.zoneId]=z})
//     cl(globs.zonesInfo.info)
//     cl(this.zoneMap)
    await loadSiteData(pa.site)
//     cl(groups[0])
    await this.loadFuiPages()
    this.loadChannels()
    let groups=await this.loadGroups()
//     cl(groups)
    var groupType
    if(pa.adminInfo){
//       cl(groups)
//       cl(pa)
      let gr=groups.filter(gr=>{return gr.v==pa.adminInfo})[0]// get selected
      groupType=gr?.type||"channel"
    }
//     cl(groups[0].gwType)
    this.sorts=[1,2,3]
//     cl(this.props)
    this.fuiPageType=mpa?.pageType
    let vals={
      loaded:true,
      groups:groups,
      groupType:groupType,
      selConfig:mpa?.pageType||"",
//       zuci:mpa.zuci,
    }
    this.zuci=mpa.zuci
//     cl(vals)
//     cl("load info call")
    vals.commonControls=this.getCommonControls(vals)
//     cl(vals)
    this.setState(vals)
  }

  notify=(note)=>{
//     cl(note)
    if(note.unMount){// called when widget is unmounted
      this.notifies[note.id].forEach((n,i)=>{
        if(n==note.func){
          this.notifies[note.id].splice(i,1)
        }
      })
    }else{
      if(note.id in this.notifies){}else{
        this.notifies[note.id]=[]
      }
      this.notifies[note.id].push(note.func)
    }
  }

  doNotify=(event,cmd,data)=>{
    var res
//     cl(this.notifies)
    if(event in this.notifies){
      res=this.notifies[event].map(f=>{
        return f({cmd:cmd, data:data})
      })
    }
    return res
  }

  updateLocal=(sendArr)=>{
//     cl(this.state)
//     cl(this.props)
//     cl(sendArr)
    sendArr.forEach(p=>{
//       cl(p)
      dbVals.z[p.z][p.c][p.i]=p.d
    })
  }

  saveApplyGroup=async()=>{
    let st=this.state
//     cl(st)
    let res=await this.doNotify("localSave")[0]
//     cl(res)
    let gr=st.groups.filter(gr=>{return gr.v==this.props.parms.adminInfo})[0]// get selected group
//     cl(gr)
//     cl(this.chanMap)
    let updType=(gr.type=="zone")?"zone":"channel"
    let pNames=res.map(r=>{
//       cl(r.i,gr.gwType)
      let [tab,col]=paramIdToTableColumn(r.i,gr.gwType)
      return col
    })
    var zNames=[]
    var sendArr={}
// Now, sendArr is an object, with an attribute for each zone
//     cl("sag")
    if(gr.type=="chan"){
      zNames=gr.members.map(m=>{// go through the channels
// I think we're going to have to mark virtual zones here'
        let chan=this.chanMap[m]
        res.forEach(r=>{
//           cl(r)
          let pObj=Object.assign({},r)
          pObj.z=chan.zInd
          pObj.c=chan.ind
          if(!sendArr[chan.zInd]){sendArr[chan.zInd]=[]}
          sendArr[chan.zInd].push(pObj)
        })
        return `${chan.zName}-${chan.name}`
      })
//       cl(sendArr)
    }else{
//       cl("zone group")
//       cl(gr.members)
      zNames=gr.members.map(m=>{
        let zone=this.zoneMap[m]
//         cl(zone)
        res.forEach(r=>{
//           cl(r)
          let pObj=Object.assign({},r)
          pObj.z=zone.siteZoneIndex
          pObj.virtual=zone.virtual||false
          pObj.c=r.c
          pObj.controllerId=zone.controllerId
//           pObj.c=chan.ind
          if(!sendArr[zone.siteZoneIndex]){sendArr[zone.siteZoneIndex]=[]}
          sendArr[zone.siteZoneIndex].push(pObj)
        })
//         cl(zone)
//         cl(res)
        return `${zone.zoneName}`
      })
//       cl(sendArr)
    }
//     cl(zNames)
//     cl("sag")
    let vPlural=(res.length!=1)?"s":""
    let zPlural=(gr.members.length!=1)?"s":""
// (${pNames.join(", ")})
    let msg=`This will update ${res.length} value${vPlural} on ${gr.members.length} ${updType}${zPlural} (${zNames.join(", ")}). Is this \
      what you want to do?`
    let confirm=await this.props.parms.getPopup({text:msg,
      buttons:["Cancel","Yes"]})
//     cl(confirm)
//     cl("sag")
    if(confirm=="Yes"){
//       cl("ok do it")
//       let virtual=false
//       let controllerId=""
      let localOnly=false
//       cl(sendArr)
//       cl(virtual,gr.gwType,controllerId,localOnly)
      let saK=Object.keys(sendArr)
//       cl(saK)
//       cl(sendArr)
//       cl(gr.gwType)
      for(let i=0;i<saK.length;i++){
        let k=saK[i]
        let sa=sendArr[k]
        let virtual=sa[0].virtual
        let controllerId=sa[0].controllerId
        this.updateLocal(sa)
//         cl(sa)
        let res=await sendArray(sa,virtual,gr.gwType,controllerId,localOnly)
          .then(e=>{globs.events.publish("saveOK",true)});
//         cl(res)
      }
//       Object.keys(sendArr).forEach(k=>{
//       })
    }else{// need to restore original values
      globs.events.publish("newContext")
      cl("new cont")
    }
  }

  doSaveGroup=async(st,id,site)=>{
    let gr=st.groups.filter(gr=>{return gr.groupId==id})[0]
//     cl(gr)
    let group={
      groupId:id,//pa.adminInfo,
      name:gr.name,
      members:gr.members,
      siteId:site,//pa.site,
      type:gr.type,
    }
//     cl(group)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/groups", method: "update",
      sessionId: globs.userData.session.sessionId, body: group})
  }

  zonesSaved=async(cmd,data)=>{
    cl("zones Saved")
    let st=this.state
    let vals={
      groups:st.groups,
      groupType:st.groupType,
      selConfig:st.selConfig
    }
    let commonControls=this.getCommonControls(vals)
    this.setState({commonControls:commonControls})
  }

  saveGroup=async(cmd,data)=>{
    let st=this.state
    let pa=this.props.parms
//     cl(pa)
//     cl(st)
//     cl(this.props)
//     if(st.pageType=="apply"){return this.saveApplyGroup()}
    if(["applyGroup","zoneGroup"].includes(pa.adminPage)){return this.saveApplyGroup()}
//     return
//     let pa=pa
    if(cmd=="save"){
      await this.doSaveGroup(st,pa.adminInfo,pa.site)
    }
    globs.events.publish("saveOK",true)
}

  deleteGroup=async(groupId)=>{
    let st=this.state
    cl(groupId)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/groups", method: "delete",
      sessionId: globs.userData.session.sessionId, body: {groupId:groupId}})
  }

  doSortMembers=(vals,sort)=>{
//     cl(vals)
    let st=Object.assign({},this.state,vals)
//     let sort=st.memberSortMode
    var pv=(obj,col)=>{
      if(["zName","typeName","name"].includes(col)){
//         cl(obj,col)
        return (obj[col]||"").toLowerCase()
      }else{
        return obj[col]
      }

    }
    let sortCols=["","sel","zName","ind","typeName","name"]
    this.sorts.pop()
    this.sorts.unshift(sort)
//     cl(sort)
//     cl(this.sorts)
//     cl(this.sorts)

//     let st=this.state
    let sInd=Math.abs(sort)
    let sInd2=Math.abs(this.sorts[1])
    let sortCol=sortCols[sInd]
    let sortCol2=sortCols[sInd2]
    let sDir=(sort>0)?1:-1
    let sDir2=(this.sorts[1]>0)?1:-1

    let gr=st.groups.filter(gr=>{return gr.groupId==this.props.parms.adminInfo})[0]
//     cl(this.props.parms.adminInfo)
    if([sInd,sInd2].includes(1)){
      this.chans.forEach(c=>{
//         cl(c)
        c.sel=!(gr.members||[]).includes(c.key)
      })
    }

//     cl(sortCol,sortCol2)
    this.chans.sort((a,b)=>{
      if(pv(a,sortCol)>pv(b,sortCol)){return sDir}
//       if(a[sortCol]>b[sortCol]){return sDir}
      if(pv(a,sortCol)<pv(b,sortCol)){return 0-sDir}
//       if(a[sortCol]<b[sortCol]){return 0-sDir}

      if(a[sortCol2]>b[sortCol2]){return sDir2}
      if(a[sortCol2]<b[sortCol2]){return 0-sDir2}
      return 0
      return 0
    })
  }

  getFuiPageType=(vals)=>{// when a group is selected
    let st=Object.assign({},this.state,vals||{})
//     cl(st)

    let gr=st.groups.filter(gr=>{return gr.groupId==this.props.parms.adminInfo})[0]// the selected group
    if(!gr){return}
//     cl(gr)
    var key,chan,pageType
    if(gr.type=="zone"){
      pageType=st.selConfig//chan.chanType
    }else{
      key=(gr.members||[])[0]||""
      chan=this.chans.filter(c=>{return c.key==key})[0]
      pageType=chan?.chanType
    }
// //     cl(pageType)
    return pageType

  }

  onChange=async(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    let pa=this.props.parms
//     cl(st)
//     cl(pa)
    var groups,gr,groupId
    switch(type){
      case "selGroup":
        vals.fuiPageType=this.getFuiPageType(vals)
        this.fuiPageType=vals.fuiPageType
        this.doSortMembers(vals,st.memberSortMode)
        gr=st.groups.filter(g=>{return g.groupId==vals.selGroup})[0]
//         cl(gr.type)
        vals.groupType=gr.type
//         cl(gr)
        vals.commonControls=this.getCommonControls(vals)
        cl(vals)
//         cl(vals.fuiPageType)
        await this.setState(vals)
//         cl(this.state)
//         cl(this.state.fuiPageType)
//         globs.events.publish("newContext")
        break
      case "groupName":
        globs.events.publish("savePageEnable",true)
        groups=st.groups.slice(0)
        gr=groups.filter(gr=>{return gr.groupId==this.props.parms.adminInfo})[0]
        gr.t=vals.groupName
        gr.name=vals.groupName
        this.setState({groups:groups})
        break
      case "groupType":
        groups=st.groups.slice(0)
        gr=[]
        gr=groups.filter(grx=>{return grx?.groupId==this.props.parms.adminInfo})[0]
//         cl(vals)
        gr.type=vals.groupType
//         cl(gr)
        vals.groups=groups
//         globs.events.publish("savePageEnable",true)
        vals.members=[]
        vals.selGroup=""
//         vals.commonControls=this.getCommonControls(vals)
        this.setState(vals)
        break
      case "sortMembers":
        if(vals.memberSortMode==Math.abs(st.memberSortMode)){
          vals.memberSortMode=0-st.memberSortMode
        }
        this.doSortMembers(vals,vals.memberSortMode)
        this.setState(vals)
        break
      case "member":
        globs.events.publish("savePageEnable",true)
//         let members=(st.groups.members||[]).slice(0)
        groups=st.groups.slice(0)
        gr=groups.filter(gr=>{return gr.groupId==this.props.parms.adminInfo})[0]
        if(!gr?.members){gr.members=[]}

        if(gr.members.includes(vals.key)){
          let pos=gr.members.indexOf(vals.key)
          gr.members.splice(pos,1)
        }else{
          gr.members.push(vals.key)
        }
//         cl(vals.key)
        delete vals.key
        vals.groups=groups
        vals.commonControls=this.getCommonControls(vals)
//         cl(vals)
        this.setState(vals)
//         this.setState({groups:groups})
        break
      case "deleteGroup":
        cl(vals)
        cl(this.state)
        groups=st.groups.slice(0)
        groups=groups.filter(g=>{return (g.groupId!=vals.groupId)})
        cl(groups)
        this.deleteGroup(vals.groupId)
//         vals={groups:groups,selGroup:groups[0].v}
//         vals.commonControls=this.getCommonControls(vals)
        this.setState({groups:groups})
        break
      case "addGroup":
        vals.e.preventDefault()
        groups=st.groups.slice(0)
        groupId=getRandomString(16)
        groups.push({name:"New Group",groupId:groupId,type:"chan",v:groupId/*st.groupType*/})
        vals={groups:groups,selGroup:groupId}
        vals.commonControls=this.getCommonControls(vals)
        this.doSaveGroup(vals,groupId,pa.site)
        this.setState(vals)
        break
      case "selConfig":
//         cl(vals)
        this.fuiPageType=vals.selConfig
        vals.commonControls=this.getCommonControls(vals)
//         cl(vals)
//         cl(this.fuiPageType)
        globs.events.publish("newContext",{pageType:this.fuiPageType})
      case "groupPage":// edit or apply page
//         cl(vals)
        this.setState(vals)
        break
      case "conflict":
        this.conflictClick(vals)
        break
      case "overrideConflict":
//         cl(st.conflictKey)
        let overrides=st.conflictOverrides.slice(0)
        overrides.push(st.conflictKey)
        globs.events.publish("savePageEnable",true)

//         cl(overrides)
//         cl(overrides)// an array of the keys that have been overridden
//         cl(st.conflictKey)
        this.setState({showConflictPopup:false,conflictOverrides:overrides})
      case "cancelConflict":
        this.setState({showConflictPopup:false})
        break
      case "fui":
        switch(vals.cmd){
          case "channelType":
            vals.data.commonControls=this.getCommonControls(vals.data)
//             cl(vals.data)
            this.setState(vals.data)
            break
          default:
            return this.props.parms.onChange(vals)
        }
    }
  }

  showSelectGroup=()=>{
    let pa=this.props.parms
//     cl(pa)
//       <th>Type</th>
    let header=(
      <tr key="head">
      <th>Name</th>
      <th>Edit</th>
      <th>Delete</th>
      </tr>
    )
    let st=this.state
//     cl(st.groups[0].t)
    let rows=st.groups.map((g,i)=>{
//       cl(g)
//       cl(g.t)
      let type=(g.type=="zone")?"Zone":"Channel"
      let baseUrl=`/usa/c18/sites/${pa.site}/zones/${pa.zone}/admin/`
//       let url=`/usa/c18/sites/${pa.site}/zones/${pa.zone}/admin/editGroup/${g.groupId}`
      let url=`${baseUrl}editGroup/${g.groupId}`
      let urlApply=`${baseUrl}applyGroup/${g.groupId}`
//           <td>{type}</td>
      return(
        <tr key={i}>
          <td>
            <C18Anchor00 to={urlApply} className="settings-button">{g.name}</C18Anchor00>
          </td>
          <td>
            <C18Anchor00 to={url} className="settings-button material-icons-outlined flip">edit</C18Anchor00>
          </td>
          <td>
            <button type="button" className="material-icons trash" aria-label="delete user"
            onClick={()=>this.onChange("deleteGroup",{groupId:g.groupId})}>delete_outline</button>
          </td>
        </tr>
      )
    })
    return(
      <div style={{width:300}}>
        <div className="section-controls floatright">
          <C18Anchor00 to="" className="material-icons-outlined add"
          onClick={e=>{this.onChange("addGroup",{e:e})}}
            aria-label="add Group" title="New Group">add</C18Anchor00>
        </div>
        <div className="clearfloat"></div>
        <br />
        <table><tbody>
        {header}
        {rows}
        </tbody></table>
      </div>
    )
//         <label htmlFor="selGroup">Select Group</label>
  }

  showEditGroup=()=>{
    let st=this.state
    let pa=this.props.parms
//     cl(pa)
//     cl(st)
    let gr=st.groups.filter(gr=>{return gr.groupId==this.props.parms.adminInfo})[0]
//     let gotMembers=gr.members.length!=0
//     if(!gr){return}
//     cl(gr)
    let grName=gr?.name
//     <div className="clearfloat"></div>
//       {this.showGroupType((gr?.members?.length||0)==0)}
    return(
      <div>
        <label htmlFor="groupName">Group Name</label>
        <input id="groupName" type="text"
          value={grName||""}
          style={{display:"inline-block"}}
          onChange={e=>this.onChange("groupName",{groupName:e.target.value})}
        />
      </div>
    )
//         <div style={{display:"inline-block"}}>
//           <button type="button" className="material-icons trash after-selector"
//           aria-label="delete group"
//           onClick={e=>{
//             cl("click")
//             this.onChange("deleteGroup",{a:5})}
//           }
//           style={{marginBottom:-10}}
//           >
//             delete_outline
//           </button>
//
//           <button type="button" className="material-icons-outlined add after-selector"
//           aria-label="add group"
//           onClick={e=>this.onChange("addGroup")}
//           style={{marginBottom:-10}}
//           >
//             add
//           </button>
//         </div>
  }

  showGroupType=(enable)=>{
//     cl(enable)
    let st=this.state
    let gr=st.groups.filter(gr=>{return gr.groupId==this.props.parms.adminInfo})[0]
//     cl(st)
    let labelStyle={display:"inline-block", paddingRight:20}
    return(
      <div style={{marginTop:20}}>
        <label htmlFor="groupType">Group Type</label>
        <div id="groupType">
          <input type="radio" id="zone" name="groupType" value="zone"
            disabled={!enable}
            checked={gr.type=="zone"}
            onChange={e=>this.onChange("groupType",{groupType:e.currentTarget.value})}
          />
          <label htmlFor="zone" style={labelStyle}>Zones</label>
          <input type="radio" id="chan" name="groupType" value="chan"
            disabled={!enable}
            checked={gr.type=="chan"}
            onChange={e=>this.onChange("groupType",{groupType:e.currentTarget.value})}
          />
          <label htmlFor="chan" style={labelStyle}>Channels</label>
        </div>
      </div>
    )
  }

  showZoneMembers=()=>{
    var showOneHead=(ind,txt)=>{
      return(
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sortMembers",{memberSortMode:ind+1})}
        >{txt} <span className="material-icons-outlined">{icons[ind]}</span></button></th>
      )
    }
    var showMemberHead=()=>{
      return(
        <tr style={pntStyle}>
          {showOneHead(0,"Select")}
          {showOneHead(1,"Zone")}
        </tr>
      )
    }
    let st=this.state
    let pa=this.props.parms
    let pntStyle={cursor:"pointer"}
    let icons=[]
    let zones=globs.zonesInfo.info.filter(z=>{return z.siteId==pa.site})
    let zoneLines=[]
    let gr=st.groups.filter(gr=>{return gr.groupId==this.props.parms.adminInfo})[0]
    if(!gr){return}
    zones.forEach((z,i)=>{
      var bgColor
//       if((gr.members||[]).includes(z.zoneId)){bgColor="#CCDDFF"}
      var checked=(gr.members||[]).includes(z.zoneId)
//       ,backgroundColor:bgColor
      zoneLines.push(
        <tr key={i}
          style={{cursor:"pointer"}}
          onClick={e=>this.onChange("member",{key:z.zoneId})}
        >
        <td><input
          type="checkbox"
          checked={checked}
          onChange={e=>{}}
        />
        </td>
        <td>{z.zoneName}</td>
        </tr>
      )
    })
    return(
      <div>
        <label htmlFor="members">Group Member Zones</label>
        <div id="members" style={{height:500,
          borderStyle:"solid",borderWidth:1,borderRadius:10,
          overflowY:"auto",padding:20
        }}
        >
          <table><tbody>
            {showMemberHead()}
            {zoneLines}
          </tbody></table>
        </div>
      </div>
    )
  }

  showMembers=()=>{
//     cl("show memeber")
    let st=this.state
    var showGroupType=(group)=>{
      var eqType=0,eqName="None",eqColor="black"
      this.chans.forEach(c=>{
        if(c.used&&((group.members||[]).includes(c.key))){
          if(eqType==0){
            eqType=c.typeId
            eqName=c.typeName
            eqColor="black"
          }else{
            if(eqType!=c.typeId){
              eqType=-1
              eqName="Mixed!"
              eqColor="red"
            }
          }
        }
      })
      return(
        <div style={{marginTop:0,color:eqColor,marginBottom:10}}>
          <label>Group Equipment Type</label>
        {`${eqName}`}
        </div>
      )
    }
    var showOneHead=(ind,txt)=>{
      return(
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sortMembers",{memberSortMode:ind+1})}
        >{txt} <span className="material-icons-outlined">{icons[ind]}</span></button></th>
      )
    }
    var showMemberHead=()=>{
      return(
        <tr style={pntStyle}>
          {showOneHead(0,"Select")}
          {showOneHead(1,"Zone")}
          {showOneHead(2,"EQ Chan")}
          {showOneHead(3,"EQ Type")}
          {showOneHead(4,"EQ Name")}
        </tr>
      )
    }
    let pntStyle={cursor:"pointer"}
    let sort=st.memberSortMode// 1-5 for the columns
    let icons=[]
    for(let i=0;i<6;i++){
      let icon=""
      if(Math.abs(sort)==i+1){
        icons.push((sort<0)?"keyboard_arrow_up":"keyboard_arrow_down")
      } else{
        icons.push("")
      }
    }
//     cl(icons)
    let chans=[]
//     Object.keys(this.chans).forEach(k=>{
//       let zoneId=k
      let gr=st.groups.filter(gr=>{return gr.groupId==this.props.parms.adminInfo})[0]
      if(!gr){return}
      this.chans.forEach((c,i)=>{

      if(c.used){
//         var /*bgColor*/
//         if((gr.members||[]).includes(c.key)){bgColor="#CCDDFF"}
        var checked=(gr.members||[]).includes(c.key)
        chans.push(
          <tr key={`${c.key}-${i}`}
            style={{cursor:"pointer"}}
            onClick={e=>this.onChange("member",{key:c.key})}
          >
            <td><input
              type="checkbox"
              checked={checked}
              onChange={e=>{}}
            />
            </td>
            <td>{maxLen(c.zName,50)}</td>
            <td>{+c.ind+1}</td>
            <td>{c.typeName}</td>
            <td>{maxLen((c.name||"None"),50)}</td>
          </tr>
        )
      }
    })
//     })
    return(
      <div>
        {showGroupType(gr)}
        <label htmlFor="members">Group Member Channels</label>
        <div id="members" style={{height:500,
          borderStyle:"solid",borderWidth:1,borderRadius:10,
          overflowY:"auto",padding:20
        }}
        >
          <table><tbody>
            {showMemberHead()}
            {chans}
          </tbody></table>
        </div>
      </div>
    )
  }

//   channelTypeToFuiPage=(channelType)=>{
//
//   }

//   getCcValue=()=>{
// //     return dbVals.z[z][c][i]
//
//   }

  conflictClick=async(vals)=>{
//     cl(vals)
    if(vals.type=="custom"){
      await this.setState({conflictKey:vals.conflictKey})
      this.onChange("overrideConflict")
      return
    }
    let [x,ind]=vals.e.currentTarget.id.split("-")// get the control ID
//     cl(ind)
    let cont=this.group0.controls[ind]
//     cl(this.group0)
//     cl(cont)
    let key=`${this.group0.gwType}-${cont.pid}-${cont.type}`
    let left=vals.e.currentTarget.offsetLeft+vals.e.currentTarget.offsetWidth
    let top=vals.e.currentTarget.offsetTop
//     cl(left,top,key)
    await this.setState({showConflictPopup:true,conflictLeft:left,conflictTop:top,
      conflictKey:key,
    })
//     cl(key)
//     cl(this.state.showConflictPopup)
  }

  forceReRender=()=>{
    if(!this.reRenderTimeout){
      this.reRenderTimeout=setTimeout(r=>{
//         cl("rerender")
        this.reRenderTimeout=null
        this.setState({update:(this.update||0)+1})
      },1000)
    }
  }

  conflictHandler=(inCont,i,isCustom)=>{
// isCustom means a control like setpoints that handles its values internally
// the 'i' refers to the complete list of controls, not just real parameters
//     cl(this.controlRefs.length)
//     cl(this.controlRefs[i]?.current)
    var cont,ref,type,key
    if(isCustom){
      cont=inCont.cont
      ref=inCont.ref
      type="custom"
      key=inCont.key
//       cl(ref?.current)
    }else{
      cont=inCont
      ref=this.controlRefs[i]
    }
//     cl(ref)
    if(!ref?.current){this.forceReRender()}
    let wid=(ref?.current?.clientWidth||0)-1
    let hgt=(ref?.current?.clientHeight||0)+8
//     cl(this.controlRefs.length)
//     cl(wid,hgt)
//     return cont
//           Conflict
//     cl(i,ref)
// "#FFEEDD",
    return(
      <React.Fragment key={i}>
      <div key={i} ref={ref} style={{position:"relative",
        cursor:"pointer"
      }}
      onClick={e=>this.onChange("conflict",{e:e,type:type,conflictKey:key})}
      id={`handle-${i}`}
      >
          <div style={{position:"absolute",left:-2,top:-2,width:wid,height:hgt,backgroundColor:"#FF0000",
            opacity:0.3,borderRadius:10,color:"red",textAlign:"center",
           fontSize:40,paddingTop:(hgt-40)/2,zIndex:3,// 2 does not cover select cont
          }}
          >
          </div>
          {cont}
      </div>
      </React.Fragment>
    )
//       <div className="clearFloat" style={{display:"block"}}></div>
  }

  md=(e)=>{// close conflict popup when clicked outside
    let st=this.state
    let [x,y]=[e.clientX,e.clientY]
    if(
      (x<st.conflictLeft)||(x>st.conflictLeft+st.conflictWidth)||
      (y<st.conflictTop)||(y>st.conflictTop+st.conflictHeight)
    ){
//       cl("md")
      if(st.showConflictPopup){this.setState({showConflictPopup:false})}
    }
  }

  keyUp=(e)=>{
//     cl(e)
    if(e.key=="Escape"){
      this.setState({showConflictPopup:false})
    }
  }

  showConflict=()=>{
//     cl(this.state.showConflictPopup)
//     return
//     cl(this.commonValues)
    if(!this.commonValues){return}
    let st=this.state
//     cl(st)
//     if(st.groupType=="zone"){return}
    let key=st.conflictKey
//     cl(key)
//     if(!key){return}
//     cl(this.commonValues[key])
//     cl(this.commonValues)
//     cl(key)
    let vals=this.commonValues[key]
//     cl(vals)
    if(!vals){return}
    var chans
//     cl(st.groupType)
    if(st.groupType=="zone"){
//       cl("zone")
      chans=Object.keys(vals)
        .filter(k=>{return k!="conflict"})
        .map((k,i)=>{
        let zone=this.zoneMap[k]
//         cl(k)
//         cl(zone)
        let name=`${zone.zoneName}`
        let val=vals[k]
        if(val===true){val=1}// temp!! for wCon returning true
//         cl(val)
        return(
          <tr key={i} ><td>{`${name}`}</td><td>{val}</td></tr>
        )
      })
//       chans=(<tr><td>here</td></tr>)
    }else{
//       cl(vals)
//       if(!Array.isArray(vals)){
        chans=Object.keys(vals).filter(k=>{
          return((k.indexOf("+")>=0)&&(!Array.isArray(vals[k])))
        })
        .map((k,i)=>{
          let chan=this.chanMap[k]
    //       cl(chan)
          let name=`${chan.zName}-${chan.name}`
          let val=vals[k]
          return(
            <tr key={i} ><td>{`${name}`}</td><td>{val}</td></tr>
          )
        })
//       }
//       cl(chans)
    }
//     cl(vals)


//     cl(this.controlTabCol)
//     cl(key)
    let tc=this.controlTabCol[key]
    if(!tc&&(key.substring(0,4)=="1900")){// if not found, try an 1800, rather than 1900 page:
      let key2=`1800${key.substring(4)}`
      tc=this.controlTabCol[key2]
    }
//     cl(tc)
    if(!tc){return}
    let [tab,col]=tc
    let name=`${tab}-${col}`
    let itemName=(st.groupType=="zone")?"zones":"channels"
    if(st.showConflictPopup){
//       cl("show popup")
      let chans2=(
        <table><tbody>
        {chans}
        </tbody></table>
      )
//       cl("show it")
//       let chans=<tr><td>line</td></tr>
      return(
        <div style={{position:"absolute",left:st.conflictLeft,top:st.conflictTop,width:st.conflictWidth,
//           height:st.conflictHeight,
          backgroundColor:"white",zIndex:2,borderRadius:10,boxShadow:"10px 10px 24px #00000080",
          borderStyle:"solid",borderWidth:1,textAlign:"center",padding:20,zIndex:5}}>
          <h2>{name}</h2>
          <p>If you change this setting, all of these {itemName} will be set to the same value. Currently, they have different settings:
          </p>
          {chans2}
          <p>You can override this conflict, and set all of these {itemName} to the same value, or you can cancel this operation, and leave them as they are.
          </p>
          <C18Button00 type="button" className="outlined" onClick={()=>this.onChange("overrideConflict",{})}>
            Override</C18Button00>
          <C18Button00 type="button" className="filled" onClick={()=>this.onChange("cancelConflict",)}
          style={{marginLeft:20}}>
            Cancel</C18Button00>
        </div>
      )
    }else{return null}
  }

  checkControlConflict=(val1,val2)=>{// compares scalars and arrays
    if(Array.isArray(val1)){
      let ret=false
      val1.forEach((v,i)=>{ret=ret||(v!=val2[i])})
      return ret
    }else{
      return val1!=val2
    }
  }

  getCommonControlValues=(groupChans,commonControls)=>{
    cl("get common")
    var getControlPids=(type,pid)=>{// makes array of pids for array types of controls
      let pidArrs={showAuxControls:80,Triggers18b:18,pumpAdvancedSettings:6,durationOnOff:3,HumDeHum:4,retractableGreenhouse:11,equipmentOverride:9,pmAdvancedSettings:11,biweeklyRepeat:14,master18:3,pidAdvancedSettings:11}
      //{biweeklyRepeat:14,equipmentOverride:9}
      let arrCnt=pidArrs[type]
      if(arrCnt){
        let pids=[...Array(arrCnt).keys()].map(k=>{return pid+k})
        return pids
      }else{
        return pid
      }
    }
    var getControlValue=(zone,chan,contType,pid)=>{// gets array of values for array types
      let pids=getControlPids(contType,pid)
      if(Array.isArray(pids)){
        return pids.map(pid=>{return dbVals.z[zone.siteZoneIndex][chan][pid]})
      }else{
        return dbVals.z[zone.siteZoneIndex][chan][pid]
      }
    }
// for each channel, for each control, get the value
    let commonValues={}
    commonControls.forEach(cc=>{// go through the controls
      let [gwType,contPid,contType]=cc.split("-")
      let [cont,pid,type]=cc.split("-")
      let contValues={}
      var val0,conflict=false
      groupChans.forEach((ch,i)=>{// go through the channels
        let [zoneId,chan]=ch.key.split("+")
        let zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==zoneId})[0]
        let val=getControlValue(zone,chan,contType,+pid)
//         cl(val)
        contValues[ch.key]=val
        if(i){
//           conflict=conflict||(val!=val0)
          conflict=conflict||this.checkControlConflict(val,val0)
        }else{
          val0=val
        }
      })
      contValues.conflict=(conflict)?this.conflictHandler:null
      commonValues[cc]=contValues
    })
    cl(commonValues)
    return commonValues
  }

  getZoneCommonControlValues=(config,groupZones,commonControls)=>{
//     cl(config)
//     cl(dbVals)
//     cl(commonControls)
    let st=this.state
//     cl(this.state)
//     cl(this.props)
    var getControlPids=(config,pid)=>{
      let pids=groupPidArrs[config]
      if(pids){return pids}
      else{return pid}
    }
// for each channel, for each control, get the value
    let commonValues={}
    let configType=config.split("_")[0]
    commonControls.forEach(cc=>{
      let [cont,pid,type]=cc.split("-")
      let contValues={}
      var val0,conflict=false
      groupZones.forEach((zo,i)=>{
        let zoneId=zo
        let zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==zoneId})[0]
        var chan
        let parts=this.props.match.url.split("/")
//         cl(parts[4])
        if(parts[4].substring(0,8)=="channel_"){
          chan=this.zuci.split("-")[2]
        }else{
          chan=(["zone","setpoints"].includes(configType))?255:240
        }
        let pids=getControlPids(config,pid)
//         cl(pids)
        var vals
        if(Array.isArray(pids)){
          vals=pids.map(p=>{
            return dbVals.z[zone.siteZoneIndex][chan][p]
          })
        }else{
//           cl(zone.siteZoneIndex,chan,pid)
          vals=dbVals.z[zone.siteZoneIndex][chan][pid]
//           cl(vals)
        }
        contValues[zoneId]=vals
        if(i){
          conflict=conflict||this.checkControlConflict(vals,val0)
        }else{
          val0=vals
        }
      })
      contValues.conflict=(conflict)?this.conflictHandler:null
      commonValues[cc]=contValues
    })
//     cl(commonValues)
    return commonValues
  }

  getZoneCommonControls=(gr,vals)=>{
//     cl("zone conts")
//     cl(vals)
//     cl(gr)
    let st=Object.assign({},this.state,vals)
//     cl(st)
//     cl(this.fuiPageMap)
    let pa=this.props.parms
//     cl(st.selConfig)
//     cl(pa.adminInfo)
//     cl(this.fuiPageMap)
//     cl(this.state)
//     cl(st)
//     cl(this.fuiPageMap)
    let fuiPage =this.fuiPageMap[st.selConfig]// st.selConfig
//     cl(fuiPage)
    if(!fuiPage){return}
//     cl(fuiPage)
    let controls=fuiPage.controls
//     cl(controls[2].pid)
    let z0=globs.zonesInfo.info.filter(z=>{return z.zoneId==gr.members[0]})[0]
//     cl(z0)
    let gwType=z0.gatewayType||1800
    this.group0={controls:controls,gwType:gwType}
//     cl(controls)
    let commonControls=controls.filter(c=>{
//       cl(c)
      return c.pid>=0}).map(c=>{
      return `${gwType}-${c.pid}-${c.type}`
    })

//     cl(commonControls)
    for(let i=0;i<(controls||[]).length/*controlCount*/;i++){
      if(!this.controlRefs[i]){this.controlRefs[i]=React.createRef()}
    }
//     cl(controls)
//     cl(commonControls)
    this.commonValues=this.getZoneCommonControlValues(st.selConfig,gr.members,commonControls)
//     cl(this.commonValues)
    return commonControls
  }

  getCommonControls=(vals)=>{// vals will update the state, use the new values
//     cl("get common")
//     cl(this.chans)
//     cl(vals)
    let st=Object.assign({},this.state,vals)
//     cl(st)
//     cl("still")
    let gr=st.groups.filter(gr=>{return gr.v==this.props.parms.adminInfo})[0]// get selected
//     cl(st.groups)
//     cl(this.props.parms.adminInfo)
//     cl(gr)
    if(!gr){return[]}
    if(gr.type/*st.groupType*/=="zone"){return this.getZoneCommonControls(gr,vals)}
    let members=gr.members||[]
//     if(members.length==0){cl("No Members");return[]}
    let groupChans=this.chans.filter(c=>{return members.includes(c.key)})// all the chans in the group
//     cl(groupChans)
// each chan has an array of "keys" for the controls that it has
//     cl(groupChans[0])
//     this.controls=groupChans[0].controls
    this.group0=groupChans[0]||{}
//     cl(this.group0)
    let commonControls=this.group0.keys||[]
    cl(commonControls)
//     cl(groupChans[0].keys)
//     cl(this.group0.controls)
    for(let i=0;i<(this.group0.controls||[]).length/*controlCount*/;i++){
      if(!this.controlRefs[i]){this.controlRefs[i]=React.createRef()}
    }
    for(let i=1;i<groupChans.length;i++){// for each chan, filter to the controls this chan has
      let keys=groupChans[i].keys
      commonControls=commonControls.filter(cc=>{
        return keys.includes(cc)
      })
    }
    // cl(commonControls)
//     cl(groupChans)
    this.commonValues=this.getCommonControlValues(groupChans,commonControls)
//     cl(this.commonValues)
    // cl(commonControls)
    return commonControls
  }

  showCommonControls=()=>{
    let st=this.state
//     cl(st)
//     cl("get")
    let commonControls=st.commonControls//this.getCommonControls(st)
    if(!commonControls){return}
//     cl(commonControls)
    return(
      <div>
        <label htmlFor="commonControls">Group Common Controls</label>
        <div id="commonControls" style={{width:300,height:300,overflowY:"auto",
          borderStyle:"solid",borderWidth:1,borderRadius:10,padding:10,
        }}>
        {commonControls.map((cc,i)=>{
//           cl(this.controlTabCol[cc])
          let [tab,col]=this.controlTabCol[cc]
          return(
          <div key={i}>{`${tab}-${col}`}</div>
        )})}
        </div>
      </div>
    )
  }

  selectGroups=()=>{
    let st=this.state
//     cl(st)
    var members
//             <div className="clearfloat"></div><br/>
//             {this.showCommonControls()}
//     switch(st.groupType){
//       case "chan":
//         members=(
//           <div>
//             <div className="clearfloat"></div><br/>
//             {this.showMembers()}
//           </div>
//         )
//         break
//       case "zone":
//         members=(
//           <div>
//             <div className="clearfloat"></div><br/>
//             {this.showZoneMembers()}
//           </div>
//         )
//         break
//     }
    return(
      <div>
        {this.showSelectGroup()}
      </div>
    )
//         {this.showGroupType()}
//         <div className="clearfloat"></div><br/>
  }

  editGroups=()=>{
    let st=this.state
//     cl(st)
//     cl(this.props.parms)
    let pa=this.props.parms
    let gr=st.groups.filter(gr=>{return gr.groupId==pa.adminInfo})[0]
//     cl(gr)
//     cl(st)
    var members
//             <div className="clearfloat"></div><br/>
//             {this.showCommonControls()}
    switch(gr?.type){
      case "chan":
        members=(
          <div>
            <div className="clearfloat"></div><br/>
            {this.showMembers()}
          </div>
        )
        break
      case "zone":
//         cl("show zones")
        members=(
          <div>
            <div className="clearfloat"></div><br/>
            {this.showZoneMembers()}
          </div>
        )
        break
    }
    return(
      <div>
        <div className="clearfloat"></div><br/>
        {this.showEditGroup()}
        {members}
      </div>
    )
  }

  showFuiFields=()=>{
//     cl("show fui")
//     console.trace()
    let st=this.state
//     cl(st)
//     cl(this.props)
    let pa=this.props.parms
//     cl(pa)
//     cl(this.fuiPageMap)
//     cl(this.controlTabCol)
//     cl(this.chans)
    let gr=st.groups.filter(gr=>{return gr.groupId==pa.adminInfo})[0]// the selected group
//     cl(gr)
//     cl(st.selConfig)
    if(!gr||((gr.type=="zone")&&(!pa.adminInfo))){return}
//     cl("still")
    var key,parts,zoneId,chanId,zone,zInd,gwType,chan,pageType,commonControls
//     cl(gr)
    if(gr.type=="zone"){
      // cl("zone")
      key=(gr.members||[])[0]||""
//       cl(key)
//       cl(st.commonControls)
  //     cl(key)
      parts=key.split("+")// only makes sense for channel types
      zoneId=parts[0]
//       cl(zoneId)
//       chanId=+parts[1]
//       cl(st.selConfig.substring(0,8))
      if(st.selConfig.substring(0,8)=="channel_"){
        chanId=this.zuci.split("-")[2]
      }else{
        chanId=255
      }
//       cl(zoneId)
      zone=globs.zonesInfo.info.filter(z=>{
//         cl(z)
        return z.zoneId==zoneId})[0]
//       cl(zone)
  //     cl(zone.siteZoneIndex)
      zInd=zone.siteZoneIndex
  //     cl(zInd)
      gwType=zone.gatewayType||1800
//       cl(zone,zInd,gwType)

//       chan=this.chans.filter(c=>{return c.key==key})[0]
//       cl(chan)
      pageType=pa.adminInfo// st.selConfig//chan.chanType
      commonControls=st.commonControls//this.getCommonControls(this.state)
//       cl(commonControls)
//       cl("set")
//       return
    }else{// channel type
      // cl("chan")
      key=(gr.members||[])[0]||""
//       cl(gr)
      parts=key.split("+")
      zoneId=parts[0]
      chanId=+parts[1]
//       cl(zoneId)
      zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==zoneId})[0]
      zInd=zone?.siteZoneIndex
      gwType=zone?.gatewayType||1800
//       cl(key)
      cl(this.chans)
      chan=this.chans.filter(c=>{
//         cl(c.key)
        return c.key==key})[0]
//       cl(chan)
//       cl(key)
      pageType=chan?.chanType
      commonControls=st.commonControls//this.getCommonControls(this.state)
//       cl(commonControls)
    }
//     cl(gr)
//     cl(commonControls)
//     cl(gr)
//     cl(key)
//     cl(chan)

//     let zInd=0
    let unit=0
//     let sInd=1
//     let pageType="channel_Irrigation_Scheduled"
//     cl(sInd)
//     cl(pageType)
//     return(<div>test</div>)
//     cl(this.fuiPageType)
//     cl(st.conflictOverrides)
//     cl(commonControls)
//     cl(this.commonValues)
    return(
      <div>
        <LiveFui
          parms={{
          getPopup:this.props.parms.getPopup,
//           getPopup:o=>this.props.parms.getPopup("admin",o),
          mode:"c18",
          onChange:o=>this.onChange("fui",o),
          pageModified:false,
          gatewayType:gwType,
          commonControls:commonControls,
          commonValues:this.commonValues,
          conflictOverrides:st.conflictOverrides,
        }}
          match={{
          params:{
            pageType:this.fuiPageType,//st.fuiPageType,//pageType,
            zuci:`${zInd}-${unit}-${chanId}-0`,
//             url:`/usa/c18/fui/intemp_sensor/0-${unit}-240-${sInd}`,
          }
          }}
          notify={this.notify}
        />
      </div>
    )
  }

  showConfigSelect=()=>{
    let st=this.state
//     cl(st)
    let gr=st.groups.filter(gr=>{return gr.groupId==this.props.parms.adminInfo})[0]
//     cl(gr)
//     if((!gr)||(gr.type!=st.groupType)){return}
    let z0=(globs.zonesInfo.info.filter(z=>{return z.zoneId==(gr?.members||[])[0]})||[])[0]
//     cl(gr.members[0])
//     cl(z0)

    if(!z0){return}
//     if(!z0){return}
    let gwType=z0.gatewayType||1800
//     cl(z0)
//     cl(gr)
//     cl(st)
    if(gr.type!="zone"){return}
    let pages={1800:[
//       {v:"HumDeHum",t:"Hum and DeHum"},
      {v:"setpoints",t:"Setpoints"},
//       {v:"temp_Staging",t:"Temperature Staging"},
      {v:"unit_Accumulator",t:"Accumulator"},
      {v:"unit_Analog_Temp_Calibration",t:"Analog Temperature Calibration"},
      {v:"unit_Analog_Temp_Mapping",t:"Analog Temperature Mapping"},
      {v:"unit_Generic_Calibration",t:"Generic Calibration"},
      {v:"unit_Generic_Mapping",t:"Generic Mapping"},
      {v:"unit_Input_Calibration",t:"Input Calibration"},
      {v:"unit_Input_Mapping",t:"Input Mapping"},
      {v:"unit_Input_Multipliers",t:"Input Multipliers"},
      {v:"unit_Irrigation_Sensor_Mapping",t:"Irrigation Sensor Mapping"},
      {v:"unit_Miscellaneous",t:"Miscellaneous"},
//       {v:"unit_Mixing_Tank_Calibration",t:"Mixing Tank Calibration"},
//       {v:"unit_Mixing_Tanks",t:"Mixing Tanks"},
      {v:"unit_Network_Sensors",t:"Network Sensors"},
      {v:"unit_Soil_Moisture_Calibration",t:"Soil Moisture Calibration"},
      {v:"unit_Vent_Position_Calibration",t:"Vent Position Calibration"},
      {v:"unit_Vent_Position_Mapping",t:"Vent Position Mapping"},
      {v:"zone_Alarms",t:"Alarms"},
//       {v:"zone_Aux_Alarms",t:"Aux Alarms"},
//       {v:"zone_Aux_Controls",t:"Aux Controls"},
//       {v:"zone_Aux_PVariables",t:"Aux PVariables"},
//       {v:"zone_Aux_Variables",t:"Aux Variables"},
      {v:"zone_Deadband",t:"Deadband"},
      {v:"zone_Fallback",t:"Fallback"},
      {v:"zone_H-C_Demand",t:"H-C Demand"},
      {v:"zone_History",t:"History"},
      {v:"zone_Hum_DeHum",t:"Hum DeHum"},
      {v:"zone_Irrigation",t:"Irrigation"},
      {v:"zone_Lighting",t:"Lighting"},
      {v:"zone_Output",t:"Output"},
      {v:"zone_Peristaltic",t:"Peristaltic"},
      {v:"zone_Setpoints",t:"Setpoints"},
      {v:"zone_Smartcool",t:"Smartcool"},
      {v:"zone_SP_Drive_to_Avg",t:"SP Drive to Avg"},
      {v:"zone_SP_Influence_Factors",t:"SP Influence Factors"},
      {v:"zone_SP_Retractable_Greenhouse",t:"SP Retractable Greenhouse"},
      {v:"zone_Stages",t:"Stages"},
      {v:"zone_Units",t:"Units"},
    ],
    1900:[
//       {v:"HumDeHum",t:"Hum and DeHum"},
      {v:"setpoints",t:"Setpoints"},
//       {v:"temp_Staging",t:"Temperature Staging"},
      {v:"unit_Accumulator",t:"Accumulator"},
      {v:"unit_Analog_Temp_Calibration",t:"Analog Temperature Calibration"},
      {v:"unit_Analog_Temp_Mapping",t:"Analog Temperature Mapping"},
      {v:"unit_Generic_Calibration",t:"Generic Calibration"},
      {v:"unit_Generic_Mapping",t:"Generic Mapping"},
      {v:"unit_Input_Calibration",t:"Input Calibration"},
      {v:"unit_Input_Mapping",t:"Input Mapping"},
      {v:"unit_Input_Multipliers",t:"Input Multipliers"},
      {v:"unit_Irrigation_Sensor_Mapping",t:"Irrigation Sensor Mapping"},
      {v:"unit_Miscellaneous",t:"Miscellaneous"},
//       {v:"unit_Mixing_Tank_Calibration",t:"Mixing Tank Calibration"},
//       {v:"unit_Mixing_Tanks",t:"Mixing Tanks"},
      {v:"unit_Network_Sensors",t:"Network Sensors"},
      {v:"unit_Soil_Moisture_Calibration",t:"Soil Moisture Calibration"},
      {v:"unit_Vent_Position_Calibration",t:"Vent Position Calibration"},
      {v:"unit_Vent_Position_Mapping",t:"Vent Position Mapping"},
      {v:"zone_Alarms",t:"Alarms"},
//       {v:"zone_Aux_Alarms",t:"Aux Alarms"},
//       {v:"zone_Aux_Controls",t:"Aux Controls"},
//       {v:"zone_Aux_PVariables",t:"Aux PVariables"},
//       {v:"zone_Aux_Variables",t:"Aux Variables"},
      {v:"zone_Deadband",t:"Deadband"},
      {v:"zone_Fallback",t:"Fallback"},
      {v:"zone_H-C_Demand",t:"H-C Demand"},
      {v:"zone_History",t:"History"},
//       {v:"zone_Hum_DeHum",t:"Hum DeHum"},
      {v:"zone_Irrigation",t:"Irrigation"},
      {v:"zone_Lighting",t:"Lighting"},
      {v:"zone_Output",t:"Output"},
      {v:"zone_Peristaltic",t:"Peristaltic"},
      {v:"zone_Setpoints",t:"Setpoints"},
      {v:"zone_Smartcool",t:"Smartcool"},
      {v:"zone_SP_Drive_to_Avg",t:"SP Drive to Avg"},
      {v:"zone_SP_Influence_Factors",t:"SP Influence Factors"},
      {v:"zone_SP_Retractable_Greenhouse",t:"SP Retractable Greenhouse"},
      {v:"zone_Stages",t:"Stages"},
      {v:"zone_Units",t:"Units"},
      ]
    }
    return(
      <div style={{width:300,marginBottom:20}}>
        <label htmlFor="selGroup">Select Configuration Page</label>
        <C18Select00 id="selGroup"
          parms={{list:true,height:200}}
          value={st.selConfig}
          onChange={e=>{
            let vals={selConfig:e.currentTarget.value}
//             cl(vals)
            this.onChange("selConfig",vals)}
          }
        >
          {makeOpts(pages[gwType])}
        </C18Select00>
      </div>
    )
  }

  applyGroup=()=>{
    // cl("apply group")
//         <div className="clearfloat"></div><br/>
//         {this.showEditGroup()}
//         <div className="clearfloat"></div><br/>
//         {this.showGroupType()}
//         <div className="clearfloat"></div><br/>
//         {this.showMembers()}
//         <div className="clearfloat"></div><br/>
//         {this.showCommonControls()}
    let st=this.state
//     cl(st)
//     if(acctFeature("zoneGroups")&&globs.userData.session.groupId){
//       cl(st)
//       cl(this.fuiPageType)
//     }
    let gr=st.groups.filter(gr=>{return gr.groupId==this.props.parms.adminInfo})[0]
//     cl(gr)
//         {this.showConfigSelect()}
    return(
      <div>
        {this.showFuiFields()}
        {this.showConflict()}
      </div>
    )
  }

  zoneGroup=()=>{
    return(
      <div>Zone Group</div>
    )
  }

  render(){
    let st=this.state
//     cl(this.commonValues)
//     cl(st.groupType)
    let pa=this.props.parms
//     cl(pa)
//     cl(st)
    let pages={"selectGroup":this.selectGroups,"editGroup":this.editGroups,
      "applyGroup":this.applyGroup,"zoneGroup":this.applyGroup}
    if(st.loaded){
//       cl(pa.adminPage)
      return pages[pa.adminPage]()
    }else{
      return <div id="content-area">loading . . .</div>
    }
  }
}

export default C18GroupsEdit00 ;

