import React from 'react';
import C18Anchor00 from './C18Anchor00'
import ReactHtmlParser from 'react-html-parser'
import C18SubMenuHeader00 from './C18SubMenuHeader00'
import {wsTrans,sensorIds,getUId} from '../utils/utils'
import {cl,globs,constant,dateToDisplayDate} from '../../components/utils/utils';

class C18TechPortal00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      pageType:"users",
      selUser:"",
      editObj:{},
      editId:"",
      editKey:"",
    }
    this.pageTitles={accounts:"Accounts",sites:"Sites",gateways:"Gateways",
      zones:"Zones",users:"Users",}
    this.pageParms={
      users:["userSearch","users","name","email","userId","selUser"],
      accounts:["accountSearch","accounts","name","adminEmail","accountId","selAccount"],
      sites:["siteSearch","sites","name","","siteId","selSite"],
      gateways:["gatewaySearch","gateways","name","","gatewayId","selGateway"],
      zones:["zoneSearch","zones","zoneName","","zoneId","selZone"],
    }
    this.itemIds={accounts:"accountId",sites:"siteId",gateways:"gatewayId",zones:"zoneId",users:"userId"}
    this.loadInfo()
    this.setBreadCrumbs()
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Tech Portal", url:`/usa/c18/admin/techPortal2`},
            ]},
        },
      )
    }
  }
  
  loadWsTrans=async(parms)=>{
    return await wsTrans("usa", {cmd: "cRest", uri: parms.uri, 
      method: parms.method||"retrieve", sessionId: globs.userData.session.sessionId, 
      body: parms.body})
    
  }
  
  loadInfo=async()=>{
// users, sites, accounts, zones, gateways,   
    let users=(await this.loadWsTrans({uri:"/su/allUsers"})).data
    let accounts=(await this.loadWsTrans({uri:"/su/suAccounts"})).data
    let sites=(await this.loadWsTrans({uri:"/su/allSites"})).data
    let gateways=(await this.loadWsTrans({uri:"/su/allGateways"})).data
    let zones=(await this.loadWsTrans({uri:"/su/allZones"})).data
    this.setState({loaded:true,
      users:users,
      accounts:accounts,
      sites:sites,
      gateways:gateways,
      zones:zones,
    })
  }
  
  infoClick=(co,obj)=>{
//     cl(obj)
    let pp=this.pageParms[co]
    let selId=pp[5]
    let itemId=pp[4]
    let nameId=pp[2]
    let searchId=pp[0]
    let vals={pageType:co}
    vals[selId]=obj[itemId]
    vals[searchId]=obj[nameId]
//     Object.assign(vals,{infoArr:this.makeInfoArr(vals)})
//     cl(vals)
    this.setState(vals)
    
  }
  
  onChange=(type,vals)=>{
//     cl([type,vals])
    switch(type){
      case "infoLine":
        vals.e.preventDefault()
        cl(vals)
        globs.events.publish("showInfoPage", vals.infoId)
        break
      case "searchText":
        let val2={}
        val2[vals.id]=vals.search
//         cl(val2)
        this.setState(val2)
        break
      case "itemClick":
        Object.assign(vals,{infoArr:this.makeInfoArr(vals)})
//         cl(vals)
        this.setState(vals)
        break
      case "userAttribute":
//         cl(vals)
        this.setState(vals)
        break
      case "attEdit":
//         cl(vals)
        vals.obj[vals.key]=vals.val
        break
      case "pageType":
//         cl(vals)
        this.setState(vals)
        break
      case "infoClick":
//         cl(vals)
        this.infoClick(vals.co,vals.obj)
        break
    }
  }
  
  getList=(obj,listId,type)=>{// obj is the selected item
    let res=this.state[listId].filter(ga=>{
      let match=true
      if(obj.accountId){match=match&&(ga.accountId==obj.accountId)}
      if(listId=="accounts"){return match}
      if(obj.siteId){match=match&&(ga.siteId==obj.siteId)}
      if(listId=="sites"){return match}
      if(obj.gatewayId){match=match&&(ga.gatewayId==obj.gatewayId)}
      if(listId=="gateways"){return match}
      if(obj.zoneId){match=match&&(ga.zoneId==obj.zoneId)}
      return match
    })
    return res
  }
  
  getItem=(vals)=>{
    let st=this.state
    let type=st.pageType
//     cl(vals)
//     cl(this.state.pageType)
    let itemId=this.itemIds[type]
    let selId=Object.values(vals)[0]
    return st[type].filter(us=>{return us[itemId]==selId})[0]
  }
  
  makeInfoArr=(vals)=>{
    let st=this.state
    let item=this.getItem(vals)
    let type=st.pageType
    let cols={}
    Object.keys(this.itemIds).forEach(id=>{
      if(id!=type){
        cols[id]=this.getList(item,id,type)
      }
    })
    return cols
  }
  
  showSearch=(id)=>{
    return(
      <div>
        <label style={{display:"inline-block",marginRight:10}}>
          Search:</label>
        <input 
        value={this.state[id]||""}
        onChange={e=>this.onChange("searchText",{id:id,search:e.currentTarget.value})}
        />
      </div>
    )
  }
  
  filterList=(searchId,type,field1,field2)=>{
    let st=this.state
    let search=(st[searchId]||"").toLowerCase()
    let i=0
    let users=st[type].filter(us=>{
//       cl(us)
      let f1=(us[field1]||"").toLowerCase().indexOf(search)>=0
      let f2=(us[field2]||"").toLowerCase().indexOf(search)>=0
      return (f1||f2)&&(i++<20)
    })
    return users
  }
  
  showOneItemVal=(st,item,itemId,key)=>{
    let val=item[key]
    if(val&&(typeof(val)=="object")){val=JSON.stringify(val)}
    if((item[itemId]==st.editId)&&(key==st.editKey)){
      return(
        <td>
          <input
            value={val}
            onChange={e=>this.onChange("attEdit",{obj:item,key:key,val:e.currentTarget.value})}
          />
        </td>
      )
    }else{
      return(
        <td style={{cursor:"pointer"}}
        onClick={e=>this.onChange("userAttribute",{editObj:item,editId:item[itemId],editKey:key})}
        >
          {val}
        </td>
      )
    }
  }
  
  showOneItem=(type,itemId,selId)=>{// "users","userId","selUser"
    let st=this.state
    let item=(st[type].filter(us=>{return us[itemId]==st[selId]})||[])[0]
    var lines=[]
    if(item){
      delete item["_id"]
      lines=Object.keys(item).map((k,i)=>{
        return(
          <tr key={i}>
          <td>{k}</td>
          {this.showOneItemVal(st,item,itemId,k)}
          </tr>
        )
      })
    }
    return(
      <div style={{display:"inline-block",width:400,height:200,overflowY:"auto"}}>
        <table><tbody>
        {lines}
        </tbody></table>
      </div>
    )
  }
  
  showItems=(searchId,type,field1,field2,itemId,selId)=>{
    let items=this.filterList(searchId,type,field1,field2)
//     cl(items)
    return(
      <div style={{display:"inline-block"}}>
        {this.showSearch(searchId)}
        <div style={{width:200, height:200, overflowY:"auto",backgroundColor:"#DDDDDD"}}>
        {items.map((us,i)=>{
//           let name=`${us[field1]}-${us[field2]}`.substring(0,22)
          let name=us[field1]||""
          let name2=us[field2]
//           cl([field1,field2])
//           cl(name)
          name=((name2)?`${name}-${name2}`:name).substring(0,22)
          let val={}
          val[selId]=us[itemId]
          return(
            <div key={i} style={{cursor:"pointer"}}
            onClick={e=>this.onChange("itemClick",val)}
            >
            {name}
            </div>
          )
        })}
        </div>
      </div>
    )
  }
  
  showInfoEntryLink=()=>{
    
    
  }
  
  showInfoEntry=(co,obj)=>{
    let pp=this.pageParms[co]
    let nameId=pp[2]
    return(
      <span style={{cursor:"pointer"}}
        onClick={e=>this.onChange("infoClick",{co:co,obj:obj})}
      >
      {obj[nameId]}
      </span>
    )
    
    
    switch(co){
      case "accounts":
        return(
          <span style={{cursor:"pointer"}}
            onClick={e=>this.onChange("infoClick",{co:co,obj:obj})}
          >
          {obj.name}
          </span>
        )
        return obj.name
        break
      case "sites":
        return obj.name
        break
      case "gateways":
        return obj.name
        break
      case "zones":
        return obj.zoneName
        break
      case "users":
        return obj.name
        break
    }
  }
  
  showInfoArr=()=>{
//     cl(this.state.infoArr)
    let ia=this.state.infoArr
    if(!ia){return}
    let cols=Object.keys(ia)
//     cl(cols)
    let lineCnt=0
    let heads=cols.map((co,i)=>{
      let len=ia[co].length
      if(lineCnt<len){lineCnt=len}
      return <td key={i}>{co}</td>})
//     cl(lineCnt)
    let lines=[]
    for(let i=0;i<lineCnt;i++){
      let line=cols.map((co,j)=>{
        if(ia[co].length>i){
//           cl(ia[co])
          let name=this.showInfoEntry(co,ia[co][i])
//           cl(name)
          return <td key={j}>{name}</td>
        }else{
          return <td key={j}></td>
        }
      })
//         cl("push")
      lines.push(
        <tr key={i}>{line}</tr>
      )
    }
    return(
      <>
      <h2>Related Values</h2>
      <table><tbody>
      <tr>{heads}</tr>
      {lines}
      </tbody></table>
      </>
    )
//     return(
//       
//     )
  }
  
  render(){
    let st=this.state
    let [searchId,type,field1,field2,itemId,selId]=this.pageParms[st.pageType]
    if(st.loaded){
      return(
        <div>
            <C18SubMenuHeader00 parms={{
              items:[
                {v:"accounts",t:"Accounts"},
                {v:"sites",t:"Sites"},
                {v:"gateways",t:"Gateways"},
                {v:"zones",t:"Zones"},
                {v:"users",t:"Users"},
              ],
              pageType: this.state.pageType,
              onChange: o=>this.onChange("pageType",o),
            }}/>
            <div className="clearfloat"/>
            
            <h3>{this.pageTitles[st.pageType]}</h3>
            {this.showItems(searchId,type,field1,field2,itemId,selId)}
            {this.showOneItem(type,itemId,selId)}
            {this.showInfoArr()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18TechPortal00;
