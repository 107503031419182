import React from 'react';
import UsaIcon from './UsaIcon';
import UsaSelect00 from './UsaSelect00';
import {cl} from '../../components/utils/utils';

class Position00 extends React.Component{
  constructor(props) {// 
    super(props);
//     cl(props)
    this.state=Object.assign({}, props.parms.vals)
    this.state.editMode=false
//     this.state={
//       editMode: false,
//       left: 0,
//       top: 0,
//       width: 1,
//       height: 1,
//     }
  }
  
  saveRestorePos=(cmd)=>{
    switch(cmd){
      case "save":
        this.back=this.state
        break
      default:
        let b=this.back
        this.setState({left: b.left, top: b.top, width: b.width, height: b.height})
        break
    }
  }
  
  result=(type)=>{
//     cl(type)
    switch(type){
      case "position-OK":
        this.props.onChange(this.state)
        this.setState({editMode: false})
        break
      case "position-Cancel":
        this.saveRestorePos("restore")
        this.setState({editMode: false})
        break
      case "position-Edit":
        this.saveRestorePos("save")
        this.setState({editMode: true})
        break
      default:
        break
    }
  }
  
  editPosition=()=>{
    return(
      <div>
      <UsaIcon icon="position-OK" result={this.result} inline/>
      <UsaIcon icon="position-Cancel" result={this.result} inline/>
      <UsaIcon icon="position-Edit" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Position:</h3></span>
      <div></div>
      <UsaSelect00 parms={{
        title: "Left",
        width: 100,
        inline: true,
        value: this.state.left,
        valueId: "left",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Top",
        width: 100,
        inline: true,
        value: this.state.top,
        valueId: "top",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Width",
        width: 100,
        inline: true,
        value: this.state.width,
        valueId: "width",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Height",
        width: 100,
        inline: true,
        value: this.state.height,
        valueId: "height",
        onChange: o=>this.setState(o),
      }}/>
      </div>
    )
  }
  
  displayPosition=()=>{
    let s=this.state
    return(
      <div>
      <UsaIcon icon="position-Edit" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Position:</h3></span>
      {` x: ${s.left}, y: ${s.top}, w: ${s.width}, h: ${s.height}`}
      </div>
    )
  }
  
  render(){
    if(this.state.editMode){
      return this.editPosition()
    }else{
      return this.displayPosition()
    }
  }
}

export default Position00 ;

