import React from 'react';
import UsaSelect00 from '../UsaSelect00'
import UsaArrayFlags00 from '../UsaArrayFlags00'

import {cl, arrToFlags, flagsToArr} from '../../../components/utils/utils';

class RepeatMonthDays extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      months: 1,
      days: []
    }
    for(let i=0;i<31;i++){this.state.days.push(false)}
    this.repeat=this.props.parms.repeat
  }
  
  onChange=(type, vals)=>{
/* here, we store the info as days and an array of hours. we report that to the parent as period and details(flags)*/
    let s=Object.assign(this.state, vals)
    this.repeat={
      period: s.months,
      details: arrToFlags(s.days),
    }
    this.setState(vals)
    this.props.parms.onChange({repeat: this.repeat})
  }
  
  render(){
    let dayOpts=[]
    for(let i=0;i<31;i++){dayOpts.push(i+1)}
    return(
      <div>
      <UsaSelect00 parms={{
        title: "Repeat\u00A0Every",
        footer: "Months",
        footerPlural: true,
        type: "number",
        min: 1,
        width: 50,
        select: false, // !this.state.editMode,
        valueId: "months",//valueId, // "widgetSel",
        value: this.state.months, //value, // this.state.widgetSel,
        onChange: vals=>this.onChange("parts",vals),
      }}/>
      <div style={{height: 10}}/>
      on these days:
      <UsaArrayFlags00 parms={{
        inline: true,
        opts: dayOpts,
        columns: 7,
        valueId: "days",
        value: this.state.days,
        onChange: (vals)=>this.onChange("parts", vals),
      }}/>
      </div>
    )
  }
}

export default RepeatMonthDays ;

