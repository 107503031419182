import React from 'react';
import UsaIcon from './UsaIcon';
import UsaSelect00 from './UsaSelect00';
import DateTime00 from './DateTime00'
import UsaCheckbox00 from './UsaCheckbox00';
import {cl,constant, dateToYrMoDa} from '../../components/utils/utils';

class TimePeriod00 extends React.Component{
  constructor(props) {// 
    super(props);
//         cl(props)
    this.state={
      editMode: false,
      liveMode: true,
      flags: props.parms.vals.flags,
      duration: props.parms.vals.duration,
      period: props.parms.vals.period,
      beginTime: props.parms.vals.beginTime,//new Date(),
      endTime: props.parms.vals.endTime,//new Date(),
      periodType: props.parms.vals.periodType,
      barCount: props.parms.vals.barCount,
      showValueNames: props.parms.vals.showValueNames,
      showValues: props.parms.vals.showValues,
    }
    this.periods=[
      {v: 1, t: "Seconds"},
      {v: 60, t: "Minutes"},
      {v: 3600, t: "Hours"},
      {v: 24*3600, t: "Days"},
      {v: 7*24*3600, t: "Weeks"},
      {v: 31*24*3600, t: "Months"},
      {v: 365*24*3600, t: "Years"},
    ]
    this.makePeriodToDesc()
  }
  
  makePeriodToDesc=()=>{
    this.periodDesc={}
    this.periods.forEach(tp=>{
      this.periodDesc[tp.v]=tp.t
    })
  }
  
  saveRestorePeriod=(cmd)=>{
    switch(cmd){
      case "save":
        this.back=this.state
        break
      default:
//         let b=this.back
        this.setState(this.back)
        break
    }
  }
  
  result=(type)=>{
    switch(type){
      case "period-OK":
        this.props.onChange(this.state)
        this.setState({editMode: false})
        break
      case "period-Cancel":
        this.saveRestorePeriod("restore")
        this.setState({editMode: false})
        break
      case "period-Edit":
        this.saveRestorePeriod("save")
        this.setState({editMode: true})
        break
      default:
        break
    }
  }
  
  setStateValue=(val)=>{
//     cl(this.state)
    this.setState(val)
//     let tmp={
//       duration: this.state.duration,
//       period: this.state.period,// 1 day
//     }
//     let ret=Object.assign(tmp, val)
//     this.props.onChange(ret)
//     cl(ret)
    
  }
  
//   timeDateToTimestamp=(time, date)=>{
//     let str=`${date}T${time}`
//     return Math.floor(new Date(str).getTime()/1000)
//   }
//   
//   timestampToDate=(ts)=>{
//     return new Date(ts*1000)
//   }
  
  onChange=(type, vals)=>{
     cl(vals)
    
    switch(type){
      case "liveMode":
        let flags=this.state.flags & ~constant.TIME_PERIOD_LIVE
        if(vals.liveMode)flags|=constant.TIME_PERIOD_LIVE
        vals={flags: flags}
        break
      default:
        break
    }
    this.setState(vals)
    let obj=Object.assign(this.state, vals)
    this.props.onChange(obj)
  }
  
  showSetTime=()=>{
//     cl(this.state)
    return([
      "Begin Time",
      <DateTime00 key={0} 
        parms={{
          date: this.state.beginTime,
          valueId: "beginTime",
          id: "begin"}} 
        onChange={o=>this.onChange("begin", o)}
      />,
      "End Time",
      <DateTime00 key={1} 
        parms={{
          date: this.state.endTime,
          valueId: "endTime",
          id: "end"}} 
        onChange={o=>this.onChange("end", o)}
      />
      ]
    )
  }
  
  showLiveTime=(barChartType)=>{
//     cl(this.state)
    let title="Duration"/(barChartType)?"Interval":"Duration"
    return(
      [
        <UsaSelect00 key={0} parms={{
          title: title,
          width: 100,
          inline: true,
          valueId: "duration",//valueId, // "widgetSel",
          value: this.state.duration, //value, // this.state.widgetSel,
          //         textId: "widgetName",
          opts: this.widgetOpts,
          onChange: o=>{this.onChange("duration", o)},
        }}/>,
        <UsaSelect00 key={1} parms={{
          title: "Unit",
          select: true, // !this.state.editMode,
          inline: true,
          valueId: "period",//valueId, // "widgetSel",
          value: this.state.period, //value, // this.state.widgetSel,
          opts: this.periods,
          onChange: o=>{this.onChange("period", o)},
        }}/>
      ]
    )
  }
  
  showSingleBarParms=()=>{
/* Show Value Names, Show Values*/
    return(
      <div>
        <UsaCheckbox00 parms={{
          title: "Show\u00A0Value\u00A0Names",
          value: this.state.showValueNames || false,
          valueId: "showValueNames",
          onChange: o=>this.onChange("showValueNames", o),
        }}/>
        <UsaCheckbox00 parms={{
          title: "Show\u00A0Values",
          value: this.state.showValues || false,
          valueId: "showValues",
          onChange: o=>this.onChange("showValues", o),
        }}/>
      </div>
    )
    
  }
  
  editTimePeriod=()=>{
    let periodTypeOpts=[
      {v:"barInterval", t:"Interval"},
      {v:"barSingle", t:"Single"},
    ]
    let barChartType=((this.props.parms.graphType=="bar")||(this.props.parms.graphType=="3dBar"))
    let barSingle=barChartType&&(this.state.periodType=="barSingle")
    let liveMode=barChartType || !!(this.state.flags & constant.TIME_PERIOD_LIVE)
    return(
      <div>
      <UsaIcon icon="period-OK" result={this.result} inline/>
      <UsaIcon icon="period-Cancel" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Time Period:</h3></span>
      <div></div>
      {barChartType&&
        <>
        <UsaSelect00 parms={{
          title: "Bar\u00A0Chart\u00A0Type",
          inline: true,
          select: true, // !this.state.editMode,
          valueId: "periodType",//valueId, // "widgetSel",
          value: this.state.periodType || "barInterval", //value, // this.state.widgetSel,
          opts: periodTypeOpts,
          onChange: o=>this.setState(o),
        }}/>
        {!barSingle&&
          <UsaSelect00 parms={{
            title: "Number\u00A0of\u00A0Bars",
            inline: true,
            valueId: "barCount",//valueId, // "widgetSel",
            value: this.state.barCount || 10, //value, // this.state.widgetSel,
            onChange: o=>this.setState(o),
          }}/>
        }
        </>
      }
      {!barChartType&&
        <UsaCheckbox00 parms={{
          title: "Live",
          value: liveMode,
          valueId: "liveMode",
          onChange: o=>this.onChange("liveMode", o),
        }}/>
      }
      {barSingle && this.showSingleBarParms()}
      {!barSingle && liveMode && this.showLiveTime(barChartType)}
      {!liveMode && this.showSetTime()}
      </div>
    )
  }
  
  showShortTimePeriod=()=>{
    if(this.state.flags&constant.TIME_PERIOD_LIVE){
      return ` ${this.state.duration} ${this.periodDesc[this.state.period]}`
    }else{
      let da=new Date(1000*this.state.beginTime)
      return dateToYrMoDa(da)
    }
  }
  
  displayTimePeriod=()=>{
    let barChartType=((this.props.parms.graphType=="bar")||(this.props.parms.graphType=="3dBar"))
    let barSingle=barChartType&&(this.state.periodType=="barSingle")
    return(
      <div>
      <UsaIcon icon="period-Edit" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Time Period:</h3></span>
      {!barSingle && (" " + this.showShortTimePeriod())}
      {barSingle && (" Bar Chart Single Values")}
      </div>
    )
  }
  
  render(){
    if(this.state.editMode){
      return this.editTimePeriod()
    }else{
      return this.displayTimePeriod()
    }
  }
}

export default TimePeriod00 ;

