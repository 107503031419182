import React from 'react';
import {cl, globs, leadZeros} from '../../components/utils/utils';

class CravoInput extends React.Component{
  constructor(props) {
    super(props);
//     this.state={
//       value: "",
//       focused: false,
//       msg: ""
//     }
  }

  isArray=(valueId)=>{
    return ["pos","activePos","thresh"].includes(valueId)
  }
  render(){
    let label=this.props.label
    let color=this.props.color
    let width=this.props.width
    let marginLeft=this.props.marginLeft
    let valueId=this.props.valueId
    let ind=this.props.ind
    let wids={"none": 30, "min": 30, "%": 30, "mph": 30, "minutes": 30, "\u00B0F": 40, "\u00B0C": 30, "seconds": 30, "W/m\u00B2": 30,}
    let pRight={"none": 0, "min": 35, "%": 20, "mph": 40, "minutes": 70, "\u00B0F": 25, "\u00B0C": 25, "seconds": 65, "W/m\u00B2": 45,}
    let text=(label=="none")?"":label
    let style = {width: width, marginLeft: marginLeft}
//     var val
//     if(this.isArray(valueId)){
//       val=this.state[valueId][ind]
//     }else{
//       val=this.state[valueId]
//     }
    return[
      <input key={0}
      type="number"
      style={style}
      value={this.props.value}
      onChange={e=>{
//         cl(valueId,ind,e)
        this.props.onChange(valueId, ind, e)}
      }
      />,
      <span key={1}>{text}</span>
    ]
  }
}

export default CravoInput ;
