import React from 'react';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import C18MuiFormControl from '../../usa/components/C18MuiFormControl'
import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
import C18MuiSelect from '../../usa/components/C18MuiSelect'
import C18MuiMenuItem from '../../usa/components/C18MuiMenuItem'
import {cl, globs} from '../../components/utils/utils';

class Master18 extends React.Component{
  constructor(props) {
    super(props);
    this.props.getValue(props.ind, {type: "array", count: 3})
  }
/*these 3 values:
pi[1800].config_channels_configuration["feedingMode"] = 57
pi[1800].config_channels_configuration["fine_adjust_range"] = 58
pi[1800].config_channels_configuration["followChannelIndex"] = 59
*/

  onChange=(e)=>{
//     cl(e.target.value)
    let vals = this.props.value.slice(0);
    if (e.target.value === -1){// master
      vals[0] = 0;// master
      vals[2] = 0;
    }else{
      vals[0] = 1;
      vals[2] = e.target.value;
    }
//     cl(vals)
    this.props.onChange(this.props.ind,
      {value: vals})
  }

  getMasterOptions=()=>{
//     cl(this.props.current.masters)
    let options = [{t: "Master Pump", v: -1}]
    this.props.current.masters.forEach((p, i)=>{
      options.push({t: "Follow " +
        this.props.current.channelNames[p], v: p})
    })
    return options;
  }

  makeSelect=()=>{
    let opt=this.getMasterOptions();
//     cl(opt)
    return opt.map((c, i)=>{
      return(
        <C18MuiMenuItem key={i} value={c.v}>{c.t}</C18MuiMenuItem>
      )
    });
  }

  getMasterValue=(vals)=>{
//     cl(vals)
    if (vals[0]*1 === 0){
      return -1;
    }else{
      let masters = this.props.current.masters;
//       cl(masters)
      for(let i = 0 ; i < masters.length ; i++){
        if (vals[2]*1 == masters[i]){
          return masters[i]//i + 1;// returning *index*
        }
      }
    }
    return -1;
  }

  render(){
//     cl(this.props);
    let val = this.getMasterValue(this.props.value);
//     cl(val)
    return(
      <div>
        <C18MuiFormControl
        style={{minWidth: 150}}
        >
          <C18MuiInputLabel>{this.props.cont.title}</C18MuiInputLabel>
          <C18MuiSelect
            value={val}
            onChange={this.onChange}
            saveOK={this.props.saveOK}
            >
            {this.makeSelect()}
          </C18MuiSelect>
        </C18MuiFormControl>
      </div>
    );
  }
}

  export default Master18 ;
