import React from "react";
// import TextField from "@material-ui/core/TextField";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Input from "@material-ui/core/Input";
// import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import C18MuiTextField from '../../usa/components/C18MuiTextField'
import C18MuiFormControl from '../../usa/components/C18MuiFormControl'
import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
import C18MuiInputAdornment from '../../usa/components/C18MuiInputAdornment'
import C18MuiInput from '../../usa/components/C18MuiInput'
import C18MuiIconButton from '../../usa/components/C18MuiIconButton'
import {cl} from "../../components/utils/utils";
import Tooltip from "@material-ui/core/Tooltip";




class TimeInput extends React.Component{
    constructor(props) {
        super(props);
        this.props.getValue(props.ind, {type: "scalar"}); //Get value from DB
        this.timeInSeconds = 80542;
        this.hour = true;
        this.min = true;
        this.sec = true;
        this.units = "seconds";
        if ("custom" in props){
            if ("hour" in props.custom)
                this.hour = props.custom.hour;
            if ("min" in props.custom)
                this.min = props.custom.min;
            if ("sec" in props.custom)
                this.sec = props.custom.sec;
            if ("units" in props.custom)
                this.units = props.custom.units;
        }
        this.length = 0;
        if (this.day)
            this.length++;
        if (this.hour)
            this.length++;
        if (this.min)
            this.length++;
        if (this.sec)
            this.length++;
        this.state={
            displayValue: this.formatSeconds(true),
            open: false,
        }
    }

    /*handleMouseDownOptions = event => {
        cl("Handle mouse show options")
        event.preventDefault();
    };

    handleClickShowOptions = event =>{
        cl("Handle CLick Show options")
    };
    */

    updateSeconds(value, target){
        let s = this.timeInSeconds;
        let h   = Math.floor(s / 3600);
        s  -= h * 3600;
        let m = Math.floor(s / 60);
        s  -= m * 60;
        if (target === "h")
            h = value;
        else if (target === "m")
            m = value;
        else if (target === "s")
            s = value;
        this.timeInSeconds = (h*3600) + (m*60) + s;

    }

    formatSeconds(nice){
        let s = this.timeInSeconds;
        const h   = Math.floor(s / 3600);
        s  -= h * 3600;
        const m = Math.floor(s / 60);
        s  -= m * 60;
        let val = "";
        if (this.hour)
            val += (h >= 10 ? h : "0"+h)+(nice ? "h " : "");
        if (this.min)
            val += (m >= 10 ? m : "0"+m)+(nice ? "m " : "");
        if (this.sec)
            val += (s >= 10 ? s : "0"+s)+(nice ? "s " : "");
        return val;
    }

    //01 05 06
    convertTimeStringToSeconds(timeString){
        cl(timeString);
        let splitValue = timeString.match(/.{1,2}/g);
        if (timeString.length > 0 || splitValue == null)
            return;
        cl("CTSTS: " + splitValue);
        let seconds = 0;
        if (splitValue.length === 3){
            seconds+= splitValue[0]*3600;
            seconds+= splitValue[1]*60;
            seconds+= splitValue[2]*1;
        }else if(splitValue.length===2) {
            seconds+= splitValue[0]*60;
            seconds+= splitValue[1]*1;
        }else if (splitValue.length === 1){
            seconds+= splitValue[0]*1;
        }
        return seconds;
    }

    handleBlur = () => {
        cl("Handle Blur")
        this.setState({
            open: false,
            displayValue: this.formatSeconds(true),
            tooltipValue: this.formatSeconds(true),
        });

        //TODO Execute this.props.onChange here
        let obj = {type: "scalar", value: this.timeInSeconds};
        this.props.onChange(this.props.ind, obj)
    };

    onFocus = e => {
        cl("Handle  on Focus")
        this.setState({
            open: true,
            displayValue: this.formatSeconds(false),
            tooltipValue: this.formatSeconds(true),
        });

    };



    verifyTextInput = event =>{
        //if any number > 6, if there is an h or m auto format, make sure not longer than length

        cl("Verift TextInput" + event.target.value);
        if (event.target.value === "")
            this.timeInSeconds = 0;
        this.setState({
            displayValue: event.target.value
        })
    };

    handleKeyPress = event => {
        let input = this.nameInput.children[0];
        let key = event.key;
        cl(key);
        if (key == null)
            return;
        if (isNaN(Number(key))){

            if (key.toLowerCase() === "h"
                || key.toLowerCase() === "m" || key.toLowerCase() === "s"){
                let digit1 = input.value.charAt(input.selectionStart-2);
                let digit2 = input.value.charAt(input.selectionStart-1);
                if (isNaN(Number(digit1)) || digit1 >=6)//Means invalid
                    digit1 = 0;
                if (isNaN(Number(digit2)))
                    digit2=0;
                let final = Number(digit1)*10+Number(digit2);
                this.updateSeconds(final, key);
                this.setState({
                    displayValue: this.formatSeconds(false),
                });
                event.preventDefault();
            }else{
                if (key.length > 1)
                    return;
                else{
                    cl("TODO THROW ERROR FOR NOT RIGHT CHAR");
                    event.preventDefault();
                    return;
                }
            }
        }
        cl("Setting state in key press")
        this.setState({
            open: true,
            //displayValue: this.formatSeconds(false),
            tooltipValue: this.formatSeconds(true),
        });

    };

    render() {
        return (
            <div>
                <C18MuiFormControl variant="outlined">
                    <C18MuiInputLabel htmlFor="age-simple">{this.props.cont.title}</C18MuiInputLabel>
                    <Tooltip title={this.state.tooltipValue}
                             placement={"right"}
                             open={this.state.open}
                             disableFocusListener
                             disableHoverListener
                             disableTouchListener >
                        <C18MuiInput
                            id="outlined-adornment-password"
                            type="text"
                            value={this.state.displayValue}
                            ref={(input) => {this.nameInput = input;}}
                            onFocus={this.onFocus}
                            onBlur={this.handleBlur}
                            onKeyDown={this.handleKeyPress}
                            onChange={this.verifyTextInput}
                            endAdornment={
                                <C18MuiInputAdornment position="end">
                                    <C18MuiIconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowOptions}
                                        onMouseDown={this.handleMouseDownOptions}
                                    >
                                        <ExpandMoreIcon />
                                    </C18MuiIconButton>
                                </C18MuiInputAdornment>
                            }
                            labelWidth={70}
                        />
                    </Tooltip>
                </C18MuiFormControl>
            </div>
        );
    }
}

export default TimeInput ;
