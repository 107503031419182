import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {cl,globs} from '../../components/utils/utils';

class C18MuiFormControlLabel extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.state={
    }
  }
  
  render(){
    if(globs.noMui){
      return (
        <>
          {this.props.control}
          <label>{this.props.label}</label>
        </>
      )
    }else{
      return React.createElement(FormControlLabel,this.props)
    }
  }
}
      
export default C18MuiFormControlLabel;
 
