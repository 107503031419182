import React from 'react';
import Parameters00 from './Parameters00';
import TimePeriod00 from './TimePeriod00';
import DatapointList00 from './DatapointList00';
import UsaIcon from './UsaIcon';
import {cl, globs, constant} from '../../components/utils/utils';

class DataVisualizationEdit00 extends React.Component{
  constructor(props) {// 
    super(props);
//     cl(props.parms.paneGraph)
    this.state=props.parms.paneGraph
//     this.state={
//         title: "First Graph",
//         bgColor: "#90FFFF",
//         xTicks: 3,
//         flags: 0,
//         duration: 5,
//         period: 3600,
//         beginTime: 1610048120,
//         endTime: 1610038120,
//         dpList:[
//           {zone: 1, channel: 4, index: 3, pid: 23, color: "#FF0000", yTicks: 0},
//         ],
//     }
  }
  
  onChange=(type, vals)=>{
//     cl(type)
//     cl(vals)
    switch(type){
      case "parameters":
        this.setState({
          bgColor: vals.bgColor,
          title: vals.title,
          xTicks: vals.xTicks,
        })
        break;
      case "timePeriod":
        this.setState({
          beginTime: vals.beginTime,
          duration: vals.duration,
          endTime: vals.endTime,
          flags: vals.flags,
          period: vals.period,
        })
        break
      case "dpList":
        this.setState({dpList: vals})
        break
      default:
        break
    }
//     cl(vals)
//     this.setState({timePeriod: vals})
  }
  
  result=(type)=>{
    var ret
    switch(type){
      case "Visualization-Cancel":
        ret={}
        break
      case "Visualization-OK":
        ret=this.state
        break
      default:
        break
    }
//     cl(type)
//     let ret={
//       type: type,
//       editMode: false,
//     }
    this.props.onChange("visualization", ret)
  }
  
  render(){
    let s=this.state
//     cl(s)
    return(
      <div style={{position: "absolute", left: 100, top: 100, width: 400, 
        backgroundColor: "#FFCCA0", borderRadius: 20, boxShadow: "10px 10px 20px #808080", padding: 10}} 
      >
      <h3>Insert Visualization
      <UsaIcon icon={`Visualization-OK`} result={this.result} inline/>
      <UsaIcon icon={`Visualization-Cancel`} result={this.result} inline/>
      </h3>
      <Parameters00 parms={{vals: {
        title: this.state.title,
        bgColor: this.state.bgColor,
        xTicks: this.state.xTicks,
      }/*s.parameters*/}} type="visualization" onChange={(vals)=>{this.onChange("parameters", vals)}}/>
      <TimePeriod00 parms={{vals: {
        flags: this.state.flags,
        duration: this.state.duration,
        period: this.state.period,
        beginTime: this.state.beginTime,
        endTime: this.state.endTime,
      }/*s.timePeriod*/}} onChange={(vals)=>{this.onChange("timePeriod", vals)}}/>
      <DatapointList00 parms={{
        vals: this.state.dpList.slice(0),/*s.dpList*/
        width: 400,
      }} onChange={(vals)=>{this.onChange("dpList", vals)}}/>
      </div>
    )
  }
}

export default DataVisualizationEdit00 ;

