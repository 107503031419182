import React from 'react';
import UsaIcon from './UsaIcon';
import UsaSelect00 from './UsaSelect00';
import {wsTrans, checkLoggedIn} from '../utils/utils'
import {cl, globs, userSpecificHome} from '../../components/utils/utils';


class ManageAccount extends React.Component{
  constructor(props) {
    super(props);
    document.title="Manage Account";
    this.state={
      loaded: false,
      accountName: "Account Name",
    }
    this.loadAccount()
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Account", url:`/usa/c18/admin/manageAccount`},
            ]},
        },
      )
    }
  }

  loadAccount=async()=>{
    let accts=await wsTrans("usa", {cmd: "cRest", uri: "/s/accounts", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
    
    this.setState({accountName: accts.data.name, loaded: true})
  }
  
  saveAccount=async()=>{
    await wsTrans("usa", {cmd: "cRest", uri: "/s/accounts", method: "update", 
      sessionId: globs.userData.session.sessionId, body: {name: this.state.accountName}})
  }
  
  result=(type)=>{
    switch(type){
      case "Account-OK":
        this.saveAccount()
        break
      default:
        break
    }
  }
  
  onChange=(type, vals)=>{
    switch(type){
      case "accountName":
        this.setState(vals)
        break
      default:
        break
    }
  }
  
  render(){
    return(
      <div style={{padding: 10}}>
      <h3>
      <UsaIcon icon={`Account-OK`} result={this.result} inline/>
      <UsaIcon icon={`Account-Cancel`} result={this.result} inline/>
      Manage Account</h3>
      Account ID: {globs.userData.session.accountId}
      <div style={{height: 10}}/>
      <UsaSelect00 key={0} parms={{
        title: "Account Name",
        width: 500,
        valueId: "accountName",//valueId, // "widgetSel",
        value: this.state.accountName, //value, // this.state.widgetSel,
        onChange: o=>{this.onChange("accountName", o)},
      }}/>
      </div>
    );
  }
}

export default ManageAccount ;

