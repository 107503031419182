import {cl, constant, globs, saveLocalStorage, getLocalStorage, callStack} from './utils'; // cl,
import {dbVals} from './http';
// let httpHost = window.location.hostname
// const baseUrl = "http://" + httpHost + ":3374";
// cl(baseUrl)



const po = function (method, body){
  globs.token = globs.userData.accessToken
//   cl(globs.token = globs.userData.accessToken);
//   cl(globs.userData.accessToken);
        var ret = {
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'user-agent': 'Mozilla/4.0 MDN Example',
                'content-type': 'application/json',
                'authorization': 'Bearer ' + globs.token,
            },
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        }
        if (body !== "") ret.body = body ;
        return ret ;
    }

var getPost=(url, method, body=null, auth=null, func=null, params=null)=>{
  cl("getPost: " + url);
  return new Promise((res, rej)=>{
    let options={
      method: method,
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/json',
        'authorization': 'Bearer ' + globs.token,
      },
    }
    if(auth){options.headers.authorization='Bearer ' + auth;}
    if(body){options.body=body;}
    return fetch(url, options).then(r0=>{
      r0.json().then(r1=>{
        cl(r1);
        if(func){func(r1, params);}
        res(r1);
      });
    }, e=>{rej(e);});
  })
}

function doGetPost(purl, cmd, func, params){// this is used to get the FUI pages
//   console.trace()
//   cl("ERROR!!! Call to old http interface")
//   console.trace()
//   let cs=callStack()
//   cl(cs)
//   cl(cmd.b.c);
  return new Promise((res, rej)=>{
    let url = constant.authUrl + purl;
//     cl(url)
    let pv = ((cmd === "POST") || (cmd === "PUT")) ?
      JSON.stringify(params.Post) : "";
    let httpObj = po (cmd, pv) ;
//     cl(httpObj);
//     cl(url)
    return fetch(url, httpObj).then(
      response => response.json().then(
      resp=>{
        func(resp, params);
        res(resp);
      }), e=>{rej(e)});
  })
}

// function getHistory(){
//   cl("get history: " + dbVals.historyTime)
//   return doGetPost("/ngrest/s/" + dbVals.site +
//     "/t/" + dbVals.historyTime,
//     "GET", setHistory, {});
// }
//

var getSite = ()=>{
//   cl(globs.token);
}

function setLogin (resp, parms){
  // cl("set login");
//   cl(resp);
  cl(parms);
  if (resp.result === "ok"){
    globs.token = resp.token;
//     dbVals.initted = false; // force re-read of dbVals
  // if (globs.siteid !== parms.Post.siteid){
    globs.siteid = parms.Post.siteid;
    globs.username = parms.Post.username;
    dbVals.initted = false;
    dbVals.gotSite = false;
    cl(globs);
    // }
    let loginInfo = {token: resp.token, wsHost: resp.wsHost, siteid: parms.Post.siteid,
      username: globs.username, sitename: globs.siteName}
//     cl(loginInfo);
    saveLocalStorage("loginInfo", JSON.stringify(loginInfo));
//     cl(parms);
//     cl(globs);
//     cl(resp.token);
  }
//   cl(resp);
//   cl(globs);
}

function nop (resp, parms){
  // cl(resp);
}

function saveConfigs2 (resp, parms){
//   resp.configs = {// debugging
//   "siteViewColumns":["Zone","Name","BaroPres","InTemp","InHum"],
//   "zoneViewColumns":[["HumSP","InHum","DeHumSP","HeatSP"]]
// }  ;
//   cl(resp.configs);
//   cl(globs.token);

  globs.userConfigs = resp.configs;
//   cl(resp)
}

function login (parms){
//   cl(parms);
  return doGetPost("/open/logins", "POST", setLogin, parms);
}

// function createUser (parms){
//   doGetPost("/users", "POST", setLogin, parms);
// }

function saveConfigs (){
//   cl("save configs");
// { "_id" : ObjectId("5d6fb902ce207b090bc259b6"),
//   "userid" : "DDmcGqE1wqKkp5r9OpP3soQKTMVxn2HP",
//   "configs" : {
//     "siteViewColumns" : [ "Zone", "Name", "BaroPres", "InTemp", "InHum" ],
//     "zoneViewColumns" : [ [ "HumSP", "InHum", "DeHumSP", "HeatSP" ] ]
//   } }
//   cl(globs.userConfigs);
  doGetPost("/auth/siteconfig", "POST", nop, {Post: globs.userConfigs});
}

function getConfigs (parms){
//   cl("get configs")
// globs.token=
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IkREbWNHcUUxd3FLa3A1cjlPcFAzc29RS1RNVnhuMkhQIiwicHJpdnMiOnsicyI6MH0sImlhdCI6MTU3MDM1NzUxMSwiZXhwIjoxNTcwNDQzOTExfQ.ZRQy4naNEJQeGf70yft1QJNlAcyl6X91NYr32tuzg5U";
  if (parms === undefined){parms = {Post: ""}};
//   cl(parms);
  return //doGetPost("/auth/siteconfig", "GET", saveConfigs2, parms);
}

var savePages=(resp)=>{
  dbVals.pages = resp.pages;
//   cl(resp.pages)
}

var getPages=()=>{
//   saveOnePage(1);
  return //doGetPost("/auth/pages", "GET", savePages);
}

var saveOnePage=(page)=>{
//   page = {name: "ZoneStages",
//     controls: [
//       {name: "Zone Name",
//       type: "text",
//       pid: {z: 0, c: 255, i: 5022}
//       }
//   ]}
//   cl(page)
//
  return doGetPost("/auth/pages", "PUT", nop, {Post: page});
}

var getToken = ()=>{
  return globs.userData.accessToken;
}

var getSiteId = ()=>{
  cl("get site");
  return globs.siteid;
}

// var checkLogin2 = (r)=>{
//   cl(r);
// }

var checkLogin = ()=>{
/* check to see if the current token is valid
if *not*, then we should login again
This needs to be updated for the new login
*/
  cl("httpauth")
  return new Promise((res, rej)=>{
//     cl("cl1");
    let loginInfo = JSON.parse(getLocalStorage("loginInfo"))
    cl(loginInfo)
    // loginInfo = null;
    // loginInfo = null;
    if (loginInfo === null){
//       cl("cl1");
      res(false);
    } else {
//       cl("cl1");
//       cl(loginInfo);
      globs.token= loginInfo.token;
      globs.wsHost = loginInfo.wsHost;
      globs.siteid = loginInfo.siteid;
      globs.username = loginInfo.username;
      globs.siteName = loginInfo.sitename;
      dbVals.site = loginInfo.siteid;
      doGetPost("/auth/check", "GET", nop).then(r=>{
        res(r.result === "ok")
//         cl(r);
      });
    }
  });
}

var getUsers = ()=>{
  return new Promise((res, rej)=>{
    doGetPost("/users", "GET", nop).then(r=>{
//       cl(r);
      res(r)
    });
  });
}

var getSites = ()=>{
  return new Promise((res, rej)=>{
    doGetPost("/sites", "GET", nop).then(r=>{
//       cl(r);
      res(r)
    });
  });
}

/*pages
[{title: "one", type: "one", scope: 0, description: "first page"}]*/

var getFuiPages = ()=>{
//   cl("getFuiPages");
//   console.trace()
  return new Promise((res, rej)=>{
    if (globs.fuiPages !== null){
      res(globs.fuiPages);
    } else {
      doGetPost("/fuipages", "GET", nop).then(r=>{
        let arr = [];
//         cl("got fui page")
        r.pages.forEach(p=>{
//           cl(p)
          p.controls.forEach(c=>{
            if((c.type=="channelType")&&(c.pid<0)){
//               cl(c.pid)
              c.pid=508}// channelType hack
          })
          arr[p.type]=p
        })
        globs.fuiPages = arr
//         cl(globs.fuiPages)
        res(globs.fuiPages)
      });
    }
    // let ret = [{title: "one", type: "one", scope: 0,
    //   description: "first page"}];
    // res(ret);
  });
}

var saveFuiPage=(key, page)=>{
  // cl(globs.fuiPages)
  globs.fuiPages[key] = page;
  // cl(key, page)
/*now, this has to save to the local copy of fuiPages, too*/
//   cl(site);
// cl("save vui")
  return new Promise((res, rej)=>{
    doGetPost("/fuipages", "PUT", nop, {Post: {key: key, page: page}}).then(r=>{
      res(r)
    });
  });
}

var deleteFuiPage=(key)=>{
//   cl(site);
// cl("save vui")
  return new Promise((res, rej)=>{
    doGetPost("/fuipages/" + encodeURI(key), "DELETE", nop, {Post: {key: key}}).then(r=>{
      res(r)
    });
  });
}

var getTemplates = ()=>{
//   cl("get temps")
  return new Promise((res, rej)=>{
    if (globs.templates !== null){
      // cl("rets")
      res(globs.templates);
    } else {
      doGetPost("/templates", "GET", nop).then(r=>{
        // cl("ret")
        let arr = [];
        r.templates.forEach(t=>{
          arr[t.name]=JSON.parse(t.text);
        })
        globs.templates = arr
        // cl(arr)
        res(globs.templates)
      });
    }
  });
}

var saveTemplate=(key, template)=>{
  if (globs.templates === null) globs.templates = {};
  // globs.templates[key] = template;
  return new Promise((res, rej)=>{
    doGetPost("/templates", "PUT", nop, {Post: {key: key, template: template}}).then(r=>{
      res(r)
    });
  });
}

var deleteTemplate=(key)=>{
  return new Promise((res, rej)=>{
    doGetPost("/templates/" + encodeURI(key), "DELETE", nop, {Post: {key: key}}).then(r=>{
      res(r)
    });
  });
}

var saveSite=(site)=>{
//   cl(site);
  return new Promise((res, rej)=>{
    doGetPost("/sites", "PUT", nop, {Post: {site: site}}).then(r=>{
//       cl(r);
      res(r)
    });
  });
}

var saveUser = (user)=>{
  return new Promise((res, rej)=>{
    doGetPost("/users", "PUT", nop, {Post: {user: user}}).then(r=>{
//       cl(r);
      res(r)
    });
  });
}

var createUser = (user)=>{
  return new Promise((res, rej)=>{
    doGetPost("/users", "POST", nop, {Post: {user: user}}).then(r=>{
      res(r)
    });
  });
}

var deleteUser = (user)=>{
  return new Promise((res, rej)=>{
    doGetPost("/users/" + user.userid, "DELETE", nop, {Post: {user: user}}).then(r=>{
      res(r)
    });
  });
}

export {getPost, login, getConfigs, saveConfigs, getSite, saveSite, getToken, getSiteId, checkLogin,
  getUsers, saveUser, createUser, deleteUser, getSites, getPages,
  saveOnePage, getFuiPages, saveFuiPage, deleteFuiPage,
  getTemplates, saveTemplate, deleteTemplate,doGetPost}
