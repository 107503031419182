import React from 'react';
import {cl, globs, showVersion} from '../../components/utils/utils';
import Position00 from '../../usa/components/Position00';
import Parameters00 from '../../usa/components/Parameters00';
import UsaIcon from '../../usa/components/UsaIcon';
import UsaSelect00 from '../../usa/components/UsaSelect00';
import UsaColorPicker00 from '../../usa/components/UsaColorPicker00';
import Gauge2 from './Gauge01';
import Colors00 from '../../usa/components/Colors00';
import Labels00 from '../../usa/components/Labels00';
import Datapoints from '../../usa/components/Datapoints';
// import {init, dbVals} from '../../components/utils/http'
// import InTemp from './InTemp.js';
// import {getParmValue, eqTypes, getChannelType} from '../utils/utils'
import history from '../../history'
import {colorIsDark} from '../utils/utils';

class GenericGaugeWidget01 extends React.Component{
/* this gets the size in parms w, h. assume a 5px padding*/  
  constructor(props) {
    super(props);
//     this.props.notify({id: "dashboardSel", func: this.onDashboardSel})
    props.notify({id: "drag", func: this.onDrag})
    if(!props.parms){
      this.state={
        colors:[],
        labels:[],
      }
      return
      
    }
//     cl(props)
    let p=props.parms.wdg.parms
//     cl(props.parms)
    let val=(p.dataPoints)?p.dataPoints.val : 0
    this.gauge={}
    this.state={
//       value: Math.round(p.dataPoints.val * p.dec) / (p.dec * p.mult),
      value: Math.round(val * p.dec) / (p.dec * p.mult),
//       dragId: -1,
//       dragStartX: 0,
//       dragStartY: 0,
//       dragX: 0,
//       dragY: 0,
      //       props: props,
      loaded: false,
    }
    props.parms.wdg.update=this.update
//     cl(props.parms.wdg)
//     cl(p)
//     let minTemp=50
//     let maxTemp=90
//     let val=70
//     this.val=Math.round(p.val * p.dec) / p.dec
//     this.val=Math.round(p.dataPoints.val * p.dec) / (p.dec * p.mult)
//     this.title=p.title
//     this.color="#0000FF"
//     this.bgColor=p.bgColor
//     this.note=p.note
//     this.titlePadding= .02*size
//     this.titleFontSize=.12*size
//     cl(size)
//   this.setGauge()
//   let size=this.props.parms.w
//   this.gauge={
//       value: Math.round(p.dataPoints.val * p.dec) / (p.dec * p.mult),
//       width: 0.8*size-10,//p.w-10,// sets size
//       height: 0.6*size,
//       fontSize: 0.08*size,//0.6*size,//12,
//       bgColor: p.bgColor,
//       ringInset: 0.08*size, //15,
//       ringWidth: 0.15*size, //30,
//       min: p.min,
//       minAngle: -120,
//       max: p.max,
//       maxAngle: 120,
// //       ticks: ticks,
// //       colors: colors,
//       ticks: p.ticks, //[50, 70, 90],
//       colors:p.colors,//
// //       [
// //       [50, "#FF0000"],
// //       [60, "#0000FF"],
// //       [90, "#FF0000"],
// //       ]
//     }
//     setInterval(this.testUpdates, 3000)
//   cl(this.gauge)
  }
  
  componentWillUnmount=()=>{
    this.props.notify({id: "drag", func: this.onDrag, unMount: true})
  }
  
  makeColors=(p)=>{
//     cl(p)
    var cols=[]
    cols.push([+p.minVal,p.lowColor])
    cols.push([+p.dataPoints.lp,p.midColor])
    cols.push([+p.dataPoints.hp,p.hiColor])
    cols.push([+p.maxVal,"#FFFFFF"])
//     cl(cols)
    return cols
  }
  
  setGauge=()=>{
    let p=this.props.parms.wdg.parms
//     cl(this.props)
//     cl(p)
    p.minVal=50
    p.maxVal=90
    let size=this.props.parms.w
    let val=(p?.dataPoints?.val) ? p.dataPoints.val : 0
//     cl("set Gauge")
    this.gauge.value=Math.round(val * p.dec) / (p.dec * p.mult)
    this.gauge.width=0.8*size-10//p.w-10,// sets size
    this.gauge.height=0.7*size
    this.gauge.fontSize=0.08*size//0.6*size,//12,
    this.gauge.bgColor=p.bgColor
    this.gauge.pointerColor=p.pntColor
    this.gauge.centerColor=p.cntColor
//     this.gauge.ringInset=0.08*size //15,
//     this.gauge.ringWidth=0.15*size //30,
//     this.gauge.min=p.min
    this.gauge.minAngle=-120
//     this.gauge.max=p.max
    this.gauge.maxAngle=120
//     this.gauge.ticks=p.ticks||[] //[50, 70, 90],
//     this.gauge.colors=p.colors||[]//
    
    this.gauge.ringInset=(size/2)*(100-p.outRadius)/100
    this.gauge.ringWidth=(size/2)*(p.outRadius-p.inRadius)/100
    this.gauge.min=p.minVal
    this.gauge.max=p.maxVal
    this.gauge.value=p.dataPoints.dp
//     this.gauge.colors=[]
    this.gauge.colors=this.makeColors(p)
    this.gauge.ticks=[+p.minVal,+p.dataPoints.lp,+p.dataPoints.hp,+p.maxVal]
//     this.gauge.ticks=[5, 10, 25, 70]
//     this.gauge.ringInset=10//0.08*size //15,
//     this.gauge.ringWidth=2//0.15*size //30,
    
    
//     cl(this.gauge.value)
//     cl(this.props)
    
  }
  
  update=(wdg, paramId, isRender)=>{
    showVersion(this.gauge)
//     showVersion(wdg)
    if(wdg==this.gauge){cl("the same")}
//     cl(this.gauge)
//     cl(isRender)
    let p=this.props.parms.wdg.parms
    let val = Math.round(p.dataPoints.dp * p.dec) / (p.dec * p.mult)
    this.gauge.value=val
    cl(val)
//     cl(`update ${val}`)
//     cl(val)
//     cl(this.gauge.update)
//     cl(this.gauge)
    this.gauge.update(isRender)
//     cl(isRender)
    if(!isRender){
//       cl("setting state")
      this.setState({value: val})
    }
  }
  

  testUpdates=()=>{
    this.updVal=(this.updVal==72) ? 74 : 72
//     cl(`update ${this.updVal}`)
    this.gauge.value=this.updVal
    
  }
  
  onClick=()=>{
    if(this.props.parms.e) {return}// edit mode
    //     cl(this.props.parms.wdg)
//     cl("clicked")
//     let url=`/fui/live/${this.chType}/0-0-${this.props.parms.wdg.parms.chan}-0`
    let url=this.props.parms.wdg.parms.link
//     cl(url)
    history.push(url)
  }
  
/********************* These are the new Widget functions, to make them freestanding**********************/
widgetType={v: "generic01", t: "Generic01"}

editType=(that)=>{
  cl(this.state)
  let pos=Object.assign({}, that.state.position)
  let def=Object.assign({}, that.state.defaults)
  let parm=Object.assign({}, that.state.parameters)
//   let lp=Object.assign({}, that.state.lowpoint)
//   let hp=Object.assign({}, that.state.highpoint)
  let dp=Object.assign({}, that.state.datapoint)
  let col=that.state.colors.slice(0)
  let lab=that.state.labels.slice(0)
//     <div>
//     <Position00 parms={{vals: pos}} onChange={(vals)=>{that.onChange("position", vals)}}/>
//     <Parameters00 parms={{
//       vals: parm,
//       wdg: this,
// //       sites: that.sites
//       
//     }} type="name" onChange={(vals)=>{that.onChange("parameters", vals)}}/>
//     </div>
  return(
    <div>
    <Position00 parms={{vals: pos}} onChange={(vals)=>{that.onChange("position", vals)}}/>
    <Parameters00 parms={{
      vals: parm,
      wdg: this,
    }} type="generic01" onChange={(vals)=>{that.onChange("parameters", vals)}}/>
    <Datapoints parms={{vals: dp, field: "lp", title:"Low Point"}} onChange={(vals)=>{that.onChange("lowpoint", vals)}}/>
    <Datapoints parms={{vals: dp, field: "hp", title:"High Point"}} onChange={(vals)=>{that.onChange("highpoint", vals)}}/>
    <Datapoints parms={{vals: dp, field: "dp", title:"Data Point"}} onChange={(vals)=>{that.onChange("datapoint", vals)}}/>
    <Colors00 parms={{vals: {colors: col}}} onChange={(vals)=>{that.onChange("colors", vals)}}/>
    <Labels00 parms={{vals: {labels: lab}}} onChange={(vals)=>{that.onChange("labels", vals)}}/>
    </div>
  )
}

editParameters=(that)=>{
  cl(that.state)
//   cl(that.state.title)
  // Position, Parameters, Datapoint, Colors, Labels
    return(
      <div>
      <UsaIcon icon="parameters-OK" result={that.result} inline/>
      <UsaIcon icon="parameters-Cancel" result={that.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Parameters:</h3></span>
      <UsaSelect00 parms={{
        title: "Title",
        width: 100,
        value: that.state.title,
        valueId: "title",
        onChange: o=>that.setState(o),
      }}/>
      <UsaColorPicker00 parms={{
        title: "Background\u00A0Color",
        width: 100,
        inline: true,
        value: that.state.bgColor,
        valueId: "bgColor",
        onChange: o=>that.setState(o),
      }}/>
      <UsaColorPicker00 parms={{
        title: "Low\u00A0Color",
        width: 100,
        inline: true,
        value: that.state.lowColor,
        valueId: "lowColor",
        onChange: o=>that.setState(o),
      }}/>
      <UsaColorPicker00 parms={{
        title: "Middle\u00A0Color",
        width: 100,
        inline: true,
        value: that.state.midColor,
        valueId: "midColor",
        onChange: o=>that.setState(o),
      }}/>
      <UsaColorPicker00 parms={{
        title: "High\u00A0Color",
        width: 100,
        inline: true,
        value: that.state.hiColor,
        valueId: "hiColor",
        onChange: o=>that.setState(o),
      }}/>
      <UsaColorPicker00 parms={{
        title: "Pointer\u00A0Color",
        width: 100,
        inline: true,
        value: that.state.pntColor,
        valueId: "pntColor",
        onChange: o=>that.setState(o),
      }}/>
      <UsaColorPicker00 parms={{
        title: "Center\u00A0Color",
        width: 100,
        inline: true,
        value: that.state.cntColor,
        valueId: "cntColor",
        onChange: o=>that.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Outside\u00A0Radius",
        width: 100,
        value: that.state.outRadius,
        valueId: "outRadius",
        onChange: o=>that.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Inside\u00A0Radius",
        width: 100,
        value: that.state.inRadius,
        valueId: "inRadius",
        onChange: o=>that.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Center\u00A0Radius",
        width: 100,
        value: that.state.cenRadius,
        valueId: "cenRadius",
        onChange: o=>that.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Multiplier",
        width: 100,
        value: that.state.mult,
        valueId: "mult",
        onChange: o=>that.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Note",
        width: 100,
        value: that.state.note,
        valueId: "note",
        onChange: o=>that.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Link",
        width: 100,
        value: that.state.link,
        valueId: "link",
        onChange: o=>that.setState(o),
      }}/>
      </div>
    )
}

parameterChange=(v)=>{
  cl(v)
  return{bgColor: v.bgColor, 
      lowColor: v.lowColor,
      midColor: v.midColor,
      hiColor: v.hiColor,
      pntColor: v.pntColor,
      cntColor: v.cntColor,
      inRadius: v.inRadius,
      outRadius: v.outRadius,
      cenRadius: v.cenRadius,
    dec: v.dec, link: v.link, mult: v.mult, note: v.note, title: v.title}
}

makeParms=(wdg)=>{
//   cl(wdg)
  return{
    parms:{
      bgColor: wdg.parameters.bgColor,
      lowColor: wdg.parameters.lowColor,
      midColor: wdg.parameters.midColor,
      hiColor: wdg.parameters.hiColor,
      pntColor: wdg.parameters.pntColor,
      cntColor: wdg.parameters.cntColor,
      inRadius: wdg.parameters.inRadius,
      outRadius: wdg.parameters.outRadius,
      cenRadius: wdg.parameters.cenRadius,
      dpid: wdg.datapoint,
      dec: wdg.parameters.dec,
      link: wdg.parameters.link,
      mult: wdg.parameters.mult,
      note: wdg.parameters.note,
      title: wdg.parameters.title,
    },
  }
}

showWidget=(that, x, y, w, h, wdg, i)=>{
//   cl(i)
//   cl(wdg)
  let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i, e: that.state.edit, cm: that.cm, md: that.md};
  let Cont=this.constructor
  return(
    <Cont key={i} parms={parms} notify={that.notify}/>
  );
}

/********************* End of new Widget functions **********************/

  onDrag=(o)=>{// o has the updated info
    if("dragStart" in o){
      this.setState(o)
    }else{
      if(this.state.dragStart.i==this.props.parms.i){
        this.setState(o)
      }
    }
  }
  
  cm=(e)=>{
    this.props.parms.cm({e: e, i: this.props.parms.i})
  }
  
  md=(e)=>{
    this.props.parms.md({e: e, i: this.props.parms.i})
  }
  
  render() {
//     cl("render")
    this.setGauge()
//     cl(this.gauge)
//     cl("generic render")
//     cl(this.props)
//     cl(this.props.parms.wdg.parms.dataPoints.val)
//     cl(this.props.config.value)
    let dm=5
    let p = this.props.parms;
    let p2=p.wdg.parms
//     let size=props.parms.w
//     cl(p2)
//     this.gauge.value=this.state.value // p2.dataPoints.val/p2.mult
//     if(!p?.wdg?.parms?.dataPoints?.val) return(
//       <div>No Data</div>
//     )
    if(!p?.wdg?.parms?.dataPoints?.val) {p.wdg.parms.dataPoints.val=0}
//     cl(p)
    let color= p2.bgColor
    let x=p.x+dm
    let y=p.y+dm
    if(this?.state?.dragStart?.i==this.props.parms.i){
      x+=this.state.drag.x-this.state.dragStart.x
      y+=this.state.drag.y-this.state.dragStart.y
    }
    let textColor=colorIsDark(p2.bgColor)?"white":"black"
    let fontSize=1.3*p.w/p2.title.length
//     cl(this.color)
//     cl(this.props)
//     cl([x, y])
//     cl(this.gauge)
//           <div style={{textAlign: "center", fontSize: .12*p.w, marginTop: 0-(1*.12*p.w), color: textColor}}>
//             {Math.round(p2.dataPoints.dp * p2.dec) / (p2.dec * p2.mult)}</div>
    return(
        <div style={{position: "absolute", left: x, top: y, width: p.w-2*dm, height: p.h-2*dm, textAlign: "center",
          paddingTop: 0, borderRadius: 2, borderWidth: 2, borderColor: "#F0F0F0", borderStyle: "solid", backgroundColor: color}} onClick={this.onClick}
          onContextMenu={this.cm} onMouseDown={this.md}>
          <div style={{textAlign: "center", fontSize: fontSize/*.12*p.w*/, padding: .02*p.w, color: textColor}}>{p2.title}</div>
          <Gauge2 config={this.gauge}/>
            <div style={{textAlign: "center", fontSize: 0.5*.12*p.w, padding: 0, color: textColor}}>{p2.note}</div>
          </div>
      );
  }
}

export default GenericGaugeWidget01;
