import React from 'react';
import UsaIcon from './UsaIcon';
import UsaSelect00 from './UsaSelect00';
// import UsaSelect00 from './UsaSelect00';
// import Datapoints from './Datapoints';
import {cl} from '../../components/utils/utils';

class Colors00 extends React.Component{
  constructor(props) {
    super(props);
    this.state=Object.assign({}, props.parms.vals)
    this.state.editMode=false
//     cl(this.state)
//     this.state={
//       editMode: false,
//       colors:[
//         {val: 0, color: "#FFCC00", edit: false},
//         {val: 10, color: "#FFCC40", edit: false},
//         {val: 20, color: "#FFCC80", edit: false},
//         {val: 30, color: "#FFCCC0", edit: false},
//       ],
//     }
  }
  
  deepCopy=(arr)=>{// just used for this.state.colors, for now
    let o2=[]
    arr.forEach(o=>{
      o2.push(Object.assign({}, o))
    })
    cl(o2)
    return o2
  }
  
  colorSquare=(col, size)=>{
    return(
      <div style={{width: size, height: size, marginLeft: 10, display: "inline-block", verticalAlign: "middle", backgroundColor: col}}/>
    )
    
  }
  
  result=(type)=>{
    switch(type){
//       case "colors-Cancel":
//         this.setState({colors: this.colorsBack, editMode: false})
//         break
      case "colors-OK":
        this.props.onChange(this.state)
        this.setState({editMode: false})
        break
      case "colors-Cancel":
        this.setState({editMode: false, colors: this.deepCopy(this.colorsBack)})
        break
      case "colors-Edit":
        this.colorsBack=this.deepCopy(this.state.colors)
//       case "colors-OK":
        this.setState({editMode: !this.state.editMode})
        break;
      default:
        cl(type)
        let parts=type.split("-")
        let cmd=parts[1]
        let col2=this.state.colors.slice(0)
        let id=+parts[0].substr(1)
        switch(cmd){
          case "Delete":
            col2.splice(id,1)
            this.setState({colors: col2})
            break
          case "Create":
            col2.splice(id, 0, {val: 0, color: "#FFFFFF"})
          case "OK":
          case "Edit":
            col2.forEach((c,i)=>{
              if(id==i){
                col2[id].edit=!col2[id].edit
              }else{
                col2[i].edit=false
              }
            })
            this.setState({colors: col2})
            break;
          case "Delete":
            break;
        }
        break
    }
  }
  
  setColorVals=(o)=>{
    let o2=Object.entries(o)[0]
    let id=+o2[0].substr(1)
    let ty=o2[0].substr(0,1)
    let col2=this.state.colors.slice(0)
    if(ty=="v"){
      col2[id].val=o2[1]
    }else{
      col2[id].color=o2[1]
    }
    this.setState({colors: col2})
    
  }
  
  showColors=()=>{
    return this.state.colors.map((c,i)=>{
      if(c.edit){
        return(
          <div key={i}>
          <UsaIcon icon={`c${i}-OK`} result={this.result} inline/>
          <UsaSelect00 parms={{
            title: "Value",
            width: 100,
            inline: true,
            value: c.val,
            valueId: `v${i}`,
            onChange: this.setColorVals,
          }}/>
          <UsaSelect00 parms={{
            title: "Color",
            width: 100,
            inline: true,
            value: c.color,
            valueId: `c${i}`,
            onChange: this.setColorVals,
          }}/>
          {this.colorSquare(c.color, 20)}
          </div>
        )
      }else{
        return(
          <div key={i}>
          <UsaIcon icon={`c${i}-Create`} result={this.result} inline/>
          <UsaIcon icon={`c${i}-Edit`} result={this.result} inline/>
          <UsaIcon icon={`c${i}-Delete`} result={this.result} inline/>
          {c.val} 
          {this.colorSquare(c.color, 20)}
          <br/></div>
        )
      }
    })
      
  }
  
  editColors=()=>{
    return(
      <div>
      <UsaIcon icon="colors-OK" result={this.result} inline/>
      <UsaIcon icon="colors-Cancel" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Colors</h3></span>
      {this.showColors()}
      </div>
    )
    
  }
  
  displayColors=()=>{
    let count=this.state.colors.length
    return(
      <div>
      <UsaIcon icon="colors-Edit" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Colors:</h3></span>
      {` ${this.state.colors.length} colors`}
      </div>
    )
    
  }
  
  render(){
    if(this.state.editMode){
      return this.editColors()
    }else{
      return this.displayColors()
    }
  }
}

export default Colors00 ;

