import React from 'react';
import C18Select01 from '../usa/components/C18Select01'
import {loadZonesInfo,loadSitesInfo,gdRest,getLatLongCenter,latLongCenterToPos,
  posToLatLngCenter,downloadFile,makeGodotResourceUrl,resToGodot} from '../usa/components/C18utils'
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import {cl,checkLoggedIn} from './utils/utils';
import {globs,constant} from '../components/utils/utils'
import {doGetPostBasic,wsTrans} from '../usa/utils/utils'
import history from "../history"

const zoomMult=1500

class C2PhysView00 extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.mapDiv=React.createRef();
    this.state={
      loaded: false,
      lat:34,
      lng:-118,
//       zoneId:"MhBlV22lWIuDEKhA",
//       siteId:"NgTWZibHrcKqvdPu",
      showMap:1,
      modeSel:"site2",
    }
    this.camMode="zoomPan"
    this.loadInfo()
    this.getMapMouse=true
    this.td=new TextDecoder()
    cl(window.innerWidth,window.innerHeight)
    this.w=1024//window.innerWidth
    this.h=768//window.innerHeight
    
    this.gdRestKey=gdRest.gdSetListener(this.gdRestLocal)
  }
  
  componentWillUnmount=()=>{
    gdRest.gdRemoveListener()
  }
  
//   componentDidMount=()=>{
//     cl("did mount")
//   }
// 
//   shouldComponentUpdate=()=>{
//     cl("did shouldComponentUpdate")
//     return true
//   }
//   
//   getSnapshotBeforeUpdate=()=>{
//     cl("did getSnapshotBeforeUpdate")
//     return null
//   }
//   
//   componentDidUpdate=()=>{
//     cl("did componentDidUpdate")
//   }
//   
//   getDerivedStateFromProps=()=>{
//     cl("did getDerivedStateFromProps")
//   }
  
  showDrawing=()=> {
    if(this.state.loaded){
      return (
        <iframe src="/godot/PhysicalView.html" width={this.w} height={this.h}></iframe>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  } 
  
  setMap=()=>{
    return new Promise((r,e)=>{
      let tmr=setInterval(x=>{
        if(window.google){
//           cl("got google")
          clearInterval(tmr)
          let st=this.state
          this.google=window.google
          let gh=st.geoGH
          let sc=this.siteCenter
          var latLng=new window.google.maps.LatLng(sc.lat,sc.lng)
          this.map=new window.google.maps.Map(this.mapDiv.current, {
            center:latLng,
            scrollWheel: true,
//             streetView:false,
            tilt:0,
            zoom: 17,
            disableDefaultUI:true,
            mapTypeId:"hybrid",
          })
          r(true)
        }
      },100)
    })
  }
  
  setZoneDisplay=async(args)=>{
    return// debugging! removing error messages
    let uri="/display"
    let method="create"
    let body={
      zoneId:args.zoneId,
      diType:args.diType,
      diParms:args.diParms,
    }
    let resp=await gdRest.callGodot(uri,method,body)
  }
  
  moveGenericZone2=async(args)=>{
    let uri="/zone"
    let method="create"
    let body={
      zoneId:args.zoneId,
      x:args.pos.x,
      y:args.pos.y,
      z:args.pos.z,
      w:args.size.w,
      h:args.size.h,
      d:args.size.d,
      t:args.rot*constant.RAD_PER_DEG,
      type:"curved",
      mode:"site1"// site1 mode is the 4 sensor view
    }
//     cl("calling godot")
//     cl(body)
    let resp=await gdRest.callGodot(uri,method,body)
  }
  
  moveGenericZoneToGodot=async(args)=>{
/* this needs to be updated to use the zone functions, rather than node funcs
it needs to create a zone in site1 mode: fake gh, fourSense
in Godot:
zoneCreate: (zoneId,x,y,w,h,t,type,mode)

*/
//     cl(args)
//     let uri="/node"
//     let method="create"
//     let body={
//       resPath:"/assets/GenericGH0.glb",
//       nodeType:"scene",
//       nodePath:"/",
//       name:args.zoneId,
//       scale:[args.size.w,args.size.h,args.size.d],
//       translate:[args.pos.x,args.pos.z,0-args.pos.y],
//       rotate:[0,args.rot*constant.RAD_PER_DEG,0],
//     }
// //     cl(body)
//     let resp=await gdRest.callGodot(uri,method,body)
//     cl("move generic2")
    await this.moveGenericZone2(args)
//     cl("back in js")
/* arguments:
pos:[x,y]
size:[width,height,depth]
theta:(angle)

 */    
    
  }
  
  getSiteCenterSize=(siteId)=>{
//     this.siteZones=globs.zonesInfo.info.filter(z=>{return (z.geoGH)&&(z.siteId==siteId)})
    let latLngArr=this.siteZones.map(z=>{return[z.geoGH.y,z.geoGH.x]})
    this.siteCenter=getLatLongCenter(latLngArr)// returns lat,lng,zoom
//     cl(this.siteCenter)
//     cl(cent)
  }
  
  doSetGodotCamera=async(body)=>{
    let uri="/camera"
    let method="update"
//     cl(body)
    await gdRest.callGodot(uri,method,body)
  }
  
  setGodotNav=async(nav)=>{// aka "view"
    let uri="/view"
    let method="update"
    let body={mode:nav}
    await gdRest.callGodot(uri,method,body)
  }
  
  setGodotMode=async(zoneId,mode)=>{// for each zone
    let uri="/zone"
    let method="update"
    let body={zoneId:zoneId,mode:mode}
    cl(body)
    await gdRest.callGodot(uri,method,body)
  }
  
  setGodotCamera=async(args)=>{
    let body={
      projection:constant.GODOT_PROJECTION_ORTHOGONAL,
      translate:[args.pos.x,args.pos.z,0-args.pos.y],
      rotate:[0-Math.PI/2,0,0],
    }
//     cl(body)
    await this.doSetGodotCamera(body)
//     let resp=await gdRest.callGodot(uri,method,body)
  }
  
  setGodotProjection=async(proj)=>{
    let proj0=(proj=="perspective")?
      constant.GODOT_PROJECTION_PERSPECTIVE:
      constant.GODOT_PROJECTION_ORTHOGONAL
    let body={
      projection:proj0,
    }
    cl(body)
    await this.doSetGodotCamera(body)
  }

  setDisplay=async(zoneId, type, alarm=true, theme="dark", orientation="h")=>{
    // pass in sensors arr and alarm?
    let args2={
      zoneId:zoneId,
      diType:type,
      diParms:{sensors:[
        {name:"inTemp",value:73.2,unit:"degF"},
        {name:"inHum",value:45,unit:"%"},
        {name:"inLight",value:450,unit:"w/m2"},
        {name:"inCo2",value:1200,unit:"PPM"},
      ],
        alarm:alarm,
        theme:theme,
        orientation:orientation,
      }
    }
    await this.setZoneDisplay(args2)
  }
  
  setDisplayFourSensor=async(zoneId)=>{
    let args2={
      zoneId:zoneId,
      diType:"fourSensor",
      diParms:{sensors:[
        {name:"inTemp",value:73.2,unit:"degF"},
        {name:"inHum",value:45,unit:"%"},
        {name:"inLight",value:450,unit:"w/m2"},
        {name:"inCo2",value:1200,unit:"PPM"},
      ],
        alarm:true,
        theme:"dark",
        orientation:"h",
      }
    }
    await this.setZoneDisplay(args2)
  }
  
  moveGenericZonesToGodot=async(siteId)=>{// initializes site2 mode
//     this.getSiteCenterSize(siteId)
    let latCos=Math.cos(this.siteCenter.lat*constant.RAD_PER_DEG)
    for(let i=0;i<1/*this.siteZones.length*/;i++){
      let z=this.siteZones[i]
      let pos=latLongCenterToPos(z.geoGH.y,z.geoGH.x,this.siteCenter,latCos)
      let gh=z.geoGH
      let args={
        zoneId:z.zoneId,//"oneZone",
        pos:{x:pos.x,y:pos.y,z:0},// meters
        size:{w:gh.w,h:gh.h,d:10},// meters
        rot:0-gh.t,// degrees
      }
      await this.moveGenericZoneToGodot(args)
    }//)
    var args
    args={
      projection:constant.GODOT_PROJECTION_ORTHOGONAL,
      pos:{x:0,y:0,z:zoomMult},
      rot:{x:0,y:0,z:0},
    }
//     cl("set in movegeneric")
    await this.setGodotCamera(args)
  }
  
  getZoneScene=async(zoneId)=>{// get from server
    let path=`zones/${zoneId[0]}/${zoneId[1]}/${zoneId[2]}/${zoneId.substring(3)}.tscn`
    let uri=makeGodotResourceUrl(path)
    return await downloadFile(uri)
  }
  
//   putZoneSceneGodot=(zoneId,zoneScene)=>{
//     cl(zoneId)
//   }
  
  getResource=async(path)=>{
// res://assets/roomThermometer.glb
    let parts=path.split("/").slice(2)
    let path2=parts.join("/")
    let uri=makeGodotResourceUrl(path2)
    return await downloadFile(uri)
  }
  
  moveResourcesToGodot=async()=>{// this moves all. This will become lazy, later
    let ur=Object.keys(this.uniqueResources)
    cl(ur)
    for(let i=0;i<ur.length;i++){
      let path=ur[i]
      let parts=path.split("/")
      let path2=parts.slice(2).join("/")
      cl(path2)
      let res=await this.getResource(path)
      cl(res.length)
      let type="model" 
      res=await resToGodot(path2,type,res)
    }
  }
  
  getScene=async(path,name)=>{// Recursive!!!
//     cl(path)
//this was used to upload the resources from a scene, by scanning it recursively
    let uri=makeGodotResourceUrl(path)
    let cont=await downloadFile(uri)
//     cl(cont)
    let td=new TextDecoder()
    let str=td.decode(cont)
//     cl(str)
    
    let path2=`scenes/${name}`
//     let type=(path.substring(0,5)=="zones")?"zoneScene":"scene"
    let type="scene"
//     cl(`to godot: ${path2}, ${cont.length} bytes`)
    let scene={tree:(await resToGodot(path2,type,str)).retData,scenes:{}}
    cl(scene)
    for(let i=0;i<scene.tree.length;i++){
      let sc=scene.tree[i]
      let parts=sc.res.split("/").slice(2)
      if(parts[0]=="scenes"){
        let path3=parts.join("/")
        let name3=parts.slice(-1)[0]
        scene.scenes[path3]=await this.getScene(path3,name3)
      }
    }
    return scene
  }
  
  moveZoneScenesToGodotO=async(siteId)=>{
    cl("move zc")
    for(let i=0;i<1/*this.siteZones.length*/;i++){
      let z=this.siteZones[i]
      let name=`${z.zoneId}.tscn`//z.zoneId
      let path=`zones/${name[0]}/${name[1]}/${name[2]}/${name.substring(3)}`
      z.scene=await this.getScene(path,name)
      cl(z.scene)
    }
  }
  
  moveZoneScenesToGodot=async(siteId)=>{
// this uploads all of the zoneScenes to Godot as strings for scenes
// and gets back the tree for each with the equipment in it, stored in siteZones
// this.siteZones is the zones for this site, from globs.zonesInfo
//     cl("move zc")
    for(let i=0;i<1/*this.siteZones.length*/;i++){
      let z=this.siteZones[i]
      let name=`${z.zoneId}.tscn`//z.zoneId
      let path=`zones/${name[0]}/${name[1]}/${name[2]}/${name.substring(3)}`
      let uri=makeGodotResourceUrl(path)
      let cont=await downloadFile(uri)
      let str=this.td.decode(cont)
      let path2=`scenes/${name}`
      let res=await resToGodot(path2,"zoneScene",str)
      z.scene=res.data
    }
//     cl(this.siteZones)
  }
  
  setMapCenter=(cent)=>{
    let ll={lat:cent.lat,lng:cent.lng}
    this.map.setCenter(ll)
    this.map.setZoom(cent.zoom)
  }
  
  showSite1=async(siteId)=>{
//     cl("done")
    await this.moveGenericZonesToGodot(siteId)
    await this.moveZoneScenesToGodot(siteId)
//     await this.moveResourcesToGodot()
    this.setMapCenter(this.siteCenter)
//     this.showTree()
//     cl("showed")
  }
  
  loadPacks=async()=>{
    cl("load packs")
    return await gdRest.callGodot("/packs","update",{packs:this.sitePacks})
  }
  
  godotReady=(obj)=>{
    this.loadPacks()
//     cl("Godot is Ready")
//     cl(this.parts)
    if(this.parts[5]=="zones"){}else{
      return this.showSite1(this.siteId)
    }
//     if(this.parts[3]=="sites"){
//       this.siteId=this.parts[4]
//     }
//     cl(this.props)
//     cl(this.state)
    cl(this.parts)
//     this.moveGenericZonesToGodot()
    return ("return to Godot")
  }
  
  godotState=(obj)=>{
//     cl(obj)
    switch(obj.method){
      case "update":
        if(obj.data.state=="ready"){return this.godotReady(obj)}
//         cl(obj)
    }
  }
  
  doGodotCamera=(obj)=>{
// if     
//     cl(obj)
    let divHgt=20
    if((this.camMode=="zoomPan")&&(obj.body.pos[1]<divHgt)){
      cl("go to walking")
      this.camMode="walking"
      this.setGodotNav("walking")
    }
//     cl(this.camMode)
    if((this.camMode=="walking")&&(obj.body.pos[1]>=divHgt)){
      cl("go to zoomPan")
      this.camMode="zoomPan"
      this.setGodotNav("zoomPan")
    }
//     cl(obj.body)
    let cent=posToLatLngCenter(obj.body.pos,this.siteCenter)
    this.checkMode(cent.zoom)
//     cl(cent)
    if(cent.zoom<=18){
      this.setMapCenter(cent)
    }
    this.setState({showMap:this.getMapOpacity(cent.zoom)})
    
  }
  
  gdRestLocal=(msg)=>{
//     cl(msg)
    let obj=JSON.parse(msg)
    let cmds={
      "/godotState":this.godotState,
      "/camera":this.doGodotCamera,
    }
    if(cmds[obj.uri]){
      return cmds[obj.uri](obj)
    }
//     cl(obj)
//     switch(obj.uri){
//       case "/godotState":
//         return this.godotState(obj)
//       case "/camera":
//         return this.doGodot
//     }
    return null
  }
  
  makeSiteIcon=async(siteId,alarms)=>{
    let siteName=globs.sitesInfo.info.filter(s=>{return s.siteId==siteId})[0].name
//     cl(siteName)
//     cl(this.siteCenter)
    let latLng={lat:this.siteCenter.lat,lng:this.siteCenter.lng}
    await wsTrans("usa", {cmd: "cRest", uri: "/s/siteIcons", method: "create", 
      sessionId: globs.userData.session.sessionId, body: {siteId:siteId,alarms:alarms}})
    let url=`http://ngsg.link4cloud.com:3105/usa/images/physViewSites/${this.siteId}.png`
//     cl(url)
    let markIcon={
      url:url,
      scaledSize:new window.google.maps.Size(32,51), 
      labelOrigin:new window.google.maps.Point(0,-20)
    }
//     cl(this.map)
    let icn=new this.google.maps.Marker({
      position:latLng,
      icon:markIcon,
      map:this.map,
      label: { color: '#ffff', fontWeight: 'bold', fontSize: '24px', text: siteName }
    })
  }
  
  getSiteRtd=async()=>{
    this.curSiteRtd=await wsTrans("usa", {cmd: "cRest", uri: "/s/siteRtd", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {siteId:this.siteId}})
  }
  
  getAllPacks=(siteId)=>{
    let packs={}
    this.siteZones.forEach(z=>{
      (z.godotPacks||[]).forEach(gp=>{
        packs[gp]=1
      })
    })
    cl(packs)
    this.sitePacks=Object.keys(packs)
  }
  
  loadInfo=async()=>{
    let ret=await checkLoggedIn()
    await loadZonesInfo()
    await loadSitesInfo()
    this.parts=this.props.parms.url.split("/")
    if(this.parts[3]=="sites"){
      this.siteId=this.parts[4]
      this.siteZones=globs.zonesInfo.info.filter(z=>{return (z.geoGH)&&(z.siteId==this.siteId)})
      this.getSiteCenterSize(this.siteId)
      this.getAllPacks(this.siteId)
    }
    await this.setMap()
    this.makeSiteIcon(this.siteId,3)
    this.uniqueResources={}
    this.otherScenes={}
    this.map.addListener("click",e=>this.onChange("mapClick",{e:e}))
    this.map.addListener("idle",e=>this.onChange("mapLoaded",{e:e}))
    if(this.siteId){
      await this.getSiteRtd()
//       cl(this.curSiteRtd)
      setInterval(this.getSiteRtd,10000)
    }
    this.setState({loaded:true})
    
  }
  
  getMapOpacity=(zm)=>{//18:0,17:.25,16:.5,15:.75,14:1.00
    let mapOpac=0.25*(18-zm)
    if(mapOpac>1){mapOpac=1}
    if(mapOpac<0){mapOpac=0}
    return mapOpac
  }
  
  checkMode=(zm)=>{
//     cl(zm)
//     cl("check")
    var newMode="site2"
    if(zm>17){newMode="site1"}
    if(zm>19){newMode="site0"}
    if(zm>22){newMode="zone"}
    if(newMode!=this.state.modeSel){
      cl(`Set new mode: ${newMode}, was ${this.state.modeSel}`)
      this.doModeSel({modeSel:newMode})
    }
    this.setState({modeSel:newMode})
  }
  
  moveGodotToMap=()=>{// on mapLoaded
    let cnt=this.map.getCenter().toJSON()
//     cl(cnt)
    let zm=this.map.getZoom()
//     cl(this.siteCenter)
//     cl(this.siteCenter.zoom)
    if(!this.siteCenter || !gdRest.gdFunc || (zm<14)){return}
//     cl(this.siteCenter)
    this.checkMode(zm)
//     cl(`New Zoom is ${zm}`)
    let dy=(cnt.lat-this.siteCenter.lat)*constant.METERS_PER_DEG
    let dx=(cnt.lng-this.siteCenter.lng)*this.siteCenter.latCos*constant.METERS_PER_DEG
    let sz=zoomMult/Math.pow(2,zm-16)
//     cl([dx,dy,sz])
    let args={
      projection:constant.GODOT_PROJECTION_ORTHOGONAL,
      pos:{x:dx,y:dy,z:sz},
      rotate:{x:0-Math.PI/2,y:0,z:0},
    }
    this.setGodotCamera(args)
//     cl(mapOpac)
    this.setState({showMap:this.getMapOpacity(zm)})
//     cl(cnt)
//     cl(zm)
    
  }
  
  doCamSel=(vals)=>{
//     cl(vals)
    this.setGodotProjection(vals.camSel)
  }
  
  doNavSel=(vals)=>{
    this.setGodotNav(vals.navSel)
  }
  
  setModeSite1=(zoneId)=>{
    // this.setDisplayFourSensor(zoneId)
    this.setDisplay(zoneId, "FourSensor")
  }

  setModeSite0=(zoneId)=>{
    this.setDisplay(zoneId, "Equipment")
    this.setDisplay(zoneId, "Sensors")

  }
  
  doModeSel=(vals)=>{// change all the zones
//     cl(this.state)
//     cl(this.props)
//     cl(this.state.modeSel)
    let gzi=globs.zonesInfo.info
    for(let i=0;i<1/*gzi.length*/;i++){
      let z=gzi[i]
//     globs.zonesInfo.info.forEach(z=>{
      if((z.siteId==this.siteId)&&(z.geoGH)){
//         cl(z.zoneId)
        this.setGodotMode(z.zoneId,vals.modeSel)
        if(vals.modeSel=="site1"){this.setModeSite1(z.zoneId)}
        if(vals.modeSel=="site0"){this.setModeSite0(z.zoneId)}
      }
    }//)
  }
  
//   MhBlV22lWIuDEKhA
//   LCUZshedgSw1Xair
//   oMc4MMK9Mmd0Vgyj

  showTree=async()=>{
    cl("show tree")
    let uri="/commands"
    let method="create"
    let body={
      cmd:"showTree",
    }
    await gdRest.callGodot(uri,method,body)
  }
  
  mapMM=async(e)=>{
    if(!gdRest.gdFunc){return}
//     cl(e)
    let uri="/mouse"
    let method="update"
    let body={mousePos:[e.clientX,e.clientY]}
//     cl(body)
    await gdRest.callGodot(uri,method,body)
    
  }
  
  getBounds=()=>{
    let bnd=this.map.getBounds()
    if(bnd){
      let ne=bnd.getNorthEast().toJSON()
      let sw=bnd.getSouthWest().toJSON()
      this.proj={
        lat0:sw.lat,
        lng0:sw.lng,
        dLat:(ne.lat-sw.lat),
        dLng:(ne.lng-sw.lng),
      }
    }
//     cl(this.proj)
  }
  
  onChange=(type,vals)=>{
    cl(type,vals)
    switch(type){
      case "upd":
//         history.push(`/cs/physView/sites/xyz`)
        if(vals["camSel"]){this.doCamSel(vals)}
        if(vals["navSel"]){this.doNavSel(vals)}
        if(vals["modeSel"]){this.doModeSel(vals)}
        this.setState(vals)
        break
      case "mapLoaded":
        this.getBounds()
        if(this.getMapMouse){
//           cl("add listener")
          this.mapDiv.current.addEventListener("mousemove",this.mapMM)
          this.getMapMouse=false
        }
//         cl(ret)
//         cl("move 1")
        this.moveGodotToMap()
//         cl("move 2")
//         cl(type)
        break
      case "mapClick":
        break
      case "buttons":
        switch(vals.id){
          case "showTree":
            this.showTree()
            break
        }
        break
    }
  }
  
//   componentDidMount=()=>{
// //     this.map=new window.google.maps.Map(this.svgDiv.current, {
//       center:new window.google.maps.LatLng(34.4,-117.64),
//       zoom: 18,
//     })
//   }

  showMap=()=>{
    const render = (status: Status) => {
      return <h1>{status}</h1>;
    };
//     cl(document.body.clientWidth)
    let st=this.state
//     let opac=(st.showMap)?0.5:0
    let left=(st.showMap)?0:-10000
    let opac=0.5
    return(
      <Wrapper apiKey={"AIzaSyCXufgRra7tRE9leN2xIV5TW0CxMRt17HQ"} render={render}>
        <div style={{
          position:"absolute",
          opacity:st.showMap,
          left:left,
          top:0,
          width:this.w,
          height:this.h,
          backgroundColor:"#998877"}}
        ref={this.mapDiv}>
        </div>
      </Wrapper>
    )
  }
  
  showModeSelect=()=>{
    let opts=[
      {v:"zone",t:"zone"},
      {v:"site0",t:"site0"},
      {v:"site1",t:"site1"},
      {v:"site2",t:"site2"},
      {v:"account",t:"account"},
    ]
    return(
      <C18Select01 parms={{
        label:"View Mode",
        modeSel:this.state.modeSel,
        valueName:"modeSel",
        opts:opts,
        onChange:this.onChange,
      }}/>
    )
  }
  
  showCamSelect=()=>{
    let opts=[
      {v:"orthogonal",t:"orthogonal"},
      {v:"perspective",t:"perspective"},
    ]
    return(
      <C18Select01 parms={{
        label:"Camera Mode",
        modeSel:this.state.camSel,
        valueName:"camSel",
        opts:opts,
        onChange:this.onChange,
      }}/>
    )
  }
  
  showNavSelect=()=>{
    let opts=[
      {v:"zoomPan",t:"zoomPan"},
      {v:"walking",t:"walking"},
    ]
    return(
      <C18Select01 parms={{
        label:"Nav Mode",
        modeSel:this.state.navSel,
        valueName:"navSel",
        opts:opts,
        onChange:this.onChange,
      }}/>
    )
  }
  
  showControls=()=>{
    let st=this.state
    return(
      <div>
      <input type="checkbox"
      checked={st.showMap!=0}
      onChange={e=>this.onChange("upd",{showMap:(e.currentTarget.checked?1:0)})}
      />
      <label htmlFor="showMap" style={{display:"inline-block"}}>
      Show Map
      </label>
      {this.showModeSelect()}
      {this.showCamSelect()}
      {this.showNavSelect()}
      </div>
    )
  }
  
  showButtons=()=>{
    let style={borderStyle:"solid",borderWidth:1,padding:5,borderColor:"#888888"}
    return(
      <div>
        <button style={style} type="button"
          onClick={e=>{this.onChange("buttons",{id:"showTree"})}}
        >Show Tree</button>
      </div>
    )
  }
  
  render(){
//     cl("render physview")
    let st=this.state
//             <div  style={{width:1024,height:768,backgroundColor:"#FFEECC"}}
//             ref={this.svgDiv} 
//     if(st.loaded){
//           {this.showButtons()}
//           <div style={{position:"absolute",left:"100",top:"100"}}>here we are!</div>
//           {this.showControls()}
      return (
        <div>
          <div style={{position:"relative"}}>
            {this.showDrawing()}
            {this.showMap()}
          </div>
        </div>
      )
//     }else{
//       return <div id="content-area">loading. . .</div>
//     }
  }
}
export default C2PhysView00;
