import React from 'react';
import {cl} from '../../components/utils/utils';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import Input from "@material-ui/core/Input";
// import InputLabel from '@material-ui/core/InputLabel';
import C18MuiInputAdornment from '../../usa/components/C18MuiInputAdornment'
import C18MuiInput from '../../usa/components/C18MuiInput'
import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'

class Numerical extends React.Component{
  constructor(props) {
    super(props);
    this.state = {value: 49};
    this.props.getValue(props.ind, {type: "scalar"})
  }

  onChange=(e)=>{
    this.props.onChange(this.props.ind,
      {value: e.target.value})
  }

  getUnit=()=>{
/* this is pretty complex:
In our custom.unit prop, is the *kind* of unit to use:
degf, mph, wpm2, liter, ml, uS, *or* a unique, one of
a kind unit, which will be used literally if it's not
found in the unitTab table. Relative Humidity just
sends "%", which is what's displayed.

LinkConn has 5 unit settings: temp, wind speed, light,
volume, and a conductivity. these are passed to us
in the current.units prop, as an array of 5 .
Each element is a zero-based index into the unitTab
below.

The first element in the arrays in uniTab is the
index into that current.units array.

So, we get the type of unit in custom.unit, and
look it up in unitTab. Then, we use the first element
of the found array as an index into current.units, to
see what unit the user has selected in LinkConn.
We have to add 1 to the result, and then we use *that*
as an index into the array that we got from unitTab.
Then *that's* the unit that we display.

There are two entries for L and ml, to indicate the
*size* of the unit. L will display as L or gal, while
ml will display as ml or oz, even though they
refer to the same unit setting in LinkConn.
*/
    // cl(this.props)
    let unitTab = {
      degf: [0, "F", "C"],
      mph: [1, "mph", "kph"],
      wpm2: [2, "w/m2", "klux", "uMol"],
      liter: [3, "L", "gal", "gal"],
      ml: [3, "ml", "oz", "ml"],
      uS: [4, "uS", "CF", "PPM"],
    }
    let unitType = unitTab[this.props.custom.unit]
    if (unitType === undefined){
      return this.props.custom.unit;
    }else{
      let unitSel = 1 + this.props.current.units[unitType[0]]*1; //How to use this?
      return unitType[unitSel];
    }
  }

  render(){
    // cl(this.props.value)
    // let val = this.props.value;
    // if (val === undefined) val = 5;
    let unit = this.getUnit();
    if (unit === undefined) unit = "";
    // cl(this.props.value)
    // cl(this.props.title)
    // cl(unit)
    return(
      <div>
      <C18MuiInputLabel htmlFor="unknown">{this.props.title}</C18MuiInputLabel>
      <C18MuiInput
          onChange={this.onChange}
          value={this.props.value}
          endAdornment={<C18MuiInputAdornment position="start">
            {unit}
          </C18MuiInputAdornment>}
          inputProps={{
              step: this.props.custom.step,
              min: this.props.custom.min,
              max: this.props.custom.max,
              type: "number", // this.state.type
              inputmode:"decimal",
          }}
      />
      </div>
    );
  }
}

  export default Numerical ;
