import React from 'react';
import UsaIcon from './UsaIcon';
import UsaSelect00 from './UsaSelect00';
import UsaCheckbox00 from './UsaCheckbox00';
import {cl, globs, constant} from '../../components/utils/utils';
import {colorSquare} from '../utils/utils'

class DataVisualizationEditScreen00 extends React.Component{
  constructor(props) {// 
    super(props);
    let p=this.props.parms
    this.state={
      title: p.title,
      color: p.color,
      bgColor: p.bgColor,
      width: p.width,
      height: p.height,
      favorite: p.favorite,
    }
    this.saveRestoreState("save")
  }
  
  saveRestoreState=(cmd)=>{
    switch(cmd){
      case "save":
        this.back=this.state
        break
      default:
        this.setState(this.back)
        break
    }
  }
  
  result=(type)=>{
//     cl(type)
    switch(type){
      case "Screen-Cancel":
        return this.props.onChange("screen-cancel", this.back)
      case "Screen-OK":
        return this.props.onChange("screen-ok", this.state)
      case "Screen-Edit":
        return this.props.onChange("paneEdit")
      case "Screen-Delete":
        return this.props.onChange("screen-delete")
      default:
        break
    }
  }
/* fields for visualization screen:
visualizationScreenId, accountId, siteId, userId,
title, color, bgColor, size, flags (favorite)*/

  render(){
    return(
      <div style={{position: "absolute", left: 150, top: 150, width: 400, height: 400, backgroundColor: "#FFCCAA", borderRadius: 20, boxShadow: "10px 10px 20px #808080", padding: 10}} 
      >
      <h3>Edit Visualization Screen
      <div/>
      <UsaIcon icon={`Screen-Edit`} result={this.result} inline/>
      <UsaIcon icon={`Screen-Delete`} result={this.result} inline/>
      <UsaIcon icon={`Screen-OK`} result={this.result} inline/>
      <UsaIcon icon={`Screen-Cancel`} result={this.result} inline/>
      </h3>
      <UsaSelect00 parms={{
        key: 0,
        title: "Title",
        width: 300,
        value: this.state.title,
        valueId: "title",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        key: 1,
        title: "Title Color",
        width: 150,
        inline: true,
        value: this.state.color,
        valueId: "color",
        onChange: o=>this.setState(o),
      }}/>
      {colorSquare(this.state.color, 50, 0)}
      <div style={{height: 10}}/>
      <UsaSelect00 parms={{
        key: 2,
        title: "Title\u00A0Background\u00A0Color",
        width: 150,
        inline: true,
        value: this.state.bgColor,
        valueId: "bgColor",
        onChange: o=>this.setState(o),
      }}/>
      {colorSquare(this.state.bgColor, 50, 1)}
      <div style={{height: 10}}/>
      <UsaSelect00 parms={{
        key: 3,
        title: "Width",
        width: 100,
        inline: true,
        value: this.state.width,
        valueId: "width",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        key: 4,
        title: "Height",
        width: 100,
        inline: true,
        value: this.state.height,
        valueId: "height",
        onChange: o=>this.setState(o),
      }}/>
      <div style={{height: 10}}/>
      <UsaCheckbox00 parms={{
        title: "Favorite",
        inline: true,
        value: this.state.favorite,
        valueId: "favorite",
        onChange: o=>this.setState(o),
      }}/>
      
      </div>
    )
  }
}

export default DataVisualizationEditScreen00 ;

