import React from 'react';
import UsaSelect00 from './UsaSelect00';
import UsaIcon from './UsaIcon';
import {cl} from '../../components/utils/utils';
import {pi, dpNames, pInd, tableBases2} from '../../components/utils/paramIds';

class Datapoints extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.tmp=Object.assign({}, props.parms.vals)
    this.field=props.parms.field||"dp"// accommodate lp and hp
    cl(this.tmp)
    cl(this.field)
    let v=props.parms.vals
    this.state={
      search: "",
      results: [],
      zone: v.zone,//(+v.zone>=0) ? v.zone : "(none)",
      channel: v.channel,//(+v.channel>=0) ? v.channel: "(none)",
      index: v.index,//(+v.index>=0) ? v.index: "(none)",
      timeOffset: v.timeOffset,
      verticalOffset: v.verticalOffset,
      dpSel:-1,
      dpName: "whats",
      editMode: (props.parms.mode=="select") ? true: false,
      dashboardIcon2: "datapoint-Edit",
    };
    this.state.dp=this.tmp[this.field]//.dp
    cl(this.state)
    this.makeDPNames()
//     cl("init")
//     this.getDatapointName(this.state.dp)
  }
  
  makeDPNames=()=>{
    this.dpNames=[];
//     cl(dpNames)
//     cl(Object.keys(pi))
//     cl(pi[1800]["snapshots"].length)
    Object.entries(dpNames).forEach(e=>{
      Object.entries(pi[1800][e[1]]).forEach(en=>{
        this.dpNames.push(e[0] + "." + en[0])
      })
    })
//     cl(this.dpNames)
  }
  
  doSearch=()=>{
    let searchLen=100
    let end=this.searchPos + searchLen
    if(end > this.dpNames.length) {end = this.dpNames.length}
    let words=this.search.toLowerCase().split(" ")
    for(let i=this.searchPos; i<end; i++){
      let add=true
      for(let j=0 ; j<words.length; j++){
        add&=(this.dpNames[i].toLowerCase().indexOf(words[j])>=0)
      }
      if(add){this.results.push(this.dpNames[i])}
//       cl(this.dpNames[i])
    }
    this.searchPos=end
    this.setState({results: this.results})
    if((end < this.dpNames.length)&&(this.results.length<20)){setTimeout(this.doSearch, 0)}
//     cl(this.results)
  }
  
  newSearch=(o)=>{
    this.setState(o)
    this.search=o.search
    this.results=[]
    this.searchPos=0
    this.doSearch()
  }
  
  getParamId=(table, column)=>{
    let fieldName=dpNames[table]
    let type = pInd[1800][fieldName]
    let pid = pi[1800][fieldName][column]
    let dp=pid+type[0]
    return dp
//     cl(dp)
  }
  
  idToTabInd=(id)=>{
    for (let i = 0 ; i < tableBases2.length ; i++){
      if(tableBases2[i][0] > id) return i - 1;
    }
  }
  
  tabIndToName=(base)=>{
    for(let i=0; i<Object.keys(pInd[1800]).length; i++){
      let k=Object.keys(pInd[1800])[i];
      if(pInd[1800][k][0]==base)return k
    }
  }
  
  tabOffsetToName=(tab, ofs)=>{
//     cl([tab, ofs])
    for(let i=0; i<Object.keys(pi[1800][tab]).length; i++){
      let k=Object.keys(pi[1800][tab])[i]
      if(pi[1800][tab][k]==ofs) return k
    }
  }
  
  getDatapointName=(pid)=>{
    if(!pid) return ""
//     cl(dpNames)
    let ind = this.idToTabInd(pid)
    let tab=(tableBases2[ind])
    let base=tab[0]
    let tabName=this.tabIndToName(base)
//     cl(tabName)
    let ofsName=this.tabOffsetToName(tabName, pid-base)
    let type=tab[1]
//     cl([tabName, ofsName])
    let abv = (tabName.indexOf("snap")>=0) ? "snap" : "conf"
    let ret=abv + "-" + ofsName
    cl(ret)
    return ret
  }
  
/* table types:
z 0 snapshots per unit
z c 1 snapshot channels  per channel
z c 2 snapshot ecph  per tank
z c i 3 config ecph sensor per tank and sensor
z i  4 config setpoints  per setpoint
none 5 config communication for whole site
z    6 zone configuration per zone
z    7 controller configuration per unit */
  parentChange=(vals)=>{
//     cl(vals)
    if(this.props.parms.mode=="select"){
      this.props.onChange(vals)
    }
    this.setState(vals)
  }
  
  clickResult=(i)=>{
//     cl(this.state.results[i])
    let tc=this.state.results[i].split(".")
//     cl(tc)
    let dp=this.getParamId(tc[0], tc[1])
    let s=this.state
//     cl(s)
//     let zone=(s.zone=="(none)")
    let obj={dp: dp, dpSel: i}
//     cl((s.zone != ""))
    obj.zone=s.zone//((s.zone != "") && (s.zone!="(none)")) ? s.zone : -1
    obj.channel=s.channel//((s.channel!="") && (s.channel!="(none)")) ? s.channel : -1
    obj.index=s.index//((s.index!="") && (s.index!="(none)")) ? s.index : -1
    obj.dpName=this.state.results[i]//s.results[s.index]
//     cl(obj)
    this.parentChange(obj)
//     this.props.onChange(obj)
//     this.setState({dpSel: i, dp: dp})
//     this.setState({editMode: false, dashboardIcon2: "datapoint-Edit", dp: dp})
//     cl(tc)
  }
  
  showResults=()=>{
    return(
      <div style={{width: this.props.parms.width, height: 150, backgroundColor: "lightBlue", overflowY: "auto", cursor: "pointer",}}>
      {this.state.results.map((r,i)=>{
        let color=(this.state.dpSel==i)?"bisque" : "lightBlue"
        let style={backgroundColor: color}
        return <span key={i} style={style} onClick={c=>{this.clickResult(i)}}>{r}<br/></span>
      })}
      </div>
    )
  }
  
  showHead=()=>{
    let dpName=(this.props.parms.title)?this.props.parms.title:"Datapoint"
    if(this.props.parms.mode=="select"){}else{
      let name=this.getDatapointName(this.state.dp)
      return(
        <>
          <UsaIcon icon={"datapoint-OK"} result={this.result} inline/>
          <UsaIcon icon={"datapoint-Cancel"} result={this.result} inline/>
          <span><h3 style={{display: "inline-block"}}>{dpName}:</h3></span>
          {` ${name}`}
        </>
      )
    }
  }
  
  editDataPoint=()=>{
    return(
      <div>
      {this.showHead()}
      <UsaSelect00 parms={{
        title: "Search",
        width: 200,
        value: this.state.search,
        valueId: "search",
        onChange: this.newSearch,
      }}/>
      {this.showResults()}
      <UsaSelect00 parms={{
        title: "Override\u00A0Zone",
        width: 100,
        inline: true,
        value: this.state.zone,
        valueId: "zone",
        onChange: o=>this.parentChange(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Override\u00A0Channel",
        width: 100,
        inline: true,
        value: this.state.channel,
        valueId: "channel",
        onChange: o=>this.parentChange(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Override\u00A0Index",
        width: 100,
        inline: true,
        value: this.state.index,
        valueId: "index",
        onChange: o=>this.parentChange(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Vertical\u00A0Offset",
        width: 100,
        inline: true,
        value: this.state.verticalOffset || 0,
        valueId: "verticalOffset",
        onChange: o=>this.parentChange(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Time\u00A0Offset",
        width: 100,
        mode: "muiDuration00",
        inline: true,
        value: this.state.timeOffset || 0,
        valueId: "timeOffset",
        onChange: o=>this.parentChange(o),
      }}/>
      <div style={{height: 20}}></div>
      </div>
    )
  }
  
  saveRestoreDatapoints=(cmd)=>{
    switch(cmd){
      case "save":
        this.back=this.state
        break
      default:
        let b=this.back
        this.setState({zone: b.zone, channel: b.channel, index: b.index})
        break
    }
  }
  
  result=(type)=>{
//     cl(type)
    switch(type){
      case "datapoint-Edit":
        this.saveRestoreDatapoints("save")
        this.setState({editMode: true, dashboardIcon2: "datapoint-Cancel"})
        break
      case "datapoint-OK":
        let s=this.state
        let vals={dp: s.dp, zone: s.zone, channel: s.channel, index: s.index}
        cl(vals)
        this.props.onChange(vals)
        this.setState({editMode: false, dashboardIcon2: "datapoint-Edit"})
        break
      case "datapoint-Cancel":
//         let next= (this.state.editMode) ? 
        this.saveRestoreDatapoints("restore")
        this.setState({editMode: false, dashboardIcon2: "datapoint-Edit"})
//         {editMode: true, dashboardIcon2: "datapoint-Cancel"} 
//         this.setState(next)
        break;
      default:
        break
    }
    
  }
  
  displayDatapoint=()=>{
    let name=this.getDatapointName(this.state.dp)
    let dpName=(this.props.parms.title)?this.props.parms.title:"Datapoint"
    return(
      <div>
      <UsaIcon icon={"datapoint-Edit"} result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>{dpName}:</h3></span>
      {` ${name}`}
      </div>
    )
  }
  
  render(){
    if(this.state.editMode){
      return this.editDataPoint()
    }else{
      return this.displayDatapoint()
    }
  }
}

export default Datapoints ;

