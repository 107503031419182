import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import TextField from '@material-ui/core/TextField';
import {cl} from '../../components/utils/utils';

class UsaArrayFlags00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
//     this.state={
//       value: false,
//     }
  }
  
  onClick=(i)=>{
//     cl(i)
    let arr=this.props.parms.value.slice(0)
    arr[i]=!arr[i]
    let vals={}
    vals[this.props.parms.valueId]=arr
    this.props.parms.onChange(vals)
  }
  
  renderArr=(arr)=>{// children is an array or a scalar
    if(typeof arr[2]==='object'){// arr[2] is an array of children
      let children=arr[2].map(c=>{return this.renderArr(c)})
      return React.createElement(arr[0],arr[1],children)// render the children, and return as array
    }else{
      return React.createElement(...arr)
    }
  }
  
  showArray=()=>{
    let p=this.props.parms
    var rows, columns, count, opts
    opts=p.opts
    count=opts.length
    if(p.columns){
//       cl(p.columns)
      columns=p.columns
      rows=Math.floor((count+columns-1)/columns)
    }else{
      columns=count
      rows=1
    }
//     let columns=7
//     let rows=5
    let cont=[]
    for(let i=0; i<rows; i++){
      let row=[]
      for(let j=0; j<columns; j++){// make td children
        let val=(columns*i+j)
        let show=(val>count)?"":opts[val]
        let bgColor=(this.props.parms.value[val])?"#FFDD88":"#CCCCCC"
        row.push(['td', {key: j, style:{cursor: "pointer", backgroundColor: bgColor}, 
          onClick: ()=>this.onClick(val) }, show])
//         let bgColor=(this.props.parms.value[i])?"#FFFFDD":"#DDDDDD"
//       {this.props.parms.opts.map((o,i)=>{
//         let bgColor=(this.props.parms.value[i])?"#FFFFDD":"#DDDDDD"
//         return <td key={i} style={{cursor: "pointer", backgroundColor: bgColor}} 
//           onClick={()=>this.onClick(i)}>{o}</td>
        
      }
      cont.push(['tr', {key: i}, row])
    }
    let tbody=['tbody',{key: 0},cont]
    let table=['table',{key: 0},[tbody]]
    return this.renderArr(table)
  }
  
  showArray2=()=>{
    let columns=7
    let rows=4
    let cont=[]
    for(let i=0; i<rows; i++){
      let row=[]
      for(let j=0; j<columns; j++){// make td children
        let val=(1+columns*i+j)
        if(val>31){val=""}
        row.push(
          React.createElement('td',{key: j, style:{cursor: "pointer"}},val)
        )
        cl(['td',{key: j, style:{cursor: "pointer"}},val])
      }
      cont.push(
        React.createElement('tr',{key: i},row)
      )
      cl(['tr',{key: i},row])
    }
    let tbody=React.createElement('tbody',null,cont)
    cl(['tbody',null,cont])
    let table=React.createElement('table',null,tbody)
    cl(['table',null,tbody])
    return table
    
//     return(
//       <table><tbody>
//       <tr>
//       {this.props.parms.opts.map((o,i)=>{
//         let bgColor=(this.props.parms.value[i])?"#FFFFDD":"#DDDDDD"
//         return <td key={i} style={{cursor: "pointer", backgroundColor: bgColor}} 
//           onClick={()=>this.onClick(i)}>{o}</td>
//       })}
//       </tr>
//       </tbody></table>
//     )
    
//     return(
//       <table><tbody>
//       <tr>
//       {this.props.parms.opts.map((o,i)=>{
//         let bgColor=(this.props.parms.value[i])?"#FFFFDD":"#DDDDDD"
//         return <td key={i} style={{cursor: "pointer", backgroundColor: bgColor}} 
//           onClick={()=>this.onClick(i)}>{o}</td>
//       })}
//       </tr>
//       </tbody></table>
//     )
  }
  
  render(){
    return(
      <div>
        {this.showArray()}
      </div>
    )
  }
}

export default UsaArrayFlags00 ;

