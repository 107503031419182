import React from 'react';
import UsaSelect00 from './UsaSelect00';
// import EditWidget01 from './EditWidget01';
import Position00 from './Position00';
import Parameters00 from './Parameters00';
import Datapoints from './Datapoints';
import DatapointList00 from './DatapointList00';
import Colors00 from './Colors00';
import Labels00 from './Labels00';
import TimePeriod00 from './TimePeriod00';
import Defaults00 from './Defaults00';
import UsaIcon from './UsaIcon';
import NameWidget00 from '../../visualization/components/NameWidget00';
import GenericGaugeWidget01 from '../../visualization/components/GenericGaugeWidget01';
import EquipmentListWidget00 from '../../visualization/components/EquipmentListWidget00';
import ZoneListWidget00 from '../../visualization/components/ZoneListWidget00';
import DashboardWidget00 from '../../visualization/components/DashboardWidget00';
import LinkWidget01 from '../../visualization/components/LinkWidget01';
// import Alert from '@material-ui/lab/Alert';
// import Password from './Password';
// import TextInput from './TextInput';
// import OkCancel from './OkCancel';
// import UsaButton from './UsaButton';
// import {wsTrans, saveTokens, logout } from '../utils/utils';
import {cl, globs, constant} from '../../components/utils/utils';
import {wsTrans} from '../utils/utils'
import {doGetPostBasic} from '../utils/utils'
// import history from "../../history"

class EditWidget00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(NameWidget00)
    let nw=new NameWidget00({notify: x=>{}})
    let gw01=new GenericGaugeWidget01({notify: x=>{}})
    let el00=new EquipmentListWidget00({notify: x=>{}})
    let zl00=new ZoneListWidget00({notify: x=>{}})
    let db00=new DashboardWidget00({notify: x=>{}})
    let li01=new LinkWidget01({notify: x=>{}})
    this.newWidgets=[nw,gw01,el00,zl00,db00,li01]
//     cl(props)
//     cl(props.parms.widget)
    this.props.notify({id: "dashboardSel", func: this.onDashboardSel})
    this.state={
      loaded: false,
      editMode: false,
      dashboardSel: "",
      widgetSel: props.parms.widget,
      widgetType: "generic",
      dashboardIcon1: "widget-New",
      dashboardIcon2: "widget-Edit",
      dashboardIcon3: "widget-Delete",
      position: {
        left: 5,
        top: 0,
        width: 1,
        height: 1,
      },
      timePeriod:{
        flags: 0,
        duration: 0,
        period: 24*3600,// 1 day
        beginTime: 100,
        endTime: 1000,
      },
      defaults:{
        zone: 0,
        chan: 0,
        ind: 0,
      },
      parameters:{
        title: "",
        bgColor: "#90FFFF",
        dec: 1,
        mult: 1,
        note: "",
        link: "",
      },
      datapoint:{
        dp: 205,
      },
      datapointList:[
        {zone: 1, channel: 4, index: 3, pid: 23, color: "#FF0000"},
      ],
      colors:[
      {val: 0, color: "#FFCC00", edit: false},
      {val: 10, color: "#FFCC40", edit: false},
      {val: 20, color: "#FFCC80", edit: false},
      {val: 30, color: "#FFCCC0", edit: false},
      ],
      labels:[
      {val: 5},
      {val: 10},
      {val: 18},
      ],
    };
    this.widgetOpts=[]
//     this.widgetOpts=[
//       {v: 1, t: "one"},
//       {v: 2, t: "two"},
//       {v: 3, t: "three"},
//     ]
    this.widgetTypeOpts=[
    {v: "inTemp", t: "inTemp"},
    {v: "generic", t: "Generic"},
    {v: "timeSeries", t: "Time Series"},
    {v: "equipment", t: "Equipment"},
    {v: "link", t: "Link"},
    {v: "feed", t: "Feed"},
    {v: "site", t: "Site"},
    {v: "zone", t: "Zone"},
    ]
    this.addNewWidgetTypes(this.widgetTypeOpts)
//     cl("load")
    this.loadWidgets()
  }
  
/********************* These are the new Widget functions, to make them freestanding**********************/
  addNewWidgetTypes=(opts)=>{
    this.newWidgets.forEach(w=>{
      opts.push(w.widgetType)
    })
//     cl(opts)
  }
  
  editNewWidgetType=(widgetType)=>{
    let nw=this.newWidgets
    for(let i=0;i<nw.length;i++){
      if(nw[i].widgetType.v==widgetType){
        return nw[i].editType(this)
      }
    }
    return(
      <div>
      default old-widget type
      </div>
    )
  }
  
  newWidgetParameterChange=(v, widgetType)=>{
//     cl(v)
//     cl(this.state)
    let nw=this.newWidgets
    for(let i=0;i<nw.length;i++){
      if(nw[i].widgetType.v==widgetType){
        return nw[i].parameterChange(v)
      }
    }
    return{title: v.title, bgColor: v.bgColor, dec: v.dec, mult: v.mult, note: v.note, 
      link: v.link, type: v.type, xTicks: v.xTicks, yTicks: v.yTicks
    }
  }
  
  
/********************* End of new Widget functions **********************/

  onDashboardSel=async(val)=>{// called by the parent when it changes
//     cl("load dashbaord")
    this.makeWidgetOpts(val.dashboardSel)
    this.setState(val)
  }
  
  sortWidgets=(wdgs)=>{// sorts by position on dashboard
//     wdgs.forEach(w=>{cl([w.position.left, w.position.top])})
    wdgs.sort((a,b)=>{
      let ap = 100 * a.position.top + a.position.left
      let bp = 100 * b.position.top + b.position.left
      if(ap<bp){
        return -1
      }else{
        if(ap>bp){
          return 1
        }else{
          return 0
        }
      }
    })
//     cl(wdgs)
  }
  
  trimName=(name)=>{
    if(name.length>15){
      return name.substr(0,15)+"..."
    }else{
      return name
    }
  }
  
  makeWidgetOpts=(dashboard)=>{
//     cl(dashboard)
//     cl("make widgetopts")
    this.widgetOpts=[]
    Object.keys(this.widgets).forEach((k,i)=>{
//       cl(this.widgets[k].dashboard);
//       cl(dashboard)
      if(this.widgets[k].dashboard==dashboard){
//         cl(this.widgets[k])
        let name=this.widgets[k].parameters.title+"-"+i
        this.widgetOpts.push({v: k, t: this.trimName(name)})
      }
    })
//     cl(this.widgets)
  }
  
  loadSites=async()=>{
//     cl("load sites")
    this.sites=[]
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {}})// get all for account
    this.sites=r.data
  }
  
  loadWidgets=async()=>{// this is getting called twice
//     cl(this.props)
//     cl(this.state)
//     cl(`load widgets: ${dashboard}`)
    this.loadSites()
    this.widgetOpts=[]
    this.widgets={}
//     cl("load")
//     if(dashboard==""){
// //       this.setState({dashboardSel: dashboard})// causing an unmounted error
//       return
//     }
//     cl(this.props)
    let getDashboard={dashboard: "none"}
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/widgets", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {dashboard: this.props.parms.dashboard}})
//     cl(r)
    if(r.data.length){}else{
      r=await wsTrans("usa", {cmd: "cRest", uri: "/s/widgets", method: "retrieve", 
        sessionId: globs.userData.session.sessionId, body: {accountId: globs.userData.session.accountId}})
    }
//     cl(r)
//     cl(r)
    
    
//     let getWidgets={cmd: "cRest", uri: "/s/widgets", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId,
//       body: {userId: globs.userData.session.userId}};// 
//     let r=await wsTrans("usa", getWidgets)
//     cl("load")
//     cl(r)
/* this is not working when we get the widgets *after* the dashboard has been set. */    
//     cl("got widgets***************************************************")
    let wa=r.data// the widgets as they are in the db
//     cl(wa)
//     cl("load")
//     cl(wa)
    if(wa.length==0) return
    this.sortWidgets(wa)
    wa.forEach((w,i)=>{
      this.widgets[w.id]=w
      let name=w.parameters.title+"-"+i
//       cl(w.dashboard)
      if(w.dashboard == this.props.parms.dashboard){
//         cl(w)
        this.widgetOpts.push({v: w.id, t: name})
      }
    })
//     cl(this.widgetOpts)
//     cl("load")
//     cl("load")
    var sel=""
    if(this.widgetOpts.length){
      sel=(this.state.widgetSel) ? this.state.widgetSel : this.widgetOpts[0].v
      this.makeWidgetOpts(this.state.dashboardSel)
    }
// 	cl("set")
//     cl("load")
    this.setState({widgetSel:sel, loaded: true})
  }
  
//   selectWidgets=(dashboard)=>{
// //     cl(dashboard)
//     Object.entries(this.widgets).forEach(w=>{
//       if(w[0]==dashboard){
//       cl(w)
//       }
//     })
//   }
  
  sendImage=(imgName, images)=>{
    cl([imgName, images])
    let n=imgName
    let imageId=n.substr(0,n.lastIndexOf('.'))
    let data = new FormData()
    data.append("type", "background")
    data.append("sessionId", globs.userData.session.sessionId)
    data.append('file', images)
    data.append('id', imageId)
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    doGetPostBasic(url, method, data, type)
  }
  
  saveWidget=async()=>{
//     cl(this.state.widgetSel)
//     return
//     cl(this.state.datapointList)
//     cl(this.state.parameters)
    let widget={
        accountId: globs.userData.session.accountId,
        dashboard: this.props.parms.dashboard,
        id: this.state.widgetSel,
        userId: globs.userData.session.userId,
        position: this.state.position,
        period: this.state.timePeriod,
        defaults: this.state.defaults,
        type: this.state.widgetType,
        parameters: this.state.parameters,
        lowpoint: this.state.lowpoint,
        highpoint: this.state.highpoint,
        datapoint: this.state.datapoint,
        datapointList: this.state.datapointList,
        colors: this.state.colors,
        labels: this.state.labels,
    }
    widget.datapointList.forEach(dp=>{ dp.data=[] })// erase any data
    let p=widget.parameters
    if(p.images){this.sendImage(p.imgName, p.images)}
    cl(widget)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/widgets", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: widget})
    this.widgets[r.data.id]=widget
    this.makeWidgetOpts(this.state.dashboardSel)
    globs.events.publish("widgetUpdated", {widget: widget})
    this.setState({widgetSel: r.data.id})
//     cl(r)
  }
  
  deleteFromWidgetOpts=(id)=>{
    for(let i=0; i<this.widgetOpts.length; i++){
      if(id==this.widgetOpts[i].v){
        this.widgetOpts.splice(i,1);
        return
      }
    }
  }
  
  deleteWidget=async()=>{
//     cl("delete widget")
    let widgetId={id: this.state.widgetSel}
    delete this.widgets[this.state.widgetSel]
    this.deleteFromWidgetOpts(this.state.widgetSel)
    cl("state")
    this.setState({widgetSel: this.widgetOpts[0].v})
//     cl(this.widgets)
    let delWidget={cmd: "cRest", uri: "/s/widgets", method: "delete", sessionId: globs.userData.session.sessionId,
      body: widgetId};
    let r=await wsTrans("usa", delWidget)
  }
  
  getNewWidget=()=>{
    cl("state")
    this.setState({
      widgetType: "generic",
      position: {left: 0, top: 0, width: 1, height: 1},//Object.assign({}, w.position),
        parameters: {title: ""},//Object.assign({}, w.parameters),
        datapoint: {},//Object.assign({}, w.datapoint),
        colors: [{val: 0, color: "#FFFFFF"}],//w.colors.slice(0),
        labels: [{val: 0}],//w.labels.slice(0),
    })
  }
  
  getLocalWidget=()=>{// copy from widgets array to state
//     cl(this.widgets)
//     cl(this.state.widgetSel)
    let w=this.widgets[this.state.widgetSel]
    if(!w){return}
//     cl(w)
//     cl(this.widgets)
//     cl(w)
//     cl("state")
    this.setState({
      widgetType: w.type,
      position: Object.assign({}, w.position),
      defaults: Object.assign({}, w.defaults),
      parameters: Object.assign({}, w.parameters),
      datapoint: Object.assign({}, w.datapoint),
//       datapointList: Object.assign({}, w.datapointList),
      datapointList: (w.datapointList) ? w.datapointList.slice(0) : [],
      timePeriod: Object.assign({flags: constant.TIME_PERIOD_LIVE}, w.period),
      colors: w.colors.slice(0),
      labels: w.labels.slice(0),
    })
  }
  
  result=(type)=>{
//     cl(type)
    switch(type){
      case "widget-New":
        this.getNewWidget()
        cl("state")
        this.setState({editMode: true, dashboardIcon1: "widget-OK", dashboardIcon2: "widget-Cancel", 
          dashboardIcon3: "widget-Blank", widgetSel: ""})
        break
      case "widget-Edit":
        this.getLocalWidget()
//         cl("state")
        this.setState({editMode: true, dashboardIcon1: "widget-OK", dashboardIcon2: "widget-Cancel", 
          dashboardIcon3: "widget-Blank"})
        break
      case "widget-Delete":
        this.deleteWidget()
        break;
      case "widget-OK":
        this.saveWidget()
      case "widget-Cancel":
//         cl("state")
        this.setState({editMode: false, dashboardIcon1: "widget-New", dashboardIcon2: "widget-Edit", 
          dashboardIcon3: "widget-Delete"})
        break
      default:
        break
    }
  }
  
//   notify=(note)=>{
//     if(note.id in this.notifies){}else{
//       this.notifies[note.id]=[]
//     }
//     this.notifies[note.id].push(note.func)
//   }
//   
//   setMyState=(o)=>{
//     this.setState(o)
//     for(let k in o){
//       if(k in this.notifies){
//         this.notifies[k].forEach(f=>{
//           f(o)
//         })
//       }
//     }
//   }

  setStateValue=(vals)=>{
    cl(vals)
//     cl("state")
    this.setState(vals);
  }
  
  widgetSelect00=()=>{
//     cl(this.widgetOpts)
//     let emode=this.state.editMode
//     let valueId=(emode) ? "widgetName" : "widgetSel"
//     let value=(emode) ? this.state.widgetName : this.state.widgetSel
//     let title=(emode) ? "" : ""
    return(
      <div>
      <UsaIcon icon={this.state.dashboardIcon1} result={this.result} inline/>
      <UsaIcon icon={this.state.dashboardIcon2} result={this.result} inline/>
      <UsaIcon icon={this.state.dashboardIcon3} result={this.result} inline/>
      {(this.state.widgetSel!=="") &&
      <UsaSelect00 parms={{
        title: "Select\u00A0Widget\u00A0on\u00A0Page",
        select: true, // !this.state.editMode,
        inline: true,
        valueId: "widgetSel",//valueId, // "widgetSel",
        value: this.state.widgetSel, //value, // this.state.widgetSel,
//         textId: "widgetName",
        opts: this.widgetOpts,
        onChange: this.setStateValue,
      }}/>}
      </div>
    )
  }
  
  widgetTypeSelect=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Widget\u00A0Type",
        select: true,
        text: this.state.text,
        textId: "text",
        value: this.state.widgetType,
        valueId: "widgetType",
        opts: this.widgetTypeOpts,
        onChange: o=>{this.setState(o); cl("state")},
      }}/>
    )
  }
  
  changeFeed=(v)=>{
//     cl(v)
    let inc=[]
    if(v.incGJ)inc.push("GJ")
    if(v.incMsg)inc.push("Msg")
    if(v.incTasks)inc.push("Tasks")
    if(v.incAlarms)inc.push("Alarms")
    if(v.incActivity)inc.push("Activity")
    if(v.incNotification)inc.push("Notification")
    if(v.incAdmin)inc.push("Admin")
    if(v.incIDoser)inc.push("IDoser")
    return {
      bgColor: v.bgColor,
      inc: inc,
      title: v.title,
    }
  }
  
  changeParameters=(v)=>{
//     cl(this.state)
    let parameters={}
    switch(this.state.widgetType){
      case "feed":
        parameters= this.changeFeed(v)
//         cl(parameters)
        break
      case "site":
        parameters={bgColor: v.bgColor, site: v.site, title: v.title, link: v.link, backImage: v.backImage, imgName: v.imgName, images: v.images}
        break
      case "zone":
        cl("zone")
        cl(v)
        parameters={bgColor: v.bgColor, 
          tmpFlag: v.tmpFlag, 
          humFlag: v.humFlag, 
          co2Flag: v.co2Flag, 
          litFlag: v.litFlag, 
          ecFlag: v.ecFlag, 
          phFlag: v.phFlag, 
          almFlag: v.almFlag, 
          transparent: v.transparent,
          graphBar: v.graphBar,
          link: v.link,
          title: v.title}
        break
      case "timeSeries":
        parameters={
          bgColor: v.bgColor,
          currentValueColor: v.currentValueColor,
          graphType: v.graphType,
          showCurrentValue: v.showCurrentValue,
          title: v.title,
          xTicks: v.xTicks,
        }
        cl(parameters)
        break
      case "equipment":
        parameters={
          title: v.title,
          link: v.link,
          offColor: v.offColor,
          onColor: v.onColor,
        }
        cl(parameters)
        break
      default:
        parameters=this.newWidgetParameterChange(v, this.state.widgetType)
//         parameters={title: v.title, bgColor: v.bgColor, dec: v.dec, mult: v.mult, note: v.note, 
//           link: v.link, type: v.type, xTicks: v.xTicks, yTicks: v.yTicks
//         }
        break
    }
    this.setState({parameters: parameters})
    
  }
  
  onChange=(type, v)=>{
    cl([type, v])
//     cl(this.state)
    var datapoint
    switch(type){
      case "position":
        let position={left: v.left, top: v.top, width: v.width, height: v.height}
        cl("state")
        this.setState({position: position})
        break
      case "defaults":
        let defaults={zone: v.zone, chan: v.chan, ind: v.ind}
        cl("state")
        this.setState({defaults: defaults})
        break
      case "parameters":
//         cl(v)
        this.changeParameters(v)
//         let parameters={title: v.title, bgColor: v.bgColor, dec: v.dec, mult: v.mult, note: v.note, link: v.link, type: v.type, xTicks: v.xTicks, yTicks: v.yTicks
//         }
// //         cl("state")
//         this.setState({parameters: parameters})
        break
      case "lowpoint":
        datapoint=Object.assign(this.state.datapoint,{lp:v.dp})
        this.setState({datapoint: datapoint})
        break
      case "highpoint":
        datapoint=Object.assign(this.state.datapoint,{hp:v.dp})
        this.setState({datapoint: datapoint})
        break
      case "datapoint":
        datapoint=Object.assign(this.state.datapoint,{dp:v.dp})
        this.setState({datapoint: datapoint})
//         this.setState({datapoint: v})
        break
      case "datapointList":
//         cl("state")
        cl(v)
        this.setState({datapointList: v})
        break
      case "colors":
        let colors=v.colors.slice(0)
        cl("state")
        this.setState({colors: colors})
        break
      case "labels":
        let labels=v.labels.slice(0)
        cl("state")
        this.setState({labels: labels})
        break
      case "timePeriod":
//         cl(this.state)
        let timePeriod={duration: v.duration, period: v.period, 
          flags:v.flags,
          beginTime: v.beginTime,
          endTime: v.endTime,
          periodType: v.periodType,
          barCount: v.barCount,
          showValueNames: v.showValueNames,
          showValues: v.showValues,
        }
//         cl("state")
        this.setState({timePeriod: timePeriod})
//         timePeriod:{
//           duration: 0,
//           period: 24*3600,// 1 day
//         },
        break
      default:
        break
    }
    //     this.props.onChange(type, vals)
  }
  
  inTempType=()=>{
    let parm=Object.assign({}, this.state.parameters)
    let pos=Object.assign({}, this.state.position)
    let dp=Object.assign({}, this.state.datapoint)
//     <Datapoints parms={{vals: dp}} onChange={(vals)=>{this.onChange("datapoint", vals)}}/>
    return(
      <div>
      <Parameters00 parms={{vals: parm}} type="link" onChange={(vals)=>{this.onChange("parameters", vals)}}/>
      <Position00 parms={{vals: pos}} onChange={(vals)=>{this.onChange("position", vals)}}/>
      </div>
    )
  }
  
  timeSeriesType=()=>{
//     cl(this.state)
    let pos=Object.assign({}, this.state.position)
//     cl(pos)
    let period=Object.assign({}, this.state.timePeriod)
//     cl(period)
    let parm=Object.assign({}, this.state.parameters)
//     let dpList=Object.assign({}, this.state.datapointList)
    let dpList=this.state.datapointList.slice(0)
//       <TimePeriod00 parms={{vals: {
//         flags: this.state.flags,
//         duration: this.state.duration,
//         period: this.state.period,
//         beginTime: this.state.beginTime,
//         endTime: this.state.endTime,
//       }/*s.timePeriod*/}} onChange={(vals)=>{this.onChange("timePeriod", vals)}}/>
//       <TimePeriod00 parms={{vals: period}} onChange={(vals)=>{this.onChange("timePeriod", vals)}}/>
    return(
      <div>
      <Position00 parms={{vals: pos}} onChange={(vals)=>{this.onChange("position", vals)}}/>
      <Parameters00 parms={{vals: parm}} type="timeSeries" onChange={(vals)=>{this.onChange("parameters", vals)}}/>
      <TimePeriod00 parms={{vals: {
        flags: this.state.timePeriod.flags,
        duration: this.state.timePeriod.duration,
        period: this.state.timePeriod.period,
        beginTime: this.state.timePeriod.beginTime,
        endTime: this.state.timePeriod.endTime,
        periodType: this.state.timePeriod.periodType,
        barCount: this.state.timePeriod.barCount,
        showValues: this.state.timePeriod.showValues,
        showValueNames: this.state.timePeriod.showValueNames,
      },
      graphType: this.state.parameters.graphType,
      /*s.timePeriod*/}} onChange={(vals)=>{this.onChange("timePeriod", vals)}}/>
      
      <DatapointList00 parms={{vals: dpList}} onChange={(vals)=>{this.onChange("datapointList", vals)}}/>
      
      </div>
    )
  }
  
  feedType=()=>{
    let pos=Object.assign({}, this.state.position)
    let parm=Object.assign({}, this.state.parameters)
    let def=Object.assign({}, this.state.defaults)
    return(
      <div>
      <Position00 parms={{vals: pos}} onChange={(vals)=>{this.onChange("position", vals)}}/>
      <Defaults00 parms={{vals: def}} onChange={(vals)=>{this.onChange("defaults", vals)}}/>
      <Parameters00 parms={{vals: parm}} type="feed" onChange={(vals)=>{this.onChange("parameters", vals)}}/>
      </div>
    )
  }
  
  equipmentType=()=>{
    let pos=Object.assign({}, this.state.position)
    let parm=Object.assign({}, this.state.parameters)
    let def=Object.assign({}, this.state.defaults)
    return(
      <div>
      <Position00 parms={{vals: pos}} onChange={(vals)=>{this.onChange("position", vals)}}/>
      <Parameters00 parms={{vals: parm}} type="equipment" onChange={(vals)=>{this.onChange("parameters", vals)}}/>
      <Defaults00 parms={{vals: def}} onChange={(vals)=>{this.onChange("defaults", vals)}}/>
      </div>
    )
  }
  
  linkType=()=>{
    let pos=Object.assign({}, this.state.position)
    let def=Object.assign({}, this.state.defaults)
    let parm=Object.assign({}, this.state.parameters)
    return(
      <div>
      <Position00 parms={{vals: pos}} onChange={(vals)=>{this.onChange("position", vals)}}/>
      <Defaults00 parms={{vals: def}} onChange={(vals)=>{this.onChange("defaults", vals)}}/>
      <Parameters00 parms={{vals: parm}} type="link" onChange={(vals)=>{this.onChange("parameters", vals)}}/>
      </div>
    )
  }
  
  siteType=()=>{
    let pos=Object.assign({}, this.state.position)
    let parm=Object.assign({}, this.state.parameters)
    return(
      <div>
      <Position00 parms={{vals: pos}} onChange={(vals)=>{this.onChange("position", vals)}}/>
      <Parameters00 parms={{
        vals: parm,
        sites: this.sites
        
      }} type="site" onChange={(vals)=>{this.onChange("parameters", vals)}}/>
      </div>
    )
  }
  
  zoneType=()=>{
    let pos=Object.assign({}, this.state.position)
    let def=Object.assign({}, this.state.defaults)
    let parm=Object.assign({}, this.state.parameters)
    return(
      <div>
      <Position00 parms={{vals: pos}} onChange={(vals)=>{this.onChange("position", vals)}}/>
      <Defaults00 parms={{vals: def}} onChange={(vals)=>{this.onChange("defaults", vals)}}/>
      <Parameters00 parms={{
        vals: parm,
        sites: this.sites
        
      }} type="zone" onChange={(vals)=>{this.onChange("parameters", vals)}}/>
      </div>
    )
  }
  
  genericType=()=>{
    let pos=Object.assign({}, this.state.position)
    let parm=Object.assign({}, this.state.parameters)
    let dp=Object.assign({}, this.state.datapoint)
//     let col=Object.assign({}, this.state.colors)
    let col=this.state.colors.slice(0)
//     let lab=Object.assign({}, this.state.labels)
    let lab=this.state.labels.slice(0)
    return(
      <div>
      <Position00 parms={{vals: pos}} onChange={(vals)=>{this.onChange("position", vals)}}/>
      <Parameters00 parms={{vals: parm}} type="generic" onChange={(vals)=>{this.onChange("parameters", vals)}}/>
      <Datapoints parms={{vals: dp}} onChange={(vals)=>{this.onChange("datapoint", vals)}}/>
      <Colors00 parms={{vals: {colors: col}}} onChange={(vals)=>{this.onChange("colors", vals)}}/>
      <Labels00 parms={{vals: {labels: lab}}} onChange={(vals)=>{this.onChange("labels", vals)}}/>
      </div>
    )
  }
  
  widgetTypesEdit=()=>{
//     cl(this.state.widgetType)
    switch(this.state.widgetType){
      case "inTemp":
        return this.inTempType()
      case "generic":
        return this.genericType()
      case "timeSeries":
        return this.timeSeriesType()
      case "equipment":
        return this.equipmentType()
      case "link":
        return this.linkType()
      case "site":
        return this.siteType()
      case "zone":
        return this.zoneType()
      case "feed":
        return this.feedType()
      default:
        return this.editNewWidgetType(this.state.widgetType)
    }
  }
  
  widgetEdit=()=>{
//     <EditWidget01 parms={{vals: obj}} onChange={this.onChange}/>
    if(this.state.editMode){
//       cl(this.state)
//       let obj=Object.assign({}, this.state.widget) // shallow copy!
      return(
        <div>
        {this.widgetTypeSelect()}
        {this.widgetTypesEdit()}
        </div>
      )
    }
  }
  
  render(){
//     cl(`render ${this.state.widgetSel}`)
//     cl(this.state.dashboardSel)
//     cl(this.state)
    if(this.state.loaded){
      return(
        <div>
          {this.widgetSelect00()}
          {this.widgetEdit()}
        </div>
      )
    }else{
      return <div>loading...</div>
    }
  }
}

export default EditWidget00 ;

