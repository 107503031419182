import React from 'react';
import UsaSelect00 from '../UsaSelect00'
import {cl} from '../../../components/utils/utils';

const verticalSpace=<div style={{height: 10}}/>

class RepeatYearWeek extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      years: 1,
      month: 2,
      week: 3,
      day: 2,
    }
    this.repeat=this.props.parms.repeat
  }
  
/* Repeat Every 3 years on the 3rd Tuesday of June*/
  
  onChange=(vals)=>{
//     cl(this.props)
    let s=Object.assign(this.state, vals)
    this.repeat={
      period: s.years,
      details: s.day | (s.week << 8) | (s.month << 16)
    }
    this.setState(vals)
    this.props.parms.onChange({repeat: Object.assign({},this.repeat)})
  }
  
  render(){
    let dayOpts={
      0:{t:"Sunday"},
      1:{t:"Monday"},
      2:{t:"Tuesday"},
      3:{t:"Wednesday"},
      4:{t:"Thursday"},
      5:{t:"Friday"},
      6:{t:"Saturday"},
    }
    let ordinalOpts={
      0:{t:"First"},
      1:{t:"Second"},
      2:{t:"Third"},
      3:{t:"Fourth"},
      4:{t:"Fifth or Last"},
    }
    let monthOpts={
      1:{t:"January"},
      2:{t:"February"},
      3:{t:"March"},
      4:{t:"April"},
      5:{t:"May"},
      6:{t:"June"},
      7:{t:"July"},
      8:{t:"August"},
      9:{t:"September"},
      10:{t:"October"},
      11:{t:"November"},
      12:{t:"December"},
    }
    return(
      <>
      <UsaSelect00 parms={{
        title: "Repeat\u00A0Every",
        footer: "Years",
        footerPlural: true,
        type: "number",
        width: 50,
        select: false, // !this.state.editMode,
        valueId: "years",//valueId, // "widgetSel",
        value: this.state.years, //value, // this.state.widgetSel,
        onChange: this.onChange,
      }}/>
      {verticalSpace}
      <UsaSelect00 parms={{
        title: "On",
//         type: "number",
//         width: 50,
        select: true, // !this.state.editMode,
        opts: dayOpts,
        valueId: "day",//valueId, // "widgetSel",
        value: this.state.day, //value, // this.state.widgetSel,
        onChange: this.onChange,
      }}/>
      {verticalSpace}
      <UsaSelect00 parms={{
        title: "Of\u00A0The",
        footer: "Week of",
//         type: "number",
//         width: 50,
        select: true, // !this.state.editMode,
        opts: ordinalOpts,
        valueId: "week",//valueId, // "widgetSel",
        value: this.state.week, //value, // this.state.widgetSel,
        onChange: this.onChange,
      }}/>
      {verticalSpace}
      <UsaSelect00 parms={{
        title: "The\u00A0Month\u00A0Of",
//         footer: "Week of each Month",
//         type: "number",
//         width: 50,
        select: true, // !this.state.editMode,
        opts: monthOpts,
        valueId: "month",//valueId, // "widgetSel",
        value: this.state.month, //value, // this.state.widgetSel,
        onChange: this.onChange,
      }}/>
      </>
    )
  }
}

export default RepeatYearWeek ;

