import React from 'react';
import C18Button00 from './C18Button00'
import C18Anchor00 from './C18Anchor00'
import C18MenuBar00 from './C18MenuBar00'
import {cl} from '../../components/utils/utils';
import history from "../../history"

class C18Breadcrumb00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
//       breadcrumbs:[
//         {t:"Sites", url:"/usa/c18/sites"},
//         {t:"San Diego", url:"/usa/c18/sites/0sna8jVYIh0xw6oF"},
//         {t:"Greenhouse 03", url:"/usa/c18/sites/0sna8jVYIh0xw6oF/zones/Cw07JvgkDXlrvGnQ"},
//         {t:"sensor", url:"/usa/c18/sites/0sna8jVYIh0xw6oF/zones/Cw07JvgkDXlrvGnQ/settings/sensor1800"},
//         {t:"equipment", url:"/usa/c18/sites/0sna8jVYIh0xw6oF/zones/Cw07JvgkDXlrvGnQ/settings/equipment"},
//       ],
    }
//     this.pages={
//       sites:{n:"Sites",u:"/usa/c18/sites",p:null},
//       zones:{n:null,u:null,p:"sites"},
//       zone:(p:"zones")
//     }
  }
  
  bcClick=(e,url)=>{
    if(history.location.pathname !== url) {
      history.push(url)
    }
    e.preventDefault()
  }
  
  backArrowClick=(url)=>{
    // history.goBack()
//     cl(history)
//     cl(url)
    // replaces current entry with prev breadcrumb
    if (url) {
      history.replace(url)
    } else {
      history.goBack()
    }  
  }
  
  showBreadcrumbs=()=>{
    let bc=[]
    let backIdx = this.props.parms.breadcrumbs.length - 2
    let backUrl
    if (backIdx >= 0) backUrl = this.props.parms.breadcrumbs[backIdx].url
//     cl(this.props.parms.breadcrumbs)
    this.props.parms.breadcrumbs.forEach((b,i)=>{
      if(bc.length>0){
        bc.push (<span key={2*i}> > </span>)
      }
      bc.push(
        <C18Anchor00 to="" key={2*i+1} onClick={(e)=>{this.bcClick(e,b.url)}}> {b.t}</C18Anchor00>
      )
    })
    return(
      <div id="breadcrumb-container">
        {backIdx >= 0 &&
        <C18Button00 type="button" className="back-arrow material-icons-outlined" onClick={()=>{this.backArrowClick(backUrl)}}>arrow_back</C18Button00> 
        }
        {bc}
      </div>
    )
    
  }
  
  render(){
    return this.showBreadcrumbs()
//     return(
//       <div id="breadcrumb-container">
//         &larr; <span>Sites</span> > <span>San Diego</span> > <span>Greenhouse 03</span>
//       </div>
//     );
  }
}
      
export default C18Breadcrumb00;
