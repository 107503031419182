import React from 'react';
import * as d3 from 'd3';
import {cl} from '../../components/utils/utils';
//  import { Router, Route, Switch } from "react-router-dom";// , Link
// import {cl, inputField} from '../utils/utils';
// import {globs} from '../../components/utils/utils';
// import IconButton from '@material-ui/core/IconButton';
// import Check from '@material-ui/icons/Check';
// import Clear from '@material-ui/icons/Clear';
// //  import InputAdornment from '@material-ui/core/InputAdornment';
// //  import Input from "@material-ui/core/Input";
// //  import InputLabel from '@material-ui/core/InputLabel';
// //  import Register from './Register';
// //  import Login from './Login';
// import MainBar from '../../components/MainBar';

/* props for Gauge: 
need to define the range from min to max 
the colors to be used, with the start point for each 
the ticks to use, and their location 
size: total height, total width, ring max, ring min 
current value 
in a single config object:
{width: 180,
height: 180,
ringInset: 20,
ringWidth: 30,
min: 0,
minAngle: -120,
max: 10,
maxAngle: 120
ticks: [
[0, 0],// pos, tick
[2, 2],
[4, 4],
[6, 6],
[8, 8],
[10, 10],
],
colors: [
[0, "#FF0000"],
[2, "#FFFF00"],
[4, "#00FF00"],
[6, "#00FFFF"],
[8, "#0000FF"],
[10, "#FF0000"],
],
}
*/
class Gauge extends React.Component{
  constructor(props) {
    super(props);
//     cl(props);
    this.svgDiv=React.createRef();
    this.mounted=false
    this.state={
      value: props.value,
    }
//     cl("setting update")
    props.config.update = this.update
  }
  
  componentDidMount() {
//     cl("did mount")
//     return true
    this.gauge=this.Gauge2(this.svgDiv.current);
    this.gauge.render(this.props.config.value);
//     this.gauge.render(this.props.config.value);
    this.mounted=true
//     cl(this.gauge)
  }
  
  update=(isRender)=>{
//     cl(`gauge update: ${isRender}`)
//     cl(`gauge update ${this.props.config.value}`)
    if(isRender){// probably needs to initialize more stuff
      this.gauge.render(this.props.config.value);
    }else{
      this.gauge.update(this.props.config.value)
    }
//     cl("done")
  }
  
//   shouldComponentUpdate(){
//     cl("shouldComponentUpdate");
//   }
//   
// //   static getDerivedStateFromProps(){
// //     return null
// //   }
//   
//   getSnapshotBeforeUpdate(){
//     cl("shouldComponentUpdate");
//   }
//   
//   componentDidUpdate(){
//     
//   }
  
  Gauge2=(container, configuration)=>{
//     cl("gauge");
//     cl(container);
    //   var container;
    //   var configuration;
    let co = this.props.config;
    var that = {};
//     {
//       colors: co.colors,
//       ticks: co.ticks,
//       size						: co.width,
//       clipWidth					: co.width + 2,
//       clipHeight					: co.height + 2,
//       ringInset					: co.ringInset,
//       ringWidth					: co.ringWidth,
//       
//       pointerWidth				: 0.05*co.width,//10,
//       pointerTailLength			: 0.025*co.width,
//       pointerHeadLengthPercent	: 0.9,
//       
//       minValue					: co.min,
//       maxValue					: co.max,
//       
//       minAngle					: co.minAngle,
//       maxAngle					: co.maxAngle,
//       
//       transitionMs				: 4000,
//       
//       majorTicks					: co.ticks.length - 1,
//       labelFormat					: d3.format('d'),
//       labelInset					: 0.08*co.width,//10,
//       
//       arcColorFn					: d3.interpolateHsl(d3.rgb('#28e2ca'), d3.rgb('#3e6c0a'))
//     };
    
    var makeConfig=()=>{
      let co = this.props.config;
//       cl(co)
      return {
        colors: co.colors,
        ticks: co.ticks,
        size						: co.width,
        clipWidth					: co.width + 2,
        clipHeight					: co.height + 2,
        ringInset					: co.ringInset,
        ringWidth					: co.ringWidth,
        
        pointerWidth				: 0.05*co.width,//10,
        pointerTailLength			: 0.025*co.width,
        pointerHeadLengthPercent	: 0.9,
        
        minValue					: co.min,
        maxValue					: co.max,
        
        minAngle					: co.minAngle,
        maxAngle					: co.maxAngle,
        
        transitionMs				: 4000,
        
        majorTicks					: co.ticks.length - 1,
        labelFormat					: d3.format('d'),
        labelInset					: 0.08*co.width,//10,
        
        arcColorFn					: d3.interpolateHsl(d3.rgb('#28e2ca'), d3.rgb('#3e6c0a'))
      };
    }
    
    var config = makeConfig()
    var range = undefined;
//     var globProps = this.props;
    var r = undefined;
    var pointerHeadLength = undefined;
    var value = 0;
    
    var svg = undefined;
    var arc = undefined;
    var scale = undefined;
//     var ticks = undefined;
    var testTicks = undefined;
    var tickData = undefined;
    var pointer = undefined;
    
    var donut = d3.pie();
    
    var rlc=(node)=>{
      node.removeChild(node.lastChild);
    }
    
    var rc=(node)=>{
      if(!node.firstChild) return;
      while (node.firstChild) {
        rlc(node);
      }  
    }
    
    function deg2rad(deg) {
      return deg * Math.PI / 180;
    }
    
    function newAngle(d) {
      var ratio = scale(d);
      var newAngle = config.minAngle + (ratio * range);
      return newAngle;
    }
    
    var arcSize=(arr)=>{
      let ret=[];
//       cl(config);
      let range = config.maxValue - config.minValue;
//       cl(range);
      for (let i = 1 ; i < arr.length ; i++){
//         ret.push((arr[i][0] - arr[i-1][0])/range);
        ret.push([(arr[i-1][0] - config.minValue)/range, (arr[i][0]-config.minValue)/range]);
        //         cl(size);
//         let a=arr[i];
//         cl(a);
      };
//       cl(ret);
//       ret=[0.25, 0.15, 0.25, 0.2, 0.2];
//       ret=[0.11, 0.11, 0.11, 0.11, 0.12];// this is multiplied by the 0-10 value
//       ret=[
//         [0, 0.2],
//         [0.2, 0.4],
//         [0.4, 0.7],
//         [0.7, 0.8],
//         [0.8, 1],
//       ];
      return ret;
    }
    
    function configure(configuration) {
      var prop = undefined;
//       for ( prop in configuration ) {
//         config[prop] = configuration[prop];
//       }
      
      range = config.maxAngle - config.minAngle;
      r = config.size / 2;
      pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);
      
      // a linear scale that maps domain values to a percent from 0..1
      scale = d3.scaleLinear()
      .range([0,1])
      .domain([config.minValue, config.maxValue]);
      
      //     ticks = scale.ticks(config.majorTicks);
//       cl(testTicks);
//       testTicks=[
//       {tick: 0, size: 0.2, color: "#FF0000"},
//       {tick: 1, size: 0.2, color: "#FFFF00"},
//       {tick: 2, size: 0.2, color: "#00FF00"},
//       {tick: 3, size: 0.2, color: "#00FFFF"},
//       {tick: 4, size: 0.2, color: "#0000FF"},
//       {tick: 5, size: 0.2, color: "#FF0000"},
//       ];
      
      //     tickData = d3.range(config.majorTicks).map(function() {return 1/config.majorTicks;});
      
      arc = d3.arc()
      .innerRadius(r - config.ringWidth - config.ringInset)
      .outerRadius(r - config.ringInset)
      .startAngle(function(d, i) {
        var ratio = d[0];// * i;
        return deg2rad(config.minAngle + (ratio * range));
      })
      .endAngle(function(d, i) {
        var ratio = d[1];// * (i+1);
        return deg2rad(config.minAngle + (ratio * range));
      });
    }
    that.configure = configure;
    
    function centerTranslation() {
      return 'translate('+r +','+ r +')';
    }
    
    function isRendered() {
      return (svg !== undefined);
    }
    that.isRendered = isRendered;
    
    function render(newValue) {
      rc(container)
      config = makeConfig()
      svg = d3.select(container)
      .append('svg:svg')
      .attr('class', 'gauge')
      .attr('width', config.clipWidth)
      .attr('height', config.clipHeight);
      
      var centerTx = centerTranslation();
      
      var arcs = svg.append('g')
      .attr('class', 'arc')
      .attr('transform', centerTx);
      arcs.selectAll('path')
      .data(arcSize(config.colors))
      .enter().append('path')
      .attr('fill', function(d, i) {
        return config.colors[i][1];
        //       return config.arcColorFn(d * i);
      })
      .attr('d', arc);
//       cl(co)
      var lg = svg.append('g')
      .attr('class', 'label')
      .attr('style', `text-anchor: center; font-size: ${co.fontSize}px;`)
      .attr('transform', centerTx);
      lg.selectAll('text')
      .data(config.ticks)
      .enter().append('text')
      .attr('style', 'text-anchor: middle')
      .attr('transform', function(d) {
        var ratio = scale(d);
        var newAngle = config.minAngle + (ratio * range);
        return 'rotate(' +newAngle +') translate(0,' +(config.labelInset - r) +')';
      })
      .text(config.labelFormat);
      
      var lineData = [ [config.pointerWidth / 2, 0], 
      [0, -pointerHeadLength],
      [-(config.pointerWidth / 2), 0],
      [0, config.pointerTailLength],
      [config.pointerWidth / 2, 0] ];
      var pointerLine = d3.line().curve(d3.curveLinear)
      var pg = svg.append('g').data([lineData])
      .attr('class', 'pointer')
      .attr('transform', centerTx);
      
      pointer = pg.append('path')
      .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/ )
      .attr('transform', 'rotate(' +config.minAngle +')');
      
      update(newValue === undefined ? 0 : newValue);
    }
    that.render = render;
    
    function update(newValue, newConfiguration) {
//       if ( newConfiguration  !== undefined) {
//         configure(newConfiguration);
//       }
//       cl("upd")
      var ratio = scale(newValue);
      that.curValue=newValue
      var newAngle = config.minAngle + (ratio * range);
      pointer.transition()
      .duration(config.transitionMs)
      .ease(d3.easeElastic)
      .attr('transform', 'rotate(' +newAngle +')');
    }
    that.update = update;
    
    configure(configuration);
    
    return that;
  }
  
  render(){
//     cl("render");
//     cl(this.props)
    if(this.gauge && (this.gauge.curValue !== this.props.config.value)){
      this.gauge.update(this.props.config.value)
    }
    if(this.mounted){
//       this.gauge.render(this.props.config.value);
      this.componentDidMount()
    }
//     cl(this.gauge)
    return(
      <div ref={this.svgDiv} 
      style={{
        width: this.props.config.width, 
        height: this.props.config.height, 
        margin: "auto",
//         color: "white",
        backgroundColor: this.props.config.bgColor}} />
    );
  }
}

export default Gauge ;
