import React from 'react';
import VentPos from './VentPos'
import CropProtection from './CropProtection'
import StructuralProtection from './StructuralProtection'
import CravoSetpoint0 from './CravoSetpoint0'
import SaveCancel from './SaveCancel'
import {setParamIds, getParam, putParam, getVentPos, saveVentPos, getCropProtection, saveCropProtection, 
    getStructuralProtection, saveStructuralProtection, getWindBreak, saveWindBreak, getOpenField, saveOpenField, 
    getShadeHouse, saveShadeHouse, getHumidityIncrease, saveHumidityIncrease} from './CravoData'
import {p, pi, pInd} from '../../components/utils/paramIds';
import {cl, globs, leadZeros, getTime} from '../../components/utils/utils';
import {sendArray, checkSendArray,dbVals} from '../../components/utils/http';

const cream="#f5f5dc"
const aqua="#00ffff"
const yellow="#fffacd"
const green="#98fb98"

class RetractableRoof extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    let parts=props.zuci.split("-")
    let zone=+parts[0]
    let chan=+parts[2]
    this.props.getValue(props.ind, {type: "saveCancel"})
    this.state={
      zone: zone,
      chan: chan,
      ventPos:{
        pos: [0,0,0,5,0,0,0],
        activePos: [0,0,10,0,0,0,0],
      },
      cropProtection:{
        windSpeed: [1,2,3],
        position: [4,5,6],
        delayOut: [7,8,9],
      },
      structuralProtection:{
        wind: 0,
        position: 9,
        delay: 0,
        digital: 0,
        open: 0,
        delay2: 0,
        max: 0,
      },
//       cravoWB:{
//         upTime: 33,
//         downTime: 23,
//         deadband: 0,
//         pos: [9,8,7,6,5,4,3,2],
//         thresh: [2,3,4,5,6,7,8,9],
//       },
//       cravoOF:{
//         upTime: 33,
//         downTime: 23,
//         deadband: 0,
//         pos: [9,8,7,6,5,4,3,2],
//         thresh: [2,3,4,5,6,7,8,9],
//       },
//       cravoSH:{
//         upTime: 33,
//         downTime: 23,
//         deadband: 0,
//         pos: [9,8,7,6,5,4,3,2],
//         thresh: [2,3,4,5,6,7,8,9],
//       },
//       cravoHI:{
//         upTime: 33,
//         downTime: 23,
//         deadband: 0,
//         pos: [9,8,7,6,5,4,3,2],
//         thresh: [2,3,4,5,6,7,8,9],
//       },
    }
    setParamIds(this)
    this.getCravoData()
//     cl(this.state)
  }
  
  getCravoData=()=>{
    let z=this.state.zone
    let c=this.state.chan
//     cl(dbVals.z[z][c][709])
    this.base=pInd[1800].config_channels_configuration[0]
    getVentPos(this,z,c)
    getCropProtection(this,z,c)
    getStructuralProtection(this,z,c)
    getWindBreak(this,z,c)
    getOpenField(this,z,c)
    getShadeHouse(this,z,c)
    getHumidityIncrease(this,z,c)
//     cl(this.state)
  }
  
  saveCravoData=()=>{
//     cl("save")
    let z=this.state.zone
    let c=this.state.chan
//     cl(dbVals.z[z][c][709])
//     cl(c)
    let t=Math.floor(getTime())
    this.base=pInd[1800].config_channels_configuration[0]
    let parms=[]
    saveVentPos(this,z,c,t,parms)
    saveCropProtection(this,z,c,t,parms)
    saveStructuralProtection(this,z,c,t,parms)
    saveWindBreak(this,z,c,t,parms)
    saveOpenField(this,z,c,t,parms)
    saveShadeHouse(this,z,c,t,parms)
    saveHumidityIncrease(this,z,c,t,parms)
//     cl(dbVals.z[z][c][709])
    cl(parms)
    checkSendArray(parms,this.props.current.virtual).then(r=>{globs.events.publish("saveOK",true)})
//     sendArray(parms)
//     cl(this.state)
//     cl(parms)
  }
  
/*
 */  

  onChange=(c,type, vals, val2)=>{
    cl(this.props.parms)
//     cl([c,type, vals, val2])
//     cl(type)
//     cl(this.state)
//     let c=this.state.chan
    var obj
    globs.events.publish("savePageEnable",true)
    switch(type){
      case "ventPos":
        let vp=Object.assign(this.state.ventPos, vals)
//         cl(vp)
        this.setState({ventPos: vp})
        break
      case "cropProtection":
        this.setState({cropProtection: Object.assign(this.state.cropProtection, vals)})
        break
      case "structuralProtection":
        this.setState({structuralProtection: Object.assign(this.state.structuralProtection, vals)})
        break
      case "windBreak":
        obj=Object.assign({},this.state.windBreak)
        obj[c]=Object.assign(obj[c],vals)
        this.setState({windBreak:obj})
        break
      case "openField":
        obj=Object.assign({},this.state.openField)
        obj[c]=Object.assign(obj[c],vals)
        this.setState({openField:obj})
//         this.setState({openField: Object.assign(this.state.openField, vals)})
        break
      case "shadeHouse":
        obj=Object.assign({},this.state.shadeHouse)
        obj[c]=Object.assign(obj[c],vals)
        this.setState({shadeHouse:obj})
//         this.setState({shadeHouse: Object.assign(this.state.shadeHouse, vals)})
        break
      case "humidityIncrease":
        obj=Object.assign({},this.state.humidityIncrease)
        obj[c]=Object.assign(obj[c],vals)
        this.setState({humidityIncrease:obj})
//         this.setState({humidityIncrease: Object.assign(this.state.humidityIncrease, vals)})
        break
      case "saveCancel":
        switch(val2.value){
          case "save":
            this.saveCravoData()
            this.props.onChange(this.props.ind, val2)
            break
          default:
            break
        }
        break
      default:
        cl(type)
        cl(vals)
        cl(val2)
        break
    }
  }
  
  getValue=(ind, val)=>{
  }
  
  showSaveCancel=()=>{
    return(
      <SaveCancel 
        onChange={(ind,val)=>this.onChange(0,"saveCancel",ind,val)}
        getValue={this.getValue}
        ind="0"
      />
    )
  }
  
  render(){
    let c=this.state.chan
    return(
      <div>
      <VentPos 
        values={this.state.ventPos}
        onChange={v=>this.onChange(0,"ventPos",v)}
      />
      <CropProtection
        values={this.state.cropProtection}
        onChange={v=>this.onChange(0,"cropProtection",v)}
      />
      <StructuralProtection
        values={this.state.structuralProtection}
        onChange={v=>this.onChange(0,"structuralProtection",v)}
      />
      <CravoSetpoint0
      parms={{trans: ["GH","WB","OF"], color: cream, chan: c}}
        values={this.state.windBreak}
        onChange={v=>this.onChange(c,"windBreak",v)}
      />
      <CravoSetpoint0
      parms={{trans: ["WB","OF","SH"], color: aqua, chan: c}}
        values={this.state.openField}
        onChange={v=>this.onChange(c,"openField",v)}
      />
      <CravoSetpoint0
      parms={{trans: ["OF","SH","HI"], color: yellow, chan: c}}
        values={this.state.shadeHouse}
        onChange={v=>this.onChange(c,"shadeHouse",v)}
      />
      <CravoSetpoint0
      parms={{trans: ["SH","HI","GH"], color: green, chan: c}}
        values={this.state.humidityIncrease}
        onChange={v=>this.onChange(c,"humidityIncrease",v)}
      />
      {this.showSaveCancel()}
      </div>
    )
  }
}

export default RetractableRoof ;
