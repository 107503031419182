import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import TextField from '@material-ui/core/TextField';
import {cl} from '../../components/utils/utils';

class UsaCheckbox00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      name: "Gene Knight",
      value: false,
    }
  }
  
  handleChange=(e)=>{
//     cl(e.currentTarget.checked)
    let val={}
    val[this.props.parms.valueId]=e.currentTarget.checked
//     cl(val)
    this.props.parms.onChange(val)
//     let ret={value: e.currentTarget.checked}
//     this.setState(ret)
    
  }
      
  render(){
    return(
      <div style={{paddingLeft: 10, paddingTop: 5, display: (this.props.parms.inline) ? "inline" : "block"}}>
      <FormControlLabel
        style={{margin: 0}}
        control={
          <Checkbox
            checked={this.props.parms.value}
            onChange={this.handleChange}
            style={{padding: 0}}
            name="checkBox"
            color="primary"
          />
        }
        label={this.props.parms.title}
      />      
      </div>
    )
  }
}

export default UsaCheckbox00 ;

