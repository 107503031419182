import React from 'react';
import C18Anchor00 from './C18Anchor00'
import C18Input00 from './C18Input00'
import {wsTrans} from '../utils/utils'
import {loadUsersInfo} from './C18utils'
import {cl,globs,constant,getRandomString,dateToDisplayDate} from '../../components/utils/utils';
import history from "../../history"

class C18CropPhases00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      loaded:true,
      recipeName:"New Crop Recipe",
      description:"",
      weekCnt:10,
      daysToHarvest:140,
      mouseDown:false,
      selecting:false,
      selBegin:-1,
      selEnd:-1,
      phases:[],
//       phases:[
//         {d:0,v:"c1",t:"Clone One"},
//         {d:5,v:"c2",t:"Clone Two or a longer name for now"},
//         {d:7,v:"v1",t:"Veg One"},// 1wk
//         {d:21,v:"v2",t:"Veg Two"},
//         {d:35,v:"f1",t:"Flower One"},// 5wk
//         {d:63,v:"f2",t:"Flower Two"},
//         {d:91,v:"f3",t:"Flower Three"},
//         {d:112,v:"h0",t:"Harvest"},// 16wk
//       ]
    }
    this.setBreadCrumbs()
    this.loadInfo()
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.subscribe_cropPhase=globs.events.subscribe("cropPhase",this.newCropPhase)
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveCropRecipe)
//     this.doLoadMessages=setInterval(this.doLoadMessages,10000)
  }
  
  componentWillUnmount=()=>{
//     cl("will unmount")
    this.subscribe_cropPhase.remove()
    this.subscribe_savePageEvent.remove()
  }
  
  saveCropRecipe=async(cmd)=>{
    if(cmd=="save"){
      let st=this.state
//       cl(st)
      globs.events.publish("saveOK",true)
      let recipe={
        cropRecipeId:st.cropRecipeId||getRandomString(16),
        name:st.recipeName,
        description:st.description,
        daysToHarvest:st.daysToHarvest,

      }
      await wsTrans("usa", {cmd: "cRest", uri: "/s/cropRecipes", method: "update",
        sessionId: globs.userData.session.sessionId, body: recipe})
      let phases=[]
      st.phases.forEach(ph=>{
        if(ph.v!="h0"){
          phases.push(
            {
              cropPhaseId:ph.v,
              startDay:ph.d
            }
          )
        }
      })
//       cl(phases)
      await wsTrans("usa", {cmd: "cRest", uri: "/s/cropPhases", method: "update",
        sessionId: globs.userData.session.sessionId, body: {phases:phases}})
    }
  }
  
  setBreadCrumbs=()=>{
//     cl(this.props.parms)
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Messages", url:`/usa/c18/messaging/messageList`},
            ]},
        },
      )
    }
  }
  
  loadInfo=async()=>{
    let pa=this.props.parms
//     cl(pa)
    if(pa.adminInfo!="phases"){
      let res= await wsTrans("usa", {cmd: "cRest", uri: "/s/cropRecipes", // get recipe
        sessionId: globs.userData.session.sessionId, 
        method: "retrieve", body: {cropRecipeId:pa.adminInfo}})
//       cl(res.data)
      let recipe=res.data[0]
//       cl(recipe)
      res= await wsTrans("usa", {cmd: "cRest", uri: "/s/cropPhases", // get phases
        sessionId: globs.userData.session.sessionId, 
        method: "retrieve", body: {cropRecipeId:pa.adminInfo}})
//       cl(res.data)
      let allPhases=res.data
      res.data.push({
        startDay:recipe.daysToHarvest,
        cropPhaseId:"h0",
        name:"Harvest"
      })
      let phases=res.data.filter(ph=>{return ph.startDay>=0}).map(ph=>{
//         cl(ph.startDay)
        return{
          d:+ph.startDay,
          v:ph.cropPhaseId,
          t:ph.name,
        }
      })
//       cl(phases)
      phases.sort((a,b)=>{
        if(a.d>b.d){return 1}
        if(a.d<b.d){return -1}
        return 0
      })
//       cl(phases)
      this.setState({recipeName:recipe.name,description:recipe.description,
        cropRecipeId:recipe.cropRecipeId,phases:phases,allPhases:allPhases,
        daysToHarvest:recipe.daysToHarvest,
      })
    }
//     await loadUsersInfo()
//     this.userArr={}
//     globs.usersInfo.info.forEach(ui=>{this.userArr[ui.userId]=ui})
//     let messages=await this.loadMessages()
//     this.setState({loaded:true,messages:messages})
  }
  
  startToDays=(phases,dir)=>{
//     cl(phases)
    let ret=[]
    let st=this.state
    if(dir=="toDays"){
      for(let i=1;i<phases.length;i++){
        for(let j=phases[i-1].d;j<phases[i].d;j++){
          ret.push(phases[i-1].v)
        }
      }
      ret.push("h0")
//       cl(ret)
    }else{
      let phaseLU={}
      let phasesUsed=[]
//       st.phases.forEach(ph=>{phaseLU[ph.v]=ph})
      st.allPhases.forEach(ph=>{phaseLU[ph.cropPhaseId]=ph})
//       cl(phaseLU)
      let curPhase=""
      phases.forEach((ph,i)=>{
//         cl(ph)
        if((ph!=curPhase)&&(!phasesUsed.includes(ph))){
          phasesUsed.push(ph)
          ret.push({
            d:i,
            v:ph,
            t:phaseLU[ph]?.name||0
          })
          curPhase=ph
        }
      })
//       ret.push({d:phases.length,v:"h0",t:"Harvest"})
//       cl(ret)
    }
    return ret
  }
  
  newCropPhase=(phase)=>{
    let st=this.state
    let phases=this.startToDays(this.state.phases,"toDays")// 1 entry / day
    for(let i=st.selBegin;i<=st.selEnd;i++){
      phases[i]=phase
    }
    let phases2=this.startToDays(phases,"toStarts")// 1 entry / phase
    globs.events.publish("savePageEnable",true)
    this.setState({phases:phases2})
  }
  
  onChange=(type,vals)=>{
    cl(type,vals)
    switch(type){
      case "upd":
        globs.events.publish("savePageEnable",true)
        this.setState(vals)
        break
    }
  }
  
  getWeekCount=()=>{
    let st=this.state
    return Math.floor((+st.daysToHarvest+6)/7)
//     let max=st.phases.slice(-1)[0]?.d||0
// //     cl(max)
//     return Math.floor((max+6)/7)
  }
  
  showDay=(day)=>{
    let st=this.state
    let pa=this.props.parms
//     cl(pa)
    var stage="none",stageInd=0
    for(let i=0;i<st.phases.length;i++){
//       cl(day,st.phases[i].d)
      if(day>=st.phases[i].d){
        stage=st.phases[i].t
        stageInd=i
      }
    }
    let beg=st.selBegin
    let end=st.selEnd
    if(beg>end){beg=end;end=st.selBegin}
    let bgColor=(stageInd%2==1)?"#CCEEEE":"#CCEECC"
    if(st.selecting&&(day>=beg)&&(day<=end)){bgColor="#CCCCCC"}
//     cl(stageInd)
//     cl(st.phases[stageInd])
    let phase=st.phases[stageInd]
    let cmd={
      cmd:"cropPhase",
      phases:st.allPhases?.slice(0,st.allPhases.length-1),
      selecting:st.selecting,
      cropRecipe:pa.adminInfo,
      cropPhase:phase?.v,
      phaseName:phase?.t,
//       beg:st.selBegin,
//       end:st.selEnd,
    }
//         onMouseOut={e=>this.mot(e,day)}
    return(
      <td key={day}
      >
      <div id={JSON.stringify(cmd)}
        style={{
          width:100,
           height:100,
           borderRadius:10,
           backgroundColor:bgColor,
           margin:10,
           padding:5,
        }}
        onMouseDown={e=>this.md(e,day)}
        onMouseOver={e=>this.mov(e,day)} 
      >
        <div style={{textAlign:"right"}}>{`${1+day}`}</div>
        <div style={{textAlign:"center"}}>{stage}</div>
        
      </div>
      </td>
    )
  }
  
  showWeekLine=(week)=>{
    let days=[0,1,2,3,4,5,6]
    return(
      <tr key={week}>
      <td>{`Week ${week+1}`}</td>
      {days.map(d=>{
        return this.showDay(7*week+d)
      })}
      </tr>
    )
  }
  
  showWeeks=()=>{
    let st=this.state
    let weeks=[]
    for(let i=0;i<1+this.getWeekCount();i++){
      weeks.push(
        this.showWeekLine(i)
      )
    }
    return(
      <table><tbody>
      {weeks}
      </tbody></table>
    )
    
  }
  
  md=(e,day)=>{
    e.preventDefault()
    if(e.button==0){
      this.setState({mouseDown:true,selecting:true,selBegin:day,selEnd:day})
    }
  }
  
  mu=(e)=>{
    this.setState({mouseDown:false})
  }
  
//   mm=(e)=>{
// //     cl(e)
// //     e.preventDefault()
//   }
  
  mov=(e,day)=>{
    let st=this.state
    if(st.mouseDown){
      this.setState({selEnd:day})
    }
  }
  
//   mot=(e,day)=>{
// //     cl(day)
//   }
  
  render(){
    let st=this.state
    if(st.loaded){
      let name=st.recipeName
//           onMouseMove={this.mm}
      return(
        <div
          onMouseUp={this.mu}
        >
          <div className="clearfloat"/><br/>
          <label htmlFor="recipe-name">Crop Recipe Name</label>
          <C18Input00 type="text" id="recipe-name" 
            value={name||""}
            onChange={e=>this.onChange("upd",{recipeName:e.currentTarget.value})}
          />
          <div className="clearfloat"/><br/>
          <label htmlFor="cropRecipe-description">Description</label>
          <textarea id="cropRecipe-description"
          value={st.description}
          onChange={e=>this.onChange("upd",{description:e.currentTarget.value})}
          />
          <div className="clearfloat"/><br/>
          <label htmlFor="cropRecipe-daysToHarvest">Days to Harvest</label>
          <C18Input00 type="number" id="days-to-harvest" 
            value={st.daysToHarvest}
            onChange={e=>this.onChange("upd",{daysToHarvest:e.currentTarget.value})}
          />
          <div className="clearfloat"/><br/>
          <h3>Crop Phases:</h3>
          {this.showWeeks()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18CropPhases00
