 
import React from 'react';
import {cl, globs} from '../../components/utils/utils';
import {colorIsDark,splitStringInHalf} from '../utils/utils';
import {getLinkUrl} from '../../usa/utils/utils'
// import Gauge2 from './Gauge';
// import {init, dbVals} from '../../components/utils/http'
// import InTemp from './InTemp.js';
// import {getParmValue, eqTypes, getChannelType} from '../utils/utils'
import history from '../../history'

class Link00 extends React.Component{
/* this gets the size in parms w, h. assume a 5px padding*/  
  constructor(props) {
    super(props);
//     cl(props)
    props.notify({id: "drag", func: this.onDrag})
  }
  
  componentWillUnmount=()=>{
    this.props.notify({id: "drag", func: this.onDrag, unMount: true})
  }
  
  
  onDrag=(o)=>{// o has the updated info
//     cl("od")
//     cl(o)
    if("dragStart" in o){
      this.setState(o)
    }else{
      if(this.state.dragStart.i==this.props.parms.i){
//         cl(o)
        this.setState(o)
      }
    }
  }
  
  cm=(e)=>{
    this.props.parms.cm({e: e, i: this.props.parms.i})
  }
  
  md=(e)=>{
    this.props.parms.md({e: e, i: this.props.parms.i})
  }
  
  addZci=(url, zci)=>{
    let end=url.lastIndexOf("/")
    let suff=url.substr(end);
    let p=suff.split("-")
    if(p.length==4){return url}
    return `${url}/${zci[0]}-0-${zci[1]}-${zci[2]}`
  }
  
//   getLinkUrl=(parms)=>{
//     let url=parms.link
//     let end=url.lastIndexOf("/")
//     let suff=url.substr(end);
//     let p=suff.split("-")
//     if(p.length==4){return url}
//     let zci=parms.zci
//     return `${url}/${zci[0]}-0-${zci[1]}-${zci[2]}`
//   }
  
  onClick=()=>{
//     cl(this.props)
    if(this.props.parms.e) {return}// edit mode
    let url=getLinkUrl(this.props.parms.wdg.parms)
//     cl(url)
    if(url){history.push(url)}
  }
  
  render() {
//     cl(this.props)
    let p = this.props.parms;
    let p2=p.wdg.parms
    let dm=5
    let color=p2.bgColor
    let x=p.x+dm
    let y=p.y+dm
    if(this?.state?.dragStart?.i==this.props.parms.i){
      x+=this.state.drag.x-this.state.dragStart.x
      y+=this.state.drag.y-this.state.dragStart.y
    }
    let titleLen=p2.title.length
    let textColor=colorIsDark(p2.bgColor)?"white":"black"
    let fontSize=1.5*p.w/titleLen
    if(fontSize>0.5*p.h){fontSize=0.5*p.h}
    if(fontSize<0.26*p.h){fontSize=0.26*p.h}
//     splitStringInHalf(p2.title)
      
//       cl(p)
//     cl(x)
//     return null
    return(
        <div style={{position: "absolute", left: x, top: y, width: p.w-2*dm, height: p.h-2*dm, textAlign: "center",
          paddingTop: 0, borderRadius: 10, backgroundColor: color, cursor: "pointer"}} onClick={this.onClick}
          onContextMenu={this.cm} onMouseDown={this.md}>
          <div style={{textAlign: "center", fontSize: fontSize, padding: .02*p.w, color: textColor}}>{p2.title}</div>
        </div>
      );
  }
}

export default Link00;
