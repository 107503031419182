import React from 'react';
import {cl, globs} from '../../components/utils/utils';
import Gauge2 from './Gauge';
// import Graph from './Graph';
import {getParmInfo, stages} from '../utils/utils'
import history from '../../history'
import {colorIsDark} from '../utils/utils';

class InTemp extends React.Component{
  constructor(props) {
    super(props);
//     cl(stages);
//     cl(props.parms.wdg.parms.dataPoints)
    cl(props)
    this.state={
      loaded: false,
    }
    let dp=props.parms.wdg.parms.dataPoints
    this.title=props.parms.wdg.parms.title
    this.stage=stages[dp.Temp_Stage]
    this.color="#0000FF";
//     this.gauge={
//       value: 3,
//       width: 100,// sets size
//       height: 70,
//       bgColor: '#C0FFFF',
//       ringInset: 20,
//       ringWidth: 80,
//       min: 2,
//       minAngle: -120,
//       max: 12,
//       maxAngle: 120,
    //       ticks: [2, 4, 6, 8, 10, 12],// these must be values, in scale min-max°F
//       colors: [
//       [2, "#FF0000"],
//       [4, "#FFFF00"],
//       [6, "#00FF00"],
//       [8, "#00FFFF"],
//       [10, "#0000FF"],
//       [12, "#FF0000"],
//       ],
//     }
    props.notify({id: "drag", func: this.onDrag})
    props.parms.wdg.update=this.update
  }
  
  componentWillUnmount=()=>{
    this.props.notify({id: "drag", func: this.onDrag, unMount: true})
  }
  
  lss=null
  
  lazySetState=(st)=>{
    if(!this.lss){
      this.lss=setTimeout(x=>{
        this.setState(st)
//         cl("set State")
        this.lss=null
      }, 10)
    }
    
  }
  
  update=(wdg, paramId, isRender)=>{
//     cl(this.state)
//     cl(wdg)
//     cl(wdg.parms.dataPoints)
//     cl(wdg.parms.dataPoints.In_Temp)
    this.lazySetState({inTemp: wdg.parms.dataPoints.In_Temp})
  }
  
  setColors=(p)=>{
//     cl(p);
    p.colors=["#0000FF", "#0040FF", "#0080FF", "#00C0FF", "#00FFFF", "#00FFC0", "#00FF80", "#00FF40", "#00FF00", "#40FF00", 
    "#80FF00", "#C0FF00", "#FFFF00", "#FFC000", "#FF8000", "#FF4000", "#FF0000"];
    let dp=p.dataPoints;
    cl(dp)
//     cl(dp)
    let hsp=+dp.Heat_Setpoint
    let csp=+dp.Cool_Setpoint
    let minAlarm=hsp - dp.Low_Alarm_Below_Heat_Setpoint
    let maxAlarm=csp + +dp.High_Alarm_Above_Cool_Setpoint
//     cl(dp.High_Alarm_Above_Cool_Setpoint)
    let minTemp = 10 * Math.floor((minAlarm - 5)/10)
    let maxTemp = 10 * Math.ceil((maxAlarm + 5)/10)
    let hs=+(dp.Heat_Stages||3)
    let cs=+(dp.Cool_Stages || 6)
    let sw=+dp.Stage_Width
    let colors=[];
//     cl(p.colors);
    let cb=hs+2
    colors[cb-hs-2]=[minTemp, p.colors[0]]
    colors[cb-hs-1]=[minAlarm, p.colors[1]]
    let ticks=[minTemp, minAlarm, hsp, csp, maxAlarm, maxTemp]
    for (let i = 0 - hs ; i <= 0 ; i++){
//       cl(i);
      let temp=hsp+i*sw
      colors[cb+i]=[temp, p.colors[8+i]]
//       ticks.push(temp);
    }
    for (let i = 0 ; i <= cs ; i++){
      let temp=csp+i*sw
      colors[cb+i+1]=[temp, p.colors[9+i]]
//       ticks.push(temp);
    }
    colors[cb+cs+2]=[maxAlarm, p.colors[16]]
    colors[cb+cs+3]=[maxTemp, p.colors[16]]// last color not used
//     cl(colors)
//     ticks.push(maxAlarm)
//     ticks.push(maxTemp)
//     cl(colors);
//     cl(ticks)
    let val = Math.round(dp.In_Temp * 10)/10
    let size=this.props.parms.w
    this.titlePadding= .02*size
    this.titleFontSize=.12*size
    this.gauge={
      value: val,
      width: 0.8*size-10,//p.w-10,// sets size
      height: 0.6*size,
      fontSize: 0.075*size,//0.6*size,//12,
      bgColor: p.bgColor,
      ringInset: 0.08*size, //15,
      ringWidth: 0.15*size, //30,
      min: minTemp,
      minAngle: -120,
      max: maxTemp,
      maxAngle: 120,
      ticks: ticks,
      colors: colors,
    }
//     cl(this.gauge);
//     cl(minTemp, maxTemp)
    
  }
  
  onClick=()=>{
    if(this.props.parms.e) {return}// edit mode
    let url=this.props.parms.wdg.parms.link
    history.push(url)
  }
  
  onDrag=(o)=>{// o has the updated info
//     cl(o)
    if("dragStart" in o){
      this.setState(o)
//       cl("set")
    }else{
      if(this.state.dragStart.i==this.props.parms.i){
        this.setState(o)
      }
    }
  }
  
  cm=(e)=>{
    this.props.parms.cm({e: e, i: this.props.parms.i})
  }
  
  md=(e)=>{
    this.props.parms.md({e: e, i: this.props.parms.i})
  }
  
  render() {
    let p = this.props.parms;
//     cl(p)
    let dp=p.wdg.parms.dataPoints
    if(!dp.In_Temp) return(
      <div>No Data</div>
    )
    //     cl(dp)
//     let p2 = p.wdg.parms;
    this.setColors(p.wdg.parms);
//     this.gauge.width=p.w
//     this.gauge.height=p.h
//     let dp = p2.dataPoints;
//     cl(d);
    let dm=5
    let x=p.x+dm
    let y=p.y+dm
    let textColor=colorIsDark(p.wdg.color)?"white":"black"
//     cl(textColor)
    if(this?.state?.dragStart?.i==this.props.parms.i){
      x+=this.state.drag.x-this.state.dragStart.x
      y+=this.state.drag.y-this.state.dragStart.y
    }
//     cl(this?.state?.dragStart)
//     cl([x,y])
    return(
      <div style={{position: "absolute", left: x, top: y, width: p.w-2*dm, height: p.h-2*dm, 
        borderRadius: 10, backgroundColor: p.wdg.color, color: textColor}} onContextMenu={this.cm} onMouseDown={this.md}
        onClick={this.onClick}>
        <div style={{textAlign: "center", fontSize: this.titleFontSize, padding: this.titlePadding}}>{this.title}</div>
        <Gauge2 config={this.gauge}/>
        <div style={{textAlign: "center", fontSize: this.titleFontSize, marginTop: 0-(1*this.titleFontSize), 
          color: textColor}}>{Math.round(dp.In_Temp * 10)/10}

        <span style={{ fontSize: 0.6*this.titleFontSize, verticalAlign: 5}}>&deg;F</span></div>
        <div style={{textAlign: "center", fontSize: 0.5*this.titleFontSize, padding: 0, color: textColor}}>{this.stage}</div>
        </div>
    );
  }
}

export default InTemp;
