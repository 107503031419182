import React from 'react';
import {Link} from 'react-router-dom'
import {cl} from '../../components/utils/utils';

class C18Anchor00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
    }
  }
  
  render(){
    return React.createElement(Link,this.props)
  }
}
      
export default C18Anchor00;
