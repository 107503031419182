import React from 'react';
import CravoInput from './CravoInput'
import {cl, globs, leadZeros} from '../../components/utils/utils';

const blue="#55b4d2"
const white="#FFFFFF"

class VentPos extends React.Component{
  constructor(props) {
    super(props);
//     this.props.getValue(props.ind, {type: "scalar"})
    this.state=Object.assign({},this.props.values)
//     this.state={
//       pos: props.values.pos,
//       activePos: props.values.activePos,
// //       pos: [0,0,0,0,0,0,0,],
// //       activePos: [0,0,0,0,0,0,0,],
//     }
  }
  
  onChange=(valueId, ind, e)=>{
    let val=e.currentTarget.value
    let vals={}
    let arr=this.state[valueId].slice(0)
    arr[ind]=val
    vals[valueId]=arr
//     cl(vals)
    this.props.onChange(vals)
    this.setState(vals)
  }
  
  makeTable=()=>{
    let colors=["#4ce743", "#42dc4e", "#3ad25f", "#33c774", "#2ebb91", "#29a8ac", "#2274a4", ]
    var row, rows
    rows=[]
    row=<tr key={0}><td></td><td>Normal</td><td>Cool 1</td><td>Cool 2</td><td>Cool 3</td><td>Cool 4</td><td>Cool 5</td><td>Cool 6</td></tr>
    rows.push(row)
    row=(
      <tr key={1}><td>What % should vent be open for each stage?</td>
      {this.state.pos.map((o,i)=>{
        return <td key={i}>
          <CravoInput
            label={"%"}
            color={colors[i]}
            value={this.state.pos[i]}
            valueId={"pos"}
            ind={i}
            onChange={(valueId, ind, e)=>{this.onChange(valueId, ind, e)}}
          />
        </td>
      })}</tr>
    )
    rows.push(row)
    row=(
      <tr key={2}><td>Vent position when in active cooling mode</td>
      {this.state.pos.map((o,i)=>{
        return <td key={i}>
          <CravoInput
            label={"%"}
            color={colors[i]}
            value={this.state.activePos[i]}
            valueId={"activePos"}
            ind={i}
            onChange={(valueId, ind, e)=>{this.onChange(valueId, ind, e)}}
          />
        </td>
      })}</tr>
    )
    rows.push(row)
    
    return(
      <table><tbody>
      {rows}
      </tbody></table>
    )
  }
  
  render(){
    return(
      <div className="vent-position-wrapper">
      {this.makeTable()}
      </div>
    )
  }
}

export default VentPos ;
