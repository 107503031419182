import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import {cl,globs} from '../../components/utils/utils';

class C18MuiInputLabel extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
    }
  }
  
  render(){
    if(globs.noMui){
      return(
        <label htmlFor={this.props.htmlFor}>
          {this.props.children}
        </label>
      )
    }else{
      return React.createElement(InputLabel,this.props)
    }
  }
}
      
export default C18MuiInputLabel;
 
