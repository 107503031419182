import React from 'react';
import {cl} from '../../components/utils/utils';

class C18CustomSelect01 extends React.Component{
  constructor(props) {
    super(props);
  }
  
  render(){
    let parms=this.props.parms
    let id=this.props.parms.id||""
    return(
      <div className="custom-select">
        {!parms.hideTitle&&
          <label htmlFor={id}>{parms.title}</label>
        }
        <select id={id}
          value={parms.value}
          onChange={parms.onChange}>
        {
          parms.options.map((o,i)=>{
          return(
              <option key={i} value={o.v}>{o.t}</option>
          )
          })
        }
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
    )
  }
}
      
export default C18CustomSelect01;
