import React from 'react';
import UsaSelect00 from '../UsaSelect00'
import {cl} from '../../../components/utils/utils';

class RepeatDays extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      days: 1,
    }
    this.repeat=this.props.parms.repeat
  }
  
  onChange=(vals)=>{
//     cl(this.props)
    let s=Object.assign(this.state, vals)
    this.repeat={
      period: s.days,
//       details: arrToFlags(s.hours),
    }
    this.setState(vals)
    this.props.parms.onChange({repeat: Object.assign({},this.repeat)})
  }
  
  render(){
    return(
      <UsaSelect00 parms={{
        title: "Repeat\u00A0Every",
        footer: "Days",
        footerPlural: true,
        type: "number",
        min: 1,
        width: 50,
        select: false, // !this.state.editMode,
        valueId: "days",//valueId, // "widgetSel",
        value: this.state.days, //value, // this.state.widgetSel,
        onChange: this.onChange,
      }}/>
    )
  }
}

export default RepeatDays ;

