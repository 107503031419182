import React from 'react';
import UsaSelect00 from './UsaSelect00'
import UsaIcon from './UsaIcon';
import IconButton from '@material-ui/core/IconButton';
import Camera from '@material-ui/icons/Camera';
import {cl, globs, constant, getRandomString} from '../../components/utils/utils';// ,globs,az,getTime, getRandomString
import {wsTrans, checkLoggedIn,doGetPostBasic} from '../utils/utils'
import history from "../../history"

class ManageSites extends React.Component{
  constructor(props) {
    super(props);
    document.title="Manage Sites";
//     cl(globs.userData.session)
    this.state={
      site: globs.userData.session.siteId,
      name: "",
      editSite: "",
      editName: "",
      siteOpts:[],
      showAdd: false,
    }
    this.getSites()
//     cl(this.state)
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Sites", url:`/usa/c18/admin/manageSites`},
            ]},
        },
      )
    }
  }
  
  getSites=async()=>{
    let logged=await checkLoggedIn()
    if(!logged){history.push("/usa/login"); return}
    let siteOpts=(await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {accountId: globs.userData.session.accountId}})).data
    let sites={}
    siteOpts.forEach(s=>{
      sites[s.siteId]={v:s.siteId, t:s.name, w:s.weatherStation, x:s.siteImage}
//       sites.push({v:s.siteId, t:s.name, w:s.weatherStation})
    })
    let site=(this.state.site)?this.state.site:siteOpts[0].siteId
    
//     if(this.getSiteIndex(site)<0)site=siteOpts[0].siteId
//     cl(site)
    this.setState({siteOpts: sites, site:site})
//     cl(siteOpts[0].siteId)
    // cl(siteOpts)
  }
  
//   getSiteIndex=(siteId)=>{
//     for(let i=0; i<this.state.siteOpts.length; i++){
//       if(this.state.siteOpts[i].v==siteId){return i}
//     }
//     return -1
//   }
  
  addSite=async()=>{// need to distinguis between old and new
    let name=this.state.editName
    let siteId=this.state.editSite// blank if this is new
//     let sites=this.state.siteOpts.slice(0)
    let sites=Object.assign({},this.state.siteOpts)
    let si={v:siteId, t:name, w:this.state.weatherStation, x:this.state.siteImage}
    if(siteId==""){// new site
      siteId=getRandomString(16)
      sites[siteId]=si//push(si)
    }else{// update site Name
//       let ind=this.getSiteIndex(siteId)
      sites[siteId]=si//[ind]=si
    }
    await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId: siteId, name: name, weatherStation: this.state.weatherStation, 
        siteImage:this.state.siteImage}})
    this.setState({siteOpts: sites})
  }
  
  deleteSite=async()=>{
    let siteId=this.state.site
//     let ind=this.getSiteIndex(siteId)
//     let sites=this.state.siteOpts.slice(0)
//     sites.splice(ind, 1)
    let sites=Object.assign({},this.state.siteOpts)
    delete sites[siteId]
    this.setState({siteOpts: sites})
    await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "delete", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId: siteId}})
  }
  
  loadZones=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId: this.state.site}})
    cl(res)
    let zoneOpts=[]
    res.data.forEach(z=>{
      zoneOpts.push({v:z.zoneId, t:z.zoneName})
    })
    cl(zoneOpts)
    return zoneOpts
  }
  
  setSite=async(siteId)=>{
//     cl(this.state.siteOpts[siteId])
//     cl(siteId)
//     cl(globs.userData.session)
    cl(siteId);
    let si=this.state.siteOpts[siteId]
    // get site image info
    await wsTrans("usa", {cmd: "cRest", uri: "/s/sessions", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId: siteId, weatherStation: si.w, siteImage: si.x}})
    globs.userData.session.siteId=siteId
    globs.userData.session.weatherStation=si.w
    globs.userData.session.siteImage=si.x
  }
//   "d6NyXr5QFxemGJmef7XoAZxMtWh1cda5" sessionid, siteid: 9kMWrPGfiJMCNhXN
  
  onChange=(type, vals)=>{
    cl(vals)
    cl(this.state.siteOpts)
    switch(type){
      case "site":
        this.setSite(vals.site)
        break
      default:
        break
    }
    this.setState(vals)
  }
  
  result=async(type)=>{
//     cl(type)
    switch(type){
      case "Site-New":
        this.setState({showAdd: true, editName: "", editSite: ""})
        break
      case "Site-Edit":
//         cl(this.state.site)
//         cl(this.state.siteOpts[this.state.site])
//         cl(this.state)
        cl(this.state.siteOpts)
        let so=this.state.siteOpts[this.state.site]
//         let so=this.state.siteOpts[this.getSiteIndex(this.state.site)]
//         let name=this.state.siteOpts[this.getSiteIndex(this.state.site)].t
        this.setState({showAdd: true, editName: so.t, editSite: this.state.site, 
          weatherStation: so.w, siteImage: so.x, zoneOpts: await this.loadZones()})
        break
      case "Site-Delete":
        this.deleteSite()
        break
      case "SiteAdd-OK":
        this.addSite()
      case "SiteAdd-Cancel":
        this.setState({showAdd: false})
        break;
      default:
        break
    }
  }
  
  showSiteSel=()=>{
    // cl(this.state.siteOpts)
    return(
      <div>
      <h3>Manage Sites</h3>
      <UsaIcon icon="Site-New" result={this.result} inline/>
      <UsaIcon icon="Site-Edit" result={this.result} inline/>
      <UsaIcon icon="Site-Delete" result={this.result} inline/>
      <UsaSelect00 parms={{
        title: "Select\u00A0Site",
        inline: true,
        width: 200,
        select: true, // !this.state.editMode,
        valueId: "site",//valueId, // "widgetSel",
        value: this.state.site, //value, // this.state.widgetSel,
        opts: this.state.siteOpts,
        onChange: (vals)=>this.onChange("site", vals),
      }}/>
      <h4>{this.state.site}</h4>
      </div>
    )
  }
  
  markImage=async(e)=>{
    
    cl(e.target.files)
    let files=e.target.files[0]
    let imageId= getRandomString(16)
    let ext=files.name.substr(files.name.lastIndexOf('.')+1)
    let data = new FormData()
    data.append("type", "site")
    data.append("sessionId", globs.userData.session.sessionId)
    data.append('file', files)
    data.append('id', imageId)
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    cl("post image")
    let ret=await doGetPostBasic(url, method, data, type)
    let ret2 = await ret.json()
    this.setState({siteImage: `${imageId}.${ext}`})
  }
  
  showSiteImage=()=>{
//     cl(this.state.avatar)
    // let av=this.state.avatar
    var path
    cl(this.state.siteImage);
    if(this.state.siteImage){
      let si=this.state.siteImage.split('.')[0];
      let ext=this.state.siteImage.split('.')[1];
      path=`${constant.expressUrl}/usa/images/site/uploads/${si[0]}/${si[1]}/${si[2]}/${si.substr(3)}.${ext}`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
    return(
      <div>
      <div style={{height: 20}}/>
      <div>
        <IconButton
          variant="contained"
          component="label"
          title="Upload Image"
        >
          <Camera/>
          <input hidden multiple type="file" onChange={this.markImage}/>
        </IconButton>
      </div>
      
      <h3 style={{display: "inline-block"}}>Site Image</h3>
      <div/>
      <img width="80" src={path} style={{padding: 10}}/>
      </div>
    )
  }
  
  showSiteAdd=()=>{
//     let zoneOpts=[]
    return(
      <div>
      <h3>Manage Sites</h3>
        <div style={{height: 10}}/>
        <UsaIcon icon="SiteAdd-OK" result={this.result} inline/>
        <UsaIcon icon="SiteAdd-Cancel" result={this.result} inline/>
        <UsaSelect00 parms={{
          title: "Site\u00A0Name",
          inline: true,
          width: 200,
          valueId: "editName",//valueId, // "widgetSel",
          value: this.state.editName, //value, // this.state.widgetSel,
          onChange: (vals)=>this.onChange("editName", vals),
        }}/>
        <div style={{height: 10}}/>
        <UsaSelect00 parms={{
          title: "Weather\u00A0Station",
          inline: true,
          select: true,
          width: 200,
          valueId: "weatherStation",//valueId, // "widgetSel",
          value: this.state.weatherStation, //value, // this.state.widgetSel,
          opts: this.state.zoneOpts,
          onChange: (vals)=>this.onChange("weatherStation", vals),
        }}/>
        {this.showSiteImage()}
      </div>
    );
  }
  
  render(){
    if(this.state.showAdd){
      return this.showSiteAdd()
    }else{
      return this.showSiteSel()
    }
  }
}

export default ManageSites ;
 
 
