import React from 'react';
// import MainBar from '../../components/MainBar';
import UsaSelect00 from './UsaSelect00';
import UsaIcon from './UsaIcon';
import {wsTrans, checkLoggedIn} from '../utils/utils'
import {cl, globs, userSpecificHome, getRandomString} from '../../components/utils/utils';


class ManageGateways extends React.Component{
  constructor(props) {
    super(props);
    document.title="Manage Gateways";
    this.state={
      loaded: false,
      gatewayEdit: false,
      gatewayAdd: false,
      gatewayName: "none",
    }
    this.getSites()
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Gateways", url:`/usa/c18/admin/manageGateways`},
            ]},
        },
      )
    }
}

  getSites=async()=>{
    let siteP=wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
    let gwP=wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "retrieve2", 
      sessionId: globs.userData.session.sessionId, body: {}})
    let [siteR, gwR]=await Promise.all([siteP, gwP])
    let sites=siteR.data
    let siteOpts={}
    sites.forEach(s=>{
      siteOpts[s.siteId]=s.name
    })
    let site=sites[0].siteId
    this.gateways=gwR.data
    let gatewayOpts=this.makeGatewayOpts(site)
    let gateway=Object.keys(gatewayOpts)[0]
    let gatewayName=gatewayOpts[gateway]
//     cl(gatewayOpts)
    this.setState({siteOpts: siteOpts, site: site, gatewayOpts: gatewayOpts, gateway: gateway, 
      gatewayName: gatewayName, loaded: true})
//     cl(gwR.data)
  }
  
  updateGateway=async(gw)=>{
    await wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "update", 
      sessionId: globs.userData.session.sessionId, body: gw})
  }
  
//   updateZone=async(gw)=>{// this needs to be changed! It creates a zone 0 when it creates a gateway
//     let zoneId=getRandomString(16)
//     await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update", 
//       sessionId: globs.userData.session.sessionId, body: {
//         siteId: gw.siteId,
//         gatewayId: gw.gatewayId,
//         zoneId: zoneId,
//         zoneIndex: 0,
//         zoneName: "New Zone",
//       }})
//   }
  
  deleteGateway=async(gw)=>{// this should remove the site and account info from the gateway
    cl(gw)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "delete", 
      sessionId: globs.userData.session.sessionId, body: gw})
  }
  
  makeGatewayOpts=(site)=>{
//     cl(site)
//     cl(this.gateways)
    let gatewayOpts={}
    this.gateways.forEach(g=>{
//       cl(g)
      if(g.siteId===site){
//         cl(g.siteId)
//         cl(site)
        gatewayOpts[g.gatewayId]=g.name
      }
    })
//     cl(gatewayOpts)
    return gatewayOpts
  }
  
  result=(type)=>{
//     cl(type)
    var gatewayOpts
    var gateway
    var g
    switch(type){
      case "Gateway-Add":
        this.setState({gatewayAdd: true, gatewayName: "", gateway: ""})
        break
      case "Gateway-Edit":
        
        this.setState({gatewayEdit: true})
        break
      case "Gateway-Delete":
/*delete from this.gateways, gatewayOpts, and the DB*/
        let gw=this.gateways
        for(let i=0; i<gw.length; i++){
          if(this.state.gateway==gw[i].gatewayId){
            g=this.gateways.splice(i,1)[0]
          }
        }
        gatewayOpts=Object.assign({},this.state.gatewayOpts)
        delete gatewayOpts[this.state.gateway]
        if(Object.keys(gatewayOpts).length){
          gateway=Object.keys(gatewayOpts)[0]
        }
        delete g.name
        this.deleteGateway(g)
        this.setState({gatewayOpts: gatewayOpts, gateway: gateway})
        break
      case "GatewayAdd-OK":
/*need to create a new entry in this.gateways, and gatewayOpts, and save to DB*/
//         let gateway=this.state.gatewayId
        gatewayOpts=Object.assign({},this.state.gatewayOpts)
        gatewayOpts[this.state.gateway]=this.state.gatewayName
        g={
          accountId: globs.userData.session.accountId,
          siteId: this.state.site,
          gatewayId: this.state.gateway,
          name: this.state.gatewayName,
        }
        this.gateways.push(g)
        this.updateGateway(g)
//         this.updateZone(g)
        this.setState({gatewayEdit: false, gatewayAdd: false, gatewayOpts: gatewayOpts})
        break
      case "GatewayName-OK":
        gatewayOpts=Object.assign({},this.state.gatewayOpts)
        gatewayOpts[this.state.gateway]=this.state.gatewayName
        this.gateways.forEach(g=>{
          if(g.gatewayId==this.state.gateway){
            g.name=this.state.gatewayName
            this.updateGateway(g)
          }
        })
        this.setState({gatewayEdit: false, gatewayAdd: false, gatewayOpts: gatewayOpts})
        break
      case "GatewayName-Cancel":
        this.setState({gatewayEdit: false, gatewayAdd: false})
        break
      default:
        break
    }
  }
  
  onChange=(type, vals)=>{
    var gatewayName
    switch(type){
      case "site":
//         cl(vals.site)
        let gatewayOpts=this.makeGatewayOpts(vals.site)
        let gateway=Object.keys(gatewayOpts)[0]
        gatewayName=gatewayOpts[gateway]
//         cl(gatewayOpts)
        vals=Object.assign({gatewayOpts: gatewayOpts, gateway: gateway, gatewayName: gatewayName}, vals)
        this.setState(vals)
        break
      case "gateway":
        cl(vals)
        cl(vals.gateway)
        gatewayName=this.state.gatewayOpts[vals.gateway]
        vals=Object.assign({gatewayName: gatewayName}, vals)
        this.setState(vals)
        break
      case "gatewayName":
        this.setState(vals)
        break;
      default:
        break
    }
  }
  
  showAddGateway=()=>{
    return(
      <div>
      <h3>Gateway
        <UsaIcon icon={`GatewayAdd-OK`} result={this.result} inline/>
        <UsaIcon icon={`GatewayAdd-Cancel`} result={this.result} inline/>
        </h3>
        <UsaSelect00 parms={{
          title: "Gateway\u00A0ID",
          width: 200,
          select: false, // !this.state.editMode,
          valueId: "gateway",//valueId, // "widgetSel",
          value: this.state.gateway, //value, // this.state.widgetSel,
          opts: this.state.gatewayOpts,
          onChange: (vals)=>this.onChange("gateway", vals),
        }}/>
        <div style={{height: 10}}/>
        <UsaSelect00 parms={{
          title: "Gateway\u00A0Name",
          width: 200,
          select: false, // !this.state.editMode,
          valueId: "gatewayName",//valueId, // "widgetSel",
          value: this.state.gatewayName, //value, // this.state.widgetSel,
          opts: this.state.gatewayOpts,
          onChange: (vals)=>this.onChange("gatewayName", vals),
        }}/>
      </div>
    )
  }
  
  showSelectEditGateway=()=>{
    if(this.state.gatewayAdd){return this.showAddGateway()}
    if(this.state.gatewayEdit){
      return(
        <div>
        <h3>Gateway
          <UsaIcon icon={`GatewayName-OK`} result={this.result} inline/>
          <UsaIcon icon={`GatewayName-Cancel`} result={this.result} inline/>
          </h3>
          <UsaSelect00 parms={{
            title: "Gateway\u00A0Name",
            width: 200,
            inline: true,
            select: false, // !this.state.editMode,
            valueId: "gatewayName",//valueId, // "widgetSel",
            value: this.state.gatewayName, //value, // this.state.widgetSel,
            opts: this.state.gatewayOpts,
            onChange: (vals)=>this.onChange("gatewayName", vals),
          }}/>
        </div>
      )
    }else{
      cl(this.state)
      if(Object.keys(this.state.gatewayOpts).length){
        return(
          <div>
          <h3>Gateway
            <UsaIcon icon={`Gateway-Add`} result={this.result} inline/>
            <UsaIcon icon={`Gateway-Edit`} result={this.result} inline/>
            <UsaIcon icon={`Gateway-Delete`} result={this.result} inline/>
            </h3>
            <UsaSelect00 parms={{
              title: "Select\u00A0Gateway",
              width: 200,
              inline: true,
              select: true, // !this.state.editMode,
              valueId: "gateway",//valueId, // "widgetSel",
              value: this.state.gateway, //value, // this.state.widgetSel,
              opts: this.state.gatewayOpts,
              onChange: (vals)=>this.onChange("gateway", vals),
            }}/>
            <div style={{height: 10}}/>
            Gateway Id: {this.state.gateway}
          </div>
        )
      }else{// not gateways for this site
        return(
          <div>
          <h3>Gateway
            <UsaIcon icon={`Gateway-Add`} result={this.result} inline/>
            </h3>
            No Gateway
          </div>
        )
      }
    }
  }
  
  render(){
    if(this.state.loaded){
      return(
        <div style={{padding: 10}}>
        <h3>Manage Gateways</h3>
        <UsaSelect00 parms={{
          title: "Select\u00A0Site",
          width: 200,
          select: true, // !this.state.editMode,
          valueId: "site",//valueId, // "widgetSel",
          value: this.state.site, //value, // this.state.widgetSel,
          opts: this.state.siteOpts,
          onChange: (vals)=>this.onChange("site", vals),
        }}/>
        <div style={{height: 10}}/>
        Site Id: {this.state.site}
        <div style={{height: 10}}/>
        {this.showSelectEditGateway()}
        </div>
      );
    }else{return <div>loading...</div>}
  }
}

export default ManageGateways ;

