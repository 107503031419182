import React from 'react';
import TextInput from './TextInput';
import UsaButton from './UsaButton';
import UsaCheckbox from './UsaCheckbox';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import {wsTrans} from '../utils/utils';
import {cl, globs} from '../../components/utils/utils';
import {checkLoggedIn} from '../utils/utils';
import history from "../../history"


class ManageGateways extends React.Component{
  constructor(props) {
    super(props);
    document.title="Manage Gateways";
    this.getGateways();
    this.state={
      showAddGateway: false,
      gatewayId: "EK00-RX9W-17FG-ZUYZ",
      name: "Gateway One",
    };
  }
  
  toggleAddGateway=()=>{
    this.setState({showAddGateway: !this.state.showAddGateway});
    //     cl(result);
  }
  
  setStateValue=(id, value)=>{
    let val={};
    val[id]=value;
    this.setState(val);
  }
  
  addThisGateway=()=>{
    cl("add gateway");
    cl(this.state.gatewayId);
    let body={gatewayId: this.state.gatewayId, name: this.state.name};
    let addGateway={cmd: "cRest", uri: "/s/gateways", method: "create", body: body, 
      sessionId: globs.userData.session.sessionId};
    wsTrans("usa", addGateway).then(r=>{
      cl(r);
    });
  }
  
  initGatewayNames=(gateways)=>{
//     cl(gateways);
    let names={};
    if(gateways){
      gateways.forEach((c, i)=>{
        let gatewayField="co-" + c.gatewayId;
        names[gatewayField]=c.gatewayName;
      });
      this.setState(names);
    }
  }
  
  getGateways=async()=>{
    let logged=await checkLoggedIn()
    if(!logged){
      history.push("/usa/login")
      return
    }
      
      if(!globs.userData.session)return
//     cl(globs.userData);
    if(!globs?.userData?.session?.siteId)return
    let body={siteId: globs.userData.session.siteId};
    let addGateway={cmd: "cRest", uri: "/s/gateways", method: "retrieve", body: body, 
      sessionId: globs.userData.session.sessionId};
      wsTrans("usa", addGateway).then(r=>{
        this.initGatewayNames(r.data);
        this.setState({gateways: r.data});
//         cl(r);
      });
  }
  
  showGateways=()=>{
    if(!this?.state?.gateways) return;
//     cl(this.state);
    return(
      <div>
        {this.state.gateways.map((r, i)=>{
          let gatewayField="co-" + r.gatewayId;
          cl(this.state[gatewayField]);
          return (
            <div key={i} style={{display: "flex", alignItems: "center"}}>
              <div>
              <IconButton style={{padding: 0}}>
              <Close style={{fontSize: 18}}/>
              </IconButton>
              </div>
              <div>{r.gatewayId}</div>
              <div>
                <TextInput title="" valueId={gatewayField} onChange={this.setStateValue}
                initValue={this.state[gatewayField]} width={100}/>
              </div>
              <div>
              <UsaCheckbox/>
              </div>
              <div>{r.gatewayId}</div>
            </div>
          )
//           r.gatewayId
        })}
      </div>
    );
    cl(this.state);
  }
  
  render(){
    return(
/* manageGateways:
show a list of gateways in current site 
for each, delete, change name, make active, 
also, 
C5MM-FXW3-BT1J-Y1-H
ABCDEFGHIJKLMNOPQRSTUVWXYZ
*/
      <div>
      <h2>Manage Gateways</h2>
      {this.showGateways()}
      <UsaButton text="Add Gateway" onClick={this.toggleAddGateway}/>
      {(this.state.showAddGateway) &&
        <div>
        <h3>Add a Gateway</h3>
        <TextInput title="Gateway ID" valueId="gatewayId" onChange={this.setStateValue}
          initValue={this.state.gatewayId}/>
        <TextInput title="Gateway Name" valueId="name" onChange={this.setStateValue}
          initValue={this.state.name}/>
        {(this.state.alertText) &&
          <Alert severity={this.state.alertSeverity}>{this.state.alertText}</Alert>
        }
        <UsaButton text="Add This Gateway" onClick={this.addThisGateway}/>
        </div>
      }
      </div>
    );
  }
}

export default ManageGateways ;
 
 
