import React from 'react';
import {getUserIndex,makeAvatarPath} from '../../usa/components/C18utils'
import {getDatapointName} from '../../usa/utils/utils'
import {cl,globs,getTime,dateToDisplayDate} from '../../components/utils/utils';

class AuditLogEntry extends React.Component{
  constructor(props) {
    super(props);
    // cl(props)
    this.state={
      closed: true,
    }
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    switch(type){
      case "openClose":
        // show the rest of the entries if applicable (load more option???)
        this.setState(vals)
    }
    
  }
  
  makeAuditLogEntry=(c)=>{
    let da=new Date(1000*c.t)
    let ui
    if (c.u) {
      let idx = getUserIndex(c.u)
      ui=(idx == -1) ? {name: "Super User"} : globs.usersInfo.info[getUserIndex(c.u)]
    } else {
      // TODO check to see whether 1800 or Pearl 
      ui={name:"LinkConn User"}
    }
    return {
      parmName:getDatapointName(c?.i,globs.siteZoneTypes[c.z]),
      oldVal:c.o||"Unassigned",
      newVal:c.d,
      src:c.f,
      unit:"",
      name:ui.name,
      avatar:makeAvatarPath(ui.avatar),
      time:dateToDisplayDate(da,"h:mm AP"),
      date:dateToDisplayDate(da,"mm/dd/yyyy"),
      t:c.t,
      _id:c._id
    }    
  }

  showEntry=(entries, i)=>{
    // cl([entries, i])
    // TODO check to see whether 1800 or Pearl
    // check if single entry or array
    var a
    let src
    if (entries.length == 1) {
      a = entries[0]
      src=(a.src==1)?"(lc)":""
//       let dpName=getDatapointName(a.i)
      return(
        <div key={i} id="sideBarAuditLogEntry">
          <div className="info-section">
            <div className="floatleft">
              <img src={a.avatar} alt={a.name}/><span className="name">{a.name+src}</span>
            </div>
            <div className="floatright time-date">
              <span className="time">{a.time}</span> | <span className="date">{a.date}</span>
            </div>
            <div className="clearfloat"></div><br />
            <div className="floatleft">
              <span id="sideBarAuditLogContent" className="log-entry">{a.parmName}</span><br />
              <div className="change"><span><i>{`${a.oldVal}${a.unit}`}</i></span> -> <span><i>{`${a.newVal}${a.unit}`}</i></span></div>
            </div>
          </div>

          <div className="clearfloat"></div>
          <br /><hr /><br />
        </div>
      )
    } else {
      src=(entries[0].src==1)?"(lc)":""
      let group = entries.map((a, j) => {
        if (!this.state.closed) {
          return(
            <div key={`${i}-${j}`} id="sideBarAuditLogEntry">
              <div className="info-section audit-log-reply">
                <div className="floatleft">
                  <span id="sideBarAuditLogContent" className="log-entry">{a.parmName}</span><br />
                  <div className="change"><span><i>{`${a.oldVal}${a.unit}`}</i></span> -> <span><i>{`${a.newVal}${a.unit}`}</i></span></div>
                </div>
                <div className="clearfloat"></div>
                <br /><hr /><br />
              </div>
            </div>
          )
        }
      });
      group.unshift(
          <div key={i} id="sideBarAuditLogEntry">
            <div className="info-section">
              <div className="floatleft">
                <img src={entries[0].avatar} alt={entries[0].name}/><span className="name">{entries[0].name+src}</span>
              </div>
              <div className="floatright time-date">
                <span className="time">{entries[0].time}</span> | <span className="date">{entries[0].date}</span>
              </div>
              <div className="clearfloat"></div><br />
              <div className="floatleft">
                <div className="change"><span>
                  <i>{`${entries.length} entries...`}</i>
                  <>
                  {(this.state.closed)&&
                    <button id="/sidebar/auditLogEntry/openClose" type="button" className={`material-icons-outlined`}
                      title="Expand" onClick={e=>this.onChange("openClose",{closed:false})}>
                      chevron_right
                    </button>
                  }
                  {(!this.state.closed)&&
                    <button id="/sidebar/auditLogEntry/openClose" type="button" className={`material-icons-outlined`}
                      title="Collapse" onClick={e=>this.onChange("openClose",{closed:true})}>
                      expand_more
                    </button>
                  }
                </>
                </span>
                </div>
              </div>
            </div>
  
            <div className="clearfloat"></div>
            <br /><hr /><br />
          </div>
      )
    return group
    }
  }
  
  render() {
    // sort through entries, display entries past first as "replies"
    return this.showEntry(this.props.parms.entries, this.props.parms.i)
  }

}
      
export default AuditLogEntry;
