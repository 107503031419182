import React from 'react';
import {cl} from '../../components/utils/utils';

class UsaTextArea00 extends React.Component{
  constructor(props){
    super(props)
    this.state={
      value: this.props.parms.value,
    }
  }
  
  onChange=(e)=>{
    let v=e.target.value
    let vals={}
    vals[this.props.parms.valueId]=v
    this.props.parms.onChange(vals)
    this.setState({value: v})
  }
  
  render(){
    let style={}
    if(this.props.parms.width){style.width=this.props.parms.width}
    if(this.props.parms.height){style.height=this.props.parms.height}
    return(
      <textarea 
        style={style}
        onChange={this.onChange}
        value={this.props.parms.value}
        >
      </textarea>
    )
  }
}

export default UsaTextArea00;
