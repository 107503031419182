import React from 'react';
// import ReCAPTCHA from "react-google-recaptcha";
// import {Link} from 'react-router-dom'
import C18Input00 from './C18Input00'
// import C18InputError00 from './C18InputError00'
import C18Button00 from './C18Button00'
// import C18Anchor00 from './C18Anchor00'
import history from "../../history"
import {loadCamerasInfo,loadSitesInfo,loadZonesInfo} from './C18utils'
import {wsTrans} from '../utils/utils'
import {cl, globs,getTime,constant} from '../../components/utils/utils';

class C18Cameras00 extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    let pa=props.parms
    this.state={
      addId:pa.adminInfo||"",
      addName:"",
      loaded:false,
    }
    this.setBreadcrumbs()
    this.loadInfo()
  }
  
  setBreadcrumbs=async()=>{
    let pa=this.props.parms
    await loadSitesInfo()
    this.site=globs.sitesInfo.info.filter(s=>{return s.siteId==pa.site})[0]
    cl(this.site)
    await loadZonesInfo()
    this.zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==pa.zone})[0]
    if(pa){
      pa.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:`/usa/c18/sites`},
              {t:`${this.site.name}`, url:`/usa/c18/sites/${pa.site}`},
              {t:`${this.zone.zoneName}`, url:`/usa/c18/sites/${pa.site}/zones/${pa.zone}`},
              {t:`${this.zone.zoneName} Sensor Settings`, 
                url:`/usa/c18/sites/${pa.site}/zones/${pa.zone}/settings/sensor1800`},
              {t:`${this.zone.zoneName} Cameras`, 
                url:`/usa/c18/sites/${pa.site}/zones/${pa.zone}/admin/cameras`},
            ]},
        },
      )
    }
  }
  
  loadInfo=async()=>{
    let pa=this.props.parms
//     cl(this.props)
    let scope="account"
    if(pa.site){scope="site"}
    if(pa.zone){scope="zone"}
    if(pa.pageType){scope="config"}
    await loadCamerasInfo()
    cl(globs.camerasInfo.info)
    var cameras=[]
    switch(scope){
      case "account":
        cameras=globs.camerasInfo.info
        break
      case "site":
        cameras=globs.camerasInfo.info.filter(c=>{return c.siteId==pa.site})
        break
      case "zone":
        cl("zone")
        cameras=globs.camerasInfo.info.filter(c=>{return c.zoneId==pa.zone})
        break
    }
    cl(cameras)
    let cam=cameras.filter(ca=>{return ca.cameraId==pa.adminInfo})[0]
    cl(cam)
    this.setState({loaded:true,scope:scope,cameras:cameras,addName:cam?.name})
  }
  
  addCamera=async()=>{
    cl("add camera")
    cl(this.props)
    let st=this.state
    let pa=this.props.parms
    let cam={cameraId:st.addId,name:st.addName,siteId:pa.site,zoneId:pa.zone}
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/cameras", method: "update", 
      sessionId: globs.userData.session.sessionId, body: cam})
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    let pa=this.props.parms
    switch(type){
      case "cameraAdd":
        this.setState(vals)
        break
      case "addCamera":
        this.addCamera()
        break
      case "selCam":
//         cl(vals)
//         cl(this.props)
        let url=`/usa/c18/sites/${pa.site}/zones/${pa.zone}/admin/cameras/${vals.cameraId}`
        let cam=globs.camerasInfo.info.filter(ca=>{return ca.cameraId==vals.cameraId})[0]
        history.push(url)
        this.setState({addId:vals.cameraId,addName:cam.name})
        break
    }
  }
  
  showAddCamera=()=>{
    let st=this.state
    let pa=this.props.parms
    let edit=(pa.adminInfo)?true:false
    cl(st)
    let addDisable=(!st.addId?.length)||(!st.addName?.length)
    return(
      <div style={{width:400,borderStyle:"solid",
        padding:20,
        borderWidth:1,borderRadius:10}}>
        <h3>{pa.adminInfo?"Edit":"Add"} Camera</h3>
        {edit?
          <h4>{`Camera Id: ${pa.adminInfo}`}</h4>
          :
          <>
            <label htmlFor="camera-addid">Camera Id</label>
            <C18Input00 type="text" id="camera-addid" className="with-right-button"
              value={this.state.addId}
              onChange={e=>this.onChange("cameraAdd",{addId:e.currentTarget.value})}
            />
          </>
        }
        <label htmlFor="camera-addname">Camera Name</label>
        <C18Input00 type="text" id="camera-addname" className="with-right-button"
          value={this.state.addName||""}
          onChange={e=>this.onChange("cameraAdd",{addName:e.currentTarget.value})}
        />
        <C18Button00 type="button" className="filled" disabled={addDisable}
        onClick={()=>this.onChange("addCamera",{})}>{(edit)?"Save":"Add"}</C18Button00>
        
      
      </div>
    )
  }
  
  showACam=(cam,i)=>{
    let st=this.state
    let now=Math.floor(getTime())
    cl(now)
    return(
      <div key={i} style={{width:300,
        backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:20,
        margin:20,verticalAlign:"top",
        display:"inline-block",
      }}
        onClick={e=>this.onChange("selCam",{cameraId:cam.cameraId})}
        >
      <h3>{cam.name}</h3>
      <img src={`http://ngsg.link4cloud.com:3195/camVideo/nmNENuZW/${now}`} 
        width="300"/>
      </div>
    )
  }
  
  showCameras=()=>{
    let st=this.state
    let cams=st.cameras.map((c,i)=>{
      return this.showACam(c,i)
    })
    return cams
//     globs.camerasInfo.info.filter
//     return()
  }

  render(){
    let st=this.state
    if(st.loaded){
      return(
        <div>
        {this.showCameras()}
        {this.showAddCamera()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

export default C18Cameras00;
