import React from 'react';
import {cl, globs,constant} from '../../components/utils/utils';
import Graph2 from './Graph';
// import Graph from './Graph';
import {wsTrans} from '../../usa/utils/utils'
import {getTime} from '../../components/utils/utils'
import {dbVals} from '../../components/utils/http'
import {getParmInfo} from '../../usa/utils/utils'

class TimeSeries00 extends React.Component{
  constructor(props) {
    super(props);
//     cl("constructor")
//     cl(props)
//     cl(props.parms.wdg.parms.dpList[0].data.length)
//     cl(props.parms.wdg.color)
//     cl(props.parms.wdg.parms.title)
    props.notify({id: "drag", func: this.onDrag})
//     cl("construct")
    //     cl(props)
//     cl(props.parms)
    let dm=5
    this.state={loaded: false}
    this.graph={
      left: props.parms.x,
      top: props.parms.y,
//       width: props.parms.w-2*dm,
//       height: props.parms.h-2*dm,
      radius: 10,
      margin: 5,
//       xTicks: 1,
//       yTicks: 2,
//       title: props.parms.wdg.parms.title,
      data: [],
      showCurrentValue:props.parms.wdg.parms.showCurrentValue,
      currentValueColor:props.parms.wdg.parms.currentValueColor,
      periodType:props.parms.wdg.parms.periodType,
      showValues:props.parms.wdg.parms.showValues,
      showValueNames:props.parms.wdg.parms.showValueNames,
//       data: [
//         {date: 1608130982000, close: 100},
//         {date: 1608130992000, close: 110},
//         {date: 1608131002000, close: 120},
//         {date: 1608131022000, close: 130},
//       ],
//       bgColor: props.parms.wdg.color,
    }
//     cl(this.state)
    props.parms.wdg.update=this.update
//     cl("set update")
    this.getData()
  }

// static getDerivedStateFromProps(){cl("getDerivedStateFromProps")}
// shouldComponentUpdate(){cl("shouldComponentUpdate")}
// getSnapshotBeforeUpdate(){cl("getSnapshotBeforeUpdate")}
// componentDidUpdate(){cl("componentDidUpdate")}
// 
// static getDerivedStateFromError(){cl("getDerivedStateFromError")}
// componentDidCatch(){cl("componentDidCatch")}
// componentDidMount(){cl("componentDidMount")}



//   componentDidMount=()=>{this.mounted=true; cl("mount")}
  componentWillUnmount=()=>{
    this.mounted=false
//     cl("un mount")
    this.props.notify({id: "drag", func: this.onDrag, unMount: true})
  }
  
  componentDidMount=()=>{
    this.mounted=true
//     cl("did mount")
  }
  
  update=(wdg, paramId, isRender)=>{
//     cl(wdg)
//     showVersion(this.gauge)
//     if(wdg==this.gauge){cl("the same")}
    let p=this.props.parms.wdg.parms
//     cl(p)
    p.dec=p.dec||1
    let val = Math.round(p.dataPoints.val * p.dec) / (p.dec * p.mult)
//     this.gauge.value=val
//     this.gauge.update(isRender)
    if(this.mounted&&!isRender){
      this.setState({value: val})
    }
  }
  
/* Bar Chart Data:
can be interval, or single
single means that only the last value for each datapoint is shown
in this case, each should also have a name
bar chart data could have usually just one array, with time, value, name
actually, if it has a name, it doesn't have a time! interval=time, single=name
so, an array with name/time, and value
for the stacked bars, paint the values from back to front!
so, for interval, get the data normally, using the duration, and assuming 10 bars - this will be filled in, later

*/

  getOneData=async(z, c, i, b, e)=>{// b,e: fromTime, toTime
//     cl("getOneData")
    let body={fromTime: b, toTime: +e, z: +z, c: +c, i: +i}// get one dashboard, by id
//     cl(body)
    let getHistory={cmd: "cRest", uri: "/s/history", method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: body};
//       cl(body)
    let res=await wsTrans("usa", getHistory)
//     cl(res)
    return res
  }
  
//   getAllData=async()=>{
//     await this.getData()
//     cl("done")
//   }

  dataToBar=(parms, dp)=>{
//     cl("dataToBar")
//     cl(dp)
    let bars=+this.props.parms.wdg.parms.barCount//10// temp!
//     cl(bars)
//     cl(parms)
//     cl(dp)// has t and d fields
    if(dp.data.length){
      let totalTime=dp.e0-dp.b0
      let perBar=Math.floor(totalTime/(bars))
      let data2=[]
      dp.data.forEach(dt=>{
        let ind=Math.floor((dt.t-dp.b0)/perBar)
        data2[ind]={t:dt.t, d:dt.d}
      })
      let last=dp.data[0].d
      for(let i=0;i<bars;i++){
        if(data2[i]){
          last=data2[i]
        }
        data2[i]={t:dp.b0+i*perBar, d:last.d}
      }
//       cl(data2)
      dp.data=data2
    }
  }
  
  setCurrentValues=async()=>{// for testing only!
// currently just inTemp for zones 0-2
    let e=Math.floor(getTime());
//     cl(e)
    let proms=[]
    let c=240
    let i=23
    for(let z=0;z<3;z++){ proms.push(this.getOneData(z,c,i,e,e)) }
    cl(dbVals)
    let vals=await Promise.all(proms)//.then((vals,i)=>{
      
      vals.forEach(v=>{
        let da=v.data[0]
        cl(v)
        if(da){
          if(!dbVals.z[da.z]){dbVals.z[da.z]={}}
          if(!dbVals.z[da.z][da.c]){dbVals.z[da.z][da.c]={}}
          dbVals.z[da.z][da.c][da.i]=da.d
        }
//         cl("added")
      })
//     })
    
//   cl(dbVals.z)
  }
  
  getBarSingleData=async()=>{
    cl("getBarSingleData")
//     await this.setCurrentValues()
    let p=this.props.parms.wdg.parms
    let keys=Object.keys(p.dpid)
//     cl(p.dpid)
    let da=[]
//     cl(dbVals)
    keys.forEach(k=>{
        let dp=p.dpid[k]
        let z0=+((dp.zone!=="")?dp.zone : p.zone)
        let c0=+((dp.channel!=="")?dp.channel : p.chan)
        let ix=+((dp.index!=="")?dp.index : p.indx)
//         cl([z0, c0])
        if(dbVals.z[z0]){
          da.push([dp.showName, dbVals.z[z0][c0][23], 23])
        }
    })
//     cl(da)
    this.graph.data=da.slice(0)
    if(this.mounted){
      this.setState({loaded: true})// setState, now that this is async
    }
  }
  
  getData=()=>{
/* this needs to convert the zci, pid to z, c, i
also, this needs to use the dashboard zci, and the widget zci overrides*/
/* Time Offsets:
Time offset refers to a shift from the past: 1 day means show *yesterday*

I don't really understand how the data gets in dpList!
this.props.parms.wdg.parms.dpList[0].data is the same as:
this.props.parms.wdg.parms.dpid[0].data

*/

//     cl("getData")
    let p=this.props.parms.wdg.parms
//     cl(p)
    let isBar=((p.graphType=="bar")||(p.graphType=="3dBar"))
    if(isBar && (p.periodType=="barSingle")){return this.getBarSingleData()}
    return new Promise((r,er)=>{
//       cl(p)
//       cl(p.dpList.data)
      var b,e
      if(p.flags&constant.TIME_PERIOD_LIVE){
        e=Math.floor(getTime())
        b=e-p.duration*p.period
      }else{
        b=p.beginTime
        e=p.endTime
      }
//       cl([b,e])
      let proms=[]
      let keys=Object.keys(p.dpid)
      if(!keys.length){r()}
//       if(!p.dpList){r()}
//       cl(p.dpList)
      keys.forEach(async k=>{
//           cl(p.dpid[k])
        let dp=p.dpid[k]
//         cl(dp)
        let b0=b-(dp.timeOffset || 0)
        let e0=e-(dp.timeOffset || 0)
//         cl([b,e,b0,e0])
        let z0=(dp.zone!=="")?dp.zone : p.zone
        let c0=(dp.channel!=="")?dp.channel : p.chan
        let ix=(dp.index!=="")?dp.index : p.indx
        dp.b0=b0
        dp.e0=e0
        let [z,c,i]=getParmInfo(z0,c0,ix,dp.pid)
        proms.push(this.getOneData(z, c, i, b0, e0))
      })
//       cl(p.dpList)

//       p.dpList.forEach(async dp=>{
//         let z0=(dp.zone!=="")?dp.zone : p.zone
//         let c0=(dp.channel!=="")?dp.channel : p.chan
//         let ix=(dp.index!=="")?dp.index : p.indx
//         let [z,c,i]=getParmInfo(z0,c0,ix,dp.pid)
//         proms.push(this.getOneData(z, c, i, b, e))
//       })
      let startTime=getTime()
      Promise.all(proms).then(vals=>{
//         cl(vals)
//       cl(p.dpList)
        keys.forEach((k,i)=>{
          let dp=p.dpid[k]
//           cl(dp)
          let mult=(dp.multiplier?dp.multiplier:1)
          dp.data=vals[i].data.map(v=> {return{t:v.t,d:v.d/mult}})
//           cl(dp.data.slice(0))// data is clean here
          let gt=p.graphType
          if((gt=="bar")||(gt=="3dBar")){this.dataToBar(p,dp)}// we would only get here if the type is "interval", not "single"
        })
//         p.dpList.forEach((dp,i)=>{
//           let mult=(dp.multiplier?dp.multiplier:1)
//           dp.data=vals[i].data.map(v=> {return{t:v.t,d:v.d/mult}})
//         })
        this.graph.data=p.dpList.slice(0)
//       cl(p.dpList)
        this.setState({loaded: true})
        r()// return
      })
      
    })
  }
  
  onClick=()=>{
    if(this.props.parms.e) {return}// edit mode
    
  }

  onDrag=(o)=>{// o has the updated info
//     cl(this.mounted)
//     cl(this.state)
    if("dragStart" in o){
      this.setState(o)
    }else{
      if(this.state.dragStart.i==this.props.parms.i){
        this.setState(o)
      }
    }
  }
  
  cm=(e)=>{
//     cl("gen")
    e.stopPropagation()
    this.props.parms.cm({e: e, i: this.props.parms.i})
  }
  
  md=(e)=>{
    this.props.parms.md({e: e, i: this.props.parms.i})
  }
  
  render() {
//     cl("render")
    let dm=5
    let p = this.props.parms;
    this.graph.bgColor=p.wdg.color
    this.graph.title=p.wdg.parms.title
    this.graph.xTicks=(p.wdg.parms.xTicks)?1:0
    this.graph.yTicks=(p.wdg.parms.yTicks)?3:0
    this.graph.graphType=p.wdg.parms.graphType || "line"
    this.graph.width=this.props.parms.w-2*dm
    this.graph.height=this.props.parms.h-2*dm
//     cl(p)
//     cl(p.wdg.color)
    let x=p.x+dm
    let y=p.y+dm
    if(this?.state?.dragStart?.i==this.props.parms.i){
      x+=this.state.drag.x-this.state.dragStart.x
      y+=this.state.drag.y-this.state.dragStart.y
    }
//     cl(this.state.loaded)
    return(
        <div style={{position: "absolute", left: x, top: y, width: p.w-2*dm, height: p.h-2*dm, 
          borderRadius: 10, backgroundColor: p.wdg.color}} onContextMenu={this.cm} onMouseDown={this.md}>
          {this.state.loaded &&
          <Graph2 config={this.graph}/>}
          </div>
      );
  }
}

export default TimeSeries00;
