import React from 'react';
import UsaIcon from './UsaIcon';
import UsaSelect00 from './UsaSelect00';
// import UsaSelect00 from './UsaSelect00';
// import Datapoints from './Datapoints';
import {cl} from '../../components/utils/utils';

class Labels00 extends React.Component{
  constructor(props) {
    super(props);
    this.state=Object.assign({}, props.parms.vals)
    this.state.editMode=false
//     this.state={
//       editMode: false,
//       labels: [
//       {val: 5},
//       {val: 10},
//       {val: 15}],
//     }
  }
  
  setLabelVals=(o)=>{
    let o2=Object.entries(o)[0]
    let id=+o2[0].substr(1)
    let lab2=this.state.labels.slice(0)
    lab2[id].val=o2[1]
    this.setState({labels: lab2})
  }
  
  editLabels=()=>{// open labels to edit
    return(
      <div>
      <UsaIcon icon="labels-OK" result={this.result} inline/>
      <UsaIcon icon="labels-Cancel" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Labels</h3></span>
      {this.state.labels.map((l,i)=>{
        cl(l)
        if(l.edit){
          return(
            <div key={i}>
            <UsaIcon icon={`c${i}-OK`} result={this.result} inline/>
            <UsaSelect00 parms={{
              title: "Value",
              width: 100,
              inline: true,
              value: l.val,
              valueId: `v${i}`,
              onChange: this.setLabelVals,
            }}/>
            </div>
          )
        }else{
          return <div key={i}>
          <UsaIcon icon={`l${i}-Create`} result={this.result} inline/>
          <UsaIcon icon={`l${i}-Edit`} result={this.result} inline/>
          <UsaIcon icon={`l${i}-Delete`} result={this.result} inline/>
          {l.val}</div>
        }
      })}
      </div>
    )
  }
  
  displayLabels=()=>{// as a single line
    return(
      <div>
      <UsaIcon icon="labels-Edit" result={this.result} inline/>
      <span><h3 style={{display: "inline-block"}}>Labels:</h3></span>
      {` ${this.state.labels.length} labels`}
      </div>
    )
  }

  deepCopy=(arr)=>{// just used for this.state.colors, for now
    let o2=[]
    arr.forEach(o=>{
      o2.push(Object.assign({}, o))
    })
    cl(o2)
    return o2
  }
  result=(type)=>{
    var lab2
    switch(type){
//       case "labels-OK":
//         this.setState({editMode: false})
//         break
//       case "labels-Cancel":
//         this.setState({labels: this.labelsBack, editMode: false})
//         break
      case "labels-OK":
        this.props.onChange(this.state)
        this.setState({editMode: false})
        break
      case "labels-Cancel":
//         this.saveRestorePos("restore")
        this.setState({editMode: false, labels: this.labelsBack.slice(0)})
        break
      case "labels-Edit":
        lab2=this.state.labels.slice(0)
        lab2.forEach(l=>{l.edit=false})
        this.labelsBack=this.deepCopy(lab2)
        this.setState({editMode: true, labels: lab2})
        break
      default:
        cl(type)
        lab2=this.state.labels.slice(0)
        cl(lab2)
        let parts=type.split("-")
        let cmd=parts[1]
        let id=+parts[0].substr(1)
        switch(cmd){
          case "Delete":
            lab2.splice(id,1)
            this.setState({labels: lab2})
            break
          case "Create":
            lab2.splice(id, 0, {val: 0, edit: false})
          case "OK":
          case "Edit":
            lab2.forEach((l,i)=>{
              if(id==i){
                lab2[i].edit=!lab2[id].edit
              }else{
                lab2[i].edit=false
              }
            })
            this.setState({labels: lab2})
            break
          default:
            break
        }
        break
    }
  }
  
  render(){
    if(this.state.editMode){
      return this.editLabels()
    }else{
      return this.displayLabels()
    }
  }
}

export default Labels00 ;

