import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {cl,globs} from '../../components/utils/utils';

class C18MuiMenuItem extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
    }
  }
  
  render(){
    if(globs.noMui){
      return(
        <option
          value={this.props.value}
        >
          {this.props.children}
        </option>
      )
    }else{
      return React.createElement(MenuItem,this.props)
    }
  }
}
      
export default C18MuiMenuItem;
 
