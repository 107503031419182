import React from 'react';
import C18Select00 from './C18Select00'
import {loadSitesInfo,loadUsersInfo} from './C18utils'
// import C18Input00 from './C18Input00'
// import C18Button00 from './C18Button00'
// import {loadCamerasInfo} from './C18utils'
import {wsTrans,getDatapointName} from '../utils/utils'
import {cl, globs,getTime,constant,getRandomString} 
  from '../../components/utils/utils';

class C18ManageWatch00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded:false,
      siteSel:globs.userData.session.siteId,
      users:[],
    }
    this.loadInfo()
  }
  
  loadInfo=async()=>{
    await loadSitesInfo()
    await loadUsersInfo()
    let query={siteId:this.state.siteSel}
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/watch", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: query})
    resp.data.forEach(wa=>{
      wa.name=getDatapointName(wa.pid,1800)
      wa.en=true
    })
    let notify=[]
    globs.usersInfo.info.forEach(us=>{
      notify.push({name:us.name,en:true})
    })
    let resp2=await wsTrans("usa", {cmd: "cRest", uri: "/s/watchNotify", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: query})
    cl(resp2)
    let notifies=resp2.data[0]?.notifies||[]
    let users=globs.usersInfo.info.map(us=>{
      return {name:us.name,userId:us.userId}
    })
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveWatch)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.setState({loaded:true,watchList:resp.data,notifies:notifies,
      users:users,
    })
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
  onChange=(type,vals)=>{
    cl(type,vals)
    let st=this.state
    switch(type){
      case "watchEn":
        globs.events.publish("savePageEnable",true)
        let watch=st.watchList.slice(0)
        watch[vals.ind].en=vals.en
        this.setState({watchList:watch})
        break
      case "notifyEn":
        globs.events.publish("savePageEnable",true)
        let no=st.notifies
        if(vals.en){
          no.push(st.users[vals.ind].userId)
        }else{
          let ind=no.indexOf(st.users[vals.ind].userId)
          if(ind>=0){no.splice(ind,1)}
        }
        this.setState({notifies:no})
        break
      case "siteSel":
        this.setState(vals)
        break
    }
  }
  
  saveWatchList=async()=>{
    let dels=this.state.watchList.filter(wa=>{return !wa.en}).map(wa=>{return wa.watchId})
//     cl(dels)
    let delObj={watchId:{$in:dels}}
    if(dels.length){
      await wsTrans("usa", {cmd: "cRest", uri: "/s/watch", method: "delete", 
        sessionId: globs.userData.session.sessionId,
        body: delObj})
    }
    let keeps=this.state.watchList.filter(wa=>{return wa.en})
    return keeps
    this.setState({watchList:keeps})
    
  }
  
  saveWatchNotify=async()=>{
    let st=this.state
    cl(st.notifies)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/watchNotify", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId:st.siteSel,notifies:st.notifies}})
  }

  saveWatch=async(cmd)=>{
    cl(cmd)
    if(cmd=="save"){
      let keeps=await this.saveWatchList()
      let notes=await this.saveWatchNotify()
      globs.events.publish("saveOK",true)
    }
  }
  
  showSitesOpts=()=>{
    return globs.sitesInfo.info.map((s,i)=>{
      return(
        <option key={i} value={s.siteId}>{s.name}</option>
      )
    })
  }
  
  showSelectSite=()=>{
    return (
      <div className="custom-select">
        <label htmlFor="">Select Site</label>
        <C18Select00 id=""
          parms={{list:true}}
          value={this.state.siteSel}
          onChange={e=>this.onChange("siteSel",{siteSel: e.currentTarget.value})}
        >
          {this.showSitesOpts()}
        </C18Select00>
        {false&&
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        }
      </div> 
    )
  }
  
  showWatchItem=(wa,i)=>{
//     cl(wa)
    let st=this.state
    return(
      <tr key={i}>
      <td><input type="checkbox"
        checked={wa.en}
        onChange={e=>this.onChange("watchEn",{ind:i,en:e.currentTarget.checked})}
      />
      </td>
      <td>{wa.name}</td></tr>
    )
  }
  
  showWatchList=()=>{
    let st=this.state
    return(
      <div style={{width:300,height:200,borderStyle:"solid",borderWidth:1,borderRadius:10,
        padding:10,overflowY:"auto",
      }}>
      <table><tbody>
        {st.watchList.map((wa,i)=>{
          return this.showWatchItem(wa,i)
        })}
      </tbody></table>
      </div>
    )
  }
  
  showNotifyItem=(no,i)=>{
//     cl(no)
    let st=this.state
    let check=st.notifies.includes(no.userId)
    return(
      <tr key={i}>
      <td><input type="checkbox"
        checked={check}
        onChange={e=>this.onChange("notifyEn",{ind:i,en:e.currentTarget.checked})}
      />
      </td>
      <td>{no.name}</td></tr>
    )
  }
  
  showNotifyList=()=>{
    let st=this.state
    return(
      <div style={{width:300,height:200,borderStyle:"solid",borderWidth:1,borderRadius:10,
        padding:10,overflowY:"auto",
      }}>
      <table><tbody>
        {st.users.map((no,i)=>{
          return this.showNotifyItem(no,i)
        })}
      </tbody></table>
      </div>
    )
  }

  render(){
    let st=this.state
    if(st.loaded){
      return(
        <div>
        {this.showSelectSite()}
        <h3>Watch Points</h3>
        {this.showWatchList()}
        <h3>Notify</h3>
        {this.showNotifyList()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

export default C18ManageWatch00;
