import React from 'react';
import UsaIcon from './UsaIcon';
import UsaSelect00 from './UsaSelect00';
// import UsaSelect00 from './UsaSelect00';
// import Datapoints from './Datapoints';
import {cl} from '../../components/utils/utils';

class Alarms00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
//     var alarms=["HiInTemp", "LoInTemp", "ErrInTemp"]
    var alarms=["AlLo", "AlHi", "ITLo", "ITHi", 
      "ITSn", "AuxA", "TmOt"]
//     var tankAlarms=["HiEC", "LoEC", "HIpH", "LOpH", "ErrEC", "ERRpH", "DevEC", "DEVpH"]
    var tankAlarms=["E1Sn", "E1Sr", "E1Ca", "E2Sn", "E2Sr", "E2Ca", "E3Sn", "E3Sr", "E3Ca", "ECLo", "ECHi", "ECDe", "P1Sn", 
      "P1Sr", "P1Ca", "P2Sn", "P2Sr", "P2Ca", "P3Sn", "P3Sr", "P3Ca", "PhLo", "PhHi", "PhDe"]
    if(props.parms.isTank){
      this.alarms=tankAlarms
    }else{
      this.alarms=alarms.concat(tankAlarms)
    }
    this.alarmOpts={}
    this.alarms.forEach(a=>{this.alarmOpts[a]=a})
    this.state={
      alarm: this.alarms[0],
      enables: Object.assign(props.parms.enables),// enables[userId][alarmId]
      editUsers: false,
    }
    this.userOpts={}// just the user names/ids, not enable values
    props.parms.users.forEach(u=>{
      this.userOpts[u.userId]=u.name
    })
    this.state.alarmUsers=this.getPutAlarms("get", this.state.alarm)
//     this.state=Object.assign(this.getPutAlarms("get"),this.state)
  }
  
  onChange=(type,vals)=>{
//     cl(type)
//     cl(vals)
    switch(type){
      case "alarm":// needs to set state.alarmUsers
//         let userEnables=this.getPutAlarms("get")
        vals=Object.assign({alarmUsers: this.getPutAlarms("get",vals.alarm), editUsers: false}, vals)
//         let alarms=this.getPutAlarms(vals.alarm)
//         cl(vals)
        this.setState(vals)
        break
      case "users":
        switch(vals.result){
          case "Select-OK":
            this.getPutAlarms("put",this.state.alarm, vals.vals)
            break
          case "Select-Cancel":
            break
        }
//         cl(this.state)
        this.setState({editUsers: false})
//         cl(vals)
//         this.setState(vals)
        break
      default:
        break
    }
  }
  
  getPutAlarms=(type, alarmId, enables)=>{
//     let alarmId=this.state.alarm
    switch(type){
      case "put":// put it back in the enables structure
        let curEnables=this.state.enables
        Object.keys(this.userOpts).forEach(k=>{
          curEnables[k][alarmId]=enables[k]
        })
        this.setState({enables: curEnables, alarmUsers: enables})
//         cl(curEnables)
        break
      case "get":
        let userEnables={}
        Object.keys(this.userOpts).forEach(k=>{
          userEnables[k]=this.state.enables[k][alarmId]
        })
//         cl(this.state.enables)
//         cl(userEnables)
//         cl(alarmId)
        return userEnables
      default:
        break
    }
  }
  
  result=(type)=>{
//     cl(type)
    switch(type){
      case "Alarms-OK":
        this.props.parms.onChange({result: type, enables: this.state.enables})
        break
      case "Alarms-Cancel":
        this.props.parms.onChange({result: type})
        break
//       case "Alarm-OK":
//         this.getPutAlarms("put", this.state.alarm)
//         break
      case "Users-Edit":
        this.setState({editUsers: true})
        break
      default:
        break
    }
  }
  
  showSelectUsers=()=>{
//     cl(this.state)
    let enables=this.getPutAlarms("get",this.state.alarm)
    if(this.state.editUsers){
      return(
        <div>
          <UsaSelect00 parms={{
            title: "Select\u00A0Recipients",
            width: 200,
            select: true, // !this.state.editMode,
            multi: true,
            valueId: "users",//valueId, // "widgetSel",
            value: enables,//this.state.alarmUsers, //value, // this.state.widgetSel,
            opts: this.userOpts,
            onChange: (vals)=>this.onChange("users", vals),
          }}/>
        </div>
      )
    }else{
      return(
        <h3>Recipients
            <UsaIcon space="1" icon={`Users-Edit`} result={this.result} inline/>
        </h3>
      )
      
    }
    
  }
  
  render(){
    return(
      <div>
      <h3>{this.props.parms.title}
          <UsaIcon icon={`Alarms-OK`} space="1" result={this.result} inline/>
          <UsaIcon icon={`Alarms-Cancel`} result={this.result} inline/>
      </h3>
        <UsaSelect00 parms={{
          title: "Select\u00A0Alarm",
          width: 200,
          select: true, // !this.state.editMode,
          valueId: "alarm",//valueId, // "widgetSel",
          value: this.state.alarm, //value, // this.state.widgetSel,
          opts: this.alarmOpts,
          onChange: (vals)=>this.onChange("alarm", vals),
        }}/>
        <div style={{height: 10}}/>
      {this.showSelectUsers()}
      </div>
    )
  }
}

export default Alarms00 ;

