import React from 'react';
//  import { Router, Route, Switch } from "react-router-dom";// , Link
//  import {cl, inputField} from '../utils/utils';
//  import {globs} from '../../components/utils/utils';
//  import IconButton from '@material-ui/core/IconButton';
//  import Check from '@material-ui/icons/Check';
//  import Clear from '@material-ui/icons/Clear';
import MainBar from '../../components/MainBar';


class ManageAccess extends React.Component{
  constructor(props) {
    super(props);
    document.title="Manage Access";
}
  
  render(){
    return(
      <div>manageAccess</div>
    );
  }
}

export default ManageAccess ;

