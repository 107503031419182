import React from 'react';
import {cl, globs} from '../../components/utils/utils';
import {init, dbVals} from '../../components/utils/http'
import InTemp from './InTemp';
import EQ00 from './EQ00';
import GenericWidget from './GenericWidget';
import {getParmValue, setParmValue} from '../utils/utils'
import {addDataHook} from '../../components/utils/ws'
import history from '../../history'

class DashboardDisplay extends React.Component{
  constructor(props) {
    super(props);
    document.title="Dashboard Display";
    this.state={
      loaded: false,
    }
//     getParmInfo(0, 0, 0, 4811);
//     cl("dash");
    this.dashArray=// definition of the whole dashboard
    {
      level: "zone", // site, zone, channel
      zone: 0,
      chan: 0,
      indx: 0,
      width: 600,// overall width
      height: 800,
      sx: 100,// count of horiz squares
      sy: 100,
      widgets: [// array of widgets
//         {ix: 0, iy: 1, nx: 2, ny: 1, id: "color", color: "#FFFFCC"},
//         {ix: 2, iy: 1, nx: 1, ny: 1, id: "color", color: "#FFCCCC"},
//         {ix: 0, iy: 0, nx: 1, ny: 1, id: "EQ00",
//           parms:{
//             chan: 1,
//             dpid:{
//               Equipment_Name: 507,
//               Equipment_Type: 508,
//               Equipment_Snapshot_Position: 104,
//             },
// //             type: "EQ00",
// //             name: "Irr 01",
// //             color: "#80CCCC",
// //             pi[1800].channels_configuration["channelName"] = 7
// //             pi[1800].channels_configuration["channelType"] = 8
// //             pi[1800].snapshot_channels["position"] = 4
// //             p.PID_BASE_SNAP_CHANNELS = 100;
// //             p.PID_BASE_CONF_CHANNELS = 500;
// //             width: 200,
// //             height: 200,
//           }
//         },
/*ecph:
   pi[1800].snapshot_ecphs["ec1"] = 3
   pi[1800].snapshot_ecphs["ph1"] = 18
   p.PID_BASE_SNAP_ECPHS = 200;
   
*/

  {ix: 4, iy: 2, nx: 2, ny: 2, id: "generic", color: "#C0FFFF",
    parms:{
      zone: 0,
      chan: 192,
      indx: 0,
      dpid:{
        val: 203,
      },
//       dpUpdates: [203]
      title: "EC",
      bgColor: "#80FFFF",
      dec: 1,// decimal digits to show
      mult: 10,
      note: "Tank 1, Sensor 1",
      min: 0,// these can be taken from the colors array
      max: 2000,
      ticks: [0, 500, 1000, 1500, 2000],
      colors:[
      [0, "#FFFFFF"],
      [200, "#FFFFCC"],
      [400, "#FFFF88"],
      [600, "#FFFF44"],
      [800, "#FFFF00"],
      [1000, "#EEEE00"],
      [1200, "#CCCC00"],
      [1400, "#AAAA00"],
      [1600, "#888800"],
      [1800, "#606000"],
      [2000, "404000"],
      ],
    },
  },
  
/* parameter:
pi[1800].snapshots["inTemperature"] =	23
pi[1800].snapshots["outTemperature"] =	24
pi[1800].snapshots["inHumidity"] =	27
pi[1800].snapshots["outHumidity"] =	28
pi[1800].snapshots["co2"] =	31
pi[1800].snapshots["inLight"] =	32
pi[1800].snapshots["outLight"] =	33
pi[1800].snapshots["windSpeed"] =	34
pi[1800].snapshots["windDirection"] =	35


*/

  {ix: 0, iy: 4, nx: 2, ny: 2, id: "generic", color: "#C0C0FF",
    parms:{
      zone: 0,
      chan: 240,
      indx: 0,
      dpid:{
        val: 27,
      },
      //       dpUpdates: [203]
      title: "inHum",
      bgColor: "#E0E0C0",
      dec: 1,
      mult: 100,
      note: "",
      min: 0,
      max: 100,
      ticks: [0, 20, 40, 60, 80, 100],
      colors:[
      [0, "#C0C000"],
      [10, "#C0C030"],
      [20, "#C0C060"],
      [30, "#C0C090"],
      [40, "#C0C0C0"],
      [50, "#9090C0"],
      [60, "#7070C0"],
      [70, "#4040C0"],
      [80, "#2020C0"],
      [90, "#0000C0"],
      [100, "000080"],
      ],
    },
  },
  
  {ix: 2, iy: 4, nx: 2, ny: 2, id: "generic", color: "#C0C0FF",
    parms:{
      zone: 0,
      chan: 240,
      indx: 0,
      dpid:{
        val: 31,
      },
      //       dpUpdates: [203]
      title: "inCO2",
      bgColor: "#F0E0C0",
      dec: 1,
      mult: 1,
      note: "",
      min: 0,
      max: 2000,
      ticks: [0, 400, 800, 1200, 1600, 2000],
      colors:[
      [0, "#A0A0A0"],
      [200, "#A0B0A0"],
      [400, "#A0B8A0"],
      [600, "#A0C0A0"],
      [800, "#A0D0A0"],
      [1000, "#A0D8A0"],
      [1200, "#60E060"],
      [1400, "#70D070"],
      [1600, "#20E020"],
      [1800, "#00FF00"],
      [2000, "#00FF00"],
      ],
    },
  },
  
  {ix: 4, iy: 4, nx: 2, ny: 2, id: "generic", color: "#C0C0FF",
    parms:{
      zone: 0,
      chan: 240,
      indx: 0,
      dpid:{
        val: 34,
      },
      //       dpUpdates: [203]
      title: "Wind Speed",
      bgColor: "#A0D0E0",
      dec: 1,
      mult: 1,
      note: "",
      min: 0,
      max: 120,
      ticks: [0, 30, 60, 90, 120],
      colors:[
      [0, "#80FF80"],
      [20, "#90E090"],
      [40, "#A0D0A0"],
      [60, "#C0C0C0"],
      [80, "#D0A0A0"],
      [100, "#E08080"],
      [120, "#60E060"],
      ],
    },
  },
  
  {ix: 0, iy: 6, nx: 2, ny: 2, id: "generic", color: "#C0C0FF",
    parms:{
      zone: 0,
      chan: 240,
      indx: 0,
      dpid:{
        val: 32,
      },
      //       dpUpdates: [203]
      title: "inLight",
      bgColor: "#A0D0E0",
      dec: 1,
      mult: 10,
      note: "",
      min: 0,
      max: 2000,
      ticks: [0, 500, 1000, 1500, 2000],
      colors:[
      [0, "#808080"],
      [400, "#A0A060"],
      [800, "#B0B050"],
      [1200, "#C0C040"],
      [1600, "#E0E020"],
      [2000, "#FFFF00"],
      ],
    },
  },
  
  {ix: 2, iy: 6, nx: 2, ny: 2, id: "generic", color: "#C0C0FF",
    parms:{
      zone: 0,
      chan: 240,
      indx: 0,
      dpid:{
        val: 24,
      },
      //       dpUpdates: [203]
      title: "outTemp",
      bgColor: "#A0D0E0",
      dec: 1,
      mult: 1,
      note: "",
      min: 0,
      max: 120,
      ticks: [0, 20, 40, 60, 80, 100, 120],
      colors:[
      [0, "#8080FF"],
      [30, "#A080E0"],
      [50, "#C080C0"],
      [70, "#E0C0A0"],
      [90, "#FF8080"],
      [120, "#FF8080"],
      ],
    },
  },
  
  {ix: 2, iy: 2, nx: 2, ny: 2, id: "generic", color: "#C0C0FF",
    parms:{
      zone: 0,
      chan: 192,
      indx: 0,
      dpid:{
        val: 218,
      },
      //       dpUpdates: [203]
      title: "pH",
      bgColor: "#A0D0E0",
      dec: 1,
      mult: 100,
      note: "Tank 1, Sensor 1",
      min: 0,
      max: 14,
      ticks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      colors:[
      [0, "#C0FFC0"],
      [2, "#C0E0C0"],
      [4, "#C0C0C0"],
      [7, "#E0B0B0"],
      [10, "#F0A0A0"],
      [12, "#FF8080"],
      [14, "#FFFF00"],
      ],
    },
  },
  
  {ix: 0, iy: 2, nx: 2, ny: 2, id: "inTemp", color: "#C0FFFF",
          parms:{
            dpid:{
              Heat_Setpoint: 8,
              Cool_Setpoint: 9,
              Temperature_Units: 4739,
              High_Alarm_Above_Cool_Setpoint: 4751,
              Low_Alarm_Below_Heat_Setpoint: 4755,
              Cool_Stages: 4809,
              Heat_Stages: 4811,
              Stage_Width: 4813,
              Temp_Stage: 6,
              In_Temp: 23,
            },
//             dpUpdates:[8, 9, 4809, 4811, 4813, 6, 23]
//             dpUpdates: [23],
            dataPoints: {
//               Heat_Setpoint: 68 + this.getDBVal(8),
//               Cool_Setpoint: 72,
//               Temperature_Units: 0,
//               High_Alarm_Above_Cool_Setpoint: 10,
//               Low_Alarm_Below_Heat_Setpoint: 10,
//               Cool_Stages: 6,
//               Heat_Stages: 3,
//               Stage_Width: 1.0,
//               Temp_Stage: 6,
//               In_Temp: 77.2,

//               Heat_Setpoint: 8,
//               Cool_Setpoint: 9,
//               Temperature_Units: 4739,
//               High_Alarm_Above_Cool_Setpoint: 4751,
//               Low_Alarm_Below_Heat_Setpoint: 4755,
//               Cool_Stages: 4809,
//               Heat_Stages: 4811,
//               Stage_Width: 4813,
//               Temp_Stage: 6,
//               In_Temp: 23,
            },
            type: "inTemp",
            name: "inTemp",
            icon: "flare",
            width: 200,
            height: 200,
            colors: ["#0000FF", "#0040FF", "#0080FF", "#00C0FF", "#00FFFF", "#00FFC0", "#00FF80", "#00FF40", "#00FF00", "#40FF00", 
              "#80FF00", "#C0FF00", "#FFFF00", "#FFC000", "#FF8000", "#FF4000", "#FF0000"],
          },
        },
      ]
    }
    this.addEqWidgets(this.dashArray.widgets)
    this.initData().then(r=>{
      this.getAllData()
      this.setState({loaded: true})
//       let da=this.dashArray
//       let id=203
//       let upd={wdg: da.widgets[0], id: id, isRender: false, f: this.updateValue}
// //       cl(upd)
//       addDataHook(upd, da.zone, 192, id)// temporary testing location!!!
    })
//     var addDataHook=(upd, z, c, i)=>{
      
//     this.refreshTimer=setInterval(this.refreshPage, 2000)
//     setInterval(this.testUpdates, 5000)
  }
  
  componentWillUnmount() {
    cl("unmount dashboard")
  }
  
  refreshPage=()=>{
//     cl("refresh")
//     cl(history.length)
//     cl(history.location.pathname)
    if(history.location.pathname=="/usa/dashboard"){
      history.push("/usa/dashboard")
    }else{
      clearInterval(this.refreshTimer)
    }
//     cl(history[history.length-1])
//     history.push("/usa/dashboard")
  }
  
  getAllData=()=>{

    this.dashArray.widgets.forEach(w=>{
      if(w.parms) this.setDatapoints(w)
    });
  }
  
  initData=()=>{
    return init()
//     init().then(r=>{
//       cl(r)
//       cl(dbVals)
//     });
  }
  
  addEqWidgets=(widgets)=>{
//     let widgets=[];
    for(let i=0; i<12;i++){
      widgets.push(
        {ix: i%6, iy: Math.floor(i/6), nx: 1, ny: 1, id: "EQ00",
          parms:{
            chan: i,
            dpid:{
              Equipment_Name: 507,
              Equipment_Type: 508,
              Equipment_Snapshot_Position: 104,
            },
          }
        }
      )
    }
  }
  
  setDatapoints=(wdg)=>{
//     cl("set data")
    if(!wdg.parms.dpid) return
    let parms=wdg.parms
//     cl(wdg.parms.chan)
    let z=this.dashArray.zone
    let c=(parms.chan) ? parms.chan : this.dashArray.chan
    let ix=this.dashArray.indx
    parms.zci=[z, c, ix]
//     cl(parms.dpid)
    let dp2={}
    for(let key in parms.dpid){
      let id=parms.dpid[key]
      dp2[key]=getParmValue(...parms.zci, id)
      let upd={wdg: wdg, id: id, isRender: false, f: this.updateValue}
      addDataHook(upd, z, c, id)// temporary testing location!!!
      
//       if(id==23){
//         dp2[key]=72+Math.random()*5
// //         cl([z, c, ix, id, dp2[key]])
//       }
    }
    if(parms.dpUpdates){
/* the parameters that we want to update from are listed here. when an update occurs, we need to know
which widget it applies to. Each low-level component will register with functions to call for both
a value change, and a format (complete re-render) change. The *first* value in the dpUpdates array is the value
when we get the update callback, it needs to identify the widget, the valueId, and the new value
have an object that is passed down as a prop, that contains the routines to call on updates, so
each widget can add a render and an update entries
when we get an update, it will 
First, note that we should do a lazy update, delayed by a 100ms or so, to collect the updates together
to do the update, we need to fill in the values locally, do the same dpid to datapoint operation, then decide if it's a
value update or a full rerender. the first element in the dpid array is the value
this data has to pass through the generic or inTemp widgets
all of the database
the gauge component needs a definite update or render command, *not* a setState
each widget could have an index
It seems that the message should be handled down to the generic widget, and *then* down to the gauge component
So, update our values, in wdg.parms, then call the widget for update or render, 
then the widget will update its values, and call the gauge for update or render
for now, I would just have an update function in the object that is given to the widget, that is filled in *by*
the widget in its constructor

When we get an update, it will come with wdg, paramId, and update/render flag
*/
//       wdg.parms.dpUpdates.forEach(upd=>{
//         cl(upd)
//       })
    }
//     dp2.In_Temp=75.4
//     cl(dp2)
//     let v = getParmValue(z, c, ix, 8)// get heat setpoint
//     cl(v)
//     cl(wdg)
//     let dp={
//       Heat_Setpoint: 68,// + this.getDBVal(8),
//       Cool_Setpoint: 72,
//       Temperature_Units: 0,
//       High_Alarm_Above_Cool_Setpoint: 10,
//       Low_Alarm_Below_Heat_Setpoint: 10,
//       Cool_Stages: 6,
//       Heat_Stages: 3,
//       Stage_Width: 1.0,
//       Temp_Stage: 6,
//       In_Temp: 77.2,
//     }
    parms.dataPoints=dp2
//     cl(dp)
  }
  
  updateValue=(upd)=>{// fields: wdg, id, isRender, zci
//     if(upd.wdg.parms.chan == 7){
//       cl(upd.wdg.parms)
//     }
//     cl(upd.wdg)
    let wdg=upd.wdg
    let paramId=upd.id
    let isRender=upd.isRender
//     cl(paramId)
//     cl([wdg, paramId, isRender])
//     return
// this is called when a value changes
    for(let key in wdg.parms.dpid){
      let id=wdg.parms.dpid[key]
      if(paramId==id){
//         cl(paramId)
        wdg.parms.dataPoints[key]=getParmValue(...wdg.parms.zci, id)// updates our value
        if(wdg.update)wdg.update(wdg, paramId, isRender)// calls the widget to update
//         cl(wdg.parms.dataPoints[key])
        return
      }
    }
  }
  
  showGeneric=(x, y, w, h, wdg, i)=>{
    let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i};
    return(
      <GenericWidget key={i} parms={parms}/>
    );
  }
  
  showInTemp=(x, y, w, h, wdg, i)=>{
//     cl("intemp");
    let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i};
    return(
        <InTemp key={i} parms={parms}/>
    );
  }
  
  showEQ00=(x, y, w, h, wdg, i)=>{
    let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i};
    return(
      <EQ00 key={i} parms={parms}/>
    );
  }
  
  showColor=(x, y, w, h, wdg, i)=>{
    return(
      <div key={i} style={{position: "absolute", left: x, top: y, width: w, height: h, backgroundColor: wdg.color}}>
      </div>
    );
  }
  
  showAWidget=(dash, wdg, i)=>{
    let widgets={
      "color": this.showColor, 
      "inTemp": this.showInTemp, 
      "generic": this.showGeneric, 
      "EQ00": this.showEQ00}
    let func=widgets[wdg.id];
    return func(dash.sx*wdg.ix, dash.sy*wdg.iy, dash.sx*wdg.nx, dash.sy*wdg.ny, wdg, i);
  }
  
  showWidgets=(dash, widgets)=>{
    return(
      <div>
      {this.state.loaded &&
        dash.widgets.map((w,i)=>{return this.showAWidget(dash, w, i)})}
      </div>
    );
  }
  
  render() {
//     cl("render")
//     if(this.state.loaded) this.getAllData()
    return(
      <div style={{
        position: "relative",
        width: this.dashArray.width, 
        height: this.dashArray.height, 
        backgroundColor: "lightBlue"}}>
      {this.showWidgets(this.dashArray)}
      </div>
    );
  }
}

export default DashboardDisplay;
