import * as base64 from 'base-64';
import {wsTrans} from '../../usa/utils/utils'
import {globs} from '../../components/utils/utils'


var cl = console.log

var callStack= function(){
    var orig = Error.prepareStackTrace;
    Error.prepareStackTrace = function(_, stack){ return stack; };
    var err = new Error;
    Error.captureStackTrace(err, arguments.callee);
    var stack = err.stack;
    Error.prepareStackTrace = orig;
    return stack;
  }
  
var checkLoggedIn=async()=>{
  let str=localStorage.getItem("session")
  let session=JSON.parse(str)
  let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/users/login", method: "retrieve", 
    body: {sessionId: session.sessionId}})
  if(res.result){
    globs.userData={session:res.session,loggedIn:true}
    return true
  }else{
    localStorage.removeItem("session")
    return false
  }
}

export {
  cl,checkLoggedIn,
  
}
