import React from 'react';
import UsaSelect00 from './UsaSelect00'
import UsaArrayFlags00 from './UsaArrayFlags00'
import TextField from '@material-ui/core/TextField';
import UsaCheckbox00 from './UsaCheckbox00';
import UsaIcon from './UsaIcon';
import {cl} from '../../components/utils/utils';

/*
types:
Every N days {type: "ndays", n: 3}
every n weeks, On these WeekDays: 7 flags {type: "nweeks", n: 1, weekdays: [0, 3]}// sunday, wednesday
every n months, On these MonthDays: 31 flags {type: "nmonths", n: 1, monthdays: [7, 14, 21]}// every month on the 7th, 14th, 21st
every n months on nth (day of the week) {type: "nmonthweek", n: 1, week: 2, day: 3}// every month, on the 2nd wednesday
every n years on (day of the year) {type: "nyears", n:1, month: 2, day: 5}// every year on the 5th of Feb
every n years on nth (day of the week) in (month) {type: "nyearmonthweek", n: 1, month: 2, week: 3, day: 3}// every year on the 3rd wednesday of february

Daily,
Weekly, Some Days
Monthly, Some Days
Monthly, on a Week and Day
Yearly, Some Day
Yearly, on a Month, Week, and Day
*/

class UsaRepeat00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded: false,
      type: "none",
      n: 1,
    }
  }
  
  onChange=(type,vals)=>{
//     cl(vals)
    cl(type)
    cl(vals)
    switch(type){
      case "type":
        switch(vals.type){
          case "daily":
            vals=Object.assign({
              n: 1,
            },vals)
            this.props.parms.onChange(vals)
            break
          case "weekly":
            cl("do week")
            vals=Object.assign({
              week: [false,false,false,false,false,false,false],
              n: 1,
            },vals)
            this.props.parms.onChange(vals)
            break
          case "monthly":
            cl("do monthly")
            let flags=[]
            for(let i=0; i<31; i++){flags.push(false)}
            vals=Object.assign({
              month: flags,
              n: 1,
            },vals)
            this.props.parms.onChange(vals)
            break
          default:
            break
        }
        break
      case "parts":
        this.props.parms.onChange(vals)
        break
      default:
        break
    }
//     cl(type)
//     cl(vals)
    this.setState(vals)
  }
  
  dailyEnter=()=>{
    return(
      <div>
      Every
      <UsaSelect00 parms={{
        title: "Day\u00A0Count",
        baseline: true,
        type: "number",
        width: 40,
        inline: true,
        valueId: "n",//valueId, // "widgetSel",
        value: this.state.n, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("parts", vals),
      }}/>
      {(this.state.n==1)?"Day":"Days"}
      </div>
    )
  }
  
  weeklyEnter=()=>{
    let weeklyOpts=["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    return(
      <div>
      Every
      <UsaSelect00 parms={{
        title: "Day\u00A0Count",
        baseline: true,
        type: "number",
        width: 40,
        inline: true,
        valueId: "n",//valueId, // "widgetSel",
        value: this.state.n, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("parts", vals),
      }}/>
      {(this.state.n==1)?"Week":"Weeks"}
      <div style={{height: 10}}/>
      on these days:
      <UsaArrayFlags00 parms={{
        inline: true,
        opts: weeklyOpts,
        valueId: "week",
        value: this.state.week,
        onChange: (vals)=>this.onChange("parts", vals),
      }}/>
      </div>
    )
  }
  
  monthlyDaysEnter=()=>{
    let monthlyOpts=[]
    for(let i=1; i<32; i++){monthlyOpts.push(i)}
    return(
      <div>
      Every
      <UsaSelect00 parms={{
        baseline: true,
        type: "number",
        width: 40,
        inline: true,
        valueId: "n",//valueId, // "widgetSel",
        value: this.state.n, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("parts", vals),
      }}/>
      {(this.state.n==1)?"Month":"Months"}
      <div style={{height: 10}}/>
      on these days:
      <UsaArrayFlags00 parms={{
        inline: true,
        opts: monthlyOpts,
        columns: 7,
        valueId: "month",
        value: this.state.month,
        onChange: (vals)=>this.onChange("parts", vals),
      }}/>
      </div>
    )
  }
  
  monthlyWeekDayEnter=()=>{
    return(
      <div>monthlyWeekDayEnter</div>
    )
  }
  
  yearlyEnter=()=>{
    return(
      <div>yearlyEnter</div>
    )
  }
  
  yearlyMonthDayEnter=()=>{
    return(
      <div>yearlyMonthDayEnter</div>
    )
  }
  
  enterTypeData=(type)=>{
//     cl(type)
    switch(type){
      case "daily":
        return this.dailyEnter();
      case "weekly":
        return this.weeklyEnter();
      case "monthly":
        return this.monthlyDaysEnter();
      case "monthlyWeekDay":
        return this.monthlyWeekDayEnter();
      case "yearly":
        return this.yearlyEnter();
      case "yearlyMonthDay":
        return this.yearlyMonthDayEnter();
      default:
        return null
      
    }
  }

  typeEnter=()=>{
    let typeOpts={
      none: "One Time Only",
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
      monthlyWeekDay: "Monthly, on a Week and Day",
      yearly: "Yearly",
      yearlyMonthDay: "Yearly, on a Month, Week, and Day",
      
    }
    return(
    <UsaSelect00 parms={{
      title: "Type",
      select: true, // !this.state.editMode,
      valueId: "type",//valueId, // "widgetSel",
      value: this.state.type, //value, // this.state.widgetSel,
      opts: typeOpts,
      onChange: (vals)=>this.onChange("type", vals),
    }}/>
    )
  }
  
  render(){
    return (
      <div>
        <h3>Repeat Pattern</h3>
        {this.typeEnter()}
        <div style={{height: 10}}/>
        {this.enterTypeData(this.state.type)}
      </div>
    )
  }
}

export default UsaRepeat00 ;

