import React from "react";
import {cl,globs,allTimezones} from "../../components/utils/utils";
import {wsTrans} from '../../usa/utils/utils'
import C18Select01 from '../../usa/components/C18Select01'
// import C18MuiInput from '../../usa/components/C18MuiInput'
// import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
// import {ArrayExpression as state} from "@babel/types";

class Misc800 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
//     cl(globs.zonesInfo)
    let modes={"1002":"controllerName","1006":"timeZone"}
    this.mode=modes[props.cont.pid]
//     cl(this.mode)
    this.show={
      controllerName:this.showControllerName,
      timeZone:this.showTimeZone,
    }
    this.state={
      loaded:false
    }
    this.props.getValue(props.ind, {type: "scalar"})
    this.loadInfo()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
  }

  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }

  savePageEvent=(cmd)=>{
    this.saveMisc800Data(cmd)
  }

  saveMisc800Data=(cmd)=>{
//     cl(cmd)
//     cl(this.props)
    let st=this.state
    if(this.mode=="controllerName"){}
    let body1={controllerId:st.controllerId}
    let body2={zoneId:this.zone.zoneId}
    let isNew=false
    switch(this.mode){
      case "controllerName":
        body1.controllerName=st.zoneName
        body2.zoneName=st.zoneName
        isNew=st.zoneName!=this.zone.zoneName
        break
      case "timeZone":
        body1.time_zone=st.time_zone
        body2.time_zone=st.time_zone
        isNew=st.time_zone!=this.zone.time_zone
        break
    }
    if(isNew){
      body1.controllerId=st.controllerId
      wsTrans("usa", {cmd: "cRest", uri: "/s/iGrow800Controller", method: "update",
        sessionId: globs.userData.session.sessionId, body: body1})
      wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update",
        sessionId: globs.userData.session.sessionId, body: body2})
    }
//     cl(st)
    setTimeout(e=>globs.events.publish("saveOK",true), 1000)
//     globs.events.publish("saveOK",true)
  }

  loadInfo=()=>{
    let pr=this.props
    this.zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==pr.current.zoneId})[0]
    this.state.zoneName=this.zone.zoneName
    this.state.controllerId=this.zone.controllerId
    this.state.time_zone=this.zone.time_zone
  }

  onChange=async(type,vals)=>{
    cl(type,vals)
    switch(type){
      case "upd":
        globs.events.publish("savePageEnable",true)
        let keys=Object.keys(vals)
        this.props.onChange(this.props.ind,
          {value: vals[keys[0]]})
        await this.setState(vals)
//         cl(this.state)
        break
      default:
        break
    }

  }

  showControllerName=()=>{
    let st=this.state
//     cl(st)
    return(
      <div>
      <label>Controller Name</label>
      <input
        style={{padding:"10.5px 12px",borderRadius:10}}
        value={st.zoneName}
        onChange={e=>this.onChange("upd",{zoneName:e.currentTarget.value})}
      />
      </div>
    )
  }

  showTimeZone=()=>{
    let st=this.state
    let timeZones=allTimezones.map(tz=>{return(
      {v:tz,t:tz}
    )})
//     cl(st)
    return(
      <div>
        <C18Select01 parms={{
          label:"Time Zone",
          valueName:"time_zone",
          time_zone:st.time_zone,
          opts:timeZones,
          onChange:this.onChange,
        }}/>
      </div>
    )
  }

//   showTimeZone=()=>{
//     return(
//       <div>
//         Time Zone
//       </div>
//     )
//   }

  render() {
    return(
      <div>
        <div className="clearfloat"></div><br/>
        {this.show[this.mode]()}
      </div>
    )
//       return(
//         <div>
//           "misc 800"
//         </div>
//       )
  }
}

export default Misc800 ;
