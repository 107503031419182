import React from 'react';
import UsaSelect00 from './UsaSelect00';
// import TextField from '@material-ui/core/TextField';
// import UsaCheckbox00 from './UsaCheckbox00';
// import UsaIcon from './UsaIcon';
import {cl,az} from '../../components/utils/utils';
import {colorSquare} from '../utils/utils';

class UsaColorPicker00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={editingMode: false}
    this.state[props.parms.valueId]=props.parms.value
//     cl(props);
  }
  
  editColor=()=>{
    return(
      <div>
        <div style={{height: 10}}/>
        <table><tbody>
        <tr>
        <td>
          <UsaSelect00 parms={{
            title: this.props.parms.title,
            width: 100,
            inline: true,
            value: this.state[this.props.parms.valueId],//this.state.bgColor,
            valueId: this.props.parms.valueId,
            onChange: o=>this.setState(o),
          }}/>
        </td>
        <td>
          {colorSquare(this.state[this.props.parms.valueId], 50, 1, this.onClick)}
        </td>
        </tr>
        </tbody></table>
        <div style={{height: 10}}/>
      </div>
    )
  }
  
  onClick=()=>{
    if(this.state.editingMode){
      let val=[];
      val[this.props.parms.valueId]=this.state[this.props.parms.valueId]
      cl(val)
      this.props.parms.onChange(val);
    }
    this.setState({editingMode: !this.state.editingMode})
  }
  
  displayColor=()=>{
    return(
      <div style={{verticalAlign: "text-top"}}>
        <div style={{height: 10}}/>
        {this.props.parms.title}:
        {colorSquare(this.state[this.props.parms.valueId], 50, 1, this.onClick)}
        <div style={{height: 10}}/>
      </div>
    )
  }
 
  render(){
    return(
      (this.state.editingMode)?this.editColor():this.displayColor()
    )
  }
}

export default UsaColorPicker00 ;

