import React from 'react';
import C18Anchor00 from './C18Anchor00'
import {wsTrans} from '../utils/utils'
import {loadUsersInfo} from './C18utils'
import {cl,globs,constant,dateToDisplayDate} from '../../components/utils/utils';
import history from "../../history"

class C18CropRecipe00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      sortMode:-1,
      loaded:false,
      recipes:[{
          name:"Purple Kush",
          description:"classic indica strain",
          cropRecipeId:"purpleKush"
        },
        {
          name:"Train Wreck",
          description:"Sativa-dominant hybrid strain",
          cropRecipeId:"trainWreck"
        },
      ]
    }
    this.setBreadCrumbs()
    this.loadInfo()
    this.doLoadMessages=setInterval(this.doLoadMessages,10000)
  }
  
  
  setBreadCrumbs=()=>{
//     cl(this.props.parms)
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Messages", url:`/usa/c18/messaging/messageList`},
            ]},
        },
      )
    }
  }
  
  loadInfo=async()=>{
    let res= await wsTrans("usa", {cmd: "cRest", uri: "/s/cropRecipes", 
      sessionId: globs.userData.session.sessionId, 
      method: "retrieve", body: {}})
//     cl(res)
    this.setState({recipes:res.data,loaded:true})
    
//     await loadUsersInfo()
//     this.userArr={}
//     globs.usersInfo.info.forEach(ui=>{this.userArr[ui.userId]=ui})
//     let messages=await this.loadMessages()
//     this.setState({loaded:true,messages:messages})
  }
  
  onChange=(type,vals)=>{
    cl(type,vals)
    switch(type){
      case "create":
        vals.e.preventDefault()
        history.push("/usa/c18/cropRecipes/phases")
//         cl("create recipe")
        break
      case "selectRecipe":
        history.push(`/usa/c18/cropRecipes/${vals.cropRecipeId}/phases/`)
//         cl(vals)
        break
    }
  }
  
  showRecipes=()=>{
    let st=this.state
    let banding=globs.usersInfo.uiInfo?.tableBanding||true
    let lines=st.recipes.map((re,i)=>{
      let tableBand=(((i+1)%2)&&banding)?"tableBand":null
      let desc=re.description
      if(desc.length>15){desc=desc.substring(0,12)+"..."}
      return(
        <tr 
          className={tableBand} 
          key={i}
          style={{cursor:"pointer"}}
          onClick={e=>this.onChange("selectRecipe",{cropRecipeId:re.cropRecipeId})}
        >
        <td>{re.name}</td>
        <td>{desc}</td>
        </tr>
      )
    })
    return(
      <table><tbody>
      {lines}
      </tbody></table>
    )
  }
  
  render(){
    if(this.state.loaded){
      return(
        <div>
          <div className="section-controls floatright">
            <C18Anchor00 to="" onClick={(e)=>this.onChange("create", {e:e})} className="material-icons-outlined add" aria-label="add recipe">
              add
            </C18Anchor00>
          </div>
          <div className="clearfloat"/><br/>
          {this.showRecipes()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18CropRecipe00
