import React from 'react';
import {cl, constant,globs} from '../../components/utils/utils';

class Section extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.props.getValue(props.ind, {type: "local"})
  }

  render(){
    return null
//     let cust=this.props.custom
//     if(cust.image){
//       let path=`${constant.expressUrl}/usa/images/uploads/${cust.image}`
// //       cl(path)
//       let className=(cust.floatRight)?"floatright":""
//       return(
//         <div className={className}>
//         <img src={path} width={cust.width}/>
//         </div>
//       );
//     }else{
//       return null
//     }
  }
}

  export default Section ;
