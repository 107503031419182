import React from 'react';
import UsaSelect00 from '../UsaSelect00'
import {cl} from '../../../components/utils/utils';

const verticalSpace=<div style={{height: 10}}/>

class RepeatYearDay extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      years: 1,
//       week: 1,
      day: 2,
      month: 2,
    }
    this.repeat=this.props.parms.repeat
  }
  
/* Every n years, repeat on the 13th day of March*/
  
  onChange=(vals)=>{
//     cl(this.props)
    let s=Object.assign(this.state, vals)
    this.repeat={
      period: s.years,
      details: s.day | (s.month << 8)
    }
    this.setState(vals)
    this.props.parms.onChange({repeat: Object.assign({},this.repeat)})
  }
  
  render(){
    let monthDayOpts={
      1:{t:"1st"},
      2:{t:"2nd"},
      3:{t:"3rd"},
      4:{t:"4th"},
      5:{t:"5th"},
      6:{t:"6th"},
      7:{t:"7th"},
      8:{t:"8th"},
      9:{t:"9th"},
      10:{t:"10th"},
      11:{t:"11th"},
      12:{t:"12th"},
      13:{t:"13th"},
      14:{t:"14th"},
      15:{t:"15th"},
      16:{t:"16th"},
      17:{t:"17th"},
      18:{t:"18th"},
      19:{t:"19th"},
      20:{t:"20th"},
      21:{t:"21st"},
      22:{t:"22nd"},
      23:{t:"23rd"},
      24:{t:"24th"},
      25:{t:"25th"},
      26:{t:"26th"},
      27:{t:"27th"},
      28:{t:"28th"},
      29:{t:"29th"},
      30:{t:"30th"},
      31:{t:"31st"},
    }
    let monthOpts={
      1:{t:"January"},
      2:{t:"February"},
      3:{t:"March"},
      4:{t:"April"},
      5:{t:"May"},
      6:{t:"June"},
      7:{t:"July"},
      8:{t:"August"},
      9:{t:"September"},
      10:{t:"October"},
      11:{t:"November"},
      12:{t:"December"},
    }
//     let dayOpts={
//       0:{t:"Sunday"},
//       1:{t:"Monday"},
//       2:{t:"Tuesday"},
//       3:{t:"Wednesday"},
//       4:{t:"Thursday"},
//       5:{t:"Friday"},
//       6:{t:"Saturday"},
//     }
//     let ordinalOpts={
//       0:{t:"First"},
//       1:{t:"Second"},
//       2:{t:"Third"},
//       3:{t:"Fourth"},
//       4:{t:"Fifth or Last"},
//     }
    return(
      <>
      <UsaSelect00 parms={{
        title: "Repeat\u00A0Every",
        footer: "Years",
        footerPlural: true,
        type: "number",
        min: 1,
        width: 50,
        select: false, // !this.state.editMode,
        valueId: "years",//valueId, // "widgetSel",
        value: this.state.years, //value, // this.state.widgetSel,
        onChange: this.onChange,
      }}/>
      {verticalSpace}
      <UsaSelect00 parms={{
        title: "On\u00A0The",
        footer: "Day Of",
//         type: "number",
//         width: 50,
        select: true, // !this.state.editMode,
        opts: monthDayOpts,
        valueId: "day",//valueId, // "widgetSel",
        value: this.state.day, //value, // this.state.widgetSel,
        onChange: this.onChange,
      }}/>
      {verticalSpace}
      <UsaSelect00 parms={{
        title: "The\u00A0Month\u00A0Of",
//         footer: "Week of each Month",
//         type: "number",
//         width: 50,
        select: true, // !this.state.editMode,
        opts: monthOpts,
        valueId: "month",//valueId, // "widgetSel",
        value: this.state.month, //value, // this.state.widgetSel,
        onChange: this.onChange,
      }}/>
      </>
    )
  }
}

export default RepeatYearDay ;

