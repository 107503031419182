import React from 'react';
import C18Anchor00 from './C18Anchor00'
import {loadAccountInfo,loadSitesInfo,loadZonesInfo,loadSiteData,getZoneInfo,loadPrivsInfo,getReportsIndex,loadReportsInfo,
  loadSchedulesInfo,getSchedulesIndex,privs,loadUser,getZoneId,addToTracking
} from './C18utils'
import {cl, globs, initInfos} from '../../components/utils/utils';
import history from "../../history"

class C18Logout00 extends React.Component{
  constructor(props) {
    super(props);
    document.title="Logout";
    this.loadInfo()
  }
  
  loadInfo=()=>{
    loadPrivsInfo()
  }
  
  render(){
    return(
      <div>
      <div className="login-register-new">
        <div className="login-left">
        </div>
        <div className="login-right">

        <header>
          <div className="main-logo floatleft">
            <a href="https://link4controls.com/" className="main-logo"><img src="/img/link4_logo_blue_trans.png" alt="Link4 Controls"/></a>
          </div>
          <div className="floatright">
            Don't have an account? <C18Anchor00 id="back-to-login" to="/usa/c18/register">Register</C18Anchor00>
          </div>
        </header>

          <form>
            <h1>You Are Logged Out</h1>

            <div className="button-container">
              <button type="button" className="outlined"
              onClick={()=>{history.push("/usa/c18/login")}}
              >Log In</button>
            </div>

          </form>

          <div id="footer" className="login">
            <p>Cloud-Based Environmental Controls</p>
            <p>Copyright &copy;2021 Link4 Corporation. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

export default C18Logout00;
