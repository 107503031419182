import React from 'react';
import C18Button00 from './C18Button00'
import {loadUser} from './C18utils';
// import C18Anchor00 from './C18Anchor00'
// import C18Select00 from './C18Select00'
// import ReactHtmlParser from 'react-html-parser'
// import C18SubMenuHeader00 from './C18SubMenuHeader00'
import {wsTrans} from '../utils/utils'
import {cl,globs,constant,getRandomString} from '../../components/utils/utils';

class C18DevHome00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded:true,
      gatewayId:getRandomString(16),
    }
    this.loadInfo()
    this.setBreadCrumbs()
  }
  
  componentWillUnmount=()=>{
    this.subscribe_keyDownEvent.remove()
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Home", url:"/usa/c18/home"},
            ]},
        },
      )
    }
  }
  
  loadInfo=async()=>{
//     cl(globs.user)
//     await loadUser()
//     cl(globs.user)
  }
  
  createCreds=async()=>{
    let st=this.state
    let creds=await wsTrans("usa", {cmd: "cRest", uri: "/s/iotCreds", method: "create", 
      sessionId: globs.userData.session.sessionId, body: {
        iotId:globs.user.apiKey.substring(0,16),
        gatewayId:st.gatewayId,
      }})
  }
  
  onChange=(type,vals)=>{
    cl(type,vals)
    switch(type){
      case "createCreds":
        this.createCreds()
//         cl(type)
        break
    }
  }
  
  render(){
    let st=this.state
//     let [searchId,type,field1,field2,itemId,selId]=this.pageParms[st.pageType]
    if(st.loaded){
      let iotId=globs.user.apiKey.substring(0,16)
      let pub=`sensors/${iotId}/${iotId}/${st.gatewayId}/u0/c`
      let sub=`sensors/${iotId}/${iotId}/${st.gatewayId}/d0/c`
      return(
        <div>
        <table><tbody>
        <tr><td width="100">Your Iot ID:</td><td>{iotId}</td></tr>
        <tr><td width="100">Gateway ID:</td><td>{st.gatewayId}</td></tr>
        <tr><td width="100">Publish to:</td><td>{pub}</td></tr>
        <tr><td width="100">Subscribe to:</td><td>{sub}</td></tr>
        </tbody></table>
        <div className="clearfloat"></div><br/>
        <C18Button00
        className="filled" type="button"
        onClick={e=>this.onChange("createCreds")}
        >
        Create Creds
        </C18Button00>
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18DevHome00;
