import React from 'react';
import C18InputError00 from './C18InputError00'
import C18Anchor00 from './C18Anchor00'
import {wsTrans, saveTokens} from '../utils/utils'
import {cl,globs,constant} from '../../components/utils/utils';
import history from "../../history"

class C18ResetPassword00 extends React.Component{
  constructor(props) {
    super(props);
    document.title="Reset Password";
    this.state={
      password1:"",
      password2:"",
      alertId:0,
    };
     this.alerts=[
      "Password is Too Short",//0
      "Passwords Don't Match",//1
      "What a Super-Duper Password!",//2
      "Checking . . .",//3
      "Password is Too Common ",//4
      "",//5
      "Password successfully changed - taking you to Sites!",//5
     ];
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "reset":
//         cl("reset")
//         cl(this.props)
        this.forgotPassword(this.props.token)
        // send user back to login page?
        
        break
      case "visibility":
        let vals2={}
        vals2[vals.id]=!this.state[vals.id]
        cl(vals2)
        this.setState(vals2)
      default:
        let state=Object.assign({},this.state,vals)
        this.validate(state)
        this.setState(vals)
        break
    }
  }
  
  
forgotPassword=async(token)=>{
//   cl(token);
  let body={password: this.state.password1, token: token};
//   cl(body)
  let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/forgot", method: "retrieve", body: {password: this.state.password1, token: token}})
//   cl(res)
  cl(res)
  saveTokens("", "", res.data.session)

  this.setAlert(6)
  setTimeout(()=>{ 
    // push history to c18host so nav state changes
    history.push("/usa/c18/sites")
    // window.location="/usa/c18/sites"
  },1000)
  
//   saveTokens(res.data.accessToken, res.data.refreshToken, res.data.session)
    // history.push('/usa/c18/sites');
}

  checkFields=async(state)=>{
    this.timeoutIF = null;
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/users/unique", method: "retrieve", 
      body: {companyName: "", email: "", password: state.password1}})
    cl(res)
//     if(!res.uc && (this.state.alertId > 2)) return this.setAlert(2);// company name used
//     if(!res.ue && (this.state.alertId > 5)) return this.setAlert(5);// email used
    if(!res.up && (this.state.alertId > 2)) return this.setAlert(4);// password fails
    this.setAlert(5)
//     if(this.state.alertId === 12) this.setAlert(14);
  }
   
  validate=(state)=>{
    if(state.password1.length < 6) return this.setAlert(0);
    if(state.password1 !== state.password2) return this.setAlert(1);
    if(state.password1=="password") return this.setAlert(2)
    this.checkFields(state)
    return this.setAlert(3);
  }
  
  setAlert=(n)=>{
    let state=this.state
    var passwordError
    switch(n){
      default:
        passwordError=this.alerts[n]
        break
        
//       case 0:
//         emailError=this.alerts[0]
//         break
    }
    this.setState({
      passwordError: passwordError,
      alertId:n,
    });
  }
  
  render(){
//     cl(this.state)
    return(
      <div>
        <div class="login-register-new">
          <div class="login-left">
          </div>

          <div class="login-right">

          <header>
            <div class="floatleft">
            <a href="https://link4controls.com/" class="main-logo"><img src="/img/link4_logo_blue_trans.png" alt="Link4 Controls logo"/></a>
            </div>
          </header>

            <form>
              <h1>Reset Password</h1>

              <C18InputError00 parms={{
                type:'password',
                visible:this.state.password1Visible,
                title:"Password",
                id:"password1Visible",
                required: true,
                value: this.state.password1,
                valueId: "password1",
                onChange: this.onChange,
                message:this.state.passwordError,
                messageType: "error",
                disabled:this.state.loggedIn,
              }}/>
              <C18InputError00 parms={{
                type:'password',
                visible:this.state.password2Visible,
                title:"Confirm Password",
                id:"password2Visible",
                required: true,
                value: this.state.password2,
                valueId: "password2",
                onChange: this.onChange,
                message:this.state.passwordError2,
                messageType: "error",
                disabled:this.state.loggedIn,
              }}/>
              <div class="clearfloat"></div>

              <br />

              <div class="button-container">
                <button type="button" id="login-button" className="filled"
                disabled={(this.state.alertId)<5 && (this.state.alertId!=2)}
                onClick={e=>this.onChange("reset",{})}
                >Reset Password</button>
              </div>

            </form>
          </div>
        </div>

        <div id="footer">
          <p>Copyright and other misc stuff</p>
        </div>
      </div>
    )
  }
}

export default C18ResetPassword00 ;
