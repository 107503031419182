import React from 'react';
// import {webSocketTransaction, initWebSocket} from '../utils/utils';
import {wsTrans, saveTokens } from '../utils/utils';
import {cl, globs} from '../../components/utils/utils';
import Alert from '@material-ui/lab/Alert';
import Password from './Password';
import OkCancel from './OkCancel';
import MainBar from '../../components/MainBar';
import history from "../../history"

 

class ResetPassword extends React.Component{
  constructor(props) {
    super(props);
    document.title="Reset Password";
    cl(globs.npi);
    this.state={
      password1: "",
      password2: "",
      alertSeverity: "info",
      alertText: "Please Enter a new Password",
  };
  }
  
checkPasswords=(id, value)=>{
  let pass1=this.state.password1;
  let pass2=this.state.password2;
  if(id == "password1"){
    pass1=value;
  }else{
    pass2=value;
  }
  var msg;
  if (pass2 == ""){
    msg="Please Enter a New Password";
  }else{
    msg=(pass1===pass2) ? "Click OK to Set New Password" : "Passwords don't Match";
  }
  this.setState({alertText: msg});
  
    
}
  
setStateValue=(id, value)=>{
  let val={};
  val[id]=value;
  this.setState(val);
  this.checkPasswords(id, value);
}

forgotPassword=(token)=>{
//   cl(token);
  let body={password: this.state.password1, token: token};
  let resetPassword={cmd: "cRest", uri: "/o/forgot", method: "retrieve", body: body};
  wsTrans("usa", resetPassword).then(r=>{
//     cl(r);
    saveTokens(r.data.accessToken, r.data.refreshToken, r.data.session)
    history.push('/usa/dashboard');
  });
}

doResult=(result)=>{
  if((this.state.password1 !== "") &&(this.state.password1===this.state.password2)){
    this.forgotPassword(this.props.token);
//       cl(this.props.token);
  }
//     cl(result);
}
  
  render(){
    let name=(globs?.npi?.name) ? globs.npi.name : "Unknown User";// npi is "nextPageInfo"
    let token=(globs?.npi?.token) ? globs.npi.token : "abc-token";
    return(
      <div>
      <h3>Reset Password<OkCancel result={this.doResult}/></h3>
      <Password title="Password" valueId="password1" onChange={this.setStateValue}/>
      <Password title="Confirm Password" valueId="password2" onChange={this.setStateValue}/>
      <Alert severity={this.state.alertSeverity}>{this.state.alertText}</Alert>
      </div>
    );
  }
}

export default ResetPassword ;
