import React from 'react';
import UsaSelect00 from '../UsaSelect00'
import UsaArrayFlags00 from '../UsaArrayFlags00'

import {cl, arrToFlags, flagsToArr} from '../../../components/utils/utils';

class RepeatDayHours extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      days: 1,
      hours: [false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, ]
    }
    this.repeat=this.props.parms.repeat
//     let arr=flagsToArr(23)
//     let flags=arrToFlags(arr)
//     cl(arr)
//     cl(flags)// 23=10111, 29=11101
  }
  
  onChange=(type, vals)=>{
/* here, we store the info as days and an array of hours. we report that to the parent as period and details(flags)*/
    let s=Object.assign(this.state, vals)
    this.repeat={
      period: s.days,
      details: arrToFlags(s.hours),
    }
    this.setState(vals)
    this.props.parms.onChange({repeat: this.repeat})
  }
  
//   weeklyEnter=()=>{
//   }
  
  render(){
    let hourOpts=[
      "12am", "1am", "2am", "3am", "4am", "5am",
      "6am", "7am", "8am", "9am", "10am", "11am", 
      "12pm", "1pm", "2pm", "3pm", "4pm", "5pm",
      "6pm", "7pm", "8pm", "9pm", "10pm", "11pm", 
    ]
    return(
      <div>
      <UsaSelect00 parms={{
        title: "Repeat\u00A0Every",
        footer: "Days",
        footerPlural: true,
        type: "number",
        min: 1,
        width: 50,
        select: false, // !this.state.editMode,
        valueId: "days",//valueId, // "widgetSel",
        value: this.state.days, //value, // this.state.widgetSel,
        onChange: vals=>this.onChange("parts",vals),
      }}/>
      <div style={{height: 10}}/>
      on these hours:
      <UsaArrayFlags00 parms={{
        inline: true,
        opts: hourOpts,
        columns: 6,
        valueId: "hours",
        value: this.state.hours,
        onChange: (vals)=>this.onChange("parts", vals),
      }}/>
      </div>
    )
  }
}

export default RepeatDayHours ;

