import React from 'react';
import {cl} from '../../components/utils/utils';

class SmartInject extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      arr: this.props.value,
    }
    this.props.getValue(props.ind, {type: "array", count: 11})
//     cl(this.props.value)
  }

  onChange=(type, valId, ind, e)=>{
    var vals={}
    let arr=this.props.value.slice(0)
    switch(type){
      case "inp":
        arr[ind]=e.currentTarget.value
        break
      case "check":
        arr[ind]=(e.currentTarget.checked)?1:0
        break
    }
    this.setState({arr: arr})
//     cl(arr)
    this.props.onChange(this.props.ind, {value: arr})
  }
  
  showInput=(val, valId, ind, onChange)=>{
    return(
      <input
        value={val}
        style={{width: "70%",}}
        type="number"
        onChange={e=>onChange("inp", valId, ind, e)}
      />
    )
  }
  
  showSmartRows=()=>{
    let ret=[]
    for(let i=0;i<5;i++){
      let v=this.props.value
      let diff=(v[i+1])?v[i+1]:0
      let ratio=(v[i+6])?v[i+6]:0
      ret.push(
        <tr key={i}>
        <td>When the sensor falls away by</td>
        <td>{this.showInput(diff, "siDifference", i+1, this.onChange)}</td>
        <td>or greater, then apply a ratio of</td>
        <td>{this.showInput(ratio, "siRatio", i+6, this.onChange)}</td>
        </tr>
      )
    }
    return ret
  }
  
  showSmartTable=()=>{
    return(
      <table><tbody>
      {this.showSmartRows()}
      </tbody></table>
    )
  }
  
  render(){
//     cl(this.props.value)
    let en=(+this.props.value[0])?"checked":""
    return(
      <div className="checkbox-field">
      <span>
        <input
          type="checkbox"
          checked={en}
          onChange={e=>this.onChange("check", "enable", 0, e)}
        />
        <label>Enable Smart Inject</label>
      </span>
      {en&&this.showSmartTable()}
      </div>
    );
  }

}

export default SmartInject ;
