import React from 'react';
import TextField from '@material-ui/core/TextField';// import UsaIcon from './UsaIcon';
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton';
import Camera from '@material-ui/icons/Camera';
import Input from '@material-ui/core/Input';// import UsaIcon from './UsaIcon';
import DateTime00 from './DateTime00'
import UsaSelect00 from './UsaSelect00'
import UsaIcon from './UsaIcon';
// import UsaSelect00 from './UsaSelect00';
import {cl,globs,constant, az,getTime, getRandomString} from '../../components/utils/utils';
import {wsTrans,doGetPostBasic} from '../utils/utils'
// import * as d3 from 'd3';
// import base64 from 'base64'
import {checkLoggedIn} from '../utils/utils';
import history from "../../history"

class GrowJournal00 extends React.Component{
  constructor(props) {// 
    super(props);
    cl(props)
    let da=Math.floor(getTime())//new Date().getTime()/1000
    
    this.state={
      zone: 2,
      modTime:da,//Math.floor(getTime()),
      dispTime:da,//Math.floor(getTime()),
      gjSubject: "",
      gjBody: "",
      images: [],
    }
//     this.gjZoneOpts=[
//       {v: 0, t: "Room One"},
//       {v: 1, t: "Room Two"},
//       {v: 2, t: "Room Three"},
//       {v: 3, t: "Room Four"},
//     ]
    this.gjCategoryOpts=[
      {v: "equipment", t: "equipment"},
      {v: "sensors", t: "sensors"},
      {v: "plants", t: "plants"},
      {v: "pests", t: "pests"},
      {v: "nutrients", t: "nutrients"},
      {v: "power", t: "power"},
      {v: "personnel", t: "personnel"},
      {v: "supplies", t: "supplies"},
      {v: "maintenance", t: "maintenance"},
      {v: "other", t: "other"},
    ]
    this.login()
//     this.setZone()
  }
/*row journal:
date, time - click on normal field to open picker
save actual time of entry, and time it's about
category
subject / title
body
image
transcribe
date, time picker
needs an area for the thread that we may be responding to
*/
  
  login=async()=>{
    let logged=await checkLoggedIn()
    if(!logged)history.push("/usa/login")
  }
  
//   setZone=()=>{
// //     cl(this.props)
//     let zoneIndex=+this.props.parms.zuci.split("-")[0]
//     this.zone=this.props.parms.zones[zoneIndex]
// //     cl(this.zone.zoneName)
//   }
  
  onChange=(type, vals)=>{
    switch(type){
      case "date":
        let str=`${vals.dateValue}T${vals.timeValue}`
        let da=new Date(str)
        this.setState({disptime: da})
        cl(da);
      case "category":
      case "zone":
      case "title":
      case "body":
        this.setState(vals)
        break
      default:
        cl(type)
        cl(vals)
        break
    }
  }
  
  zoneSelect=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Zone",
        select: true, // !this.state.editMode,
        valueId: "zone",//valueId, // "widgetSel",
        value: this.state.zone, //value, // this.state.widgetSel,
        opts: this.gjZoneOpts,
        onChange: (vals)=>this.onChange("zone", vals),
      }}/>
    )
  }
  
  categorySelect=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Category",
        select: true, // !this.state.editMode,
        valueId: "category",//valueId, // "widgetSel",
        value: this.state.category, //value, // this.state.widgetSel,
        opts: this.gjCategoryOpts,
        onChange: (vals)=>this.onChange("category", vals),
      }}/>
    )
  }
  
  titleEnter=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Grow\u00A0Journal\u00A0Title",
        select: false, // !this.state.editMode,
        valueId: "gjSubject",//valueId, // "widgetSel",
        value: this.state.gjSubject, //value, // this.state.widgetSel,
//         textId: "widgetName",
        onChange: (vals)=>this.onChange("title", vals),
      }}/>
    )
  }
  
  bodyEnter=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Grow\u00A0Journal\u00A0Body",
        multiline: true,
        rowsMax: 8,
        width: 400,
        select: false, // !this.state.editMode,
        valueId: "gjBody",//valueId, // "widgetSel",
        value: this.state.gjBody, //value, // this.state.widgetSel,
//         textId: "widgetName",
        onChange: (vals)=>this.onChange("body", vals),
      }}/>
    )
  }
  
  sendImages=()=>{
    let data = new FormData()
    this.state.images.forEach((img,i)=>{
      data.append(`id${i}`, img.id)
      data.append(`file${i}`, img.image)
    })
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    cl(url)
    return doGetPostBasic(url, method, data, type)// promise
  }
  
  saveJournalEntry=async()=>{
    let images=[]
    this.state.images.forEach(img=>{
      images.push({name: img.image.name, id: img.id, size: img.image.size})
    })
//     let entry={
//       threadId: getRandomString(16),
//       zone: this.state.zone,
//       body: this.state.gjBody,
//       title: this.state.gjSubject,
//       modTime: this.state.modTime,//Math.floor(this.state.dispTime.getTime()/1000),
//       dispTime: this.state.dispTime,//Math.floor(this.state.modTime.getTime()/1000),
//       images: images,
//     }
    let r=await this.sendImages()
//     cl(r)
//     let createGJ={cmd: "cRest", uri: "/s/growJournal", method: "create", 
//       sessionId: globs.userData.session.sessionId,
//       body: entry};
//     let r2=await wsTrans("usa", createGJ)
//     cl(r2)
    let r2=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "create", 
      sessionId: globs.userData.session.sessionId,
      body: {
      threadId: getRandomString(16),
      zoneId: this.props.parms.zone.zoneId,
      body: this.state.gjBody,
      subject: this.state.gjSubject,
      modTime: this.state.modTime,//Math.floor(this.state.dispTime.getTime()/1000),
      dispTime: this.state.dispTime,//Math.floor(this.state.modTime.getTime()/1000),
      images: images,
    }})
    cl(r2)
  }
  
  result=async(type)=>{
    switch(type){
      case "GJ-OK":
        await this.saveJournalEntry()
        globs.events.publish("GrowJournalUpdated")
        this.props.onChange({showCreateGrowJournal: false})
        break
      case "GJ-Cancel":
        this.props.onChange({showCreateGrowJournal: false})
        break
      default:
        break
    }
//     cl(type)
  }
  
  markImage=(e)=>{
//     let str=getRandomString(8)
//     let newImage=e.target.files[0]// one image selected
//     let data = new FormData()
//     data.append('file', files)
//     let url=`${constant.expressUrl}/usa/images`
//     let method="POST"
//     let type="multipart/form-data"
//     doGetPostBasic(url, method, data, type)
// return    
    let expImage={id: getRandomString(16), image: e.target.files[0]}// expanded image, w/ our name
//     cl(e.target.files)
//     cl(e.target.files[0])
//     let f=e.target.files[0]
//     let file={
//       lastModified: f.lastModified,
//       lastModifiedDate: f.lastModifiedDate,
//       name: f.name,
//       size: f.size,
//       type: f.type,
//       webkitRelativePath: f.webkitRelativePath,
//     }
//     file.id=getRandomString(8)
    let images=this.state.images.slice(0)
    images.push(expImage)
    cl(images)
    this.setState({gjBody: this.state.gjBody+`{Img:${expImage.id}}`, images: images})
  }
  
  gotImagesO=(e)=>{
//   function doGetPost(url, method, body=null){

    cl(e.target.files)
    let files=e.target.files[0]
    var formData = new FormData();
    formData.append("a", "b")
//     console.log(formData)
    let data = new FormData()
    data.append('file', files)
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    doGetPostBasic(url, method, data, type)
  }
  
  showNewJournal=()=>{
//       {this.zoneSelect()}
  return(
      <div>
      <h3 style={{marginLeft: 10}}>New {this.props.parms.zone.zoneName} Grow Journal Entry 
      <UsaIcon icon="GJ-OK" result={this.result} inline/>
      <UsaIcon icon="GJ-Cancel" result={this.result} inline/>
      <IconButton
        variant="contained"
        component="label"
        title="Upload Image"
      >
        <Camera/>
        <input hidden multiple type="file" onChange={this.markImage}/>
      </IconButton>
      </h3>
      <DateTime00 parms={{date: this.state.dispTime}} onChange={(vals)=>this.onChange("date", vals)}/>
      <div style={{height: 10}}/>
      {this.categorySelect()}
      {this.titleEnter()}
      {this.bodyEnter()}
      </div>
    )
  }
  
  showEditJournal=()=>{
  return(
      <div>
      <h3>Grow Journal
      <UsaIcon icon="GJ-OK" result={this.result} inline/>
      <UsaIcon icon="GJ-Cancel" result={this.result} inline/>
      <IconButton
        variant="contained"
        component="label"
        title="Upload Image"
      >
        <Camera/>
        <input hidden multiple type="file" onChange={this.markImage}/>
      </IconButton>
      </h3>
      <div style={{height: 10}}/>
      {this.bodyEnter()}
      </div>
    )
  }
  
  render(){
    if(this.props.growJournalId){
      return this.showEditJournal()
    }else{
      return this.showNewJournal()
    }
  }
}

export default GrowJournal00 ;

