 
import React from 'react';
import TextField from '@material-ui/core/TextField';// import UsaIcon from './UsaIcon';
// import UsaSelect00 from './UsaSelect00';
import {cl,az,getTime} from '../../components/utils/utils';

class DateTime00 extends React.Component{
  constructor(props) {// 
    super(props);
//     cl(props)
    let da=new Date()
    this.state={
//       modTime:da,//Math.floor(getTime()),
//       dispTime:da,//Math.floor(getTime()),
      timeValue: this.daToTime(this.timestampToDate(props.parms.date)),
      dateValue: this.daToDate(this.timestampToDate(props.parms.date)),
    }
//     cl(this.state)
  }
  
timestampToDate=(ts)=>{
  return new Date(ts*1000)
}

timeDateToTimestamp=(time, date)=>{
  let str=`${date}T${time}`
  return Math.floor(new Date(str).getTime()/1000)
}
  
daToDate=(da)=>{
  let yr=da.getFullYear()
  let mo=az(da.getMonth()+1, 2)
  let dy=az(da.getDate(), 2)
  return `${yr}-${mo}-${dy}`
}
  
daToTime=(da)=>{
  let hr=az(da.getHours(),2)
  let mn=az(da.getMinutes(),2)
  return `${hr}:${mn}`
}
  
showDate=()=>{
    return(
      <div>
      <span style={{fontSize: 12, fontFamily: "Arial", opacity: 0.54}}>{this.props.parms.title}</span>
      <div style={{height: 2}}/>
      <form noValidate> 
        <TextField
          id={`date-${this.props.parms.id}`}
          type="date"
          onChange={this.onChange}
          inputProps={{
            value: this.state.dateValue,
          }}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <TextField
          id={`time-${this.props.parms.id}`}
          type="time"
          onChange={this.onChange}
          inputProps={{
            value: this.state.timeValue,
          }}
        />
      </form>
      </div>
    )
  }
  
  onChange=(e)=>{
    var val
    switch(e.target.id.substr(0,4)){
      case "date":
        val={dateValue: e.target.value}
        break
      case "time":
        val={timeValue: e.target.value}
        break
    }
    let obj=Object.assign(this.state, val)
    let da=this.timeDateToTimestamp(obj.timeValue, obj.dateValue)
    let vals={}
    vals[this.props.parms.valueId]=da
    this.props.onChange(vals)
    this.setState(val)
  }
  
  render(){
    return(
      <div style={{display: (this.props.parms.inline) ? "inline" : "block"}}>
        {this.showDate()}
      </div>
    )
  }
}

export default DateTime00 ;

