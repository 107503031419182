import React from 'react';
import C18Button00 from './C18Button00'
import UsaIcon from './UsaIcon';
import {loadUsersInfo} from './C18utils'
//  import { Router, Route, Switch } from "react-router-dom";// , Link
import {wsTrans, inputField, doGetPostBasic, checkLoggedIn, logout} from '../utils/utils';

import {cl, globs, constant} from '../../components/utils/utils';
import IconButton from '@material-ui/core/IconButton';
import Camera from '@material-ui/icons/Camera';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
//  import InputAdornment from '@material-ui/core/InputAdornment';
//  import Input from "@material-ui/core/Input";
//  import InputLabel from '@material-ui/core/InputLabel';
//  import Register from './Register';
//  import Login from './Login';
import MainBar from '../../components/MainBar';
import history from "../../history"
 
 
class UserProfile extends React.Component{
  constructor(props) {
    super(props);
    document.title="User Profile";
//     cl(props);
//     cl(globs.userData);
    this.getMyUserInfo();
    this.state={
      name: "Unknown User",
      email: "unknown@nowhere.com",
      loaded: false,
    }
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"User Profile", url:`/usa/c18/admin/userProfile`},
            ]},
        },
      )
    }
  }
  
  getMyUserInfo=async()=>{// need to login first
    
//     cl(globs.userData);
    let logged=await checkLoggedIn()
    if(!logged)history.push("/usa/login")
//     cl(logged)
    if(!globs?.userData?.session?.userId) return;
//     cl(globs)
    let body={userId: globs.userData.session.userId};
    let getUser={cmd: "cRest", uri: "/s/users", method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: body};
      cl(body)
    wsTrans("usa", getUser).then(r=>{
      if(r.result=="ok"){
        cl(r);
        let u=r.data[0]// returns an array
        this.setState({loaded: true, name: u.name, email: u.email, avatar: u.avatar});
      }
    });
  }
  
  putMyUserInfo=async()=>{
    let body={userId: globs.userData.session.userId, name: this.state.name, email: this.state.email, avatar: this.state.avatar};
    let putUser={cmd: "cRest", uri: "/s/users", method: "update", sessionId: globs.userData.session.sessionId,
      body: body};
    cl(body)
    await wsTrans("usa", putUser)
    globs.usersInfo={}
    await loadUsersInfo()
//     cl(globs.usersInfo)
//       .then(r=>{
//         cl(r);
// 
//       });
  }
  
  ok=()=>{
    cl("save");
//     cl(this.state.name);
    this.putMyUserInfo();
  }
  
  cancel=()=>{
    cl("cancel");
  }
  
  showInfo=()=>{
//     cl(globs.userData)
    let clients={
      "Ck1W1c98ldyObUxV": "31373730353851040023004a",
      "-RoMkMjyQORLauk1": "31373730353851040023004c",
      "0WJBVFV0B0NYF0PR": "31373730353851040023004a",
//       "0WJBVFV0B0NYF0PR": "393632353438511200300018",
      "F5M1AABTX131XFTC": "393632353438511200330018",
      "015JX0V4UUCGD55V": "393632353438511200330018",
      "A73M5XD1PD0CH570": "39363235343851120023002e",
    }
//     cl(globs.userData.session.siteId)
    if(!globs.userData.session){return}
    let clientId=clients[globs.userData.session.siteId]
    if(!clientId)clientId="Unknown"
    return(
      <div>
      accountId: {globs.userData.session.accountId}<br/>
      expire: {globs.userData.session.expire}<br/>
      sessionId: {globs.userData.session.sessionId}<br/>
      siteId: {globs.userData.session.siteId}<br/>
      clientId: {clientId}<br/>
      userId: {globs.userData.session.userId}<br/>
      info
      </div>
    )
  }
  
  result=(type)=>{
    cl(type)
  }
  
  markImage=async(e)=>{
    
    cl(e.target.files)
    let files=e.target.files[0]
    let data = new FormData()
    data.append("type", "avatar")
    data.append("sessionId", globs.userData.session.sessionId)
    data.append('file', files)
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    cl("post image")
    let ret=await doGetPostBasic(url, method, data, type)
    let ret2 = await ret.json()
    this.setState({avatar: ret2.avatar})
  }
  
  showAvatar=()=>{
//     cl(this.state.avatar)
    let av=this.state.avatar
    var path
    if(av){
      path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
    return(
      <div>
      <div style={{height: 20}}/>
      <div>
        <IconButton
          variant="contained"
          component="label"
          title="Upload Image"
        >
          <Camera/>
          <input hidden multiple type="file" onChange={this.markImage}/>
        </IconButton>
      </div>
      
      <h3 style={{display: "inline-block"}}>Avatar</h3>
      <div/>
      <img width="80" src={path} style={{padding: 10}}/>
      </div>
    )
  }
  
  logOut=()=>{
    cl("logout")
    logout()
    history.push("/usa/c18/login")
  }
  
  render(){
//     cl(IconButton);
    if(this.state.loaded){
      return(
        <div>
        <form>
          {inputField({
            title: "Name", 
            onChange:e=>{this.setState({name: e.currentTarget.value})}, 
            value: this.state.name
          })}
          {inputField({title: "Email", 
            onChange:e=>{this.setState({email: e.currentTarget.value})}, 
            value: this.state.email
          })}
          <C18Button00 type="button" onClick={()=>this.logOut()}>Log Out</C18Button00>
          {this.showAvatar()}
          <div style={{height: 50}}/>
          {this.showInfo()}
        </form>
        </div>
      );
    }else{
      return <div>loading...</div>
    }
  } 
}
  
export default UserProfile ;
 
