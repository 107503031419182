// eslint-disable-line eqeqeq
import React from 'react';
import UsaIcon from './UsaIcon';
import C18Select00 from './C18Select00'
import C18MenuBar00 from './C18MenuBar00'
import C18SaveHeader00 from './C18SaveHeader00'
import C18SubMenuHeader00 from './C18SubMenuHeader00'
import C18Breadcrumb00 from './C18Breadcrumb00'
import C18WeatherStation00 from './C18WeatherStation00'
import C18EquipmentListCols00 from './C18EquipmentListCols00'
import C18SitesListTiles00 from './C18SitesListTiles00'
import C18ZonesListTiles00 from './C18ZonesListTiles00'
import C18ZoneSensorsEquipment00 from './C18ZoneSensorsEquipment00'
import C18ZoneSensorSettings00 from './C18ZoneSensorSettings00'
import C18ZoneEquipmentSettings00 from './C18ZoneEquipmentSettings00'
import C18RightSidebar00 from './C18RightSidebar00'
import C18Settings00 from './C18Settings00'
import C18Graphing00 from './C18Graphing00';
import C18GraphSelect00 from './C18GraphSelect00';
import C18EditReport from './C18EditReport';
import C18Summary from './C18Summary';
import C18SummaryList from './C18SummaryList';
import C18EditSchedule from './C18EditSchedule';
import C18ReportList from './C18ReportList';
import C18ScheduleList from './C18ScheduleList';
import C18MessageList00 from './C18MessageList00';
import C18MessageEdit00 from './C18MessageEdit00';
import C18MessageDisplay00 from './C18MessageDisplay00';
// import C18ViewReport from './C18ViewReport';
import C18ViewReport from './C18ViewReport';
import C18SaveRestore00 from './C18SaveRestore00';
import C18Tasks00 from './C18Tasks00';
import C18TaskList00 from './C18TaskList00';
import C18TaskDetail00 from './C18TaskDetail00';
import C18TaskEdit00 from './C18TaskEdit00';
import C18AlarmLog00 from './C18AlarmLog00';
import C18Login00 from './C18Login00';
import C18Logout00 from './C18Logout00';
import C18Register00 from './C18Register00';
import C18Confirm00 from './C18Confirm00';
import PostRegister from './PostRegister';
import InviteUser from './InviteUser';
import C18ResetPassword00 from './C18ResetPassword00';
import ManageAccount from './ManageAccount';
import ManageUsers from './ManageUsers';
import ManageSites from './ManageSites';
import ManageSiteAccess from './ManageSiteAccess';
import ManageGateways from './ManageGateways';
import ManageAlarms from './ManageAlarms';
import ManageZones from './ManageZones';
import C18ManageGroups00 from './C18ManageGroups00';
import C18ManageAccount from './C18ManageAccount';
import C18ManageUsers from './C18ManageUsers';
import C18EditUser00 from './C18EditUser00';
import C18ModbusDevicesEdit00 from './C18ModbusDevicesEdit00';
import C18ManageUsers3 from './C18ManageUsers3';
import C18ManageSites from './C18ManageSites';
import C18ManageSiteAccess from './C18ManageSiteAccess';
import C18ManageGateways from './C18ManageGateways';
import C18ZoneGroups00 from './C18ZoneGroups00';
import C18ManageAlarms from './C18ManageAlarms';
import C18ManageZones from './C18ManageZones';
import C18ManageSubscription from './C18ManageSubscription';
import C18GeneralInfo00 from './C18GeneralInfo00';
import C18ManageWatch00 from './C18ManageWatch00';
import C18PageAccesses00 from './C18PageAccesses00';
import C18Sensor00 from './C18Sensor00';
import C18HeaDetail01 from './C18HeaDetail01';
import C18Messaging00 from './C18Messaging00';
import C18EditInfoPages from './C18EditInfoPages';
import C18TechPortal00 from './C18TechPortal00';
import C18TechPortal01 from './C18TechPortal01';
// import C18ThreeD00 from './C18ThreeD00';
import C18SalesPortal00 from './C18SalesPortal00';
import C18VideoDetail00 from './C18VideoDetail00';
import VidTimeline00 from '../../visualization/components/VidTimeline00'
import C18DevHome00 from './C18DevHome00';
import C18Cameras00 from './C18Cameras00';
import C18CropRecipeList00 from './C18CropRecipeList00';
import C18CropPhases00 from './C18CropPhases00';
import C18DayPhases00 from './C18DayPhases00';
import C18SvgEditor00 from './C18SvgEditor00';
import C18PhysView00 from './C18PhysView00';
import C18GroupsEdit00 from './C18GroupsEdit00';

import Nutrients from './IDoser/Nutrients'
import Recipes from './IDoser/Recipes'
import Areas from './IDoser/Areas'
import Tanks from './IDoser/Tanks'
import Schedules from './IDoser/Schedules'
import Stations from './IDoser/Stations'
import C18Modbus00 from './C18Modbus00';
import C18ModbusGP00 from './C18ModbusGP00';
import C18Expansion00 from './C18Expansion00';
import C18PearlMapping00 from './C18PearlMapping00';
import C18PearlInputConfig00 from './C18PearlInputConfig00';
import C18ServerLog00 from './C18ServerLog00';


// import UserProfile from './UserProfile';
import UserProfile from './C18UserProfile';
import SelectSensors from './SelectSensors';
import Status from './Status';
import C18SyncStatus from './C18SyncStatus';
import TestReportWriter from './TestReportWriter';
import C18AutoSetup00 from './C18AutoSetup00';
import C18QrCode00 from './C18QrCode00';
import C18QrCodeReceive00 from './C18QrCodeReceive00';
import LiveFui from '../../fui/LiveFui';
import history from "../../history"
import {loadAccountInfo,loadSitesInfo,loadZonesInfo,loadSiteData,getZoneInfo,loadPrivsInfo,getReportsIndex,loadReportsInfo,
  loadSchedulesInfo,getSchedulesIndex,privs,loadUser,getZoneId,addToTracking,loadDevicesInfo,loadPresetsInfo,getPresetIndex,
  updateDeviceInfo,rnRest,handleRnMsg,restResp,restKey,restArr,loadSubscriptionInfo,getUserIndex, checkAdmin, checkOwner,loadUsersInfo,
  restGd,handleGdMsg,restRespGd,restKeyGd,restArrGd,gdRest,rnCl,getSuperUserFlags,loadSummaryPresetsInfo,getSummaryPresetIndex,
  getBilling, getRecurlyAccount,setGroupId,getGroupInfo,acctFeature
} from './C18utils'
import {cl,show,globs,constant,getDaysRemaining,validAppVersions,getTimeI,getLocalStorage,saveLocalStorage} from '../../components/utils/utils';
import config from '../../components/utils/config'
import {wsTrans,sensorIds,getUId,checkLoggedIn,saveBrowserAccess} from '../utils/utils'
import {login,setTitle} from './C18utils'

class C18Host00 extends React.Component{
  constructor(props) {
    super(props);
    cl.oci(this,[this.constructor.name],this.onChange)
//     cl(props)
    globs.userData.loggedIn=false
//     cl(globs.userData)
    this.mounted=false;
    (props.rest||{}).child=this.childRest
    this.notifies=[]
//     this.newProps={}// child functions to notify of new Props
    this.myPageTitle='';
    this.state={
      loaded:false,
      savePage: false,
      saveEnable: true,
      url:this.props.url,
      pageTitle:"",
      pageModified:false,
      sideBarOpen: (window.ReactNativeWebView || (window.innerWidth < 460)) ? false:true,
      sidebarMode:"growJournal",
      siteStatus:[],
      zoneSelect:false,
      zoneSelectValue:"",
      breadcrumbs:[
        {t:"Sites", url:"/usa/c18/sites"},
//         {t:"San Diego", url:"/usa/c18/sites/0sna8jVYIh0xw6oF"},
//         {t:"Greenhouse 03", url:"/usa/c18/sites/0sna8jVYIh0xw6oF/zones/Cw07JvgkDXlrvGnQ"},
//         {t:"sensor", url:"/usa/c18/sites/0sna8jVYIh0xw6oF/zones/Cw07JvgkDXlrvGnQ/settings/sensor1800"},
//         {t:"equipment", url:"/usa/c18/sites/0sna8jVYIh0xw6oF/zones/Cw07JvgkDXlrvGnQ/settings/equipment1800"},
      ],
      imageDisplay:[],
      popup:{
        opacity:0,
        text:"Are you sure?",
        buttons:["Cancel","Yes"],
        resolve:null,
      },
      selController:0,
      // forecast: null
      firstTime: true
    }
//     cl("check login type")
    if(this.loginType()){// don't need to be logged in'
//       cl("set loaded")
//       cl("Load style.css")
      this.loadCss("style.css")
      this.state.loaded=true
    }else{
//       cl("login")
//       login().then(r=>{
//         this.loadInfo()
//       })
    }
//     cl(globs.userData)
    this.handleMessage()
//     cl("hm")
    this.procUrl(this.props.url)
//     cl("procd")
  }
  
  loginType=()=>{
//     cl(this.props.page)
    let ret=["login","register","devRegister","postRegister","activate","inviteUser",
      "resetPassword","logout"].includes(this.props.page)
//     cl(ret)
//     cl(`loginType: ${ret}`)
    return ret
//     return (this.props.page=="login")||(this.props.page=="register")||(this.props.page=="postRegister")
//       ||(this.props.page=="activate")||(this.props.page=="inviteUser")||(this.props.page=="resetPassword")
  }

  findCss = () => {
    let arr = document.getElementById("deviceTheme")
//     cl(arr)
    return arr
  }
  
  loadCss=(cssFile)=>{
//     cl("loadCss")
//     cl(cssFile)
    if(this.loadedCss==cssFile){return}
    this.loadedCss=cssFile
    let elem = this.findCss()
    if (!elem) {
//       cl(cssFile)
      let link=document.createElement("link")
      link.id="deviceTheme"
      link.type="text/css"
      link.rel="stylesheet"
      link.href=`/${cssFile}`
      document.head.appendChild(link)
    } else {
//       cl("reassigning href to " + cssFile)
      elem.href=`/${cssFile}`
    }
  }
  
  loadInfo=async()=>{
//     cl("loadinfo")
    let themes={originalLight:"style.css",originalDark:"style_dark.css"}
    loadAccountInfo()
//     cl(globs.userData.session)
//     cl(show("main"))
    await loadUser()
    loadSitesInfo()
//     cl(show("main"))
    await loadDevicesInfo()
//     cl(globs.device)
    // let device = globs.devicesInfo.info.find((d) => (d.deviceId == globs.userData.session.uId)&&(d.userId==globs.userData.session.userId))

//     cl(globs.user.theme)
//     cl(globs.user.theme)
    // this.loadCss(themes[globs?.user?.theme]||"style.css")
//     console.log(device?.deviceTheme)
    this.loadCss(themes[globs.device?.deviceTheme || "originalLight"])
//     cl(globs.device.deviceTheme)
//     cl(show("main"))
    await loadZonesInfo()
//     cl("load privs")
    await loadPrivsInfo()
//     cl(globs.userData.session.features)
//     cl(show("main"))
    await loadSubscriptionInfo()
//     cl(show("main"))
//     cl(globs.privsInfo.info)
    await loadReportsInfo()
    await loadSchedulesInfo()
    this.checkGateways()
//     cl("loaded zones")
//     this.zoneInfo=getZoneInfo(this.state.zone)// should not be done here! zone can change
//     cl(this.zoneInfo)
//     cl(this.props)
//     cl(info)
    if(this.state.site){loadSiteData(this.state.site)}
//     if(this.state.zone){
//       cl(this.state.zone)
//     }
//     cl(this.state)
//     cl(globs)
//     cl("got data")
    this.mySetState("loadinfo",{loaded:true})


  }
  
//   shouldComponentUpdate=(nextProps, nextState)=>{
//     cl(nextProps)
//     return true
//     
//   }

  historyListen=async(location,action)=>{
//     cl("**********************************************************")
//     cl(`historyListen: ${location.pathname}`)
//     cl(location.pathname)
//     addToTracking({cmd:"url",url:location.pathname})

    await this.procUrl(location.pathname)
//     cl("History Listen Done")
  }
  
  pageSaveOkNeeded=()=>{
    var userPriv=0
    if(this.state.zone){
      userPriv=privs("zone",this.state.zone,constant.AREA_PRIVS_WRITE)
    }else{
      if(this.state.site){
        userPriv=privs("site",this.state.site,constant.AREA_PRIVS_WRITE)
      }else{
        userPriv=privs("account",0,constant.AREA_PRIVS_WRITE)
      }
    }
    
//     if(privs("site",s.siteId,constant.AREA_PRIVS_READ)){
    return this.state.pageModified&&(userPriv!=0)
  }

  getTrialInfoDays = (days, checkpoint) => {
    while (days <= checkpoint && checkpoint >= 0) {
      switch (checkpoint) {
        case 15:
          checkpoint = 7
          break
        case 7:
          checkpoint = 2
          break
        case 2:
          checkpoint = 1
          break
        case 1:
          checkpoint = 0
          break
        case 0:
          checkpoint = -1
          break
        default:
          checkpoint = 15
          break
      }
    }
//     cl(checkpoint)
    return checkpoint
  }

  trialPopupNeeded=async()=>{
    // check if on trial
//     cl(new Date(globs.subscriptionInfo.info.end_date))
    let onTrial = globs.subscriptionInfo.info?.plan_code == "cloud2p0trialsub"
    let text
    let popup
    if (onTrial) {
      let days = Math.ceil(getDaysRemaining(new Date(globs.subscriptionInfo.info.end_date)))
      popup = false
      text = `In ${days} days, your free trial will end. If you do not purchase licenses before trial ends, you will not be able to access unlicensed zones.`
      // get next trial popup day
      let user = globs.user
      if (!user) {return [popup, text]}
      if(!user.uiInfo) {
//       if (!globs.user) await loadUser()// from dev
//       let user = globs.user||{}
//       if(!user?.uiInfo) {
        user.uiInfo= {
          showTrialInfo: 15,
        }
      }
      let trialPopup = (user.uiInfo?.showTrialInfo == null) ? 15 : user.uiInfo.showTrialInfo
//       cl(user.uiInfo)
      // trialPopup = 15
//       cl(trialPopup)
//       cl(days)
      if (days <= 0 && trialPopup >= 0) {
        text = "Your free trial has expired. Configure a new plan on the Manage Subscription page."
        popup = true
        // set popup to -1, won't be shown again
        trialPopup = this.getTrialInfoDays(days, trialPopup)
      } else if (days == 1 && trialPopup >= 1) {
        let hours = Math.floor(getDaysRemaining(new Date(globs.subscriptionInfo.info.end_date)) * 24)
        text = `Your free trial will end in ${hours} hours. If you do not purchase licenses before trial ends, you will not be able to access unlicensed zones.`
        popup = true
        trialPopup = this.getTrialInfoDays(days, trialPopup)
      } else if (days <= 2 && trialPopup >= 2) {
        popup = true
        trialPopup = this.getTrialInfoDays(days, trialPopup)     
      } else if (days <= 7 && trialPopup >= 7) {
        popup = true
        trialPopup = this.getTrialInfoDays(days, trialPopup)
      } else if (days <= 15 && trialPopup >= 15) {
        popup = true
        trialPopup = this.getTrialInfoDays(days, trialPopup)
      }
      if (popup) {
        user.uiInfo.showTrialInfo = trialPopup
//         cl(user.uiInfo)
        await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "update2", 
          sessionId: globs.userData.session.sessionId,
          body: {userId: globs.userData.session.userId, uiInfo: user.uiInfo}})
      }
    }
    return [popup, text]
  }

  pastDuePopupNeeded=async()=>{
    let accountInfo = await getRecurlyAccount()
    let pastDue = accountInfo.hasPastDueInvoice
    let text
    let popup
    if (pastDue) {
      let lastPastDuePopup = Number(getLocalStorage("lastPastDuePopup"))
      if (!lastPastDuePopup || Date.now() - lastPastDuePopup > 3600000) {
        saveLocalStorage("lastPastDuePopup", Date.now())
        popup = true
        text = `Your account is past due. Your subscription is on hold until account status is restored.`
      }
    }
    return [popup, text]
  }
  
  handleWindowBeforeUnload=(e)=>{
    if(this.pageSaveOkNeeded()){
      cl("before unload")
      e.preventDefault()
      e.returnValue=""
    }
//     cl("handle")
  }
  
  notify=(note)=>{// used here to notify widgets of a change in drag position
    if(note.unMount){// called when widget is unmounted
      this.notifies[note.id].forEach((n,i)=>{
        if(n==note.func){
          this.notifies[note.id].splice(i,1)
        }
      })
    }else{
      if(note.id in this.notifies){}else{
        this.notifies[note.id]=[]
      }
//       cl(note)
      this.notifies[note.id].push(note.func)
    }
  }
  
  checkGateways=async()=>{// needs to check zones, too
//     cl(globs.zonesInfo.info)
//     cl("check gateways")
//     cl(globs.zonesInfo.info)
    if(!globs?.userData?.session||!globs.zonesInfo?.info){return}
    await loadSitesInfo()
    let sites={}
    if(globs?.sitesInfo?.info){globs.sitesInfo.info.forEach(s=>{sites[s.siteId]=1})}else{return}
    
//     cl(Object.keys(sites))
    
    
    wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "retrieve2", 
      sessionId: globs.userData.session.sessionId, body: {}},true).then(gw=>{
        if(!gw.data){return}
        let status={}
        gw.data.forEach(g=>{
//           cl("dogw")
          if(Object.keys(sites).includes(g.siteId)){
//             cl(g)
//             cl(g.connected,g.gatewayId)
            // check zones:
            globs.zonesInfo.info.forEach(z=>{
              if((z.gatewayId==g.gatewayId)&&(+z.inNet)){
//                 cl(z.zoneName)
                if(!z.connected){
//                   cl(z)
                  g.connected=false}
              }
            })
            status[g.gatewayId]=g
          }
        })
//         cl(status)
        let upd=false
        let ss=this.state.siteStatus
//         cl(status)
//         cl(this.state.siteStatus)
        
        Object.keys(status).forEach(k=>{
          upd|=((ss[k]||{}).connected!=status[k].connected)
        })
//         cl(upd)
        if(upd){
//           cl("do gateway connected update")
          this.setState({siteStatus:status})
//           cl(status)
        }
      })
  }
  
  componentDidMount=()=>{
//     cl("host mount")
    this.historyUnlisten=history.listen(this.historyListen)
//     this.checkGateways()
    this.checkGatewaysTimer=setInterval(this.checkGateways,100000)
    this.mounted=true
    window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
  }
  
  componentWillUnmount=()=>{
    cl("host unmount")
    this.historyUnlisten()
    clearInterval(this.checkGatewaysTimer)
    this.mounted=false
//     globs.userData.loggedIn=false
    window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
  }
  
  mySetState=(source,obj)=>{
//     cl(source,obj)
//     cl(obj)
//     cl(source)
    if(this.mounted){
      this.setState(obj)
//       cl(obj)
    }else{
      Object.assign(this.state,obj)
    }
  }
  
//   setNewProps=(type, newProps)=>{
//     this.newProps[type]=newProps
//   }

  doNotify=(event,cmd,data)=>{
    if(event in this.notifies){
      this.notifies[event].forEach(f=>{
        f({cmd:cmd, data:data})
      })
    }
  }

  urlSettings=(parts,pos)=>{
// this needs to see to updating the breadcrumbs
/* the settings component knows how to set the breadcrumbs
we need to get a message to it: new url 
best way to send a message to a child?
the child constructor needs to register a function: newProps
      props.notify({id: "drag", func: this.onDrag})
      
  componentWillUnmount=()=>{
    this.props.notify({id: "drag", func: this.onDrag, unMount: true})
  }
      

*/    
//     cl("set settings")
//     cl(this.notifies)
    this.doNotify("newUrl","settingsType",parts[8])
//     if("newUrl" in this.notifies){
//       this.notifies["newUrl"].forEach(f=>{
//         f({cmd:"settingsType", data:parts[8]})
//       })
//     }
//     this.notify({id:"newUrl",url:"some"})
    this.mySetState("urlSettings", {mode:"settings",savePage: false,pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[],
      site:this.getSiteId(parts,4),zone:parts[6],settingsType:parts[8]})
  }


  checkZoneGroup=(parts)=>{
//     cl(globs.userData.session?.features)
    let groupId=parts[6]
    let thisGroup=getGroupInfo(groupId)
//     let siteGroups=globs.zonesInfo.groups.filter(g=>{return g.siteId==this.siteId})[0]
//     let thisGroup=siteGroups.groups.filter(g=>{return groupId==g.groupId})[0]
    return(thisGroup)?[groupId,thisGroup.zones[0]]:["",groupId]
//     getZoneInfo(thisGroup.zones[0]):null
  }
  
  urlZones=async(parts)=>{
//     cl(parts)
    var groupId,zoneId
    if(acctFeature("zoneGroups")){
      [groupId,zoneId]=this.checkZoneGroup(parts)
      await setGroupId(groupId,zoneId)
    }else{
      zoneId=parts[6]
    }
    let sideBarNoteId
    if(this.badReadPriv("zone",zoneId)){return}
    let sidebarMode="growJournal"
    globs.userData.zoneSelected=parts[6]
    switch(parts[7]){
      case "admin":
//         cl("admin")
        return this.urlAdmin(parts,8)
      case "settings":
        return this.urlSettings(parts,8)
      case "idoser":
        return this.urlIDoser(parts,8)
      case "modbus":
        return this.urlModbus(parts,8)
      case "modbusGp":
        return this.urlModbusGp(parts,8)
      case "manageAlarms2":
        return this.urlManageAlarms2(parts,8)
      case "modbusDevicesEdit":
        return this.urlModbusDevicesEdit(parts,8)
      case "pearl":
//         cl(parts)
        return this.urlPearl(parts,8)
      case "graphing":
      case "sensorGraph":
        return this.urlGraphing(parts,8)
      case "sensor":
        return this.urlSensor(parts,8)
      case "alarmLog":
        return this.urlAlarmLog(parts,8)
      case "tasks":
      case "taskDetail":
      case "taskEdit":
        return this.urlTasks(parts,8)
      case "saveRestore":
        return this.urlSaveRestore(parts,8)
      case "generalInfo":
        return this.urlGeneralInfo(parts,8)
      case "SPgrowJournal":
      case "SPalarms":
      case "SPauditLog":
      case "SPimages":
      case "SPgeneralInfo":
      case "SPtasks":
        sidebarMode=parts[7].substr(2)
        cl(sidebarMode)
        sideBarNoteId = parts[8]||""
      default:
        let siteId=parts[4]
        globs.events.publish("newContext",{level:"zone",zoneId:zoneId,siteId:siteId})
        this.mySetState("urlZones", {mode:"zones",groupId:groupId,
          scope:"zones",imageDisplay:[],
          savePage: false,pageModified:false,site:this.getSiteId(parts,4),zone:zoneId,
          sidebarMode:sidebarMode,sideBarNoteId:sideBarNoteId})
    }
  }
  
  badReadPriv=(level,id)=>{
    if(level=="account"){return false}
//     cl(level,id)
    if(privs(level,id,constant.AREA_PRIVS_READ)==0){
      cl("bad privs")
      history.push("/usa/c18/sites")
      return true
    }
  }
  
  // http://ngsg.link4cloud.com:3104/usa/c18/sites/d1fIue45CWvP@Nik/zones/Cq5jDVdC03vdecmJ
  // http://ngsg.link4cloud.com:3104/usa/c18/fui/channel_Vent_Retractable_Roof/1-0-0-0
  
  urlSites=async(parts)=>{
    // cl(parts)
    let siteId=this.getSiteId(parts,4)
    await setGroupId("")
//     let siteId=globs.userData.session.siteId//this.getSiteId(parts,4)
//     cl(siteId)
//     this.siteId=siteId// used by groups
    if(siteId=="taskEdit"){return this.urlTasks(parts,5)}
    if(siteId=="tasks"){return this.urlTasks(parts,5)}
    var sidebarMode="growJournal";
    let spValues=["SPtasks","SPgrowJournal"]
    let sideBarNoteId = ""
    // cl(siteId)
    if(spValues.includes(siteId)){
      sidebarMode=(siteId||"SPgrowJournal").substring(2)
      siteId=null
    }
    if(spValues.includes(parts[5])){
      sidebarMode=(parts[5]||"SPgrowJournal").substring(2)
      sideBarNoteId = parts[6]||""
    }
    if(spValues.includes(parts[4])){
      sideBarNoteId = parts[5]||""
    }
    if(siteId){await loadSiteData(siteId)}
//     cl("site loaded")
    let scope=(siteId)?"site":"sites"
//     let level=(siteId)?"site":"account"
    let level=(siteId)?"site":"account"
    globs.userData.zoneSelected=null
    globs.userData.siteSelected=parts[4]||null
//     cl(parts)
    switch(parts[5]){
      case "zones":
        return this.urlZones(parts)
      case "alarmLog":
        if(this.badReadPriv(level,siteId)){return}
        return this.urlAlarmLog(parts,6)
      case "tasks":
      case "taskDetail":
      case "taskEdit":
        if(this.badReadPriv(level,siteId)){return}
        return this.urlTasks(parts,6)
      case "fui":
        return this.urlFui(parts,6)
        break
      case "SPgrowJournal":
      case "SPalarms":
      case "SPauditLog":
      case "SPimages":
      case "SPgeneralInfo":
      case "SPtasks":
        sidebarMode=parts[5].substr(2)
        // cl(sidebarMode)
      default:
        await setGroupId("")
//         cl("default")
        if(parts[4]=="taskDetail"){
          return this.urlTasks(parts,5)
        }
        if(this.badReadPriv(level,siteId)){return}

        if(getSuperUserFlags()&(constant.SUPER_PRIVS_ADMIN) && this.state.firstTime) {
          if(globs.zonesInfo.info.length == 1) {
            let z = globs.zonesInfo.info[0]
            let url=`/usa/c18/sites/${z.siteId}/zones/${z.zoneId}`
            history.push(url)
            this.setState({firstTime: false})
          }
        }
        this.setState({firstTime: false})
        globs.events.publish("newContext",{level:level,siteId:siteId})
//         cl("set sites loaded")
        this.mySetState("urlSites", {loaded:true,mode:"sites",scope:scope,savePage: false, 
          imageDisplay:[],groupId:"", pageModified:false,site:siteId,sidebarMode:sidebarMode,sideBarNoteId:sideBarNoteId})
    }
  }
  
  getSiteId=(parts,ind)=>{
    return (parts[ind-1]=="sites")?parts[ind]:""
  }
  
  getZoneId=(parts,ind)=>{
    return (parts[ind-1]=="zones")?parts[ind]:""
  }

  getReportId=(parts,ind)=>{
    return (parts[ind-1]=="editReport" || parts[ind-1]=="viewReport"  || parts[ind-1]=="viewGraph"  || parts[ind-1]=="editGraph"  
      || parts[ind-1]=="editSchedule" ||
      parts[ind-1]=="viewSummary"  || parts[ind-1]=="editSummary")?parts[ind]:""
  }
  
  urlAdmin=async(parts,pos)=>{
    await setGroupId("")
//     cl(parts,pos)
//     this.mySetState({mode:"settings",site:parts[4],zone:parts[6],settingsType:parts[8]})
//     cl(parts[pos])
    let pageTitles={
//       manageAccount: "Manage Account",
//       manageUsers: ManageUsers,
//       manageSites: ManageSites,
//       manageSiteAccess: ManageSiteAccess,
//       manageGateways: ManageGateways,
//       manageAlarms: ManageAlarms,
//       manageZones: ManageZones,
      manageAccount2: {title:"Manage Account",saveEnable:true},
      manageUsers2: {title:"Manage Users",saveEnable:true},
      manageUsers3: {title:"Manage Users",saveEnable:false},
      manageSites2: {title:"Manage Sites",saveEnable:true},
      manageSiteAccess2: {title:"Manage Access",saveEnable:true},
      manageGateways2: {title:"Manage Gateways",saveEnable:true},
      manageZoneGroups: {title:"Manage Zone Groups",saveEnable:true},
      manageAlarms2: {title:"Manage Alarms",saveEnable:true},
      modbusDevicesEdit: {title: "Modbus Devices Edit",saveEnable:true},
      modbusDevicesEdit2: {title: "Modbus Devices Edit",saveEnable:true},
      manageZones2: {title:"Manage Zones",saveEnable:true},
      manageGroups: {title:"Manage Groups",saveEnable:true},
      userProfile: {title:"User Profile",saveEnable:true},
      selectSensors: {title:"Select Sensors",saveEnable:true},
      selectSensors800: {title:"Select Sensors 800",saveEnable:true,controller:800},
      systemStatus: {title:"System Status",saveEnable:false},
      syncStatus: {title:"Sync Status",saveEnable:false},
      testReportWriter: {title:"Test Report Writer",saveEnable:true},
      manageSubscription: {title:"Manage Subscription",saveEnable: true},
      editInfoPages: {title:"Edit InfoPages",saveEnable: false},
      techPortal: {title:"Tech Portal",saveEnable: false},
      techPortal2: {title:"Tech Portal",saveEnable: false},
      salesPortal: {title:"Sales Portal",saveEnable: false},
      vidTest: {title:"Video Test",saveEnable: false},
      cameras: {title:"Cameras",saveEnable: false},
      manageWatch: {title:"Manage Watch",saveEnable: false},
      pageAccesses: {title:"Page Accesses",saveEnable: false},
      editUser: {title:"Add User"},
      modbusDevicesEdit: {title:"Manage Devices"},
      selectGroup: {title:"Group Select",saveEnable: true},
      editGroup: {title:"Group Edit",saveEnable: true},
      applyGroup: {title:"Group Apply",saveEnable: true},
      autoSetup: {title:"Auto Setup",saveEnable: true},
      qrcode: {title:"QR Code Sender",saveEnable: false},
      qrcodeReceive: {title:"QR Code Receiver",saveEnable: true},
    }
//     cl("url admin")
//     cl(parts[pos])
    this.mySetState("urlAdmin", {
      mode:"admin",savePage: (pageTitles[parts[pos]]||{}).saveEnable,
      pageModified:false,/*sideBarOpen:false,*/
      imageDisplay:[],
      site:this.getSiteId(parts,4),
      zone:parts[6],
      adminPage:parts[pos],
      adminInfo:parts[pos+1],
      pageTitle:(pageTitles[parts[pos]]||{}).title})
//     cl("admin")
  }
  
  urlFui=(parts,pos)=>{// at this point, zuci is just "parts[5]"
//     cl("urlFui")
    // cl(parts)
//     cl([this.props,this.state])
//     cl(this.props)
//     cl(this.state)
//     cl(parts)
    let fuiMode=parts[3]
//     cl(fuiMode)
//     cl(pos)
    let sidebarMode=(parts[8]||"SPgrowJournal").substring(2)
    let sideBarNoteId = ""
    if (["SPgrowJournal", "SPtasks"].includes(parts[8])) {
        sideBarNoteId = parts[9]||""
    }
    let url=`/usa/c18/fui/${parts[pos]}/${parts[pos+1]}`
//     cl(globs)
    let site=globs.userData.session.siteId
    let zuci=parts[pos+1]
    let zParts=zuci.split("-")
//     cl(zParts)
//     cl(site,zParts)
    let zone=getZoneId(site,+zParts[0])// siteZoneIndex
    if(this.badReadPriv("zone",zone)){return}
//     cl(site)
//     cl(globs)
//     cl(url)
//     cl([url,parts[4],parts[5],site,zone])
//     cl("rerender")
    this.mySetState("urlFui", {mode:"fui",url:url,pageModified:false, fullWidth:false, imageDisplay:[],pageType:parts[pos],fuiMode:fuiMode,
      zuci:parts[pos+1],sidebarMode:sidebarMode,site:site,zone:zone, sideBarNoteId:sideBarNoteId})
//     cl("new cont1")
//     cl("urlFui publishes newContext")
    globs.events.publish("newContext",{level:"config",siteId:site,zoneId:zone,pageType:parts[4],zuci:parts[5]})
//     cl("New Context in urlFui Done")
//     cl("url fui")
//     cl("admin")
  }
  
  urlGraphing=(parts)=>{
//     cl(this.props)
//     cl(this.state)
// //         this.mySetState({mode:"zones",savePage: false,site:this.getSiteId(parts,4),zone:this.getZoneId(parts[6])})
    switch(parts[7]){
      case "graphing":
        this.mySetState("urlGraphing", {mode:"graph",savePage: false,pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[],
          site:this.getSiteId(parts,4),zone:this.getZoneId(parts,6)})//
        break
      case "sensorGraph":
        let str=parts[8]
        let sensors=str.split("-")
//         cl(sensors)
        let period=parts[9]
//         cl(period)
        let zoneId=this.getZoneId(parts,6)
        globs.events.publish("newContext",{level:"graphing",zone:zoneId,sensors:sensors})
        this.mySetState("urlGraphing", {mode:"graph",savePage: false,pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[],
          site:this.getSiteId(parts,4),zone:zoneId,sensors:sensors,period:period})//
        break
    }
/*    
    this.mySetState("urlGraphing", {mode:"graph",savePage: false,pageModified:false,sideBarOpen:false, imageDisplay:[],
      site:this.getSiteId(parts,4),zone:this.getZoneId(parts,6)})//
//     cl("admin")*/
  }
  
  urlReports=async(parts)=>{
//     cl(globs.presetsInfo.info)
//     cl(parts)
    await loadReportsInfo()// it seems like there should be a 
    await loadPresetsInfo()
    await loadSummaryPresetsInfo()
    await loadSchedulesInfo()
    // await loadAnalysisInfo()
//     cl(globs)
//     cl(globs)
    let reportId=this.getReportId(parts, 5)
//     cl(reportId)
    let reportName=""
    let scheduleName=""
    let rInd=getReportsIndex(reportId)
    let sInd=getSchedulesIndex(reportId)
    let gInd=getPresetIndex(reportId)
    let aInd=getSummaryPresetIndex(reportId)
//     cl(gInd)
    
    reportName=(globs.reportsInfo.info[rInd])?globs.reportsInfo.info[rInd].title:""
    scheduleName=(globs.schedulesInfo.info[sInd])?globs.schedulesInfo.info[sInd].name:""
    let graphName=(globs.presetsInfo.info[gInd]?.name||"")
    let summaryName=(globs.summaryPresetsInfo.info[aInd]?.name||"")
//     cl(globs.schedulesInfo.info)
//     cl(globs.schedulesInfo.info[sInd])
    let pageTitles={
      "editReport":`Edit ${reportName} Report`,
      "editSchedule":`Edit ${scheduleName} Schedule`,
      "reportList":"Report List",
      "scheduleList":"Schedule List",
      "graphList":"Graph List",
      "viewReport":`View ${reportName} Report`,
      "graphs":`Graphs`,
      "viewGraph":`${graphName} Graph`,
      "editGraph":`${graphName} Graph`,
      "editSummary": `Edit ${summaryName} Analysis`,
      "summaryList":"Summary List",
      "viewSummary":`View ${summaryName} Analysis`,
    }
//     cl(parts)
    this.mySetState("urlReports", {
      mode:"reports",
      savePage: false,
      pageModified:false,/*sideBarOpen:false,*/ 
      imageDisplay:[],
      reportId: reportId, 
      pageType:parts[4],
      pageTitle:pageTitles[parts[4]]})
  }
  
  urlIDoser=(parts,pos)=>{
//     cl(parts)
    let siteId=parts[4]
    let zoneId=parts[6]
    this.mySetState("urlIDoser", {mode:"idoser",site:siteId,zone:zoneId,savePage: false,pageModified:false,pageType:parts[pos]})
  }
  
  urlSensor=(parts,pos)=>{
    // cl(parts)
    let siteId=parts[4]
    let zoneId=parts[6]
//     cl(parts[pos])
    switch (parts[9]) {
        case "manageAlarms2":
            return this.urlManageAlarms2(parts, 10)
    }
    let sidebarMode=(parts[9]||"SPgrowJournal").substring(2)
    let sideBarNoteId = ""
    if (["SPgrowJournal", "SPtasks"].includes(parts[9])) {
        sideBarNoteId = parts[10]||""
    }
    
    this.mySetState("urlSensor", {mode:"sensor",site:siteId,zone:zoneId,savePage:
      true,pageModified:false,pageType:parts[pos],zuci:parts[pos-1], sidebarMode:sidebarMode,sideBarNoteId:sideBarNoteId})
    this.doNotify("newSensor","newSensor",parts[pos])// to Sensor00 page
  }
  
  urlModbus=(parts,pos)=>{
//     cl(parts)
    let siteId=parts[4]
    let zoneId=parts[6]
    this.mySetState("urlModbus", {mode:"modbus",site:siteId,zone:zoneId,savePage: true,pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[],pageType:parts[pos]})
  }

  urlModbusGp=(parts,pos)=>{
//     cl(parts)
    let siteId=parts[4]
    let zoneId=parts[6]
    this.mySetState("urlModbusGp", {mode:"modbusGp",site:siteId,zone:zoneId,savePage: true,pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[],pageType:parts[pos]})
  }

  urlManageAlarms2=(parts,pos)=>{
   // cl(parts)
   // cl(pos)
   let siteId=parts[4]
   let zoneId=parts[6]
   let sensorId=parts[8]
   let tab=parts[pos]
   this.mySetState("urlManageAlarms2", {mode:"manageAlarms2",site:siteId,zone:zoneId,tab:tab,sensor:sensorId,savePage: true,pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[],pageType:parts[pos]})
  }

  urlModbusDevicesEdit=(parts,pos)=>{
    // cl(parts)
    // cl(pos)
    let siteId=parts[4]
    let zoneId=parts[6]
    switch (parts[8]) {
      case "deviceId":
          return this.urlModbusDevicesEdit2(parts, pos)
    }
    this.mySetState("urlModbusDevicesEdit", {mode:"modbusDevicesEdit",site:siteId,zone:zoneId,savePage: true,pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[],pageType:parts[pos]})
  }

  urlModbusDevicesEdit2=(parts,pos)=>{
    cl(parts)
    cl(pos)
    let siteId=parts[4]
    let zoneId=parts[6]
    let tab=parts[9]
    let type=parts[11]
    this.mySetState("modbusDevicesEdit2", {mode:"modbusDevicesEdit2",site:siteId,zone:zoneId,tab:tab,type:type,savePage: true,pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[],pageType:parts[pos]})
  }
  
  urlPearl=(parts,pos)=>{
//     cl(parts)
    let siteId=parts[4]
    let zoneId=parts[6]
    this.mySetState("urlModbus", {mode:"pearl",site:siteId,zone:zoneId,savePage: true,pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[],pageType:parts[pos]})
  }

  urlTasks=(parts,pos)=>{
//     cl(parts)
    let scope=["sites","site","zones"][Math.floor(pos/2)-2]// pos=4,6,8
    this.mySetState("urlTasks", {mode:"tasks",savePage: false,
      pageTitle:"Tasks",
      pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[],
      adminInfo:parts[pos]||"",
//       pageType:modes[pos/2-2],
      pageSubType:parts[pos-1]||"",
      scope:scope,
      site:this.getSiteId(parts,4)||"",
      zone:this.getZoneId(parts,6)||"",
    })
//     cl("state set in tasks")
  }
  
  urlCropRecipes=(parts,pos)=>{
//     cl(parts)
//     let scope=["sites","site","zones"][Math.floor(pos/2)-2]// pos=4,6,8
    this.mySetState("urlCropRecipes", {mode:"cropRecipes",savePage: false,
      pageTitle:"Crop Recipes",
      pageType:parts[5]||parts[4],// recipes only has 4
//       pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[],
      adminInfo:parts[pos]||"",// cropRecipeId
      adminInfo2:parts[pos+2]||"",//cropPhaseId
//       pageSubType:parts[pos-1]||"",
// //       scope:scope,
//       site:this.getSiteId(parts,4)||"",
//       zone:this.getZoneId(parts,6)||"",
    })
  }
  
  urlSvgEditor=(parts,pos)=>{
    this.mySetState("urlSvgEditor", {mode:"svgEditor",savePage: true,
      pageTitle:"SVG Editor",
    })
  }
  
  urlPhysView=(parts,pos)=>{
    cl(parts)
//     return
    this.mySetState("urlPhysView", {mode:"physView",savePage: false,
      pageTitle:"Physical View",
    })
  }
  
  urlServerLog=(parts,pos)=>{
//     cl(parts)
//     return
    this.mySetState("urlServerLog", {mode:"serverLog",savePage: false,
      pageTitle:"Server Log",
    })
  }

  urlDevHome=(parts,pos)=>{
    cl("home")
    this.mySetState("urlHome", {
      pageTitle:"Dev Home",
      mode:"devHome",
      savePage: false,
      pageModified:false,
//       sideBarOpen:false, 
      imageDisplay:[],
      site:this.getSiteId(parts,4),
      zone:parts[6],
      messageId:parts[5],
      pageType:parts[4]})
  }
  
  urlMessaging=(parts,pos)=>{
    this.mySetState("urlMessaging", {
      pageTitle:"Messaging",
      mode:"messaging",
      savePage: false,
      pageModified:false,
//       sideBarOpen:false, 
      imageDisplay:[],
      scope:"",
      site:this.getSiteId(parts,4),
      zone:parts[6],
      messageId:parts[5],
      pageType:parts[4]})
  }
  
  url3d=(parts,pos)=>{
    this.mySetState("url3d", {
      pageTitle:"url3d",
      mode:"threeD",
      savePage: false,
      pageModified:false,
      sideBarOpen:false, 
      imageDisplay:[],
      site:this.getSiteId(parts,4),
      zone:parts[6],
      pageType:parts[4]})
  }
  
  urlAlarmLog=(parts,pos)=>{
    this.mySetState("urlAlarmLog", {mode:"alarmLog",savePage: false,pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[],site:this.getSiteId(parts,4),zone:parts[6],pageType:parts[4]})
  }
  
  urlGeneralInfo=(parts,pos)=>{
    this.mySetState("urlGeneralInfo", {mode:"generalInfo",savePage: false,pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[],site:this.getSiteId(parts,4),zone:parts[6],pageType:parts[4]})
  }
  
  urlSaveRestore=(parts,pos)=>{
    this.mySetState("urlSaveRestore", {mode:"saveRestore",savePage: false,pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[],site:this.getSiteId(parts,4),zone:parts[6],pageType:parts[4]})
  }
  
  urlDefault=()=>{
    this.mySetState("urlDefault", {mode:"login",savePage: false,pageModified:false,/*sideBarOpen:false,*/ imageDisplay:[]})
  }
  
  urlLoginRegister=(parts)=>{
//     cl(parts)
//     cl("set loaded loginReg")
    this.mySetState("urlLoginReg", {loaded:true, mode:parts[3],savePage: false,/*sideBarOpen:false,*/ imageDisplay:[],token:parts[4]})
  }
  
  checkPageSave=async()=>{
    if(this.pageSaveOkNeeded()){
      let popInfo={text:"Do you want to save your changes to this page?", buttons:["No","Yes"]}
      let res=await this.getPopup("admin",popInfo)
      if(res=="Yes"){
        globs.events.publish("savePageEvent","save")
        globs.events.publish("savePageEnable",false)
      }
    }
  }

  checkTrialPopup=async()=>{
//     cl("check")
    let ret = await this.trialPopupNeeded()
    if(ret[0]){
      let text = ret[1]
      // check if user is admin/owner
      let buttons = ["OK, Remind Me Later"]
      let owner = checkOwner()
      let admin = await checkAdmin()
//       cl([owner, admin])
      if (owner || admin) {
        buttons.push("Manage Subscription")
      } else {
        text += " Please contact the owner or an admin of this account to manage licenses."
      }
//      if (owner || admin) buttons.push("Manage Subscription")// alternate for above
      let res=await this.getPopup("admin", {text:text, buttons:buttons})
      if (res=="Manage Subscription") {
        history.push("/usa/c18/admin/manageSubscription")
      }
    }
  }

  checkPastDue=async()=>{
    let ret = await this.pastDuePopupNeeded()
    if(ret[0]){
      let text = ret[1]
      // check if user is admin/owner
      let buttons = ["OK"]
      let owner = checkOwner()
      let admin = await checkAdmin()
//       cl([owner, admin])
      if (owner || admin) {
        buttons.push("Manage Subscription")
      } else {
        text += " Please contact the owner or an admin of this account to manage account payment status."
      }
//      if (owner || admin) buttons.push("Manage Subscription")// alternate for above
      let res=await this.getPopup("admin", {text:text, buttons:buttons})
      if (res=="Manage Subscription") {
        history.push("/usa/c18/admin/manageSubscription")
      }
    }
  }

  handleNewTab=(parts)=>{
//     cl("handle tab "+parts[4])
//     cl(globs.userData)
    globs.userData.session={sessionId:parts[4]}
  }
  
  procUrl=async(url)=>{
//    this.setState({mode:undefined})
//     cl(globs?.userData?.session?.siteId)
//     cl(url)
//     cl(show("main"))
//    cl("host")
    saveBrowserAccess(`Proc: ${url}`)
    cl.octSaveUrl(url)
//     globs.curUrl=url
    this.props.onChange({cmd:"procUrl",url:url})
//    cl("host")
//     cl(globs.userData.loggedIn)
    if(globs.userData.loggedIn&&globs.requestedUrl){
//       cl(globs.requestedUrl)
      url=globs.requestedUrl
//       cl("nulled")
      globs.requestedUrl=null
    }
    cl(`ProcUrl: ${url}`)
//     cl(`at: ${getTimeI()}`)
    if(!globs.serverOK){return}
//     cl(globs.userData)
    let parts = url/*this.state.url*/.split("/")
//     cl(parts)
    if(["serverLog"].includes(parts[3])){this.handleNewTab(parts)}
    if(globs.userData.loggedIn){// logged in - or not!// ?.session
//       cl("wait")
//       cl(globs?.userData?.session)
      await loadZonesInfo()// this is where the big delay is
//       cl("load privs")
      await loadPrivsInfo()
//       cl("load presets")
      await loadPresetsInfo()
//       cl("load privs")

      // set device theme
      let themes={originalLight:"style.css",originalDark:"style_dark.css"}
      await loadDevicesInfo()
      await loadSubscriptionInfo()
      this.loadCss(themes[globs?.device?.deviceTheme]||"style.css")
      if(!(getSuperUserFlags()&(constant.SUPER_PRIVS_ADMIN)) && !this.loginType() && !["demo", "mjdemo", "mjdemoRemote"].includes(config.host)){
          await this.checkTrialPopup()
          await this.checkPastDue()
      }
//       cl("procurl")
      // let device = globs.devicesInfo.info.find((d) => (d.deviceId == globs.userData.session?.uId)&&(d.userId==globs.userData.session?.userId))
//       console.log(device?.deviceTheme)
//       this.loadCss(themes[device?.deviceTheme]||"style.css")
//       cl(device?.deviceTheme)
//       cl("load privs")
//       cl("logged in")
    }else{// not logged in
      cl(show("main"))

//       cl(globs.devicesInfo.info)
//       cl("load privs")
//       cl(this.loginType())
      if(!this.loginType()){//parts[3]!="login"
//         cl("do login")
        if(await login()){// successful login
//           cl(globs.userData.session.features)
//           cl("login return")
//           cl(globs?.userData?.session?.siteId)
//             cl("success")
            await this.loadInfo()
//             cl("check2")
            let is3P=(globs.privsInfo.thirdPartyAccounts?.length>1)
            if(!(getSuperUserFlags()&(constant.SUPER_PRIVS_ADMIN)) && (!is3P) &&
              !this.loginType() && !["demo", "mjdemo", "mjdemoRemote"].includes(config.host)){
                await this.checkTrialPopup()
            }
//             cl("done")
        }else{
          console.log("loading light mode since not logged in")
          this.loadCss("style.css")
//           cl("not logged in, saving url")
//           cl(url)
          if((url.indexOf("logout")<0)&&(url.indexOf("login")<0)){
            globs.requestedUrl=url            
          }
          return
        }
//         return
      }
//       cl("not logged in")
    }
//       cl("load privs")
//     cl(show("main"))
    if(!this.loginType()){await this.checkPageSave()}
//       cl("load privs")
//       cl(parts)
    if(parts[3]!="fui"){globs.events.publish("savePageEnable",false)}
//     globs.userData.siteSelected="nobody"
//     cl(parts);
//     cl(`C18Host procUrl: ${parts[3]}`)
    switch(parts[3]){
      case "login":
      case "logout":
      case "register":
      case "devRegister":
      case "postRegister":
      case "activate":
      case "inviteUser":
      case "resetPassword":
        return this.urlLoginRegister(parts)
      case "sites":
        return this.urlSites(parts)
      case "graphing":
        return this.urlGraphing(parts)
      case "admin":
//         cl("admin")
        return this.urlAdmin(parts,4)// 4 is position of the type of admin
      case "fui":
      case "zgfui":
        return this.urlFui(parts,4)
      case "idoser":
        return this.urlIDoser(parts,4)
      case "modbus":
        return this.urlModbus(parts,4)
      case "modbusGp":
        return this.urlModbusGp(parts,4)
      case "manageAlarms2":
        return this.urlManageAlarms2(parts,4)
      case "modbusDevicesEdit":
        return this.urlModbusDevicesEdit(parts,4)
      case "modbusDevicesEdit2":
        return this.urlModbusDevicesEdit2(parts,4)
      case "reports":
        return this.urlReports(parts)
      case "tasks":
        return this.urlTasks(parts,4)
      case "alarmLog":
        return this.urlAlarmLog(parts)
      case "messaging":
        return this.urlMessaging(parts,4)
      case "home":
        return this.urlDevHome(parts,4)
      case "3d":
        cl("got 3d")
        return this.url3d(parts,4)
      case "cropRecipes":
//         cl("got recipes")
        return this.urlCropRecipes(parts,4)
      case "svgEditor":
        return this.urlSvgEditor(parts,4)
      case "physView":
        return this.urlPhysView(parts,4)
      case "serverLog":
        return this.urlServerLog(parts,4)
      default:
        return this.urlDefault()
    }
  }
  
  onChangeSensorEquip=(vals)=>{
//     cl(vals)
    let baseUrl=`/usa/c18/sites/${this.state.site}/zones/${this.state.zone}`
//     cl(baseUrl)
    switch(vals.cmd){
      case "equipmentWrench":
        let url=`${baseUrl}/settings/equipment`
        history.push(url)
        this.procUrl(url)
        break
      case "sensorWrench":
        cl(vals)
        vals.e.preventDefault()
        break
      default:
        break
    }
  }
  
  makeSiteZoneUrl=(vals)=>{
// /usa/c18/admin/selectSensors
//     cl(vals)
    let parts=vals.data.url.split("usa/c18/")
    let urlPart=""
    if(vals.data.site){urlPart+=`sites/${vals.data.site}/`}
    if(vals.data.zone){urlPart+=`zones/${vals.data.zone}/`}
    let url=`${parts[0]}usa/c18/${urlPart}${parts[1]}`
//     cl(url)
    return url
//     cl(url)
//     cl(vals)
//     return vals.data.url
  }
  
  makeFuiUrl=async(url)=>{
//     cl("make fui url")
    let chans={"3200_setpoints":240,unit_Mixing_Tanks:192,zone_Setpoints:255}
//     cl("make fui url")
//     cl(this.props)
//     cl(this.state)
//     cl(url)
    await loadZonesInfo()
    // cl(this.state.zone)
    let zoneId=(acctFeature("zoneGroups")&&globs.userData.session.groupId)?
      globs.userData.session.groupZoneId:
      this.state.zone
    let zone=getZoneInfo(zoneId)
    // cl(zone)
    let pos=url.indexOf("/0-")
    let parts=url.substr(1+pos).split('-')
    let urlParts=parts[0].split("/")
//     cl(urlParts)
//     let contCh=(this.state.selController||0)?+this.state.selController+240:240
    let unit=this.state.selController||0
    let contCh=240
//     cl(contCh)
    let chan=chans[urlParts[2]]||contCh
//     cl(chan)
    let ch=(+parts[2])||chan
//     cl(parts)
//     cl(pos)
    let suffix=`/${zone.siteZoneIndex}-${unit}-${ch}-`
    if(pos>=0){
      let pos2=url.lastIndexOf("-")
      suffix+=url.substr(pos2+1)
      url=url.substr(0,pos)
    }else{
      suffix+="0"
    }
    let ret=`/usa/c18${url}${suffix}`
//     cl(ret)
    return ret
    
  }
  
  onChangeSettings=async(vals)=>{
/*for selectSensors:
/usa/c18/sites/l3tj4pJ0mUASoFzc/zones/IDFWgZvenLyUSBdk/settings/selectSensors
/usa*/
//     cl(vals)
    var url
    switch(vals.cmd){
      case "breadcrumbs":
      case "pageTitle":
        this.mySetState("pageTitle",vals.data)
        break
      case "setUrl":
        switch(vals.data.mode){
          case "usa":
            url=vals.data.url
//             cl(url)
//             cl(vals)
            if(vals.data.site){
              url=this.makeSiteZoneUrl(vals)
              
            }
            history.push(url)
            break
          case "fui":
//             let url=this.makeFuiUrl(vals.data.url)
//             history.push(this.makeFuiUrl(vals.data.url))
            url=await this.makeFuiUrl(vals.data.url,vals.data.controllerSel)
//             cl(url)
            history.push(url)
//             history.push(`/usa/c18${vals.data.url}/0-0-0-0`)
            break
          case "settings":
            url=this.props.url
//             cl(url)
            let pos=url.indexOf("/settings")
            url=url.substr(0,pos+9)+vals.data.url
            history.push(url)
            break
          case "idoser":
            cl("idoser mode")
            url=vals.data.url
//             url=this.props.url
            cl(url)
//             let pos=url.indexOf("/settings")
//             url=url.substr(0,pos+9)+vals.data.url
            history.push(url)
            break
          case "modbus":
          case "modbusGp":
            url=vals.data.url
            history.push(url)
            break
          case "manageAlarms2":
//             cl("idoser mode")
            url=vals.data.url
//             cl(url)
            history.push(url)
           break
          case "modbusDevicesEdit":
            url=vals.data.url
            history.push(url)
           break
          case "modbusDevicesEdit2":
            url=vals.data.url
            history.push(url)
           break
          case "cropRecipes":
            url=vals.data.url
            history.push(url)
            break
          case "pearl":
            url=vals.data.url
            history.push(url)
            break
        }
        break
      case "selController":
        delete vals.cmd
        this.setState(vals)// selController
        break
    }
  }
  
  onChangeBreadcrumbsOnly=(vals)=>{
    let st=this.state
    switch(vals.cmd){
      case "breadcrumbs":
      case "pageTitle":
        this.myPageTitle=vals.pageTitle
        if(this.myPageTitle){
          this.setState({pageTitle:this.myPageTitle})
        }
//         cl(vals)
      case "savePage":
//         cl(vals.data)
        this.mySetState("savePage",vals.data)
        break
      case "saveHeader":
        this.mySetState("saveEnable",{pageModified:vals.data.saveEnabled})
        break
//       case "clearSave":
//         cl("clearsave")
//         this.mySetState("clearSave",vals.data)
//         cl(vals.data)
//         cl(this.state.pageModified)
//         window.location.reload()
//         break
      case "fullWidth":
//         cl("full width")
        this.mySetState("fullWidth",{fullWidth:true})
        break
//         this.setState(vals.data)
//         cl(this.state)
      case "idoserPage":
//         cl(this.state)
//         cl(this.props)
//         cl(`/usa/c18/idoser/${vals.pageType}`)
        history.push(`/usa/c18/sites/${st.site}/zones/${st.zone}/idoser/${vals.pageType}`)
        break
      case "modbusPage":
        history.push(`/usa/c18/sites/${st.site}/zones/${st.zone}/modbus/${vals.pageType}`)
//         history.push(`/usa/c18/modbus/${vals.pageType}`)
        break
      case "manageAlarms2Page":
        history.push(`/usa/c18/sites/${st.site}/zones/${st.zone}/manageAlarms2/levels`)
      break
      case "modbusDevicesEditPage":
        history.push(`/usa/c18/sites/${st.site}/zones/${st.zone}/modbusDevicesEdit`)
      break
      case "modbusDevicesEdit2Page":
        history.push(`/usa/c18/sites/${st.site}/zones/${st.zone}/modbusDevicesEdit/deviceId/${st.tab}/typeId/${st.type}`)
      break
      case "reportsPage":
        history.push(`/usa/c18/reports/${vals.pageType}`)
        break
      case "cropRecipesPage":
//         cl(vals)// pageType = 'phases'
//         cl(st)
        switch(vals.pageType){
          case "recipes":
            return history.push(`/usa/c18/cropRecipes/${vals.pageType}`)
          case "phases":
            if(st.adminInfo=="recipes"){
              return history.push(`/usa/c18/cropRecipes/${vals.pageType}`)
            }else{
              return history.push(`/usa/c18/cropRecipes/${st.adminInfo}/${vals.pageType}`)
            }
          case "days":
            return history.push(`/usa/c18/cropRecipes/${st.adminInfo}/${vals.pageType}/new`)
        }
      case "menuPage":
//         cl(vals)
//         cl(`change menu page to /usa/c18/fui/${vals.pageType}/${st.zuci}`)
        history.push(`/usa/c18/fui/${vals.pageType}/${st.zuci}`)
        // this.procUrl(url)
        break
      default:
        break
      case "pageTitle":
        this.mySetState("pageTitle",vals.data)
//         cl(vals)
        break
    }
  }
  
  onChangeSitesListTiles=(vals)=>{
    switch(vals.cmd){
      case "breadcrumbs":
        this.mySetState("breadcrumbs",vals.data)
        break
    }
    cl(vals)
  }
  
  onChangeAdmin=(vals)=>{
    switch(vals.cmd){
      case "breadcrumbs":
        this.mySetState("breadcrumbs",vals.data)
        break
    }
  }
  
//   setShowCamera=(cam)=>{
//     cl("show camera")
//     cl(cam)
// //             return this.showImage(vals.vals)
//   }
  
  showCamera=(idsp,i)=>{
//     cl(idsp)
    return(
      <div className="image-popup" key={i} 
            style={{borderRadius: 10,
              boxShadow: "5px 10px 10px #888888"}}>
        <VidTimeline00 parms={{
          camera:idsp.id.substring(4)
        }}
        />
        <div className="image-close-button">
          <UsaIcon icon={`im${i}-Cancel`} result={o=>this.onChange("imageIcon",o)}/>
        </div>
      </div>
    )
//           <div key={i} style={{width: w, height: h}} className="image-popup"
//             >
//             <img width={w} src={`${constant.expressUrl}/usa/images/uploads/${path}`}
//             style={{backgroundColor: "#C0FFC0", borderRadius: 10, 
//               boxShadow: "5px 10px 10px #888888"}}
//             onMouseDown={e=>this.mouseDownImage(e,i)} onMouseMove={this.mouseMoveImage} onMouseUp={this.mouseUp} 
//             onDragStart={this.dragStartImage} onDragEnd={this.dragEnd}/>
//             <div className="image-close-button">
//               <UsaIcon icon={`im${i}-Cancel`} result={o=>this.onChange("imageIcon",o)}/>
//             </div>
//             
//           </div>
  }

  upload800=async(zoneInfo)=>{
    cl("upload 800")
    wsTrans("usa", {cmd: "cRest", uri: "/s/iGrow800Controller", method: "retrieve",
      sessionId: globs.userData.session.sessionId,
      body: {
//         cmd2:vals.val.wrenchCmd,// eraseDB
        siteId:zoneInfo.siteId,
        gatewayId:zoneInfo.gatewayId,
        zone:+zoneInfo.siteZoneIndex}})
  }
  
  onChangeZoneSensorsEquipment=async(vals)=>{
//     cl(vals)
    let gi=(acctFeature("zoneGroups"))?getGroupInfo(globs.userData.session.groupId):null
    let zoneStr=(gi)?gi.groupId:this.state.zone
    let baseUrl=`/usa/c18/sites/${this.state.site}/zones/${zoneStr}`
    var url
    let zoneInfo=getZoneInfo(this.state.zone)
//     cl(this.state)
//     cl(zoneInfo)
//     cl(globs.zonesInfo)
    switch(vals.cmd){
      case "breadcrumbs":
        this.mySetState("breadcrumbs",vals.data)
        break
      case "sensorWrench":
//         cl(vals)
        switch(vals.val.wrenchCmd){
          case "wrench":
            url=`${baseUrl}/settings/sensor${zoneInfo.gatewayType||1800}`
//             cl(url)
            break
          case "upload":
            vals.val.e.preventDefault()
//             cl(zoneInfo)
            if(zoneInfo.gatewayType==800){return this.upload800(zoneInfo)}
            let body={
              cmd:"syncPacks",
              gatewayId:zoneInfo.gatewayId,
              siteZoneIndex:zoneInfo.siteZoneIndex}
            wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "update",
              sessionId: globs.userData.session.sessionId, body:body})

//             wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "retrieve",
//               sessionId: globs.userData.session.sessionId, body:
//               {gatewayId:zoneInfo.gatewayId,
//                 cmd:"readWholeController",
//                 siteZoneIndex:zoneInfo.siteZoneIndex,
//                 zone: zoneInfo.zoneId}})
            return
          case "eraseDB":
            vals.val.e.preventDefault()
            cl("eraseDB")
            wsTrans("usa", {cmd: "cRest", uri: "/s/packCmd", method: "create", 
              sessionId: globs.userData.session.sessionId,
              body: {cmd2:vals.val.wrenchCmd,// eraseDB
                siteId:zoneInfo.siteId,
                gatewayId:zoneInfo.gatewayId,
                zone:+zoneInfo.siteZoneIndex}})
            return
          case "charts":
            url=`${baseUrl}/graphing`
            break
          default:
            break
        }
//         if(vals.val.wrenchCmd=="wrench"){
//         }else{
//         }
        history.push(url)
        this.procUrl(url)
        break
      case "equipmentWrench":
        // change url to accomodate stage
        switch(vals.val.wrenchCmd){
          case "stages":
            cl("staging")
            // cl(this.state)
            // cl(this.props)
            // direct to staging page
            let stagingSuffix=(zoneInfo.gatewayType==800)?"_800":""
            url= await this.makeFuiUrl(`/fui/temp_Staging${stagingSuffix}`)
            break
          default:
            url=`${baseUrl}/settings/equipment${zoneInfo.gatewayType||1800}`
            break
        }
        history.push(url)
        this.procUrl(url)
        break
      case "camera":
//         this.setShowCamera(vals.cam)
        let idsp=this.state.imageDisplay.slice(0)
        vals.cam.type="camera"
        idsp.push(vals.cam)
        this.setState({imageDisplay:idsp})
        break
    }
  }
  
/***************** Image Handling, from Grow Journal *********************/  
  imageDisplayRender=()=>{
    // cl(this.state.mode)
    let showImages=
      ((this.state.sideBarOpen)&&(
      (this.state.sidebarMode=="growJournal")||
      (this.state.sidebarMode=="images")))||
      (this.state.mode=="messaging")||
      (this.state.mode=="graphing")||
      (this.state.mode=="reports") ||
      (this.state.mode=="sensor")
    if(!showImages){return}
//       cl(this.state.imageDisplay)
      return this.state.imageDisplay.map((idsp, i)=>{
        if(idsp.type=="camera"){return this.showCamera(idsp,i)}
        let ii=idsp.imageInfo
        let path=idsp.path
        let w=ii.w
        let h=ii.h
        let dw = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) / 2
        let dh = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) / 1.5 
        if(w>dw){
          h=h*dw/w
          w=dw
        }
        if(h>dh) {
          w=w*dh/h
          h=dh
        }
        if(h>1000) {
          w=w*1000/h
          h=1000
        }

        return(
          <div key={i} style={{width: w, height: h}} className="image-popup"
            >
            <img width={w} src={`${constant.expressUrl}/usa/images/uploads/${path}`}
            style={{backgroundColor: "#C0FFC0", borderRadius: 10, 
              boxShadow: "5px 10px 10px #888888"}}
            onMouseDown={e=>this.mouseDownImage(e,i)} onMouseMove={this.mouseMoveImage} onMouseUp={this.mouseUp} 
            onDragStart={this.dragStartImage} onDragEnd={this.dragEnd}/>
            <div>
               <div className="image-close-button" style={{right: -23}}>
                 <UsaIcon icon={`im${i}-Cancel`} result={o=>this.onChange("imageIcon",o)}/>
               </div>

               <div style={{position: "absolute", top: 24, right: -23, backgroundColor: "#FFFFFF"}}>
                
              <div className="image-button">
              <a id="downloadFile" className="download-file" 
               onClick={async(e)=>this.downloadFile(`${constant.expressUrl}/usa/images/uploads/${path}`, `${path}`)}>
          
                <span className="material-icons">
                  file_download
                </span>
              </a>
              </div>
              <div className="image-button">
              <a href={`${constant.expressUrl}/usa/images/uploads/${path}`} target="_blank">
                <span className="material-icons-outlined">
                open_in_new
                </span>
              </a>
              </div>
               </div>
            </div>
            
          </div>
        )
      })
  }

  downloadFile=(url, name)=>{
    // cl("downloading file to")
    // cl([url, name])
     let method="GET"
    let query = `cmd=download&name=${name}`
    // let query = `cmd=download&name=${name.replaceAll(\s, "_")}`
    var a = document.createElement("a");
    // cl(`${url}?${query}`)
    a.href = `${url}?${query}`
    a.target = "_blank"
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

/******************** Drag / Drop Code ***************************/

/* this is going to get reworked to *not* change the state*/

  mouseDownImage=(ev,i)=>{// this has to distinguish between images, and widgets!
    globs.dragging=true
    let ii=this.state.imageDisplay[i].imageInfo
    let upd={dragStart: {i: i, x: ev.clientX, y: ev.clientY,
      x0: ii.x, y0: ii.y}, drag: {x: ev.clientX, y: ev.clientY}}
          //cl("setState")
    this.mySetState("mouseDownImage",upd)
  }
  
  mouseMoveImage=(ev)=>{
    if(this.state.dragStart){
      let upd={drag: {x: ev.clientX, y: ev.clientY}}
      let i=this.state.dragStart.i
      let ii=this.state.imageDisplay[i].imageInfo
      let drs=this.state.dragStart
      let drg=this.state.drag
      ii.x=drg.x-drs.x+drs.x0
      ii.y=drg.y-drs.y+drs.y0
          //cl("setState")
      this.mySetState("mouseMoveImage",upd)
    }
  }
  
  dragStartImage=(ev)=>{
    ev.preventDefault()
  }
  
  mouseUp=(e)=>{
    globs.dragging=false
    this.mySetState("mouseup",{dragStart: null})
  }
  
/******************** End Drag / Drop Code ***************************/
  loadImage=(path)=>{
//     cl(`load ${path}`)
    return new Promise((r,e)=>{
      let img=new Image()
      img.src=`${constant.expressUrl}/usa/images/uploads/${path}`
      img.onload=(l=>{r(img)})
    })
  }
  
  gotImage=(path)=>{
    for(let i=0;i<this.state.imageDisplay.length;i++){
      let im=this.state.imageDisplay[i]
      // cl(im)
      // cl(path == im.path)
      if(path==im.path){return true}
    }
  }

  showImage=async(vals)=>{
    // cl(vals)
    let path=vals.path
    if(this.gotImage(path)){return}
    let im=await this.loadImage(path)
//     cl(im.width)
//     cl(path)
    let s=vals.section||{}
    s.w=im.width
    s.h=im.height
    s.x=s.x||0+Math.floor(500*Math.random())
    s.y=s.y||100+Math.floor(500*Math.random())
//     let images=this.state.imageDisplay.slice(0)
//     images.push({path: path, imageInfo: s})
    let images=[{path: path, imageInfo: s}]
    // cl(images)
/*
imageInfo:{
  h:900,
  i:"id"
  t:""
  w:1200
  x:150
  y:150
  }
path:""
only needs, h, w, path
*/
          //cl("setState")
//           cl(images)
    this.mySetState("showImage",{imageDisplay: images})
  }
  
  closeImage=(id)=>{
    let imgDisp=this.state.imageDisplay.slice(0)
    imgDisp.splice(id,1)
    this.mySetState("closeImage",{imageDisplay:imgDisp})
//     cl(id)
  }
  
/***************** End of Image Handling, from Grow Journal *********************/  

  pushNote=()=>{
//             cl("Push Note")
    wsTrans("usa", {cmd: "cRest", uri: "/s/pushNote", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {userIds: [globs.userData.session.userId]}})
  }
  
  navigateTasks=()=>{
//     cl(this.state.scope)
//     cl(this.state.site)
//     cl(this.state)
    switch(this.state.scope){
      case "sites":
      default:
        history.push("/usa/c18/tasks")
        break
      case "site":
        history.push(`/usa/c18/sites/${this.state.site}/tasks`)
        break
      case "zones":
        history.push(`/usa/c18/sites/${this.state.site}/zones/${this.state.zone}/tasks`)
        break
    }
  }
  
 onChange=async(type,vals)=>{
   // cl(type,vals)
  if(vals.cmd==="levels") {
    this.ManageAlarmsLevels = vals.cmd
  }
  
//     cl("**********************************************************")
//     cl(`C18Host onChange, type: ${type}, ${vals.cmd}`)
//     console.trace()
//     cl([type,vals])
    cl.oc(this,type,vals)
//     cl(this.props)
//     cl(this.state)
//     cl(this.state)
   addToTracking({cmd:"hostChange",type:type,valscmd:vals.cmd,})
    var url
//     cl([type,vals])
    switch(type){
      case "menuBar":
//         cl(type,vals)
//         let baseUrl=`/usa/c18/sites/${this.state.site}/zones/${this.state.zone}`
        switch(vals.cmd){
          case "settings":
            url=`/usa/c18/admin`
            history.push(url)
            break
          case "user":
            url=`/usa/c18/admin/userProfile`
            history.push(url)
            break
          case "sites":
            history.push("/usa/c18/sites")
            break
          case "groups":
            history.push("/usa/c18/admin/manageGroups")
            break
          case "tasks":
            this.navigateTasks()
//             history.push("/usa/c18/tasks")
            break
//           case "reports":
//             cl(this.props)
//             history.push("/usa/c18/graphing")
//             break
          case "reports":
            history.push("/usa/c18/reports/graphList")
            break
          case "notifyIcon":
            history.push("/usa/c18/messaging/messageList")
            break
          case "editFui":
            let parts=this.props.url.split('/')
            history.push(`/fui/edit/${parts[4]}`)
            break
          case "alarms":
//             cl(this.props.url)
            history.push("/usa/c18/alarmLog")
//             let parts=this.props.url.split('/')
//             history.push(`/fui/edit/${parts[4]}`)
            break
          case "pushNote":
            this.pushNote()
            break
          case "subscription":
            history.push("/usa/c18/admin/manageSubscription")
            break
          case "home":
            history.push("/usa/c18/home")
            break
          case "helpIcon":
//             cl("help icon")
            if(window.innerWidth <= 768) {
              // vals.e.preventDefault()
              this.props.loadDOMIds()
            } else {
              //cl(this.state)
               globs.events.publish("showInfoPage","/sites")
            }
            break
        }
        break
      case "fui":
//         this.setState(vals)// used for savePage, and saveEnable
//         cl(this.props)
//         cl(vals)
        return this.onChangeBreadcrumbsOnly(vals)// also handles savePage stuff, now
      case "admin":
//         cl("admin")
        return this.onChangeBreadcrumbsOnly(vals)
      case "zonesListTiles":
        return this.onChangeBreadcrumbsOnly(vals)
      case "manageAlarms2Page":
        return this.onChangeBreadcrumbsOnly(vals)
      case "modbusDevicesEditPage":
        return this.onChangeBreadcrumbsOnly(vals)
      case "modbusDevicesEdit2Page":
          return this.onChangeBreadcrumbsOnly(vals)
      case "sitesListTitles":
        // if(vals.forecast) {
        //   this.setState(this.setState({ forecast: vals.forecast }))
        // }
        return this.onChangeBreadcrumbsOnly(vals)
      case "graphing":
        if (vals.cmd == "showImage") {
            return this.showImage(vals.vals)
            break
        }
        return this.onChangeBreadcrumbsOnly(vals)
//       case "messaging":
//         return this.onChangeBreadcrumbsOnly(vals)
      case "zoneSensorsEquipment":
        return this.onChangeZoneSensorsEquipment(vals)
      case "alarmLog":
      case "generalInfo":
        return this.onChangeBreadcrumbsOnly(vals)
        
//       case "sensorEquip":
//         return this.onChangeSensorEquip(vals)
      case "settings":
//         cl("settingsd2")
        return this.onChangeSettings(vals)
//       case "breadcrumbs":
//         cl(vals)
//         this.setState(vals)
      case "sideBar":
        // cl(vals)
        // cl(vals.cmd)
        switch(vals.cmd){
          case "showImage":
            return this.showImage(vals.vals)
            break
          case "savePage":
//             cl("save page")
            return this.onChangeBreadcrumbsOnly(vals)
          case "sidebarMode":
            // cl(vals)
            this.mySetState("sidebarMode",vals.data)
            break
          case "sideBarOpen":
            // cl(vals.vals)
            this.mySetState("sideBarOpen", vals.vals)
            // let mobile know
            if (window.ReactNativeWebView) {
              let sidebarState = (this.hasSideBar()) ? ((vals.vals.sideBarOpen) ? "open" : "closed") : "hidden"
              let ret = await rnRest("/sidebar", "update", {sidebar: sidebarState})
            } 
            // let msg = {cmd: "putSidebarState", sidebarState: sidebarState, mode: this.state.mode, hasSideBar: this.hasSideBar()}
            // window.ReactNativeWebView.postMessage(JSON.stringify(msg))
          default:
            this.mySetState("onChangeDefault",vals)
            break
        }
        break
      case "imageIcon":
//         cl(vals)// im0-Cancel
        let parts=vals.split("-")
//         cl(parts)
//         cl(parts[0])
        this.closeImage(parts[0].substr(2))// skip 'im'
//         cl(this.state)
        break
      case "reports":
      case "tasks":
      case "devHome":
      case "cropPhases":
      case "messaging":
//         cl(vals)
        switch(vals.cmd){
          case "savePage":
          case "breadcrumbs":
          case "pageTitle":
          case "saveHeader":
            this.onChangeBreadcrumbsOnly(vals)
            break
          case "image":
//             cl(vals)
            this.showImage(vals)
            break
        }
//         cl(vals)
        break
      case "idoserPage":
//         cl(type,vals)
        vals.cmd=vals.cmd||type
        this.onChangeBreadcrumbsOnly(vals)
//         cl(vals)
        break
      case "modbusPage":
//         cl(vals)
        vals.cmd=vals.cmd||type
        this.onChangeBreadcrumbsOnly(vals)
        break
      case "manageAlarms2Page":
      cl(vals)
      vals.cmd=type
      this.onChangeBreadcrumbsOnly(vals)
      break
      case "modbusDevicesEditPage":
      cl(vals)
      vals.cmd=type
      this.onChangeBreadcrumbsOnly(vals)
      break
      case "modbusDevicesEdit2Page":
        cl(vals)
        vals.cmd=type
        this.onChangeBreadcrumbsOnly(vals)
      break
      case "reportsPage":
        vals.cmd=type
        this.onChangeBreadcrumbsOnly(vals)
        break
      case "cropRecipesPage":
        vals.cmd=type
        this.onChangeBreadcrumbsOnly(vals)
        break
      case "saveHeader":
        this.mySetState("saveEnable",{pageModified:vals.saveEnable})
        break
      case "devicesidebar":
        switch(vals.method){
          case "update":
//             cl(vals)
            let sOpen = (this.hasSideBar() &&  vals.body.sidebar == "open")
            this.mySetState("sideBarOpen", {sideBarOpen: sOpen})
            break
          default:
            this.mySetState("onChangeDefault",vals)
            break
        }
        break
      case "menuPage": // tabs in fui pages
        vals.cmd=type
        this.onChangeBreadcrumbsOnly(vals)
        break
      case "zoneNameClick":
        this.zoneNameClick(vals)
        break
      case "zoneSelectValue":
        this.setState({zone:vals.zoneSelectValue,zoneSelect:false})
        break
      default:
        return
    }
  }
  
//   setBreadcrumbs=(links)=>{
//     cl(links)
//   }

  resolvePopup=(e,resolve)=>{
//     cl(e)
    let popup=this.state.popup
    popup.opacity=0
    this.mySetState("resolvePopup",{popup:popup})
    if(resolve){resolve(e)}
  }

  getPopup=(type, vals)=>{
//     cl("getpopup")
//     cl(type,vals)
//     console.trace()
    return new Promise((r,e)=>{
      switch(type){
        case "admin":
        case "sideBar":
          let popup={
            opacity:1,
            text:vals.text,
            buttons:vals.buttons,
            resolve:e=>this.resolvePopup(e,r),
          }
//           cl(popup)
          this.mySetState("getPopup",{popup:popup})
//           cl(this.state)
          break
        default:
          break
      }
    })
  }
  
  childGetPopup=(obj)=>{// must return promises
//     cl(obj)
    let body=obj.body
//     let vals=body.vals
    return this.getPopup(body.type,body.vals)
  }
  
  childRest=(obj)=>{
    let cmds={getPopup:this.childGetPopup}
    if(cmds[obj.uri]){return cmds[obj.uri](obj)}
  }

  // evalutes whether current page has side bar present
  hasSideBar = () => {
    let mode = this.props.url.split("/")[3]
    return ["sites", "zones", "fui"].includes(mode)
  }

  // now handles godot messages too
  handleMessage = async () => {
//     cl("setting up message handler")
    // handle message should be replaced by "injectJavascript" once I can verify
    const mHandleMessage = async (e) => {
//         cl(e)
        if (e.origin == window.location.origin || (window.ReactNativeWebView && e.origin == "")) {
            // switch based on origin
            var data
            try{
              data = JSON.parse(e.data)
            }catch{
              data={}
            }
//             cl(data)
            if (data.src == "godot") {
                cl("data is godot")
                handleGdMsg(data)
            } else if (data.src == "cloud") {
                // pass
            } else {
//                 cl("data is rn")
                handleRnMsg(data)
                if (data.cmd == "rest" && data.uri == "/sidebar") {
                  switch (data.method) {
                    case "retrieve":
                      // get current sidebar state for this page
                      await this.getSidebar(data)
                      break
                    case "update":
                      // update sidebar state for this page (on swipe from device)
                      this.updateSidebar(data)
                      break
                    }
                }
            }
        }
        return
    }
    window.addEventListener("message", async (e) => mHandleMessage(e))
    document.addEventListener("message", async (e) => mHandleMessage(e))
//     cl("message handler set")
    // send/request device id
    if (window.ReactNativeWebView) {
        const requestDeviceId = async () => {
            let deviceId = getUId()
            rnCl(validAppVersions)
            let device = await rnRest("/device", "retrieve", {deviceId: deviceId, session: globs.userData.session, appVersion: validAppVersions})
            // rn app returns device with info
            if (device.deviceId) {
              getUId(device.deviceId)
            }
            // load session if it exists
            if (device.session) {
              checkLoggedIn(device.session)
            }
            // // create device (if not already made)
            // await loadDevicesInfo()
            // // update device on table with info
            // await updateDeviceInfo(device)
        }
        await requestDeviceId()
    }
  }

  updateSidebar=(obj)=>{
    this.onChange("devicesidebar", obj)
    let sidebarState = (this.hasSideBar()) ? ((this.state.sideBarOpen) ? "open" : "closed") : "hidden"
    restResp(obj, {sidebar: sidebarState})
  }

  getSidebar=async(obj)=>{
    if (window.ReactNativeWebView) {
      let sidebarState = (this.hasSideBar()) ? ((this.state.sideBarOpen) ? "open" : "closed") : "hidden"
      restResp(obj, {sidebar: sidebarState})
      // let ret = await rnRest("/sidebar", "update", {sidebar: sidebarState})
    }    
  }

  getGroupName=()=>{
//     cl(globs.userData.session.groupId)
    if(acctFeature("zoneGroups")){
      let gi=getGroupInfo(globs.userData.session.groupId)
      if(gi){
        return (
          <span style={{color:"white",backgroundColor:"green",padding:10,borderRadius:25,
            marginRight:10}}>
          {`${gi.name}`}</span>
        )
      }
    }
  }

  getGroupZoneId=(zoneId)=>{
    let gi=getGroupInfo(globs.userData.session.groupId)
    return(gi)?gi.groupId:zoneId
  }

  zoneNameClick=(vals)=>{
//     cl(vals)
    let st=this.state
    this.setState({zoneSelect:!st.zoneSelect})
  }

  showZoneName=(zi)=>{
    if(!acctFeature("zoneGroups")){return zi.zoneName}
//     cl(globs)
    var showZoneOpts=()=>{
      let gi=getGroupInfo(globs.userData.session.groupId)
      let zones=globs.zonesInfo.info.filter(z=>{return gi.zones.includes(z.zoneId)})
//       cl(zones)
      zones.sort((a,b)=>{
        if(a.zoneName>b.zoneName){return 1}
        if(a.zoneName<b.zoneName){return -1}
        return 0
      })
      return zones.map((z,i)=>{
        return(
          <option key={i} value={z.zoneId}>{z.zoneName}</option>
        )
      })
    }
    var showZoneSelect=()=>{
      if(st.zoneSelect){
        return(
          <div style={{fontSize:14,fontWeight:300}}>
            <C18Select00 id=""
              parms={{list:true,height:200}}
              value={this.state.zone}
              onChange={e=>this.onChange("zoneSelectValue",{zoneSelectValue: e.currentTarget.value})}
            >
              {showZoneOpts()}
            </C18Select00>
          </div>
        )
      }
    }
    let st=this.state
    if(globs.usersInfo.groupInfo?.allowSelectZone){
      let style=(st.zoneSelect)?{position:"absolute",display:"inline-block",
        backgroundColor:"white",zIndex:1,border:"1px solid",
        borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:10,
        marginTop:-10}:
        {display:"inline-block"}
      return (
        <div style={style}>
        <span style={{cursor:"pointer"}}
          onClick={e=>{this.onChange("zoneNameClick",{here:"there"})}}
        >
          {zi.zoneName}
        </span>
          {showZoneSelect()}
        </div>
      )
    }
  }

  showZones=()=>{
    let st=this.state
//     var groupName=this.getGroupName()
//     let gi=this.getGroupInfo(st.groupId)
//     if(gi){
//       groupName=(
//         <span style={{color:"white",backgroundColor:"green",padding:10,borderRadius:25,
//           marginRight:10}}>
//         {`${gi.name}: `}</span>
//       )
//     }
//     cl(groupName)
    setTitle("Zone View")
//     cl(this.state)
//     cl(this.state.loaded)
//     cl(globs.zonesInfo.info)
//     cl(this.props)
    let zoneInfo=getZoneInfo(this.state.zone)
//     if(!zoneInfo){zoneInfo=this.showGroup(this.state.zone)}
//     cl(zoneInfo)
    if(!zoneInfo){return null}
//     cl(this.state.popup)
//     globs.events.publish("newContext",{level:"zone",zoneId:this.state.zone})
    return(
      <div  className={`zone-page fullwidth ${(this.state.savePage)?"save-page":""}`}>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}
        />
        <div id="page-container" className={(this.state.sideBarOpen)?"sidebar-open":""}>
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <C18WeatherStation00
            parms={{
              site:this.state.site, 
              zone:this.state.zone, 
              // forecast: this.state.forecast,
            }}
            />
            <h1>{this.getGroupName()}{this.showZoneName(zoneInfo)}</h1>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <C18Confirm00 parms={this.state.popup}/>
            <C18ZoneSensorsEquipment00 parms={{
              site:this.state.site, 
              zone:this.state.zone, 
              gwType:zoneInfo.gatewayType,
              url:this.props.url,
              sideBarOpen: this.state.sideBarOpen,
              onChange:o=>{this.onChange("zoneSensorsEquipment",o)}
            }}/>
          </main>
            <C18RightSidebar00 parms={{
              onChange:o=>{this.onChange("sideBar",o)},
              level:"zone",
              mode:this.state.sidebarMode,
              sideBarNoteId:this.state.sideBarNoteId,
              site:this.state.site, 
              zone:this.getGroupZoneId(this.state.zone),
              sideBarOpen: this.state.sideBarOpen,
              getPopup:o=>this.getPopup("sideBar",o),
            }}/>
          {this.imageDisplayRender()}
        </div>
      </div>
    );
  }
  
  showSites=()=>{
//     cl(this.state)
    let st=this.state
    if(st.site&&(st.site!="none")){// zones for one site
      setTitle("Zones")
//       globs.events.publish("newContext",{level:"site",siteId:this.state.site})
      return(
        <div  className={`site-page fullwidth ${(this.state.savePage)?"save-page":""}`}>
          <C18MenuBar00 parms={{
            onChange:o=>this.onChange("menuBar",o),
            siteStatus:this.state.siteStatus,
            mode:this.state.mode,
          }}/>
          <C18Confirm00 parms={this.state.popup}/>
          <div id="page-container" className={(this.state.sideBarOpen)?"sidebar-open":""}>
            <main id="main" className="no-sidebar">
              <C18Breadcrumb00 parms={{
                breadcrumbs: this.state.breadcrumbs,
              }}/>
              <C18WeatherStation00
                parms={{
                  site:this.state.site, 
                  zone:this.state.zone, 
                  // forecast: this.state.forecast,
                }}
              />
              <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
              <C18Confirm00 parms={this.state.popup}/>
              <C18ZonesListTiles00 parms={{
                site: this.state.site,
                onChange:o=>this.onChange("zonesListTiles",o),
                getPopup:o=>this.getPopup("sideBar",o),
              }}/>
            </main>
            <C18RightSidebar00 parms={{
              onChange:o=>{this.onChange("sideBar",o)},
              level:"site",
              mode:this.state.sidebarMode,
              sideBarNoteId:this.state.sideBarNoteId,
              site:this.state.site, 
              zone:this.state.zone, 
              sideBarOpen: this.state.sideBarOpen,
              getPopup:o=>this.getPopup("sideBar",o),
            }}/>
          {this.imageDisplayRender()}
          </div>
        </div>
      );
    }else{// all the sites
      setTitle("Sites")
//       globs.events.publish("newContext",{level:"account"})
      return(// sites for one account
        <div  className={`account-page fullwidth ${(this.state.savePage)?"save-page":""}`}>
          <C18MenuBar00 parms={{
            onChange:o=>this.onChange("menuBar",o),
            siteStatus:this.state.siteStatus,
            mode:this.state.mode,
          }}/>
          <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
          <C18Confirm00 parms={this.state.popup}/>
          <div id="page-container" className={(this.state.sideBarOpen)?"sidebar-open":""}>
            <main id="main" className="no-sidebar">
              <C18Breadcrumb00 parms={{
                breadcrumbs: this.state.breadcrumbs
              }}/>
              <C18SitesListTiles00 parms={{
                onChange:o=>this.onChange("sitesListTitles",o),
                getPopup:o=>this.getPopup("sideBar",o),
              }}/>
            </main>
            <C18RightSidebar00 parms={{
              onChange:o=>{this.onChange("sideBar",o)},
              level:"account",
              mode:this.state.sidebarMode,
              sideBarNoteId:this.state.sideBarNoteId,
              site:this.state.site, 
              zone:this.state.zone, 
              sideBarOpen: this.state.sideBarOpen,
              getPopup:o=>this.getPopup("sideBar",o),
            }}/>
          {this.imageDisplayRender()}
          </div>
        </div>
      );
    }
  }
  
  showSettings=()=>{
    setTitle(this.state.pageTitle)
//     cl("settings")
//     cl(this.state)
    return(
      <div  className={(this.state.savePage)?"save-page":""}>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <div id={this.state.settingsType}>
              <h1>{this.getGroupName()}{`${this.state.pageTitle||"Settings"}`}</h1>
              <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
              <div id="content-area">
                <section className="link4">
                  <C18Settings00 parms={{
                    site: this.state.site,
                    zone: this.state.zone,
                    settingsType: this.state.settingsType,
                    selController:this.state.selController,
                    onChange:o=>{this.onChange("settings",o)},
                  }}
                  notify={this.notify}/>
                </section>
              </div>
            </div>
            
            
          </main>
        </div>
      </div>
    );
    
    return(
      <div>settings</div>
    )
  }
  
/*
Manage Account, Users, Sites, Gateways, Alarms, Zones*/

  showAdminPage=()=>{
    let st=this.state
    let pa=this.props.parms
//     cl(st)
    let adminPages={
//       manageAccount: ManageAccount,
//       manageUsers: ManageUsers,
//       manageSites: ManageSites,
//       manageSiteAccess: ManageSiteAccess,
//       manageGateways: ManageGateways,
//       manageAlarms: ManageAlarms,
//       manageZones: ManageZones,
      manageAccount2: C18ManageAccount,
      manageUsers2: C18ManageUsers,
      manageUsers3: C18ManageUsers3,
      editUser: C18EditUser00,
      modbusDevicesEdit: C18ModbusDevicesEdit00,
      manageSites2: C18ManageSites,
      manageSiteAccess2: C18ManageSiteAccess,
      manageGateways2: C18ManageGateways,
      manageAlarms2: C18ManageAlarms,
      manageZones2: C18ManageZones,
      manageGroups: C18ManageGroups00,
      generalInfo: C18GeneralInfo00,
      userProfile: UserProfile,
      selectSensors: SelectSensors,
      selectSensors800: SelectSensors,
      systemStatus: Status,
      syncStatus: C18SyncStatus,
      testReportWriter: TestReportWriter,
      manageSubscription: C18ManageSubscription,
      editInfoPages:C18EditInfoPages,
      techPortal:C18TechPortal00,
      techPortal2:C18TechPortal01,
      salesPortal:C18SalesPortal00,
      vidTest:C18VideoDetail00,
      manageZoneGroups:C18ZoneGroups00,
      cameras:C18Cameras00,
      manageWatch:C18ManageWatch00,
      pageAccesses:C18PageAccesses00,
      selectGroup:C18GroupsEdit00,
      editGroup:C18GroupsEdit00,
      applyGroup:C18GroupsEdit00,
      autoSetup:C18AutoSetup00,
      qrcode:C18QrCode00,
      qrcodeReceive:C18QrCodeReceive00,
    }
//     cl(st.adminPage)
//     cl(st)
    if(st.adminPage){
        //       cl(st)
        let ShowPage=adminPages[st.adminPage]

        return(
          <ShowPage parms={{
            site: st.site,
            zone: st.zone,
            url:st.url,// this is not getting updated
            adminPage:st.adminPage,
            adminInfo:st.adminInfo,
            onChange:o=>{this.onChange("admin",o)},
            getPopup:o=>this.getPopup("admin",o),
            search:pa.search,
          }}/>
        )

    }else{
      return(
        <C18Settings00 parms={{
          site: st.site,
          zone: st.zone,
          settingsType: "account",
          label: this.ManageAlarmsLevels ? this.ManageAlarmsLevels : "",
          onChange:o=>{this.onChange("settings",o)},
        }}
        notify={this.notify}/>
      )
    }
  }
  
  showAdmin=()=>{
//     cl(this.state)
    setTitle(this.state.pageTitle)
//     let level=(this.state.zone)?"zone":"site"
//     var saveOK
//     cl(level)
//     if(level=="site"){
//       saveOK=privs(level,this.state.site,constant.AREA_PRIVS_WRITE)!=0
//     }else{
//       saveOK=privs(level,this.state.zone,constant.AREA_PRIVS_WRITE)!=0
//     }
//     if(["userProfile"].includes(this.state.adminPage)){saveOK=true}
    let saveOK=true
//     cl(saveOK)
//     cl(this.state)
//     cl(this.state.adminPage)
    return(
      <div  className={(this.state.savePage)?"save-page":""}>
        <C18Confirm00 parms={this.state.popup}/>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>

            <div>
              <h1>{`${this.state.pageTitle||"Admin"}${(saveOK)?"":" (Read Only)"}`}</h1>
              <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
              <div id="content-area">
                <section className="link4">
                  {this.showAdminPage()}
                </section>
              </div>
            </div>
            
            
            
          </main>
        </div>
      </div>
    );
  }
  
  showFuiPage=(tabItems)=>{
//     cl(this.props)
//     cl(this.state)
//     cl(this.state)
//     cl(this.state.pageType)
    var showZoneGroupFui=()=>{
      let groupId=globs.userData.session.groupId
      let siteId=globs.userData.session.siteId
      if(acctFeature("zoneGroups")&&groupId){
        return(
          <C18GroupsEdit00 parms={{
            mode:"c18",
            onChange:o=>{this.onChange("fui",o)},
            getPopup:o=>this.getPopup("sideBar",o),
            pageModified:this.state.pageModified,
            adminPage:"zoneGroup",
            adminInfo:groupId,
            site:siteId,
          }}
          match={{
            params:{
              pageType:this.state.pageType,
              zuci:this.state.zuci,
            },
            url:this.state.url,
          }}/>
        )
      }else{
        return(
          <LiveFui parms={{
            mode:"c18",
            onChange:o=>{this.onChange("fui",o)},
            getPopup:o=>this.getPopup("sideBar",o),
            pageModified:this.state.pageModified,
          }}
          match={{
            params:{
              pageType:this.state.pageType,
              zuci:this.state.zuci,
            },
            url:this.state.url,
          }}/>
        )
      }
    }
//             <LiveFui parms={{
//               mode:"c18",
//               onChange:o=>{this.onChange("fui",o)},
//               getPopup:o=>this.getPopup("sideBar",o),
//               pageModified:this.state.pageModified,
//             }}
    return (
      <div id="content-area">
        <section className="link4">
          <div className="fui">
            {tabItems.length > 0 &&
              <>
                <C18SubMenuHeader00 parms={{
                  items: tabItems,
                  pageType: this.state.pageType,
                  onChange: o=>this.onChange("menuPage",o),
                }}/>
                <br/>
                <br/>
                <br/>
              </>
            }
            {showZoneGroupFui()}
          </div>
        </section>
      </div>
    )
//     return <div>fui page</div>
  }

  getTabItems=()=>{
    let ret = []
    let zoneInfo=getZoneInfo(this.state.zone)
    let suffix800=(zoneInfo.gatewayType==800)?"_800":""
    switch(this.state.pageType){
      case "temp_Staging":
      case "temp_Staging_800":
      case "HumDeHum":
      case "HumDeHum_800":
        ret = [
                {v:`temp_Staging${suffix800}`,t:"Temp Staging"},
                {v:`HumDeHum${suffix800}`,t:"Hum DeHum"},
              ]
      default:
    }
    return ret
  }
  
  showFui=()=>{
//     cl(this.state.adminPage)
//     cl(this.state)
    let st=this.state
//     cl(st.fuiMode)
    setTitle("Configuration")
    let zInd=+st.zuci.split("-")[0]
    var groupName=this.getGroupName()
//     cl(st)
//     cl(zInd)
//     cl(st)
//     globs.events.publish("newContext",{level:"config",pageType:st.pageType,zuci:st.zuci})
    let tabItems = this.getTabItems()
//     cl(st)

    return(

      <div  className={`${(st.fullWidth)?"fullwidth ":""}${(st.savePage)?"save-page":""}`}>
        <C18Confirm00 parms={st.popup}/>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:st.siteStatus,
          mode:st.mode,
        }}/>
          <div id="page-container" className={(st.sideBarOpen)?"sidebar-open":""}>
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: st.breadcrumbs
            }}/>
            <h1>{groupName}{st.pageTitle}</h1>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}
            site={st.site}
            zInd={zInd}
            parms={{reloadCode:"reload"}}
            />
            {this.showFuiPage(tabItems)}
          </main>
          <C18RightSidebar00 parms={{
            onChange:o=>{this.onChange("sideBar",o)},
            level:"config",
            site:st.site,
            zone:this.getGroupZoneId(st.zone),
            mode:st.sidebarMode,
            sideBarNoteId:st.sideBarNoteId,
            zuci:st.zuci,
            pageType:st.pageType,
            sideBarOpen: st.sideBarOpen,
            getPopup:o=>this.getPopup("sideBar",o),
          }}/>
          {this.imageDisplayRender()}
        </div>
      </div>
    );
  }
  
  showGraph=()=>{
//     cl(this.props)
    setTitle("Graphing")
    let cName="fullwidth " + ((this.state.savePage)?"save-page":"")
//     let cName=`fullwidth ${(this.state.savePage)?"save-page":""}`
//     cl(cName)
//     globs.events.publish("newContext",{level:"graph",siteId:this.state.site})
    return(
      <div  className={cName}>
        <C18Confirm00 parms={this.state.popup}/>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            
            <h1>{this.state.pageTitle}</h1>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <C18Graphing00 parms={{
                  site: this.state.site,
                  zone:this.state.zone,
                  sensors:this.state.sensors,
                  period:this.state.period,
                  pageType:"editGraph",
                  onChange:vals=>this.onChange("graphing",vals),
                  getPopup:o=>this.getPopup("admin",o),
                }}
                />
              </section>
            </div>
          </main>
          {this.imageDisplayRender()}
        </div>
      </div>
    )
  }
  
  
  showIDoserPage=()=>{
//     cl(this.state.pageType)
//     cl(this.props)
    let siteId=globs.userData.session.siteId
//     cl(siteId)
    let saveOK=privs("zone",this.state.zone,constant.AREA_PRIVS_WRITE)!=0
//     cl(saveOK)
    let s=this.state
    switch(this.state.pageType){
      case "nutrients": return <Nutrients parms={{
        onChange:o=>this.onChange("idoserPage",o),
        getPopup:o=>this.getPopup("admin",o),
        site:s.site,
        zone:s.zone,
        saveOK:saveOK,
        pageModified:this.state.pageModified,
      }}/>
      case "recipes": return <Recipes parms={{
        onChange:o=>this.onChange("idoserPage",o),
        getPopup:o=>this.getPopup("admin",o),
        site:s.site,
        zone:s.zone,
        saveOK:saveOK,
        pageModified:this.state.pageModified,
      }}/>
      case "areas": return <Areas parms={{
        onChange:o=>this.onChange("idoserPage",o),
        getPopup:o=>this.getPopup("admin",o),
        site:s.site,
        zone:s.zone,
        saveOK:saveOK,
        pageModified:this.state.pageModified,
      }}/>
      case "tanks": return <Tanks parms={{
        onChange:o=>this.onChange("idoserPage",o),
        getPopup:o=>this.getPopup("admin",o),
        site:s.site,
        zone:s.zone,
        saveOK:saveOK,
        pageModified:this.state.pageModified,
      }}/>
      case "stations": return <Stations parms={{
        onChange:o=>this.onChange("idoserPage",o),
        getPopup:o=>this.getPopup("admin",o),
        site:s.site,
        zone:s.zone,
        saveOK:saveOK,
        pageModified:this.state.pageModified,
      }}/>
      case "schedules": return <Schedules parms={{
        onChange:o=>this.onChange("idoserPage",o),
        getPopup:o=>this.getPopup("admin",o),
        site:s.site,
        zone:s.zone,
        saveOK:saveOK,
        pageModified:this.state.pageModified,
      }}/>
      default: return null
    }
  }
  
  
  showReportPage=()=>{
//     cl(this.state.pageType)
    switch(this.state.pageType){
      case "viewGraph":
        return(
          <C18Graphing00 parms={{
            presetId:this.state.reportId,
            pageType:this.state.pageType,
            onChange:vals=>this.onChange("graphing",vals),
            getPopup:o=>this.getPopup("admin",o),
          }}/>
        )
      case "editGraph":
        return(
          <C18Graphing00 parms={{
            presetId:this.state.reportId,
            pageType:this.state.pageType,
            onChange:vals=>this.onChange("graphing",vals),
            getPopup:o=>this.getPopup("admin",o),
          }}/>
        )
      case "graphList":
//         cl(this.state)
        return(
          <C18GraphSelect00 parms={{
            onChange:vals=>this.onChange("graphing",vals),
            getPopup:o=>this.getPopup("admin",o),
          }}/>
        )
        break
      case "editReport":
        return(
          <C18EditReport parms={{
            reportId: this.state.reportId,
            onChange:o=>this.onChange("reports",o),
            getPopup:o=>this.getPopup("admin",o),
          }}
          />
        )
        break
      case "editSchedule":
        return(
          <C18EditSchedule parms={{
            reportScheduleId: this.state.reportId,
            onChange:o=>this.onChange("reports",o),
            getPopup:o=>this.getPopup("admin",o),
          }}
          />
        )
        break
      case "reportList":
        return(
          <C18ReportList parms={{
            onChange:o=>this.onChange("reports",o),
            getPopup:o=>this.getPopup("admin",o),
          }}
          />
        )
        break
      case "scheduleList":
        return(
          <C18ScheduleList parms={{
            onChange:o=>this.onChange("reports",o),
            getPopup:o=>this.getPopup("admin",o),
          }}/>
        )
        break
      case "viewReport":
        return(
          <C18ViewReport parms={{
            reportId: this.state.reportId,
            onChange:o=>this.onChange("reports",o),
            getPopup:o=>this.getPopup("admin",o),
          }}/>
        )
        break
      case "summaryList":
        return(
          <C18SummaryList parms={{
            onChange:o=>this.onChange("reports",o),
            getPopup:o=>this.getPopup("admin",o),
          }} 
          />
        )
        break
      case "viewSummary":
        return(
          <C18Summary parms={{
            presetId:this.state.reportId,
            pageType:this.state.pageType,
            onChange:o=>this.onChange("reports",o),
            getPopup:o=>this.getPopup("admin",o),
          }}/>
        )
        break
      case "editSummary":
        return(
          <C18Summary parms={{
            presetId:this.state.reportId,
            pageType:this.state.pageType,
            onChange:o=>this.onChange("reports",o),
            getPopup:o=>this.getPopup("admin",o),
          }}/>
        )
        break
      default:
        return null
    }
    return null
  }
  
  showReports=()=>{
    setTitle("Reports and Graphs")
    
    let cName=((["viewGraph","editGraph"].includes(this.state.pageType))?"fullwidth ":"") + 
      ((this.state.savePage)?"save-page":"")
//     cl(this.state)
    
    return(
      <div  className={cName}>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <C18Confirm00 parms={this.state.popup}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <h1>{this.state.pageTitle}</h1>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <div className="reports-and-graphs">
                  <C18SubMenuHeader00 parms={{
                    items:[
                      {v:"graphList",e:"editGraph",l:"viewGraph",t:"Graphs"},
                      {v:"reportList",e:"editReport",l:"viewReport",t:"Reports"},
                      {v:"scheduleList",e:"editSchedule",t:"Schedules"},
                      {v:"summaryList",e:"editSummary",l:"viewSummary",t:"Data Analysis"},
                    ],
                    pageType: this.state.pageType,
                    onChange: o=>this.onChange("reportsPage",o),
                  }}/>
                  {this.showReportPage()}

                </div>
              
              </section>
            </div>
          </main>
          {this.imageDisplayRender()}
        </div>
      </div>
      
    )
  }
  
  showModbus=()=>{
    let saveOK=privs("site",globs.userData.session.siteId,constant.AREA_PRIVS_WRITE)!=0
    let title=(saveOK)?"Modbus":"Modbus (Read Only)"
    setTitle(title)
    let s=this.state
    return(
      <div  className={(this.state.savePage)?"save-page":""}>
        <C18Confirm00 parms={this.state.popup}/>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <h1>{title}</h1>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <div className="idoser">
                  <C18SubMenuHeader00 parms={{
                    items:[
                      {v:"devices",t:"Devices"},
                      {v:"types",t:"Types"},
                    ],
                    pageType: this.state.pageType,
                    onChange: o=>this.onChange("modbusPage",o),
                  }}/>
                <C18Modbus00 parms={{
                  pageType:this.state.pageType,
                  onChange: o=>this.onChange("modbusPage",o),
                  getPopup:o=>this.getPopup("admin",o),
                  site:s.site,
                  zone:s.zone,
                  saveOK:saveOK,
                  pageModified:s.pageModified,
                }}/>
              
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
    )
  }

  showModbusGp=()=>{
    let saveOK=privs("site",globs.userData.session.siteId,constant.AREA_PRIVS_WRITE)!=0
    let title=(saveOK)?"Modbus":"Modbus (Read Only)"
    setTitle(title)
    let s=this.state
    return(
      <div  className={(this.state.savePage)?"save-page":""}>
        <C18Confirm00 parms={this.state.popup}/>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <h1>{title}</h1>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <div className="idoser">
                <C18ModbusGP00 parms={{
                  pageType:this.state.pageType,
                  onChange: o=>this.onChange("modbusPage",o),
                  getPopup:o=>this.getPopup("admin",o),
                  site:s.site,
                  zone:s.zone,
                  saveOK:saveOK,
                  pageModified:s.pageModified,
                }}/>

                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
    )
  }

  showManageAlarms2=()=>{
    let saveOK=privs("site",globs.userData.session.siteId,constant.AREA_PRIVS_WRITE)!=0
    let s=this.state
    cl(s)
    return(
      <div  className={(this.state.savePage)?"save-page":""}>
        <C18Confirm00 parms={this.state.popup}/>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <h1>Manage Alarms</h1>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <div>

                <C18ManageAlarms parms={{
                  pageType:this.state.pageType,
                  onChange: o=>this.onChange("manageAlarms2Page",o),
                  getPopup:o=>this.getPopup("admin",o),
                  site:s.site,
                  zone:s.zone,
                  tab:s.tab,
                  sensor:s.sensor,
                  saveOK:saveOK,
                  pageModified:s.pageModified,
                }}
                />
              
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
    )
  }

  showModbusDevicesEdit=()=>{
    let saveOK=privs("site",globs.userData.session.siteId,constant.AREA_PRIVS_WRITE)!=0
    let s=this.state
    cl(s)
    return(
      <div  className={(this.state.savePage)?"save-page":""}>
        <C18Confirm00 parms={this.state.popup}/>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <h1>Modbus Devices Edit</h1>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <div>

                <C18ModbusDevicesEdit00 parms={{
                  pageType:this.state.pageType,
                  onChange: o=>this.onChange("modbusDevicesEditPage",o),
                  getPopup:o=>this.getPopup("admin",o),
                  site:s.site,
                  zone:s.zone,
                  saveOK:saveOK,
                  pageModified:s.pageModified,
                }}
                />
              
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
    )
  }

  showModbusDevicesEdit2=()=>{
    let saveOK=privs("site",globs.userData.session.siteId,constant.AREA_PRIVS_WRITE)!=0
    let s=this.state
    cl(s)
    return(
      <div  className={(this.state.savePage)?"save-page":""}>
        <C18Confirm00 parms={this.state.popup}/>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <h1>Modbus Devices Edit</h1>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <div>

                <C18ModbusDevicesEdit00 parms={{
                  pageType:this.state.pageType,
                  onChange: o=>this.onChange("modbusDevicesEdit2Page",o),
                  getPopup:o=>this.getPopup("admin",o),
                  site:s.site,
                  zone:s.zone,
                  tab:s.tab,
                  type:s.type,
                  saveOK:saveOK,
                  pageModified:s.pageModified,
                }}
                />
              
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
    )
  }
  
  showIDoser=()=>{
//     cl(this.state)
//     cl(this.props)
    
    setTitle("iDoser")
//     cl("foridoser")
    let saveOK=privs("zone",this.state.zone,constant.AREA_PRIVS_WRITE)!=0
    let pageTitle={
      nutrients:"Nutrients",
      recipes:"Recipes",
      areas:"Areas",
      tanks:"Tanks",
      stations:"Stations",
      schedules:"Schedules",
    }[this.state.pageType]
//     cl(this.state.breadcrumbs)
    return(
      <div  className={(this.state.savePage)?"save-page":""}>
        <C18Confirm00 parms={this.state.popup}/>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <h1>iDoser {`${pageTitle}${(!saveOK)?" (Read Only)":""}`}</h1>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <div className="idoser">
                  <C18SubMenuHeader00 parms={{
                    items:[
                      {v:"nutrients",t:"Nutrients"},
                      {v:"recipes",t:"Recipes"},
                      {v:"areas",t:"Areas"},
                      {v:"tanks",t:"Tanks"},
                      {v:"stations",t:"Stations"},
                      {v:"schedules",t:"Schedules"},
                    ],
                    pageType: this.state.pageType,
                    onChange: o=>this.onChange("idoserPage",o),
                  }}/>
                  {this.showIDoserPage()}
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
      
    )
  }

  showPearlPage=()=>{
    let st=this.state
    let saveOK=privs("site",globs.userData.session.siteId,constant.AREA_PRIVS_WRITE)!=0
//     cl(st)
    switch(st.pageType){
      case "expansion":
        return(
          <C18Expansion00
            parms={{
              pageType:this.state.pageType,
              onChange: o=>this.onChange("modbusPage",o),
              getPopup:o=>this.getPopup("admin",o),
              site:st.site,
              zone:st.zone,
              saveOK:saveOK,
              pageModified:st.pageModified,
            }}
          />
        )
      case "mapping":
        return(
          <C18PearlMapping00
            parms={{
              site:st.site,
              zone:st.zone,
              onChange: o=>this.onChange("modbusPage",o),
              getPopup:o=>this.getPopup("admin",o),
            }}
          />
        )
      case "inputConfig":
//         cl(st)
        return(
          <C18PearlInputConfig00
          parms={{
            site:st.site,
            zone:st.zone,
            onChange: o=>this.onChange("modbusPage",o),
            getPopup:o=>this.getPopup("admin",o),
          }}
          />
        )
      case "calibration":
        cl("do cal")
        return <div>Pearl Calibration - Soon</div>
    }
    return(
      <div>Pearl2</div>
    )
  }

  showPearl=()=>{
    let pa=this.props.parms
//     cl(pa)
    let st=this.state
//     cl(st)
    let titles={expansion:"Expansion",mapping:"Mapping",inputConfig:"Input Config"}
    let pageTitle=`Pearl ${titles[st.pageType]}`
    let saveOK=true
    var groupName=this.getGroupName()
    return(
      <div  className={(this.state.savePage)?"save-page":""}>
        <C18Confirm00 parms={this.state.popup}/>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <h1>{groupName}{`${pageTitle}${(!saveOK)?" (Read Only)":""}`}</h1>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <div className="idoser">
                  {this.showPearlPage()}
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
    )
  }

  showServerLog=()=>{
    let pa=this.props.parms
//     cl(pa)
    let st=this.state
//     cl(st)
//     let titles={expansion:"Expansion",mapping:"Mapping",inputConfig:"Input Config"}
//     let pageTitle=`Pearl ${titles[st.pageType]}`
//     let saveOK=true
    return(
      <div>
        <C18ServerLog00/>
      </div>
    )
  }


  getSensorId=(id0)=>{
    if(["ec","ph"].includes(id0.substring(2,4))){
      return id0.substring(0,4)
    }else{
      return id0
    }
  }

  showHeaStation=()=>{
    return (
      <div>Hea Station</div>
    )
  }
  
  showSensor=()=>{
    let st=this.state
//     cl(this.state)
//     cl(this.props)
//     cl(this.state)
    
//     cl(globs?.userData?.session?.siteId)
    setTitle("Sensor")
//     cl(sensorIds)
    var pageTitle="Sensor"
    if(sensorIds){pageTitle=sensorIds[this.getSensorId(this.state.pageType)]?.name}
//     cl(this.state.pageType)
//     cl(sensorIds)
//     cl(pageTitle)
    let saveOK=privs("zone",this.state.zone,constant.AREA_PRIVS_WRITE)!=0
    var sensorPage
    if(st.pageType.substring(2,4)=="ST"){
      sensorPage=(
        <C18HeaDetail01 parms={{
          site: st.site,
          zone: st.zone,
          pageType:st.pageType,
          onChange:o=>{this.onChange("admin",o)},
          getPopup:o=>this.getPopup("admin",o),
        }}
        notify={this.notify}
        />
      )
    }else{
      sensorPage=(
        <C18Sensor00 parms={{
          site: st.site,
          zone: st.zone,
          pageType:st.pageType,
          onChange:o=>{this.onChange("admin",o)},
          getPopup:o=>this.getPopup("admin",o),
        }}
        notify={this.notify}
        />
      )
    }

//                 <C18Sensor00 parms={{
//                   site: st.site,
//                   zone: st.zone,
//                   pageType:st.pageType,
// //                   url:st.url,// this is not getting updated
// //                   adminInfo:st.adminInfo,
//                   onChange:o=>{this.onChange("admin",o)},
//                   getPopup:o=>this.getPopup("admin",o),
//                 }}
    // cl([this.state.sidebarMode, this.state.sideBarNoteId])
    return(
      <div  className={"fullwidth "+((st.savePage)?"save-page":"")}>
        <C18Confirm00 parms={st.popup}/>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:st.siteStatus,
          mode:this.state.mode,
        }}/>
          <div id="page-container" className={(this.state.sideBarOpen)?"sidebar-open":""}>
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: st.breadcrumbs
            }}/>
            <h1>{this.getGroupName()}{`${pageTitle}${(!saveOK)?" (Read Only)":""}`} Sensor</h1>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <div>
                {sensorPage}
                </div>
              </section>
            </div>
          </main>
            <C18RightSidebar00 parms={{
              onChange:o=>{this.onChange("sideBar",o)},
              level:"sensor",
              mode:this.state.sidebarMode,
              site:this.state.site, 
              zone:this.state.zone,
              sensor:this.state.pageType,
              sideBarOpen: this.state.sideBarOpen,
              sideBarNoteId:this.state.sideBarNoteId,
              zuci: this.state.pageType,
              pageType:this.state.zuci,
              getPopup:o=>this.getPopup("sideBar",o),
            }}/>
          {this.imageDisplayRender()}
        </div>
      </div>
      
    )
  }
  
  showThreeD=()=>{
//       <C18ThreeD00/>
    return(
      <div>
      </div>
    )
  }
  
  showSaveRestore=()=>{
    return(
      <div  className={(this.state.savePage)?"save-page":""}>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <div id={this.state.settingsType}>
              <h1>{`${this.state.pageTitle||"Settings"}`}</h1>
              <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
              <C18Confirm00 parms={this.state.popup}/>
              <div id="content-area">
                <section className="link4">
                  <C18SaveRestore00 parms={{
                    site:this.state.site,
                    zone:this.state.zone,
                    onChange:o=>{this.onChange("admin",o)},
                    getPopup:o=>this.getPopup("admin",o),
                  }}/>
                </section>
              </div>
            </div>
            
            
          </main>
        </div>
      </div>
    );
  }
  
  showLoginRegister=()=>{
    let Page={login: C18Login00, logout: C18Logout00, register: C18Register00, 
      devRegister: C18Register00, activate: C18Register00, postRegister: PostRegister, 
      inviteUser:InviteUser, resetPassword:C18ResetPassword00, }[this.state.mode]
//     let Page=(this.state.mode=="login")?C18Login00:C18Register00
//     cl(this.state)
    return <Page mode={this.state.mode} token={this.state.token}/>
  }
  
  showResetPassword=()=>{
    return <div>Reset</div>
  }
  
  showTasks=()=>{
//     cl("show Tasks")
    setTitle("Tasks")
    let st=this.state
//     cl(st.pageSubType)
    let Page={tasks:C18TaskList00,taskDetail:C18TaskDetail00,taskEdit:C18TaskEdit00}[st.pageSubType]
    return(
      <div  className={(this.state.savePage)?"save-page":""}>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <C18Confirm00 parms={this.state.popup}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <div>
              <h1>{`${this.state.pageTitle||"Settings"}`}</h1>
              <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
              <div id="content-area">
                <section className="link4">
                  <Page parms={{
                    site:st.site,
                    zone:st.zone,
                    adminInfo:st.adminInfo,
      //               pageType:st.pageType,
                    scope:st.scope,
                    pageSubType:st.pageSubType,
                    onChange:(e)=>{this.onChange("tasks",e)},
                    getPopup:o=>this.getPopup("admin",o),
                  }}/>
                </section>
              </div>
            </div>
          </main>
          {this.imageDisplayRender()}
        </div>
      </div>
      
    )
  }
  
  showAlarmLog=()=>{
    setTitle("AlarmLog")
    return(
      <div  className={(this.state.savePage)?"save-page":""}>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <C18AlarmLog00 parms={{
                  onChange:o=>this.onChange("alarmLog",o),
                  site:this.state.site,
                  zone:this.state.zone,
                  mode:"fullPage",
                }}/>
              </section>
            </div>
          </main>
        </div>
      </div>
      
    )
  }
  
  showGeneralInfo=()=>{
    setTitle("General Info")
    return(
      <div  className={(this.state.savePage)?"save-page":""}>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <C18GeneralInfo00 parms={{
              onChange:o=>this.onChange("generalInfo",o),
              site:this.state.site,
              zone:this.state.zone,
            }}/>
          </main>
        </div>
      </div>
      
    )
  }
  
  showMessagingPage=()=>{
//     cl(this.state)
    let st=this.state
    switch(st.pageType){
      case "messageList":
        return(
            <C18MessageList00 parms={{
              onChange:o=>this.onChange("messaging",o),
              site:st.site,
              zone:st.zone,
            }}/>
        )
      case "messageEdit":
        return(
            <C18MessageEdit00 parms={{
              onChange:o=>this.onChange("messaging",o),
//               site:st.site,
//               zone:st.zone,
              messageId:st.messageId,
            }}/>
        )
      case "messageDisplay":
        return(
            <C18MessageDisplay00 parms={{
              onChange:o=>this.onChange("messaging",o),
              messageId:st.messageId,
//               site:st.site,
//               zone:st.zone,
            }}/>
        )
    }
  }
  
  showMessaging=()=>{
    setTitle("Messaging")
//     cl(this.state)
    return(
      <div  className={(this.state.savePage)?"save-page":""}
        style={{height:"100%"}}
      >
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <h1>{this.state.pageTitle}</h1>
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <div className="reports-and-graphs">
                  {this.showMessagingPage()}
                </div>
              </section>
            </div>
          </main>
          {this.imageDisplayRender()}
        </div>
      </div>
    )
  }
  
  showDevHome=()=>{
    setTitle("Dev Home")
//     cl(this.state)
    return(
      <div style={{height:"100%"}}>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <h1>{this.state.pageTitle}</h1>
            
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <div className="reports-and-graphs">
                  <C18DevHome00 parms={{
                    onChange:o=>this.onChange("devHome",o),
                  }}
                  />
                </div>
              </section>
            </div>
          </main>
          {this.imageDisplayRender()}
        </div>
      </div>
    )
  }
  
  showCropRecipePage=()=>{
    let st=this.state
//     cl(st)
    switch(st.pageType){
      case "phases":
        return(
          <C18CropPhases00 
          parms={{
            onChange:e=>this.onChange("cropPhases",e),
            adminInfo:st.adminInfo,
          }}
          >
          </C18CropPhases00>
        )
      case "days":
        return(
          <C18DayPhases00
            parms={{
              onChange:e=>this.onChange("cropPhases",e),
              adminInfo:st.adminInfo,
              adminInfo2:st.adminInfo2,
            }}
          >
          </C18DayPhases00>
        )
      default:
        return(
          <C18CropRecipeList00>
          </C18CropRecipeList00>
        )
    }
  }
  
  showCropRecipes=()=>{
    setTitle("Crop Recipes")
//     cl(this.state)
    let st=this.state
//     cl(st)
    return(
      <div style={{height:"100%"}} className={(this.state.savePage)?"save-page":""}>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <C18Confirm00 parms={this.state.popup}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <h1>{this.state.pageTitle}</h1>
            
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <div className="reports-and-graphs">
                  <C18SubMenuHeader00 parms={{
                    items:[
                      {v:"recipes",t:"Crop Recipes"},
                      {v:"phases",t:"Crop Phases"},
                      {v:"days",t:"Day Phases",d:(["recipes","phases"].includes(st.adminInfo))},// d is disabled
                    ],
                    pageType: this.state.pageType,
                    onChange: o=>this.onChange("cropRecipesPage",o),
                  }}/>
                {this.showCropRecipePage()}
                </div>
              </section>
            </div>
          </main>
          {this.imageDisplayRender()}
        </div>
      </div>
    )
  }
  
  showSvgEditorPage=()=>{
    return(
      <div>
        <C18SvgEditor00 parms={{
          onChange:e=>this.onChange("svgEditor",e)
        }}
        >
        </C18SvgEditor00>
      </div>
    )
  }
  
  showSvgEditor=()=>{
    setTitle("SVG Editor")
//     cl(this.state)
    let st=this.state
//     cl(st)
    return(
      <div
      style={{height:"100%"}} 
      className={"fullwidth "+((this.state.savePage)?"save-page":"")}>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <C18Confirm00 parms={this.state.popup}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <h1>{this.state.pageTitle}</h1>
            
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <div className="reports-and-graphs">
                  {this.showSvgEditorPage()}
                
                </div>
              </section>
            </div>
          </main>
          {this.imageDisplayRender()}
        </div>
      </div>
    )
  }
  
  showPhysViewPage=()=>{
    return(
      <div>
        <C18PhysView00 parms={{
          onChange:e=>this.onChange("physView",e)
        }}
        >
        </C18PhysView00>
      </div>
    )
  }
  
  showPhysView=()=>{
    setTitle("SVG Editor")
//     cl(this.state)
    let st=this.state
//     cl(st)
    return(
      <div
      style={{height:"100%"}} 
      className={"fullwidth "+((this.state.savePage)?"save-page":"")}>
        <C18MenuBar00 parms={{
          onChange:o=>this.onChange("menuBar",o),
          siteStatus:this.state.siteStatus,
          mode:this.state.mode,
        }}/>
        <C18Confirm00 parms={this.state.popup}/>
        <div id="page-container">
          <main id="main" className="no-sidebar">
            <C18Breadcrumb00 parms={{
              breadcrumbs: this.state.breadcrumbs
            }}/>
            <h1>{this.state.pageTitle}</h1>
            
            <C18SaveHeader00 onChange={v=>this.onChange("saveHeader",v)}/>
            <div id="content-area">
              <section className="link4">
                <div className="reports-and-graphs">
                  {this.showPhysViewPage()}
                
                </div>
              </section>
            </div>
          </main>
          {this.imageDisplayRender()}
        </div>
      </div>
    )
  }
  
  render(){
//     cl(globs.userData.session.groupId)
    if(acctFeature("zoneGroups")&&globs.userData.session?.groupId){
      document.body.classList.add("groupMode")
    }else{
      document.body.classList.remove("groupMode")
    }
//     cl(this.state.pageType)
//     cl(this.state)
//     cl(globs.userData.session.sessionId)
//     cl(`c18 host render ${this.state.loaded}, ${this.state.mode}`)
//     cl(this.state)
//     cl("render")
//     cl(this.props.url)
//     console.log("this.state.mode")
//     console.log(this.state.mode)
    
    if(this.state.loaded&&this.state.mode){
//       cl("render loaded")
      // cl(this.state.mode)
      let pages={sites: this.showSites, site: this.showSites, zones: this.showZones, 
        settings: this.showSettings, 
        admin: this.showAdmin, 
        fui: this.showFui,
        graph: this.showGraph,
        reports: this.showReports,
        tasks: this.showTasks,
        login: this.showLoginRegister,
        register: this.showLoginRegister,
        devRegister: this.showLoginRegister,
        postRegister: this.showLoginRegister, 
        activate: this.showLoginRegister,
        inviteUser:this.showLoginRegister,
        logout: this.showLoginRegister,
        idoser: this.showIDoser,
        modbus: this.showModbus,
        modbusGp: this.showModbusGp,
        manageAlarms2: this.showManageAlarms2,
        modbusDevicesEdit: this.showModbusDevicesEdit,
        modbusDevicesEdit2: this.showModbusDevicesEdit2,
        alarmLog: this.showAlarmLog,
        generalInfo: this.showGeneralInfo,
        resetPassword:this.showLoginRegister,
        sensor:this.showSensor,
        messaging:this.showMessaging,
        threeD:this.showThreeD,
        saveRestore:this.showSaveRestore,
        cropRecipes:this.showCropRecipes,
        svgEditor:this.showSvgEditor,
        physView:this.showPhysView,
        devHome:this.showDevHome,
        pearl:this.showPearl,
        serverLog:this.showServerLog,

      }
//       cl(this.state)

      return(
        <>
          {pages[this.state.mode]()}
        </>
      )
//       cl(this.state.mode)
//       return pages[this.state.mode]()
    }else{
//       cl("loading")
      return <div id="content-area">loading host. . .</div>
    }
//     return(
//       <div>
//       <C18MenuBar00/>
//       <div id="page-container">
//       <main id="main" className="no-sidebar">
//         <C18Breadcrumb00/>
//         C18Host00
//       </main>
//       </div>
//       </div>
//     );
  }
}

export default C18Host00;
