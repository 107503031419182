import React from 'react';
import {showSelect, showInput} from '../../components/utils/reactUtils';
import {cl} from '../../components/utils/utils';

class SmartCool extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      arr: this.props.value,
    }
    this.props.getValue(props.ind, {type: "array", count: 80})
//     cl(this.props.value)
  }

  onChange=(type, valId, ind, e)=>{
//     cl([type, valId, ind, e])
//     var vals={}
    let arr=this.props.value.slice(0)
    arr[ind]=e.currentTarget.value
    this.props.onChange(this.props.ind, {value: arr})
    this.setState({arr:arr})
  }
  
  showSCInput=()=>{
    return(
      <div>
      {this.showInput()}
      </div>
    )
  }
  
  showSCArray=()=>{
    let rows=[]
    var cols=[<td key="x"></td>]
    var rowLabs=["Ku", "Gmax", "Kd", "Gmin"]
    for(let i=0;i<10;i++){cols.push(<td key={i} align="center">{i+1}</td>)}
    rows.push(<tr key="x">{cols}</tr>)

    for(let i=0;i<4;i++){
      cols=[<td key="x">{rowLabs[i]}</td>]
      
      for(let j=0;j<10;j++){
        let ind=2*(10*i+j)
        let val=this.props.value[ind]
//         cl(val)
        val=(val)?val:0
        let valId=""
        cols.push(
          <td key={j}>
          {showInput("sc", val, valId, ind, this.onChange)}
          </td>
        )
      }
      rows.push(
        <tr key={i}>{cols}</tr>
      )
    }
    return(
      <table><tbody>
      {rows}
      </tbody></table>
    )
  }
  
  render(){
    return(
      <div className="smart-cool-table-wrapper">
      {this.showSCArray()}
      </div>
    );
  }

}

export default SmartCool ;
