import React from 'react';
import AuxControl from './AuxControl'
import UsaIcon from '../../usa/components/UsaIcon';
import {cl, globs, leadZeros, deepCopy, getTime} from '../../components/utils/utils';

class AuxControls01 extends React.Component{
  constructor(props) {
    super(props);
    // cl(props)
    this.state={
      pageType:props.type,
    }
  }
  
  onChange=(type,i,vals)=>{
    switch(type){
      case "addAux":
//         let ac=this.state.auxControls.slice(0)
//         ac.push(this.makeOneAux())
        this.props.parms.onChange("addAux",{ac:this.makeOneAux()})
//         this.setState({auxControls: this.makeOneAux()})
        break
      case "auxControl":
//         cl(type,i)
//         cl(vals)
        this.props.parms.onChange("auxControl",{i:i,v:vals})
//         let ac=this.state.auxControls.slice(0)
//         ac[ind]=deepCopy(vals)
//         this.setState({auxControls: ac})
        break
//       case "saveCancel":
//         switch(vals.value){
//           case "save":
//             this.saveAuxControls()
//             break
//           default:
//             break
//         }
//         break
    }
  }
  
  makeOneAux=()=>{
    return {
//       editField: -1,
      operandTypes: [0,0,0,0],// ind 3 is the target type/value
      operandValues: [0,0,0,0],
      operators: [0,0],
      conditionSeconds: 0,
      conditionSecondsText: "00:00",
      conditionSecondsFocus: false,
//       durationDiv: 1,
      action: 0,
//       targetType: 0,
//       targetValue: 0,
      actionParameter: 0,
      actionHoldTime: 0,
      actionHoldText: "00:00",
      actionHoldFocus: false,
      userComment: "",
    }
  }
  
//   showAddAuxControl=()=>{
//     return(
//       <div>
//           <UsaIcon icon="Aux-Add" 
//           result={k=>this.onChange("addAux")} 
//           saveOK={!this.props.parms.saveOK}
//           inline/>
//       </div>
//     )
//   }
  
  newProps={}
  saveNewProps=(f,i)=>{// to register a components newProps function
//     cl(i)
    this.newProps[i]=f
  }
  
  auxControlKey=0
  
  showAuxControls2=()=>{
//     cl(this.props.parms.auxControls)
    return this.props.parms.auxControls.map((a,i)=>{
//       cl(a)
//           <UsaIcon icon="Aux-Up" 
//           saveOK={!this.props.parms.saveOK}
//           result={k=>this.result(k,i)} inline/>
//           <UsaIcon icon="Aux-Down" 
//           saveOK={!this.props.parms.saveOK}
//           result={k=>this.result(k,i)} inline/>
//           <UsaIcon icon="Aux-Delete" 
//           saveOK={!this.props.parms.saveOK}
//           result={k=>this.result(k,i)} inline/>
      return (
        <div key={i}>
          <AuxControl parms={{
            auxControl: a,
            inline: true,
            onChange: vals=>this.onChange("auxControl",i,vals),// vals is the whole aux control
//             newProps: f=>this.saveNewProps(f,i),
            newProps: f=>this.props.parms.newProps(f,i),
            variables: this.props.parms.variables,
            pVariables: this.props.parms.pVariables,
            auxAlarms: this.props.parms.alarms,
            equipments: this.props.parms.equipments,
            equipmentTypes: this.props.parms.equipmentTypes,
            tanks: this.props.parms.tanks,
            saveOK:this.props.parms.saveOK,
            sensors:this.props.parms.sensors,
            index:i,
            getPopup:this.props.parms.getPopup,
          }}/>
        </div>
      )
    })
  }
  
  showAuxControls=()=>{
//     if(this.state.auxControls.length){
    let varType="AuxControls"
    return(
      <div style={{
        animationName: "fadeIn",
        animationDuration: "3s",
      }}>
      {this.showAuxControls2()}
      <button className="material-icons-outlined add" aria-label="add nutrient"
        disabled={!this.props.parms.saveOK}
        onClick={e=>this.onChange("addAux",{})}
      >add</button>
      <div style={{height: 10}}/>
      </div>
    )
  }
  
  render(){
    return(
      <div>
        {this.showAuxControls()}
      </div>
    )
  }
}

export default AuxControls01 ;
