import React from 'react';
import * as d3 from 'd3';
import {wsTrans,sensorIds,getUId,checkLoggedIn} from '../../usa/utils/utils'
import {cl,globs,constant,getTime,getTimeI,dateToDisplayDate,az} from '../../components/utils/utils';
// import ArrowBack/* */from '@mui/icons-material/Delete';
// import ArrowBack from 'material-ui/svg-icons/action/keyboard-arrow-right';
import FastRewind from '@material-ui/icons/FastRewind'
import FastForward from '@material-ui/icons/FastForward'
import PlayArrow from '@material-ui/icons/PlayArrow'
import Pause from '@material-ui/icons/Pause'

import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'

class VidTimeline00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.svgDiv=React.createRef();
    this.imgEl=React.createRef();
    this.mounted=false
//     this.zFact=10000// zoom factor
    this.w=600
    this.x=0
    this.tr={x:0,y:0,k:1}
    this.loadInfo()
    this.mDown=false
//     document.onwheel=e=>{
//       cl(e)
//       e.stopPropagation()
//     }
    
    this.state={
      loaded:false,
      selTime:getTimeI(),
      selPos:this.w,
      play:0,
      spdPos:0,
      imgX:0,
      imgY:0,
      imgZ:1,
      camW:0,
      camH:0,
      camDivW:this.w,
      camDivH:338,
      
    }
    this.playTimer=setInterval(this.doPlay,200)
//     this.w=600
  }

  componentDidMount() {
    this.mounted=true
//     document.addEventListener("mousedown",this.md,false)
    document.addEventListener("mouseup",this.mu,false)
    document.addEventListener("mousemove",this.mm,false)
    document.addEventListener('wheel', this.wh, { passive: false });    
  }
  
  componentWillUnmount=()=>{
    cl("unmount")
    document.removeEventListener("mouseup",this.mu,false)
    document.removeEventListener("mousemove",this.mm,false)
    document.removeEventListener('wheel', this.wh, { passive: false });    
  }
  
  loadInfo=async()=>{
    let pa=this.props.parms
    let camId={cameraId:pa.camera}
//     cl(camId)
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/cameras", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: camId})
    let cam=resp.data[0]
//     cl(cam)
    this.zFact=(cam.last-cam.first)/this.w
    
    
    this.setState({loaded:true,camera:cam})
//     cl(cam.first,this.x2Time(0))
//     cl(cam.last,this.x2Time(this.w))
    this.initZoom()
    this.getImage(pa.camera,getTimeI())
//     cl(this.state.camera)
    this.drawBoxes()
  }

  onChange=(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    switch(type){
      case "play":
        this.startPlay(360)
        break
      case "forward":
        this.startPlay(8640)
        break
      case "rewind":
        this.startPlay(-8640)
        break
      case "pause":
        this.setState({play:false})
        break
      case "next":
        this.setState({selTime:st.selTime+60})
        break
      case "prev":
        this.setState({selTime:st.selTime-60})
        break
    }
  }

  zoom = d3.zoom().on('zoom', z=>{this.hz(z)});
  
  ovf=0

  hz=z=>{
//     if(this.ovf++>10){return}
    let tr=this.tr
    let cam=this.state.camera
    this.tr=z.transform
    let ts=this.x2Time(0)
    let ts2=this.x2Time(this.w)
//     cl(this.tr)
//     cl(ts,ts2)
//     cl(cam.first,cam.last)
    if((ts<cam.first)||(ts2>cam.last)){//
//       if(ts2>=cam.last){
//         tr.k=(400-tr.x)/(cam.last-cam.first)*this.zFact
//       }
      this.tr=tr
      cl(this.tr)
      this.svg.call(this.zoom.transform, d3.zoomIdentity.translate(tr.x, tr.y).scale(tr.k))
      return
    }
    
    this.tl.attr("transform",
          `translate(${this.tr.x},0) scale(${(this.tr.k/this.zFact)},1) `)//
    clearTimeout(this.tmr)
    this.tmr=setTimeout(this.drawBoxes,400)
    if(this.state.play){this.setState({play:false})}
//     this.throttleDraw()
  }

  loadFrames=()=>{}
  
  tsToFile=(ts)=>{
    let tzo=480
    let da2=new Date(1000*ts-60000*tzo)
    let mm=1+da2.getUTCMonth()
    let dd=da2.getUTCDate()
    let yyyy=da2.getUTCFullYear()
    let HR=da2.getUTCHours()
    let mn=da2.getUTCMinutes()
    return `v${az(mm,2)}${az(dd,2)}${az(HR,2)}${az(mn,2)}`
  }
  
  appendImage=(boxes,x,int,ts)=>{
//     cl([x,int,ts])
//     cl(ts)
    let da=new Date(1000*ts)
    let pa=this.props.parms
//     cl(da)
//     let tsFn=this.tsToFile(ts)
//     let fr=this.frames.data
//     let fn=fr[0]
//     for(let i=0;i<fr.length;i++){
//       if(tsFn<fr[i]){
//         break
//       }
//       fn=fr[i]
//     }
//     cl(fn)
//     let path=`http://ngsg.link4cloud.com:3105/usa/videos/cam1/${fn}`
    
    let path=`${constant.camUrl}:${constant.camExpress}/camVideo/${pa.camera}/${ts}`

    let im=boxes.append('svg:image')
//     cl(int)
    let scale=1/(this.tr.k/this.zFact)
//     cl(ts,x/scale)
    im
    .attr('x',x/scale)
    .attr('y',0)
    .style('width',64)
    .style('height',36)
    .attr("xlink:href", path)
    .attr("transform",`scale(${scale},1) `)//
//     .on('click',(x,y,z)=>{cl([x,x.offsetX,x.clientX])})
// "http://ngsg.link4cloud.com:3105/usa/videos/cam1/v03101316.jpg"/>
  }
  
  selImage=(vals)=>{
    let selTime=this.x2Time(vals.e.offsetX)
    this.setState({selTime:selTime,selPos:vals.e.offsetX,play:false})
    
  }
  
  x2Time=(x)=>{
    let ts=this.state.camera.first+(x-this.tr.x)/(this.tr.k/this.zFact)
    return ts
  }
  
  time2X=(ts)=>{
    return (ts-this.state.camera.first)*(this.tr.k/this.zFact)+this.tr.x
  }

  drawBoxes=async()=>{// initial size is 400 seconds. draw 1 minute lines
    let intervals=[1,1.3,1.7,2.2,3,4, 5.2, 6.8, 8.8, 12, 16, 20, 27, 35, 45,
    60, 80, 100, 135, 180, 240, 300, 400, 540, 720, 960, 1200, 
    1440, 1920, 2400, 3240, 4320, 5760, 7200, 9600, 12960, 17280, 28800, 36000, 
    43200, 57600, 72000, 95040, 129600, 172800, 216000, 288000, 388800, 525600]
    let w=this.w/(this.tr.k/this.zFact)
    let t0=this.state.camera.first//1641024000// 1-1-2022
    var int
    for(let i=0;i<intervals.length;i++){
      int=60*intervals[i]
      let cnt=w/int
      if(cnt<6){break}
    }
//     cl(int)
    let boxes=this.tl.append('g')
//     if(this.clickable){this.clickable.remove()}
//     this.clickable=this.tl.append('rect')
//     this.clickable
//     .attr('width',this.w/(this.tr.k/this.zFact))
//     .attr('height',32)
//     .attr('fill','white')
//     .style('opacity',0.0)
//     .on('click',e=>this.selImage({e:e}))
    let min=(-this.w-this.tr.x)/(this.tr.k/this.zFact)
    let max=min+1200/(this.tr.k/this.zFact)//this.w
    let iMin=Math.floor(min/int)
    let iMax=Math.floor(max/int)
//     cl(iMin,iMax)
//     this.frames=await wsTrans("usa", {cmd: "cRest", uri: "/s/videoFrames", 
//       method: "retrieve",
//       sessionId: globs.userData.session.sessionId, body: {from:t0+Math.floor(min),to:Math.floor(t0)+max}})
//     cl(frames)


    for(let i=iMin;i<=iMax;i++){
      let x0=int*i// this needs to be mapped
//       cl(x0)
      let t1=t0+x0+int
      let da=new Date(1000*t1)
//       cl(da)
      let dy=dateToDisplayDate(da,"mm/dd",420)
      let hm=dateToDisplayDate(da,"hh:mm",420)
//       cl([dy,hm])
//       cl([dy,hm])
      let ts=t0+x0
      let rc=boxes.append('rect')
      rc
      .attr('x',x0)
      .attr('y',0)
      .attr('width',int)
      .attr('height',36)
      .attr('fill','white')
      .attr('stroke',"black")
//       .on('click',(x,y,z)=>{cl([x,x.offsetX,x.clientX])})
      this.appendImage(boxes,x0,int,t1-int)// -int is a hack. image and time should be in the same rect
//       cl(hm,(int+x0-int*.04)*(this.tr.k/this.zFact))
      boxes.append('text')
      .attr('x',(int+x0-int*.04)*(this.tr.k/this.zFact))
      .attr('y',15)
      .style("text-anchor","end")
      .attr('fill','black')
      .attr("transform",`translate(0,0) scale(${1/(this.tr.k/this.zFact)},1) `)//
      .text(`${dy}`)
      boxes.append('text')
      .attr('x',(int+x0-int*.04)*(this.tr.k/this.zFact))
      .attr('y',32)
      .style("text-anchor","end")
      .attr('fill','black')
      .attr("transform",`translate(0,0) scale(${1/(this.tr.k/this.zFact)},1) `)//
      .text(`${hm}`)
//       .attr('text','here')
    }
    if(this.boxes){this.boxes.remove()}
    this.boxes=boxes
    clearInterval(this.tmr)
    this.tmr=null
    let selTime=this.state.camera.first+(this.state.selPos-this.tr.x)/(this.tr.k/this.zFact)
//     cl(selTime)
    this.setState({selTime:selTime})
  }

  initZoom=()=> {
    this.svg = d3.select(this.svgDiv.current).append("svg")
    this.svg
    .attr('width',this.w)
    .attr('height',36)
    this.tl=this.svg.append('g')

    this.clickable=this.svg.append('rect')
    this.clickable
    .attr('width',this.w)
    .attr('height',32)
    .attr('fill','white')
    .style('opacity',0.0)
    .on('click',e=>this.selImage({e:e}))

    this.svg.call(this.zoom)
    this.tr={x:0,y:0,k:1}
    this.tl.attr("transform",
          `translate(${this.tr.x},0) scale(${(this.tr.k/this.zFact)},1) `)//
//     this.drawBoxes()
  }
  
  imgOnLoad=(imgP,e)=>{
//     cl(e)
    let st=this.state
    imgP.loaded=true
    let imInfo=e.path[0]
    imgP.height=imInfo.height
    imgP.width=imInfo.width
    let divH=imInfo.height*600/imInfo.width
    if(!this.state.camW){this.setState({camW:imInfo.width,camH:imInfo.height,camDivH:divH})}
    let loadTime=getTime()-imgP.createTS
//     cl(loadTime)
    
  }
  
  imgOnError=(imgP,e)=>{
    cl(e)
    imgP.error=true
  }
  
  doPlay=()=>{
    let st=this.state
    if(st.play){
      let ts=this.playArray[0]?.ts
      let end=this.x2Time(this.w)
      let beg=this.x2Time(0)
//       cl(beg,end,ts)
      this.setState({selTime:ts,play:((ts<end)&&(ts>beg))})
      if(this.playArray[0]?.loaded){
        this.imgEl.current.src=this.playArray[0].img.src
        this.playArray.shift()
      }
      if(this.playArray[0]?.error){
        this.playArray.shift()
      }
      this.fillPlayArray(st.camera.cameraId)
    }
  }
  
  fillPlayArray=(camId)=>{
    let pla=this.playArray
    let len=pla.length
    if(len>=3){return}
    let ts=pla[len-1].ts
    for(let i=1;i<3;i++){
      this.playArray.push(this.getImage(camId,ts+i*this.playSpeed))
    }
//     cl(next)
  }
  
  getImage=(camId,ts)=>{
//     cl(camId,ts)
    let imgP={
      img:new Image(),
      createTS:getTime(),
      ts:ts}
    imgP.img.onload=e=>this.imgOnLoad(imgP,e)
    imgP.img.onerror=e=>this.imgOnError(imgP,e)
    imgP.img.src=`${constant.camUrl}:${constant.camExpress}/camVideo/${camId}/${ts}`
//     cl(imgP.img)
    return imgP
  }
  
  startPlay=(spd)=>{
    let st=this.state
    let selTime=st.selTime+60
    selTime-=(selTime%60)// to begin of minute
    let imgP=this.getImage(st.camera.cameraId,selTime)
    this.playArray=[imgP]
    this.playSpeed=spd
    this.setState({play:true,})
    this.fillPlayArray(st.camera.cameraId)
//     this.frameCount=0
  }
  
  mdi=(e)=>{
    cl(e)
    let st=this.state
    this.mdiDown=true
    this.mdiStartX=e.clientX-st.imgX
    this.mdiStartY=e.clientY-st.imgY
  }
  
  wh=(e)=>{
    e.preventDefault()
    let st=this.state
    let dx0=e.offsetX//-st.imgX
    let dy0=e.offsetY//-st.imgY
    let dz=(e.wheelDeltaY>0)?1.1:(1/1.1)
    let dx1=dx0*dz
    let dy1=dy0*dz
    let imgX=st.imgX+dx0-dx1
    if(imgX>0){imgX=0}
    let imgY=st.imgY+dy0-dy1
    if(imgY>0){imgY=0}
    let imgZ=st.imgZ*dz
    if(imgZ<1){return}
    
    let wid=this.w*imgZ
    let hgt=st.camH*wid/st.camW
    let frameW=this.w
    let frameH=hgt*frameW/wid
//     cl(imgX,wid,frameW)
    if(imgX+wid<frameW){imgX=frameW-wid}
//     cl(imgX)
    if(imgY+hgt<frameH){imgY=frameH-hgt}
    this.setState({imgX:imgX,imgY:imgY,imgZ:imgZ})
//     return false
  }
  
  md=(e)=>{
    this.mDown=true
    this.startX=e.clientX-this.state.spdPos
    this.startY=e.clientY
    cl(`down: ${e.clientX}`)
    this.startPlay(60)
//     this.setState({play:false})
//     cl(e)
  }
  
  mu=(e)=>{
    if(this.mDown){
      this.mDown=false
//       cl(`up: ${e.clientX}`)
//       cl(this.startX)
//       cl(this.state.spdPos)
      this.playSpeed=0
      this.setState({spdPos:0,play:false})
    }
    if(this.mdiDown){
      this.mdiDown=false
    }
  }
  
  mm=(e)=>{
// -=80 - +80, speed top 1 year / 5 secs min 
    let st=this.state
    if(this.mDown){
      let dx=e.clientX-this.startX
      if(dx>80){dx=80}
      if(dx<-80){dx=-80}
      this.setState({spdPos:dx})
//       cl(dx)
      let ln=0.122*Math.abs(dx)
      let mult=Math.exp(ln)*((dx>0)?1:-1)
      
//       cl(mult)
      this.playSpeed=60*mult
    }
    if(this.mdiDown){
      let imgX=e.clientX-this.mdiStartX
      let imgY=e.clientY-this.mdiStartY
      if(imgX>0){imgX=0}
      if(imgY>0){imgY=0}
      let wid=this.w*st.imgZ
      let hgt=st.camH*wid/st.camW
      let frameW=this.w
      let frameH=hgt*frameW/wid
      if(imgX+wid<frameW){imgX=frameW-wid}
      if(imgY+hgt<frameH){imgY=frameH-hgt}
      this.setState({imgX:imgX,imgY:imgY})
    }
  }
  
  showEditBar=()=>{
    let st=this.state
    let da=new Date(1000*st.selTime)
    let daStr=dateToDisplayDate(da,"yyyy-mm-dd hh:mm",420)
//                   onMouseUp={e=>this.mu(e)}
//                   onMouseMove={e=>this.mm(e)}
    return(
        <div>
          <table style={{height: 12, width:this.w}}><tbody>
            <tr style={{textAlign:"center", cursor:"pointer", backgroundColor:"#CCD5DD",
            }}>
            <td width="33" height="20" style={{borderStyle:"solid",borderWidth:1}}
              onClick={e=>this.onChange("rewind")}
            >
            <FastRewind/>
            </td>
            <td width="33" height="20" style={{borderStyle:"solid",borderWidth:1}}
              onClick={e=>this.onChange("prev")}
            >
            <ArrowBackIos style={{marginLeft:6,marginRight:-6,fontSize:18}}/>
            </td>
            <td width="34" height="20" style={{borderStyle:"solid",borderWidth:1}}
              onClick={e=>this.onChange("pause")}
            >
            <Pause/>
            </td>
            <td width="34" height="20" style={{borderStyle:"solid",borderWidth:1}}
              onClick={e=>this.onChange("next")}
            >
            <ArrowForwardIos style={{fontSize:18,margin:"0px 0px 0px 3px"}}/>
            </td>
            <td width="33" height="20" style={{borderStyle:"solid",borderWidth:1}}
              onClick={e=>this.onChange("play")}
            >
            <PlayArrow/>
            </td>
            <td width="33" height="20" style={{borderStyle:"solid",borderWidth:1}}
              onClick={e=>this.onChange("forward")}
            >
            <FastForward/>
            </td>
            <td width="200">{daStr}</td>
            <td width="200" style={{borderStyle:"solid",borderWidth:1}}>
              <div style={{width:200,height:20,position:"relative"}}>
                <div style={{width:160,height:4,borderStyle:"solid",
                  borderWidth:1,position:"absolute",left:20,top:8}}/>
                <div style={{width:14,height:14,position:"absolute",left:90+st.spdPos,
                  top:3,backgroundColor:"#CCCCCC",borderStyle:"solid",borderRadius:"50%",borderWidth:2}}
                  draggable="false"
                  onMouseDown={e=>this.md(e.nativeEvent)}
                  />
              </div>
            </td>
            </tr>
          </tbody></table>
        </div>
    )
  }

  render(){
//  style={{fontSize:48}}    
// http://ngsg.link4cloud.com:3105/usa/videos/cam1/v03101316.jpg
// /usr/l4c2/files/videos/cam1
    if(this.state.loaded){
      let pa=this.props.parms
      let st=this.state
//       cl(st)
//       cl(st.selTime)
      let wid=this.w*st.imgZ
      let cam=pa.camera
      let now=Math.floor(getTime())
  //     let selPos=(st.selTime-st.camera?.first)*(this.tr.k/this.zFact)+this.tr.x-1
      let selPos=this.time2X(st.selTime)
      if(selPos>this.w){selPos=this.w}
      if(isNaN(selPos)){selPos=0}
      return(
        <div>
          <div style={{position:"relative",width:this.w,height:st.camDivH,borderRadius:10,
            overflow:"hidden",overscrollBehavior:"none"}}
            onMouseDown={e=>this.mdi(e.nativeEvent)}
          >
            <div style={{position:"absolute",left:st.imgX,top:st.imgY,
              width:wid,overscrollBehavior:"none",overscrollBehaviorInline:"none"}}>
              <img ref={this.imgEl} width={wid}
              draggable="false"
              style={{borderRadius:10}}
              src={`${constant.camUrl}:${constant.camExpress}/camVideo/${cam}/${this.state.selTime}`}
              />
            </div>
          </div>
          {this.showEditBar()}
          <div ref={this.svgDiv}
          style={{
            width:this.w,
            height:36,
            backgroundColor:"#CCD0D4",
            position:"relative",
          }}>
            <div style={{position:"absolute",width:3,height:36,marginLeft:selPos,borderStyle:"solid",
              borderWidth:1,backgroundColor:"white"
            }}/>
          </div>
        </div>
      )
      
    }else{
      return <div id="content-area">loading. . .</div>
      
    }
  }
}

export default VidTimeline00 ;
