import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
// import InTemp from './InTemp';
// import EQ00 from './EQ00';
// import GenericWidget from './GenericWidget';
import GrowJournal00 from './GrowJournal00';
import CreateMessage00 from './CreateMessage00';
import CreateToDo00 from './CreateToDo00';
import UsaIcon from './UsaIcon';
import GenericWidget from '../../visualization/components/GenericWidget';
import InTemp from '../../visualization/components/InTemp';
import EQ00 from '../../visualization/components/EQ00';
import TimeSeries00 from '../../visualization/components/TimeSeries00';
import Link00 from '../../visualization/components/Link00';
import Site00 from '../../visualization/components/Site00';
import Zone00 from '../../visualization/components/Zone00';
import Feed00 from '../../visualization/components/Feed00';
import NameWidget00 from '../../visualization/components/NameWidget00';
import GenericGaugeWidget01 from '../../visualization/components/GenericGaugeWidget01';
import EquipmentListWidget00 from '../../visualization/components/EquipmentListWidget00';
import ZoneListWidget00 from '../../visualization/components/ZoneListWidget00';
import DashboardWidget00 from '../../visualization/components/DashboardWidget00';
import LinkWidget01 from '../../visualization/components/LinkWidget01';
// import GenericWidget from '../../visualization/components/GenericWidget';
import {cl, globs, constant, getRandomString, userSpecificHome, setRestTimeout, getTime} from '../../components/utils/utils';
import {init, dbVals} from '../../components/utils/http'
import {addDataHook, removeHook} from '../../components/utils/ws'
import {wsTrans, getParmValue, checkLoggedIn, getParmInfo} from '../utils/utils'
import history from "../../history"

class DashDisplay00 extends React.Component{
  constructor(props) {// 
//     cl(props)
    super(props);
    let nw=new NameWidget00({notify: x=>{}})
    let gw01=new GenericGaugeWidget01({notify: x=>{}})
    let el00=new EquipmentListWidget00({notify: x=>{}})
    let zl00=new ZoneListWidget00({notify: x=>{}})
    let db00=new DashboardWidget00({notify: x=>{}})
    let li01=new LinkWidget01({notify: x=>{}})
    this.newWidgets=[nw,gw01,el00,zl00,db00,li01]
    cl(globs.userData.session)
//     cl(props)
//     cl("dash construct")
    this.dashDiv=React.createRef();
    //     cl(props)
    this.state={
      drag: {x: 0, y: 0},
      dashboard: null,
      widgets: [],
      screenW: 0,
      screenH: 0,
      loaded: false,
      edit: false,
      contextOpen: false,
      menuAnchor: null,
      menux: 0,
      menuy: 0,
      imageDisplay:[],
      showCreateGrowJournal:false,
      showCreateMessage:false,
}
//     this.stacontextOpen=false
//     this.menuAnchor=null
    this.notifies=[]
    this.initAll()
    this.subscribe_widgetUpdated = globs.events.subscribe("widgetUpdated", this.widgetUpdated)
    this.subscribe_dashboardUpdated=globs.events.subscribe("dashboardUpdated", this.dashboardUpdated)
    this.subscribe_windowResize=globs.events.subscribe("windowResize", this.windowResize)
    this.hooks=[]
//     this.subscribe_readWidgetData=globs.events.subscribe("readWidgetData", this.readWidgetData)
  }
  
/********************* These are the new Widget functions, to make them freestanding**********************/

  newWidgetMake=(widgetType,wdg)=>{
    let nw=this.newWidgets
    for(let i=0;i<nw.length;i++){
      if(nw[i].widgetType.v==widgetType){
        return nw[i].makeParms(wdg)
      }
    }
    return{}
  }
  
  newWidgetShow(widgetType, x, y, w, h, wdg, ind){
//     cl(ind)
    let nw=this.newWidgets
    for(let i=0;i<nw.length;i++){
      if(nw[i].widgetType.v==widgetType){
        return nw[i].showWidget(this, x, y, w, h, wdg, ind)
      }
    }
    return null
  }
  


/********************* End of new Widget functions **********************/

  removeHooks=()=>{
    this.hooks.forEach(h=>{
//       cl(h)
      removeHook(...h)
//       cl(h)
    })
    this.hooks=[]
  }
  
  setZone=()=>{
//     cl("set zone")
//     var zoneIndex
//     cl(this.props)
//     cl(this.state.dashboard)
    let dash=this.state.dashboard
//     cl(dash)
    let zuci=this.props.zuci
    if(!zuci){zuci="0-0-0-0"}
    let p=zuci.split("-")
    this.zo=(dash.z!="") ? +dash.z : +p[0]
    this.ch=(dash.c!="") ? +dash.c : +p[2]
    this.in=(dash.ix!="") ? +dash.ix : +p[3]
    this.zone=this.zones[this.zo]
//     cl(this.zo)
//     cl(this.zone)
//     cl(dbVals.z[this.zo])
    return !dbVals.z[this.zo]
//     cl([this.zo,this.ch,this.in])
//     cl(this.zone)
  }
  
  widgetUpdated=(info)=>{
//     cl(info)
//     cl(this.state)
    if(info.widget){
      let widgets=this.state.widgets.slice(0)
      for(let i=0; i<widgets.length; i++){
        if(widgets[i].id==info.widget.id){
          widgets[i]=Object.assign({},info.widget)
          //cl("setState")
          this.setState({widgets: widgets})
          return
        }
      }
    }
  }
  
//   componentDidUpdate=()=>{
//     this.readWidgetData()
// //     cl("did")
//   }
  
//   componentDidMount=()=>{this.mounted=true; cl("mount")}
  componentWillUnmount=()=>{
    this.mounted=false; 
//     cl("unmount")
    this.subscribe_widgetUpdated.remove()
    this.subscribe_dashboardUpdated.remove()
    this.subscribe_windowResize.remove()
  }
  
  initAll=async()=>{
    let logged=await checkLoggedIn()
//     cl("init1")
    if(!logged){history.push("/usa/login"); return}
//     cl("init1")
    let in0=init()
//     cl(this.props.page)
    let db= this.loadDashboard(this.props.page)
//     cl("init1")
    let zo=this.loadZones()
//     cl(zo)
    setRestTimeout("dashboard", db)
//     setRestTimeout("initialization", in0)
    setRestTimeout("zone", zo)
//     cl("init1")
//     db.then(x=>{cl("db")})
//     in0.then(x=>{cl("in0")})
//     zo.then(x=>{cl("zo")})
    Promise.all([db, in0, zo]).then(ra=>{
//     cl("init1")
//       cl(ra)
      let badZone=false
      if(ra[0]){badZone=this.setZone()}// if a valid dashboard
          //cl("setState")
      this.setState({loaded:true, badZone: badZone/*zones:zo*/})
//       cl(this.zones)
    })
  }
  
  setWeatherStationZone=(zones)=>{
    let ws=0
    Object.keys(zones).forEach(k=>{
      let z=zones[k]
      if(z.zoneId==globs.userData.session.weatherStation){
        ws=z.siteZoneIndex;
      }
    })
    this.weatherStationZoneIndex=ws
  }
  
  loadZones=async()=>{
//     cl("load zones")
    return new Promise((r,e)=>{
//       let body={i: dashboardId}// get one dashboard, by id
//       let getDashboard={cmd: "cRest", uri: "/s/dashboards", method: "retrieve", sessionId: globs.userData.session.sessionId,
//         body: body};
      
      wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "retrieve", 
        sessionId: globs.userData.session.sessionId,
        body: {siteId: globs.userData.session.siteId}})
        .then(re=>{
          let zones={}
          re.data.forEach(z=>{
            zones[z.siteZoneIndex]=z
          })
          this.zones=zones//re.data
//           cl(this.zones)
          this.setWeatherStationZone(this.zones)
          r()
//           cl(re)
//           r(re.data)
        })
    })
  }
  
  windowResize=(size)=>{
//     cl(`resized to: ${size[0]}, ${size[1]}`)
    this.dynamicDashboard(this.state.dashboard)
    let ws=globs.userData.winSize
          //cl("setState")
    this.setState({screenX: ws[0], screenY: ws[1]})
  }
  
  dashboardUpdated=(info)=>{
    if(info.dashboard){
          //cl("setState")
      this.setState({dashboard: info.dashboard})
    }
  }
  
  orderWidgets=(widgets)=>{
//     cl(5+"0")
    widgets.forEach(w=>{
      w.posOrder=(w.position.top*1e6)+(w.position.left*1)
//       cl(w)
    })
    let w2=widgets.sort((a,b)=>{
      if(a.posOrder>b.posOrder){return 1}
      if(a.posOrder==b.posOrder){return 0}
      return -1
    })
//     cl(w2)
//     cl(widgets)
    return widgets
  }
  
  loadOneDBWidgets=async(dashboards, widgets, dashboardId, parent)=>{// parent is the parent *widget*, if any
//     cl("load db "+dashboardId)
    dashboards.push(dashboardId)
    let re=await wsTrans("usa", {cmd: "cRest", uri: "/s/widgets", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {dashboard: dashboardId}})
    this.dbWidgets[dashboardId]={widgets: re.data}
    this.orderWidgets(this.dbWidgets[dashboardId].widgets)
    this.dbWidgets[dashboardId].widgets.forEach(async w=>{
      w.parent=parent// the parent *widget*, not dashboard
      widgets.push(w)
      if(w.type=="dashboard"){
//         cl(w)
        w.widgets=await this.loadOneDBWidgets(dashboards,widgets,w.parameters.dashboard,w)
      }
    })
    return widgets;
  }
  
  loadWidgets=async(dashboardId)=>{
/* the whole widget display system has to work even after loadAccountWidgets has loaded a totally new set of widgets
the actual widgets will get overwritten at that point, and the position, etc. needs to be refigured.
*/
    let widgets=[]
    this.dbWidgets={}
    let dashboards=[]
    await this.loadOneDBWidgets(dashboards, widgets, dashboardId,null)
//     cl(dashboards)
    let re=await wsTrans("usa", {cmd: "cRest", uri: "/s/dashboards", method: "retrieve2", 
      sessionId: globs.userData.session.sessionId,
      body: {i: {$in:dashboards}}})
    this.dbDashboards={}
    re.data.forEach(d=>{
      this.dbDashboards[d.i]=d
    })
//     cl(this.dbDashboards)
    this.dashboards=dashboards// an array of the dashboards that appear
/* this.dbWidgets is an AA with dashboardId=>the array of widgets for that dashboard
this.dbDashboards is an AA with dashboardId=>the dashboard info */    
//     cl(this.dbWidgets)
/*now, all the widgets on the page have been loaded, 
widgets that appear on dashboardWidgets have a "parent" property that points there
now we need to put all the widgets with the same parent in display order
*/
    return widgets
//     let w2=[]
//     widgets.forEach(w=>{
//       w2.push(w)
//       if(w.type
//     })
  }
  
  loadDashboard=async(dashboardId)=>{
    return new Promise(async(r,e)=>{
      let body={i: dashboardId}// get one dashboard, by id
      let getDashboard={cmd: "cRest", uri: "/s/dashboards", method: "retrieve", 
        sessionId: globs.userData.session.sessionId,
        body: body};
      let re=await wsTrans("usa", getDashboard)
      let dashboard=re.data
//       cl(dashboard)
      if(dashboard.n){
        globs.events.publish("setMainBarTitle", dashboard.n)
      }
//       let widgets=[]
//       this.loadOneDBWidgets(widgets, dashboardId,null)
      let widgets=await this.loadWidgets(dashboardId)
//       cl(widgets)
          //cl("setState")
      this.setState({dashboard: dashboard, widgets: widgets})
      r(dashboard)
    })
  }
  
  appendWidgets=(widgets, dashboard)=>{
    
  }
  
  positionWidgets2=(acctWidgets)=>{
/* this will be called after loadAccountWidgets, and needs to adjust *just* the widgets that appear on this dashboard
go through all the widgets, making an AA with the ids as keys
have to order them as they appear on screen
with child widgets immediately following the parent
each widget needs to know who its parent dashboard is, to get the size
the widgets should be flagged to show that they appear on this page
we already know what dashboards are on the page, before loading all the widgets
load account widgets
move them to a new widgets *object*, in the array order that they'll be displayed
go through the list in order, placing them on the page, given the current width
acctWidgets is the random order from the db
this.dbWidgets has the widgets organized by dashboard that shows
the widgets in dbWidgets are already in order. "orderWidgets" was used in loadOneDBWidgets
as the widgets are loaded one dashboard at a time
all of these widgets need to have their parent links set correctly, to position on page
we already have the widgets in order in this.state.widgets - just move them in that order, and *then* all the rest
 */
//     cl("position widgets 2")
    let widgets0={}// the AA of acct widgets
    acctWidgets.forEach(w=>{ widgets0[w.id]=w })// convert array to AA
    let widgets1=[]
    this.state.widgets.forEach(w=>{ // move the *showing* widgets to the new array
      widgets0[w.id].parent=w.parent
      widgets1.push(widgets0[w.id])
      delete widgets0[w.id]
    })
    Object.values(widgets0).forEach(w=>{widgets1.push(w)})// move the *rest* of the widgets
    return widgets1

  }
  
  loadAccountWidgets=async()=>{// actually, loading *user* at the moment
    let re=await wsTrans("usa", {cmd: "cRest", uri: "/s/widgets", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {accountId: globs.userData.session.accountId}})
//     re.data.forEach(w=>{
//       if(w.id=="BcSL6dUsVpUjZVG5"){
//         cl(w)
//       }
//     })
//           //cl("setState")
//     this.setState({widgets: re.data})
    this.backWidgets=this.deepCopy(re.data)
    return this.positionWidgets2(re.data)
//     return re.data
  }
  
  saveDashboard=async()=>{
//     let body={i: dashboardId}// get one dashboard, by id
    let saveDashboard={cmd: "cRest", uri: "/s/dashboards", method: "update", sessionId: globs.userData.session.sessionId,
      body: this.state.dashboard};
//     cl(saveDashboard)
    let r=await wsTrans("usa", saveDashboard)
    await this.updateModifiedWidgets()
//     let dashboard=r.data
//     body={dashboard: dashboardId}// not actually used, this gets *all* widgets for the account
//     let getWidgets={cmd: "cRest", uri: "/s/widgets", method: "retrieve", sessionId: globs.userData.session.sessionId,
//       body: body};
//     r=await wsTrans("usa", getWidgets)
//     let widgets=r.data
//     this.setState({dashboard: dashboard, widgets: widgets})
    
    
  }
  
  deepCopy=(widgets)=>{
    let wOut=[]
    widgets.forEach(w=>{
      let w2=Object.assign({},w)
      w2.position=Object.assign({},w2.position)
      wOut.push(w2)
//       cl(w)
    })
    return wOut
  }
  
  getWidgetInd=(id)=>{
    let w=this.state.widgets
    for(let i=0; i<w.length; i++){
//       cl(w)
      if(w[i].id===id){return i}
    }
  }
  
  updateWidget=async(w)=>{
//     cl(w.id)
//     cl(w.flags)
    w.flags&=~constant.WIDGET_FLAG_MODIFIED
    let updWidget={cmd: "cRest", uri: "/s/widgets", method: "update", sessionId: globs.userData.session.sessionId,
      body: w};
      wsTrans("usa", updWidget)
  }
  
  deleteWidget=async(id)=>{
      wsTrans("usa", {cmd: "cRest", uri: "/s/widgets", method: "delete", 
        sessionId: globs.userData.session.sessionId,
        body: {id: id}})
  }
  
  updateModifiedWidgets=async()=>{
    this.state.widgets.forEach(w=>{
      if(w.flags&constant.WIDGET_FLAG_MODIFIED){
//         cl(w)
        this.updateWidget(w)
      }
    })
  }
  
  deepCopyOne=(obj)=>{
    let obj2={}
    Object.keys(obj).forEach(k=>{
      obj2[k]=obj[k]
    })
    return obj2
  }
  
  deepCopyFunction = (inObject) => {
  var outObject
  if (typeof inObject !== "object" || inObject === null) { return inObject }


  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {}
  for (let key in inObject) {
    let value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = this.deepCopyFunction(value)
  }
  return outObject
}
  
  doCutWidget=(id)=>{
    let w=this.state.widgets[this.getWidgetInd(id)]
    if(!w.flags){w.flags=0}
    w.flags |= constant.WIDGET_FLAG_CUT
    this.updateWidget(w)
  }
  
  getSelectedWidget=()=>{
    return (this.state.widgets[this.state.contextTarget]).id
  }
  
  copyWidget=()=>{// this.state.widgets is an *array*, not an object!
    let id=this.getSelectedWidget()
//     cl(id)
    let widgets=this.state.widgets.slice(0)
    let w=this.deepCopyFunction(widgets[this.getWidgetInd(id)])
    w.datapointList.forEach(dp=>{ dp.data=[] })// erase any data
//     cl(w.id)
    
//     let w=Object.assign({},widgets[this.getWidgetInd(id)])
    if(!w.flags){w.flags=0}
    w.flags |= constant.WIDGET_FLAG_CUT
    w.id=getRandomString(16)
    w.accountId=globs.userData.session.accountId
//     cl(w.accountId)
    widgets.push(w)
          //cl("setState")
    this.setState({widgets: widgets})
    this.updateWidget(w)
  }
  
  deleteWidgetCmd=()=>{
    cl("delete")
    let id=this.getSelectedWidget()
    let widgets=this.state.widgets.slice(0)
    widgets.splice(this.getWidgetInd(id),1)
    
          //cl("setState")
    this.setState({widgets: widgets})
    this.deleteWidget(id)
  }
  
  setDatapoints=(wdg)=>{
// this needs to consider the zci on the uri, the zci of the dashboard, and the defaults of the widget!
//     cl(wdg)
//     cl(dbVals)
//     if(!wdg.parms.dpid.val) return
      let parms=wdg.parms
//       cl(parms)
      let z=(parms.zone||(parms.zone==0)) ? parms.zone : this.zo//this.dashArray.zone
      let c=(parms.chan||(parms.chan==0)) ? parms.chan : this.ch////this.dashArray.chan
      let ix=(parms.indx||(parms.indx==0)) ? parms.indx : this.in//0//this.dashArray.indx
      if(parms?.dpid?.z){
        if(parms.dpid.z=="ws"){
          z=this.weatherStationZoneIndex
          delete parms.dpid.z
//           cl(this.weatherStationZoneIndex)
        }else{
          z=+parms.dpid.z
        }
      }
      parms.zci=[z, c, ix]
//       cl([z, c, ix])
//       cl([parms.zone, this.zo, z])
//       cl(parms)
      let dp2={}
//       cl(parms)
//       let ids=[]
//       if(parms.dpList){
//         parms.dpList.forEach((dp,i)=>{
//           ids.push({k:i, v:dp.pid})
//         })
//       }
//       for(let key in parms.dpid){
//         if(parms.dpid[key]){ids.push({k:key, v:parms.dpid[key]})}
//       }
//       ids.forEach(id=>{
//         let [z2, c2, i2]=getParmInfo(z, c, ix, id.v)
//         dp2[id.k]=getParmValue(...parms.zci, id.v)
//         let upd={wdg: wdg, id: id.v, isRender: false, f: this.updateValue}
//         addDataHook(upd, z2, c2, i2)// temporary testing location!!!
//       })
      for(let key in parms.dpid){// dpid is used at least by generic and equipment widgets
//       cl(parms)
//       if(parms?.dpid?.val){
//         cl(key)
//         cl(parms.dpid)
        let id=parms.dpid[key]
//         cl(id)
        var z2, c2, i2
        if(id){
//           cl(id)
          if(typeof id=="object"){// I don't think this is used anymore
            [z2, c2, i2]=getParmInfo(z, c, ix, id.pid)
            dp2[key]=getParmValue(...parms.zci, id.pid)
          }else{
            
            [z2, c2, i2]=getParmInfo(z, c, ix, id)
            dp2[key]=getParmValue(...parms.zci, id)
          }
          
//         cl([...parms.zci, id.v])
          let upd={wdg: wdg, id: id, isRender: false, f: this.updateValue}
//           cl(upd)
          addDataHook(upd, z2, c2, i2)// temporary testing location!!!
          this.hooks.push([upd,z2,c2,i2])
//           cl([z2,c2,i2])
        }
      }
//       cl(dp2)
      if(parms.dpUpdates){
      }
      parms.dataPoints=dp2
//       cl(dp2)
  }
  
  updateValue=(upd)=>{// fields: wdg, id, isRender, zci
//     cl(upd)
    //     if(upd.wdg.parms.chan == 7){
    //       cl(upd.wdg.parms)
    //     }
    //     cl(upd.wdg)
    let wdg=upd.wdg
    var paramId
    if(upd.id&&(typeof upd.id=="object")){
      paramId=upd.id.pid
    }else{
      paramId=upd.id
    }
    let isRender=upd.isRender
    //     cl(paramId)
    //     cl([wdg, paramId, isRender])
    //     return
    // this is called when a value changes
//     let ids=[]
//     let parms=wdg.parms
//     if(parms.dpList){
//       parms.dpList.forEach((dp,i)=>{
//         ids.push({k:i, v:dp.pid})
//       })
//     }
//     for(let key in parms.dpid){
//       if(parms.dpid[key]){ids.push({k:key, v:parms.dpid[key]})}
//     }
//       ids.forEach(id=>{
//         cl(id.v)
//         if(paramId==id.v){
//           let val=getParmValue(...wdg.parms.zci, id.v)// updates our value
//           cl(val)
//           wdg.parms.dataPoints[ids.k]=val
//           if(wdg.update)wdg.update(wdg, paramId, isRender)// calls the widget to update
//             return
//         }
//       })

    for(let key in wdg.parms.dpid){
//       cl(key)
      let id=wdg.parms.dpid[key]
//       cl(id)
//       cl(paramId)
      if(id&&(typeof id=="object")){// for now, assuming this is timeseries array
        if(paramId==id.pid){
          let val=getParmValue(...wdg.parms.zci, id.pid)
          let t=Math.floor(getTime())
          id.data.shift()
          id.data.push({t: t, d: val})
          if(wdg.update)wdg.update(wdg, paramId, isRender)// calls the widget to update
//           cl(id.data)
        }
      }else{
        if(paramId==id){
          //         cl(paramId)
          wdg.parms.dataPoints[key]=getParmValue(...wdg.parms.zci, id)// updates our value
  //         cl(`update: ${paramId} to ${wdg.parms.dataPoints[key]}`)
  //         cl(wdg.update)
          if(wdg.update)wdg.update(wdg, paramId, isRender)// calls the widget to update
            //         cl(wdg.parms.dataPoints[key])
            return
        }
      }
    }
  }
  
  fake=  {ix: 4, iy: 2, nx: 2, ny: 2, id: "generic", color: "#C0FFFF",
    parms:{
      zone: 0,
      chan: 0,
      indx: 0,
      dpid:{
        val: 203,
      },
      //       dpUpdates: [203]
      title: "EC",
      bgColor: "#80FFFF",
      dec: 1,// decimal digits to show
      mult: 10,
      note: "Tank 1, Sensor 1",
      min: 0,// these can be taken from the colors array
      max: 2000,
      ticks: [0, 500, 1000, 1500, 2000],
      colors:[
      [0, "#FFFFFF"],
      [200, "#FFFFCC"],
      [400, "#FFFF88"],
      [600, "#FFFF44"],
      [800, "#FFFF00"],
      [1000, "#EEEE00"],
      [1200, "#CCCC00"],
      [1400, "#AAAA00"],
      [1600, "#888800"],
      [1800, "#606000"],
      [2000, "404000"],
      ],
    },
  }
  
  colorsToArray=(colors)=>{
    let ret=[]
    colors.forEach(c=>{
      ret.push([c.val, c.color])
    })
    return ret
  }
  
  labelsToArray=(labels)=>{
    let ret=[]
    labels.forEach(l=>{
      ret.push(l.val)
    })
    return ret
  }
  
  colorsToMinMax=(colors)=>{
    let min=1000000
    let max=-1000000
    colors.forEach(c=>{
      if(min>+c.val){min=+c.val}
      if(max<+c.val){max=+c.val}
    })
    return [min,max]
  }
  
  makeDpid=(dpList)=>{
//     cl(dpList)
    let dpid={}
    dpList.forEach((d,i)=>{
      dpid[i]=d
    })
//     cl(dpid)
    return dpid
  }
  
  makeWidget=(wdg, type)=>{
//     cl(this.state.dashboard)
    let db=this.state.dashboard
    let minMax=this.colorsToMinMax(wdg.colors)
//     cl(minMax)
//     cl(wdg)
    var ret
//     cl(wdg)
    switch(type){
      case "generic":
        ret={
          color: wdg.parameters.bgColor,
          dashboard: {z: db.z, c: db.c, i: db.ix},
          id: "generic2",
          parms:{
            zone: (wdg.defaults.zone)?+wdg.defaults.zone:this.zo,//0,//wdg.defaults.zone,
            chan: (wdg.defaults.chan)?+wdg.defaults.chan:this.ch,//,+wdg.defaults.chan,
            indx: (wdg.defaults.ind)?+wdg.defaults.ind:this.in,//,0,//wdg.defaults.ind,
//             zone: 0,
//             chan: 0,
//             indx: 0,
//             dpid: wdg.datapoint,
            dpid:{
              val: wdg.datapoint.dp,
              z: wdg.datapoint.zone,
//               c: wdg.datapoint.channel,
//               i: wdg.datapoint.index,
            },
            title: wdg.parameters.title,
            bgColor: wdg.parameters.bgColor,
            dec: wdg.parameters.dec,
            mult: wdg.parameters.mult,
            link: wdg.parameters.link,
            note: wdg.parameters.note,
            min: minMax[0],
            max: minMax[1],
            ticks: this.labelsToArray(wdg.labels),
            colors: this.colorsToArray(wdg.colors),
          },
        }
        break
      case "timeSeries":
//         cl(wdg)
        let dpid=this.makeDpid(wdg.datapointList)
//         cl(wdg)
        ret={
          color: wdg.parameters.bgColor,
          id: "generic2",
          parms:{
            zone: (wdg.defaults.zone)?+wdg.defaults.zone:this.zo,//0,//wdg.defaults.zone,
            chan: (wdg.defaults.chan)?+wdg.defaults.chan:this.ch,//,+wdg.defaults.chan,
            indx: (wdg.defaults.ind)?+wdg.defaults.ind:this.in,//,0,//wdg.defaults.ind,
//             zone: 0,
//             chan: 0,
//             indx: 0,
            dpid: dpid,
            //             dpid: wdg.datapoint,
//             dpid:{
//               val: wdg.datapoint.dp,
//             },
            dpList: wdg.datapointList,
            link: wdg.parameters.link,
            xTicks: wdg.parameters.xTicks,
            yTicks: wdg.parameters.yTicks,
            graphType: wdg.parameters.graphType,
            showCurrentValue: wdg.parameters.showCurrentValue,
            currentValueColor: wdg.parameters.currentValueColor,
            
            title: wdg.parameters.title,
            bgColor: wdg.parameters.bgColor,
            flags: wdg.period.flags,
            beginTime: wdg.period.beginTime,
            endTime: wdg.period.endTime,
            duration: wdg.period.duration,
            period: wdg.period.period,
            periodType: wdg.period.periodType,
            barCount: wdg.period.barCount,
            showValueNames: wdg.period.showValueNames,
            showValues: wdg.period.showValues,
//             dec: wdg.parameters.dec,
//             mult: wdg.parameters.mult,
            link: wdg.parameters.link,
            note: wdg.parameters.note,
//             min: minMax[0],
//             max: minMax[1],
//             ticks: this.labelsToArray(wdg.labels),
//             colors: this.colorsToArray(wdg.colors),
          },
        }
//         cl(ret)
//         cl(wdg)
        break
      case "equipment":
//         cl(wdg.defaults.chan)
//         cl((wdg.defaults.chan)?+wdg.defaults.chan:this.ch)
        ret={
//           color: wdg.parameters.bgColor,
//           id: "generic2",
          parms:{
            defaults: wdg.defaults,
            zone: (wdg.defaults.zone)?+wdg.defaults.zone:this.zo,//0,//wdg.defaults.zone,
            chan: (wdg.defaults.chan)?+wdg.defaults.chan:this.ch,//,+wdg.defaults.chan,
            indx: (wdg.defaults.ind)?+wdg.defaults.ind:this.in,//,0,//wdg.defaults.ind,
            offColor: wdg.parameters.offColor,
            onColor: wdg.parameters.onColor,
            //             dpid: wdg.datapoint,
              dpid:{
                Equipment_Name: 507,
                Equipment_Type: 508,
                Equipment_Snapshot_Position: 104,
              },
              //             dpid:{
//               val: wdg.datapoint.dp,
//             },
//             title: wdg.parameters.title,
//             bgColor: wdg.parameters.bgColor,
//             duration: wdg.period.duration,
//             period: wdg.period.period,
            //             dec: wdg.parameters.dec,
            //             mult: wdg.parameters.mult,
            link: wdg.parameters.link,
//             note: wdg.parameters.note,
            //             min: minMax[0],
            //             max: minMax[1],
            //             ticks: this.labelsToArray(wdg.labels),
            //             colors: this.colorsToArray(wdg.colors),
          },
        }
//         cl(ret)
        break
      case "inTemp":
        ret={
          color: wdg.parameters.bgColor,
          id: "generic2",
          parms:{
            zone: (wdg.defaults.zone)?+wdg.defaults.zone:this.zo,//0,//wdg.defaults.zone,
            chan: (wdg.defaults.chan)?+wdg.defaults.chan:this.ch,//,+wdg.defaults.chan,
            indx: (wdg.defaults.ind)?+wdg.defaults.ind:this.in,//,0,//wdg.defaults.ind,
            title: wdg.parameters.title,
            bgColor: wdg.parameters.bgColor,
            link: wdg.parameters.link,
            dpid:{
              Heat_Setpoint: 8,
              Cool_Setpoint: 9,
              Temperature_Units: 4739,
              High_Alarm_Above_Cool_Setpoint: 4751,
              Low_Alarm_Below_Heat_Setpoint: 4755,
              Cool_Stages: 4809,
              Heat_Stages: 4811,
              Stage_Width: 4813,
              Temp_Stage: 6,
              In_Temp: 23,
            },
          },
        }
        break
      case "link":
//         cl("link")
        ret={
          parms:{
            title: wdg.parameters.title,
            bgColor: wdg.parameters.bgColor,
            zone: (wdg.defaults.zone)?+wdg.defaults.zone:this.zo,//0,//wdg.defaults.zone,
            chan: (wdg.defaults.chan)?+wdg.defaults.chan:this.ch,//,+wdg.defaults.chan,
            indx: (wdg.defaults.ind)?+wdg.defaults.ind:this.in,//,0,//wdg.defaults.ind,
            defaults: wdg.defaults,
              dpid:{
                Equipment_Name: 507,
                Equipment_Type: 508,
                Equipment_Snapshot_Position: 104,
              },
            link: wdg.parameters.link,
          },
        }
        break
      case "site":
//         cl(wdg)
        ret={
          parms:{
            title: wdg.parameters.title,
            backImage: wdg.parameters.backImage,
            imgName: wdg.parameters.imgName,
            bgColor: wdg.parameters.bgColor,
//             defaults: wdg.defaults,
//             dpid:{},
            link: wdg.parameters.link,
            site: wdg.parameters.site,
          },
        }
        break
      case "zone":
//         cl(wdg)
//         cl(this.zo)
        ret={
          parms:{
            title: wdg.parameters.title,
            bgColor: wdg.parameters.bgColor,
            tmpFlag: wdg.parameters.tmpFlag,
            humFlag: wdg.parameters.humFlag,
            co2Flag: wdg.parameters.co2Flag,
            litFlag: wdg.parameters.litFlag,
            ecFlag: wdg.parameters.ecFlag,
            phFlag: wdg.parameters.phFlag,
            almFlag: wdg.parameters.almFlag,
            transparent: wdg.parameters.transparent,
            graphBar: wdg.parameters.graphBar,
            link: wdg.parameters.link,
            zone: (wdg.defaults.zone)?+wdg.defaults.zone:this.zo,//0,//wdg.defaults.zone,
//             zone: wdg.parameters.zone,
          },
        }
        break
      case "feed":
//         cl([wdg.defaults.zone, this.zo])
        ret={
          parms:{
            title: wdg.parameters.title,
            bgColor: wdg.parameters.bgColor,
            inc: wdg.parameters.inc,
            link: wdg.parameters.link,
            zone: (wdg.defaults.zone)?+wdg.defaults.zone:this.zo,//0,//wdg.defaults.zone,
          },
        }
//         cl(ret)
        break
      default:
        ret=this.newWidgetMake(type, wdg)
//         cl(ret)
        break
    }
//     ret=this.fake
    
    if(ret.parms){this.setDatapoints(ret)}
//     cl(ret)
    return ret
  }
  
  popupPushPop=e=>{
    let pops = this.state.popUps.slice(0);
    if (e !== undefined){
      pops.push(e);
    } else {
      pops.pop();
    }
          //cl("setState")
    this.setState({popUps: pops})
  }
  
  addPop = e=>{
    this.popupPushPop(e);
  }
  
  delPop = e=>{
    this.popupPushPop();
  }
  
  addCutWidgets=(base)=>{
//     cl(this.state.widgets)
    return(
      this.state.widgets.map((wdg0,i)=>{
//         if(wdg0.id=="BcSL6dUsVpUjZVG5"){cl("got cut")}
        if(wdg0.flags&constant.WIDGET_FLAG_CUT){
//           cl(wdg0)
          return(
            <MenuItem key={base+i} id={`wdg${wdg0.id}`}>{`Paste ${wdg0.parameters.title}`}</MenuItem>
          )
        }
      })
    )
  }
  
  showMenu=()=>{
    return(
      <Menu 
        open={this.state.contextOpen}
        onClose={this.closeContextMenu}
        anchorEl={this.state.menuAnchor}
        onClick={this.closeContextMenu}
      >
        <MenuItem key="1" id="editMode">
        {this.state.edit && <Check/>}{/*only in edit mode*/}
        Edit Mode</MenuItem>
        {(!this.state.edit)&&/*only in edit mode*/
            (this.state.contextTarget>=0)&&[/*if a widget is selected*/
                <MenuItem key="3" id="editWidget">Edit Widget</MenuItem>,
            ]
        }
        {this.state.edit&&/*only in edit mode*/
          [
            <MenuItem key="2" id="cancelEdit">Cancel Edit</MenuItem>,
            <MenuItem key="3" id="editWidget">Edit Widget</MenuItem>,
            (this.state.contextTarget>=0)&&[/*if a widget is selected*/
              [
                <MenuItem key="4" id="cutWidget">Cut</MenuItem>,
                <MenuItem key="6" id="copyWidget">Copy</MenuItem>,
                <MenuItem key="7" id="deleteWidget">Delete</MenuItem>,
              ]
            ],
            this.state.edit&&(this.state.contextTarget<0)&&/*if a widget is *not* selected*/
              this.addCutWidgets(5)
          ]
        }
      </Menu>
    )
  }
  
  showContextMenu=(parms)=>{
//     cl(parms)
    let upd={contextOpen: true, menuAnchor: parms.e.currentTarget, contextX: parms.cx, 
      contextY: parms.cy, contextTarget: parms.i}
//     if(parms.i>=0){
//       upd.contextTarget=parms.i
//     }
          //cl("setState")
    this.setState(upd)
//     this.setState({contextTarget: parms.i, contextX: parms.cx, contextY: parms.cy})
//     this.contextOpen=true
//     this.menuAnchor=parms.e.currentTarget
//     cl(parms)
    
  }
  
  editWidget=()=>{
//     cl(this.state)
    var widgetId=""
    if(this.state.widgets.length){
      let ind=(this.state.contextTarget>=0) ? this.state.contextTarget : 0
      widgetId=this.state.widgets[ind].id
    }
    let msg={id: "editWidget", widgetId: widgetId, dashboardId: this.state.dashboard.i, dashboardAcct: this.state.dashboard.a}
    this.props.onMsg(msg)
  }
  
  cutWidget=()=>{
//     cl(this.state.widgets.length)
//     cl(this.state.contextTarget)
//     cl(this.state.widgets[+this.state.contextTarget])
    let widgetId=(this.state.widgets[this.state.contextTarget]).id
    this.doCutWidget(widgetId)
  }
  
  pasteWidget=(id)=>{
//     cl(this.dashDiv.current)
//     cl(this.dashDiv.current.offsetTop)
    let ox=this.dashDiv.current.offsetLeft
    let oy=this.dashDiv.current.offsetTop
    let w0=this.state.widgets.slice(0)
    let ind=this.getWidgetInd(id)
    let w=w0[ind]
    let size=this.state.dashboard.s
    let wi=size*w.position.width
    let he=size*w.position.height
//     cl(w)
//     cl(he)
//     cl(this.state.contextY)
    
    let x=Math.round((this.state.contextX-ox-wi/2)/size)
    let y=Math.round((this.state.contextY-oy-he/2)/size)
    w.position.left=x
    w.position.top=y
    w.dashboard=this.state.dashboard.i
    w.flags&=~constant.WIDGET_FLAG_CUT
    w.flags|=constant.WIDGET_FLAG_MODIFIED
          //cl("setState")
    this.setState({widgets: w0})
  }
  
  closeContextMenu=async(e)=>{
    let id=e.target.id
    let newState={}
    if(id.substr(0,3)=="wdg"){
      this.pasteWidget(id.substr(3))
    }else{
      switch(e.target.id){
        case "editMode":
          if(this.state.edit){
            this.saveDashboard()
          }else{
            newState.widgets=await this.loadAccountWidgets()// also saves backWidgets, and does setState
//             this.backWidgets=this.deepCopy(this.state.widgets)
          }
//           //cl("setState")
          newState.edit=!this.state.edit
//           this.setState({edit: !this.state.edit})
          break
        case "cancelEdit":
//           //cl("setState")
          newState.edit=!this.state.edit
          newState.widgets=this.backWidgets
//           this.setState({edit: !this.state.edit, widgets: this.backWidgets})
          cl(this.backWidgets)
          break
        case "editWidget":
          this.editWidget()
          break
        case "cutWidget":
          this.cutWidget()
          break
        case "copyWidget":
          this.copyWidget()
          break
        case "deleteWidget":
          this.deleteWidgetCmd()
          break
        default:
          break
      }
    }
          //cl("setState")
    newState.contextOpen=false
    newState.menuAnchor=null
    this.setState(newState)
//     this.setState({contextOpen: false, menuAnchor: null})
  }
  
  notify=(note)=>{// used here to notify widgets of a change in drag position
    if(note.unMount){// called when widget is unmounted
      this.notifies[note.id].forEach((n,i)=>{
        if(n==note.func){
          this.notifies[note.id].splice(i,1)
        }
      })
    }else{
      if(note.id in this.notifies){}else{
        this.notifies[note.id]=[]
      }
      this.notifies[note.id].push(note.func)
    }
  }
  
  setMyState=(o)=>{
          //cl("setState")
    this.setState(o)// o is an object to set state with
    for(let k in o){
      if(k in this.notifies){
        this.notifies[k].forEach(f=>{
          f(o)
        })
      }
    }
  }
  
  cm=(ev)=>{
//     cl(globs.userData.session)
    if(!globs?.userData?.session?.privs?.dashboard ||(globs.userData.session.privs.dashboard!="rw")) return
//     if(globs.userData.session.privs.dashboard!="rw") return
    let parms={cx: ev.e.clientX, cy: ev.e.clientY, i: ev.i, e: ev.e}
//     cl(parms)
    ev.e.preventDefault()
    ev.e.stopPropagation()
    this.showContextMenu(parms)
  }
  
  md=(ev)=>{// drag
//     cl(ev)
//     cl(ev.e.button)
//     cl("down");
//     cl(ev.e.clientX)
    if(this.state.edit && ev.e.button==0){// left button
      ev.e.preventDefault()
      let upd={dragStart: {i: ev.i, x: ev.e.clientX, y: ev.e.clientY}, drag: {x: ev.e.clientX, y: ev.e.clientY}}
      this.setMyState(upd)
    }
  }
  
  mouseMove=(e)=>{
    if(this.state.dragStart){
      let upd={drag: {x: e.clientX, y: e.clientY}}
      this.setMyState(upd)
    }
  }
  
  mouseUp=(e)=>{
    if(this.state.dragStart){
      let drag={x: e.clientX, y: e.clientY}
      let i=this.state.dragStart.i
      let dx=drag.x-this.state.dragStart.x
      let dy=drag.y-this.state.dragStart.y
      let size=this.state.dashboard.s
      let dx2=Math.round(dx/size)
      let dy2=Math.round(dy/size)
      let widgets=this.state.widgets.slice(0)
      let x2=widgets[i].position.left
//       widgets[i].position.left+=+dx2
      widgets[i].position.left=+widgets[i].position.left+dx2
      widgets[i].position.top=+widgets[i].position.top+dy2
      widgets[i].flags|=constant.WIDGET_FLAG_MODIFIED
//       cl(widgets[i].flags)
      //     cl(widgets[i].position.left)
      this.setMyState({dragStart: null, drag: null, widgets: widgets})
    }
  }
  
  showGeneric=(x, y, w, h, wdg, i)=>{
//     return <div key={i}>generic</div>
    let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i, e: this.state.edit, cm: this.cm, md: this.md};
    return(
      <GenericWidget key={i} parms={parms} notify={this.notify}/>
    );
  }
  
  showInTemp=(x, y, w, h, wdg, i)=>{
    //     cl("intemp");
    let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i, e: this.state.edit, cm: this.cm, md: this.md};
    return(
      <InTemp key={i} parms={parms} notify={this.notify}/>
    );
  }
  
//   showEQ00=(x, y, w, h, wdg, i)=>{
//     let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i, e: this.state.edit, cm: this.cm, md: this.md};
//     return(
//       <EQ00 key={i} parms={parms}/>
//     );
//   }
//   

  showTimeSeries=(x, y, w, h, wdg, i)=>{
//     cl(wdg)
    let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i, e: this.state.edit, cm: this.cm, md: this.md};
    return(
      <TimeSeries00 key={i} parms={parms} notify={this.notify}/>
    );
  }
  
  showEquipment=(x, y, w, h, wdg, i)=>{
//     cl("equip")
    let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i, e: this.state.edit, cm: this.cm, md: this.md};
    return(
      <EQ00 key={i} parms={parms} notify={this.notify}/>
    );
  }
  
  showLink=(x, y, w, h, wdg, i)=>{
    let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i, e: this.state.edit, cm: this.cm, md: this.md};
    return(
      <Link00 key={i} parms={parms} notify={this.notify}/>
    );
  }
  
  showSite=(x, y, w, h, wdg, i)=>{
    let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i, e: this.state.edit, cm: this.cm, md: this.md};
    return(
      <Site00 key={i} parms={parms} notify={this.notify}/>
    );
  }

  showZone=(x, y, w, h, wdg, i)=>{
    let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i, e: this.state.edit, cm: this.cm, md: this.md};
    return(
      <Zone00 key={i} parms={parms} notify={this.notify}/>
    );
  }

/******************** Begin of code to support images ***************************/
/******************** Drag / Drop Code ***************************/

  mouseDownImage=(ev,i)=>{// this has to distinguish between images, and widgets!
    let ii=this.state.imageDisplay[i].imageInfo
    let upd={dragStart: {i: i, x: ev.clientX, y: ev.clientY,
      x0: ii.x, y0: ii.y}, drag: {x: ev.clientX, y: ev.clientY}}
          //cl("setState")
    this.setState(upd)
  }

  mouseMoveImage=(ev)=>{
    if(this.state.dragStart){
      let upd={drag: {x: ev.clientX, y: ev.clientY}}
      let i=this.state.dragStart.i
      let ii=this.state.imageDisplay[i].imageInfo
      let drs=this.state.dragStart
      let drg=this.state.drag
      ii.x=drg.x-drs.x+drs.x0
      ii.y=drg.y-drs.y+drs.y0
          //cl("setState")
      this.setState(upd)
    }
  }
  
  dragStartImage=(ev)=>{
    ev.preventDefault()
  }
  
/******************** End Drag / Drop Code ***************************/
  result=(type)=>{
    let parts=type.split("-")
    let id=parts[0].substr(2)
//     cl(id)
    switch(parts[1]){
      case "Cancel":
        let images=this.state.imageDisplay.slice(0)
        images.splice(id, 1)
          //cl("setState")
        this.setState({imageDisplay: images})
        break
      default:
        cl(type)
        break
    }
  }
  
  showImage=async(vals)=>{
//     cl(vals)
    let path=vals.path
    let im=await this.loadImage(path)
//     cl(im.width)
//     cl(path)
    let s=vals.section
    s.w=im.width
    s.h=im.height
    let images=this.state.imageDisplay.slice(0)
    images.push({path: path, imageInfo: s})
          //cl("setState")
    this.setState({imageDisplay: images})
  }
  
  onChange=(type, vals)=>{
//     cl(type)
//     cl(vals)
    switch(type){
      case "feed":
        switch(vals.type){
          case "cmd":
          //cl("setState")
            this.setState(vals)
            break
          case "image":
            return this.showImage(vals)
            break
          default:
            break
        }
      case "gjCreate":// this is the return from creating a gj entry
          //cl("setState")
        this.setState(vals)
        break
      case "messageCreate":
          //cl("setState")
        this.setState(vals)
        break
      case "todoCreate":
          //cl("setState")
        this.setState(vals)
        break
    }
  }
  
  showFeed=(x, y, w, h, wdg, i)=>{
//     cl(this.props)
    let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i, e: this.state.edit, cm: this.cm, 
      md: this.md, zone: this.zone};
    return(
      <Feed00 key={i} parms={parms} notify={this.notify}
      onChange={v=>this.onChange("feed",v)}/>
    );
  }
  
  loadImage=(path)=>{
    return new Promise((r,e)=>{
      let img=new Image()
      img.src=`${constant.expressUrl}/usa/images/uploads/${path}`
      img.onload=(l=>{r(img)})
    })
    
  }
  getImage=(path)=>{
    return new Image().src=`${constant.expressUrl}/usa/images/uploads/${path}`
//     return <img src={`${constant.expressUrl}/usa/images/uploads/${path}`}/>
  }
  
  imageDisplayRender=()=>{
      return this.state.imageDisplay.map((idsp, i)=>{
//         cl(idsp)
        let ii=idsp.imageInfo
        let path=idsp.path
//         let im=await this.loadImage(path)
//         cl(im)
//         let im=this.getImage(path)
//         cl(im)
        let w=ii.w
        let h=ii.h
        if(w>500){
          h=h*500/w
          w=500
        }
        return(
          <div key={i} style={{position: "absolute", left: ii.x, top: ii.y, width: w, height: h, backgroundColor: "red", zIndex:2}}>
            <img width={w} src={`${constant.expressUrl}/usa/images/uploads/${path}`}
            style={{backgroundColor: "#C0FFC0", borderRadius: 10, 
              boxShadow: "5px 10px 10px #888888"}}
            onMouseDown={e=>this.mouseDownImage(e,i)} onMouseMove={this.mouseMoveImage} onMouseUp={this.mouseUp} 
            onDragStart={this.dragStartImage} onDragEnd={this.dragEnd}/>
            <div style={{width: 48, height: 48, 
              position: "absolute", right: 0, top: 0}}>
              <UsaIcon icon={`im${i}-Cancel`} result={this.result}/>
            </div>
            
          </div>
        )
      })
  }
/******************** End of code to support images ***************************/

//   readWidgetData=(wdg)=>{
// //     cl(this.state.widgets)
// //     this.state.widgets.map(wdg=>{
// //       if(wdg.parms){this.setDatapoints(wdg)}
// //     })
//   }

  pwNotProp=(dashboardId, wid)=>{
/*to position the widgets, go through each dashboard's set of widgets, and position them
when you come to a dashboardWidget, position the widget first, *then* position its own widgets
*/
//     cl(dashboardId)
//     cl(this.dbWidgets)
//     cl(this.dbDashboards[dashboardId])
//     let wid=globs.userData.winSize[0]
    let db=this.dbDashboards[dashboardId]
    let s=+db.s
    let wid0=db.s*db.w
    let hgt0=db.s*db.h
    let x=0, y=0, y1=0
//     cl("wid")
//     cl([wid0,hgt0,wid])
//     cl("2"*3)
    
    this.state.widgets.forEach(w=>{
      if(w.dashboard==dashboardId){// only those on this dashboard
//         cl(w)
//         cl([w.position.left, w.position.top, +w.position.width, +w.position.height, ])
// this is the hard part, determining where the next widget will go
        let h1=s*w.position.height
        let w1=s*w.position.width
//         cl([w1,h1])
        if(x+w1>wid){// break the line
          x=0
          y=y1// y1 is the position of this line
          y1=y+h1
        }else{// add to line
          if(y+h1>y1){y1=y+h1}// update line position vertical
        }
//         cl([x,y, y1])
        w.position.x=x
        w.position.y=y
        if(w.parent){
          w.position.x+=w.parent.position.x
          w.position.y+=w.parent.position.y
        }
        x+=w1
//         cl(w.parent)
        if(w.type=="dashboard"){
          this.pwNotProp(w.parameters.dashboard,w1)
        }
//         cl(w)
      }
    })
    db.h=y1/db.s
    db.w=wid/db.s
//     cl([y1, db.h])
  }
  
  pwNotDynamic=(dashboardId)=>{
    cl("not dynamic")
    let db=this.dbDashboards[dashboardId]
    let s=+db.s
    let wid0=db.s*db.w
    let hgt0=db.s*db.h
    this.state.widgets.forEach(w=>{
      if(w.dashboard==dashboardId){// only those on this dashboard
//         cl(w.parent)
        w.position.x=s*w.position.left
        w.position.y=s*w.position.top
        if(w.parent){
          w.position.x+=w.parent.position.x
          w.position.y+=w.parent.position.y
        }
//         cl([w.position.x,w.position.y])
        if(w.type=="dashboard"){
          let w1=0
          this.pwNotDynamic(w.parameters.dashboard,w1)
        }
      }
    })
  }

  positionWidgets=()=>{
/* the screen width is in globs.userData*/
    if(this.state.dashboard.d){// dynamic
      if(this.state.dashboard.p){}else{// not proportional, fixed
        this.pwNotProp(this.state.dashboard.i, globs.userData.winSize[0])
      }
    }else{
      this.pwNotDynamic(this.state.dashboard.i)
    }
  }
  
  showWidgets=()=>{
//     cl("show")
    this.positionWidgets()// this needs to be done sooner
    let size=this.state.dashboard.s;
    this.removeHooks()
//     cl(this.state.widgets)
    return(
      <div>
      {this.state.widgets.map((wdg0,i)=>{
//         cl(wdg0)
//         cl(i)
//         if((!(wdg0.flags&constant.WIDGET_FLAG_CUT)) &&(wdg0.dashboard==this.state.dashboard.i)){
        if((!(wdg0.flags&constant.WIDGET_FLAG_CUT)) &&(this.dashboards.includes(wdg0.dashboard))){
// //           cl(wdg0)
//           cl(i)
          let p=wdg0.position
          wdg0.x=p.x
          wdg0.y=p.y
//           wdg0.x=size*p.left
//           wdg0.y=size*p.top
//           if(wdg0.parent){// handles dashboardWidgets
//             wdg0.x+=wdg0.parent.x
//             wdg0.x+=wdg0.parent.y
//           }
          
          let w=size*p.width
          let h=size*p.height
          let wdg=this.makeWidget(wdg0, wdg0.type)
//           cl(wdg.parms.chan)
//           cl(wdg)
          switch(wdg0.type){
            case "inTemp":
              return this.showInTemp(wdg0.x, wdg0.y, w, h, wdg, i)
            case "generic":
//               cl("show generic")
              return this.showGeneric(wdg0.x, wdg0.y, w, h, wdg, i)
            case "eq":
              return this.showEQ00(wdg0.x, wdg0.y, w, h, wdg, i)
            case "timeSeries":
//               cl(wdg.parms.dpList[0].data)
              return this.showTimeSeries(wdg0.x, wdg0.y, w, h, wdg, i)
            case "equipment":
              cl("new equip")
              let tmp=this.showEquipment(wdg0.x, wdg0.y, w, h, wdg, i)
//               cl(wdg)
              return tmp
            case "link":
//               cl(i)
              return this.showLink(wdg0.x, wdg0.y, w, h, wdg, i)
            case "site":
//               cl(i)
              return this.showSite(wdg0.x, wdg0.y, w, h, wdg, i)
            case "zone":
              cl("new zone")
              return this.showZone(wdg0.x, wdg0.y, w, h, wdg, i)
            case "feed":
              return this.showFeed(wdg0.x, wdg0.y, w, h, wdg, i)
            default:
              cl(i)
              return this.newWidgetShow(wdg0.type, wdg0.x, wdg0.y, w, h, wdg, i)
          }
        }
      })}
      </div>
    )
  }
  
  getAccountDashboards=async()=>{
    cl(globs.userData.session)
    let r = await wsTrans("usa", {cmd: "cRest", uri: "/s/dashboards", method: "retrieve2", sessionId: globs.userData.session.sessionId,
      body: {a: globs.userData.session.accountId}})// {u: globs.userData.session.userId}
    cl(r.data)
    globs.events.publish("dashboardUpdated", {dashboard: r.data[0].i})
//     this.setState({dashboard: r.data[0]})
//     return r.data[0].i
  }

  positionFixedWidgets=(widgets,size,w)=>{
    
  }
  
  dynamicDashboard=(db)=>{
    if(!db.s0){db.s0=db.s}
    if(db.p){
      db.s=globs.userData.winSize[0]/db.w
    }else{
      this.positionFixedWidgets()
    }
//     cl(this.state.widgets)
  }
  
  showDashboard=()=>{
//     cl("show")
//     cl(this.props)
//     let db=this.props.page
//     cl(this.state)
//     cl(this.props)
    let db=this.state.dashboard
    if(!db){
      this.getAccountDashboards()
//       db=userSpecificHome().substr(10)
      return <div>Missing Dashboard. . .</div>
    }
    if(this.state.dashboard.i!==this.props.page){// this is used to update the dashboard when it changes on the editor
//       cl(this.props.page)
      this.loadDashboard(this.props.page)
    }
//     if(db.d){this.dynamicDashboard(db)}
    db=this.dbDashboards[db.i]
    this.positionWidgets()
//     cl(db.h)
    let width=db.s*db.w
    let height=db.s*db.h
//     cl(this.state)
    let backG=this.state.dashboard.col
    let style={width: width, height: height, backgroundColor: backG, position: "relative"}
    if(this.state.dashboard.bi){
      let bg=this.state.dashboard.bg
      let path=`${constant.expressUrl}/usa/images/backgrounds/uploads/${bg[0]}/${bg[1]}/${bg[2]}/${bg.substr(3)}`
      style.backgroundImage=`url("${path}")`
    }else{}
//     cl(style)
    return(
      <div style={style}
      onMouseMove={this.mouseMove} onMouseUp={this.mouseUp} onContextMenu={ev=>this.cm({i: -1, e: ev})}
      ref={this.dashDiv}>
      {this.showWidgets()}
      </div>
    )
  }
  
  displayCreateGrowJournal=()=>{
    if(this.state.showCreateGrowJournal){
      return(
        <div style={{position: "absolute", left: 100, top: 100, width: 500, height: 800, backgroundColor: "#BBFFEE", borderRadius: 20, boxShadow: "10px 10px 20px #808080"}}>
        <GrowJournal00
          onChange={v=>{this.onChange("gjCreate",v)}}
          parms={{
            zuci: this.props.zuci,
            zones: this.zones,
            zone: this.zone,
          }}
          />
        </div>
      )
    }else{return null}
  }
  
  displayCreateMessage=()=>{
    if(this.state.showCreateMessage){
      return(
        <div style={{position: "absolute", left: 100, top: 100, width: 500, height: 800, backgroundColor: "#BBFFEE", borderRadius: 20, boxShadow: "10px 10px 20px #808080"}}>
        <CreateMessage00
          onChange={v=>{this.onChange("messageCreate",v)}}
          parms={{
            zuci: this.props.zuci,
            zones: this.zones,
            zone: this.zone,
          }}
          />
        </div>
      )
    }else{return null}
  }
  
  displayCreateToDo=()=>{
    if(this.state.showCreateToDo){
      return(
        <div style={{position: "absolute", left: 100, top: 100, width: 500, height: 800, backgroundColor: "#BBFFEE", borderRadius: 20, boxShadow: "10px 10px 20px #808080"}}>
        <CreateToDo00
          onChange={v=>{this.onChange("todoCreate",v)}}
          parms={{
            zuci: this.props.zuci,
            zones: this.zones,
            zone: this.zone,
          }}
          />
        </div>
      )
    }else{return null}
  }
  
  render(){
//     cl("render")
    if(this.state.loaded){
      if(this.state.badZone){
        return(
          <div>{`Nonexistent Zone: Zone ${this.zo}`}</div>
        )
      }else{
        return(
          <div>
          {this.showDashboard()}
          {this.showMenu()}
          {this.imageDisplayRender()}
          {this.displayCreateGrowJournal()}
          {this.displayCreateMessage()}
          {this.displayCreateToDo()}
          </div>
        )
      }
    }else{
      return <div>loading</div>
    }
  }
}

export default DashDisplay00 ;

