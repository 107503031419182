import React from 'react';
import Alert from '@material-ui/lab/Alert';
import TextInput from './TextInput';
import OkCancel from './OkCancel';
import UsaButton from './UsaButton';
import UsaSelect00 from './UsaSelect00'
import UsaIcon from './UsaIcon';
import {wsTrans} from '../utils/utils';
import {cl, globs, constant} from '../../components/utils/utils';
import {checkLoggedIn} from '../utils/utils';
import history from "../../history"


class ManageUsers extends React.Component{
  constructor(props) {
    super(props);
    document.title="Manage Users";
    this.state={
      showAddUser: false,
      showEditUser: false,
      addUserEmail: "test@b.com",
      alertSeverity: "info",
      alertText: "",
      addUserLink: "",
      loaded: false,
    };
//     this.login()
    this.loadUsers()
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Users", url:`/usa/c18/admin/manageUsers`},
            ]},
        },
      )
    }
  }
  
  loadUsers=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {accountId: globs.userData.session.accountId}})
    cl(res)
    let user=res.data[0].userId
    let userOpts={}
    res.data.forEach(u=>{
      userOpts[u.userId]=({v:u.userId, t:u.name, p:u.privs, e:u.email,})
    })
    cl(userOpts)
    let logged=await checkLoggedIn()
    if(!logged){history.push("/usa/login")}
    this.setState({loaded: true, user: user, userOpts: userOpts})
      
//     this.login()
//     return userOpts
  }
  
  getSites=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {accountId: globs.userData.session.accountId}})
    cl(res)
    let site=res.data[0].siteId
    let siteOpts={}
    res.data.forEach(s=>{
      siteOpts[s.siteId]=({t: s.name})
    })
    cl(siteOpts)
    let logged=await checkLoggedIn()
    if(!logged){history.push("/usa/login")}
    this.setState({loaded: true, site: site, siteOpts: siteOpts})
  }
  
  login=async()=>{
    let logged=await checkLoggedIn()
    if(!logged)history.push("/usa/login")
    this.setState({loaded: true})
  }
  
  validEmail=(email)=>{
    let atPos = email.indexOf("@");
    let perPos = email.lastIndexOf(".");
    return ((atPos > 0) && (perPos > atPos));
  }
  
  checkAddUserEmail=()=>{// just checks if a valid email format
    let msg = (this.validEmail(this.state.addUserEmail)) ? "" : "Not a Valid Email Address";
    this.setState({alertText: msg});
    return msg==="";
  }
  
  setStateValue=(id, value)=>{
    let val={};
    val[id]=value;
    this.setState(val);
    if(id==="addUserEmail"){
      this.checkAddUserEmail();
    }
  }
  
  toggleAddUser=()=>{
    this.setState({showAddUser: !this.state.showAddUser});
//     cl(result);
  }
  
  deleteUser=async()=>{
    let userId=this.state.user
    let users=Object.assign({},this.state.userOpts)
    delete users[userId]
    let user=Object.keys(users)[0]
    this.setState({userOpts: users, user: user})
    await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "delete", 
      sessionId: globs.userData.session.sessionId,
      body: {userId: userId}})
  }
  
  addThisUser=()=>{
    if(this.checkAddUserEmail()){
      cl("add user: " + this.state.addUserEmail);
      let body={email: this.state.addUserEmail};
      let addUser={cmd: "cRest", uri: "/s/manageUsers", sessionId: globs.userData.session.sessionId, 
        method: "create", body: body};
      wsTrans("usa", addUser).then(r=>{
        this.setState({addUserLink: r.data.token, showAddUser: false});
        cl(r);
      });
    }
  }
  
  showAddUserLink=()=>{
    if(this.state.addUserLink){
      let url = constant.feUrl + `/usa/inviteUser/${this.state.addUserLink}`;
      return(
        <div>
        This is the link in the email: 
        <a href={url}>{url}</a>
        </div>
      );
    }
  }
  
  onChange=(type, vals)=>{
//     cl(vals)
//     cl(this.state.userOpts)
    switch(type){
      case "user":
        this.setState(vals)
//         this.setUser(vals.user)
        break
      default:
        break
    }
    this.setState(vals)
  }
  
  
  result=async(type)=>{
//     cl(type)
    switch(type){
      case "User-New":
        this.setState({showAddUser: true, addUserEmail: ""})
        break
      case "User-Edit":
        await this.getSites()
        this.setState({showEditUser: true})
//         cl(this.state.siteOpts)
//         let so=this.state.siteOpts[this.state.site]
// //         let so=this.state.siteOpts[this.getSiteIndex(this.state.site)]
// //         let name=this.state.siteOpts[this.getSiteIndex(this.state.site)].t
//         this.setState({showAdd: true, editName: so.t, editSite: this.state.site, 
//           weatherStation: so.w, zoneOpts: await this.loadZones()})
        break
      case "User-Delete":
        this.deleteUser()
        break
      case "UserAdd-OK":
        this.addThisUser()
        break
      case "UserAdd-Cancel":
        this.setState({showAddUser: false})
        break;
      case "UserEdit-OK":
//         this.addThisUser()
        break
      case "UserEdit-Cancel":
        this.setState({showEditUser: false})
        break;
      default:
        break
    }
  }
  
  showUserAdd=()=>{
    return (
      <div>
        <h2>Manage Users</h2>
        <div>
        <UsaIcon icon="UserAdd-OK" result={this.result} inline/>
        <UsaIcon icon="UserAdd-Cancel" result={this.result} inline/>
        <h3 style={{display: "inline-block"}}>Add a User</h3>
        <TextInput title="Email of New User" valueId="addUserEmail" onChange={this.setStateValue}
          initValue={this.state.addUserEmail}/>
        {(this.state.alertText) &&
          <Alert severity={this.state.alertSeverity}>{this.state.alertText}</Alert>
        }
        {this.showAddUserLink()}
        </div>
      </div>
      
    )
//     return(
//       <div>
//       <h3>Manage Users</h3>
//         <div style={{height: 10}}/>
//         <UsaIcon icon="SiteAdd-OK" result={this.result} inline/>
//         <UsaIcon icon="SiteAdd-Cancel" result={this.result} inline/>
//         <UsaSelect00 parms={{
//           title: "Site\u00A0Name",
//           inline: true,
//           width: 200,
//           valueId: "editName",//valueId, // "widgetSel",
//           value: this.state.editName, //value, // this.state.widgetSel,
//           onChange: (vals)=>this.onChange("editName", vals),
//         }}/>
//         <div style={{height: 10}}/>
//         <UsaSelect00 parms={{
//           title: "Weather\u00A0Station",
//           inline: true,
//           select: true,
//           width: 200,
//           valueId: "weatherStation",//valueId, // "widgetSel",
//           value: this.state.weatherStation, //value, // this.state.widgetSel,
//           opts: this.state.zoneOpts,
//           onChange: (vals)=>this.onChange("weatherStation", vals),
//         }}/>
//       </div>
//     );
    
  }
  
  showUserSelect=()=>{
//     cl(this.state.user)
//     cl(this.state.userOpts)
    return (
      <div>
        <h2>Manage Users</h2>
          <UsaIcon icon="User-New" result={this.result} inline/>
          <UsaIcon icon="User-Edit" result={this.result} inline/>
          <UsaIcon icon="User-Delete" result={this.result} inline/>
          <UsaSelect00 parms={{
            title: "Select\u00A0User",
            inline: true,
            width: 200,
            select: true, // !this.state.editMode,
            valueId: "user",//valueId, // "widgetSel",
            value: this.state.user, //value, // this.state.widgetSel,
            opts: this.state.userOpts,
            onChange: (vals)=>this.onChange("user", vals),
          }}/>
          <div style={{height: 10}}/>
          {this.state.userOpts[this.state.user].e}
          <div style={{height: 10}}/>
        {this.showAddUserLink()}
        
      </div>
      
    )
  }
  
  showUserEdit=()=>{
    return(
      <div>
        <h2>Manage Users</h2>
        <div>
        <UsaIcon icon="UserEdit-OK" result={this.result} inline/>
        <UsaIcon icon="UserEdit-Cancel" result={this.result} inline/>
        <h3 style={{display: "inline-block"}}>Edit User Privileges</h3>
        <div/>
        <UsaSelect00 parms={{
          title: "Select\u00A0Site",
          inline: true,
          width: 200,
          select: true, // !this.state.editMode,
          valueId: "site",//valueId, // "widgetSel",
          value: this.state.site, //value, // this.state.widgetSel,
          opts: this.state.siteOpts,
          onChange: (vals)=>this.onChange("site", vals),
        }}/>
        
        </div>
      </div>
    )
  }
  
  
  render(){
    if(this.state.loaded){
      if(this.state.showAddUser){
        return this.showUserAdd()
      }else{
        if(this.state.showEditUser){
          return this.showUserEdit()
        }else{
          return this.showUserSelect()
        }
      }
    }else{return <div>loading...</div>}
  }
}

export default ManageUsers ;
 
 
