import React from 'react';
import Position00 from '../../usa/components/Position00';
import Defaults00 from '../../usa/components/Defaults00';
import Parameters00 from '../../usa/components/Parameters00';
import UsaIcon from '../../usa/components/UsaIcon';
import UsaSelect00 from '../../usa/components/UsaSelect00';
import UsaColorPicker00 from '../../usa/components/UsaColorPicker00';
import {dbVals,getDBVal} from '../../components/utils/http'
import {cl, globs,getTime} from '../../components/utils/utils';
import {pInd,pi} from '../../components/utils/paramIds'
import {colorIsDark} from '../utils/utils';
import {wsTrans} from '../../usa/utils/utils'
import Info from '../../images/info.png'
import history from '../../history'
import Switch from '@material-ui/core/Switch';
import Apps from '@material-ui/icons/Apps';
import Menu from '@material-ui/icons/Menu';
import Settings from '@material-ui/icons/Settings';


class ZoneListWidget00 extends React.Component{
/* this gets the size in parms w, h. assume a 5px padding*/  
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      name: "none",
      loaded: false,
      hide: false,
      grid: true,
      list: false,
    }
    this.loadZones()
  }
  
  loadZones=async()=>{
    this.zones=[]
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId: globs.userData.session.siteId}})// get all for account
    this.zones=r.data
    this.setState({loaded: true})
//     cl(this.zones)
  }
  
  componentWillUnmount=()=>{
    this.props.notify({id: "drag", func: this.onDrag, unMount: true})
  }
  
/********************* These are the new Widget functions, to make them freestanding**********************/
widgetType={v: "zoneList", t: "Zone List"}

editType=(that)=>{
  let pos=Object.assign({}, that.state.position)
//   let def=Object.assign({}, that.state.defaults)
//     <Defaults00 parms={{vals: def}} onChange={(vals)=>{that.onChange("defaults", vals)}}/>
  let parm=Object.assign({}, that.state.parameters)
  return(
    <div>
    <Position00 parms={{vals: pos}} onChange={(vals)=>{that.onChange("position", vals)}}/>
    <Parameters00 parms={{
      vals: parm,
      wdg: this,
    }} type="equipList" onChange={(vals)=>{that.onChange("parameters", vals)}}/>
    </div>
  )
}

editParameters=(that)=>{
//   cl(that.state.title)
  return(
    <div>
    <UsaIcon icon="parameters-OK" result={that.result} inline/>
    <UsaIcon icon="parameters-Cancel" result={that.result} inline/>
    <span><h3 style={{display: "inline-block"}}>Parameters:</h3></span>
    <UsaColorPicker00 parms={{
      title: "Background\u00A0Color",
      width: 100,
      inline: true,
      value: that.state.bgColor,
      valueId: "bgColor",
      onChange: o=>that.setState(o),
    }}/>
    </div>
  )
}

parameterChange=(v)=>{
  cl(v)
  return{bgColor: v.bgColor, nameType: v.nameType, lightOn: v.lightOn, darkOn: v.darkOn, lightOff: v.lightOff, darkOff: v.darkOff, 
    title: "Zone List"}
}

makeParms=(wdg)=>{
//   cl(wdg)
  return{
    parms:{
      bgColor: wdg.parameters.bgColor,
      lightOn: wdg.parameters.lightOn,
      darkOn: wdg.parameters.darkOn,
      lightOff: wdg.parameters.lightOff,
      darkOff: wdg.parameters.darkOff,
//       nameType: wdg.parameters.nameType,
      title: "Zone List",
    },
  }
}

showWidget=(that, x, y, w, h, wdg, i)=>{
//   cl(i)
//   cl(wdg)
  let parms={x: x, y: y, w: w, h: h, wdg: wdg, i: i, e: that.state.edit, cm: that.cm, md: that.md};
  let Cont=this.constructor
  return(
    <Cont key={i} parms={parms} notify={that.notify}/>
  );
}

/********************* End of new Widget functions **********************/

/******************** Drag, Context Menu, Mouse Down Functions *********************/

onDrag=(o)=>{// o has the updated info
//     cl("od")
//     cl(o)
    if("dragStart" in o){
      this.setState(o)
    }else{
      if(this.state.dragStart.i==this.props.parms.i){
//         cl(o)
        this.setState(o)
      }
    }
  }
  
  cm=(e)=>{
    this.props.parms.cm({e: e, i: this.props.parms.i})
  }
  
  md=(e)=>{
    this.props.parms.md({e: e, i: this.props.parms.i})
  }
  
  onClick=()=>{
    if(this.props.parms.e) {return}// edit mode
    let url=this.props.parms.wdg.parms.link
    history.push(url)
  }
  
/******************** End Drag, Context Menu, Mouse Down Functions *********************/

//   showBubble=(text)=>{
// // on the figma demo, the type is 9px tall, the bubble is 21 x 37px
//     let h=21
//     let fontSize=0.6*h
//     return(
//       <div style={{height: h, borderRadius: h/2, padding: "0px 10px 0px 10px", lineHeight: `${h}px`, fontSize: fontSize, 
//         backgroundColor: "#CCCCFF"}}>{text}</div>
//     )
//   }
//   
//   showEquipmentList=()=>{
// //     cl(this.state)
// //     cl(this.props)
// //     cl(dbVals.z)
//     let z=dbVals.z[this.zone]
//     let equip=[]
//     for(let i=0;i < 192;i++){
//       let ch=z[i]
//       if(ch){
//         let ty=+ch[pInd[1800].channels_configuration[0]+pi[1800].channels_configuration["channelType"]]
//         if(ty){
//           equip.push({
//             ty:ty,
//             name:ch[pInd[1800].channels_configuration[0]+pi[1800].channels_configuration["channelName"]],
//             pos:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["position"]],
//             relay:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["relay"]],
//             ovr:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["channelOverride"]],
//             analog:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["analogOutput"]],
//             auxOutStatus:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["auxOutputStatus"]],
//             auxOutValue:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["auxOutputValue"]],
//             auxEquipStatus:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["auxEquipmentStatus"]],
//             auxEquipValue:ch[pInd[1800].snapshot_channels[0]+pi[1800].snapshot_channels["auxEquipmentValue"]],
//           })
//         }
//       }
//     }
//     cl(equip)
//     let lines=equip.map((e,i)=>{
//       return (
//         <tr key={i}><td>{e.name}</td><td>{this.showBubble("ON")}</td><td><img style={{paddingTop: 4}} src={Info}/></td>
//         </tr>
//       )
//     })
//     return(
//       <table><tbody>
//       {lines}
//       </tbody></table>
//     )
//   }
  getSetpoints=(zoneIndex)=>{
/* How to get the current active setpoint:
sort setpoints into time order
duplicate last setpoint as first 0 minutes
initialize setpoint id to the *last* in time
compare the current time with the list, replacing id when we find a setpoint *before* now, and stopping when we reach the end or find one greater
or compare time with list, calculating time *since* the setpoint, *and* setpoint - 1440 for yesterday. use the minimum



*/
    let zo=dbVals.z[zoneIndex]
    var ch
    let sp={}
    if(zo){ch=zo[255]}
    if(ch){
//       cl(ch)
      let setp=pInd[1800].config_setpoints// base, type, interval, count
      for(let i=0;i<setp[3];i++){
        let base=setp[0]+(i*setp[2])
        let en=+ch[base+pi[1800].config_setpoints["enabled"]]
        let start=ch[base+pi[1800].config_setpoints["startTimeOfDay"]]
        if(en){sp[i]=start}
//         cl([i,en,start])
      }
//       cl(sp)
    }
    return sp
  }

  showZoneList=()=>{
// pi[1800].snapshots["temperatureStage"] =	6
// pi[1800].snapshots["humidityStage"] =	7
// pi[1800].snapshots["inTemperature"] =	23
// pi[1800].snapshots["outTemperature"] =	24
// pi[1800].snapshots["localTemperature"] =	25
// pi[1800].snapshots["backupTemperature"] =	26
// pi[1800].snapshots["inHumidity"] =	27
// pi[1800].snapshots["outHumidity"] =	28
// pi[1800].snapshots["localHumidity"] =	29
// pi[1800].snapshots["differentialPressure"] =	30
// pi[1800].snapshots["co2"] =	31
// pi[1800].snapshots["inLight"] =	32
// pi[1800].snapshots["outLight"] =	33
// pi[1800].snapshots["windSpeed"] =	34
// pi[1800].snapshots["windDirection"] =	35
    let lines=this.zones.map((z,i)=>{
      let zo=dbVals.z[z.siteZoneIndex]
      let ch=zo[240]
      let sp=this.getSetpoints(z.siteZoneIndex)
      let min=1440
      let spId=0
      let now=ch[pInd[1800].snapshots[0]+pi[1800].snapshots["unix_timestamp(unitTime)"]]
      now=(Math.floor(now/60)%1440)
      Object.keys(sp).forEach(k=>{
        if((now-sp[k]<min)||(now+1440-sp[k]<min)){
          min=(now-sp[k]+1440)%1440
          spId=+k
        }
      })
//       cl([spId,min])// spId is the id of the setpoint we're using now
      
//       this.getSetpoints(z.siteZoneIndex)
//       cl(sp)
      let tmpStage=getDBVal(ch[pInd[1800].snapshots[0]+pi[1800].snapshots["temperatureStage"]])||0
      let humStage=getDBVal(ch[pInd[1800].snapshots[0]+pi[1800].snapshots["humidityStage"]])||0
      
      let inTemp=getDBVal(ch[pInd[1800].snapshots[0]+pi[1800].snapshots["inTemperature"]])||0
      let inHum=getDBVal(ch[pInd[1800].snapshots[0]+pi[1800].snapshots["inHumidity"]])||0
      let inCo2=getDBVal(ch[pInd[1800].snapshots[0]+pi[1800].snapshots["co2"]])||0
      let inLight=getDBVal(ch[pInd[1800].snapshots[0]+pi[1800].snapshots["inLight"]])||0
//       cl([inTemp,inHum,inCo2,inLight])
      return(
        <tr key={i}><td>{z.zoneName}</td><td>{inTemp}</td><td>{inHum}</td><td>{inCo2}</td><td>{inLight}</td></tr>
      )
    })
    return(
      <table><tbody>
      {lines}
      </tbody></table>
    )
  }

  displayZones=()=>{
    let elems=this.zones.map((z,i)=>{
      let zo=dbVals.z[z.siteZoneIndex]
      let ch=zo[240]
      let sp=this.getSetpoints(z.siteZoneIndex)
      let min=1440
      let spId=0
      let now=ch[pInd[1800].snapshots[0]+pi[1800].snapshots["unix_timestamp(unitTime)"]]
      now=(Math.floor(now/60)%1440)
      Object.keys(sp).forEach(k=>{
        if((now-sp[k]<min)||(now+1440-sp[k]<min)){
          min=(now-sp[k]+1440)%1440
          spId=+k
        }
      })
//       cl([spId,min])// spId is the id of the setpoint we're using now
      
//       this.getSetpoints(z.siteZoneIndex)
//       cl(sp)
      let tmpStage=getDBVal(ch[pInd[1800].snapshots[0]+pi[1800].snapshots["temperatureStage"]])||0
      let humStage=getDBVal(ch[pInd[1800].snapshots[0]+pi[1800].snapshots["humidityStage"]])||0
      
      let inTemp=getDBVal(ch[pInd[1800].snapshots[0]+pi[1800].snapshots["inTemperature"]])||0
      let inHum=getDBVal(ch[pInd[1800].snapshots[0]+pi[1800].snapshots["inHumidity"]])||0
      let inCo2=getDBVal(ch[pInd[1800].snapshots[0]+pi[1800].snapshots["co2"]])||0
      let inLight=getDBVal(ch[pInd[1800].snapshots[0]+pi[1800].snapshots["inLight"]])||0

      // get setpoint
      cl(sp);

      // get unfinished tasks
//       cl([inTemp,inHum,inCo2,inLight])

      return(
        (this.state.list) ? 
          <tr key={i}>
            <td>{z.zoneName}</td>
            <td>{inTemp}</td>
            <td>{inHum}</td>
            <td>{inCo2}</td>
            <td>{inLight}</td>
            <td>Day</td>
            <td>{tmpStage}</td>
            <td>{humStage}</td>
            <td>0</td>
          </tr>
          : 
          <table key={i}><tbody>
            <tr><td>{z.zoneName}</td></tr>
            <tr><td>In Temp.</td><td>{inTemp}</td></tr>
            <tr><td>In Hum.</td><td>{inHum}</td></tr>
            <tr><td>CO<sub>2</sub></td><td>{inCo2}</td></tr>
            <tr><td>Light</td><td>{inLight}</td></tr>
            <tr><td>Setpoint</td><td>Day</td></tr>
            <tr><td>Temp. Stage</td><td>{tmpStage}</td></tr>
            <tr><td>Hum. Stage</td><td>{humStage}</td></tr>
            <tr><td>Unfinished Tasks</td><td>1</td></tr>
          </tbody></table>

      )
    })
    return(
        <div>
        {(this.state.list) &&
        <table><tbody>
        <tr>
          <td>Name</td>
          <td>In Temp.</td>
          <td>In Hum</td>
          <td>CO<sub>2</sub></td>
          <td>Light</td>
          <td>Setpoint</td>
          <td>Temp Stage</td>
          <td>Hum. Stage</td>
          <td>Unfinished Tasks</td>
        </tr>
        </tbody></table>
        }
        {elems}
      </div>
    )
  }

  showZoneHeader = () => {
    cl("zone header")
    let date = new Date();

    return(
      <table><tbody>
        <tr>
          <td>{date.toUTCString()}</td>
          <td>My Zones</td>
          <td>
            <Switch> onClick={() => { this.setState({hide: !this.state.hide})}}</Switch>
          </td>
          <td>
            <Apps onClick={() => {this.setState({grid: !this.state.grid})}} />
          </td>
          <td>
            <Menu onClick={() => {this.setState({list: !this.state.list})}} />
          </td>
          <td>
            <Settings onClick={() => this.displaySettings}/>
          </td>
        </tr>
      </tbody></table>

    )

  }

  displaySettings = () => {

  }

  render2() {
    if(this.state.loaded){
//     cl(this.props)
//     this.getName()
      let p = this.props.parms;
      let p2=p.wdg.parms
      let dm=5
      let color=p2.bgColor
      let x=p.x+dm
      let y=p.y+dm
      if(this?.state?.dragStart?.i==this.props.parms.i){
        x+=this.state.drag.x-this.state.dragStart.x
        y+=this.state.drag.y-this.state.dragStart.y
      }
      return(
          <div style={{position: "absolute", left: x, top: y, width: p.w-2*dm, height: p.h-2*dm, 
            paddingTop: 0, borderRadius: 10, backgroundColor: color, cursor: "pointer"}} onClick={this.onClick}
            onContextMenu={this.cm} onMouseDown={this.md}>
            {this.showZoneList()}
          </div>
        );
    }else{
      return (<div>loading. . .</div>)
    }
  }

  render() {
    if(this.state.loaded){
      cl("new zone list")
      let p = this.props.parms;
      let p2=p.wdg.parms
      let dm=5
      let color=p2.bgColor
      let x=p.x+dm
      let y=p.y+dm
      if(this?.state?.dragStart?.i==this.props.parms.i) {
        x+=this.state.drag.x-this.state.dragStart.x
        y+=this.state.drag.y-this.state.dragStart.y
      }
      return(
        <div style={{position: "absolute", left: x, top: y, width: p.w-2*dm, height: p.h-2*dm, 
              paddingTop: 0, borderRadius: 10, backgroundColor: color, cursor: "pointer"}} onClick={this.onClick}
              onContextMenu={this.cm} onMouseDown={this.md}>
          {this.showZoneHeader()}
          {this.displayZones()}
        </div>
      );
    } else {
      return (<div>loading. . .</div>)
    }
  }
}

export default ZoneListWidget00;
