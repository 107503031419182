import React from 'react';
import SettingsApplications from '@material-ui/icons/SettingsApplications';
import Icon from '@material-ui/core/Icon';

// import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplicationsIcon';
import {cl, globs, constant, makeShortDayDateStr} from '../../components/utils/utils';
import {getDatapointName} from '../../usa/utils/utils'

class ConfigLogFeedEntry00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
  }

  render=()=>{
    let entry=this.props.parms.entry
//     cl(entry)
    let av=entry.avatar
    var path
    if(av){
      path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
    let name=getDatapointName(entry.i)
    let dateStr=makeShortDayDateStr(entry.t)
    let desc=`${name}: ${entry.d}`
    return(
      <table><tbody>
      <tr><td><img src={path} height="40"/></td><td>
        <table><tbody>
        <tr><td>
        <Icon component={SettingsApplications} style={{padding: 0, fontSize: 18, verticalAlign: "middle"}}/>
        <span style={{verticalAlign: "middle"}}>{dateStr}</span></td></tr>
        <tr><td>{desc}</td></tr>
        </tbody></table>
      </td></tr>
      </tbody></table>
    )
  }
}

export default ConfigLogFeedEntry00;
