import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Input from "@material-ui/core/Input";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {cl} from '../../components/utils/utils';

class Password extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      passwordType: "password",
      value: (props.initValue) ? props.initValue : "",
    }
  }
  
  handleClickShowPassword = ()=>{
    cl("change");
    this.setState({passwordType: (this.state.passwordType==="password") ? "text" : "password"});
  }
  
  onIFChange=(e)=>{
    let val=e.currentTarget.value;
    this.setState({value: val});
    this.props.onChange(this.props.valueId, val);
  }

  render(){
    return(
      <div style={{padding: 10}}>
      <InputLabel htmlFor="unknown">{this.props.title}</InputLabel>
      <Input
      onChange={this.onIFChange}
      type={this.state.passwordType}
      value={this.state.value}
      endAdornment={
        <InputAdornment position="end">
        <IconButton
        aria-label="toggle password visibility"
        onClick={this.handleClickShowPassword}
        edge="end"
        >
        {this.state.passwordType === "password" ? <Visibility /> : <VisibilityOff />}
        </IconButton>
        </InputAdornment>
      }
      />
      </div>
    );
  }
}

export default Password;
