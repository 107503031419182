import React from 'react';
import {pb, pInd, pi, tableIds, tableBases2, offsetTables} from "../components/utils/paramIds";//p,
import {globs} from '../components/utils/utils';
import FUISelect from './controls/FUISelect';
import ChannelType from './controls/ChannelType';
import SelectUnit from './controls/SelectUnit';
import TextGeneral from './controls/TextGeneral';
import BiweeklyIrrigation from './controls/BiweeklyRepeat';
import FUINumerical from './controls/FUINumerical';
import Numerical from './controls/Numerical';
import SaveCancel from './controls/SaveCancel';
import Select18 from './controls/Select18';
import Master18 from './controls/Master18';
import Duration from './controls/Duration';
import DurationOnOff from './controls/DurationOnOff';
import TimeOfDay from './controls/TimeOfDay';
import TimeInput from "./controls/TimeInput";
import Checkbox from './controls/Checkbox';
import TextDisplay from './controls/TextDisplay';
import ZoneGroups from './controls/ZoneGroups';
import ShowImage from './controls/ShowImage';
import SetDefaults from './controls/SetDefaults';
import Section from './controls/Section';
import Triggers18 from './controls/Triggers18';
import Triggers18b from './controls/Triggers18b';
import HumDeHum from './controls/HumDeHum';
import HumDeHumFull from './controls/HumDeHumFull';
import VentStages from './controls/VentStages';
import AllIrrigation from './controls/AllIrrigation';
import Co2_800Generator from './controls/Co2_800Generator';
import Misc800 from './controls/Misc800';

import EcphTankSensor from './controls/EcphTankSensor';
import TankType from './controls/TankType';
import StageOnOff from './controls/StageOnOff';
import SmartInject from './controls/SmartInject';
import SmartCool from './controls/SmartCool';
import PumpAdvancedSettings from './controls/PumpAdvancedSettings';
import PIDAdvancedSettings from './controls/PIDAdvancedSettings';
import PMAdvancedSettings from './controls/PMAdvancedSettings';
// import AuxControls from './controls/AuxControls';
import AuxControls00 from './controls/AuxControls00';
import RetractableRoof from './controls/RetractableRoof';
import TempStaging00 from './controls/TempStaging00';

import RetractableGreenhouse from './controls/RetractableGreenhouse';
import Setpoints from './controls/Setpoints';
import GenericSensors from './controls/GenericSensors00';
import EquipmentOverride from './controls/EquipmentOverride';
import Link00 from './controls/Link00';
import ShowAuxControls from './controls/ShowAuxControls';
import Current from './iponicControls/Current';
import {cl,constant,proportionals} from '../components/utils/utils';
import {dbVals} from '../components/utils/http';

var controls = {
  "FUIselect": FUISelect,
  "biweeklyRepeat": BiweeklyIrrigation,
  "FUINumerical": FUINumerical,
  // "temp2": Temp2,
  "channelType": ChannelType,
  "selectUnit": SelectUnit,
  "numerical": Numerical,
  "saveCancel": SaveCancel,
  "textGeneral": TextGeneral,
  "textDisplay": TextDisplay,
  "showImage": ShowImage,
  "setDefaults": SetDefaults,
  "section": Section,
  "select18": Select18,
  "master18": Master18,
  "duration": Duration,
  "durationOnOff": DurationOnOff,

  "timeInput": TimeInput,
  "timeOfDay": TimeOfDay,
  "checkbox": Checkbox,
  "triggers18": Triggers18,
  "Triggers18b": Triggers18b,
  "HumDeHum": HumDeHum,
  "ecph18": EcphTankSensor,
  "current": Current,
  "tankType": TankType,
  "showAuxControls": ShowAuxControls,
  "stageOnOff": StageOnOff,
  "smartInject": SmartInject,
  "smartCool": SmartCool,
  "pumpAdvancedSettings": PumpAdvancedSettings,
  "pidAdvancedSettings": PIDAdvancedSettings,
  "pmAdvancedSettings": PMAdvancedSettings,
  "auxControl": AuxControls00,
  "retractableRoof": RetractableRoof,
  "retractableGreenhouse": RetractableGreenhouse,
  "equipmentOverride": EquipmentOverride,
  "zoneGroups": ZoneGroups,
  "tempStaging": TempStaging00,
  "link": Link00,
  "setpoints": Setpoints,
  "genSensors": GenericSensors,
  "HumDeHumFull": HumDeHumFull,
  "VentStages": VentStages,
  "AllIrrigation": AllIrrigation,
  "Co2_800Generator": Co2_800Generator,
  "Misc800": Misc800,

};

var pearlSpecificFUI=[10,41,50,80,81,82,121,180,280,290]

var chTypes = {
  "channel_None": {id:0, t:"None", ct:""},
  "channel_On_Off": {id:10, t:"On Off", ct:""},
//   "channel_On_Off_1900": {id:10, t:"On Off", ct:""},
  "channel_Irrigation_Scheduled": {id:20, t:"Irrigation Scheduled", ct:""},
  "channel_Irrigation_Accumulated_Light": {id:21, t:"Irrigation Accumulated Light", ct:""},
  "channel_Irrigation_Cycle": {id:22, t:"Irrigation Cycle", ct:""},
  "channel_Irrigation_Trigger": {id:23, t:"Irrigation Trigger", ct:""},
  "channel_Irrigation_Soil_Trigger": {id:24, t:"Irrigation Soil Trigger", ct:""},
  "channel_Irrigation_VPD": {id:25, t:"Irrigation VPD", ct:""},
  "channel_CO2": {id:30, t:"CO2", ct:""},
  "channel_Light_Supplemental": {id:40, t:"Light Supplemental", ct:""},
  "channel_Light_Scheduled": {id:41, t:"Light Scheduled", ct:""},
  "channel_Light_Cyclic": {id:42, t:"Light Cyclic", ct:""},
  "channel_Light_DLI": {id:43, t:"Light DLI", ct:""},
  "channel_Microzone": {id:50, t:"Microzone", ct:"l"},
  "channel_Supply_Pump": {id:60, t:"Supply Pump", ct:""},
  "channel_Peristaltic_Recirculating_Pump": {id:61, t:"Peristaltic Recirculating Pump", ct:"d"},
  "channel_Peristaltic_Batch_Pump": {id:62, t:"Peristaltic Batch Pump", ct:"d"},
  "channel_Peristaltic_Balance_Pump": {id:63, t:"Peristaltic Balance Pump", ct:"d"},
  "channel_Fill_Valve": {id:70, t:"Fill Valve", ct:""},
  "channel_Vent_Roof": {id:80, t:"Vent Roof", ct:"p"},
//   "channel_Vent_Roof_1900": {id:80, t:"Vent Roof", ct:"p"},
  "channel_Vent_Retractable_Roof": {id:81, t:"Vent Retractable Roof", ct:"p"},
//   "channel_Vent_Retractable_Roof_1900": {id:81, t:"Vent Retractable Roof", ct:"p"},
  "channel_Vent_Side_Wall": {id:82, t:"Vent Side Wall", ct:"p"},
//   "channel_Vent_Side_Wall_1900": {id:82, t:"Vent Side Wall", ct:"p"},
  "channel_Curtain": {id:90, t:"Curtain", ct:"p"},
  "channel_Mix_Valve": {id:100, t:"Mix Valve", ct:"p"},
  "channel_Proportional_Microzone": {id:110, t:"Proportional Microzone", ct:"p"},
  "channel_PID": {id:120, t:"PID", ct:"p"},
  "channel_PID_analog": {id:121, an:1, t:"PID Analog", ct:"p"},
  "channel_Variable_Out": {id:180, an:1, t:"Variable Out", ct:"p"},
  "channel_Mix_Valve_Analog": {id:280, an:1, t:"Mix Valve Analog", ct:"p"},
  "channel_DLI_Analog": {id:290, an:1, t:"DLI Analog", ct:"p"},
//   "channel_Variable_Out_Analog": {id:300, an:1, t:"Variable Out", ct:"p"},
};

var chTypesExp={
  1800:{
    "channel_None": {id:0, t:"None", ct:""},
    "channel_On_Off": {id:10, t:"On Off", ct:""},
    "channel_Irrigation_Scheduled": {id:20, t:"Irrigation Scheduled", ct:""},
    "channel_Irrigation_Accumulated_Light": {id:21, t:"Irrigation Accumulated Light", ct:""},
    "channel_Irrigation_Cycle": {id:22, t:"Irrigation Cycle", ct:""},
    "channel_Irrigation_Trigger": {id:23, t:"Irrigation Trigger", ct:""},
    "channel_Irrigation_Soil_Trigger": {id:24, t:"Irrigation Soil Trigger", ct:""},
    "channel_Irrigation_VPD": {id:25, t:"Irrigation VPD", ct:""},
    "channel_CO2": {id:30, t:"CO2", ct:""},
    "channel_Light_Supplemental": {id:40, t:"Light Supplemental", ct:""},
    "channel_Light_Scheduled": {id:41, t:"Light Scheduled", ct:""},
    "channel_Light_Cyclic": {id:42, t:"Light Cyclic", ct:""},
    "channel_Light_DLI": {id:43, t:"Light DLI", ct:""},
    "channel_Microzone": {id:50, t:"Microzone", ct:"l"},
    "channel_Supply_Pump": {id:60, t:"Supply Pump", ct:""},
    "channel_Peristaltic_Recirculating_Pump": {id:61,
      t:"Peristaltic Recirculating Pump", ct:"d"},
    "channel_Peristaltic_Batch_Pump": {id:62, t:"Peristaltic Batch Pump", ct:"d"},
    "channel_Peristaltic_Balance_Pump": {id:63, t:"Peristaltic Balance Pump", ct:"d"},
    "channel_Fill_Valve": {id:70, t:"Fill Valve", ct:""},
    "channel_Vent_Roof": {id:80, t:"Vent Roof", ct:"p"},
    "channel_Vent_Retractable_Roof": {id:81, t:"Vent Retractable Roof", ct:"p"},
    "channel_Vent_Side_Wall": {id:82, t:"Vent Side Wall", ct:"p"},
    "channel_Curtain": {id:90, t:"Curtain", ct:"p"},
    "channel_Mix_Valve": {id:100, t:"Mix Valve", ct:"p"},
    "channel_Proportional_Microzone": {id:110, t:"Proportional Microzone", ct:"p"},
    "channel_PID": {id:120, t:"PID", ct:"p"},
    "channel_PID_analog": {id:121, an:1, t:"PID Analog", ct:"p"},
    "channel_Variable_Out": {id:180, an:1, t:"Variable Out", ct:"p"},
    "channel_Mix_Valve_Analog": {id:280, an:1, t:"Mix Valve Analog", ct:"p"},
    "channel_DLI_Analog": {id:290, an:1, t:"DLI Analog", ct:"p"},
//     "channel_Variable_Out_Analog": {id:300, an:1, t:"Variable Out", ct:"p"},
  },

  1900:{
    "channel_None": {id:0, t:"None", ct:""},
    "channel_On_Off": {id:10, t:"On Off", ct:""},
    "channel_Irrigation_Scheduled": {id:20, t:"Irrigation Scheduled", ct:""},
    "channel_Irrigation_Accumulated_Light": {id:21, t:"Irrigation Accumulated Light", ct:""},
    "channel_Irrigation_Cycle": {id:22, t:"Irrigation Cycle", ct:""},
    "channel_Irrigation_Trigger": {id:23, t:"Irrigation Trigger", ct:""},
    "channel_Irrigation_Soil_Trigger": {id:24, t:"Irrigation Soil Trigger", ct:""},
    "channel_Irrigation_VPD": {id:25, t:"Irrigation VPD", ct:""},
    "channel_CO2": {id:30, t:"CO2", ct:""},
    "channel_Light_Supplemental": {id:40, t:"Light Supplemental", ct:""},
    "channel_Light_Scheduled": {id:41, t:"Light Scheduled", ct:""},
    "channel_Light_Cyclic": {id:42, t:"Light Cyclic", ct:""},
    "channel_Light_DLI": {id:43, t:"Light DLI", ct:""},
    "channel_Microzone": {id:50, t:"Microzone", ct:"l"},
    "channel_Supply_Pump": {id:60, t:"Supply Pump", ct:""},
    "channel_Peristaltic_Recirculating_Pump": {id:61,
      t:"Peristaltic Recirculating Pump", ct:"d"},
    "channel_Peristaltic_Batch_Pump": {id:62, t:"Peristaltic Batch Pump", ct:"d"},
    "channel_Peristaltic_Balance_Pump": {id:63, t:"Peristaltic Balance Pump", ct:"d"},
    "channel_Fill_Valve": {id:70, t:"Fill Valve", ct:""},
    "channel_Vent_Roof": {id:80, t:"Vent Roof", ct:"p"},
    "channel_Vent_Retractable_Roof": {id:81, t:"Vent Retractable Roof", ct:"p"},
    "channel_Vent_Side_Wall": {id:82, t:"Vent Side Wall", ct:"p"},
    "channel_Curtain": {id:90, t:"Curtain", ct:"p"},
    "channel_Mix_Valve": {id:100, t:"Mix Valve", ct:"p"},
    "channel_Proportional_Microzone": {id:110, t:"Proportional Microzone", ct:"p"},
    "channel_PID": {id:120, t:"PID", ct:"p"},
    "channel_PID_analog": {id:121, an:1, t:"PID Analog", ct:"p"},
    "channel_Variable_Out": {id:180, an:1, t:"Variable Out", ct:"p"},
    "channel_Mix_Valve_Analog": {id:280, an:1, t:"Mix Valve Analog", ct:"p"},
    "channel_DLI_Analog": {id:290, an:1, t:"DLI Analog", ct:"p"},
//     "channel_Variable_Out_Analog": {id:300, an:1, t:"Variable Out", ct:"p"},
  },

  800:{
//   "channel_None": {id:290, t:"None", ct:""},
  "channel_On_Off_800": {id:300, t:"On Off", ct:""},
  "channel_Vent_800": {id:310, t:"Vent", ct:""},
  "channel_Curtain_800": {id:320, t:"Curtain", ct:""},
  "channel_Alarm_800": {id:330, t:"Alarm", ct:""},
  "channel_Co2_800": {id:340, t:"CO2", ct:""},
}}

var groupPidArrs={setpoints:[
  4636,4638,4639,4640,4641,4642,4643,4644,4645,
  4646,4648,4649,4650,4651,4652,4653,4654,4655,
  4656,4658,4659,4660,4661,4662,4663,4664,4665,
  4666,4668,4669,4670,4671,4672,4673,4674,4675,
  4676,4678,4679,4680,4681,4682,4683,4684,4685,
  4686,4688,4689,4690,4691,4692,4693,4694,4695,
  4696,4698,4699,4700,4701,4702,4703,4704,4705,
  4706,4708,4709,4710,4711,4712,4713,4714,4715,
]}


// var proportionals=[constant.EQ_VENT, constant.EQ_CURT, constant.EQ_MIXV,
//   constant.EQ_MZONE2, ,constant.EQ_GEN_PID, constant.EQ_VAROUT, constant.EQ_MIXV_AN, ]


/*
idoser types:
on/off  
irrigation
co2 
light
pump
fillvalve

odd channels:
  onoff 
  irrigation
  co2 
  light 
  microzone 
  pump 
  fill valve 
  
proportional
vent 
curtain 
mix valve 
prop micro zone 
generic pid 

list types:
l4 odd, even
mb odd, even
id odd even

so, "p" is proportional, and "l" is included in link4 only, "d" is doser only


 
 */



var makeParmTabs=(gatewayType)=>{
  // base: 0
  // cols: [id: 1, zoneIndex: 2, unitIndex: 3, unix_timestamp(created): 4, unix_timestamp(unitTime): 5, …]
  // indCnt: 0
  // indSize: 0
  // name: "snapshots"
  // type: 0
  let tableList = [];
//   cl(gatewayType)
//   cl(pb[gatewayType])
//   cl(gatewayType)
//   cl(pInd[gatewayType])
  Object.keys(pb[gatewayType]).forEach((e, i)=>{
//     cl(e)
//     cl(pInd[gatewayType][e])
    tableList[tableIds[gatewayType][i]]={
      name: e, 
      base: pInd[gatewayType][e][0],
      id: tableIds[i],
      type: pInd[gatewayType][e][1],
      indSize: pInd[gatewayType][e][2],
      indCnt: pInd[gatewayType][e][3],
      cols: pi[gatewayType][e],
    }
  });
  return tableList;
}

var fixTypeOffset=(ofs, tabType, indSize)=>{
  // cl(ofs);
  switch(tabType){
    case 6:// controller settings
      ofs = (ofs - 1) / indSize;
      break;
    case 7:
      ofs = (ofs - 1) / indSize;
      break;
    default:
      break;
  }
  // cl(ofs);
  return ofs;
}

var paramIdToTableColumn=(pid,gatewayType)=>{
/*I messed up for table types 6 and 7: the controller
and zone configuration tables, since these
store values by rows, not columns*/
//   cl(pid)
//   cl(gatewayType)
//   cl(tableBases2)
//   cl(gatewayType)
//   cl(tableBases2[gatewayType])
//   cl(tableBases2[gatewayType||1800])
  for (let i = 0 ; i < tableBases2[gatewayType||1800].length ; i++){
//     cl(tableBases2[gatewayType][i + 1])
//     cl(tableBases2[gatewayType])
//     cl(tableBases2[gatewayType])
//     cl(i+1)
    if ((tableBases2[gatewayType][i + 1]||{})[0] >= pid){
//       cl(tableBases2[gatewayType][i]);
      let tabType = tableBases2[gatewayType][i][1];
      let indSize = tableBases2[gatewayType][i][2];
      let tab = tableIds[gatewayType][i];
      let base = tableBases2[gatewayType][i][0];
      let ofs = pid - base; // (pid < 1024) ? pid :
//       cl(offsetTables,gatewayType,i)
      let ofsTab = offsetTables[gatewayType][i];
      let offsetKeys = Object.keys(ofsTab);
//       cl(ofs)
//       cl(ofsTab)
//       cl(offsetKeys)
      ofs = fixTypeOffset(ofs, tabType, indSize);
//       cl(ofs)
      // cl([pid, tab])
      // cl(offsetKeys)
      // cl(ofsTab)

      for (let j = 0 ; j < offsetKeys.length ; j++){
        if (ofsTab[offsetKeys[j]]*1 === ofs){
          // cl(tab, offsetKeys[j])
          return [tab, offsetKeys[j]];
        }
      }
      return [-1, -1];
    }
  }
}

var makeFuiPage=(pageType)=>{
  // cl(globs.fuiPages)
  let page = {
    controls: [],
    description: "Insert Description Here",
    name: "Insert Name Here",
    title: "Insert Title for " + pageType + " Here",
    type: pageType,
  }
  if(!globs.fuiPages){globs.fuiPages={}}
  globs.fuiPages[pageType] = page;
  // cl(pageType)
}

var createElement=(tag, props, chil)=>{
  // cl([tag, props, chil])
  return React.createElement(tag, props, chil);
}

var getHto=(hto, i)=>{// hto is [tag, [props], [children] or text]
/* I don't think this is quite right.
the idea is that an hto can be a template or a control
*/
  // cl(hto);
  var tag, props, chil;
/* is this part actually necessary?
Yes! this is where we fill in props, etc. for variables*/
  if (typeof hto === "string"){// "children" can be just string
    // cl(hto.split('.')[0])
    let sp = hto.split('.');
    switch (sp[0]){
    case 't':
      let ch = globs.templates[sp[1]];
      return getHto(ch, i);
      // let ch = JSON.parse(gl)
      // cl(sp[1])
      return;
    case 'c':
      cl(hto);
      return;
    default:
      return;
    }
  }
  tag = hto[0];
  if (hto.length > 2){// if we have props
    props = hto[1];
    chil = hto[2];
  }else{
    props = {};
    chil = hto[1];
  }
  if (i !== undefined) props.key = i;
  // cl([tag, props, chil])
  if (typeof chil === "string"){
    return React.createElement(tag, props, chil);
  }else{
    let chilArr = [];
    chil.forEach((c, i)=>{
      chilArr.push(getHto(c, i))
    })
    return React.createElement(tag, props, chilArr);
  }
}

/***************** Aux Control Stuff *****************************/

  var makeConversionArray=()=>{
    let base=pInd[1800].config_aux_controls[0]
    let arr={}
    let vars=pi[1800].config_aux_controls
    for(let k in vars){
      arr[k]=base+vars[k]
    }
    return arr
  }
  
  var groupOperandOperators=(ac,gwType)=>{
/* From LinkConn 0 is the high one, 1 is the low one
        auxControl.operators[0] = (self.operators >> 4) & 0xF
        auxControl.operators[1] = (self.operators >> 0) & 0xF
*/
//     cl(ac.operator1)
//     cl("group operators")
//     cl(ac)
    ac.operandValues=[ac.operand1Value, ac.operand2Value, ac.operand3Value, ac.targetValue];
    ac.operandTypes=[ac.operand1Type, ac.operand2Type, ac.operand3Type, ac.targetType];
//     ac.operators=[ac.operator1, ac.operator2];
// for Pearl
//     ac.operators=[(ac.operator1>>4)&0x0F, ac.operator1&0x0F];
// for 1800    
//     cl(gwType)
//     cl([ac.operator1,ac.operator2])
    if(gwType==1900){// pearl
      ac.operators=[(ac.operator1>>4)&0x0F, ac.operator1&0x0F];
    }else{// 1800
      ac.operators=[ac.operator1, ac.operator2];
    }
  }
  
  var loadAuxControls=(zone,unit,gwType)=>{// this will scan the whole array of 128. When they're written back, delete the unused ones at the end
//     cl(zone,unit)
//     cl("load aux")
    let convArr=makeConversionArray()
//     cl(convArr)
    let mult=pInd[1800].config_aux_controls[2]
    let z=zone//this.state.zone
//     cl(zone)
//     cl(dbVals)
//     cl(z)
    let chId=240+(unit||0)
//     cl(chId)
    let ch=(dbVals.z[z]||{})[chId]||{}
    let o1=convArr["operand1Type"]
    let o2=convArr["operand2Type"]
    let o3=convArr["operand3Type"]
//     cl(o1)
    let aux=[]
    for(let i=0;i<128;i++){
      let iOfs=i*mult
      let gotVals=Object.keys(convArr).filter(k=>{
        let v=ch[iOfs+convArr[k]]
        if(v==undefined){v=0}
        if(isNaN(v)){v=1}
        return v!=0
      })
//       cl(gotVals)
      let good=(gwType==1800)?
        (+ch[iOfs+o1])||(+ch[iOfs+o2])||(+ch[iOfs+o3]):
        gotVals.length!=0
//       cl(good, iOfs+o1)
      if(good){
        let ac={}
        for (let k in convArr){
          if(ch[iOfs+convArr[k]]!=undefined){ac[k]=ch[iOfs+convArr[k]]}
        }
        groupOperandOperators(ac,gwType)
//         cl(ac)
        aux.push(ac)
      }
    }
    return aux
  }
  
  var loadDBNames=(zone, unit, vars, ofs, fillIn)=>{
//     console.trace()
//     cl([zone,unit,vars,ofs,fillIn])
//     cl(ofs,fillIn,vars)
    let base=vars[0]
    let siz=vars[2]
    let cnt=vars[3]
    let ret=[]
//     cl(dbVals.z)
//     cl(zone)
    let ch=((dbVals.z[zone]||{})[240+(unit||0)])||[]
    for(let i=0;i<cnt;i++){
      let id=base+i*siz+ofs
//       cl(ch)
//       cl(id)
      let name=ch[id]||`${fillIn} ${i+1}`//?ch[id]:``
      let graph=ch[id+1]//?ch[id+1]:0
      let comment=ch[id+2]//?ch[id+2]:`${""}`
//       let name=(ch[id])?ch[id]:``
//       let graph=(ch[id+1])?ch[id+1]:0
//       let comment=(ch[id+2])?ch[id+2]:`${""}`
      ret.push({n:name,g:graph,c:comment})
    }
    return ret
  }
  
  var loadEquipmentNames=(zone,unit,channelInfo,gwType)=>{
//     console.trace()
//     cl(channelInfo)
//     cl(zone)
    if(!channelInfo){return {}}
//     console.trace()
    let names={}//[]
    // cl(channelInfo)
    channelInfo.forEach((ci,i)=>{
//       cl(ci.used)
//       cl(i,ci)
// ghost if chans 12-15, or type "none", or odd, and the even channel is proportional
// need to add "not analog"
      if(ci.channelType){
        // cl(ci)
        let ghost=(((i>=12&&i<16)&&(gwType!=1900))||(ci.channelType==0)||
          (((i%2)!=0)&&proportionals.includes(channelInfo[i-1].channelType)&&
            (!ci.isAnalog)
          )
        )
        if(ci.used&&!ghost){
          names[i]=ci.channelName||`Chan ${i+1}`
        }
      }
    })
//     cl(names)
    return names
  }

  var loadEquipmentTypes=(zone,unit,channelInfo)=>{
    let types=[]
    channelInfo.forEach(ci=>{
//       cl(ci)
//       cl(ci.channelName)
      types.push(ci.channelType)
    })
    return types
  }
  
//   var loadSensors=async(zoneId)=>{
// //     let zoneId=getZoneId(siteId,z)
// //     cl(siteId)
//     setSensorIdNames(zoneId)
// //     await loadSensorsInfo()
//     cl(zoneId)
//   }

  var setSensorNames=(unit,sensorIds)=>{
    let sensors={}
    // cl(sensorIds)
    Object.keys(sensorIds).forEach(k=>{
      let s=sensorIds[k]
      let u=+k.substring(1,2)
      if(s.lcIndex!=undefined){
        if(u==unit){sensors[s.lcIndex]=s.name}
      }
    })
    // cl(sensors)
    return sensors
  }
  
  var skipThisGwType=(pr)=>{
    let gwType=pr.current.gwType||1800
    if(pr.custom.gwTypes&&(!pr.custom.gwTypes.includes(gwType))){return true}
  }



/***************** End Aux Control Stuff *****************************/
export {makeParmTabs, paramIdToTableColumn, makeFuiPage,setSensorNames,
  controls, chTypes, chTypesExp, getHto,loadAuxControls,makeConversionArray,
  loadDBNames,loadEquipmentNames,loadEquipmentTypes,
  pearlSpecificFUI,skipThisGwType,groupPidArrs}
