import React from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18Select01 from './C18Select01'
import C18Button00 from './C18Button00'
import {getParamId} from '../utils/utils'
import {wsTrans,doGetPostBasic} from '../utils/utils'
import * as html2canvas from "html2canvas"
// import {gdRest} from './Usa'
import {/*loadSitesInfo,*/loadZonesInfo,loadAccountInfo,loadGatewaysInfo,getGatewayInfo,
  getGatewayIndex, addToAdminLog, restGd,handleGdMsg,restRespGd,restKeyGd,restArrGd,
  gdRest,privs,loadUsersInfo,downloadFile,makeGodotResourceUrl,
  resToGodot,loadGodotPack,
} from './C18utils'
import {cl, az, globs, constant, getRandomString, getTime, rnd, ufs} from '../../components/utils/utils';
import history from "../../history"

const scrWid=1024
const scrHgt=768

class C18SvgEditor00 extends React.Component{
  constructor(props) {
    super(props);
//     cl("SVG Editor Constructor")
    this.svgDiv=React.createRef();
    this.state={
      loaded: false,
      addName:"",
      addId:"",
      eqSel:"",
      diType:"seTemp",
      dp1Sel:"",
      dp2Sel:"",
      dpName:"",
      geoGH:{x:0,y:0,w:100,h:100,t:30,rot:{x:0,y:0},vLat:34.4,vLng:-117.64},
      rotStep:2,
      vLat:"",
      vLng:"",
      godotLink:"",//"/godot/PhysicalView.html",
      equip3d:[// displays and animations
        {node:"Zone/Equipment/HAF1",diType:"eqOnO",dpIds:["ch00Pos"]},
        {node:"Zone/Equipment/HAF2",diType:"eqOnO",dpIds:["ch01Pos"]},
      ],
      nodeTree:[],
    }
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveDisp)
    this.subscribe_godotReady=globs.events.subscribe("godotReady", this.godotReady)
    
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    
    this.loadInfo()
    this.setBreadCrumbs()
    this.loadMap()
    this.mapDiv=React.createRef()
    this.linkRef=React.createRef()
//     this.showLocalStorage()
    
//     this.testIndexedDb()
  }
  
  testLoadPack=()=>{
    loadGodotPack("electric_fan.pck")
  }
  
//   openIdb=(dbName)=>{
//     return new Promise((r,e)=>{
//       let db=window.indexedDB.open(dbName)
//       db.onsuccess=(e)=>{r({db:db.result,e:e})}
//       db.onerror=e
//     })
//   }
  
//   idbObjectStore=(db,os)=>{// open a single objectStore
//     return new Promise((r,e)=>{
//       let trans=db.transaction([os])
//       cl(trans)
//       let oStore=trans.objectStore(os)
//       cl(oStore)
//       cl(oStore.count())
//       oStore.onsuccess=(e)=>{cl(e)}
//       oStore.onerror=(e)=>{cl(e)}
//     })
//   }

//   idbCount=(os)=>{
//     return new Promise((r,e)=>{
//       let cnt=os.count()
//       cnt.onsuccess=(res)=>r(cnt.result)
//       cnt.onerror=e
//     })
//   }
  
  idbRequest=(obj,method,arg)=>{
    return new Promise((r,e)=>{
      let res=obj[method](arg)
      res.onsuccess=(re)=>{r(res.result)}
      res.onerror=e
    })
    
  }
  
  idbShowDatabases=async()=>{
    let buf=new Buffer(4)
    cl(buf)
    cl("show dbs")
    let db=await this.idbRequest(window.indexedDB,"open","/userfs")
    let osName=db.objectStoreNames[0]// FILE_DATA
    let trans2=db.transaction([osName])
    let store=trans2.objectStore(osName)// props: indexNames, keyPath, name, transaction
    
    let cnt2=await this.idbRequest(store,"count")
    let keys=await this.idbRequest(store,"getAllKeys")
    let key="/userfs/scenes/LCUZshedgSw1Xair.tscn"
    
    let val=await this.idbRequest(store,"get",key)
    let td=new TextDecoder()
    let str=td.decode(val.contents)
    cl(str)
    cl(keys)
    cl(val.contents.toString())
// key: "/userfs/scenes/LCUZshedgSw1Xair.tscn"    
//     cl(cnt2)
    
//     let objectStores=db.objectStoreNames
//     cl(objectStores)
//     let os=objectStores[0]
//     cl(os)
//     let trans=db.transaction([os])
//     cl(trans)
//     let osStore=trans.objectStore(os)// props: indexNames, keyPath, name, transaction
//     cl(osStore)
//     let cnt1=await this.idbRequest(osStore,"count")
//     cl(cnt1)
  }
  
  initIDB=()=>{
//     this.idbShowDatabases()
// //     window.indexedDB.open("/userfs").onsuccess
//     
//     return
    
    this.open=window.indexedDB.open("/userfs")// (dbName, version)
    cl(this.open)
    this.open.onupgradeneeded=()=>{
      cl("on upgreade")
      let db=this.open.result
      let store=db.createObjectStore("MyStore",{keyPath:"id"})
//       let index=store.createIndex("NameIndex",["name.last","name.first"])
    }
    this.open.onsuccess=()=>{
      cl("on success")
      let db=this.open.result
      let tx = db.transaction("FILE_DATA", "readwrite");
      let store = tx.objectStore("FILE_DATA");
//       let index = store.index("NameIndex");
//       store.put({id: 12345, name: {first: "John", last: "Doe"}, age: 42});
//       store.put({id: 67890, name: {first: "Bob", last: "Smith"}, age: 35});
//       let getJohn = store.get(12345);
//       getJohn.onsuccess=()=>{
//         console.log(getJohn.result.name.first);
//       }
    }
    
    this.open.onerror=()=>{
      cl(this.open.error)
      cl("error")
    }
    
//     this.idb={
//       db:db,
//       store=
// //       open:
//     }
  }
  
  testIndexedDb=async()=>{
    var resp
    await ufs.set("/userfs/scenes/LCUZshedgSw1Xair.tscn2a")
//     resp=await ufs.get("/userfs/scenes/LCUZshedgSw1Xair.tscn")
//     cl(resp)
    resp=await ufs.get("/userfs/scenes/LCUZshedgSw1Xair.tscn2a")
    cl(resp)
//     let res=await ufs.list("/userfs/packs")
    let res=await ufs.list()
    cl(res)
//     this.initIDB()
//     return
//     let path="/userfs/scenes/LCUZshedgSw1Xair.tscn2"
//     let res=await ufs.get(path)
//     var res=""
//     ufs.set(path+"2",res+" again")
//     cl(res)
    
  }
  
  showLocalStorage=()=>{
    let list=Object.keys(localStorage)
    cl(list)
  }
  
  loadMap=()=>{
//     cl("loadMap")
    let tmr=setInterval(x=>{
      if(window.google){
        clearInterval(tmr)
        this.setMap()
      }
    },100)
  }
  
  setOurState=(name,st)=>{
//     cl(`state state: ${name}`)
    this.setState(st)
  }
  
  setMap=()=>{
//     cl("set map")
    let st=this.state
    this.google=window.google
    let gh=st.geoGH
//     cl(gh.vLat)
    var latLng=new window.google.maps.LatLng(gh.vLat||34,gh.vLng||-118)
//     cl(latLng)
    this.map=new window.google.maps.Map(this.svgDiv.current, {
      center:latLng,
      scrollWheel: true,
//       streetView:false,
      tilt:0,
      zoom: 18,
      disableDefaultUI:true,
      mapTypeId:"hybrid",
    })
//     setTimeout(x=>{
//       let bnd=this.map.getBounds()
//       cl(bnd)
//       cl(bnd.getNorthEast().toJSON())
//       cl(bnd.getSouthWest().toJSON())
//     },2000)
//     var bounds = map.getBounds();    
    
//     cl(bnd.getNorthEast())
//     cl(bnd.getSouthWest())
//       latLng=bnd.toJSON()
//       cl(bnd)
//     setTimeout(x=>{
//       let proj=this.map.getProjection()
//       let point=proj.fromLatLngToPoint(latLng)
//       cl(point)
// //       let point= new this.google.maps.Point(100,100)
// //       let llf=proj.fromPointToLatLng(point)
// //       let latLng=llf.toJSON()
// //       cl(point)
// //       
// //       cl(latLng)
//     },1000)
    
    this.map.addListener("click",e=>this.onChange("mapClick",{e:e}))
    this.map.addListener("idle",e=>this.onChange("mapLoaded",{e:e}))
//     this.map.addListener('projection_changed',e=>this.onChange("mapProjection",{e:e}))
    
//     this.setState({
//       map:map,
//     })
  }
  
//   componentDidMount=()=>{
//     this.map=new window.google.maps.Map(this.svgDiv.current, {
//       center:new window.google.maps.LatLng(34.4,-117.64),
//       zoom: 18,
//     })
//   }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
    this.subscribe_godotReady.remove()
  }
  
  godotReady=()=>{// called when Godot is ready
//     cl("got it")
    this.setGodotViewMode()
    this.testLoadPack()
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Gateways", url:`/usa/c18/admin/manageGateways2`},
            ]},
        },
      )
    }
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
  loadAccounts=async()=>{
//     cl(privs("super","",constant.AREA_PRIVS_READ))
    if(privs("super","",constant.AREA_PRIVS_READ)){
      if(this.accounts){return}
      let res=await wsTrans("usa", {cmd: "cRest", uri: "/su/suAccounts", method: "retrieve", 
        sessionId: globs.userData.session.sessionId,
        body: {}})
      return res.data
//       cl(res)
      this.accounts=res.data
//       cl(this.accounts)
    }
  }
  
  loadSites=async(accountId)=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/su/allSites", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {accountId:accountId}})
    return res.data
  }
  
  loadZones=async(siteId)=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/su/allZones", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId:siteId}})
    return res.data
  }
  
  get1800Channels=async(zi)=>{
//     cl("get 1800")
    let inNetId=getParamId("configuration_controllers","isInNetwork")
    let chUsedId=getParamId("configuration_channels","used")
    let chNameId=getParamId("configuration_channels","channelName")
    let contRes=await wsTrans("usa", {cmd: "cRest", uri: "/su/current", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {s:zi.siteId,z:zi.siteZoneIndex,i:inNetId}})
    
//     cl(contRes)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/su/current", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {s:zi.siteId,z:zi.siteZoneIndex,i:{$in:[chUsedId,chNameId]}}})
//     cl(res)
    let chans0=[]
    res.data.forEach(d=>{
      if(!chans0[d.c]){chans0[d.c]={}}
      if(d.i==chUsedId){
        chans0[d.c].used=d.d
        chans0[d.c].c=d.c
      }else{
        chans0[d.c].name=d.d
      }
    })
//     cl(chans0)
    let chans=res.data
    let chanFound={}
    let zoneCont={}
    contRes.data.forEach(c=>{
      if(c.d!=0){
        zoneCont[c.c-240]=1
      }
    })
//     cl(zoneCont)
    chans0.forEach(c=>{
//       if((c.i==chUsedId)&&(c.d!=0)){
      if(c.used){
        let cont=Math.floor(c.c/40)
        if(zoneCont[cont]){
//           cl(c.d)
          chanFound[c.c]={name:c.name}
        }
      }
    })
//     cl(chanFound)
    return chanFound
  }
  
  getPearlChannels=async(zoneInfo)=>{
    return []
  }
  
  getVirtualChannels=async(zoneInfo)=>{
    let chans={}
    for(let i=0;i<12;i++){chans[i]=1}
    return chans
  }
  
  getChannels=async(zoneId)=>{
// assume that we have the data loaded for this site
    let zoneInfo=this.zones.filter(z=>{return z.zoneId==zoneId})[0]
//     cl(zoneInfo)
    let gwType=zoneInfo?.gatewayType||1800
    if(zoneInfo.virtual){gwType="virt"}
    let getTypeChannels={
      "1800":this.get1800Channels,
      "1900":this.getPearlChannels,
      "virt":this.getVirtualChannels
    }
    return await getTypeChannels[gwType](zoneInfo)
  }
  
  makePermGeoGH=(gh)=>{
    let latLng=this.mapPtoL(
      this.proj,
      gh.x+(gh.w/2),// x,y is the center
      gh.y+(gh.h/2)
    )
    return {
      x:latLng.lng,
      y:latLng.lat,
      z:0,
      w:gh.wid,
      h:gh.hgt,
      t:gh.t,
//       rot:{x:0,y:0}
    }
  }
  
  saveGeoCode=async()=>{
    let st=this.state
    let ghInfo=this.makePermGeoGH(st.geoGH)
//     cl(st)
//     let latLng=this.mapPtoL(
//       this.proj,
//       st.geoGH.x+(st.geoGH.w/2),// x,y is the center
//       st.geoGH.y+(st.geoGH.h/2)
//     )
//     let ghInfo={
//       x:latLng.lng,
//       y:latLng.lat,
//       z:0,
//       w:st.geoGH.wid,
//       h:st.geoGH.hgt,
//       t:st.geoGH.t,
//     }
//     cl(ghInfo)
//     cl(st.zoneSel)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/su/allZones", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {zoneId:st.zoneSel,geoGH:ghInfo}})
    cl("all saved")
  }
  
  saveDisp=async()=>{
    cl("save disp")
    let st=this.state
    if(st.nodeTree.length>0){
      cl(st.dispVals)
      let dispVals=Object.keys(st.dispVals).map(k=>{
        let dv=Object.assign({nodePath:k},st.dispVals[k])
  //       let dv=st.dispVals[k]
  //       dv.nodePath=k
        return dv
      })
      cl(dispVals)
      cl("saving")
      let res=await wsTrans("usa", {cmd: "cRest", uri: "/su/equip3d", method: "update", 
        sessionId: globs.userData.session.sessionId,
        body: {siteId:st.siteSel,zoneId:st.zoneSel,dispVals:dispVals}})
      cl("saving")
    }
    this.saveGeoCode()
    globs.events.publish("saveOK",true)
    
  }
  
  initLLWH=(geoGH)=>{
// take lat/lng as the location of the *center*
// then rotate about the center
//     cl(geoGH)
    geoGH.lat=geoGH.y// in degrees
    geoGH.lng=geoGH.x
    geoGH.wid=geoGH.w// in meters
    geoGH.hgt=geoGH.h
    geoGH.rot={x:0,y:0}
    if(this.proj){
      let pnt=this.mapLtoP(this.proj,geoGH.lat,geoGH.lng)//x,y in meters
// the pnt looks OK. 484,411 in the test case, from upper left
//       cl(this.proj)
//       cl(pnt)
      let ppm=(1/(60*1852))/this.proj.dLat// dLat is degrees / pixel
      geoGH.w*=ppm
      geoGH.h*=ppm
      geoGH.x=pnt.x-(geoGH.w/2)// setting to upper left of div
      geoGH.y=pnt.y-(geoGH.h/2)/*+100*///-(geoGH.h/2)//-(geoGH.hgt*ppm) - measured from *top*
      geoGH.rot=this.calcRotAxes(geoGH)
//       cl(geoGH.rot)
//       cl("got proj")
//       cl(geoGH)
//       cl(geoGH.y)
// now, x,y,w,h should be for the upper left corner
    }
//     cl("done initLLWH")
  }
  
  testRtdEvents=async()=>{
    let st=this.state
    let now=getTime()
    let rtdEvents=await wsTrans("usa", {cmd: "cRest", uri: "/s/rtdEvents", 
      method: "retrieve", sessionId: globs.userData.session.sessionId, 
      body: {s:st.siteSel,from:0,to:now}})
    let events=rtdEvents.data.map(ev=>{return JSON.parse(ev.d)})
  }
  
  loadInfo=async ()=>{
//     cl("load")
//     createGodotDirectories()
//     cl(await ufs.list())
    await loadUsersInfo()
//     let chans=[]
//     await loadZonesInfo()
    this.accounts=await this.loadAccounts()
    this.accounts.sort((a,b)=>{
      if(a.name>b.name){return 1}
      if(a.name<b.name){return -1}
      return 0
    })
    this.sites=await this.loadSites(globs.userData.session.accountId)
//     cl(this.sites)
    let siteSel=this.sites/*globs.sitesInfo.info*/[0].siteId
    this.zones=await this.loadZones(siteSel)
//     cl(this.zones)
    let zoneSel=this.zones[0].zoneId
    let geoGH=Object.assign({},this.zones[0].geoGH)
    this.initLLWH(geoGH)
//     cl(geoGH)
    let vals={zoneSel:zoneSel}
//     cl(vals)
    await this.selectZone(vals)// sets vals.dispVals
    let chans=await this.getChannels(zoneSel)
    let intId=setInterval(x=>{
//       cl("checking interval")
      if(this.map){
        this.setSiteCenter()
        clearInterval(intId)
      }
    },1000)
//     cl(this.state)
//     let equipRes=await wsTrans("usa", {cmd: "cRest", uri: "/s/equip3d", method: "retrieve", 
//       sessionId: globs.userData.session.sessionId, body: {zoneId:st.zoneSel,sceneId:sceneId}})
//     let equips=
//     cl(this.zones)
//     cl(chans)
// //     await loadSitesInfo()
//     await loadZonesInfo()
//     let zoneSel=globs.zonesInfo.info.filter(z=>{return z.siteId==siteSel})[0].zoneId
//     cl(zoneSel)
//     cl("loaded")
//     cl(vals.dispVals)
    this.setOurState("l324",{loaded:true,accountSel:globs.userData.session.accountId,
      siteSel:siteSel,zoneSel:zoneSel,dispVals:vals.dispVals,channels:chans,
      geoGH:geoGH
    })
//     cl(this.state)
  }
  
  setSiteCenter=()=>{
    if(!this.map){return}
//     let zoneInfo=this.zones.filter(z=>{return z.zoneId==zoneId})[0]
    var minLat=1000,minLng=1000,maxLat=-1000,maxLng=-1000
    this.zones.forEach(z=>{
      if(z.geoGH){
        let gh=z.geoGH
        if(minLat>gh.y){minLat=gh.y}
        if(minLng>gh.x){minLng=gh.x}
        if(maxLat<gh.y){maxLat=gh.y}
        if(maxLng<gh.x){maxLng=gh.x}
      }
    })
    let vLat=((minLat+maxLat)/2)||34
    let vLng=((minLng+maxLng)/2)||-118
    let ll={lat:vLat,lng:vLng}
    this.map.setCenter(ll)
    this.setState({vLat:vLat,vLng:vLng})
  }
  
  selectZone=async(vals)=>{//zoneId
//     cl("select zone")
//     cl(vals)
    let st=this.state
    let zoneId=vals.zoneSel
    let zoneInfo=this.zones.filter(z=>{return z.zoneId==zoneId})[0]
    let chans=await this.getChannels(zoneId)
// this is taking forever!    
    let equipRes=await wsTrans("usa", {cmd: "cRest", uri: "/su/equip3d", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {zoneId:zoneId}})
    let dispVals={}
    equipRes.data.forEach(eq=>{
      dispVals[eq.nodePath]={diType:eq.diType,dpSel:eq.dpSel}
    })
    vals.dispVals=dispVals
    vals.nodeTree=[]
    if(zoneInfo.geoGH){
      vals.geoGH=Object.assign({},zoneInfo.geoGH)
      vals.geoGH.rot={x:0,y:0}
      this.initLLWH(vals.geoGH)
    }else{
      cl(st)
      vals.geoGH={
        x:st.vLng,
        y:st.vLat,
        w:10,
        h:10,
        z:0,
        t:0,
        rot:{x:0,y:0}
      }
      cl(vals.geoGH)
      this.initLLWH(vals.geoGH)
    }
//     cl(vals)
  }
  
  updDisp=(vals)=>{
// when the disp or anim values change, update the data in the dispVals object
    let st=this.state
//     cl(st)
//     cl(vals)
    let dispVals=Object.assign({},st.dispVals)
    if(!dispVals[st.eqSel]){dispVals[st.eqSel]={}}
    if(!("diType" in vals)){vals.diType="adism1"}
    if(!("dpSel" in vals)){vals.dpSel="e0inT"}
    Object.assign(dispVals[st.eqSel],vals)
//     Object.assign(dispVals,vals)
    cl(dispVals)
    this.setOurState("l388",{dispVals:dispVals})
  }
  
  godotSetNodeCol=async(nodePath,col)=>{
    let uri="/node"
    let method="update"
    let obj={type:"matOverride",albedo_color:col,nodePath:nodePath}
    let result=await gdRest.callGodot(uri,method,obj)
    
  }
  
  godotShowEqSel=async(nodePath)=>{
    if(this.nodeSel){
      this.godotSetNodeCol(this.nodeSel,[0,0,0])
    }
//     cl(nodePath)
    this.godotSetNodeCol(nodePath,[1,0.8,0])
    this.nodeSel=nodePath
  }
  
  
  getEqDisp=(vals)=>{
//     this.godotShowEqSel(vals.eqSel)
    let st=this.state
//     cl(st.dispVals)
//     cl(vals.eqSel)
    let eq=(st?.dispVals||{})[vals.eqSel]
//     cl(eq)
    Object.assign(vals,{
      diType:eq?.diType||"adism1",
      dpSel:eq?.dpSel||"e0inT",
    })
//     cl(vals)
  }
  
  drawGH=(proj)=>{// x,y is the lower left corner
// x,y *should* be the *upper* left
//     cl("draw greenhouse")
    let gh=Object.assign({},this.state.geoGH)
//     cl(gh)
    cl(gh.x)
    let dpm=1/(constant.METERS_PER_DEG)// degrees / meter
//     let dpp=proj.dLat// dLat is degrees / pixel
    let ppm=dpm/proj.dLat// dLat is degrees / pixel
//     cl(ppm)
    let h=gh.hgt*ppm//gh.hgt*dpm// h and w are both in degrees
    let w=gh.wid*ppm//(gh.wid/cosLat)*dpm// wid, hgt are in meters
//     cl(w,h)
    let pnt=this.mapLtoP(proj,gh.lat,gh.lng)// use lat,lng for the *center*
//     let cnt=this.mapPtoL(proj,scrHgt/2,scrWid/2)
    let cnt=this.map.getCenter().toJSON()
//     cl(cnt)
    let zoom=this.map.getZoom()
//     cl(cnt)
//     pnt.y-=h// shift to upper left
//     cl(pnt)
    if(
      (pnt.x>0)&&
      ((pnt.x)<scrWid)&&//+w
      (pnt.y>0)&&
      ((pnt.y)<scrHgt)//+h
    ){
//         cl("show it!")
        gh.x=pnt.x-(w/2)// these are all in pixels
        gh.y=pnt.y-(h/2)//scrHgt-pnt2.y-118.63995708465 - move to top
        gh.w=w//pnt2.x-pnt.x
        gh.h=h//pnt2.y-pnt.y
        cl("calc rot")
        gh.rot=this.calcRotAxes(gh)
//         cl(gh)
//         let x=gh.x
      }else{
        gh.x=-1000
      }
      gh.vLat=cnt.lat
      gh.vLng=cnt.lng
      gh.zoom=zoom
      cl(gh.x)
//       cl(gh.rot)
      this.setOurState("l442",{geoGH:gh,rotStep:this.proj.rotStep})
//     cl(this.state)
  }
  
  testDOMNavigation=(div)=>{
    cl(div)
    
  }
  
  getSiteImage=async()=>{
    cl("get site image")
//     let md=document.getElementById("MyMapDiv")
    let md=this.svgDiv.current
    this.testDOMNavigation(md)
//     cl(md)
//     let can=await html2canvas(md)
//     cl(can)
//     let data=can.toDataURL()
//     cl(data)
//     cl(md)
//     cl("should have canvas")
//     cl(can)
//     let link=this.linkRef.current
//     link.setAttribute('download', 'MintyPaper.png');
//     link.setAttribute('href', can.toDataURL("image/png").replace("image/png", 
//       "image/octet-stream"));
//     link.click();
  }
  
  showTree=async()=>{
    cl("show tree")
    let uri="/commands"
    let method="create"
    let body={
      cmd:"showTree",
    }
    await gdRest.callGodot(uri,method,body)
  }
  
  calcRotAxes=(gh)=>{
    let x=gh.x
    let y=gh.y
    let cosT=Math.cos(gh.t*constant.RAD_PER_DEG)
    let sinT=Math.sin(gh.t*constant.RAD_PER_DEG)
    let ppm=this.proj.ppm
    let rotX=x*cosT+y*sinT
    let rotY=0-x*sinT+y*cosT
    return{x:rotX,y:rotY,xm:rnd(rotX/ppm,100),ym:rnd(rotY/ppm,100)}// now, returns in m, *not* pixels
  }
  
  updateLocalZone=(gh)=>{
    let st=this.state
    let zoneInfo=this.zones.filter(z=>{return z.zoneId==st.zoneSel})[0]
    let ghInfo=this.makePermGeoGH(st.geoGH)
    zoneInfo.geoGH=ghInfo
//     cl(zoneInfo.geoGH)
//     cl(gh)
//     cl(zoneInfo)
//     cl(ghInfo)
    
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    var gh,latLng
//     cl(this.proj)
    var ppm=this.proj?.ppm||0
//     cl(ppm)
    let st=this.state
    // to handle the rotated movement:
//     let x=st.geoGH.x
//     let y=st.geoGH.y
//     let cosT=Math.cos(st.geoGH.t*constant.RAD_PER_DEG)
//     let sinT=Math.sin(st.geoGH.t*constant.RAD_PER_DEG)
//     let rotX=x*cosT+y*sinT
//     let rotY=0-x*sinT+y*cosT
    var dif,dx,dy,rot

    switch(type){
      case "upd":
        if(["diType","dpSel"].includes(Object.keys(vals)[0])){
          vals=Object.assign({
            diType:st.diType||"adism1",
            dpSel:st.dpSel||"e0inT",
          },vals)
          globs.events.publish("savePageEnable",true)
          this.updDisp(vals)
        }
        if("accountSel" in vals){
          this.sites=await this.loadSites(vals.accountSel)
          vals.siteSel=this.sites[0]?.siteId
//           cl(this.sites)
//           cl("account sel")
        }
        if("siteSel" in vals){
          this.zones=await this.loadZones(vals.siteSel)
          this.setSiteCenter()
          vals.zoneSel=this.zones[0]?.zoneId
        }
        if("zoneSel" in vals){
//           cl("now doing zoneSel")
          await this.selectZone(vals)//.zoneSel
//           await this.drawGH(this.proj)
        }
        if("eqSel" in vals){
//           cl("got equip sel")
          cl(st.dispVals)
          this.getEqDisp(vals)
//           let equip=st.eqSel// currently selected
//           let disps=st.dispVals
        }
//         cl(vals)
//         cl(vals.geoGH)
//         cl("set state")
//         cl(vals.geoGH)
        this.setOurState("l486",vals)
//         cl(this.state.geoGH)
//         this.testRtdEvents()
//         this.setOurState("l488",{geoGH:vals.geoGH})
//         cl(vals)
//         cl(this.state.geoGH)
        return
      case "mapClick":
        latLng=vals.e.latLng.toJSON()
        cl(latLng)
        let img="http://ngsg.link4cloud.com:3105/usa/images/redCirc.png"
        var latLng=new window.google.maps.LatLng(34,-118)
        let map=this.map
        let markIcon={
//           url:"http://ngsg.link4cloud.com:3105/usa/images/redCirc.png",
          url:"http://maps.google.com/mapfiles/ms/icons/green.png",
          scaledSize:new window.google.maps.Size(50, 50), 
//           anchor:new window.google.maps.Point(50,50),
//           origin:new window.google.maps.Point(0,-100),
          labelOrigin:new window.google.maps.Point(0,-20)
        }
        let icn=new window.google.maps.Marker(
          {
            position:latLng,
            icon:markIcon,//img,
            map:this.map,
            label: { color: '#00aaff', fontWeight: 'bold', fontSize: '24px', text: 'Your text here but what happens\n when it gets really verbose?' }
          })
        break
      case "mapLoaded":
        cl("map loaded")
//         cl(st?.geoGH)
//         cl(st)
        let bnd=this.map.getBounds()
        if(bnd){
          let ne=bnd.getNorthEast().toJSON()
          let sw=bnd.getSouthWest().toJSON()
          let dLat=(ne.lat-sw.lat)/scrHgt// deg per pixel
          let zm=Math.round(20+Math.log(dLat)/Math.log(2))
//           cl(zm)
          let rotStep=[.1,.2,.5, 1,2,3,5,10,20,50,100][zm]
//             1 2 4 8  16 32 64 128 256 512 1024
//             1 2 5 10 20 30 50 100 200 500 1000
//           cl(Math.log(dLat))
//           cl(rotStep)
//           let ppm=1/(dLat*constant.METERS_PER_DEG)
  //         let cent=this.mapPtoL(this.proj,scrWid/2,scrHgt/2)
          this.proj={
            lat0:sw.lat,
            lng0:sw.lng,
            dLat:dLat,
            dLng:(ne.lng-sw.lng)/scrWid,// dLat / per pixel
            ppm:1/(dLat*constant.METERS_PER_DEG),
            rotStep:rotStep,
          }
//           cl(this.proj)
          this.drawGH(this.proj)
//           cl([scrWid,scrHgt])
        }
        break
      case "centerZone":
        gh=Object.assign(st.geoGH,{x:0,y:0})
        this.setOurState("l516",{geoGH:gh})
        break
      case "showTree":
        this.showTree()
        break
      case "ghc":
//         cl("ghc")
        let dpm=1/(60*1852)// degrees / meter
//         let ppm=dpm/this.proj.dLat// dLat is degrees / pixel
        globs.events.publish("savePageEnable",true)
        gh=Object.assign({},st.geoGH)
        Object.assign(gh,vals)
//         cl(st)
//         cl(vals)
        let type=Object.keys(vals)[0]
//         cl(type)
        if(type=="wid"){
          let dw=vals.wid-st.geoGH.wid
          gh.x-=(dw*ppm/2)
        }
        if(type=="hgt"){
          let dh=vals.hgt-st.geoGH.hgt
          gh.y-=(dh*ppm/2)
        }
        if(["x","y","wid","hgt"].includes(type)){
//           cl(gh)
          this.xy2ll(gh)
        }else{
          let pnt=this.mapLtoP(this.proj,gh.lat,gh.lng)
          gh.x=pnt.x
          gh.y=pnt.y
          cl(pnt)
        }
        if(["vLng","vLat"].includes(type)){
          cl("cent")
          this.map.setCenter({lat:gh.vLat,lng:gh.vLng})
        }
        gh.rot=this.calcRotAxes(gh)
        this.updateLocalZone(gh)
        this.setOurState("l 540",{geoGH:gh})
        break
      case "goToZone":
        cl(vals)
        break
      case "getSiteImage":
        return this.getSiteImage()
      case "rotT":
        gh=Object.assign({},st.geoGH)
//         cl([rotX,vals.rotX])
        gh.t=vals.t
        this.setOurState("l 540",{geoGH:gh})
        break
      case "rotXY":
        globs.events.publish("savePageEnable",true)
        gh=Object.assign({},st.geoGH)
        rot=this.calcRotAxes(gh)
        Object.assign(rot,vals)
        rot.x=rot.xm*ppm
        rot.y=rot.ym*ppm
        gh.x=rot.x*Math.cos(st.geoGH.t*constant.RAD_PER_DEG)-
          rot.y*Math.sin(st.geoGH.t*constant.RAD_PER_DEG)
        gh.y=rot.x*Math.sin(st.geoGH.t*constant.RAD_PER_DEG)+
          rot.y*Math.cos(st.geoGH.t*constant.RAD_PER_DEG)
        Object.assign(gh.rot,vals)
        this.xy2ll(gh)
        this.updateLocalZone(gh)
        this.setOurState("l 540",{geoGH:gh})
        break
      default:
        break
    }
  }
  
  mapLtoP=(proj,lat,lng)=>{
    let p=proj
//     cl(proj)
//     cl(lat)
//     cl(lng)
//     cl(lng-p.lng0)
    let x=(lng-p.lng0)/p.dLng
    let y=scrHgt-(lat-p.lat0)/p.dLat
    return({x:x,y:y})
  }
  
  mapPtoL=(proj,x,y)=>{
//     let p=this.proj
    let p=proj
    if(!p){return {lat:0,lng:0}}
//     cl(p)
    let lat=p.lat0+(scrHgt-y)*p.dLat
    let lng=p.lng0+x*p.dLng
//     cl(p.lng0)
//     cl(p.dLng)
    return({lat:lat,lng:lng})
  }

  refresh=async ()=>{
//     cl("refresh")
    let ret=await gdRest.callGodot("/refresh", "update", "hello world")
    cl(ret)
  }
  
// [ext_resource path="res://assets/ghtest5.gltf" type="PackedScene" id=1]
// [ext_resource path="res://assets/brick1.glb" type="PackedScene" id=2]
  
  
  getSceneResources=(scn)=>{
//     cl(scn)
    let parts=scn.split("instance_placeholder=\"").slice(1)
    let res0={}
    parts.forEach(p=>{
      let resLine=p.split("\"")[0]
//       cl(resLine)
      res0[resLine]=1
//       let parts2=resLine.split(" ")
//       let ret=""
//       parts2.forEach(p=>{
//         cl(p)
//         if(p.substring(0,5)=="path="){
//           let len=p.length
//           ret="/"+p.substring(12,len-1)
//         }
//       })
//       return ret
    })
    let res=Object.keys(res0)
//     cl(res0)
//     cl(res)
    return res
  }
  
  getSceneRes=(res)=>{
    
  }
  
  getRes=async(res)=>{
    let parts=res.split("/").splice(2)
//     if(parts[0]=="scenes"){return getSceneRes}
    let path=parts.join("/")
    let src=makeGodotResourceUrl(path)
    var imp
    if(parts[0]=="assets"){
      let src2=`${src}.import`
      cl(src2)
      imp=await downloadFile(src2)// returns a Uint8Array
    }else{
      
    }
    cl(parts)
    cl(src)
    let cont=await downloadFile(src)
    return {cont:cont,imp:imp}
// http://ngsg.link4cloud.com:3105/usa/godot/assets/electric_fan.gltf    
    return await downloadFile(src)
  }
  
  downloadSceneResourcestoGodotO=async(res)=>{
    cl(res)
    for(let i=0;i<1/*res.length*/;i++){
      let r=res[i]
//     res.forEach(async r=>{
      let resInfo=await this.getRes(r)
//       cl(r)
//       cl(cont)
      let parts=r.split("/").splice(2)
      let dir=parts.slice(0,parts.length-1).join("/")
      let name=parts.slice(-1).join("/")
      let path=`${dir}/${name}`
      cl(dir)
      if(dir=="assets"){
        cl("sending import**************************")
        await resToGodot(`${path}.import`,"imp",resInfo.imp)
      }
      await resToGodot(path,"res",resInfo.cont)//dir,name,
    }//)
  }
  
  getImpScnPath=(imp)=>{
    let p1=imp.indexOf("[remap]")
    let p2=imp.indexOf("path=",p1)
    let p3=imp.indexOf("\n",p2)
    return imp.substring(p2+20,p3-4)// resName
//     let resName=
//     cl(resName)
//     return makeGodotResourceUrl(`assets/${resName}`)
//     cl(resName)
//     cl(imp.substring(p2+20,p3-1))
//     let parts=imp.substring(p2).split("\n")
//     cl(parts)
// res://.import/greenhouse2.glb-44b71b7f9acf7320da6bf67f0f000e28.scn    
  }
  
  downloadModelToGodot=async(res,parts)=>{
// this assumes that any model is represented by a 'pck' file in the same directory as the model *would* be,
// probably 'assets'
    cl("download Model")
    let path=parts.join("/")
    path=path.substr(0,path.lastIndexOf('.'))+'.pck'
    cl(path)//assets/greenhouse2.pck
    let src=makeGodotResourceUrl(path)
    let pck=await downloadFile(src)
    await resToGodot(path,"model",pck)
    
//     let imp=await downloadFile(src+".import")
//     
//     let resName=this.getImpScnPath(imp)// *without* the scn extension
//     let impScnPath=makeGodotResourceUrl(`assets/${resName}`)
//     let impScn=await downloadFile(impScnPath+"scn")
//     let impMd5=await downloadFile(impScnPath+"md5")
//     
//     
//     await resToGodot(path,"model",glb)
//     await resToGodot(path+".import","import",imp)
//     await resToGodot(".import/"+resName+"scn","importScn",impScn)
//     await resToGodot(".import/"+resName+"md5","importScn",impMd5)
//     cl("done")

//     resToGodot()
    
//     cl(imp)
//     cl(src)
    
  }
  
  downloadSceneToGodot=async(res,parts)=>{
// this needs to download the scene, *and* all the models in the scene
    cl("download Scene")
    let path=parts.join("/")
    let src=makeGodotResourceUrl(path)
    let scene=await downloadFile(src)
    let res2=this.getSceneResources(scene)// returns an array of models
    cl(res2)
    for(let i=0;i<res2.length;i++){
      let parts2=res2[i].split("/").splice(2)
      await this.downloadModelToGodot(res2[i],parts2)
    }
    await resToGodot(path,"zoneScene",scene)
//     cl(scene)
//     cl(src)
//     cl(res)
    
  }
  
//   downloadpackToGodot=async()
  
  downloadSceneResourcestoGodot=async(res)=>{
//     cl(res)
    // res: (2) ['res://scenes/gh2.tscn', 'res://assets/electric_fan.glb']
    for(let i=0;i<res.length;i++){
      let r=res[i]
      let parts=r.split("/").splice(2)
      let parts2=parts[1].split(".")
      let name=parts2[0]
      let path=`packs/${name}.pck`
      let src=makeGodotResourceUrl(path)
      let pack=await downloadFile(src)
      cl(`Downloaded ${path}, ${pack.length} bytes`)
      await resToGodot(path,"pack",pack)
    }
  }

/****************** Image Handling *******************************/
  sendImages=async(images)=>{// send new ones, right now
    let data = new FormData()
    images.forEach((img,i)=>{
//       cl(["send",img])
      data.append("type", "tscn")
      data.append(`id${i}`, img.id)
      data.append(`file${i}`, img.image)
    })
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    let r=await doGetPostBasic(url, method, data, type)// pro  mise
    let obj=await r.json()
//     cl(obj)
    return obj.txt
  }
  
  markImage=(e)=>{
    let images=[]
    for(let i=0;i<e.target.files.length;i++){
      let fi=e.target.files[i]
//       cl(e.target.files[i])
      let id=fi.name.split(".")[0]
//       cl(id)
      
      images.push({id: id/*getRandomString(16)*/, image: e.target.files[i]/*e.target.files[0]*/})
    }
    return images// only new images
  }
  
  setZoneScene=async(sceneId)=>{
    let st=this.state
//     cl(st)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "update", 
      sessionId: globs.userData.session.sessionId, body: {zoneId:st.zoneSel,sceneId:sceneId}})
  }
  
  setGodotZoneScene=async(sceneId)=>{
    let uri="/resource"
    let method="create"
    let resPath=`res://${sceneId}.tscn`//assets/
    let proto="http"
    let host="ngsg.link4cloud.com:3105"
    let src=`${proto}://${host}/usa/tscn/${sceneId[0]}/${sceneId[1]}/`+
      `${sceneId[2]}/${sceneId.substring(3)}.tscn`
    let data={resPath:resPath,src:src}
    cl(data)
//     let msg=JSON.stringify(data)
//     cl(uri,method,msg)
    gdRest.callGodot(uri,method,data)
  }
  
  setGodotViewMode=async()=>{
    let uri="/view"
    let method="update"
    let data={mode:"sceneEditor"}
    gdRest.callGodot(uri,method,data)
  }
  
//   downloadFile=async(url)=>{
//     let options={}
//     let res=await fetch(url,options)
//     let res2=await res.text()
//     return res2
// //     cl(res2)
//   }
  
//   makeGodotResourceUrl=(path)=>{
//     let proto="http"
//     let host="ngsg.link4cloud.com:3105"
//     return `${proto}://${host}/usa/godot/${path}`
//     
// //     zones/${id[0]}/${id[1]}/`+
// //       `${id[2]}/${id.substring(3)}.tscn`
//     
//   }
  
//   resToGodot=async(dir,name,contents)=>{
//     let uri="/resource"
//     let method="create"
//     let data={dir:dir,name:name,contents:contents}
//     return await gdRest.callGodot(uri,method,data)
//   }

  loadFromDb=(x)=>{
    
  }

  testFunc=async(arr)=>{
    var arr2=[]
    arr.forEach(async el=>{
      arr2.push(await this.loadFromDb(el))
    })
    console.log(arr2)
  }
  
  
  downloadZoneSceneToGodot=async(type,id,zoneSceneText)=>{
//     let buf=
    switch(type){
      case "scene":
//         let dir="scenes"
//         let name=`${id}.tscn`//assets/
        let uri="/resource"
        let method="create"
//         let path=`zones/${id[0]}/${id[1]}/${id[2]}/${id.substring(3)}.tscn`
        let gdPath=`scenes/${id}.tscn`
//         let src=makeGodotResourceUrl(path)
//         let res=await downloadFile(src)
//         cl(src)
//         cl(res)
//         let type="zone"
//         cl(res)
//         cl(zoneSceneText)
        let result=await resToGodot(gdPath,type,zoneSceneText)//dir,name,
//         let data={dir:dir,name:name,contents:res}
//         let result=await gdRest.callGodot(uri,method,data)
//         if(result.result=="OK"){
//           cl("go on")
//         }
        return result
    }
  }
  
  setGodotNode=async(type,obj)=>{
    switch(type){
      case "zoneScene":
      case "placeHolder":
        let uri="/node"
        let method="update"
        obj.type=type
        let result=await gdRest.callGodot(uri,method,obj)
//         cl(result)
        break
    }
  }
  
  getGodotTree=async()=>{
    let uri="/node"
    let method="retrieve"
    let obj={recurse:true,path:"/"}
//     cl(obj)
    let result=await gdRest.callGodot(uri,method,obj)
//     cl(result)
    return result.retData
  }
  
  setEquipModels=async(eq)=>{
    cl(eq)
//     return
    for(let i=0;i<eq.length;i++){// only 1 for now
      let e=eq[i]
//     }
//     eq.forEach(e=>{
//       cl(e)
      let uri="/node"
      let method="update"
//       let userRes=e.r.replace("res://","user://")
      let obj={type:"placeHolder",path:e.v,res:e.r}
      cl(obj)
      let result=await gdRest.callGodot(uri,method,obj)
    }//)
    
  }
  
  sendZonePack=async(sceneId)=>{
    let path="packs/electric_fan.pck"
    let src=makeGodotResourceUrl(path)
    cl(src)
    let pck=await downloadFile(src)
    cl(pck.length)
    await resToGodot(path,"pack",pck)
//     let nodePath=`res://scenes/${sceneId}.tscn`
//     this.setGodotNode("zoneScene",{path:nodePath})
  }
  
  saveZoneModels=async(resArr)=>{
    let st=this.state
    let resNames=resArr.map(ra=>{
      let parts=ra.split("/")
      let parts2=parts[3].split(".")
      return parts2[0]
    })
    let zoneId=st.zoneSel
    cl(resNames)
//     let res=await wsTrans("usa", {cmd: "cRest", uri: "/su/allZones", method: "update", 
//       sessionId: globs.userData.session.sessionId,
//       body: {zoneId:zoneId,godotPacks:resNames}})
    return resNames
  }
  
  movePacksToIDB=async(packs)=>{
//     let list=await ufs.list()
//     cl(list)
//     await ufs.set("/userfs/packs/electric_fan.pck",null)// delete
    let list=await ufs.list()
    cl(list)
//     return
    let gots=list.map(li=>{
      let parts=li.split("/")
      let parts2=(parts[3]?.split("."))||""
      return parts2[0]
    })
    let gotAll=true
    for(let i=0;i<packs.length;i++){
      let pack=packs[i]
      if(!gots.includes(pack)){
//         cl("add godot pack")
        let path1=`packs/${pack}.pck`
        let url=makeGodotResourceUrl(path1)
        let cont=await downloadFile(url)// returns a Uint8Array
        let oPath=`/userfs/packs/${pack}.pck`
        await ufs.set(oPath,cont)
        gotAll=false
      }
    }
    ufs.close()
  }
  
  updateImages=async(e)=>{// called by the files dialog
    if(this.state.godotLink){
      await this.setState({godotLink:""})
      cl(this.state.godotLink)
    }
    let images=this.markImage(e)
    let st=this.state
    if(images.length){
      let zoneSceneText=await this.sendImages(images)// saves the uploaded file
      let res=this.getSceneResources(zoneSceneText)
      let resNames=await this.saveZoneModels(res)// res is array of resources used
      await this.movePacksToIDB(resNames)
      this.setState({godotLink:"/godot/PhysicalView.html"})
      return
      await this.downloadSceneResourcestoGodot(res)// download the equipment, etc.
      cl("downloaded resources")
      let sceneId=images[0].id
      this.setZoneScene(sceneId)// save to db
      let result=await this.downloadZoneSceneToGodot("scene",sceneId,zoneSceneText)
      cl(result)
      if(result.result=="OK"){
        let path=`user://scenes/${sceneId}.tscn`
        this.setGodotNode("zoneScene",{path:path})
        let nodeTree=await this.getGodotTree()
        let ghPath="Root/Zone/Structure/Greenhouse"
        let ghNode=nodeTree.filter(n=>{return n.path==ghPath})[0]
        let obj={path:ghPath,res:ghNode.res}
        cl(obj)
        this.setGodotNode("placeHolder",obj)
//         cl(ghNode)
//         cl(nodeTree)
        let eq=this.getEqSeArray(nodeTree)
//         cl(eq)
        await this.setEquipModels(eq)// put the real equipment nodes in place
        let eqSel=Object.values(eq)[0].v
        let vals={eqSel:eqSel}
        this.getEqDisp(vals)
        this.setOurState("l 726",{nodeTree:nodeTree,eqSel:eqSel,diType:vals.diType,dpSel:vals.dpSel})
      }
    }
  }

/****************** End Image Handling *******************************/

  showImportFile=()=>{
//         <C18Button00 type="button" aria-label="upload" style={{
//           width:100, height:40, borderRadius:10,
//           border:1,borderStyle:"solid",borderColor:"#AAAAAA",
//           cursor:"pointer",
//         }}
//         onClick={e=>this.onChange("showTree")}
//         >Show Tree
//         </C18Button00>
    return(
      <div>
        <form>
        <C18Button00 type="button" aria-label="upload" style={{
          width:100, height:40, borderRadius:10,
          border:1,borderStyle:"solid",borderColor:"#AAAAAA",
          cursor:"pointer",
        }}>Import Scene
        </C18Button00>
        <C18Input00 type="file" multiple onChange={this.updateImages} style={{
//           position:"relative", 
          width:100, 
          height:40, 
          marginTop:0, 
          marginLeft:-100,
//           zIndex:10,                   
//           opacity:0,
          cursor: "pointer",
        }}/>
        </form>
      </div>
    )
  }

  showRefresh=()=>{
    return (
      <button id="godot-refresh" type="button" className="material-icons trash" aria-label="refresh godot"
        onClick={o=>this.refresh()}>
        sync
      </button>
    )
  }
  
  clickTest=()=>{
    cl("test2")
    let msg=JSON.stringify({cmd:"rest",src:"cloud",uri:"/test2",body:"The Text"})
    window.postMessage(msg)
  }
  
  showTest2=()=>{
    return(
      <button style={{padding:5,borderStyle:"solid",borderWidth:1,borderRadius:5,
        marginTop:10}}
        onClick={this.clickTest}
      type="button">Test2</button>
    )
  }

  showDrawing=()=> {
    let st=this.state
    if(st.godotLink){
      return (
        <iframe src={st.godotLink} width="1024" height="600"></iframe>
      )
    }
  } 
  
  showAccountSelect=()=>{
    let accounts=this.accounts.map(a=>{
      return {v:a.accountId,t:a.name}
    })
    return(
      <C18Select01 parms={{
        label:"Account",
        accountSel:this.state.accountSel,
        valueName:"accountSel",
        opts:accounts,
        onChange:this.onChange,
      }}/>
    )
  }
  
  showZoneSelect=()=>{
//     cl(globs)
//     cl(this.zones)
    let st=this.state
    let zones=this.zones/*globs.zonesInfo.info*/
    .filter(z=>{return z.siteId==st.siteSel})
    .map(a=>{
//       cl(a.zoneId)
      return {v:a.zoneId,t:a.zoneName}
    })
    return(
      <C18Select01 parms={{
        label:"Zone",
        zoneSel:this.state.zoneSel,
        valueName:"zoneSel",
        opts:zones,
        onChange:this.onChange,
      }}/>
    )
  }
  
  showSiteSelect=()=>{
//     cl(globs)
    let sites=this.sites/*globs.sitesInfo.info*/.map(a=>{
      return {v:a.siteId,t:a.name}
    })
    return(
      <C18Select01 parms={{
        label:"Site",
        accountSel:this.state.siteSel,
        valueName:"siteSel",
        opts:sites,
        onChange:this.onChange,
      }}/>
    )
  }
  
  showAccountSiteZone=()=>{
    return(
      <>
      <div className="custom-select">
        {this.showAccountSelect()}
      </div>
      <div className="custom-select">
        {this.showSiteSelect()}
      </div>
      <div className="custom-select">
        {this.showZoneSelect()}
      </div>
      </>
    )
  }
  
  getEqSeArray=(nodes)=>{// get the equipment, sensor array
//     cl(nodes)
//     let st=this.state
//     let nodes=st.nodeTree
    if(nodes.length<=0){return []}
    var dict={}
    nodes.forEach(n=>{dict[n.name]=n})
    let equipPath=dict["Equipment"]?.path
    let sensPath=dict["Sensors"]?.path
    let baseLen=(equipPath.match(/\//g)||[]).length
    let eqList=[]
    nodes.forEach(n=>{
//       cl(n.path)
      let cnt=(n.path.match(/\//g)||[]).length
      let indE=n.path.indexOf(equipPath)
      let indS=n.path.indexOf(sensPath)
      
      if(((indE==0)||(indS==0))&&(cnt==(baseLen+1))){
        eqList.push({v:n.path,t:n.name,r:n.res})
      }
    })
    return eqList
  }
  
  showSelectEquip=()=>{
//     cl("show Select")
    let st=this.state
    let nodes=st.nodeTree
    let eqList=this.getEqSeArray(nodes)
    if(eqList.length==0){return}
//     cl(eqList)
    return(
      <C18Select01 parms={{
        label:"Equipment / Sensor",
        valueName:"eqSel",
        opts:eqList,
        onChange:this.onChange,
      }}/>
    )
  }
  
  showDispType=()=>{
    let types=[
    {v:"adism1",t:"ADISM w/ Hum"},
    {v:"adism2",t:"ADISM w/ VPD"},
    {v:"eqOnO",t:"Eq On / Off"},
    {v:"eqProp",t:"Eq Proportional"},
    {v:"seTemp",t:"Sensor Temp"},
    {v:"seTempHum",t:"Sensor Temp / Hum"},
    {v:"seGP",t:"Sensor Gen Purpose"},
    ]
//     cl(this.state.diType)
    return(
      <C18Select01 parms={{
        label:"Display Type",
        diType:this.state.diType,
        valueName:"diType",
        opts:types,
        onChange:this.onChange,
      }}/>
    )
  }
  
  showDataPoints=(dpId)=>{
    let st=this.state
//     cl(st)
    let zoneInfo=this.zones.filter(z=>{return z.zoneId==st.zoneSel})[0]
    if(!zoneInfo){return}
    
//     cl(zoneInfo)
    let sensors=zoneInfo.sensorOrder
    if(!sensors){return}
    let dpOpts=sensors.map(se=>{return{
      v:se,t:se
    }})
    let eqOpts=Object.keys(st.channels).map(k=>{
      let ch=st.channels[k]
      return({v:`ch${az(+k+1,2)}pos`,t:`ch${az(+k+1,2)} ${ch.name} Position`})
    })
    let arr2=dpOpts.concat(eqOpts)
//     cl(arr2)
    return(
      <C18Select01 parms={{
        label:"Data Point",
        dpSel:st.dpSel,
        valueName:"dpSel",
        opts:arr2,
        onChange:this.onChange,
      }}/>
    )
  }
  
  showSensorName=()=>{
    let st=this.state
    if(["seGP"].includes(st.diType)){
      return(
        <div>
          <label htmlFor="sensorName">Data Point Name</label>
          <input id="sensorName" type="text"
          value={this.state.dpName}
          onChange={e=>this.onChange("upd",{dpName:e.currentTarget.value})}
          />
        </div>
      )
    }
  }
  
  md=e=>{
/* this is shared by all 3 controls on the GH div*/
    e.preventDefault()
    let gh=Object.assign({},this.state.geoGH)
//     cl(e.currentTarget.id)
//     cl(e.button)
//     cl(e.clientX)
    if(e.button==0){// left
      gh.startPos={x:e.clientX,y:e.clientY}
      gh.start=Object.assign({},gh)
      gh.drag=true
      gh.id=e.currentTarget.id
//       cl(gh)
      this.setOurState("l977",{geoGH:gh})
    }
  }
  
  mu=e=>{
//     cl("mouse up")
    let gh=Object.assign({},this.state.geoGH)
    gh.drag=false
//     cl(e.currentTarget.id)
    this.setOurState("l986",{geoGH:gh})
  }
  
  xy2ll=(gh)=>{
// x,y is the upper left corner
// gh is the geoGH object
// convert x,y,w,h to lng,lat,wid,hgt in degrees and meters
//     let x0=gh.x
//     let y0=scrHgt-(gh.y+gh.h)
    let dpm=1/(60*1852)// degrees / meter
    let ppm=dpm/this.proj.dLat// dLat is degrees / pixel
    
    let ll=this.mapPtoL(this.proj,gh.x+(gh.wid/2)*ppm,gh.y+(gh.hgt/2)*ppm)// latLng of lower left corner
    gh.lat=ll.lat
    gh.lng=ll.lng
    gh.w=gh.wid*ppm
    gh.h=gh.hgt*ppm
//     gh.wid=gh.w/ppm
//     gh.hgt=gh.h/ppm
//     let wid=w
    
//     let ll2=this.mapPtoL(gh.x+w,gh.y+h)
    
  }
  
  ll2xy=(gh)=>{
    
  }
  
  mm=e=>{
    let gh=Object.assign({},this.state.geoGH)
    if(gh.drag){
      globs.events.publish("savePageEnable",true)
      let dx=e.clientX-gh.startPos.x
      let dy=e.clientY-gh.startPos.y
      let th=gh.t*constant.RAD_PER_DEG
      let cost=Math.cos(th)
      let sint=Math.sin(th)
      let dx2=dx*cost+dy*sint
      let dy2=0-dx*sint+dy*cost
      switch(gh.id){
        case "xyDiv":
          gh.x=gh.start.x+dx
          gh.y=gh.start.y+dy
          break
        case "whDiv":
          let ppm=(1/(60*1852))/this.proj.dLat// dLat is degrees / pixel
          gh.x=gh.start.x-(dx2)
          gh.y=gh.start.y-(dy2)
          gh.wid=(gh.start.w+(2*dx2))/ppm
          gh.hgt=(gh.start.h+(2*dy2))/ppm
          break
        case "tDiv":
          gh.t=gh.start.t+dx/4
          break
      }
      this.xy2ll(gh)
      this.updateLocalZone(gh)
      this.setOurState("l1036",{geoGH:gh})
      let pos={x:e.clientX,y:e.clientY}
//       cl(pos)
    }
//     cl(e.currentTarget.id)
  }
  
  showGeoGHFields=()=>{
    let st=this.state
//     cl(st.geoGH)
    if(!this.proj){return null}
    let latLng=this.mapPtoL(this.proj,st.geoGH.x,st.geoGH.y)
    let latLng2=this.mapPtoL(this.proj,scrWid/2,scrHgt/2)
    let rot=this.calcRotAxes(st.geoGH)
    return(
      <table><tbody>
      <tr><td>
        <button type="button"
        style={{padding:10,borderStyle:"solid",borderWidth:1,marginBottom:10}}
        onClick={e=>this.onChange("goToZone")}
        >Go To Zone</button>
      </td>
      <td>
        <label>Center Longitude</label>
        <input type="number"
          value={st.geoGH.vLng||""}
          onChange={e=>this.onChange("ghc",{vLng:+e.currentTarget.value})}
        />
      </td>      
      <td>
        <label>Center Latitude</label>
        <input type="number"
          value={st.geoGH.vLat||""}
          onChange={e=>this.onChange("ghc",{vLat:+e.currentTarget.value})}
        />
      </td>      
      </tr>
      <tr><td>
        <button type="button"
        style={{padding:10,borderStyle:"solid",borderWidth:1,marginBottom:10}}
        onClick={e=>this.onChange("centerZone")}
        >Show Zone</button>
      </td><td></td><td></td></tr>
      <tr><td>
        <label>X Coord (pixels)</label>
        <input type="number"
          value={st.geoGH.x}
          onChange={e=>this.onChange("ghc",{x:+e.currentTarget.value})}
        />
      </td>
      <td>
        <label>Longitude</label>
        <input type="number"
          value={latLng.lng}
          onChange={e=>this.onChange("ghc",{lng:+e.currentTarget.value})}
        />
      </td>      
      <td>
        <label>Width (meters)</label>
        <input type="number"
          value={st.geoGH.wid}
          onChange={e=>this.onChange("ghc",{wid:+e.currentTarget.value})}
        />
      </td>      
      </tr>
      
      <tr><td>
        <br/>
        <label>Y Coord (pixels)</label>
        <input type="number"
          value={st.geoGH.y}
          onChange={e=>this.onChange("ghc",{y:+e.currentTarget.value})}
        />
      </td>
      <td>
        <label>Latitude</label>
        <input type="number"
          value={latLng.lat}
          onChange={e=>this.onChange("ghc",{lat:+e.currentTarget.value})}
        />
      </td>      
      <td>
        <label>Height (meters)</label>
        <input type="number"
          value={st.geoGH.hgt}
          onChange={e=>this.onChange("ghc",{hgt:+e.currentTarget.value})}
        />
      </td>      
      </tr>
      
      <tr><td>
        <br/>
        <label>Rot T (degrees CW)</label>
        <input type="number"
          value={st.geoGH.t}
          onChange={e=>this.onChange("rotT",{t:+e.currentTarget.value})}
        />
      </td>
      <td>
        <label>Rot X (meters)</label>
        <input type="number"
          step={st.rotStep}
          value={st.geoGH.rot.xm||0}
          onChange={e=>this.onChange("rotXY",{xm:+e.currentTarget.value})}
        />
      </td>      
      <td>
        <label>Rot Y (meters)</label>
        <input type="number"
          step={st.rotStep}
          value={st.geoGH.rot.ym||0}
          onChange={e=>this.onChange("rotXY",{ym:+e.currentTarget.value})}
        />
      </td>      
      </tr>

      <tr>
      <td>
        <br/>
        <button type="button"
        style={{padding:10,borderStyle:"solid",borderWidth:1,marginBottom:10}}
        onClick={e=>this.onChange("getSiteImage")}
        >Capture Site Image</button>
      </td>
      <td></td>
      <td></td>
      </tr>
      
      </tbody></table>
    )
    
  }
  
  showAllZones=()=>{
    let st=this.state
//     cl(st)
//     cl(this.zones)
    let zoneBoxes=[]
    this.zones.forEach((z,i)=>{
//       cl(z)
//       cl(z.zoneId, st.zoneSel)
      if((z.zoneId!=st.zoneSel) && z.geoGH){
//         cl(z)
        let gh=Object.assign({},z.geoGH)
        this.initLLWH(gh)
//         cl(gh)
        let rot=`rotate(${gh.t}deg)`
        if((gh.x>0)&&(gh.x<scrWid)&&(gh.y>0)&&(gh.y<scrHgt)){
          zoneBoxes.push(
            (
              <div key={i} style={{
                position:"absolute",
                left:gh.x,
                top:gh.y,// now y is the top
                width:gh.w,
                height:gh.h,
                transform:rot,
                borderStyle:"solid",
                borderWidth:3,
                opacity:0.5,
                backgroundColor:"#FFFFCC"}}>
              </div>
            )
          )
        }
      }
    })
    return(
      <div style={{position:"absolute",left:0,top:0}}>
      All Zones
      {zoneBoxes}
      </div>
    )
  }
  
  showGeoGH=()=>{// uses x,y,w,h,t naively to place div (left,top,wid,hgt,rot)
    let st=this.state
//     cl(st)
    let gh=st.geoGH
//     cl(gh.x)
//     cl(gh)
    let rot=`rotate(${st.geoGH.t}deg)`
    let cent={x:gh.w/2,y:gh.h/2}
    let quart={x:gh.w/2,y:gh.h/4}
//     cl(cent)
//         draggable="false"
    return(
      <div style={{
        position:"absolute",
        backgroundColor:"#EEEEEE",
        left:gh.x,
        top:gh.y,// now y is the top
        width:gh.w,
        height:gh.h,
        transform:rot,
        borderStyle:"solid",
        borderWidth:1,
      }}
        >
      <div style={{position:"absolute",width:10,height:10,right:0,bottom:0,backgroundColor:"#CCFFFF",
        cursor:"pointer",borderStyle:"solid",borderWidth:1}}
        id="whDiv"
        onMouseDown={this.md}
        >
      </div>
      <div style={{position:"absolute",width:10,height:10,left:cent.x-5,top:cent.y-5,backgroundColor:"#FFCCFF",
        cursor:"pointer",borderStyle:"solid",borderWidth:1}}
        id="xyDiv"
        onMouseDown={this.md}
        >
        </div>
      <div style={{position:"absolute",width:20,height:10,left:quart.x-10,top:0,
        backgroundColor:"#FFFFCC",cursor:"pointer",borderStyle:"solid",borderWidth:1}}
        id="tDiv"
        onMouseDown={this.md}
        >
      </div>
      </div>
    )
  }
  
  geoCoding=()=>{
//     cl(this.state.geoGH)
    const render = (status: Status) => {
      return <h1>{status}</h1>;
    };
//       draggable="false"
    return(
      <div style={{position:"relative",width:1024}}
      ref={this.mapDiv}
      id="MyMapDiv"
      >

        <Wrapper apiKey={"AIzaSyCXufgRra7tRE9leN2xIV5TW0CxMRt17HQ"} render={render}>
          <div  style={{width:1024,height:768,backgroundColor:"#FFEECC"}}
          ref={this.svgDiv} 
          >map is here</div>
        </Wrapper>
        {this.showAllZones()}
        {this.showGeoGH()}
        <div className="clearfloat"/><br/>        
        {this.showGeoGHFields()}
      </div>
    )
  }
  
  showEditDisp=()=>{
    let st=this.state
    if(st.nodeTree.length>0){
      return(
        <div>
          {this.showDispType()}
          {this.showDataPoints()}
          {this.showSensorName()}
        </div>
      )
    }
  }
  
  render(){
//     setTimeout(this.setMap,3000)
//     cl(window.google)
    let st=this.state
//     cl(st)
    if(st.loaded){
//         {this.showRefresh()}
//         {this.showTest2()}
      return (
        <div
          onMouseMove={this.mm}
          onMouseUp={this.mu}
        >
          {this.showAccountSiteZone()}
          {this.showImportFile()}
          <div className="clearfloat"/><br/>
          {this.showDrawing()}
          <div className="clearfloat"/><br/>
          {this.showSelectEquip()}
          {this.showEditDisp()}
          {this.geoCoding()}
          <a ref={this.linkRef}></a>
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

// <div style={{width:1000,height:750,backgroundColor:"#CCFFFF",borderStyle:"solid",
//           borderWidth:1,borderRadius:10
//         }}
//         >
//           Svg Editor
//         </div>
      
export default C18SvgEditor00;
