import React from 'react';
// import Alert from '@material-ui/lab/Alert';
// import Password from './Password';
import TextInput from './TextInput';
import OkCancel from './OkCancel';
// import UsaButton from './UsaButton';
// import {wsTrans, saveTokens, logout } from '../utils/utils';
import {cl, globs} from '../../components/utils/utils';
// import history from "../../history"
import {wsTrans} from '../utils/utils'
import {checkLoggedIn} from '../utils/utils';
import history from "../../history"

class SetSite extends React.Component{
  constructor(props) {
    super(props);
//     cl(globs.userData);
    this.state={
      siteId: globs.userData.session.siteId,
    }
    this.login()
  }
  
  login=async()=>{
    let logged=await checkLoggedIn()
    if(!logged)history.push("/usa/login")
  }

  setCurSite=()=>{
// this should set the value for *both* the user and the session
//for now, we'll do just the session
//     cl(this.state.siteId);
    let body={siteId: this.state.siteId};
    globs.userData.session.siteId = this.state.siteId
    let putSession={cmd: "cRest", uri: "/s/sessions", method: "update", sessionId: globs.userData.session.sessionId,
      body: body};
      wsTrans("usa", putSession).then(r=>{
        cl(r);
      });
  }
  
  setStateValue=(id, value)=>{
    let val={};
    val[id]=value;
    this.setState(val);
  }
  
  doResult=(result)=>{
    cl(result);
    if(result=="ok") this.setCurSite();
    this.props.done("setSiteDone");
  }
  
  render(){
    return(
      <div style={{position: "absolute", borderRadius: 20, textAlign: "center", left: 100, top: 100, 
        width:300, height: 180, backgroundColor: "bisque"}}>
      <h3>Set Site<OkCancel result={this.doResult}/></h3>
      <TextInput title="Site Id" valueId="siteId" onChange={this.setStateValue}
      initValue={this.state.siteId}/>
      </div>
    );
  }
}

export default SetSite ;

