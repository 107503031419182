import React from 'react';
import UsaIcon from '../UsaIcon';
import UsaSelect00 from '../UsaSelect00'
import config from '../../../components/utils/config'
import {loadZonesInfo,getZoneInfo,saveTable,getSiteName,getZoneName} from '../C18utils'
import {wsTrans} from '../../../usa/utils/utils'
import {cl,globs,az,getTime,getRandomString} from '../../../components/utils/utils';

const verticalSpace=<div style={{height: 10}}/>

class Recipes extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      recipeId: null,
//       gatewayId:getZoneInfo(props.parms.zone).gatewayId,
      name: "",
      color:"#FFFFFF",
      description: "",
      usage: "",
      notes: "",
      recipeOpts: {},
      recipeSel: null,
      loaded: false,
      editMode: false,
      recipeNutrientSel: -1,
      recipeNutrients:[],
//         {nutrientId: "bY91eSYM5byvttl$", concentration: "0.1"},
//         {nutrientId: "@OQ8EdIxNmxuH7xT", concentration: "0.2"},
//         {nutrientId: "yHcJOv76@k284RPa", concentration: "0.3"}
//       ],
      recipeNutrientId: 0,
      recipeNutrientConc: 0,
    }
    this.colors=[
      "#800000", "#804000", "#808000", "#408000", "#008000", "#008040", "#008080", "#004080", 
      "#000080", "#400080", "#800080", "#800040", "#404040", "#FF9999", "#FFCC99", "#FFFF99", 
      "#CCFF99", "#99FF99", "#99FFCC", "#99FFFF", "#99CCFF", "#9999FF", "#CC99FF", "#FF99FF", 
      "#FF99CC", "#CCCCCC" ]
    this.loadRecipes()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.savePage)
    if(this.props.parms.saveOK){this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})}
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
//     this.loadInfo()
    this.setBreadCrumbs()
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
  setBreadCrumbs=()=>{
    let p=this.props.parms
    let siteName=getSiteName(p.site)
    let zoneName=getZoneName(p.zone)
    if(p){
      p.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
              {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
              {t:"iDoser", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/idoser/nutrients`},
              {t:"Recipes", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/idoser/recipes`},
            ]},
        },
      )
    }
  }
  
  savePage=(cmd)=>{
    cl(cmd)
    if(cmd=="save"){
      this.saveRecipe()
      this.saveRecipeNutrients()
    }
  }
  
/*
 Recipe: name, description, usage, id
 nutes:
 recipeId, nutrientId, concentration
Recipes: create, edit, delete
list of nutes:
edit, delete, add
 */
  
  onChange=(type, vals, arg3, arg4, arg5)=>{
//     cl(type,vals)
//     cl(type)
//     cl(vals)
    var recipeNutes,recipeOpts,recipeNutrients,recipeId
    switch(type){
      case "recipeSel":
        this.setState(vals)
        break
      case "upd":
        globs.events.publish("savePageEnable",true)
        let recipes=Object.assign({},this.state.recipeOpts)
        Object.assign(recipes[this.state.recipeSel],vals)
        this.setState({recipeOpts:recipes})
//         cl(recipes)
        break
      case "recipeNute":
        cl(vals)
        globs.events.publish("savePageEnable",true)
        recipeNutes=this.state.recipeNutrients
        let nutrientIndex=vals.nutrientIndex
        delete vals.nutrientIndex
        Object.assign(recipeNutes[this.state.recipeSel][nutrientIndex],vals)
        this.setState({recipeNutrients:recipeNutes})
        break
      case "addNute":
        globs.events.publish("savePageEnable",true)
        recipeNutes=this.state.recipeNutrients
        recipeNutes[this.state.recipeSel].push({
            recipeId:this.state.recipeSel,
            nutrientId:this.nutrient0,
            concentration:0,
          })
        this.setState({recipeNutrients:recipeNutes})
        break
      case "deleteNute":
        globs.events.publish("savePageEnable",true)
        cl(type,vals)
        recipeNutes=this.state.recipeNutrients
        cl(recipeNutes[this.state.recipeSel])
        recipeNutes[this.state.recipeSel].splice(vals.nutrientIndex,1)
        this.setState({recipeNutrients:recipeNutes})
        break
      case "add":
        recipeOpts=Object.assign({},this.state.recipeOpts)
        let max=1
        Object.keys(recipeOpts).forEach(k=>{
          if(!isNaN(k)){
            cl(k)
            if(max<+k){max=+k}
          }
        })

        recipeId=+max+1//getRandomString(16)
        recipeOpts[recipeId]={
          description:"",
          name:"New Recipe",
          note:"",
          recipeId:recipeId,
//           siteId:globs.userData.session.siteId,
          gatewayId:this.gatewayId,
          usage:"",
        }
        recipeNutrients=Object.assign({},this.state.recipeNutrients)
        recipeNutrients[recipeId]=[{
            recipeId:recipeId,
            nutrientId:this.nutrient0,
            concentration:0,
        }]
        this.setState({recipeOpts:recipeOpts,recipeSel:recipeId,recipeNutrients:recipeNutrients})
        break
      case "delete":
        this.deleteRecipe()
        break
      case "recipe":
        this.setState(Object.assign({editMode: false},vals))
        break
      case "fields":
        this.setState(vals)
        break
      case "recipeNutes":
      case "recipeConc":
        cl(val)
        let val=arg4.currentTarget.value
        let valId=vals
        vals={}
        vals[valId]=val
        this.setState(vals)
//         cl([vals,arg3,val,arg5])
        break
//       case "color":
//         globs.events.publish("savePageEnable",true)
//         this.setState({color:this.colors[vals.index]})
//         cl(vals)
//         break
//         let val=arg4.currentTarget.value
//         let valId=vals
//         vals={}
//         vals[valId]=val
//         this.setState(vals)
//         break
    }
    
  }
  
  loadRecipes=async()=>{
    await loadZonesInfo()
    this.gatewayId=getZoneInfo(this.props.parms.zone).gatewayId
    let nuP=wsTrans("usa", {cmd: "cRest", uri: "/s/nutrients", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    let reP=wsTrans("usa", {cmd: "cRest", uri: "/s/recipes", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    let rnP=wsTrans("usa", {cmd: "cRest", uri: "/s/recipeNutrients", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    let [nuR,reR,rnR]= await Promise.all([nuP,reP,rnP])
//     let r=reR
    this.nutrients={}
    this.nuteOpts=[]
    nuR.data.forEach(n=>{
      this.nutrients[n.nutrientId]=n
      this.nuteOpts.push({v:n.nutrientId,t:n.name})
    })
    this.nutrient0=nuR.data[0]?.nutrientId
//     cl(rnR.data)
    let recipeNutrients={}
    rnR.data.forEach(rn=>{
      if(!recipeNutrients[rn.recipeId]){recipeNutrients[rn.recipeId]=[]}
      recipeNutrients[rn.recipeId].push(rn)
    })
    
    
//     cl(this.recipeNutrients)
    let recipes={}
    var recipeSel
    if(reR.data.length){
      recipeSel=reR.data[0].recipeId
      reR.data.forEach(r=>{
//         cl(r)
        delete r.t
        delete r["_id"]
        recipes[r.recipeId]=r
//         cl(r)
      })
      Object.keys(recipes).forEach(recipeId=>{
        if(!recipeNutrients[recipeId]){
          recipeNutrients[recipeId]=[{
            recipeId:recipeId,
            nutrientId:this.nutrient0,
            concentration:0,
          }]
        }
      })
//       cl("done")
    }else{
//       recipes={none:{t:"No Recipes"}}
//       recipeSel="none"
    }
//     cl(recipeNutrients)
    this.setState({recipeOpts: recipes, recipeSel: recipeSel, recipeNutrients:recipeNutrients, loaded: true})
//     cl(this.state.recipeNutrients)
  }
  
  getARecipe=(recipeId)=>{// needs to get the recipeNutrients, too
//     cl(this.recipeNutrients[recipeId])
    let rn=this.state.recipeNutrients[recipeId]
    let nutes=[]
//     Object.keys(this.recipeNutrients[recipeId]).forEach(k=>
    for (let k in rn){
//       cl(k)
      nutes.push({nutrientId: k, concentration: rn[k]})
    }
//     cl(nutes)
    this.setState(
      Object.assign({editMode: true, recipeNutrients: nutes/*this.recipe*/},this.state.recipeOpts[recipeId]))
  }
  
  newRecipe=()=>{
    this.setState({
      recipeId: getRandomString(16),
      name:"",
      description:"",
      usage:"",
      notes:"",
      editMode:true,
    })
  }
  
  saveRecipeNutrientsO=async(recipeId)=>{
/* this.state.recipeNutrients is an array of objects: {nutrientId: concentration:}
this.recipeNutrients is an object with recipeId as the key,
then, an object with nutrientId as the key
*/
//     cl(this.state)
    let nuteArr=this.state.recipeNutrients.slice(0)// copy of the current array
    let nutes=[]
    let nutes1={}
    nuteArr.forEach(n=>{
      nutes.push({recipeId: recipeId, nutrientId: n.nutrientId, concentration: n.concentration})
      nutes1[n.nutrientId]=n.concentration
    })
    cl(nutes1)
    cl(nutes)
    this.recipeNutrients[recipeId]=nutes1
// //     this.recipeNutrients[recipeId]
//     for(let k in nuteObj){
//     }
//     cl(nutes)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/recipeNutrients", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {recipeId: recipeId, gatewayId:this.gatewayId,nutes: nutes}})
  }
  
  saveRecipeO=async()=>{
    let s=this.state
    let recipes=Object.assign(this.state.recipeOpts)
    recipes[s.recipeId]={
      recipeId: s.recipeId,
      name:s.name,
      t:s.name,
      description:s.description,
      usage:s.usage,
      notes:s.notes,
    }
    this.setState({recipeOpts: recipes, editMode: false})
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/recipes", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: recipes[s.recipeId]})
    this.saveRecipeNutrients(s.recipeId)
  }
  
  saveRecipe=async()=>{
//     this.saveTable("recipes")
//     return
    globs.events.publish("savePageEnable",false)
    cl(this.state.recipeOpts[this.state.recipeSel])
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/recipes", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: this.state.recipeOpts[this.state.recipeSel]})
    await saveTable("recipes",this.gatewayId)
    await saveTable("recipeNutrients",this.gatewayId)
  }
  
  saveRecipeNutrients=async()=>{
    let nutes=[]
    let recipeId=+this.state.recipeSel
    this.state.recipeNutrients[recipeId].forEach(n=>{
      nutes.push({accountId:globs.userData.session.accountId,gatewayId:this.gatewayId,recipeId: recipeId, 
        nutrientId: +n.nutrientId, concentration: +n.concentration})
//       nutes1[n.nutrientId]=n.concentration
    })
    cl(nutes)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/recipeNutrients", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {recipeId: recipeId,gatewayId:this.gatewayId,nutes: nutes}})
    globs.events.publish("saveOK",true)
  }
  
//   saveRecipeNutrient=async(nute)=>{
//     let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/recipeNutrients", method: "update", 
//       sessionId: globs.userData.session.sessionId,
//       body: {recipeId: this.state.recipeId, nutrientId: nute.nutrientId, concentration: nute.concentration}})
//   }
//   
  deleteRecipe=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Recipe?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let recipeId=this.state.recipeSel
      let recipeOpts=Object.assign({},this.state.recipeOpts)
      let recipeNutrients=Object.assign({},this.state.recipeNutrients)
      delete recipeOpts[recipeId]
      delete recipeNutrients[recipeId]

  //     let recipes=Object.assign(this.state.recipeOpts)
  //     delete recipes[this.state.recipeSel]
  //     this.setState({recipeOpts: recipes})
      wsTrans("usa", {cmd: "cRest", uri: "/s/recipes", method: "delete", 
        sessionId: globs.userData.session.sessionId,
        body: {recipeId: +this.state.recipeSel,gatewayId:this.gatewayId}})
      wsTrans("usa", {cmd: "cRest", uri: "/s/recipeNutrients", method: "delete", 
        sessionId: globs.userData.session.sessionId,
        body: {recipeId: +this.state.recipeSel,gatewayId:this.gatewayId}})
      this.setState({recipeSel:Object.keys(recipeOpts)[0],recipeOpts:recipeOpts,recipeNutrients:recipeNutrients})
    }
      

  }
  
//   showRecipeSelect=()=>{
//     if(Object.keys(this.state.recipeOpts).length){
//       return(
//         <UsaSelect00 parms={{
//           title: "Select\u00A0Recipe",
//           select: true,
//           inline: true,
//           valueId: "recipeSel",
//           value: this.state.recipeSel,
//           opts: this.state.recipeOpts,
//           onChange: x=>this.onChange("recipe",x)
//         }}
//         />
//       )
//     }else{
//       return null
//     }
//   }
  
  showName=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Name",
        width: 400,
        select: false, // !this.state.editMode,
        valueId: "name",//valueId, // "widgetSel",
        value: this.state.name, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
    )
  }
  
  showDescription=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Description",
        multiline: true,
        rowsMax: 8,
        width: 400,
        select: false, // !this.state.editMode,
        valueId: "description",//valueId, // "widgetSel",
        value: this.state.description, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
    )
  }
  
  showUsage=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Usage",
        multiline: true,
        rowsMax: 8,
        width: 400,
        select: false,
        valueId: "usage",
        value: this.state.usage,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
    )
  }
  
  showNotes=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Notes",
        multiline: true,
        rowsMax: 8,
        width: 400,
        select: false, // !this.state.editMode,
        valueId: "notes",//valueId, // "widgetSel",
        value: this.state.notes, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
    )
  }
  
  nuteResult=(type)=>{
    let parts=type.split("-")
    let id=+parts[0].substr(4)
    var nutes
    switch(parts[1]){
      case "OK":
        nutes=this.state.recipeNutrients.slice(0)
        let nute={
          nutrientId: this.state.recipeNutrientId,
          concentration: this.state.recipeNutrientConc
        }
//         this.saveRecipeNutrient(nute)
        nutes[id]=nute
        this.setState({recipeNutrientSel: -1, recipeNutrients: nutes})
        break
      case "Cancel":
        this.setState({recipeNutrientSel: -1})
        break
      case "Add":
        nutes=this.state.recipeNutrients.slice(0)
//         cl(this.nuteOpts)
        nutes.splice(id,0,{
          nutrientId: this.nuteOpts[0].v,
          gatewayId:this.gatewayId,
          concentration: 0
        })
//         cl(nutes)
        this.setState({recipeNutrientSel: id, recipeNutrientId: this.nuteOpts[0].v, recipeNutrients: nutes})
        break
      case "Delete":
        nutes=this.state.recipeNutrients.slice(0)
        nutes.splice(id,1)
        this.setState({recipeNutrientSel: -1, recipeNutrients: nutes})
        break
      case "Edit":
        let recipeNutrientId=this.state.recipeNutrients[id].nutrientId
        let recipeNutrientConc=this.state.recipeNutrients[id].concentration
        this.setState({recipeNutrientSel: id, recipeNutrientId: recipeNutrientId, recipeNutrientConc: recipeNutrientConc})
        break
      case "Delete":
        break
      default:
        break
    }
  }
  
  result=(type)=>{
    if(type.substr(0,4)=="Nute"){return this.nuteResult(type)}
    cl(type)
    switch(type){
      case "Recipes-OK":
        this.saveRecipe()
        break
      case "Recipes-Cancel":
        this.setState({editMode: false})
        break
      case "Recipes-Edit":
        this.getARecipe(this.state.recipeSel)
        break
      case "Recipes-Add":
        this.newRecipe()
        break
      case "Recipes-Delete":
        this.deleteRecipe()
        break
      default:
        break
    }
  }
  
  makeOptions=(opts)=>{
    return opts.map((o,i)=>{
      return (
        <option key={i} value={o.v}>{o.t}</option>
      )
    })
  }
  
  showSelect=(type, val,valId,ind,opts)=>{
    cl(val)
    return(
      <select style={{marginLeft: "0.3em",}}
        value={val} 
        disabled={!this.props.parms.saveOK}
        onChange={e=>this.onChange(type, valId,ind,e)}
      >
      {this.makeOptions(opts)}
      </select>
    )
  }
  
  showInput=(type, val, valId, ind)=>{
    return(
      <input
        value={val}
        type="number"
        onChange={e=>this.onChange(type, valId, ind, e)}
      />
    )
  }
  
  showNuteSelection=(nutrientId)=>{
    return this.showSelect("recipeNutes", this.state.recipeNutrientId, "recipeNutrientId",0,this.nuteOpts)
  }
  
  showNuteConcentration=()=>{
    return this.showInput("recipeConc",this.state.recipeNutrientConc, "recipeNutrientConc",0)
  }
  
  showNuteLine=(name, nutrientId, concentration, i)=>{
    if(this.state.recipeNutrientSel==i){
      return <>
      <td>
        {this.showNuteSelection(nutrientId)}
      </td><td>
        {this.showNuteConcentration()}
      </td>
      </>
    }else{
      return(
        <><td>{name}</td><td>{`${concentration} ml/Gal`}</td></>
      )
    }
  }
  
  showNuteIcons=(i)=>{
    if(this.state.recipeNutrientSel==i){
      return(
        <>
          <td>
            <UsaIcon icon={`Nute${i}-OK`} result={this.result} inline/>
          </td>
          <td>
            <UsaIcon icon={`Nute${i}-Cancel`} result={this.result} inline/>
          </td>
          <td>
          </td>
        </>
      )
    }else{
      return(
        <>
          <td>
            <UsaIcon icon={`Nute${i}-Add`} result={this.result} inline/>
          </td>
          <td>
            <UsaIcon icon={`Nute${i}-Edit`} result={this.result} inline/>
          </td>
          <td>
            <UsaIcon icon={`Nute${i}-Delete`} result={this.result} inline/>
          </td>
        </>
      )
    }
  }
  
//   showNutrientTable=()=>{
//     if(this.state.recipeNutrients.length){
//       return(
//       )
//     }else{return null}
//   }

  showNuteAdd=()=>{
    let i=this.state.recipeNutrients.length
    return(
      <tr>
      <td>
        <UsaIcon icon={`Nute${i}-Add`} result={this.result} inline/>
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
    )
  }
  
  showNutrients=()=>{
    cl(this.state)
    return(
      <div>
      <h3>Nutrient Concentrations</h3>
        <table><tbody>
        {this.state.recipeNutrients.map((n,i)=>{
  //         cl(n)
          let name=this.nutrients[n.nutrientId].name
          return(
            <tr key={i}>
            {this.showNuteIcons(i)}
            {this.showNuteLine(name, n.nutrientId, n.concentration, i)}
            </tr>
          )
        })}
        {this.showNuteAdd()}
        </tbody></table>
      </div>
    )
  }
  
  showEditFields=()=>{
    if(this.state.editMode){
      return(
      <div>
        {verticalSpace}
        {this.showName()}
        {verticalSpace}
        {this.showDescription()}
        {verticalSpace}
        {this.showUsage()}
        {verticalSpace}
        {this.showNotes()}
        {verticalSpace}
        {this.showNutrients()}
        
      </div>
      )
    }else{
      return null
    }
  }
  
  showMainIcons=()=>{
    if(this.state.editMode){
      return([
        <UsaIcon key={0} space="0.5" icon="Recipes-OK" result={this.result} inline/>,
        <UsaIcon key={1} icon="Recipes-Cancel" result={this.result} inline/>,
      ])
    }else{
      return([
        <UsaIcon key={0} space="0.5" icon="Recipes-Add" result={this.result} inline/>,
        <UsaIcon key={1} icon="Recipes-Edit" result={this.result} inline/>,
        <UsaIcon key={2} icon="Recipes-Delete" result={this.result} inline/>,
      ])
    }
  }
  
  renderO(){
    if(this.state.loaded){
      return(
        <div style={{padding: 10}}>
        <h3>Recipes
        {this.showMainIcons()}
        </h3>
        {this.showRecipeSelect()}
        {this.showEditFields()}
        
        </div>
      )
    }else{
      return <div>loading...</div>
    }
  }
  
  showRecipeSelect=()=>{
//     cl(this.state)
    return(
      <span className="custom-select">
        <label htmlFor="idoser-recipe">Select Recipe</label>
        <select id="idoser-recipe"
          value={this.state.recipeSel}
          onChange={e=>this.onChange("recipeSel",{recipeSel:+e.currentTarget.value})}
        >
        {Object.keys(this.state.recipeOpts).map((recipeId,i)=>{
          return(
            <option key={i} value={recipeId}>{this.state.recipeOpts[recipeId].name}</option>
          )
        })}
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }
  
  showNuteSelect=(first,nutrientIndex,id)=>{
/* the recipe nutrients are stored in this.
nutrientIndex is the location in the array of nutrients stored in this.recipeNutrients[this.state.recipeSel]*/
//     cl(this.nutrients)
    let nute=this.state.recipeNutrients[this.state.recipeSel][nutrientIndex]
//     cl(this.state.recipeNutrients[this.state.recipeSel])
//     cl(nutrientIndex)
//     cl(nute)
    return(
          <span className="custom-select floatleft">
            {first&&<label>Select Nutrient</label>}
            <select
              value={nute.nutrientId}
              disabled={!this.props.parms.saveOK}
              onChange={e=>this.onChange("recipeNute",{nutrientIndex:nutrientIndex,nutrientId:e.currentTarget.value})}
            >
            {Object.keys(this.nutrients).map((nutrientId,i)=>{
              return(
                <option key={i} value={nutrientId}>{this.nutrients[nutrientId].name}</option>
              )
            })}
            </select>
            <span className="material-icons down-arrow">
              keyboard_arrow_down
            </span>
          </span>
    )
  }
  
  nutrientConcLine=(first,nutrientIndex,id,conc)=>{
    let nute=this.state.recipeNutrients[this.state.recipeSel][nutrientIndex]
//     cl(nute)
    return(
        <div key={nutrientIndex} className="line">
        {this.showNuteSelect(first,nutrientIndex,id)}

          {first&&<label>Amount (ml/Gal)</label>}
          <input type="number" className="alignbottom" min="0" 
          value={nute.concentration/100}
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("recipeNute",{nutrientIndex:+nutrientIndex,concentration:Math.round(+e.currentTarget.value*100)})}
          />
          <button type="button" className="material-icons trash" aria-label="delete nutrient"
          disabled={!this.props.parms.saveOK}
          onClick={e=>this.onChange("deleteNute",{nutrientIndex:nutrientIndex})}
          >
            delete_outline
          </button>
        </div>

      
    )
  }
  
  showNuteConcentrations=()=>{
//     cl(this.recipeNutrients)
    if(this.state.recipeNutrients[this.state.recipeSel]){
      return(
        <div>
          <h2>Nutrient Concentrations</h2>
          {Object.keys(this.state.recipeNutrients[this.state.recipeSel]).map((n,nutrientIndex)=>{
  //           cl(n.nutrientId)
            return this.nutrientConcLine(true,nutrientIndex,n.nutrientId, n.concentration)
          })}
          <button className="material-icons-outlined add" aria-label="add nutrient"
            disabled={!this.props.parms.saveOK}
            onClick={e=>this.onChange("addNute")}
          >
            add
          </button>
        </div>
        
      )
    }
  }
  
  showRecipeColor=(recipe)=>{
    let st=this.state
//     cl(this.state)
    let rng=[...Array(13).keys()]
    return(
      <div>
        <label>Color</label>
        <table><tbody>
        <tr><td width="30">
          <div style={{width:30,height:30,backgroundColor:recipe.color}}/>
          </td>
          <td>
            <table style={{width:130}}><tbody>
            {
            [...Array(2).keys()].map(ro=>{
//               cl(ro)
              return(
                <tr key={ro} width="130">
                {rng.map(i=>{
                  return(
                    <td key={i} width="10"><div style={{width:10,height:10,
                      cursor:"pointer",
                      backgroundColor:this.colors[13*ro+i]}}
                      onClick={e=>{this.onChange("upd",{color:this.colors[13*ro+i]})}}
                    />
                    </td>
                  )
                })}
                </tr>
              )
            })
              
            }
            </tbody></table>
          </td>
        </tr>
        </tbody></table>
        
        <br/>
      </div>
    )
  }
  
  showRecipeEdit=()=>{
//     cl(this.state)
    let dis=!(Object.keys(this.state.recipeOpts).length||0)
//     cl(dis)
    let r=this.state.recipeOpts[this.state.recipeSel]||{name:"",description:"",usage:"",notes:""}
    return(
      <div>
        <label htmlFor="idoser-name">Name</label>
        <input id="idoser-name" type="text" 
        value={r?.name}
        disabled={dis||!this.props.parms.saveOK}
        onChange={e=>this.onChange("upd",{name:e.currentTarget.value})}
        />

        <br />
        
        {this.showRecipeColor(r)}

        <label htmlFor="idoser-description">Description</label>
        <textarea id="idoser-description"
        value={r?.description}
        disabled={dis||!this.props.parms.saveOK}
        onChange={e=>this.onChange("upd",{description:e.currentTarget.value})}
        />

        <br />

        <label htmlFor="idoser-usage">Usage</label>
        <textarea id="idoser-usage" 
        value={r?.usage}
        disabled={dis||!this.props.parms.saveOK}
        onChange={e=>this.onChange("upd",{usage:e.currentTarget.value})}
        />
        <br />

        <label htmlFor="idoser-notes">Notes</label>
        <textarea id="idoser-notes" 
        value={r?.notes}
        disabled={dis||!this.props.parms.saveOK}
        onChange={e=>this.onChange("upd",{notes:e.currentTarget.value})}
        />

        <br />
        {this.showNuteConcentrations()}
      </div>
    )
  }
  
  render(){
//     cl(this.state)
    if(this.state.loaded){
      return(
        <div id="iDoser_recipes">
          <div className="clearfloat"></div>
        {this.showRecipeSelect()}
        <button type="button" className="material-icons trash after-selector" aria-label="delete nutrient"
        disabled={!this.props.parms.saveOK}
        onClick={e=>this.onChange("delete")}>
          delete_outline
        </button>

        <button type="button" className="material-icons-outlined add after-selector" aria-label="add nutrient"
        disabled={!this.props.parms.saveOK}
        onClick={e=>this.onChange("add")}>
          add
        </button>

        <div className="clearfloat"></div>
        {(config.server=="dev")&&
          <button onClick={()=>{
            this.saveTable("schedules")
          }}>Test Send</button>
        }
        <br /><hr /><br />
        {this.showRecipeEdit()}
        </div>

      )
    }else{return<div>loading. . .</div>}
  }
}

export default Recipes ;

