import React from 'react';
import Select18 from './Select18';
import FUISelect from './FUISelect';
import history from "../../history"
import {cl, globs} from '../../components/utils/utils';

class EcphTankSensor extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.zuci = this.props.zuci.split('-');
    // cl(this.zuci)
    // cl(this.zuci[2]*1 - 192)
    let chan = this.zuci[2]*1;
    if (chan === 0) chan = 192;
    this.state = {
      tank: chan - 192,
      input: chan,
      sensor: this.zuci[3]*1,
      setpoint: this.zuci[3]*1,
    }
    this.props.getValue(props.ind, {type: "local"})
    // cl(this.zuci);
  }

/*this is going to open many cans of worms!
on the pages that this controls, the tank is going
to be identified by the channel of that page
so the controls are going to have to know that

my question right now is how the controls on an ecph
page are going to know what they're referring to

the problem that I have now is to get the entire fui
page to refresh, when the url has changed. this is not
an issue when the router loads a whole new page,
but is when the *details* on the page change

when the url changes, the props change to the fui page
liveFui should have all the information that it needs
to update its "values" array.


*/

  onChange=(e)=>{
    this.props.onChange(this.props.ind,
      {value: e.target.value})
  }

  getValue18=(ind, type)=>{
    // cl(type)

  }

  onChange18=(ind, val)=>{
    cl([ind, val])
    cl(this.props)
    cl(this.state)
// ind = 0 is tank, 1 is sensor
//     cl(this.props)
//     cl(ind);
    // cl(val);
    // let zuci = "-".join(this.zuci);
//     let zuci = this.props.zuci.slice(0);
    let zuci = this.props.zuci.split('-');
    switch(this.props.custom.type){
      case "setpoint":
        zuci[3]=val.value
        this.setState({setpoint: val.value})
        break
      case "input":
        zuci[2]=val.value
        this.setState({input: val.value})
        break
      default:// for tank and sensor
        if (ind == 0){
          zuci[2] = 192 + val.value;
          this.setState({tank: val.value})
        } else {
          zuci[3] = val.value;
          this.setState({sensor: val.value})
        }
        break
    }
    let zuciStr = zuci.join("-");
    // let zuci = this.zuci.join("-");
    // cl(zuciStr)
    // cl(this.zuci)
    let url = "/usa/c18/fui/" + this.props.type + "/" + zuciStr;
    history.replace(url)
    // cl(url)
    this.props.onChange(this.props.ind,
      {value: val.value,noChange:true})
    
  }

  showTankSelect=()=>{
    return(
      <div>
        <Select18
          getValue={this.getValue18}
          onChange={this.onChange18}
          current={this.props.current}
          value={this.state.tank}
          ind={0}
          custom={{
            type: "tanks",
          }}
          cont={{
            title: "Tank Name",
          }}
          />
      </div>
    );
  }

  showSetpointSelect=()=>{
    return(
      <div>
        <Select18
          getValue={this.getValue18}
          onChange={this.onChange18}
          current={this.props.current}
          value={this.state.setpoint}
          saveOK={this.props.saveOK}
          ind={0}
          custom={{
            type: "setpoints",
          }}
          cont={{
            title: "Setpoint",
          }}
          />
      </div>
    );
  }

  showInputSelect=()=>{
    return(
      <div>
        <Select18
          getValue={this.getValue18}
          onChange={this.onChange18}
          current={this.props.current}
          value={this.state.input}
          saveOK={this.props.saveOK}
          ind={0}
          custom={{
            type: "inputs",
          }}
          cont={{
            title: "Input",
          }}
          />
      </div>
    );
  }

  showSensorSelect=()=>{
//     cl("show sensor")
    return(
      <FUISelect
      getValue={this.getValue18}
      onChange={this.onChange18}
      current={this.props.current}
      value={this.state.sensor}
      saveOK={this.props.saveOK}
      ind={1}
      custom={{
        type: "tanks",
        options: [
          {v:0, t:1},
          {v:1, t:2},
          {v:2, t:3},
        ]
      }}
      cont={{
        title: "Sensor",
      }}
      />
    );
  }

  render(){
//     cl(this.state)
    // cl(this.props);
    // cl(this.props.custom)
    switch(this.props.custom.type){
      case "setpoint":
        return this.showSetpointSelect();
      case "input":
        return this.showInputSelect();
      default:
        return this.showSensorSelect();
        
    }
//     if(this.props.custom.type === "setpoint"){
//       return this.showSetpointSelect();
//     }else{
//       return this.showSensorSelect();
//     }
  }
}

  export default EcphTankSensor ;
