import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
// import InputLabel from '@material-ui/core/InputLabel';
// import Input from "@material-ui/core/Input";
// import InputAdornment from '@material-ui/core/InputAdornment';
// import IconButton from '@material-ui/core/IconButton';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import {cl} from '../../components/utils/utils';

class OkCancel extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      passwordType: "password",
      value: "",
    }
  }
  
  render(){
    return(
      <>
      <IconButton onClick={e=>this.props.result("ok")} title="OK">
      <Check/>
      </IconButton>
      <IconButton onClick={e=>this.props.result("cancel")} title="Cancel">
      <Clear/>
      </IconButton>
      </>
    );
  }
}

export default OkCancel;
