import React from 'react';
import {wsTrans} from '../utils/utils'
import {cl, globs,constant,dateToYrMoDa,dateToLocalHrMinSecString, setRestTimeout, getTime} from '../../components/utils/utils';

class TestReportWriter extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      clientId: "31373730353851040023004a",
      loaded: false,
    }
    this.loadReport()
  }
  
  loadReport=async()=>{
  let reportId="AqEU3-v7a25fNSuW"
  let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/reportWriter", method: "retrieve", 
    sessionId: globs.userData.session.sessionId,
    body: {reportId:reportId,fromItem:0,toItem:0}})
  cl(r.data)
  }
  
  render(){
    return(
      <div>Test</div>
    )
  }
}

export default TestReportWriter ;

