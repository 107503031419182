import React from 'react';
import C18MuiFormControl from '../../usa/components/C18MuiFormControl'
import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
import C18MuiMenuItem from '../../usa/components/C18MuiMenuItem'
import C18MuiSelect from '../../usa/components/C18MuiSelect'
import {chTypes} from '../utilsFui';
import {dbVals} from '../../components/utils/http'
import {cl, globs} from '../../components/utils/utils';
import {getParamId} from '../../usa/utils/utils'

class SelectUnit extends React.Component{


  constructor(props) {
    super(props);
//     cl(props)
    this.state={
    }
    this.props.getValue(props.ind, {type: "selectUnit"})
  }
  
  onChange=async(e)=>{
//     let type = e.target.value;
//     cl(e)
//     cl(this.props.type)
//     cl(globs.fuiPages[this.props.type].title)
    let vo = {value: {
        type: "selectUnit", 
        title: globs.fuiPages[this.props.type].title,
        unit: e.target.value,
        chType:this.props.type,
      },
      noChange:true
    }//, noChange:true
//       cl(vo)
    await this.props.onChange(this.props.ind, vo);
    globs.events.publish("updateFui")
  }
  
  makeUnitSelect=()=>{
//     cl(this.props.current.zoneControllers)
    let opts=[]
    for(let i=0;i<4;i++){
      if(this.props.current.zoneControllers[i]){
        let name=(i)?`Expansion ${i}`:"Base Controller"
        opts.push(
          <C18MuiMenuItem key={i} 
          value={i}>{name}</C18MuiMenuItem>
        )
      }
    }
    return opts
  }
  
  render(){
    let unit=+this.props.zuci.split("-")[1]
    // cl(unit)
    return(
      <div>
        <C18MuiFormControl className={"formControl"}>
          <C18MuiInputLabel>Select&nbsp;Unit</C18MuiInputLabel>
          <C18MuiSelect
            value={unit}
            onChange={this.onChange}
            saveOK={this.props.saveOK}
            >
            {this.makeUnitSelect()}
          </C18MuiSelect>
        </C18MuiFormControl>
      </div>
    )
  }
}
  export default SelectUnit ;
