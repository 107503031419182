import React from 'react';
import C18Select01 from './C18Select01'
import {loadStationInfo,loadZonesInfo,getSiteName,getZoneName,
} from './C18utils'

import {cl,globs,constant,dateToDisplayDate,secsToHms} from '../../components/utils/utils';

class C18HeaDetail01 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      loaded:false,
      eventSel:0,
    }
    this.setBreadCrumbs()
    this.loadInfo()
  }

  setBreadCrumbs=()=>{
    let p=this.props.parms
    let siteName=getSiteName(p.site)
    let zoneName=getZoneName(p.zone)
    if(p){
      p.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:siteName, url:`/usa/c18/sites/${p.site}`},
              {t:zoneName, url:`/usa/c18/sites/${p.site}/zones/${p.zone}`},
              {t:"HEA Detail", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/sensor/e0ST1`},
            ]},
        },
      )
    }
  }

  onChange=(type,vals)=>{
    cl(type,vals)
    switch(type){
      case "upd":
        cl(vals)
        this.setState(vals)
        break
    }

  }

  loadInfo=async()=>{
    this.evStates={
      preparing:"Preparing",
      running:"Running",
      done:"Done",
    }
    let pa=this.props.parms
    await loadZonesInfo()
    this.zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==pa.zone})[0]
    this.stationId=+pa.pageType.substring(4)
    await loadStationInfo()
    this.station=globs.stInfo.info.filter(st=>{
      return (st.gatewayId==this.zone.gatewayId)&&(st.stationId==this.stationId)})[0]
    this.events=globs.stEventInfo.info.filter(ev=>{
      return (ev.gatewayId==this.zone.gatewayId)&&
        (ev.stationId==this.stationId)
    })
    this.events.sort((a,b)=>{
      if(a.start<b.start){return 1}
      if(a.start>b.start){return -1}
      return 0
    })
//     cl(this.events)
//     cl(globs.stInfo.info)
//     cl(globs.stEventInfo.info)
    this.setState({loaded:true,eventSel:this.events[0].start})
  }

  showSelectEvent=()=>{
//     let events=globs.stEventInfo.info.filter(ev=>{
//       return (ev.gatewayId==this.zone.gatewayId)&&
//         (ev.stationId==this.stationId)
//     })
    let events=this.events.map(ev2=>{
      let da=new Date(1000*ev2.start)
      return{
        t:dateToDisplayDate(da,"yyyy-mm-dd hh:mm"),
        v:ev2.start,
      }
    })
//     cl(events)
    return(
      <C18Select01 parms={{
        label:"Select Event",
        eventSel:this.state.eventSel,
        valueName:"eventSel",
        opts:events,
        onChange:this.onChange,
      }}/>

    )
  }

  render(){
    var showRecipe=(selEv)=>{
      return(
        <table><tbody>
        {selEv.recipe.map((re,i)=>{
          return(
            <tr key={i}><td>{re.ingName}</td><td>{`${re.ingConc} ml/gal`}</td></tr>
          )
        })}
        </tbody></table>
      )
    }
    var showPC=(pc,i)=>{
      let onOffBits=pc.onOffBits|0x07
      var mask=1
      return(
        <React.Fragment key={i}>
          <tr key={"t"+i}><td colSpan="3">{`${pc.name}:`}</td></tr>
          {pc.nutes.filter((n,j)=>{
            n.id=j
            let m0=mask
            mask=mask<<1
            return(onOffBits&m0)
          })
            .map((n,k)=>{
//               cl(n)
              let conc=`${n.cps/100} ml / ${n.vps/100} gals`
            return(
              <tr key={k}><td>{n.id}</td><td>{n.nute}</td><td>{conc}</td>
              <td></td>
              </tr>
            )
          })}
        </React.Fragment>
      )
    }
    var showAllPcs=(selEvent)=>{
      return(
        <table style={{width:"auto"}}><tbody>
          {selEvent.pcs.map((pc,i)=>{return showPC(pc,i)})}
        </tbody></table>
      )
    }
    let st=this.state
//     cl(st)
    if(st.loaded){
      let selEv=globs.stEventInfo.info.filter(ev=>{
        return (ev.gatewayId==this.zone.gatewayId)&&
          (ev.stationId==this.stationId)&&
          (ev.start==st.eventSel)
      })[0]
//       cl(selEv)
      let evState=this.evStates[selEv.state]
      let vol=selEv.curVolume-selEv.startVolume
      vol=Math.floor(vol*10)/10
      return(
        <div>
          <div><h2>{this.station.name}</h2></div>
          {this.showSelectEvent()}
          <table style={{width:"auto"}}><tbody>
          <tr><td>Status:</td><td>{evState}</td></tr>
          <tr><td>Volume:</td><td>{`${vol} gals`}</td></tr>
          <tr><td>Runtime:</td><td>{secsToHms(selEv.duration)}</td></tr>
          <tr><td>Area:</td><td>{`${selEv.areas[0][1]}`}</td></tr>
          <tr><td height="10"></td><td></td></tr>
          <tr><td>Recipe:</td><td>{`${selEv.recipeName}:`}</td></tr>
          <tr><td></td><td>{showRecipe(selEv)}</td></tr>
          <tr><td height="10"></td><td></td></tr>
          <tr><td valign="top">Pumper<br/>Controllers:</td><td>{showAllPcs(selEv)}</td></tr>

          </tbody></table>
        </div>
      )
    }else{
      return <div id="content-area">loading . . .</div>
    }
  }
}

export default C18HeaDetail01 ;

