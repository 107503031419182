import React from 'react';
import C18Button00 from './C18Button00'
import {cl} from '../../components/utils/utils';

class C18TabsHeader00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
//     props.parms.newProps(this.newProps)// register newProps function
  }

    makeTabSelect=(tabId)=>{
      return(this.props.tabId==tabId)?" selected":""
    }
    
    onClick=(tabId)=>{
      this.props.onChange({tab:tabId})
//       cl(tabId)
    }
    
    render(){
    return(
      <div className="tab-controls" role="tablist">
        {this.props.tabs.map((t,i)=>{
          return(
            <C18Button00 type="button" key={i} className={`tab${this.makeTabSelect(t.v)}`} role="tab" onClick={o=>this.onClick(t.v)}>{t.t}</C18Button00>
            
          )
        })}
      </div>
    )
  }
}

export default C18TabsHeader00;
