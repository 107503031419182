import React from 'react';
import UsaSelect00 from '../UsaSelect00'
import {cl} from '../../../components/utils/utils';

const verticalSpace=<div style={{height: 10}}/>

class RepeatMonthWeek extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      months: 1,
      week: 1,
      day: 2,
    }
    this.repeat=this.props.parms.repeat
  }
  
/* repeat on the 3rd Tuesday of each month*/
  
  onChange=(vals)=>{
//     cl(this.props)
    let s=Object.assign(this.state, vals)
    this.repeat={
      period: s.months,
      details: s.day | (s.week << 8)
    }
    this.setState(vals)
    this.props.parms.onChange({repeat: Object.assign({},this.repeat)})
  }
  
  render(){
    let dayOpts={
      0:{t:"Sunday"},
      1:{t:"Monday"},
      2:{t:"Tuesday"},
      3:{t:"Wednesday"},
      4:{t:"Thursday"},
      5:{t:"Friday"},
      6:{t:"Saturday"},
    }
    let ordinalOpts={
      0:{t:"First"},
      1:{t:"Second"},
      2:{t:"Third"},
      3:{t:"Fourth"},
      4:{t:"Fifth or Last"},
    }
    return(
      <>
      <UsaSelect00 parms={{
        title: "Repeat\u00A0Every",
        footer: "Months",
        footerPlural: true,
        type: "number",
        min: 1,
        width: 50,
        select: false, // !this.state.editMode,
        valueId: "months",//valueId, // "widgetSel",
        value: this.state.months, //value, // this.state.widgetSel,
        onChange: vals=>this.onChange("parts",vals),
      }}/>
      {verticalSpace}
      <UsaSelect00 parms={{
        title: "On",
//         type: "number",
//         width: 50,
        select: true, // !this.state.editMode,
        opts: dayOpts,
        valueId: "day",//valueId, // "widgetSel",
        value: this.state.day, //value, // this.state.widgetSel,
        onChange: this.onChange,
      }}/>
      {verticalSpace}
      <UsaSelect00 parms={{
        title: "Of\u00A0The",
        footer: "Week of the Month",
//         type: "number",
//         width: 50,
        select: true, // !this.state.editMode,
        opts: ordinalOpts,
        valueId: "week",//valueId, // "widgetSel",
        value: this.state.week, //value, // this.state.widgetSel,
        onChange: this.onChange,
      }}/>
      </>
    )
  }
}

export default RepeatMonthWeek ;

