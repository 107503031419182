/*now, this should be an array of sensors:
from:
to:
sensors: {
id:inT, etc.
data:[{t:,d:}]
}*/

var sd0=[
{t:1623974760, d:18.53},
{t:1623975120, d:18.41},
{t:1623975480, d:18.21},
{t:1623975840, d:18.24},
{t:1623976200, d:18.36},
{t:1623976560, d:18.33},
{t:1623976920, d:18.07},
{t:1623977280, d:17.9},
{t:1623977640, d:17.9},
{t:1623978000, d:17.71},
{t:1623978360, d:17.6},
{t:1623978720, d:17.34},
{t:1623979080, d:17.15},
{t:1623979440, d:17.12},
{t:1623979800, d:17.26},
{t:1623980160, d:17.6},
{t:1623980520, d:17.45},
{t:1623980880, d:17.49},
{t:1623981240, d:17.19},
{t:1623981600, d:17.31},
{t:1623981960, d:17.17},
{t:1623982320, d:17.33},
{t:1623982680, d:17.06},
{t:1623983040, d:16.8},
{t:1623983400, d:17.21},
{t:1623983760, d:17.68},
{t:1623984120, d:17.35},
{t:1623984480, d:17.75},
{t:1623984840, d:17.88},
{t:1623985200, d:18.15},
{t:1623985560, d:18.4},
{t:1623985920, d:18.68},
{t:1623986280, d:18.67},
{t:1623986640, d:18.92},
{t:1623987000, d:18.67},
{t:1623987360, d:18.95},
{t:1623987720, d:18.67},
{t:1623988080, d:18.48},
{t:1623988440, d:18.68},
{t:1623988800, d:18.56},
{t:1623989160, d:18.44},
{t:1623989520, d:18.39},
{t:1623989880, d:18.62},
{t:1623990240, d:18.26},
{t:1623990600, d:18.18},
{t:1623990960, d:18.14},
{t:1623991320, d:18.15},
{t:1623991680, d:17.88},
{t:1623992040, d:18.06},
{t:1623992400, d:18.08},
{t:1623992760, d:18.23},
{t:1623993120, d:18},
{t:1623993480, d:18.31},
{t:1623993840, d:18.24},
{t:1623994200, d:17.99},
{t:1623994560, d:18.32},
{t:1623994920, d:18.43},
{t:1623995280, d:18.79},
{t:1623995640, d:18.96},
{t:1623996000, d:19.33},
{t:1623996360, d:19.1},
{t:1623996720, d:19.34},
{t:1623997080, d:19.65},
{t:1623997440, d:19.76},
{t:1623997800, d:19.38},
{t:1623998160, d:19.43},
{t:1623998520, d:19.29},
{t:1623998880, d:19.57},
{t:1623999240, d:19.35},
{t:1623999600, d:19.3},
{t:1623999960, d:18.98},
{t:1624000320, d:18.77},
{t:1624000680, d:18.58},
{t:1624001040, d:18.55},
{t:1624001400, d:18.82},
{t:1624001760, d:18.87},
{t:1624002120, d:19.24},
{t:1624002480, d:19.02},
{t:1624002840, d:19.21},
{t:1624003200, d:18.87},
{t:1624003560, d:18.86},
{t:1624003920, d:19.12},
{t:1624004280, d:19.47},
{t:1624004640, d:19.49},
{t:1624005000, d:19.32},
{t:1624005360, d:19.68},
{t:1624005720, d:19.37},
{t:1624006080, d:19.18},
{t:1624006440, d:19.34},
{t:1624006800, d:19.49},
{t:1624007160, d:19.11},
{t:1624007520, d:19.43},
{t:1624007880, d:19.51},
{t:1624008240, d:19.48},
{t:1624008600, d:19.15},
{t:1624008960, d:19.21},
{t:1624009320, d:19.34},
{t:1624009680, d:18.92},
{t:1624010040, d:19.03},
{t:1624010400, d:18.76},
{t:1624010760, d:18.78},
{t:1624011120, d:18.41},
{t:1624011480, d:18.64},
{t:1624011840, d:18.91},
{t:1624012200, d:19.24},
{t:1624012560, d:19.23},
{t:1624012920, d:19.29},
{t:1624013280, d:19.26},
{t:1624013640, d:19.2},
{t:1624014000, d:19},
{t:1624014360, d:19.07},
{t:1624014720, d:19.1},
{t:1624015080, d:19.12},
{t:1624015440, d:19.12},
{t:1624015800, d:19.04},
{t:1624016160, d:19.25},
{t:1624016520, d:19.09},
{t:1624016880, d:19.04},
{t:1624017240, d:19.36},
{t:1624017600, d:19.07},
{t:1624017960, d:18.96},
{t:1624018320, d:18.66},
{t:1624018680, d:18.36},
{t:1624019040, d:18.02},
{t:1624019400, d:18.26},
{t:1624019760, d:18.23},
{t:1624020120, d:18.19},
{t:1624020480, d:18.32},
{t:1624020840, d:18.27},
{t:1624021200, d:18.52},
{t:1624021560, d:18.32},
{t:1624021920, d:18.3},
{t:1624022280, d:17.87},
{t:1624022640, d:17.51},
{t:1624023000, d:17.41},
{t:1624023360, d:17.06},
{t:1624023720, d:17.12},
{t:1624024080, d:17.42},
{t:1624024440, d:17.25},
{t:1624024800, d:17.47},
{t:1624025160, d:17.37},
{t:1624025520, d:17.06},
{t:1624025880, d:17.41},
{t:1624026240, d:17.58},
{t:1624026600, d:17.94},
{t:1624026960, d:18.14},
{t:1624027320, d:17.84},
{t:1624027680, d:17.49},
{t:1624028040, d:17.13},
{t:1624028400, d:17.4},
{t:1624028760, d:17.4},
{t:1624029120, d:17.06},
{t:1624029480, d:17.1},
{t:1624029840, d:16.96},
{t:1624030200, d:16.91},
{t:1624030560, d:16.98},
{t:1624030920, d:17.11},
{t:1624031280, d:17.32},
{t:1624031640, d:17.08},
{t:1624032000, d:17.34},
{t:1624032360, d:17.58},
{t:1624032720, d:17.81},
{t:1624033080, d:17.7},
{t:1624033440, d:17.41},
{t:1624033800, d:17.56},
{t:1624034160, d:17.91},
{t:1624034520, d:17.55},
{t:1624034880, d:17.23},
{t:1624035240, d:17.16},
{t:1624035600, d:17.4},
{t:1624035960, d:17.31},
{t:1624036320, d:17.28},
{t:1624036680, d:17.51},
{t:1624037040, d:17.59},
{t:1624037400, d:17.41},
{t:1624037760, d:17.68},
{t:1624038120, d:17.48},
{t:1624038480, d:17.49},
{t:1624038840, d:17.53},
{t:1624039200, d:17.73},
{t:1624039560, d:17.6},
{t:1624039920, d:17.91},
{t:1624040280, d:18.04},
{t:1624040640, d:18.33},
{t:1624041000, d:18.59},
{t:1624041360, d:18.65},
{t:1624041720, d:18.9},
{t:1624042080, d:18.63},
{t:1624042440, d:18.61},
{t:1624042800, d:18.6},
{t:1624043160, d:18.8},
{t:1624043520, d:18.82},
{t:1624043880, d:19.1},
{t:1624044240, d:19.44},
{t:1624044600, d:19.15},
{t:1624044960, d:19.18},
{t:1624045320, d:18.88},
{t:1624045680, d:18.58},
{t:1624046040, d:18.9},
{t:1624046400, d:19.22},
{t:1624046760, d:19.34},
{t:1624047120, d:19.71},
{t:1624047480, d:19.42},
{t:1624047840, d:19.28},
{t:1624048200, d:19.31},
{t:1624048560, d:19.68},
{t:1624048920, d:19.3},
{t:1624049280, d:19.69},
{t:1624049640, d:19.84},
{t:1624050000, d:20.03},
{t:1624050360, d:20.36},
{t:1624050720, d:20.55},
{t:1624051080, d:20.27},
{t:1624051440, d:20.63},
{t:1624051800, d:20.86},
{t:1624052160, d:20.76},
{t:1624052520, d:20.99},
{t:1624052880, d:20.67},
{t:1624053240, d:20.88},
{t:1624053600, d:20.91},
{t:1624053960, d:21.01},
{t:1624054320, d:21.04},
{t:1624054680, d:21.26},
{t:1624055040, d:21.6},
{t:1624055400, d:21.68},
{t:1624055760, d:21.39},
{t:1624056120, d:20.99},
{t:1624056480, d:20.7},
{t:1624056840, d:20.73},
{t:1624057200, d:20.67},
{t:1624057560, d:20.45},
{t:1624057920, d:20.12},
{t:1624058280, d:19.97},
{t:1624058640, d:19.85},
{t:1624059000, d:19.77},
{t:1624059360, d:19.87},
{t:1624059720, d:19.52},
{t:1624060080, d:19.33},
{t:1624060440, d:19.64},
{t:1624060800, d:19.83},
{t:1624061160, d:20.05},
{t:1624061520, d:19.72},
{t:1624061880, d:19.82},
{t:1624062240, d:20.04},
{t:1624062600, d:19.99},
{t:1624062960, d:19.97},
{t:1624063320, d:19.75},
{t:1624063680, d:19.55},
{t:1624064040, d:19.36},
{t:1624064400, d:19.01},
{t:1624064760, d:18.73},
{t:1624065120, d:19.03},
{t:1624065480, d:19.29},
{t:1624065840, d:18.95},
{t:1624066200, d:18.97},
{t:1624066560, d:18.68},
{t:1624066920, d:19.01},
{t:1624067280, d:19.03},
{t:1624067640, d:19.43},
{t:1624068000, d:19.39},
{t:1624068360, d:19.19},
{t:1624068720, d:18.95},
{t:1624069080, d:19.2},
{t:1624069440, d:19},
{t:1624069800, d:19.23},
{t:1624070160, d:19.51},
{t:1624070520, d:19.67},
{t:1624070880, d:19.57},
{t:1624071240, d:19.99},
{t:1624071600, d:20.33},
{t:1624071960, d:20.27},
{t:1624072320, d:20.18},
{t:1624072680, d:19.98},
{t:1624073040, d:19.83},
{t:1624073400, d:19.82},
{t:1624073760, d:20.13},
{t:1624074120, d:20.52},
{t:1624074480, d:20.55},
{t:1624074840, d:20.84},
{t:1624075200, d:20.82},
{t:1624075560, d:20.46},
{t:1624075920, d:20.72},
{t:1624076280, d:20.82},
{t:1624076640, d:21.09},
{t:1624077000, d:20.7},
{t:1624077360, d:20.3},
{t:1624077720, d:20.06},
{t:1624078080, d:19.68},
{t:1624078440, d:19.81},
{t:1624078800, d:20.07},
{t:1624079160, d:19.96},
{t:1624079520, d:20.21},
{t:1624079880, d:20.59},
{t:1624080240, d:20.64},
{t:1624080600, d:20.48},
{t:1624080960, d:20.52},
{t:1624081320, d:20.35},
{t:1624081680, d:20.65},
{t:1624082040, d:20.92},
{t:1624082400, d:21.3},
{t:1624082760, d:20.88},
{t:1624083120, d:21.09},
{t:1624083480, d:21.43},
{t:1624083840, d:21.66},
{t:1624084200, d:21.71},
{t:1624084560, d:21.41},
{t:1624084920, d:21.75},
{t:1624085280, d:21.96},
{t:1624085640, d:21.63},
{t:1624086000, d:21.26},
{t:1624086360, d:21.54},
{t:1624086720, d:21.69},
{t:1624087080, d:21.3},
{t:1624087440, d:21.56},
{t:1624087800, d:21.63},
{t:1624088160, d:21.92},
{t:1624088520, d:21.85},
{t:1624088880, d:21.88},
{t:1624089240, d:22.16},
{t:1624089600, d:22.46},
{t:1624089960, d:22.57},
{t:1624090320, d:22.48},
{t:1624090680, d:22.11},
{t:1624091040, d:21.97},
{t:1624091400, d:21.97},
{t:1624091760, d:22.13},
{t:1624092120, d:21.7},
{t:1624092480, d:21.76},
{t:1624092840, d:21.97},
{t:1624093200, d:21.74},
{t:1624093560, d:22.01},
{t:1624093920, d:21.73},
{t:1624094280, d:21.55},
{t:1624094640, d:21.35},
{t:1624095000, d:21.41},
{t:1624095360, d:21.12},
{t:1624095720, d:21.13},
{t:1624096080, d:20.82},
{t:1624096440, d:20.47},
{t:1624096800, d:20.36},
{t:1624097160, d:19.96},
{t:1624097520, d:19.77},
{t:1624097880, d:20.04},
{t:1624098240, d:20.07},
{t:1624098600, d:20.37},
{t:1624098960, d:19.92},
{t:1624099320, d:20.24},
{t:1624099680, d:20.28},
{t:1624100040, d:20.42},
{t:1624100400, d:20.62},
{t:1624100760, d:20.71},
{t:1624101120, d:20.41},
{t:1624101480, d:20.08},
{t:1624101840, d:19.62},
{t:1624102200, d:19.27},
{t:1624102560, d:19.21},
{t:1624102920, d:19.17},
{t:1624103280, d:19.07},
{t:1624103640, d:18.69},
{t:1624104000, d:18.89},
{t:1624104360, d:18.94},
{t:1624104720, d:19.15},
{t:1624105080, d:19.4},
{t:1624105440, d:19.19},
{t:1624105800, d:18.88},
{t:1624106160, d:18.45},
{t:1624106520, d:18.73},
{t:1624106880, d:18.42},
{t:1624107240, d:18.72},
{t:1624107600, d:18.45},
{t:1624107960, d:18.02},
{t:1624108320, d:17.78},
{t:1624108680, d:17.58},
{t:1624109040, d:17.59},
{t:1624109400, d:17.3},
{t:1624109760, d:17.14},
{t:1624110120, d:17.01},
{t:1624110480, d:16.67},
{t:1624110840, d:16.53},
{t:1624111200, d:16.23},
{t:1624111560, d:16.34},
{t:1624111920, d:16.22},
{t:1624112280, d:16.17},
{t:1624112640, d:16.1},
{t:1624113000, d:16.27},
{t:1624113360, d:16.12},
{t:1624113720, d:15.86},
{t:1624114080, d:16.19},
{t:1624114440, d:16.38},
{t:1624114800, d:16.08},
{t:1624115160, d:16.4},
{t:1624115520, d:16.25},
{t:1624115880, d:16.07},
{t:1624116240, d:15.87},
{t:1624116600, d:15.53},
{t:1624116960, d:15.88},
{t:1624117320, d:15.84},
{t:1624117680, d:16.04},
{t:1624118040, d:15.71},
{t:1624118400, d:15.48},
{t:1624118760, d:15.38},
{t:1624119120, d:15.54},
{t:1624119480, d:15.21},
{t:1624119840, d:15.16},
{t:1624120200, d:15.08},
{t:1624120560, d:15.5},
{t:1624120920, d:15.82},
{t:1624121280, d:15.95},
{t:1624121640, d:15.81},
{t:1624122000, d:15.61},
{t:1624122360, d:15.95},
{t:1624122720, d:16.23},
{t:1624123080, d:15.84},
{t:1624123440, d:16},
{t:1624123800, d:16.23},
{t:1624124160, d:15.95},
{t:1624124520, d:15.6},
{t:1624124880, d:15.63},
{t:1624125240, d:15.78},
{t:1624125600, d:15.95},
{t:1624125960, d:16.34},
{t:1624126320, d:16.18},
{t:1624126680, d:16.3},
{t:1624127040, d:16.59},
{t:1624127400, d:16.54},
{t:1624127760, d:16.83},
{t:1624128120, d:16.87},
{t:1624128480, d:16.97},
{t:1624128840, d:16.76},
{t:1624129200, d:16.53},
{t:1624129560, d:16.52},
{t:1624129920, d:16.88},
{t:1624130280, d:16.66},
{t:1624130640, d:16.97},
{t:1624131000, d:16.94},
{t:1624131360, d:17.17},
{t:1624131720, d:17.42},
{t:1624132080, d:17.48},
{t:1624132440, d:17.74},
{t:1624132800, d:17.74},
{t:1624133160, d:17.66},
{t:1624133520, d:17.4},
{t:1624133880, d:17.49},
{t:1624134240, d:17.26},
{t:1624134600, d:17.45},
{t:1624134960, d:17.62},
{t:1624135320, d:17.95},
{t:1624135680, d:17.62},
{t:1624136040, d:17.83},
{t:1624136400, d:18.02},
{t:1624136760, d:18.34},
{t:1624137120, d:18.44},
{t:1624137480, d:18.08},
{t:1624137840, d:17.97},
{t:1624138200, d:18.21},
{t:1624138560, d:18.63},
{t:1624138920, d:18.68},
{t:1624139280, d:18.92},
{t:1624139640, d:19.28},
{t:1624140000, d:19.54},
{t:1624140360, d:19.2},
{t:1624140720, d:19.38},
{t:1624141080, d:19.16},
{t:1624141440, d:19.39},
{t:1624141800, d:19.67},
{t:1624142160, d:19.91},
{t:1624142520, d:19.54},
{t:1624142880, d:19.3},
{t:1624143240, d:18.96},
{t:1624143600, d:18.9},
{t:1624143960, d:18.89},
{t:1624144320, d:19.02},
{t:1624144680, d:19.27},
{t:1624145040, d:19.14},
{t:1624145400, d:19.47},
{t:1624145760, d:19.61},
{t:1624146120, d:19.79},
{t:1624146480, d:19.7},
{t:1624146840, d:20.09},
{t:1624147200, d:20.18},
{t:1624147560, d:20.35},
{t:1624147920, d:19.99},
{t:1624148280, d:19.62},
{t:1624148640, d:19.93},
{t:1624149000, d:19.8},
{t:1624149360, d:19.69},
{t:1624149720, d:19.81},
{t:1624150080, d:20.04},
{t:1624150440, d:19.74},
{t:1624150800, d:19.79},
{t:1624151160, d:19.62},
{t:1624151520, d:19.46},
{t:1624151880, d:19.67},
{t:1624152240, d:19.81},
{t:1624152600, d:19.6},
{t:1624152960, d:19.63},
{t:1624153320, d:19.35},
{t:1624153680, d:19.43},
{t:1624154040, d:19.5},
{t:1624154400, d:19.72},
{t:1624154760, d:19.78},
{t:1624155120, d:19.51},
{t:1624155480, d:19.69},
{t:1624155840, d:19.69},
{t:1624156200, d:19.44},
{t:1624156560, d:19.4},
{t:1624156920, d:19.13},
{t:1624157280, d:18.94},
{t:1624157640, d:18.86},
{t:1624158000, d:19},
{t:1624158360, d:19.02},
{t:1624158720, d:19.23},
{t:1624159080, d:19.3},
{t:1624159440, d:19.54},
{t:1624159800, d:19.33},
{t:1624160160, d:19.42},
{t:1624160520, d:19.59},
{t:1624160880, d:19.46},
{t:1624161240, d:19.73},
{t:1624161600, d:19.61},
{t:1624161960, d:19.41},
{t:1624162320, d:19.04},
{t:1624162680, d:19.24},
{t:1624163040, d:19.57},
{t:1624163400, d:19.56},
{t:1624163760, d:19.55},
{t:1624164120, d:19.66},
{t:1624164480, d:20},
{t:1624164840, d:19.66},
{t:1624165200, d:19.34},
{t:1624165560, d:19.07},
{t:1624165920, d:18.82},
{t:1624166280, d:19.08},
{t:1624166640, d:18.74},
{t:1624167000, d:18.53},
{t:1624167360, d:18.41},
{t:1624167720, d:18.26},
{t:1624168080, d:18.43},
{t:1624168440, d:18.24},
{t:1624168800, d:18.09},
{t:1624169160, d:18.1},
{t:1624169520, d:18.46},
{t:1624169880, d:18.7},
{t:1624170240, d:18.98},
{t:1624170600, d:18.89},
{t:1624170960, d:19.23},
{t:1624171320, d:19.02},
{t:1624171680, d:18.92},
{t:1624172040, d:18.87},
{t:1624172400, d:19.22},
{t:1624172760, d:19.3},
{t:1624173120, d:18.94},
{t:1624173480, d:18.56},
{t:1624173840, d:18.18},
{t:1624174200, d:18.06},
{t:1624174560, d:18.13},
{t:1624174920, d:18.48},
{t:1624175280, d:18.76},
{t:1624175640, d:18.74},
{t:1624176000, d:18.64},
{t:1624176360, d:18.51},
{t:1624176720, d:18.9},
{t:1624177080, d:18.65},
{t:1624177440, d:18.52},
{t:1624177800, d:18.25},
{t:1624178160, d:18.1},
{t:1624178520, d:17.73},
{t:1624178880, d:18.03},
{t:1624179240, d:17.78},
{t:1624179600, d:17.65},
{t:1624179960, d:17.35},
{t:1624180320, d:17.44},
{t:1624180680, d:17.17},
{t:1624181040, d:17.44},
{t:1624181400, d:17.43},
{t:1624181760, d:17.11},
{t:1624182120, d:17.31},
{t:1624182480, d:17.68},
{t:1624182840, d:17.47},
{t:1624183200, d:17.56},
{t:1624183560, d:17.73},
{t:1624183920, d:18.1},
{t:1624184280, d:18.07},
{t:1624184640, d:18.07},
{t:1624185000, d:18.24},
{t:1624185360, d:18.36},
{t:1624185720, d:18.58},
{t:1624186080, d:18.83},
{t:1624186440, d:18.42},
{t:1624186800, d:18.55},
{t:1624187160, d:18.53},
{t:1624187520, d:18.1},
{t:1624187880, d:17.91},
{t:1624188240, d:17.8},
{t:1624188600, d:17.7},
{t:1624188960, d:17.78},
{t:1624189320, d:17.79},
{t:1624189680, d:17.78},
{t:1624190040, d:17.46},
{t:1624190400, d:17.55},
{t:1624190760, d:17.34},
{t:1624191120, d:17.37},
{t:1624191480, d:17.46},
{t:1624191840, d:17.22},
{t:1624192200, d:17.49},
{t:1624192560, d:17.23},
{t:1624192920, d:17.22},
{t:1624193280, d:16.91},
{t:1624193640, d:16.61},
{t:1624194000, d:16.48},
{t:1624194360, d:16.82},
{t:1624194720, d:16.69},
{t:1624195080, d:16.52},
{t:1624195440, d:16.39},
{t:1624195800, d:16.46},
{t:1624196160, d:16.47},
{t:1624196520, d:16.26},
{t:1624196880, d:16.43},
{t:1624197240, d:16.54},
{t:1624197600, d:16.83},
{t:1624197960, d:17.13},
{t:1624198320, d:16.76},
{t:1624198680, d:16.99},
{t:1624199040, d:16.58},
{t:1624199400, d:16.46},
{t:1624199760, d:16.13},
{t:1624200120, d:16.14},
{t:1624200480, d:16.5},
{t:1624200840, d:16.58},
{t:1624201200, d:16.87},
{t:1624201560, d:16.85},
{t:1624201920, d:16.8},
{t:1624202280, d:16.6},
{t:1624202640, d:16.78},
{t:1624203000, d:16.96},
{t:1624203360, d:16.86},
{t:1624203720, d:17.19},
{t:1624204080, d:16.77},
{t:1624204440, d:16.51},
{t:1624204800, d:16.86},
{t:1624205160, d:16.67},
{t:1624205520, d:16.89},
{t:1624205880, d:17.28},
{t:1624206240, d:17.48},
{t:1624206600, d:17.37},
{t:1624206960, d:17.2},
{t:1624207320, d:16.96},
{t:1624207680, d:17.06},
{t:1624208040, d:17.31},
{t:1624208400, d:17.32},
{t:1624208760, d:17.52},
{t:1624209120, d:17.43},
{t:1624209480, d:17.57},
{t:1624209840, d:17.52},
{t:1624210200, d:17.39},
{t:1624210560, d:17.7},
{t:1624210920, d:17.83},
{t:1624211280, d:18.14},
{t:1624211640, d:17.76},
{t:1624212000, d:18.13},
{t:1624212360, d:17.89},
{t:1624212720, d:17.74},
{t:1624213080, d:17.98},
{t:1624213440, d:17.84},
{t:1624213800, d:17.59},
{t:1624214160, d:17.55},
{t:1624214520, d:17.92},
{t:1624214880, d:17.61},
{t:1624215240, d:17.28},
{t:1624215600, d:17.32},
{t:1624215960, d:16.97},
{t:1624216320, d:16.59},
{t:1624216680, d:16.95},
{t:1624217040, d:16.96},
{t:1624217400, d:16.78},
{t:1624217760, d:17},
{t:1624218120, d:17.02},
{t:1624218480, d:16.9},
{t:1624218840, d:16.6},
{t:1624219200, d:16.69},
{t:1624219560, d:16.98},
{t:1624219920, d:17.21},
{t:1624220280, d:17.25},
{t:1624220640, d:17.47},
{t:1624221000, d:17.48},
{t:1624221360, d:17.66},
{t:1624221720, d:17.56},
{t:1624222080, d:17.56},
{t:1624222440, d:17.23},
{t:1624222800, d:17.64},
{t:1624223160, d:17.73},
{t:1624223520, d:18.11},
{t:1624223880, d:17.8},
{t:1624224240, d:17.88},
{t:1624224600, d:18.18},
{t:1624224960, d:18.17},
{t:1624225320, d:18.09},
{t:1624225680, d:18.16},
{t:1624226040, d:18.58},
{t:1624226400, d:18.5},
{t:1624226760, d:18.32},
{t:1624227120, d:18.45},
{t:1624227480, d:18.53},
{t:1624227840, d:18.5},
{t:1624228200, d:18.48},
{t:1624228560, d:18.4},
{t:1624228920, d:18.32},
{t:1624229280, d:18.62},
{t:1624229640, d:18.79},
{t:1624230000, d:19.17},
{t:1624230360, d:19.55},
{t:1624230720, d:19.45},
{t:1624231080, d:19.63},
{t:1624231440, d:19.81},
{t:1624231800, d:19.66},
{t:1624232160, d:19.72},
{t:1624232520, d:20.07},
{t:1624232880, d:20.32},
{t:1624233240, d:20.06},
{t:1624233600, d:20.15},
{t:1624233960, d:19.99},
{t:1624234320, d:19.83},
{t:1624234680, d:19.98},
{t:1624235040, d:19.92},
{t:1624235400, d:19.56},
{t:1624235760, d:19.55},
{t:1624236120, d:19.52},
{t:1624236480, d:19.6},
{t:1624236840, d:19.4},
{t:1624237200, d:19.56},
{t:1624237560, d:19.77},
{t:1624237920, d:19.76},
{t:1624238280, d:19.72},
{t:1624238640, d:19.61},
{t:1624239000, d:19.6},
{t:1624239360, d:20.01},
{t:1624239720, d:20.33},
{t:1624240080, d:20.68},
{t:1624240440, d:20.58},
{t:1624240800, d:20.44},
{t:1624241160, d:20.63},
{t:1624241520, d:20.72},
{t:1624241880, d:21.02},
{t:1624242240, d:20.63},
{t:1624242600, d:20.63},
{t:1624242960, d:20.97},
{t:1624243320, d:21.28},
{t:1624243680, d:21.54},
{t:1624244040, d:21.74},
{t:1624244400, d:21.86},
{t:1624244760, d:21.55},
{t:1624245120, d:21.66},
{t:1624245480, d:21.51},
{t:1624245840, d:21.87},
{t:1624246200, d:22.12},
{t:1624246560, d:22.47},
{t:1624246920, d:22.67},
{t:1624247280, d:22.39},
{t:1624247640, d:22.06},
{t:1624248000, d:22.06},
{t:1624248360, d:21.85},
{t:1624248720, d:21.58},
{t:1624249080, d:21.77},
{t:1624249440, d:21.39},
{t:1624249800, d:21.3},
{t:1624250160, d:21.65},
{t:1624250520, d:21.43},
{t:1624250880, d:21.1},
{t:1624251240, d:20.79},
{t:1624251600, d:21.14},
{t:1624251960, d:21.07},
{t:1624252320, d:20.92},
{t:1624252680, d:21.01},
{t:1624253040, d:20.8},
{t:1624253400, d:20.5},
{t:1624253760, d:20.87},
{t:1624254120, d:21.06},
{t:1624254480, d:21.36},
{t:1624254840, d:21.19},
{t:1624255200, d:21.33},
{t:1624255560, d:21.31},
{t:1624255920, d:21.32},
{t:1624256280, d:21.62},
{t:1624256640, d:21.59},
{t:1624257000, d:21.91},
{t:1624257360, d:21.53},
{t:1624257720, d:21.59},
{t:1624258080, d:21.58},
{t:1624258440, d:21.7},
{t:1624258800, d:21.9},
{t:1624259160, d:22.16},
{t:1624259520, d:22.16},
{t:1624259880, d:22.34},
{t:1624260240, d:22.08},
{t:1624260600, d:21.77},
{t:1624260960, d:21.73},
{t:1624261320, d:22.07},
{t:1624261680, d:21.71},
{t:1624262040, d:21.43},
{t:1624262400, d:21},
{t:1624262760, d:20.94},
{t:1624263120, d:20.72},
{t:1624263480, d:20.9},
{t:1624263840, d:20.79},
{t:1624264200, d:20.42},
{t:1624264560, d:20.35},
{t:1624264920, d:19.93},
{t:1624265280, d:19.57},
{t:1624265640, d:19.81},
{t:1624266000, d:19.46},
{t:1624266360, d:19.67},
{t:1624266720, d:19.84},
{t:1624267080, d:19.47},
{t:1624267440, d:19.13},
{t:1624267800, d:18.72},
{t:1624268160, d:18.61},
{t:1624268520, d:18.24},
{t:1624268880, d:18.57},
{t:1624269240, d:18.58},
{t:1624269600, d:18.45},
{t:1624269960, d:18.15},
{t:1624270320, d:18.15},
{t:1624270680, d:18.16},
{t:1624271040, d:17.84},
{t:1624271400, d:17.45},
{t:1624271760, d:17.73},
{t:1624272120, d:17.79},
{t:1624272480, d:18.14},
{t:1624272840, d:17.81},
{t:1624273200, d:17.6},
{t:1624273560, d:17.51},
{t:1624273920, d:17.85},
{t:1624274280, d:17.94},
{t:1624274640, d:17.69},
{t:1624275000, d:17.46},
{t:1624275360, d:17.08},
{t:1624275720, d:17.44},
{t:1624276080, d:17.24},
{t:1624276440, d:16.93},
{t:1624276800, d:16.58},
{t:1624277160, d:16.66},
{t:1624277520, d:16.65},
{t:1624277880, d:16.84},
{t:1624278240, d:16.53},
{t:1624278600, d:16.88},
{t:1624278960, d:16.76},
{t:1624279320, d:16.35},
{t:1624279680, d:16.03},
{t:1624280040, d:16.36},
{t:1624280400, d:16.14},
{t:1624280760, d:16.47},
{t:1624281120, d:16.66},
{t:1624281480, d:16.91},
{t:1624281840, d:16.82},
{t:1624282200, d:16.64},
{t:1624282560, d:16.92},
{t:1624282920, d:16.59},
{t:1624283280, d:16.74},
{t:1624283640, d:16.35},
{t:1624284000, d:16.03},
{t:1624284360, d:15.92},
{t:1624284720, d:15.65},
{t:1624285080, d:15.59},
{t:1624285440, d:15.47},
{t:1624285800, d:15.75},
{t:1624286160, d:15.58},
{t:1624286520, d:15.54},
{t:1624286880, d:15.47},
{t:1624287240, d:15.75},
{t:1624287600, d:16.14},
{t:1624287960, d:16.14},
{t:1624288320, d:15.8},
{t:1624288680, d:15.79},
{t:1624289040, d:15.69},
{t:1624289400, d:15.7},
{t:1624289760, d:15.44},
{t:1624290120, d:15.38},
{t:1624290480, d:15.43},
{t:1624290840, d:15.41},
{t:1624291200, d:15.45},
{t:1624291560, d:15.2},
{t:1624291920, d:15.24},
{t:1624292280, d:14.93},
{t:1624292640, d:14.62},
{t:1624293000, d:14.65},
{t:1624293360, d:14.9},
{t:1624293720, d:15.26},
{t:1624294080, d:15.17},
{t:1624294440, d:15.56},
{t:1624294800, d:15.85},
{t:1624295160, d:15.59},
{t:1624295520, d:15.45},
{t:1624295880, d:15.21},
{t:1624296240, d:15.57},
{t:1624296600, d:15.77},
{t:1624296960, d:15.72},
{t:1624297320, d:15.48},
{t:1624297680, d:15.41},
{t:1624298040, d:15.09},
{t:1624298400, d:15.14},
{t:1624298760, d:15.31},
{t:1624299120, d:14.95},
{t:1624299480, d:15.31},
{t:1624299840, d:15.68},
{t:1624300200, d:15.5},
{t:1624300560, d:15.34},
{t:1624300920, d:15.64},
{t:1624301280, d:15.3},
{t:1624301640, d:15.17},
{t:1624302000, d:15.46},
{t:1624302360, d:15.63},
{t:1624302720, d:15.65},
{t:1624303080, d:15.73},
{t:1624303440, d:16.13},
{t:1624303800, d:16.33},
{t:1624304160, d:16.39},
{t:1624304520, d:16.46},
{t:1624304880, d:16.32},
{t:1624305240, d:16.05},
{t:1624305600, d:15.97},
{t:1624305960, d:15.93},
{t:1624306320, d:15.65},
{t:1624306680, d:15.86},
{t:1624307040, d:16.29},
{t:1624307400, d:16.5},
{t:1624307760, d:16.5},
{t:1624308120, d:16.19},
{t:1624308480, d:16.16},
{t:1624308840, d:16.3},
{t:1624309200, d:15.98},
{t:1624309560, d:15.79},
{t:1624309920, d:15.92},
{t:1624310280, d:15.69},
{t:1624310640, d:16.01},
{t:1624311000, d:16.15},
{t:1624311360, d:16.11},
{t:1624311720, d:16.45},
{t:1624312080, d:16.21},
{t:1624312440, d:15.98},
{t:1624312800, d:16.15},
{t:1624313160, d:16.35},
{t:1624313520, d:16.64},
{t:1624313880, d:16.53},
{t:1624314240, d:16.42},
{t:1624314600, d:16.67},
{t:1624314960, d:16.38},
{t:1624315320, d:16.21},
{t:1624315680, d:16.04},
{t:1624316040, d:16.33},
{t:1624316400, d:16.77},
{t:1624316760, d:17.01},
{t:1624317120, d:17.05},
{t:1624317480, d:17.08},
{t:1624317840, d:16.94},
{t:1624318200, d:17.25},
{t:1624318560, d:16.95},
{t:1624318920, d:17.16},
{t:1624319280, d:17.19},
{t:1624319640, d:17.17},
{t:1624320000, d:17.47},
{t:1624320360, d:17.34},
{t:1624320720, d:17.73},
{t:1624321080, d:17.76},
{t:1624321440, d:17.66},
{t:1624321800, d:17.69},
{t:1624322160, d:17.58},
{t:1624322520, d:17.24},
{t:1624322880, d:17.64},
{t:1624323240, d:17.77},
{t:1624323600, d:18},
{t:1624323960, d:17.75},
{t:1624324320, d:17.65},
{t:1624324680, d:17.86},
{t:1624325040, d:17.63},
{t:1624325400, d:17.67},
{t:1624325760, d:17.4},
{t:1624326120, d:17.29},
{t:1624326480, d:17.38},
{t:1624326840, d:17.28},
{t:1624327200, d:17.14},
{t:1624327560, d:17.34},
{t:1624327920, d:17.4},
{t:1624328280, d:17.54},
{t:1624328640, d:17.38},
{t:1624329000, d:17.33},
{t:1624329360, d:17.68},
{t:1624329720, d:17.38},
{t:1624330080, d:17.64},
{t:1624330440, d:17.52},
{t:1624330800, d:17.25},
{t:1624331160, d:17.69},
{t:1624331520, d:17.94},
{t:1624331880, d:18.22},
{t:1624332240, d:18.07},
{t:1624332600, d:18.07},
{t:1624332960, d:18.49},
{t:1624333320, d:18.55},
{t:1624333680, d:18.79},
{t:1624334040, d:18.8},
{t:1624334400, d:18.93},
{t:1624334760, d:19.04},
{t:1624335120, d:19.26},
{t:1624335480, d:19.56},
{t:1624335840, d:19.41},
{t:1624336200, d:19.59},
{t:1624336560, d:19.8},
{t:1624336920, d:19.67},
{t:1624337280, d:20},
{t:1624337640, d:20.12},
{t:1624338000, d:20.11},
{t:1624338360, d:19.98},
{t:1624338720, d:19.79},
{t:1624339080, d:20.03},
{t:1624339440, d:19.77},
{t:1624339800, d:19.57},
{t:1624340160, d:19.42},
{t:1624340520, d:19.71},
{t:1624340880, d:20.04},
{t:1624341240, d:19.83},
{t:1624341600, d:20.11},
{t:1624341960, d:20.28},
{t:1624342320, d:20.01},
{t:1624342680, d:19.81},
{t:1624343040, d:20.13},
{t:1624343400, d:20.12},
{t:1624343760, d:20.39},
{t:1624344120, d:20.21},
{t:1624344480, d:20.53},
{t:1624344840, d:20.26},
{t:1624345200, d:20.28},
{t:1624345560, d:20},
{t:1624345920, d:19.89},
{t:1624346280, d:20.13},
{t:1624346640, d:20.15},
{t:1624347000, d:20.24},
{t:1624347360, d:19.91},
{t:1624347720, d:19.63},
{t:1624348080, d:19.8},
{t:1624348440, d:19.52},
{t:1624348800, d:19.34},
{t:1624349160, d:19.15},
{t:1624349520, d:19.11},
{t:1624349880, d:18.89},
{t:1624350240, d:18.65},
{t:1624350600, d:18.94},
{t:1624350960, d:18.92},
{t:1624351320, d:19.25},
{t:1624351680, d:19.15},
{t:1624352040, d:18.97},
{t:1624352400, d:18.7},
{t:1624352760, d:18.53},
{t:1624353120, d:18.42},
{t:1624353480, d:18.37},
{t:1624353840, d:18.43},
{t:1624354200, d:18.58},
{t:1624354560, d:18.61},
{t:1624354920, d:18.78},
{t:1624355280, d:18.6},
{t:1624355640, d:18.75},
{t:1624356000, d:18.55},
{t:1624356360, d:18.63},
{t:1624356720, d:18.39},
{t:1624357080, d:18.29},
{t:1624357440, d:17.94},
{t:1624357800, d:17.6},
{t:1624358160, d:17.78},
{t:1624358520, d:17.81},
{t:1624358880, d:17.55},
{t:1624359240, d:17.61},
{t:1624359600, d:17.7},
{t:1624359960, d:17.64},
{t:1624360320, d:17.93},
{t:1624360680, d:18.04},
{t:1624361040, d:18.13},
{t:1624361400, d:18.41},
{t:1624361760, d:18.28},
{t:1624362120, d:18.31},
{t:1624362480, d:18.03},
{t:1624362840, d:17.64},
{t:1624363200, d:17.98},
{t:1624363560, d:18.24},
{t:1624363920, d:18.06},
{t:1624364280, d:18.14},
{t:1624364640, d:18.1},
{t:1624365000, d:18.4},
{t:1624365360, d:18.25},
{t:1624365720, d:17.95},
{t:1624366080, d:18.21},
{t:1624366440, d:17.89},
{t:1624366800, d:17.56},
{t:1624367160, d:17.6},
{t:1624367520, d:17.2},
{t:1624367880, d:17.15},
{t:1624368240, d:17.4},
{t:1624368600, d:17.49},
{t:1624368960, d:17.63},
{t:1624369320, d:17.49},
{t:1624369680, d:17.51},
{t:1624370040, d:17.37},
{t:1624370400, d:17.57},
{t:1624370760, d:17.83},
{t:1624371120, d:17.75},
{t:1624371480, d:17.6},
{t:1624371840, d:17.52},
{t:1624372200, d:17.74},
{t:1624372560, d:17.69},
{t:1624372920, d:17.86},
{t:1624373280, d:18.19},
{t:1624373640, d:18.41},
{t:1624374000, d:18},
{t:1624374360, d:17.59},
{t:1624374720, d:17.78},
{t:1624375080, d:17.77},
{t:1624375440, d:17.48},
{t:1624375800, d:17.51},
{t:1624376160, d:17.63},
{t:1624376520, d:17.35},
{t:1624376880, d:17.38},
{t:1624377240, d:17.09},
{t:1624377600, d:17.01},
{t:1624377960, d:16.88},
{t:1624378320, d:16.82},
{t:1624378680, d:17.02},
{t:1624379040, d:16.93},
{t:1624379400, d:16.75},
{t:1624379760, d:16.86},
{t:1624380120, d:17.05},
{t:1624380480, d:17.01},
{t:1624380840, d:16.72},
{t:1624381200, d:17.06},
{t:1624381560, d:16.93},
{t:1624381920, d:16.71},
{t:1624382280, d:16.42},
{t:1624382640, d:16.8},
{t:1624383000, d:16.95},
{t:1624383360, d:16.93},
{t:1624383720, d:16.95},
{t:1624384080, d:16.78},
{t:1624384440, d:16.38},
{t:1624384800, d:16.78},
{t:1624385160, d:16.68},
{t:1624385520, d:16.85},
{t:1624385880, d:16.88},
{t:1624386240, d:17.2},
{t:1624386600, d:16.9},
{t:1624386960, d:16.51},
{t:1624387320, d:16.56},
{t:1624387680, d:16.31},
{t:1624388040, d:16.29},
{t:1624388400, d:16.34},
{t:1624388760, d:16.25},
{t:1624389120, d:15.89},
{t:1624389480, d:15.55},
{t:1624389840, d:15.47},
{t:1624390200, d:15.19},
{t:1624390560, d:15.52},
{t:1624390920, d:15.61},
{t:1624391280, d:15.72},
{t:1624391640, d:15.36},
{t:1624392000, d:15.6},
{t:1624392360, d:15.86},
{t:1624392720, d:16.11},
{t:1624393080, d:16.19},
{t:1624393440, d:16.01},
{t:1624393800, d:16.04},
{t:1624394160, d:16.27},
{t:1624394520, d:16.47},
{t:1624394880, d:16.59},
{t:1624395240, d:16.5},
{t:1624395600, d:16.28},
{t:1624395960, d:16.25},
{t:1624396320, d:16.57},
{t:1624396680, d:16.75},
{t:1624397040, d:16.53},
{t:1624397400, d:16.57},
{t:1624397760, d:16.51},
{t:1624398120, d:16.34},
{t:1624398480, d:16.35},
{t:1624398840, d:16.16},
{t:1624399200, d:16.13},
{t:1624399560, d:16.4},
{t:1624399920, d:16.17},
{t:1624400280, d:16.47},
{t:1624400640, d:16.84},
{t:1624401000, d:17.08},
{t:1624401360, d:16.97},
{t:1624401720, d:17.36},
{t:1624402080, d:17.05},
{t:1624402440, d:17.01},
{t:1624402800, d:16.7},
{t:1624403160, d:16.4},
{t:1624403520, d:16.56},
{t:1624403880, d:16.44},
{t:1624404240, d:16.91},
{t:1624404600, d:17.29},
{t:1624404960, d:17.04},
{t:1624405320, d:17.12},
{t:1624405680, d:16.79},
{t:1624406040, d:17.06},
{t:1624406400, d:17.19},
{t:1624406760, d:16.9},
{t:1624407120, d:17.22},
{t:1624407480, d:17.56},
{t:1624407840, d:17.75},
{t:1624408200, d:17.85},
{t:1624408560, d:17.53},
{t:1624408920, d:17.34},
{t:1624409280, d:17.38},
{t:1624409640, d:17.08},
{t:1624410000, d:17.2},
{t:1624410360, d:17.13},
{t:1624410720, d:16.92},
{t:1624411080, d:16.92},
{t:1624411440, d:17.02},
{t:1624411800, d:17.34},
{t:1624412160, d:17.43},
{t:1624412520, d:17.3},
{t:1624412880, d:17.35},
{t:1624413240, d:17.51},
{t:1624413600, d:17.75},
{t:1624413960, d:18.15},
{t:1624414320, d:18.4},
{t:1624414680, d:18.55},
{t:1624415040, d:18.69},
{t:1624415400, d:18.58},
{t:1624415760, d:18.5},
{t:1624416120, d:18.73},
{t:1624416480, d:18.65},
{t:1624416840, d:18.84},
{t:1624417200, d:19.06},
{t:1624417560, d:19.08},
{t:1624417920, d:18.79},
{t:1624418280, d:19.01},
{t:1624418640, d:19.4},
{t:1624419000, d:19.11},
{t:1624419360, d:19.47},
{t:1624419720, d:19.34},
{t:1624420080, d:18.97},
{t:1624420440, d:19.12},
{t:1624420800, d:19.07},
{t:1624421160, d:19.34},
{t:1624421520, d:19.68},
{t:1624421880, d:19.59},
{t:1624422240, d:19.43},
{t:1624422600, d:19.27},
{t:1624422960, d:19.19},
{t:1624423320, d:19.23},
{t:1624423680, d:19.57},
{t:1624424040, d:19.92},
{t:1624424400, d:19.73},
{t:1624424760, d:19.76},
{t:1624425120, d:20.15},
{t:1624425480, d:20.26},
{t:1624425840, d:20.23},
{t:1624426200, d:19.88},
{t:1624426560, d:19.87},
{t:1624426920, d:20.18},
{t:1624427280, d:20.2},
{t:1624427640, d:20.24},
{t:1624428000, d:19.92},
{t:1624428360, d:20.15},
{t:1624428720, d:20.12},
{t:1624429080, d:20.31},
{t:1624429440, d:20},
{t:1624429800, d:19.99},
{t:1624430160, d:19.62},
{t:1624430520, d:20},
{t:1624430880, d:19.77},
{t:1624431240, d:20.07},
{t:1624431600, d:19.9},
{t:1624431960, d:19.68},
{t:1624432320, d:20.01},
{t:1624432680, d:19.91},
{t:1624433040, d:19.84},
{t:1624433400, d:19.64},
{t:1624433760, d:19.71},
{t:1624434120, d:20.09},
{t:1624434480, d:19.84},
{t:1624434840, d:20.21},
{t:1624435200, d:20.08},
{t:1624435560, d:19.82},
{t:1624435920, d:19.85},
{t:1624436280, d:20.03},
{t:1624436640, d:19.7},
{t:1624437000, d:19.48},
{t:1624437360, d:19.71},
{t:1624437720, d:19.68},
{t:1624438080, d:19.88},
{t:1624438440, d:19.9},
{t:1624438800, d:20.15},
{t:1624439160, d:20.18},
{t:1624439520, d:20.3},
{t:1624439880, d:20.55},
{t:1624440240, d:20.12},
{t:1624440600, d:19.7},
{t:1624440960, d:19.99},
{t:1624441320, d:20},
{t:1624441680, d:19.76},
{t:1624442040, d:19.75},
{t:1624442400, d:19.56},
{t:1624442760, d:19.69},
{t:1624443120, d:19.29},
{t:1624443480, d:19.05},
{t:1624443840, d:19.21},
{t:1624444200, d:18.98},
{t:1624444560, d:19.34},
{t:1624444920, d:19.29},
{t:1624445280, d:19.13},
{t:1624445640, d:19.38},
{t:1624446000, d:19.24},
{t:1624446360, d:19.01},
{t:1624446720, d:19.24},
{t:1624447080, d:19.26},
{t:1624447440, d:19.52},
{t:1624447800, d:19.85},
{t:1624448160, d:19.89},
{t:1624448520, d:19.85},
{t:1624448880, d:19.62},
{t:1624449240, d:19.53},
{t:1624449600, d:19.53},
{t:1624449960, d:19.7},
{t:1624450320, d:19.33},
{t:1624450680, d:19.24},
{t:1624451040, d:19.48},
{t:1624451400, d:19.13},
{t:1624451760, d:19.43},
{t:1624452120, d:19.65},
{t:1624452480, d:19.85},
{t:1624452840, d:19.91},
{t:1624453200, d:19.51},
{t:1624453560, d:19.13},
{t:1624453920, d:19.12},
{t:1624454280, d:18.72},
{t:1624454640, d:18.41},
{t:1624455000, d:17.99},
{t:1624455360, d:17.95},
{t:1624455720, d:17.59},
{t:1624456080, d:17.63},
{t:1624456440, d:17.26},
{t:1624456800, d:16.98},
{t:1624457160, d:16.73},
{t:1624457520, d:16.47},
{t:1624457880, d:16.39},
{t:1624458240, d:16.41},
{t:1624458600, d:16.71},
{t:1624458960, d:16.67},
{t:1624459320, d:17},
{t:1624459680, d:16.86},
{t:1624460040, d:16.94},
{t:1624460400, d:16.67},
{t:1624460760, d:16.38},
{t:1624461120, d:16.67},
{t:1624461480, d:16.97},
{t:1624461840, d:16.93},
{t:1624462200, d:16.84},
{t:1624462560, d:16.71},
{t:1624462920, d:16.66},
{t:1624463280, d:16.88},
{t:1624463640, d:16.84},
{t:1624464000, d:17.22},
{t:1624464360, d:17.38},
{t:1624464720, d:17.49},
{t:1624465080, d:17.66},
{t:1624465440, d:17.56},
{t:1624465800, d:17.83},
{t:1624466160, d:18.12},
{t:1624466520, d:18.12},
{t:1624466880, d:17.96},
{t:1624467240, d:17.68},
{t:1624467600, d:17.81},
{t:1624467960, d:17.76},
{t:1624468320, d:17.98},
{t:1624468680, d:18.05},
{t:1624469040, d:18.13},
{t:1624469400, d:17.72},
{t:1624469760, d:17.75},
{t:1624470120, d:17.37},
{t:1624470480, d:17.62},
{t:1624470840, d:17.75},
{t:1624471200, d:17.91},
{t:1624471560, d:18.2},
{t:1624471920, d:17.99},
{t:1624472280, d:17.69},
{t:1624472640, d:18.01},
{t:1624473000, d:17.95},
{t:1624473360, d:17.55},
{t:1624473720, d:17.53},
{t:1624474080, d:17.72},
{t:1624474440, d:17.36},
{t:1624474800, d:17.7},
{t:1624475160, d:17.98},
{t:1624475520, d:18.36},
{t:1624475880, d:18.59},
{t:1624476240, d:18.87},
{t:1624476600, d:18.72},
{t:1624476960, d:18.42},
{t:1624477320, d:18.18},
{t:1624477680, d:18.47},
{t:1624478040, d:18.84},
{t:1624478400, d:19.16},
{t:1624478760, d:18.95},
{t:1624479120, d:19.05},
{t:1624479480, d:18.96},
{t:1624479840, d:18.7},
{t:1624480200, d:18.54},
{t:1624480560, d:18.37},
{t:1624480920, d:18.02},
{t:1624481280, d:18.24},
{t:1624481640, d:18.04},
{t:1624482000, d:18.21},
{t:1624482360, d:18.36},
{t:1624482720, d:18.41},
{t:1624483080, d:18.34},
{t:1624483440, d:18.05},
{t:1624483800, d:18.18},
{t:1624484160, d:18.14},
{t:1624484520, d:17.91},
{t:1624484880, d:18.31},
{t:1624485240, d:18.52},
{t:1624485600, d:18.44},
{t:1624485960, d:18.7},
{t:1624486320, d:18.53},
{t:1624486680, d:18.72},
{t:1624487040, d:18.78},
{t:1624487400, d:18.41},
{t:1624487760, d:18.18},
{t:1624488120, d:18.28},
{t:1624488480, d:18.29},
{t:1624488840, d:18.64},
{t:1624489200, d:18.38},
{t:1624489560, d:18.49},
{t:1624489920, d:18.36},
{t:1624490280, d:18.72},
{t:1624490640, d:18.5},
{t:1624491000, d:18.49},
{t:1624491360, d:18.75},
{t:1624491720, d:18.59},
{t:1624492080, d:18.8},
{t:1624492440, d:18.58},
{t:1624492800, d:18.57},
{t:1624493160, d:18.68},
{t:1624493520, d:18.55},
{t:1624493880, d:18.24},
{t:1624494240, d:18.11},
{t:1624494600, d:18.04},
{t:1624494960, d:17.84},
{t:1624495320, d:17.81},
{t:1624495680, d:17.93},
{t:1624496040, d:17.7},
{t:1624496400, d:17.9},
{t:1624496760, d:17.94},
{t:1624497120, d:18.19},
{t:1624497480, d:18.18},
{t:1624497840, d:18.5},
{t:1624498200, d:18.48},
{t:1624498560, d:18.48},
{t:1624498920, d:18.83},
{t:1624499280, d:18.83},
{t:1624499640, d:18.95},
{t:1624500000, d:18.85},
{t:1624500360, d:18.65},
{t:1624500720, d:18.41},
{t:1624501080, d:18.13},
{t:1624501440, d:18.36},
{t:1624501800, d:18.56},
{t:1624502160, d:18.45},
{t:1624502520, d:18.32},
{t:1624502880, d:18.37},
{t:1624503240, d:18.52},
{t:1624503600, d:18.4},
{t:1624503960, d:18.85},
{t:1624504320, d:19.15},
{t:1624504680, d:18.94},
{t:1624505040, d:18.88},
{t:1624505400, d:19.26},
{t:1624505760, d:19.42},
{t:1624506120, d:19.59},
{t:1624506480, d:19.62},
{t:1624506840, d:20.02},
{t:1624507200, d:20.03},
{t:1624507560, d:20.41},
{t:1624507920, d:20.36},
{t:1624508280, d:20.48},
{t:1624508640, d:20.87},
{t:1624509000, d:20.83},
{t:1624509360, d:20.58},
{t:1624509720, d:20.57},
{t:1624510080, d:20.51},
{t:1624510440, d:20.64},
{t:1624510800, d:20.6},
{t:1624511160, d:20.88},
{t:1624511520, d:20.52},
{t:1624511880, d:20.35},
{t:1624512240, d:20.11},
{t:1624512600, d:20.17},
{t:1624512960, d:20.5},
{t:1624513320, d:20.24},
{t:1624513680, d:20.44},
{t:1624514040, d:20.77},
{t:1624514400, d:20.7},
{t:1624514760, d:20.54},
{t:1624515120, d:20.4},
{t:1624515480, d:20.32},
{t:1624515840, d:19.97},
{t:1624516200, d:20.01},
{t:1624516560, d:20.15},
{t:1624516920, d:20.27},
{t:1624517280, d:20.44},
{t:1624517640, d:20.69},
{t:1624518000, d:20.67},
{t:1624518360, d:20.59},
{t:1624518720, d:20.27},
{t:1624519080, d:20.57},
{t:1624519440, d:20.19},
{t:1624519800, d:20.46},
{t:1624520160, d:20.82},
{t:1624520520, d:21.11},
{t:1624520880, d:21.02},
{t:1624521240, d:21.37},
{t:1624521600, d:21.48},
{t:1624521960, d:21.09},
{t:1624522320, d:21.04},
{t:1624522680, d:21.1},
{t:1624523040, d:21.23},
{t:1624523400, d:21.3},
{t:1624523760, d:21.37},
{t:1624524120, d:21.13},
{t:1624524480, d:21.19},
{t:1624524840, d:21.27},
{t:1624525200, d:21.08},
{t:1624525560, d:20.67},
{t:1624525920, d:20.35},
{t:1624526280, d:19.92},
{t:1624526640, d:20.02},
{t:1624527000, d:19.69},
{t:1624527360, d:19.72},
{t:1624527720, d:19.79},
{t:1624528080, d:19.92},
{t:1624528440, d:20.05},
{t:1624528800, d:20.34},
{t:1624529160, d:20.07},
{t:1624529520, d:20.28},
{t:1624529880, d:19.96},
{t:1624530240, d:20.3},
{t:1624530600, d:19.85},
{t:1624530960, d:19.5},
{t:1624531320, d:19.23},
{t:1624531680, d:19.44},
{t:1624532040, d:19.1},
{t:1624532400, d:18.9},
{t:1624532760, d:18.85},
{t:1624533120, d:18.49},
{t:1624533480, d:18.66},
{t:1624533840, d:18.52},
{t:1624534200, d:18.54},
{t:1624534560, d:18.74},
{t:1624534920, d:18.96},
{t:1624535280, d:19.14},
{t:1624535640, d:18.89},
{t:1624536000, d:18.82},
{t:1624536360, d:18.65},
{t:1624536720, d:18.7},
{t:1624537080, d:18.48},
{t:1624537440, d:18.38},
{t:1624537800, d:18.6},
{t:1624538160, d:18.23},
{t:1624538520, d:17.96},
{t:1624538880, d:17.97},
{t:1624539240, d:18.04},
{t:1624539600, d:18.3},
{t:1624539960, d:18.18},
{t:1624540320, d:17.91},
{t:1624540680, d:17.83},
{t:1624541040, d:18.14},
{t:1624541400, d:17.76},
{t:1624541760, d:17.72},
{t:1624542120, d:17.71},
{t:1624542480, d:17.33},
{t:1624542840, d:17.44},
{t:1624543200, d:17.14},
{t:1624543560, d:16.94},
{t:1624543920, d:16.98},
{t:1624544280, d:16.62},
{t:1624544640, d:16.37},
{t:1624545000, d:15.98},
{t:1624545360, d:15.9},
{t:1624545720, d:16.31},
{t:1624546080, d:15.92},
{t:1624546440, d:15.74},
{t:1624546800, d:15.59},
{t:1624547160, d:15.81},
{t:1624547520, d:16.19},
{t:1624547880, d:16.09},
{t:1624548240, d:15.96},
{t:1624548600, d:16.12},
{t:1624548960, d:16.22},
{t:1624549320, d:16.11},
{t:1624549680, d:16.22},
{t:1624550040, d:16.21},
{t:1624550400, d:16.29},
{t:1624550760, d:16.64},
{t:1624551120, d:16.47},
{t:1624551480, d:16.33},
{t:1624551840, d:16.43},
{t:1624552200, d:16.36},
{t:1624552560, d:16.05},
{t:1624552920, d:15.95},
{t:1624553280, d:16.17},
{t:1624553640, d:15.85},
{t:1624554000, d:16.13},
{t:1624554360, d:16.42},
{t:1624554720, d:16.32},
{t:1624555080, d:16.38},
{t:1624555440, d:16.51},
{t:1624555800, d:16.9},
{t:1624556160, d:16.67},
{t:1624556520, d:16.97},
{t:1624556880, d:16.94},
{t:1624557240, d:16.88},
{t:1624557600, d:16.77},
{t:1624557960, d:16.88},
{t:1624558320, d:16.68},
{t:1624558680, d:16.59},
{t:1624559040, d:16.85},
{t:1624559400, d:16.58},
{t:1624559760, d:16.48},
{t:1624560120, d:16.42},
{t:1624560480, d:16.79},
{t:1624560840, d:17.17},
{t:1624561200, d:16.81},
{t:1624561560, d:16.51},
{t:1624561920, d:16.58},
{t:1624562280, d:16.4},
{t:1624562640, d:16.56},
{t:1624563000, d:16.54},
{t:1624563360, d:16.48},
{t:1624563720, d:16.61},
{t:1624564080, d:16.29},
{t:1624564440, d:16.47},
{t:1624564800, d:16.77},
{t:1624565160, d:16.87},
{t:1624565520, d:17.03},
{t:1624565880, d:16.67},
{t:1624566240, d:17.01},
{t:1624566600, d:17.16},
{t:1624566960, d:16.95},
{t:1624567320, d:17.06},
{t:1624567680, d:16.84},
{t:1624568040, d:16.87},
{t:1624568400, d:16.74},
{t:1624568760, d:16.76},
{t:1624569120, d:16.77},
{t:1624569480, d:16.52},
{t:1624569840, d:16.84},
{t:1624570200, d:16.98},
{t:1624570560, d:17.27},
{t:1624570920, d:17.41},
{t:1624571280, d:17.74},
{t:1624571640, d:18.17},
{t:1624572000, d:18.19},
{t:1624572360, d:17.89},
{t:1624572720, d:18.2},
{t:1624573080, d:18.05},
{t:1624573440, d:18.3},
{t:1624573800, d:18},
{t:1624574160, d:18.38},
{t:1624574520, d:18.57},
{t:1624574880, d:18.93},
{t:1624575240, d:19.18},
{t:1624575600, d:19.16},
{t:1624575960, d:19.3},
{t:1624576320, d:18.95},
{t:1624576680, d:18.61},
{t:1624577040, d:18.35},
{t:1624577400, d:18.53},
{t:1624577760, d:18.46},
{t:1624578120, d:18.86},
{t:1624578480, d:19.16},
{t:1624578840, d:19.15},
{t:1624579200, d:18.93},
{t:1624579560, d:19.34},
{t:1624579920, d:19.14},
{t:1624580280, d:19.54},
{t:1624580640, d:19.26},
{t:1624581000, d:19.51},
{t:1624581360, d:19.51},
{t:1624581720, d:19.24},
{t:1624582080, d:18.88},
{t:1624582440, d:19.1},
{t:1624582800, d:19.37},
{t:1624583160, d:19.56},
{t:1624583520, d:19.62},
{t:1624583880, d:19.52},
{t:1624584240, d:19.26},
{t:1624584600, d:18.9},
{t:1624584960, d:19.29},
{t:1624585320, d:19.22},
{t:1624585680, d:18.91},
{t:1624586040, d:18.57},
{t:1624586400, d:18.78},
{t:1624586760, d:19.11},
{t:1624587120, d:18.86},
{t:1624587480, d:18.84},
{t:1624587840, d:18.89},
{t:1624588200, d:19.11},
{t:1624588560, d:19.5},
{t:1624588920, d:19.65},
{t:1624589280, d:19.35},
{t:1624589640, d:19.36},
{t:1624590000, d:19.63},
{t:1624590360, d:19.84},
{t:1624590720, d:20.17},
{t:1624591080, d:20.29},
{t:1624591440, d:20.05},
{t:1624591800, d:20.33},
{t:1624592160, d:19.95},
{t:1624592520, d:20.18},
{t:1624592880, d:19.87},
{t:1624593240, d:20.27},
{t:1624593600, d:20.67},
{t:1624593960, d:20.97},
{t:1624594320, d:21.1},
{t:1624594680, d:20.73},
{t:1624595040, d:20.77},
{t:1624595400, d:21.11},
{t:1624595760, d:20.7},
{t:1624596120, d:20.99},
{t:1624596480, d:20.59},
{t:1624596840, d:20.54},
{t:1624597200, d:20.62},
{t:1624597560, d:20.73},
{t:1624597920, d:21.05},
{t:1624598280, d:21.1},
{t:1624598640, d:21.3},
{t:1624599000, d:21.17},
{t:1624599360, d:20.83},
{t:1624599720, d:20.96},
{t:1624600080, d:21.25},
{t:1624600440, d:20.95},
{t:1624600800, d:20.64},
{t:1624601160, d:20.93},
{t:1624601520, d:20.9},
{t:1624601880, d:21.18},
{t:1624602240, d:20.82},
{t:1624602600, d:20.58},
{t:1624602960, d:20.4},
{t:1624603320, d:20.5},
{t:1624603680, d:20.82},
{t:1624604040, d:20.86},
{t:1624604400, d:20.99},
{t:1624604760, d:20.69},
{t:1624605120, d:20.47},
{t:1624605480, d:20.43},
{t:1624605840, d:20.77},
{t:1624606200, d:20.92},
{t:1624606560, d:21.02},
{t:1624606920, d:20.64},
{t:1624607280, d:20.25},
{t:1624607640, d:20.2},
{t:1624608000, d:19.82},
{t:1624608360, d:19.62},
{t:1624608720, d:19.85},
{t:1624609080, d:19.94},
{t:1624609440, d:20.26},
{t:1624609800, d:20.06},
{t:1624610160, d:19.89},
{t:1624610520, d:19.83},
{t:1624610880, d:20.03},
{t:1624611240, d:19.97},
{t:1624611600, d:20.03},
{t:1624611960, d:20.01},
{t:1624612320, d:20},
{t:1624612680, d:19.76},
{t:1624613040, d:19.88},
{t:1624613400, d:19.64},
{t:1624613760, d:19.37},
{t:1624614120, d:19.53},
{t:1624614480, d:19.88},
{t:1624614840, d:19.47},
{t:1624615200, d:19.31},
{t:1624615560, d:19.09},
{t:1624615920, d:18.92},
{t:1624616280, d:19.2},
{t:1624616640, d:19.46},
{t:1624617000, d:19.26},
{t:1624617360, d:18.95},
{t:1624617720, d:18.82},
{t:1624618080, d:19.02},
{t:1624618440, d:19.3},
{t:1624618800, d:18.93},
{t:1624619160, d:18.82},
{t:1624619520, d:18.83},
{t:1624619880, d:18.75},
{t:1624620240, d:18.37},
{t:1624620600, d:18.6},
{t:1624620960, d:18.51},
{t:1624621320, d:18.57},
{t:1624621680, d:18.6},
{t:1624622040, d:18.5},
{t:1624622400, d:18.55},
{t:1624622760, d:18.82},
{t:1624623120, d:18.48},
{t:1624623480, d:18.82},
{t:1624623840, d:18.52},
{t:1624624200, d:18.48},
{t:1624624560, d:18.65},
{t:1624624920, d:18.78},
{t:1624625280, d:18.91},
{t:1624625640, d:18.69},
{t:1624626000, d:18.63},
{t:1624626360, d:18.65},
{t:1624626720, d:18.92},
{t:1624627080, d:19.14},
{t:1624627440, d:19.38},
{t:1624627800, d:19.07},
{t:1624628160, d:19.18},
{t:1624628520, d:19.06},
{t:1624628880, d:18.92},
{t:1624629240, d:18.47},
{t:1624629600, d:18.05},
{t:1624629960, d:17.87},
{t:1624630320, d:18.11},
{t:1624630680, d:18.43},
{t:1624631040, d:18.16},
{t:1624631400, d:17.95},
{t:1624631760, d:18},
{t:1624632120, d:17.8},
{t:1624632480, d:17.47},
{t:1624632840, d:17.58},
{t:1624633200, d:17.67},
{t:1624633560, d:17.45},
{t:1624633920, d:17.06},
{t:1624634280, d:17.25},
{t:1624634640, d:17.61},
{t:1624635000, d:17.36},
{t:1624635360, d:17.26},
{t:1624635720, d:17.22},
{t:1624636080, d:16.89},
{t:1624636440, d:16.67},
{t:1624636800, d:16.82},
{t:1624637160, d:16.73},
{t:1624637520, d:16.66},
{t:1624637880, d:16.55},
{t:1624638240, d:16.82},
{t:1624638600, d:16.9},
{t:1624638960, d:17.04},
{t:1624639320, d:16.9},
{t:1624639680, d:16.64},
{t:1624640040, d:16.8},
{t:1624640400, d:16.74},
{t:1624640760, d:17.01},
{t:1624641120, d:16.85},
{t:1624641480, d:16.72},
{t:1624641840, d:16.58},
{t:1624642200, d:16.75},
{t:1624642560, d:16.54},
{t:1624642920, d:16.67},
{t:1624643280, d:16.64},
{t:1624643640, d:16.6},
{t:1624644000, d:16.67},
{t:1624644360, d:16.82},
{t:1624644720, d:17.06},
{t:1624645080, d:17.33},
{t:1624645440, d:17.34},
{t:1624645800, d:17.21},
{t:1624646160, d:17.58},
{t:1624646520, d:17.91},
{t:1624646880, d:17.74},
{t:1624647240, d:17.45},
{t:1624647600, d:17.51},
{t:1624647960, d:17.85},
{t:1624648320, d:17.78},
{t:1624648680, d:18.11},
{t:1624649040, d:17.71},
{t:1624649400, d:18.06},
{t:1624649760, d:18.41},
{t:1624650120, d:18.66},
{t:1624650480, d:19.04},
{t:1624650840, d:19.17},
{t:1624651200, d:19.13},
{t:1624651560, d:19.22},
{t:1624651920, d:18.99},
{t:1624652280, d:19.06},
{t:1624652640, d:19.11},
{t:1624653000, d:18.7},
{t:1624653360, d:18.34},
{t:1624653720, d:18.32},
{t:1624654080, d:18.29},
{t:1624654440, d:18.04},
{t:1624654800, d:17.98},
{t:1624655160, d:18.22},
{t:1624655520, d:18.37},
{t:1624655880, d:18.18},
{t:1624656240, d:18.4},
{t:1624656600, d:18.73},
{t:1624656960, d:18.99},
{t:1624657320, d:19.38},
{t:1624657680, d:19.76},
{t:1624658040, d:19.54},
{t:1624658400, d:19.51},
{t:1624658760, d:19.3},
{t:1624659120, d:19.18},
{t:1624659480, d:19.15},
{t:1624659840, d:18.96},
{t:1624660200, d:18.65},
{t:1624660560, d:18.63},
{t:1624660920, d:18.59},
{t:1624661280, d:18.89},
{t:1624661640, d:19.21},
{t:1624662000, d:19.23},
{t:1624662360, d:19.24},
{t:1624662720, d:19.35},
{t:1624663080, d:19.42},
{t:1624663440, d:19.5},
{t:1624663800, d:19.32},
{t:1624664160, d:19.7},
{t:1624664520, d:19.97},
{t:1624664880, d:19.96},
{t:1624665240, d:20.2},
{t:1624665600, d:19.84},
{t:1624665960, d:20.04},
{t:1624666320, d:20.37},
{t:1624666680, d:20.51},
{t:1624667040, d:20.84},
{t:1624667400, d:20.71},
{t:1624667760, d:20.75},
{t:1624668120, d:20.63},
{t:1624668480, d:20.77},
{t:1624668840, d:20.74},
{t:1624669200, d:20.52},
{t:1624669560, d:20.34},
{t:1624669920, d:20.16},
{t:1624670280, d:20.3},
{t:1624670640, d:20.57},
{t:1624671000, d:20.77},
{t:1624671360, d:20.65},
{t:1624671720, d:20.38},
{t:1624672080, d:20.02},
{t:1624672440, d:19.89},
{t:1624672800, d:19.83},
{t:1624673160, d:19.6},
{t:1624673520, d:19.81},
{t:1624673880, d:19.74},
{t:1624674240, d:19.43},
{t:1624674600, d:19.68},
{t:1624674960, d:19.9},
{t:1624675320, d:19.65},
{t:1624675680, d:19.59},
{t:1624676040, d:19.52},
{t:1624676400, d:19.16},
{t:1624676760, d:18.91},
{t:1624677120, d:18.77},
{t:1624677480, d:18.44},
{t:1624677840, d:18.3},
{t:1624678200, d:18.56},
{t:1624678560, d:18.29},
{t:1624678920, d:18.16},
{t:1624679280, d:18.6},
{t:1624679640, d:18.86},
{t:1624680000, d:19.2},
{t:1624680360, d:19.42},
{t:1624680720, d:19.07},
{t:1624681080, d:19.46},
{t:1624681440, d:19.2},
{t:1624681800, d:18.98},
{t:1624682160, d:18.97},
{t:1624682520, d:19.2},
{t:1624682880, d:19.35},
{t:1624683240, d:19.23},
{t:1624683600, d:19.38},
{t:1624683960, d:19.76},
{t:1624684320, d:19.76},
{t:1624684680, d:19.89},
{t:1624685040, d:20.03},
{t:1624685400, d:19.69},
{t:1624685760, d:19.98},
{t:1624686120, d:19.92},
{t:1624686480, d:19.93},
{t:1624686840, d:19.95},
{t:1624687200, d:20.35},
{t:1624687560, d:20.45},
{t:1624687920, d:20.14},
{t:1624688280, d:20.03},
{t:1624688640, d:20.28},
{t:1624689000, d:20.09},
{t:1624689360, d:19.98},
{t:1624689720, d:19.81},
{t:1624690080, d:19.96},
{t:1624690440, d:20.27},
{t:1624690800, d:20.22},
{t:1624691160, d:20.58},
{t:1624691520, d:20.58},
{t:1624691880, d:20.58},
{t:1624692240, d:20.17},
{t:1624692600, d:20.3},
{t:1624692960, d:20.43},
{t:1624693320, d:20.02},
{t:1624693680, d:20.04},
{t:1624694040, d:20.13},
{t:1624694400, d:19.93},
{t:1624694760, d:20.12},
{t:1624695120, d:19.84},
{t:1624695480, d:20.03},
{t:1624695840, d:20.17},
{t:1624696200, d:19.79},
{t:1624696560, d:19.7},
{t:1624696920, d:20.01},
{t:1624697280, d:20.19},
{t:1624697640, d:19.75},
{t:1624698000, d:19.6},
{t:1624698360, d:19.86},
{t:1624698720, d:20.11},
{t:1624699080, d:19.93},
{t:1624699440, d:19.96},
{t:1624699800, d:20.29},
{t:1624700160, d:20.45},
{t:1624700520, d:20.41},
{t:1624700880, d:20.35},
{t:1624701240, d:20.05},
{t:1624701600, d:19.82},
{t:1624701960, d:20.02},
{t:1624702320, d:19.98},
{t:1624702680, d:19.65},
{t:1624703040, d:19.3},
{t:1624703400, d:18.92},
{t:1624703760, d:18.97},
{t:1624704120, d:18.73},
{t:1624704480, d:18.36},
{t:1624704840, d:18.7},
{t:1624705200, d:18.72},
{t:1624705560, d:18.64},
{t:1624705920, d:18.44},
{t:1624706280, d:18.44},
{t:1624706640, d:18.26},
{t:1624707000, d:17.98},
{t:1624707360, d:18.1},
{t:1624707720, d:17.91},
{t:1624708080, d:18.17},
{t:1624708440, d:18.37},
{t:1624708800, d:18.43},
{t:1624709160, d:18.6},
{t:1624709520, d:18.75},
{t:1624709880, d:18.68},
{t:1624710240, d:18.39},
{t:1624710600, d:18.55},
{t:1624710960, d:18.53},
{t:1624711320, d:18.34},
{t:1624711680, d:17.92},
{t:1624712040, d:17.49},
{t:1624712400, d:17.79},
{t:1624712760, d:18.08},
{t:1624713120, d:17.89},
{t:1624713480, d:18.16},
{t:1624713840, d:18.4},
{t:1624714200, d:18.17},
{t:1624714560, d:17.98},
{t:1624714920, d:17.7},
{t:1624715280, d:17.31},
{t:1624715640, d:17.06},
{t:1624716000, d:16.67},
{t:1624716360, d:17.02},
{t:1624716720, d:17.07},
{t:1624717080, d:17.13},
{t:1624717440, d:17.3},
{t:1624717800, d:16.95},
{t:1624718160, d:16.98},
{t:1624718520, d:16.77},
{t:1624718880, d:16.92},
{t:1624719240, d:16.77},
{t:1624719600, d:16.65},
{t:1624719960, d:16.39},
{t:1624720320, d:16.07},
{t:1624720680, d:16.14},
{t:1624721040, d:15.82},
{t:1624721400, d:16.21},
{t:1624721760, d:16.37},
{t:1624722120, d:16.43},
{t:1624722480, d:16.59},
{t:1624722840, d:16.66},
{t:1624723200, d:16.53},
{t:1624723560, d:16.55},
{t:1624723920, d:16.41},
{t:1624724280, d:16.68},
{t:1624724640, d:16.27},
{t:1624725000, d:16.39},
{t:1624725360, d:16.15},
{t:1624725720, d:16.53},
{t:1624726080, d:16.68},
{t:1624726440, d:16.32},
{t:1624726800, d:16.55},
{t:1624727160, d:16.44},
{t:1624727520, d:16.45},
{t:1624727880, d:16.13},
{t:1624728240, d:16.3},
{t:1624728600, d:16.33},
{t:1624728960, d:16.55},
{t:1624729320, d:16.35},
{t:1624729680, d:16.24},
{t:1624730040, d:16.5},
{t:1624730400, d:16.6},
{t:1624730760, d:16.68},
{t:1624731120, d:16.83},
{t:1624731480, d:16.65},
{t:1624731840, d:16.27},
{t:1624732200, d:15.96},
{t:1624732560, d:15.64},
{t:1624732920, d:15.9},
{t:1624733280, d:15.86},
{t:1624733640, d:15.79},
{t:1624734000, d:15.47},
{t:1624734360, d:15.88},
{t:1624734720, d:16.17},
{t:1624735080, d:16.14},
{t:1624735440, d:15.85},
{t:1624735800, d:16},
{t:1624736160, d:16.08},
{t:1624736520, d:16.17},
{t:1624736880, d:15.95},
{t:1624737240, d:16.22},
{t:1624737600, d:16.14},
{t:1624737960, d:16.21},
{t:1624738320, d:16.15},
{t:1624738680, d:16.59},
{t:1624739040, d:16.88},
{t:1624739400, d:16.62},
{t:1624739760, d:16.58},
{t:1624740120, d:16.43},
{t:1624740480, d:16.75},
{t:1624740840, d:16.85},
{t:1624741200, d:16.84},
{t:1624741560, d:16.99},
{t:1624741920, d:16.74},
{t:1624742280, d:16.7},
{t:1624742640, d:16.91},
{t:1624743000, d:17.25},
{t:1624743360, d:17.18},
{t:1624743720, d:16.89},
{t:1624744080, d:16.71},
{t:1624744440, d:16.98},
{t:1624744800, d:16.93},
{t:1624745160, d:17.17},
{t:1624745520, d:17.08},
{t:1624745880, d:16.98},
{t:1624746240, d:16.96},
{t:1624746600, d:17.23},
{t:1624746960, d:17.31},
{t:1624747320, d:17.49},
{t:1624747680, d:17.67},
{t:1624748040, d:17.52},
{t:1624748400, d:17.96},
{t:1624748760, d:18.25},
{t:1624749120, d:18.34},
{t:1624749480, d:18.43},
{t:1624749840, d:18.35},
{t:1624750200, d:18.78},
{t:1624750560, d:19.21},
{t:1624750920, d:19.03},
{t:1624751280, d:19.45},
{t:1624751640, d:19.2},
{t:1624752000, d:19.25},
{t:1624752360, d:19.36},
{t:1624752720, d:19.26},
{t:1624753080, d:19.34},
{t:1624753440, d:19.11},
{t:1624753800, d:19.33},
{t:1624754160, d:19.44},
{t:1624754520, d:19.26},
{t:1624754880, d:19.07},
{t:1624755240, d:18.88},
{t:1624755600, d:18.99},
{t:1624755960, d:18.75},
{t:1624756320, d:18.57},
{t:1624756680, d:18.56},
{t:1624757040, d:18.7},
{t:1624757400, d:18.78},
{t:1624757760, d:18.74},
{t:1624758120, d:18.39},
{t:1624758480, d:18.67},
{t:1624758840, d:18.42},
{t:1624759200, d:18.23},
{t:1624759560, d:18.33},
{t:1624759920, d:18.08},
{t:1624760280, d:17.89},
{t:1624760640, d:18.17},
{t:1624761000, d:18.33},
{t:1624761360, d:18.49},
{t:1624761720, d:18.87},
{t:1624762080, d:19.26},
{t:1624762440, d:19.32},
{t:1624762800, d:19.58},
{t:1624763160, d:19.35},
{t:1624763520, d:19.45},
{t:1624763880, d:19.12},
{t:1624764240, d:19.19},
{t:1624764600, d:19.6},
{t:1624764960, d:19.27},
{t:1624765320, d:19.68},
{t:1624765680, d:19.53},
{t:1624766040, d:19.29},
{t:1624766400, d:19.6},
{t:1624766760, d:19.91},
{t:1624767120, d:20.24},
{t:1624767480, d:20},
{t:1624767840, d:20.09},
{t:1624768200, d:19.76},
{t:1624768560, d:19.7},
{t:1624768920, d:20.1},
{t:1624769280, d:20.17},
{t:1624769640, d:20.5},
{t:1624770000, d:20.49},
{t:1624770360, d:20.45},
{t:1624770720, d:20.2},
{t:1624771080, d:19.88},
{t:1624771440, d:19.81},
{t:1624771800, d:20.14},
{t:1624772160, d:19.92},
{t:1624772520, d:19.99},
{t:1624772880, d:19.75},
{t:1624773240, d:19.49},
{t:1624773600, d:19.17},
{t:1624773960, d:19.57},
{t:1624774320, d:19.9},
{t:1624774680, d:20.01},
{t:1624775040, d:20.16},
{t:1624775400, d:20.26},
{t:1624775760, d:20.44},
{t:1624776120, d:20.78},
{t:1624776480, d:21.06},
{t:1624776840, d:21.2},
{t:1624777200, d:21.26},
{t:1624777560, d:21.33},
{t:1624777920, d:21.3},
{t:1624778280, d:21.16},
{t:1624778640, d:21.38},
{t:1624779000, d:21.19},
{t:1624779360, d:20.91},
{t:1624779720, d:21.1},
{t:1624780080, d:21},
{t:1624780440, d:21.13},
{t:1624780800, d:21.08},
{t:1624781160, d:20.83},
{t:1624781520, d:21.08},
{t:1624781880, d:20.92},
{t:1624782240, d:21.06},
{t:1624782600, d:21.07},
{t:1624782960, d:21.12},
{t:1624783320, d:20.98},
{t:1624783680, d:21.15},
{t:1624784040, d:20.82},
{t:1624784400, d:21.09},
{t:1624784760, d:21.26},
{t:1624785120, d:21.58},
{t:1624785480, d:21.58},
{t:1624785840, d:21.9},
{t:1624786200, d:21.85},
{t:1624786560, d:21.66},
{t:1624786920, d:21.39},
{t:1624787280, d:21.2},
{t:1624787640, d:21.37},
{t:1624788000, d:21.49},
{t:1624788360, d:21.75},
{t:1624788720, d:21.46},
{t:1624789080, d:21.45},
{t:1624789440, d:21.75},
{t:1624789800, d:21.26},
{t:1624790160, d:21.2},
{t:1624790520, d:21.35},
{t:1624790880, d:21.34},
{t:1624791240, d:21.39},
{t:1624791600, d:21.65},
{t:1624791960, d:21.69},
{t:1624792320, d:21.48},
{t:1624792680, d:21.46},
{t:1624793040, d:20.99},
{t:1624793400, d:21.17},
{t:1624793760, d:21},
{t:1624794120, d:21.24},
{t:1624794480, d:21.11},
{t:1624794840, d:21.14},
{t:1624795200, d:20.73},
{t:1624795560, d:21.03},
{t:1624795920, d:21.01},
{t:1624796280, d:20.64},
{t:1624796640, d:20.3},
{t:1624797000, d:19.96},
{t:1624797360, d:20.18},
{t:1624797720, d:19.84},
{t:1624798080, d:20.06},
{t:1624798440, d:20.29},
{t:1624798800, d:19.89},
{t:1624799160, d:19.67},
{t:1624799520, d:19.66},
{t:1624799880, d:19.7},
{t:1624800240, d:19.29},
{t:1624800600, d:19.57},
{t:1624800960, d:19.57},
{t:1624801320, d:19.35},
{t:1624801680, d:19.36},
{t:1624802040, d:19.31},
{t:1624802400, d:18.86},
{t:1624802760, d:18.6},
{t:1624803120, d:18.37},
{t:1624803480, d:18.31},
{t:1624803840, d:18.05},
{t:1624804200, d:17.99},
{t:1624804560, d:17.66},
{t:1624804920, d:17.39},
{t:1624805280, d:17.58},
{t:1624805640, d:17.35},
{t:1624806000, d:17.25},
{t:1624806360, d:17.14},
{t:1624806720, d:17.33},
{t:1624807080, d:17},
{t:1624807440, d:17.38},
{t:1624807800, d:17.1},
{t:1624808160, d:17.33},
{t:1624808520, d:17.22},
{t:1624808880, d:17.5},
{t:1624809240, d:17.44},
{t:1624809600, d:17.65},
{t:1624809960, d:17.72},
{t:1624810320, d:17.93},
{t:1624810680, d:17.69},
{t:1624811040, d:17.5},
{t:1624811400, d:17.78},
{t:1624811760, d:17.47},
{t:1624812120, d:17.36},
{t:1624812480, d:17.01},
{t:1624812840, d:16.66},
{t:1624813200, d:16.6},
{t:1624813560, d:16.48},
{t:1624813920, d:16.76},
{t:1624814280, d:16.44},
{t:1624814640, d:16.45},
{t:1624815000, d:16.33},
{t:1624815360, d:16.01},
{t:1624815720, d:15.78},
{t:1624816080, d:15.57},
{t:1624816440, d:15.75},
{t:1624816800, d:16.08},
{t:1624817160, d:16.15},
{t:1624817520, d:16.18},
{t:1624817880, d:16.19},
{t:1624818240, d:16.26},
{t:1624818600, d:16.53},
{t:1624818960, d:16.78},
{t:1624819320, d:16.57},
{t:1624819680, d:16.3},
{t:1624820040, d:16.54},
{t:1624820400, d:16.76},
{t:1624820760, d:16.67},
{t:1624821120, d:16.83},
{t:1624821480, d:17.01},
{t:1624821840, d:16.91},
{t:1624822200, d:16.61},
{t:1624822560, d:16.32},
{t:1624822920, d:16.26},
{t:1624823280, d:15.91},
{t:1624823640, d:15.81},
{t:1624824000, d:15.75},
{t:1624824360, d:15.78},
{t:1624824720, d:16.19},
{t:1624825080, d:15.95},
{t:1624825440, d:15.94},
{t:1624825800, d:15.81},
{t:1624826160, d:15.53},
{t:1624826520, d:15.3},
{t:1624826880, d:15.21},
{t:1624827240, d:15.68},
{t:1624827600, d:15.41},
{t:1624827960, d:15.75},
{t:1624828320, d:16.08},
{t:1624828680, d:16.26},
{t:1624829040, d:16.67},
{t:1624829400, d:16.84},
{t:1624829760, d:16.85},
{t:1624830120, d:16.73},
{t:1624830480, d:16.75},
{t:1624830840, d:16.46},
{t:1624831200, d:16.39},
{t:1624831560, d:16.29},
{t:1624831920, d:16.1},
{t:1624832280, d:15.97},
{t:1624832640, d:15.83},
{t:1624833000, d:16.21},
{t:1624833360, d:16.45},
{t:1624833720, d:16.85},
{t:1624834080, d:17.16},
{t:1624834440, d:17.29},
{t:1624834800, d:17.64},
{t:1624835160, d:18.06},
{t:1624835520, d:18.23},
{t:1624835880, d:18.32},
{t:1624836240, d:18.33},
{t:1624836600, d:18.59},
{t:1624836960, d:18.63},
{t:1624837320, d:18.42},
{t:1624837680, d:18.22},
{t:1624838040, d:18.48},
{t:1624838400, d:18.25},
{t:1624838760, d:18.44},
{t:1624839120, d:18.25},
{t:1624839480, d:18.03},
{t:1624839840, d:18.24},
{t:1624840200, d:18.14},
{t:1624840560, d:17.89},
{t:1624840920, d:18.11},
{t:1624841280, d:17.99},
{t:1624841640, d:18.2},
{t:1624842000, d:18.59},
{t:1624842360, d:18.55},
{t:1624842720, d:18.65},
{t:1624843080, d:18.62},
{t:1624843440, d:18.72},
{t:1624843800, d:18.37},
{t:1624844160, d:18.75},
{t:1624844520, d:18.47},
{t:1624844880, d:18.58},
{t:1624845240, d:18.83},
{t:1624845600, d:18.61},
{t:1624845960, d:18.95},
{t:1624846320, d:18.62},
{t:1624846680, d:19.02},
{t:1624847040, d:19.02},
{t:1624847400, d:19.07},
{t:1624847760, d:19.49},
{t:1624848120, d:19.64},
{t:1624848480, d:19.9},
{t:1624848840, d:20.02},
{t:1624849200, d:20.02},
{t:1624849560, d:20.29},
{t:1624849920, d:20.01},
{t:1624850280, d:20.24},
{t:1624850640, d:19.95},
{t:1624851000, d:20.35},
{t:1624851360, d:20.56},
{t:1624851720, d:20.83},
{t:1624852080, d:20.66},
{t:1624852440, d:20.73},
{t:1624852800, d:21.04},
{t:1624853160, d:21.19},
{t:1624853520, d:21.5},
{t:1624853880, d:21.32},
{t:1624854240, d:21.46},
{t:1624854600, d:21.52},
{t:1624854960, d:21.56},
{t:1624855320, d:21.34},
{t:1624855680, d:21.37},
{t:1624856040, d:21.03},
{t:1624856400, d:20.82},
{t:1624856760, d:21.15},
{t:1624857120, d:20.79},
{t:1624857480, d:20.39},
{t:1624857840, d:20.7},
{t:1624858200, d:20.62},
{t:1624858560, d:20.94},
{t:1624858920, d:20.58},
{t:1624859280, d:20.95},
{t:1624859640, d:20.89},
{t:1624860000, d:21.14},
{t:1624860360, d:21.01},
{t:1624860720, d:20.99},
{t:1624861080, d:20.86},
{t:1624861440, d:20.46},
{t:1624861800, d:20.63},
{t:1624862160, d:20.57},
{t:1624862520, d:20.74},
{t:1624862880, d:20.94},
{t:1624863240, d:21.13},
{t:1624863600, d:20.8},
{t:1624863960, d:20.66},
{t:1624864320, d:20.99},
{t:1624864680, d:20.62},
{t:1624865040, d:20.32},
{t:1624865400, d:20.27},
{t:1624865760, d:20.09},
{t:1624866120, d:19.75},
{t:1624866480, d:19.51},
{t:1624866840, d:19.82},
{t:1624867200, d:19.79},
{t:1624867560, d:20},
{t:1624867920, d:19.75},
{t:1624868280, d:19.94},
{t:1624868640, d:19.83},
{t:1624869000, d:19.45},
{t:1624869360, d:19.79},
{t:1624869720, d:19.8},
{t:1624870080, d:19.51},
{t:1624870440, d:19.88},
{t:1624870800, d:19.72},
{t:1624871160, d:20.02},
{t:1624871520, d:20.12},
{t:1624871880, d:20.34},
{t:1624872240, d:20.14},
{t:1624872600, d:20.19},
{t:1624872960, d:20.38},
{t:1624873320, d:20.52},
{t:1624873680, d:20.07},
{t:1624874040, d:20.06},
{t:1624874400, d:19.88},
{t:1624874760, d:19.8},
{t:1624875120, d:19.8},
{t:1624875480, d:20.04},
{t:1624875840, d:20.37},
{t:1624876200, d:20.66},
{t:1624876560, d:20.34},
{t:1624876920, d:20.62},
{t:1624877280, d:20.85},
{t:1624877640, d:20.66},
{t:1624878000, d:20.44},
{t:1624878360, d:20.73},
{t:1624878720, d:20.68},
{t:1624879080, d:20.63},
{t:1624879440, d:20.81},
{t:1624879800, d:20.67},
{t:1624880160, d:20.64},
{t:1624880520, d:20.54},
{t:1624880880, d:20.35},
{t:1624881240, d:20.28},
{t:1624881600, d:19.88},
{t:1624881960, d:19.99},
{t:1624882320, d:20.26},
{t:1624882680, d:20.58},
{t:1624883040, d:20.14},
{t:1624883400, d:20.28},
{t:1624883760, d:20.01},
{t:1624884120, d:20.06},
{t:1624884480, d:20.15},
{t:1624884840, d:20.19},
{t:1624885200, d:19.73},
{t:1624885560, d:19.78},
{t:1624885920, d:19.59},
{t:1624886280, d:19.75},
{t:1624886640, d:19.77},
{t:1624887000, d:19.81},
{t:1624887360, d:19.59},
{t:1624887720, d:19.81},
{t:1624888080, d:20.13},
{t:1624888440, d:20.43},
{t:1624888800, d:20.34},
{t:1624889160, d:20.5},
{t:1624889520, d:20.66},
{t:1624889880, d:20.63},
{t:1624890240, d:20.64},
{t:1624890600, d:20.91},
{t:1624890960, d:20.95},
{t:1624891320, d:20.48},
{t:1624891680, d:20.69},
{t:1624892040, d:20.98},
{t:1624892400, d:20.74},
{t:1624892760, d:20.4},
{t:1624893120, d:20.54},
{t:1624893480, d:20.4},
{t:1624893840, d:20.44},
{t:1624894200, d:20.5},
{t:1624894560, d:20.57},
{t:1624894920, d:20.63},
{t:1624895280, d:20.94},
{t:1624895640, d:20.58},
{t:1624896000, d:20.58},
{t:1624896360, d:20.87},
{t:1624896720, d:20.8},
{t:1624897080, d:20.44},
{t:1624897440, d:20.5},
{t:1624897800, d:20.07},
{t:1624898160, d:19.68},
{t:1624898520, d:19.71},
{t:1624898880, d:19.98},
{t:1624899240, d:20.2},
{t:1624899600, d:20.12},
{t:1624899960, d:19.89},
{t:1624900320, d:19.96},
{t:1624900680, d:19.98},
{t:1624901040, d:19.72},
{t:1624901400, d:19.53},
{t:1624901760, d:19.51},
{t:1624902120, d:19.38},
{t:1624902480, d:19.47},
{t:1624902840, d:19.6},
{t:1624903200, d:19.16},
{t:1624903560, d:19.39},
{t:1624903920, d:19.41},
{t:1624904280, d:19.41},
{t:1624904640, d:19.57},
{t:1624905000, d:19.32},
{t:1624905360, d:19.34},
{t:1624905720, d:19.67},
{t:1624906080, d:19.63},
{t:1624906440, d:19.84},
{t:1624906800, d:19.59},
{t:1624907160, d:19.26},
{t:1624907520, d:18.98},
{t:1624907880, d:19.25},
{t:1624908240, d:19.42},
{t:1624908600, d:19.15},
{t:1624908960, d:19.14},
{t:1624909320, d:19.42},
{t:1624909680, d:19.07},
{t:1624910040, d:19.1},
{t:1624910400, d:18.8},
{t:1624910760, d:18.42},
{t:1624911120, d:18.29},
{t:1624911480, d:18.63},
{t:1624911840, d:18.85},
{t:1624912200, d:18.47},
{t:1624912560, d:18.68},
{t:1624912920, d:18.67},
{t:1624913280, d:18.62},
{t:1624913640, d:18.59},
{t:1624914000, d:18.56},
{t:1624914360, d:18.96},
{t:1624914720, d:19.13},
{t:1624915080, d:19.09},
{t:1624915440, d:19.03},
{t:1624915800, d:18.65},
{t:1624916160, d:18.67},
{t:1624916520, d:18.42},
{t:1624916880, d:18.36},
{t:1624917240, d:18.72},
{t:1624917600, d:18.98},
{t:1624917960, d:18.64},
{t:1624918320, d:18.68},
{t:1624918680, d:18.55},
{t:1624919040, d:18.22},
{t:1624919400, d:18.6},
{t:1624919760, d:18.65},
{t:1624920120, d:18.82},
{t:1624920480, d:19.12},
{t:1624920840, d:18.8},
{t:1624921200, d:18.75},
{t:1624921560, d:18.44},
{t:1624921920, d:18.15},
{t:1624922280, d:18.5},
{t:1624922640, d:18.35},
{t:1624923000, d:18.6},
{t:1624923360, d:18.73},
{t:1624923720, d:18.84},
{t:1624924080, d:19.02},
{t:1624924440, d:19.05},
{t:1624924800, d:18.73},
{t:1624925160, d:18.88},
{t:1624925520, d:19.13},
{t:1624925880, d:18.81},
{t:1624926240, d:19.03},
{t:1624926600, d:19.17},
{t:1624926960, d:18.81},
{t:1624927320, d:18.64},
{t:1624927680, d:18.66},
{t:1624928040, d:19.06},
{t:1624928400, d:18.89},
{t:1624928760, d:18.58},
{t:1624929120, d:18.69},
{t:1624929480, d:18.81},
{t:1624929840, d:19.03},
{t:1624930200, d:19.22},
{t:1624930560, d:19},
{t:1624930920, d:19.16},
{t:1624931280, d:19.32},
{t:1624931640, d:19.3},
{t:1624932000, d:19.49},
{t:1624932360, d:19.54},
{t:1624932720, d:19.86},
{t:1624933080, d:19.7},
{t:1624933440, d:19.79},
{t:1624933800, d:19.91},
{t:1624934160, d:19.69},
{t:1624934520, d:19.6},
{t:1624934880, d:19.32},
{t:1624935240, d:19.07},
{t:1624935600, d:19.31},
{t:1624935960, d:19.65},
{t:1624936320, d:19.36},
{t:1624936680, d:19.41},
{t:1624937040, d:19.72},
{t:1624937400, d:19.63},
{t:1624937760, d:20.01},
{t:1624938120, d:19.84},
{t:1624938480, d:20.22},
{t:1624938840, d:20.5},
{t:1624939200, d:20.51},
{t:1624939560, d:20.26},
{t:1624939920, d:20.08},
{t:1624940280, d:19.74},
{t:1624940640, d:20.01},
{t:1624941000, d:20.21},
{t:1624941360, d:20.16},
{t:1624941720, d:19.85},
{t:1624942080, d:19.62},
{t:1624942440, d:20.01},
{t:1624942800, d:19.78},
{t:1624943160, d:19.97},
{t:1624943520, d:19.64},
{t:1624943880, d:19.87},
{t:1624944240, d:19.84},
{t:1624944600, d:19.77},
{t:1624944960, d:19.87},
{t:1624945320, d:19.51},
{t:1624945680, d:19.56},
{t:1624946040, d:19.75},
{t:1624946400, d:20.1},
{t:1624946760, d:19.79},
{t:1624947120, d:19.77},
{t:1624947480, d:19.75},
{t:1624947840, d:19.55},
{t:1624948200, d:19.78},
{t:1624948560, d:19.66},
{t:1624948920, d:19.88},
{t:1624949280, d:19.96},
{t:1624949640, d:20.34},
{t:1624950000, d:20.58},
{t:1624950360, d:20.53},
{t:1624950720, d:20.61},
{t:1624951080, d:20.3},
{t:1624951440, d:20.38},
{t:1624951800, d:20.45},
{t:1624952160, d:20.66},
{t:1624952520, d:20.63},
{t:1624952880, d:20.77},
{t:1624953240, d:20.79},
{t:1624953600, d:20.79},
{t:1624953960, d:20.53},
{t:1624954320, d:20.61},
{t:1624954680, d:20.74},
{t:1624955040, d:20.31},
{t:1624955400, d:19.91},
{t:1624955760, d:20.04},
{t:1624956120, d:19.65},
{t:1624956480, d:19.45},
{t:1624956840, d:19.03},
{t:1624957200, d:19.33},
{t:1624957560, d:19.64},
{t:1624957920, d:19.85},
{t:1624958280, d:19.86},
{t:1624958640, d:19.73},
{t:1624959000, d:19.55},
{t:1624959360, d:19.18},
{t:1624959720, d:19.38},
{t:1624960080, d:19.03},
{t:1624960440, d:19.14},
{t:1624960800, d:19.1},
{t:1624961160, d:18.68},
{t:1624961520, d:18.35},
{t:1624961880, d:18.65},
{t:1624962240, d:18.23},
{t:1624962600, d:18.55},
{t:1624962960, d:18.19},
{t:1624963320, d:18.06},
{t:1624963680, d:18.27},
{t:1624964040, d:18.22},
{t:1624964400, d:18.42},
{t:1624964760, d:18.43},
{t:1624965120, d:18.47},
{t:1624965480, d:18.16},
{t:1624965840, d:18.5},
{t:1624966200, d:18.79},
{t:1624966560, d:18.64},
{t:1624966920, d:18.85},
{t:1624967280, d:18.53},
{t:1624967640, d:18.5},
{t:1624968000, d:18.07},
{t:1624968360, d:18.25},
{t:1624968720, d:18.54},
{t:1624969080, d:18.16},
{t:1624969440, d:17.96},
{t:1624969800, d:17.84},
{t:1624970160, d:18.01},
{t:1624970520, d:17.63},
{t:1624970880, d:17.96},
{t:1624971240, d:17.57},
{t:1624971600, d:17.21},
{t:1624971960, d:16.89},
{t:1624972320, d:17.14},
{t:1624972680, d:17.18},
{t:1624973040, d:16.84},
{t:1624973400, d:17.01},
{t:1624973760, d:17.06},
{t:1624974120, d:16.68},
{t:1624974480, d:16.73},
{t:1624974840, d:16.81},
{t:1624975200, d:16.54},
{t:1624975560, d:16.49},
{t:1624975920, d:16.2},
{t:1624976280, d:15.9},
{t:1624976640, d:15.97},
{t:1624977000, d:15.58},
{t:1624977360, d:15.27},
{t:1624977720, d:15.01},
{t:1624978080, d:15.37},
{t:1624978440, d:15.46},
{t:1624978800, d:15.25},
{t:1624979160, d:15.38},
{t:1624979520, d:15.21},
{t:1624979880, d:15.38},
{t:1624980240, d:15.62},
{t:1624980600, d:15.83},
{t:1624980960, d:15.85},
{t:1624981320, d:16.17},
{t:1624981680, d:16.02},
{t:1624982040, d:16.1},
{t:1624982400, d:16.5},
{t:1624982760, d:16.83},
{t:1624983120, d:16.43},
{t:1624983480, d:16.05},
{t:1624983840, d:16.2},
{t:1624984200, d:15.97},
{t:1624984560, d:16.28},
{t:1624984920, d:15.96},
{t:1624985280, d:16.25},
{t:1624985640, d:15.88},
{t:1624986000, d:16.14},
{t:1624986360, d:16.52},
{t:1624986720, d:16.29},
{t:1624987080, d:16.36},
{t:1624987440, d:16},
{t:1624987800, d:16.2},
{t:1624988160, d:16.37},
{t:1624988520, d:16.6},
{t:1624988880, d:16.76},
{t:1624989240, d:17},
{t:1624989600, d:17.01},
{t:1624989960, d:17.28},
{t:1624990320, d:17.58},
{t:1624990680, d:17.54},
{t:1624991040, d:17.35},
{t:1624991400, d:17.61},
{t:1624991760, d:17.54},
{t:1624992120, d:17.77},
{t:1624992480, d:17.91},
{t:1624992840, d:18.03},
{t:1624993200, d:18.32},
{t:1624993560, d:18.69},
{t:1624993920, d:19.07},
{t:1624994280, d:18.78},
{t:1624994640, d:18.64},
{t:1624995000, d:19.02},
{t:1624995360, d:18.92},
{t:1624995720, d:19.24},
{t:1624996080, d:18.84},
{t:1624996440, d:18.92},
{t:1624996800, d:18.86},
{t:1624997160, d:19.06},
{t:1624997520, d:19.34},
{t:1624997880, d:19.07},
{t:1624998240, d:19.31},
{t:1624998600, d:19.05},
{t:1624998960, d:18.84},
{t:1624999320, d:19},
{t:1624999680, d:19.23},
{t:1625000040, d:19.08},
{t:1625000400, d:19.09},
{t:1625000760, d:19.23},
{t:1625001120, d:18.83},
{t:1625001480, d:18.84},
{t:1625001840, d:19.19},
{t:1625002200, d:19.16},
{t:1625002560, d:18.89},
{t:1625002920, d:19.05},
{t:1625003280, d:18.88},
{t:1625003640, d:19.03},
{t:1625004000, d:18.89},
{t:1625004360, d:18.56},
{t:1625004720, d:18.87},
{t:1625005080, d:18.62},
{t:1625005440, d:18.58},
{t:1625005800, d:18.99},
{t:1625006160, d:18.92},
{t:1625006520, d:18.58},
{t:1625006880, d:18.85},
{t:1625007240, d:18.52},
{t:1625007600, d:18.48},
{t:1625007960, d:18.71},
{t:1625008320, d:18.48},
{t:1625008680, d:18.55},
{t:1625009040, d:18.61},
{t:1625009400, d:18.25},
{t:1625009760, d:18.3},
{t:1625010120, d:18.52},
{t:1625010480, d:18.93},
{t:1625010840, d:19.06},
{t:1625011200, d:18.98},
{t:1625011560, d:19.27},
{t:1625011920, d:19.69},
{t:1625012280, d:19.58},
{t:1625012640, d:19.78},
{t:1625013000, d:19.55},
{t:1625013360, d:19.32},
{t:1625013720, d:18.98},
{t:1625014080, d:18.78},
{t:1625014440, d:18.72},
{t:1625014800, d:18.46},
{t:1625015160, d:18.38},
{t:1625015520, d:18.13},
{t:1625015880, d:17.98},
{t:1625016240, d:17.94},
{t:1625016600, d:17.67},
{t:1625016960, d:17.45},
{t:1625017320, d:17.85},
{t:1625017680, d:18.1},
{t:1625018040, d:18.24},
{t:1625018400, d:18.34},
{t:1625018760, d:18.48},
{t:1625019120, d:18.77},
{t:1625019480, d:18.87},
{t:1625019840, d:19.2},
{t:1625020200, d:18.99},
{t:1625020560, d:19.11},
{t:1625020920, d:19.32},
{t:1625021280, d:19.09},
{t:1625021640, d:18.95},
{t:1625022000, d:19.28},
{t:1625022360, d:19.02},
{t:1625022720, d:18.91},
{t:1625023080, d:19.04},
{t:1625023440, d:19.38},
{t:1625023800, d:19.01},
{t:1625024160, d:18.9},
{t:1625024520, d:18.81},
{t:1625024880, d:19.18},
{t:1625025240, d:19.51},
{t:1625025600, d:19.89},
{t:1625025960, d:19.63},
{t:1625026320, d:19.91},
{t:1625026680, d:20.12},
{t:1625027040, d:20.29},
{t:1625027400, d:20.31},
{t:1625027760, d:19.93},
{t:1625028120, d:19.68},
{t:1625028480, d:19.45},
{t:1625028840, d:19.71},
{t:1625029200, d:19.59},
{t:1625029560, d:19.94},
{t:1625029920, d:20.12},
{t:1625030280, d:19.86},
{t:1625030640, d:20.01},
{t:1625031000, d:20.23},
{t:1625031360, d:20.33},
{t:1625031720, d:20.14},
{t:1625032080, d:20.07},
{t:1625032440, d:19.97},
{t:1625032800, d:20.11},
{t:1625033160, d:19.74},
{t:1625033520, d:19.66},
{t:1625033880, d:19.81},
{t:1625034240, d:19.62},
{t:1625034600, d:19.8},
{t:1625034960, d:20.01},
{t:1625035320, d:20.32},
{t:1625035680, d:20.65},
{t:1625036040, d:20.4},
{t:1625036400, d:20.38},
{t:1625036760, d:20.72},
{t:1625037120, d:20.79},
{t:1625037480, d:21.12},
{t:1625037840, d:21.44},
{t:1625038200, d:21.28},
{t:1625038560, d:21.55},
{t:1625038920, d:21.25},
{t:1625039280, d:21.02},
{t:1625039640, d:20.6},
{t:1625040000, d:20.9},
{t:1625040360, d:20.66},
{t:1625040720, d:20.53},
{t:1625041080, d:20.31},
{t:1625041440, d:20.64},
{t:1625041800, d:20.39},
{t:1625042160, d:20.41},
{t:1625042520, d:20.22},
{t:1625042880, d:20.37},
{t:1625043240, d:20.7},
{t:1625043600, d:20.71},
{t:1625043960, d:21.01},
{t:1625044320, d:20.66},
{t:1625044680, d:20.81},
{t:1625045040, d:20.66},
{t:1625045400, d:20.62},
{t:1625045760, d:20.51},
{t:1625046120, d:20.76},
{t:1625046480, d:20.52},
{t:1625046840, d:20.12},
{t:1625047200, d:20.44},
{t:1625047560, d:20.45},
{t:1625047920, d:20.37},
{t:1625048280, d:20.53},
{t:1625048640, d:20.53},
{t:1625049000, d:20.53},
{t:1625049360, d:20.24},
{t:1625049720, d:20.26},
{t:1625050080, d:19.96},
{t:1625050440, d:19.62},
{t:1625050800, d:19.35},
{t:1625051160, d:19.32},
{t:1625051520, d:19.17},
{t:1625051880, d:19.06},
{t:1625052240, d:19.29},
{t:1625052600, d:19.22},
{t:1625052960, d:19.5},
{t:1625053320, d:19.73},
{t:1625053680, d:19.84},
{t:1625054040, d:19.73},
{t:1625054400, d:19.85},
{t:1625054760, d:19.6},
{t:1625055120, d:19.65},
{t:1625055480, d:19.45},
{t:1625055840, d:19.02},
{t:1625056200, d:19.32},
{t:1625056560, d:19.06},
{t:1625056920, d:18.94},
{t:1625057280, d:18.75},
{t:1625057640, d:18.57},
{t:1625058000, d:18.56},
{t:1625058360, d:18.57},
{t:1625058720, d:18.72},
{t:1625059080, d:18.92},
{t:1625059440, d:18.5},
{t:1625059800, d:18.3},
{t:1625060160, d:17.9},
{t:1625060520, d:17.51},
{t:1625060880, d:17.22},
{t:1625061240, d:17.45},
{t:1625061600, d:17.63},
{t:1625061960, d:17.62},
{t:1625062320, d:17.89},
{t:1625062680, d:17.61},
{t:1625063040, d:17.57},
{t:1625063400, d:17.17},
{t:1625063760, d:17.3},
{t:1625064120, d:17.41},
{t:1625064480, d:17.71},
{t:1625064840, d:18.04},
{t:1625065200, d:17.66},
{t:1625065560, d:17.39},
{t:1625065920, d:17.16},
{t:1625066280, d:17.38},
{t:1625066640, d:17.11},
{t:1625067000, d:16.99},
{t:1625067360, d:16.94},
{t:1625067720, d:16.78},
{t:1625068080, d:16.89},
{t:1625068440, d:16.67},
{t:1625068800, d:16.28},
{t:1625069160, d:16.67},
{t:1625069520, d:16.38},
{t:1625069880, d:16},
{t:1625070240, d:15.69},
{t:1625070600, d:15.62},
{t:1625070960, d:15.59},
{t:1625071320, d:15.83},
{t:1625071680, d:15.78},
{t:1625072040, d:16.16},
{t:1625072400, d:15.98},
{t:1625072760, d:16.26},
{t:1625073120, d:16.21},
{t:1625073480, d:15.98},
{t:1625073840, d:15.62},
{t:1625074200, d:15.32},
{t:1625074560, d:15.47},
{t:1625074920, d:15.17},
{t:1625075280, d:15.38},
{t:1625075640, d:15.77},
{t:1625076000, d:15.54},
{t:1625076360, d:15.67},
{t:1625076720, d:15.32},
{t:1625077080, d:15.27},
{t:1625077440, d:15.08},
{t:1625077800, d:14.79},
{t:1625078160, d:14.63},
{t:1625078520, d:14.94},
{t:1625078880, d:15.31},
{t:1625079240, d:15.49},
{t:1625079600, d:15.32},
{t:1625079960, d:15},
{t:1625080320, d:15.23},
{t:1625080680, d:15.29},
{t:1625081040, d:15.22},
{t:1625081400, d:15.16},
{t:1625081760, d:15.24},
{t:1625082120, d:14.93},
{t:1625082480, d:15},
{t:1625082840, d:14.76},
{t:1625083200, d:14.51},
{t:1625083560, d:14.81},
{t:1625083920, d:14.49},
{t:1625084280, d:14.63},
{t:1625084640, d:14.42},
{t:1625085000, d:14.23},
{t:1625085360, d:14.41},
{t:1625085720, d:14.66},
{t:1625086080, d:14.39},
{t:1625086440, d:14.62},
{t:1625086800, d:15.1},
{t:1625087160, d:15.33},
{t:1625087520, d:15.74},
{t:1625087880, d:15.53},
{t:1625088240, d:15.2},
{t:1625088600, d:15.63},
{t:1625088960, d:15.73},
{t:1625089320, d:16.13},
{t:1625089680, d:16.08},
{t:1625090040, d:15.99},
{t:1625090400, d:16.43},
{t:1625090760, d:16.65},
{t:1625091120, d:16.94},
{t:1625091480, d:16.99},
{t:1625091840, d:17.15},
{t:1625092200, d:17.04},
{t:1625092560, d:17.29},
{t:1625092920, d:17.31},
{t:1625093280, d:17.67},
{t:1625093640, d:18.04},
{t:1625094000, d:17.88},
{t:1625094360, d:18.22},
{t:1625094720, d:18.13},
{t:1625095080, d:18.33},
{t:1625095440, d:18.05},
{t:1625095800, d:18.33},
{t:1625096160, d:18.18},
{t:1625096520, d:18.37},
{t:1625096880, d:18.43},
{t:1625097240, d:18.77},
{t:1625097600, d:18.62},
{t:1625097960, d:18.68},
{t:1625098320, d:18.57},
{t:1625098680, d:18.9},
{t:1625099040, d:18.55},
{t:1625099400, d:18.76},
{t:1625099760, d:18.97},
{t:1625100120, d:19.35},
{t:1625100480, d:19.73},
{t:1625100840, d:19.61},
{t:1625101200, d:19.25},
{t:1625101560, d:19.16},
{t:1625101920, d:19.15},
{t:1625102280, d:19.01},
{t:1625102640, d:19.15},
{t:1625103000, d:19.53},
{t:1625103360, d:19.21},
{t:1625103720, d:19.42},
{t:1625104080, d:19.42},
{t:1625104440, d:19.34},
{t:1625104800, d:19.27},
{t:1625105160, d:19.21},
{t:1625105520, d:18.98},
{t:1625105880, d:18.84},
{t:1625106240, d:19.08},
{t:1625106600, d:18.96},
{t:1625106960, d:19.01},
{t:1625107320, d:18.87},
{t:1625107680, d:19.15},
{t:1625108040, d:18.88},
{t:1625108400, d:19.3},
{t:1625108760, d:19.51},
{t:1625109120, d:19.68},
{t:1625109480, d:19.58},
{t:1625109840, d:19.98},
{t:1625110200, d:20.27},
{t:1625110560, d:20.51},
{t:1625110920, d:20.32},
{t:1625111280, d:20.45},
{t:1625111640, d:20.76},
{t:1625112000, d:20.93},
{t:1625112360, d:21.22},
{t:1625112720, d:21.18},
{t:1625113080, d:20.84},
{t:1625113440, d:20.52},
{t:1625113800, d:20.83},
{t:1625114160, d:20.71},
{t:1625114520, d:20.38},
{t:1625114880, d:20.6},
{t:1625115240, d:20.4},
{t:1625115600, d:20.13},
{t:1625115960, d:20.51},
{t:1625116320, d:20.62},
{t:1625116680, d:20.76},
{t:1625117040, d:20.49},
{t:1625117400, d:20.88},
{t:1625117760, d:20.54},
{t:1625118120, d:20.35},
{t:1625118480, d:20.33},
{t:1625118840, d:20.45},
{t:1625119200, d:20.18},
{t:1625119560, d:19.99},
{t:1625119920, d:20.09},
{t:1625120280, d:20.39},
{t:1625120640, d:19.99},
{t:1625121000, d:19.84},
{t:1625121360, d:20.1},
{t:1625121720, d:20.22},
{t:1625122080, d:20.18},
{t:1625122440, d:20.1},
{t:1625122800, d:20.18},
{t:1625123160, d:20.17},
{t:1625123520, d:19.98},
{t:1625123880, d:19.74},
{t:1625124240, d:19.84},
{t:1625124600, d:19.76},
{t:1625124960, d:19.91},
{t:1625125320, d:20.12},
{t:1625125680, d:19.7},
{t:1625126040, d:19.33},
{t:1625126400, d:19.15},
{t:1625126760, d:19.18},
{t:1625127120, d:19.34},
{t:1625127480, d:19.26},
{t:1625127840, d:19.18},
{t:1625128200, d:19.28},
{t:1625128560, d:18.95},
{t:1625128920, d:18.68},
{t:1625129280, d:18.55},
{t:1625129640, d:18.72},
{t:1625130000, d:18.77},
{t:1625130360, d:18.69},
{t:1625130720, d:18.77},
{t:1625131080, d:18.64},
{t:1625131440, d:18.64},
{t:1625131800, d:18.49},
{t:1625132160, d:18.69},
{t:1625132520, d:18.32},
{t:1625132880, d:18.39},
{t:1625133240, d:18.48},
{t:1625133600, d:18.46},
{t:1625133960, d:18.29},
{t:1625134320, d:18.4},
{t:1625134680, d:18.75},
{t:1625135040, d:18.69},
{t:1625135400, d:18.28},
{t:1625135760, d:18.07},
{t:1625136120, d:18.32},
{t:1625136480, d:17.91},
{t:1625136840, d:17.51},
{t:1625137200, d:17.66},
{t:1625137560, d:17.94},
{t:1625137920, d:18.22},
{t:1625138280, d:18.55},
{t:1625138640, d:18.27},
{t:1625139000, d:18.33},
{t:1625139360, d:18.45},
{t:1625139720, d:18.54},
{t:1625140080, d:18.45},
{t:1625140440, d:18.33},
{t:1625140800, d:18.06},
{t:1625141160, d:18.42},
{t:1625141520, d:18.22},
{t:1625141880, d:17.89},
{t:1625142240, d:18.12},
{t:1625142600, d:18.17},
{t:1625142960, d:18.05},
{t:1625143320, d:17.79},
{t:1625143680, d:17.62},
{t:1625144040, d:17.83},
{t:1625144400, d:18.03},
{t:1625144760, d:18.04},
{t:1625145120, d:17.71},
{t:1625145480, d:17.98},
{t:1625145840, d:17.75},
{t:1625146200, d:18.06},
{t:1625146560, d:17.91},
{t:1625146920, d:17.7},
{t:1625147280, d:17.85},
{t:1625147640, d:17.61},
{t:1625148000, d:17.76},
{t:1625148360, d:17.79},
{t:1625148720, d:17.53},
{t:1625149080, d:17.88},
{t:1625149440, d:18.09},
{t:1625149800, d:18.44},
{t:1625150160, d:18.53},
{t:1625150520, d:18.13},
{t:1625150880, d:17.79},
{t:1625151240, d:17.47},
{t:1625151600, d:17.34},
{t:1625151960, d:16.98},
{t:1625152320, d:17.1},
{t:1625152680, d:17},
{t:1625153040, d:17.14},
{t:1625153400, d:17.4},
{t:1625153760, d:17.32},
{t:1625154120, d:17.3},
{t:1625154480, d:17.3},
{t:1625154840, d:17.14},
{t:1625155200, d:17.38},
{t:1625155560, d:17.51},
{t:1625155920, d:17.55},
{t:1625156280, d:17.92},
{t:1625156640, d:18.27},
{t:1625157000, d:18.36},
{t:1625157360, d:18.17},
{t:1625157720, d:18.1},
{t:1625158080, d:18.03},
{t:1625158440, d:17.95},
{t:1625158800, d:17.71},
{t:1625159160, d:17.48},
{t:1625159520, d:17.8},
{t:1625159880, d:18.14},
{t:1625160240, d:18.16},
{t:1625160600, d:18.15},
{t:1625160960, d:18.18},
{t:1625161320, d:18.35},
{t:1625161680, d:18.49},
{t:1625162040, d:18.23},
{t:1625162400, d:18.16},
{t:1625162760, d:18.19},
{t:1625163120, d:18.2},
{t:1625163480, d:18.24},
{t:1625163840, d:18.56},
{t:1625164200, d:18.8},
{t:1625164560, d:19.08},
{t:1625164920, d:19.08},
{t:1625165280, d:19.26},
{t:1625165640, d:19.55},
{t:1625166000, d:19.45},
{t:1625166360, d:19.34},
{t:1625166720, d:19.21},
{t:1625167080, d:19.24},
{t:1625167440, d:19.13},
{t:1625167800, d:19.29},
{t:1625168160, d:19.38},
{t:1625168520, d:19.49},
{t:1625168880, d:19.73},
{t:1625169240, d:20.06},
{t:1625169600, d:19.7},
{t:1625169960, d:19.65},
{t:1625170320, d:19.57},
{t:1625170680, d:19.78},
{t:1625171040, d:19.38},
{t:1625171400, d:19.52},
{t:1625171760, d:19.59},
{t:1625172120, d:19.41},
{t:1625172480, d:19.62},
{t:1625172840, d:19.93},
{t:1625173200, d:20.1},
{t:1625173560, d:20.17},
{t:1625173920, d:20.32},
{t:1625174280, d:20.35},
{t:1625174640, d:20.39},
{t:1625175000, d:20.32},
{t:1625175360, d:20.23},
{t:1625175720, d:20.43},
{t:1625176080, d:20.64},
{t:1625176440, d:20.63},
{t:1625176800, d:20.61},
{t:1625177160, d:20.62},
{t:1625177520, d:20.55},
{t:1625177880, d:20.34},
{t:1625178240, d:20.54},
{t:1625178600, d:20.89},
{t:1625178960, d:20.54},
{t:1625179320, d:20.55},
{t:1625179680, d:20.2},
{t:1625180040, d:19.94},
{t:1625180400, d:20.07},
{t:1625180760, d:19.79},
{t:1625181120, d:19.96},
{t:1625181480, d:19.6},
{t:1625181840, d:19.99},
{t:1625182200, d:19.66},
{t:1625182560, d:19.31},
{t:1625182920, d:19.43},
{t:1625183280, d:19.64},
{t:1625183640, d:19.67},
{t:1625184000, d:19.62}
]

var sd={from: 1623974760, to: 1625184000, sensors:{"0sna8jVYIh0xw6oF-0-240-23": sd0}}

export {sd}
