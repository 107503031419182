import React from 'react';
import DateTime00 from './DateTime00'
import UsaIcon from './UsaIcon';
import IconButton from '@material-ui/core/IconButton';
import UsaSelect00 from './UsaSelect00'
import UsaRepeat00 from './UsaRepeat00'
import Camera from '@material-ui/icons/Camera';
import {wsTrans,doGetPostBasic} from '../utils/utils'
import {cl, globs, getTime,constant,getRandomString} from '../../components/utils/utils';

class CreateToDo00 extends React.Component{
  constructor(props) {// 
    super(props);
    let todoTime=getTime()+3600
    todoTime-=todoTime%3600
    this.state={
      loaded: false,
      beginTime: todoTime,
      endTime: todoTime+3600,
      subject: "",
      location: "",
      category: "equipment",
      repeat: {
        type: "none",
      },
    }
    this.loadData()
//     this.loadUsers()
  }
/*Repeating of Tasks:
The original task is in the tasks table
the *occurrences* of the tasks are in the taskEvents table
Each occurrence has the original taskId, and a repeatIndex, for 
the nth repeat of the original task
It also has a date and time.
when the repeat is changed, the dates of the tasks are recalculated
for maintenance, every month, we could recalculate the dates for the next
year, for all tasks in that account
When a task is created, calculate the next year's repeats

*/  
  
  loadData=async()=>{
    let usersP=wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
    let [usersR]=await Promise.all([usersP])
    this.users=usersR.data
//     cl(this.users)
    this.userOpts={}
    this.users.forEach(u=>{
      this.userOpts[u.userId]=u.name
    })
//     cl(this.userOpts)
    this.setState({loaded: true, assignee: this.users[0].userId})
  }
  
  saveTask=async()=>{
    cl(this.state)
    let taskP=wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "update", 
      sessionId: globs.userData.session.sessionId, body: {
        assignee: this.state.assignee,
        assignor: globs.userData.session.userId,
        taskId: getRandomString(16),// to create a new task
        beginTime: this.state.beginTime,
        endTime: this.state.endTime,
        category: this.state.category,
        location: this.state.location,
        subject: this.state.subject,
        repeat: this.state.repeat,
      }})
    let [taskR]=await Promise.all([taskP])
  }
  
  onChange=(type, vals)=>{
    cl(type)
    cl(vals)
    var repeat
    switch(type){
      case "repeat":
        if("type" in vals){
          repeat=vals
        }else{
          repeat=Object.assign(this.state.repeat, vals)
        }
        cl(repeat)
        this.setState({repeat: repeat})
        break
      default:
        this.setState(vals)
        break
    }
//     cl(type)
//     cl(vals)
  }
  
  result=(type)=>{
    cl(type)
    switch(type){
      case "Task-OK":
        cl(this.state)
        this.saveTask()
      case "Task-Cancel":
        this.props.onChange({showCreateToDo: false})
        break
      default:
        break
    }
  }
  
  assigneeEnter=()=>{
//     cl(this.userOpts)
    return(
      <UsaSelect00 parms={{
        title: "Assignee",
        select: true, // !this.state.editMode,
        valueId: "assignee",//valueId, // "widgetSel",
        value: this.state.assignee, //value, // this.state.widgetSel,
        opts: this.userOpts,
        onChange: (vals)=>this.onChange("assignee", vals),
      }}/>
    )
  }
  
  showSetTime=()=>{
//     cl(this.state)
    return([
      "Begin Time",
      <DateTime00 key={0} 
        parms={{
          date: this.state.beginTime,
          valueId: "beginTime",
          id: "begin"}} 
        onChange={o=>this.onChange("begin", o)}
      />,
      "End Time",
      <DateTime00 key={1} 
        parms={{
          date: this.state.endTime,
          valueId: "endTime",
          id: "end"}} 
        onChange={o=>this.onChange("end", o)}
      />
      ]
    )
  }
  
//Subject, Location, Category, Description  
  subjectEnter=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Task\u00A0Name",
        select: false, // !this.state.editMode,
        valueId: "subject",//valueId, // "widgetSel",
        value: this.state.subject, //value, // this.state.widgetSel,
//         textId: "widgetName",
        onChange: (vals)=>this.onChange("subject", vals),
      }}/>
    )
  }
  
  locationEnter=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Location",
        select: false, // !this.state.editMode,
        valueId: "location",//valueId, // "widgetSel",
        value: this.state.location, //value, // this.state.widgetSel,
//         textId: "widgetName",
        onChange: (vals)=>this.onChange("location", vals),
      }}/>
    )
  }
  
  categoryEnter=()=>{
    let categoryOpts={
      equipment: "equipment",
      sensors: "sensors",
      plants: "plants",
      pests: "pests",
      nutrients: "nutrients",
      power: "power",
      personnel: "personnel",
      supplies: "supplies",
      maintenance: "maintenance",
      other: "other",
    }
    return(
      <UsaSelect00 parms={{
        title: "Category",
        select: true, // !this.state.editMode,
        valueId: "category",//valueId, // "widgetSel",
        value: this.state.category, //value, // this.state.widgetSel,
        opts: categoryOpts,
        onChange: (vals)=>this.onChange("category", vals),
      }}/>
    )
  }
  
  
  
    repeatEnter=()=>{
      return <UsaRepeat00 parms={{
        onChange: (vals)=>this.onChange("repeat", vals),
      }}/>
      
    }
    
  showCreateToDo=()=>{
    return(
      <div style={{padding: 10}}>
      <h3 style={{marginLeft: 10}}>New Task
      <UsaIcon space="1" icon="Task-OK" result={this.result} inline/>
      <UsaIcon icon="Task-Cancel" result={this.result} inline/>
      </h3>
      {this.assigneeEnter()}
      <div style={{height: 10}}/>
      {this.showSetTime()}
      <div style={{height: 10}}/>
      {this.subjectEnter()}
      <div style={{height: 10}}/>
      {this.locationEnter()}
      <div style={{height: 10}}/>
      {this.categoryEnter()}
      <div style={{height: 10}}/>
      {this.repeatEnter()}
      </div>
      
    )
  }
    
  render(){
    if(this.state.loaded){
      return this.showCreateToDo()
    }else{
      return <div>loading...</div>
    }
  }
}

export default CreateToDo00
