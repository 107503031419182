import React from 'react';
import C18Anchor00 from './C18Anchor00'
import {loadZonesInfo,getZoneInfo,setSiteZoneTypes,loadSiteData,checkSave,saveTable} from './C18utils'
import {dbVals,sendArray} from '../../components/utils/http';
import {pInd} from '../../components/utils/paramIds'
import {wsTrans,getParamId2} from '../utils/utils'
import {cl,globs,getRandomString} from '../../components/utils/utils';

var numTypes=32
var numRegs=512
var blankVal=0x03FF

class C18ModbusGP00 extends React.Component{
  constructor(props) {
    super(props);
    // cl(props)
    this.state={
      loaded:false,
      typeSel:-1,
      types:{},
      registerSel:"",
      registers:{},
      pageType:"types",
    }
    this.loadData()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveModbus)
    if(this.props.parms.saveOK){this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})}
  }

  wsTrans2=async(endPoint,action,query)=>{
    // cl(query)
    let res=await wsTrans("usa", {cmd: "cRest", uri: `/s/${endPoint}`, method: action,
      sessionId: globs.userData.session.sessionId,body: query})
    return res.data
  }

  arrToObj=(arr,key)=>{
// use key to create an indexed object
    let obj={}
    arr.forEach(el=>{obj[el[key]]=el})
    return obj
  }

  loadData=async()=>{
    this.createTables()
    this.getGmbIds()
    await loadZonesInfo()
    this.zone=(globs.zonesInfo.info.filter(z=>{
      return z.zoneId==this.props.parms.zone})||{})[0]
    let zi=this.zone
    this.loadGmbTypes(zi.siteId,zi.siteZoneIndex)
    // let types0=await this.wsTrans2("gmbTypes","retrieve",{})
    // let types=this.arrToObj(types0,"typeId")
    await this.setState({
      // types:types,
      // typeSel:types0[0]?.typeId||-1,
      loaded:true,
    })
    // cl("load data done")
  }
/********************** Begin Register Tables ****************************/
  blankRegister={
    id:0,
    link:blankVal,
    name:"",
    mbType:0,
    rwType:0,
    valType:0,
    diagnostic:false,
    regAddr:blankVal,
    channelStart:15,
    channelCount:15,
    interval:0,
  }

  blankType={
    id:0,
    name:"",
    link:blankVal,
  }

  createTables=()=>{
    let st=this.state
    let deviceTypes=[]
    for(let i=0;i<numTypes;i++){
      this.blankType.id=i
      deviceTypes.push(Object.assign({},this.blankType))
    }
    let deviceTypeRegisters=[]
    for(let i=0;i<numRegs;i++){
      this.blankRegister.id=i
      deviceTypeRegisters.push(Object.assign({},this.blankRegister))
    }
    st.deviceTypes=deviceTypes
    st.deviceTypeRegisters=deviceTypeRegisters
  }

  getGmbIds=()=>{
    this.gid={
      typeName:getParamId2(1900,"pearl_mbg_types","name"),
      typeRegLink:getParamId2(1900,"pearl_mbg_types","regLink"),
      regFlagsLink:getParamId2(1900,"pearl_mbg_registers","flagsLink"),
      regRegAddr:getParamId2(1900,"pearl_mbg_registers","regAddr"),
      regChannel:getParamId2(1900,"pearl_mbg_registers","channel"),
      regInterval:getParamId2(1900,"pearl_mbg_registers","interval"),
      regName:getParamId2(1900,"pearl_mbg_registers","name"),
      typeMult:pInd[1900].pearl_mbg_types[2],
      regMult:pInd[1900].pearl_mbg_registers[2],
    }
    // cl(this.gid)
    this.siteId=globs.userData.siteLoaded
  }

  getFreeRegisterId=()=>{
    for(let i=0;i<numRegs;i++){
      if(this.state.deviceTypeRegisters[i].regAddr==blankVal){return i}
    }
    return -1
  }

  addType=()=>{
    // cl("addtype")
    let st=this.state
    var i
    for(i=0;i<numTypes;i++){
      if(st.deviceTypes[i].link==blankVal){break}
    }
    if(i>=numTypes){return false}
    let typeId=i
    let deviceTypes=st.deviceTypes.slice(0)//Object.assign({},st.deviceTypes)
    let registers=st.deviceTypeRegisters.slice(0)
    let type=deviceTypes[typeId]
    let regId=this.getFreeRegisterId()
    if(regId<0){return false}
    let reg=registers[regId]
    reg.regAddr=0// mark as used
    reg.name="New Register"
    // cl(reg)
    type.name="New Type"
    type.link=regId
    cl(deviceTypes)
    cl(registers)
    this.setState({
      deviceTypes:deviceTypes,
      deviceTypeRegisters:registers,
      registerSel:regId,
      typeSel:typeId})
  }

  addRegister=async()=>{
    let st=this.state
    let type=st.deviceTypes[st.typeSel]
    // cl(type)
    let registers=st.deviceTypeRegisters.slice(0)
    let reg=registers[type.link]
    let i=0
    while((reg.link!=blankVal)&&(i++<1024)){
      reg=registers[reg.link]
    }
    // cl(reg)
    if(reg.regAddr!=blankVal){
      // reg.link=0// mark as used
      let reg2Id=this.getFreeRegisterId()
      // cl(reg2Id)
      let reg2=registers[reg2Id]
      reg.link=reg2.id
      reg2.regAddr=0
      reg2.name="New Registerx"
      // cl(reg2)
      reg=reg2
    }
    cl(registers)
    // cl(reg)
    this.setState({deviceTypeRegisters:registers,registerSel:reg.id})
  }

  deleteRegister=()=>{
    let st=this.state
    let types=st.deviceTypes.slice(0)
    let registers=st.deviceTypeRegisters.slice(0)
    let type=types[st.typeSel]
    let regId=st.registerSel
    let reg=registers[type.link]
    let i=0
    var lastRegId
    while((reg.id!=regId)&&(i++<numRegs)){
      lastRegId=reg.id
      reg=registers[reg.link]
    }
    if(typeof(lastRegId)=="undefined"){// first register
      if(reg.link==blankVal){return}// only 1 register left
      type.link=reg.link
    }else{
      registers[lastRegId].link=reg.link
    }
    // regSel=reg.link
    this.blankRegister.id=reg.id
    registers[reg.id]=Object.assign({},this.blankRegister)
    let regSel=(reg.link==blankVal)?lastRegId:reg.link
    this.setState({deviceTypes:types,deviceTypeRegisters:registers,registerSel:regSel})
  }

  deleteType=async()=>{
    let st=this.state
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Device Type?", buttons:["Cancel","Yes"]})
    cl(res)
    if(res=="Yes"){
      let types=st.deviceTypes.slice(0)
      let registers=st.deviceTypeRegisters.slice(0)
      let type=types[st.typeSel]
// delete registers:
      let thisLink=type.link
      let i=0
      while((thisLink!=blankVal)&&(i++<numRegs)){
        let id=registers[thisLink].id
        let nextLink=registers[thisLink].link
        this.blankRegister.id=id
        registers[thisLink]=Object.assign({},this.blankRegister)
        thisLink=nextLink
      }
// delete type
      this.blankType.id=type.id
      types[type.id]=Object.assign({},this.blankType)
      let typeSel=0;
      let delType=type.id
      for(let i=0;i<numTypes;i++){
        let type=types[i]
        if(type.link!=blankVal){
          typeSel=type.id;
          if(i>delType){break}
        }
      }
      cl(types)
      cl(registers)
      this.setState({deviceTypes:types,deviceTypeRegisters:registers,
        typeSel:typeSel
      })
    }
  }

  saveGmbTypes=(arr,siteId,zoneInd)=>{
    let st=this.state
    // let arr=[]
    let obj={
      s:siteId,
      z:zoneInd,
      c:240,
      f:2,
    }
    let mult=this.gid.typeMult
    for(let i=0;i<numTypes;i++){
      let type=st.deviceTypes[i]
      if(type.link!=blankVal){
        arr.push(Object.assign({
          i:this.gid.typeName+mult*i,
          d:type.name,
        },obj))
        arr.push(Object.assign({
          i:this.gid.typeRegLink+mult*i,
          d:type.link,
        },obj))
      }
    }
  }

  loadGmbTypes=(siteId,zoneInd)=>{
    let st=this.state
    let obj={
      s:siteId,
      z:zoneInd,
      c:240,
      f:2,
    }
// types
    let gid=this.gid
    let mult=gid.typeMult
    // cl(dbVals.z[zoneInd][240][gid.typeName])
    let ch=dbVals.z[zoneInd][240]
    let types=Object.assign(st.deviceTypes)
    let typeSel=-1
    for(let i=0;i<numTypes;i++){
      let regLink=ch[gid.typeRegLink+mult*i]
      if((typeof(regLink)!="undefined")&&regLink!=blankVal){
        if(typeSel<0){typeSel=i}
        types[i]={
          id:i,
          name:ch[gid.typeName+mult*i],
          link:regLink,
        }
      }
    }
    cl(types)
    // return
// registers
    mult=gid.regMult
    let registers=Object.assign(st.deviceTypeRegisters)
    for(let i=0;i<numRegs;i++){
      let regAddr=ch[gid.regRegAddr+mult*i]
      if((typeof(regAddr)!="undefined")&&regAddr!=blankVal){
        let flagsLink=ch[gid.regFlagsLink+mult*i]
        // cl(flagsLink)
        let channels=ch[gid.regChannel+mult*i]
        let interval0=ch[gid.regInterval+mult*i]
        // let int=16*Math.exp(interval0/16)/Math.log(2)
        // cl(interval0)
        // cl(int)
        let interval=Math.exp(interval0/16*Math.log(2))/16
        // cl(int)
        // cl(Math.log(interval0/16))
        registers[i]={
          id:i,
          link:(flagsLink)&0x03FF,
          name:ch[gid.regName+mult*i],
          diagnostic:(flagsLink>>15)&0x01,
          valType:(flagsLink>>14)&0x01,
          mbType:(flagsLink>>12)&0x03,
          rwType:(flagsLink>>10)&0x03,
          diagnostic:(flagsLink>>13)&0x01,
          regAddr:regAddr,
          channelStart:(channels>>8)&0x0FF,
          channelCount:channels&0x0FF,
          interval:interval.toFixed(2),//ch[gid.regInterval+mult*i],
        }
      }
    }
    cl(registers)
    cl(types)
    cl(typeSel)
    let link=types[typeSel].link
    if(typeof(link)=="undefined"){link=-1}

    this.setState({deviceTypes:types,deviceTypeRegisters:registers,
      typeSel:typeSel,registerSel:link,
    })
  }

  saveGmbRegs=(arr,siteId,zoneInd)=>{
    let st=this.state
    // let arr=[]
    let obj={
      s:siteId,
      z:zoneInd,
      c:240,
      f:2,
    }
    let mult=this.gid.regMult
    for(let i=0;i<numRegs;i++){
      let reg=st.deviceTypeRegisters[i]
      if(reg.regAddr!=blankVal){
        let interval0=Math.floor(reg.interval*1000/62.5)
        let step=Math.log(2)/16
        let int=Math.log(interval0)/step
        cl(interval0)
        cl(step)
        cl(int)
        cl(reg)
        let flagsLink=
          (reg.diagnostic&0x01)<<15|
          (reg.valType&0x01)<<14|
          (reg.mbType&0x03)<<12|
          (reg.rwType&0x03)<<10|
          (reg.link&0x03FF)
        cl(flagsLink)
        arr.push(Object.assign({
          i:this.gid.regFlagsLink+mult*i,
          d:flagsLink,
        },obj))
        arr.push(Object.assign({
          i:this.gid.regRegAddr+mult*i,
          d:reg.regAddr,
        },obj))
        let channel=// now 16 bits
          (reg.channelStart&0xFF)<<8|
          (reg.channelCount&0xFF)
        arr.push(Object.assign({
          i:this.gid.regChannel+mult*i,
          d:channel,
        },obj))

        let interval=(reg.interval>0)?
          (Math.log(reg.interval*16)/Math.log(2)*16):
          0
        arr.push(Object.assign({
          i:this.gid.regInterval+mult*i,
          d:64,//Math.round(interval),//reg.interval,
        },obj))
        arr.push(Object.assign({
          i:this.gid.regName+mult*i,
          d:reg.name,
        },obj))
      }
    }
  }

/********************** End Register Tables ****************************/
  saveType=async()=>{
    let st=this.state
    let types=Object.keys(st.types).map(k=>{return st.types[k]})
    // let typeId=this.state.typeSel
    // let type=Object.assign({},this.state.typeOpts[typeId],
    //   {gatewayId:this.zone.gatewayId,typeId:typeId})
    // let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/gmbTypes", method: "update",
    //   sessionId: globs.userData.session.sessionId,body: types})
    // await saveTable("gmbTypes",this.gatewayId)
  }

  saveModbus=async(cmd)=>{
    cl(cmd)
    cl(this.zone)
    let zi=this.zone
    let localOnly=false
    if(cmd=="save"){
      switch(this.state.pageType){
        case "types":
          let arr=[]
          this.saveGmbTypes(arr,zi.siteId,zi.siteZoneIndex)
          this.saveGmbRegs(arr,zi.siteId,zi.siteZoneIndex)
          await sendArray(arr,zi.virtual,zi.gatewayType,zi.controllerId,localOnly)
          cl(arr)
          // this.saveType()
          // this.saveRegisters()
          // this.saveIndexes()
          break
        case "devices":
          this.saveDevice()
          break
      }
    }
    globs.events.publish("saveOK",true)
  }

  // deleteType=async()=>{
  //   let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Device Type?", buttons:["Cancel","Yes"]})
  //   cl(res)
  //   if(res=="Yes"){
  //     let to=Object.assign({},this.state.types)
  //     delete to[this.state.typeSel]
  //     let obj={typeId:this.state.typeSel}
  //     await this.wsTrans2("gmbTypes","delete",[obj])
  //     // let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/modbusTypes", method: "delete",
  //     //   sessionId: globs.userData.session.sessionId,
  //     //   body: [obj]})
  //
  //     let typeId=Object.keys(to)[0]
  //     var registerSel=this.state.registerSel
  //     // if(this.state.registerOpts[typeId]){
  //     //   registerSel=Object.keys(this.state.registerOpts[typeId])[0]
  //     // }
  //     cl(to)
  //     this.setState({types:to, typeSel:typeId, registerSel:registerSel})
  //   }
  // }

  // deleteRegister=()=>{
  //   let ro=Object.assign({},this.state.registerOpts)
  //   cl(ro)
  //   delete ro[this.state.typeSel][this.state.registerSel]
  //   let regId=Object.keys(ro[this.state.typeSel])[0]
  //   cl(ro)
  //   cl(regId)
  //   this.setState({registerSel:regId, registerOpts:ro})
  // }

  onChange=(type,vals)=>{
    let st=this.state
    cl(type,vals)
    switch(type){
      case "deleteType":
        this.deleteType()
        break
      case "typeUpd":
        globs.events.publish("savePageEnable",true)
        let deviceTypes=st.deviceTypes.slice(0)//Object.assign({},st.deviceTypes)
        deviceTypes[st.typeSel].name=vals.name
        this.setState({deviceTypes:deviceTypes})
        break
      case "regUpdNum":
        // if(isNaN(Object.values(vals)[0])){return}
      case "regUpd":
        globs.events.publish("savePageEnable",true)
        let regs=st.deviceTypeRegisters.slice(0)// Object.assign({},st.registers)
        Object.assign(regs[st.registerSel],vals)
        // regs[st.registerSel].name=vals.name
        this.setState({deviceTypeRegisters:regs})
        break
      case "addType":
        globs.events.publish("savePageEnable",true)
        this.addType()
        break
      case "addRegister":
        globs.events.publish("savePageEnable",true)
        this.addRegister()
        break
      case "deleteRegister":
        globs.events.publish("savePageEnable",true)
        this.deleteRegister()
        break
      case "typeSel":
        cl(vals)
        vals.registerSel=st.deviceTypes[vals.typeSel].link
      case "regSel":
        this.setState(vals)
        break
    }
  }

  makeOpts=(opts)=>{
    return opts.map((o,i)=>{
      return(
        <option key={i} value={o.v}>{o.t}</option>
      )
    })
  }

  showTypeSelect=(parms)=>{
    let st=this.state
    // cl(this.state)
    let typeOpts=st.deviceTypes
      .filter(t=>{return t.link!=blankVal})
      .map(t=>{return{
        v:t.id,
        t:t.name,
      }})

    // let typeOpts=Object.values(st.types).map(v=>{return {v:v.typeId,t:v.name}})
    // let to=this.state.typeOpts
    // cl(to,typeOpts)
    return(
      <div>
      <span className="custom-select">
        <label htmlFor="modbus-device">Device Type</label>
        <select id="modbus-device"
          value={this.state.typeSel}
          onChange={e=>this.onChange("typeSel",{typeSel:+e.currentTarget.value})}
        >
        {this.makeOpts(typeOpts)}
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
        <span className="save-message hidden">Please save before changing</span>
      </span>
      {parms.edit&&
        <>
          <button type="button" className="material-icons trash after-selector" aria-label="delete type"
            disabled={!this.props.parms.saveOK}
            onClick={e=>this.onChange("deleteType")}
          >
            delete_outline
          </button>

          <button type="button" className="material-icons-outlined add after-selector" aria-label="add type"
            disabled={!this.props.parms.saveOK}
            onClick={e=>this.onChange("addType")}
          >
            add
          </button>
          <div className="clearfloat"></div>
          <br /><hr /><br />
        </>
      }
      </div>
    )
  }

  showTypeEdit=()=>{
    let st=this.state
    let registerOpts=[]
    // cl(st.typeSel)
    if(st.typeSel>=0){
      let type=st.deviceTypes[st.typeSel]
      // cl(type)
      let registers=st.deviceTypeRegisters
      let reg=registers[type.link]
      // cl(reg)
      // return
      // let registerOpts=[]
      let i=0;
      while(i++<numRegs){
        registerOpts.push({
          v:reg.id,
          t:reg.name||"New Register",
        })
        if(reg.link==blankVal){break}
        if(reg.id==reg.link){break}
        reg=registers[reg.link]
      }
      // cl(registerOpts)
    }
//     cl(this.state)
//     cl(this.state.typeOpts)
//     cl(this.props.parms)
    // let ro=this.state.registers[this.state.typeSel]||{}
    // let registerOpts=Object.values(st.registers).map(v=>{return {v:v.typeId,t:v.name}})

    return(
      <div>
              <label htmlFor="modbus-name">Device Type Name</label>
              <input id="modbus-name" type="text"

              value={st.deviceTypes[st.typeSel]?.name||""}
              disabled={!this.props.parms.saveOK}
              onChange={e=>this.onChange("typeUpd",{name:e.currentTarget.value})}
              />

              <br />

              <span className="custom-select">
                <label htmlFor="modbus-registers">Registers</label>
                <select id="modbus-registers"
                  value={this.state.registerSel}
                  onChange={e=>this.onChange("regSel",{registerSel:+e.currentTarget.value})}
                >
                {this.makeOpts(registerOpts)}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
                <span className="save-message hidden">Please save before changing</span>
              </span>

              <button type="button" className="material-icons trash after-selector" aria-label="delete register"
              disabled={!this.props.parms.saveOK}
              onClick={e=>this.onChange("deleteRegister")}
              >
                delete_outline
              </button>

              <button type="button" className="material-icons-outlined add after-selector" aria-label="add register"
              disabled={!this.props.parms.saveOK}
              onClick={e=>this.onChange("addRegister")}
              >
                add
              </button>

              <div className="clearfloat"></div>
              <br /><hr /><br />
      </div>
    )
  }

  showRegSelect=(ro,label,field,opts)=>{
    return(
      <span className="custom-select">
        <label >{label}</label>
        <select id="modbus-register-mbType"
          value={ro[field]||""}
          disabled={!this.props.parms.saveOK}
          onChange={e=>{
            let vals={}
            let val=e.currentTarget.value
            // val=(isNaN(val))?val:+val
            vals[field]=e.currentTarget.value
            this.onChange("regUpd",vals)}}
        >
        {this.makeOpts(opts)}
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }

  showMbType=(ro)=>{
    let typeOpts=[
      {v:0,t:"Coil"},
      {v:1,t:"Holding Register"},
      {v:2,t:"Input Register"},
      {v:3,t:"Internal Regester"},
    ]
    return this.showRegSelect(ro,"MB Type","mbType",typeOpts)
  }

  showRwType=(ro)=>{
    let typeOpts=[
      {v:0,t:"Read"},
      {v:1,t:"Write"},
      {v:2,t:"Read / Write"},
    ]
    return this.showRegSelect(ro,"Read / Write Type","rwType",typeOpts)
  }

  showValueType=(ro)=>{
    let typeOpts=[
      {v:0,t:"Digital"},
      {v:1,t:"Analog"},
    ]
    return this.showRegSelect(ro,"Value Type","valType",typeOpts)
  }

  // showUnit=(ro)=>{
  //   let typeOpts=[
  //     {v:0,t:"Degree C"},
  //     {v:1,t:"10 x Degree C"},
  //     {v:2,t:"Degree F"},
  //     {v:3,t:"10 x Degree F"},
  //     {v:4,t:"RH %"},
  //     {v:5,t:"KPH"},
  //     {v:6,t:"MPH"},
  //     {v:7,t:"kPa"},
  //     {v:8,t:"Liters / sec"},
  //     {v:9,t:"Gallons / sec"},
  //     {v:10,t:"Volts"},
  //   ]
  //   return this.showRegSelect(ro,"Unit","unit",typeOpts)
  // }

  showPriority=(ro)=>{
    let typeOpts=[...Array(8).keys()].map(k=>{return{v:k,t:k}})
    return this.showRegSelect(ro,"Priority","priority",typeOpts)
  }

  showRegisterName=(ro)=>{
    return(
      <div>
        <label htmlFor="modbus-register-name">Register Name</label>
        <input id="modbus-register-name" type="text"
          value={ro.name}
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("regUpd",{name:e.currentTarget.value})}
        />
      </div>
    )
  }

  showRegisterAddress=(ro)=>{
    return(
      <div>
        <label htmlFor="modbus-register-addr">Register Address</label>
        <input id="modbus-register-addr" type="text"
          value={ro.regAddr}
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("regUpdNum",{regAddr:+e.currentTarget.value})}
        />
      </div>
    )
  }

  showRefreshInterval=(ro)=>{
    return(
      <div>
        <label htmlFor="refreshInterval">Refresh Interval</label>
        <input id="refreshInterval" type="text"
          value={ro.interval||0}
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("regUpdNum",{interval:+e.currentTarget.value})}
        /> (in seconds)
      </div>
    )
  }

  showChannelStart=(ro)=>{
    return(
      <div>
        <label htmlFor="ChannelStart">Channel Start</label>
        <input id="ChannelStart" type="text"
          value={ro.channelStart||0}
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("regUpdNum",{channelStart:+e.currentTarget.value})}
        />
      </div>
    )
  }

  showChannelCount=(ro)=>{
    return(
      <div>
        <label htmlFor="channelCount">Channel Count</label>
        <input id="channelCount" type="text"
          value={ro.channelCount||0}
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("regUpdNum",{channelCount:+e.currentTarget.value})}
        />
      </div>
    )
  }

  showDiagnostic=(ro)=>{
    return(
      <div>
      <table style={{width:"initial"}}><tbody>
      <tr><td>
        <input type="checkbox"
          style={{minWidth:"initial",marginTop:5}}
          checked={ro.diagnostic}
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("regUpd",{diagnostic:e.currentTarget.checked})}
        />
      </td><td>
        <label
        style={{marginBottom:0}}
        htmlFor="diagnostic">Enable Diagnostic</label>
      </td></tr>
      </tbody></table>
      </div>
    )

  }

  showRegisterEdit=()=>{
    let st=this.state
    cl(st)
    // if(!st.deviceTypeRegisters[st.registerSel]){return}
    let ro=st.deviceTypeRegisters[st.registerSel]
    if(!ro){return}
    // cl(ro)
    // cl(st)
    return(
      <div>
        {this.showRegisterName(ro)}<br/>
        {this.showRegisterAddress(ro)}<br/>
        {this.showMbType(ro)}<br/>
        {this.showRwType(ro)}<br/>
        {this.showValueType(ro)}<br/>
        {this.showChannelCount(ro)}<br/>
        {this.showChannelStart(ro)}<br/>
        {this.showRefreshInterval(ro)}<br/>
        {this.showDiagnostic(ro)}<br/>
      </div>
    )
        // {this.showUnit(ro)}<br/>
        // {this.showPriority(ro)}<br/>
  }

  showRegisterEditO=()=>{
    let st=this.state
    cl(st)
    let typeOpts=[
      "RW Coil",
      "RO Coil",
      "RO Discrete Input",
      "RW Holding Register",
      "RO Holding Register",
      "RO Input Register",
    ]
    let funcOpts=[
      "Just View",
      "Archive",
      "Alarm",
      "Alarm Type",
      "Heat SP 1",
      "Heat SP 2",
      "Hum SP 1",
      "Hum SP 2",
    ]
    let convOpts=[
      "None",
      "Multiply",
      "Indexed",
      "On / Off",
      "\u00B0C to\u00B0F",
      "\u00B0F to\u00B0C",
    ]

//     let convOpts={
//       none:"None",
//       mult:"Multiply",
//       ind:"Indexed",
//       onoff:"On / Off",
//       c2f:"\u00B0C to\u00B0F",
//       f2c:"\u00B0F to\u00B0C",
//     }

    let unitOpts={
      feet:"feet",
      "\u00B0C":"\u00B0C",
      "\u00B0F":"\u00B0F",
      minutes:"minutes",
      inches:"inches",
      cfm:"cfm",
      mm:"mm"      ,
    }
    if(!this.state.registers[this.state.registerSel]){return}
    let ro=this.state.registers[this.state.registerSel]
//     cl(this.state)
//     cl(ro)
//     let func=(ro.type>>8)&0xFF
    return(
      <div>
      {this.showRegisterName(ro)}
      {this.showMbType(ro)}
      </div>
    )

    return(
      <div>
              <label htmlFor="modbus-register-name">Register Name</label>
              <input id="modbus-register-name" type="text"
                value={ro.name}
                disabled={!this.props.parms.saveOK}
                onChange={e=>this.onChange("regUpd",{name:e.currentTarget.value})}
              />

              <br />

              <span className="custom-select">
                <label htmlFor="modbus-register-type">Type</label>
                <select id="modbus-register-type"
                  value={ro.type&0x0FF}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("regUpd",{type:e.currentTarget.value})}
                >
                  {Object.keys(typeOpts).map((typeId,i)=>{
                    return(
                      <option key={i} value={typeId}>{typeOpts[typeId]}</option>
                    )
                  })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

              <span className="custom-select">
                <label htmlFor="modbus-register-func">Function</label>
                <select id="modbus-register-func"
                  value={(ro.type>>8)&0xFF}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("regUpd",{func:e.currentTarget.value})}
                >
                  {Object.keys(funcOpts).map((funcId,i)=>{
                    return(
                      <option key={i} value={funcId}>{funcOpts[funcId]}</option>
                    )
                  })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

              <br />

              <label htmlFor="modbus-addr">Address (0-65535)</label>
              <input id="modbus-addr" type="number" step="1" min="0" max="65535"
                  value={ro.addr}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("regUpd",{addr:e.currentTarget.value})}
              />

              <div className="clearfloat"></div>
              <br />

              <span className="custom-select">
                <label htmlFor="modbus-conversion-type">Conversion Type</label>
                <select id="modbus-conversion-type"
                  value={ro.conv}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("regUpd",{conv:e.currentTarget.value})}
                >
                  {Object.keys(convOpts).map((convId,i)=>{
                    return(
                      <option key={i} value={convId}>{convOpts[convId]}</option>
                    )
                  })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

              <div className="clearfloat"></div>


              <span className="custom-select">
                <label htmlFor="modbus-register-unit">Unit</label>
                <select id="modbus-register-unit"
                  value={ro.unit}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("regUpd",{unit:e.currentTarget.value})}
                >
                  {Object.keys(unitOpts).map((unitId,i)=>{
                    return(
                      <option key={i} value={unitId}>{unitOpts[unitId]}</option>
                    )
                  })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

              <div className="clearfloat"></div>

      </div>

    )
  }

  showTypes=()=>{
    return(
      <div id="modbus_types">
          <div className="clearfloat"></div>
      {this.showTypeSelect({edit:true})}
      {this.showTypeEdit()}
      {this.showRegisterEdit()}

      </div>
    )
      // {this.showIndexEdit()}
  }
  render(){
    if(this.state.loaded){
      return this.showTypes()
    }else{return<div>loading. . .</div>}
  }
}

export default C18ModbusGP00;
