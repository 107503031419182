import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Input from "@material-ui/core/Input";
import {cl} from '../../components/utils/utils';

class TextInput extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      value: (props.initValue) ? props.initValue : "",
    }
  }
  
  onIFChange=(e)=>{
    let val=e.currentTarget.value;
    this.setState({value: val});
    this.props.onChange(this.props.valueId, val);
  }
  
  
  render(){
    let style=(this.props.width) ? {width: this.props.width} : null
    return(
      <div style={{padding: 10, display: "inline"}}>
      <InputLabel>{this.props.title}</InputLabel>
      <Input
      onChange={this.onIFChange}
      value={this.state.value}
      style={style}
      />
      </div>
    );
  }
}

export default TextInput;
