import React from 'react';
import {cl, constant} from '../../components/utils/utils';

class Current extends React.Component{

  constructor(props) {
    super(props);
    cl("construct");
    props.templateProps.mymain.fontSize = 64;
    props.templateProps.mymain.title = "new";
    props.templateProps.mymain.render();
    // cl(props.templateProps.mymain);
    // cl(props.templateProps.mymain);
  }

  render(){
    cl("render");
    return(
      <div style={{
        fontSize: 32 }}>Night</div>
    );
  }
}

export default Current ;
