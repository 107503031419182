import React from 'react';
import C18Anchor00 from './C18Anchor00'
import C18Input00 from './C18Input00'
import C18Button00 from './C18Button00'
import {wsTrans} from '../utils/utils'
import {loadUsersInfo} from './C18utils'
import {cl,globs,constant,az,getRandomString,dateToDisplayDate} from '../../components/utils/utils';
import history from "../../history"

class C18DayPhases00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    let pa=props.parms
    this.state={
      loaded:true,
      phaseName:"",
      description:"",
      spSel:"",
      cropRecipeId:pa.adminInfo,
      cropPhaseId:pa.adminInfo2,
      dayPhases:[
      {name:"Day",
        start:360,
        tempLo:60,
        humLo:40,
        lightLo:1000,
        co2Lo:1000,
        soilWcLo:50,
        soilEcLo:2.0,//dS/m
        irrPlan:"steerCycle00"
//         tempHi:80,
//         humHi:60,
//         lightHi:1200,
//         co2Hi:1000,
//         soilWcHi:80,
//         soilEcHi:2.5,
      },
      ],
    }
    this.setBreadCrumbs()
    this.loadInfo()
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveCropPhase)
//     this.doLoadMessages=setInterval(this.doLoadMessages,10000)
  }
  
  
  setBreadCrumbs=()=>{
//     cl(this.props.parms)
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Messages", url:`/usa/c18/messaging/messageList`},
            ]},
        },
      )
    }
  }
  
  loadInfo=async()=>{
//     cl(this.props.parms)
    let st=this.state
    let pa=this.props.parms
    let cropName="None"
    var res
    if(pa.adminInfo){
      res=await wsTrans("usa", {cmd: "cRest", uri: "/s/cropRecipes", method: "retrieve",
        sessionId: globs.userData.session.sessionId, body: {cropRecipeId:pa.adminInfo}})
//       cl(res)
      cropName=res.data[0]?.name||"None"
    }
    if(pa.adminInfo2!="new"){
//       cl(pa.adminInfo2)
      
      res=await wsTrans("usa", {cmd: "cRest", uri: "/s/cropPhases", method: "retrieve",
        sessionId: globs.userData.session.sessionId, body: {cropPhaseId:pa.adminInfo2}})
      let phase=res.data[0]
      let dayPhases=st.dayPhases
      res=await wsTrans("usa", {cmd: "cRest", uri: "/s/dayPhases", method: "retrieve",
        sessionId: globs.userData.session.sessionId, body: {cropPhaseId:pa.adminInfo2}})
      if(res.data.length){dayPhases=res.data}
      this.setState({cropName:cropName,phaseName:phase.name,dayPhases:dayPhases})
    }else{
      this.setState({cropName:cropName})
    }
//     await loadUsersInfo()
//     this.userArr={}
//     globs.usersInfo.info.forEach(ui=>{this.userArr[ui.userId]=ui})
//     let messages=await this.loadMessages()
//     this.setState({loaded:true,messages:messages})
  }

  saveCropPhase=async(cmd)=>{
    if(cmd=="save"){
      let st=this.state
      let pa=this.props.parms
      let cropPhaseId=st.cropPhaseId||getRandomString(16)
      globs.events.publish("saveOK",true)
//       cl("saving")
      let phase={
        cropRecipeId:st.cropRecipeId,
        cropPhaseId:cropPhaseId,
        name:st.phaseName,
        description:st.description,
        startDay:0,
      }
//       cl(phase)
      await wsTrans("usa", {cmd: "cRest", uri: "/s/cropPhases", method: "update",
        sessionId: globs.userData.session.sessionId, body: phase})
      history.push(`/usa/c18/cropRecipes/${st.cropRecipeId}/days/${cropPhaseId}`)
      let dayPhases=st.dayPhases.slice(0)
      dayPhases.forEach(dp=>{
        dp.cropRecipeId=st.cropRecipeId
        dp.cropPhaseId=cropPhaseId
      })
//       cl(dayPhases)
      await wsTrans("usa", {cmd: "cRest", uri: "/s/dayPhases", method: "update",
        sessionId: globs.userData.session.sessionId, body: {cropPhaseId:cropPhaseId,
          dayPhases:dayPhases}})
      if(!st.cropPhaseId){this.setState({cropPhaseId:cropPhaseId})}
    }
  }
  
  addPhase=(vals)=>{
    let dayPhases=this.state.dayPhases.slice(0)
    let newPhase=Object.assign({},dayPhases[vals.ph])
    dayPhases.splice(vals.ph,0,newPhase)
    this.setState({dayPhases:dayPhases})
  }
  
  deletePhase=(vals)=>{
    let dayPhases=this.state.dayPhases.slice(0)
    if(dayPhases.length<2){return}
    dayPhases.splice(vals.ph,1)
//     let newPhase=Object.assign({},dayPhases[vals.ph])
//     dayPhases.splice(vals.ph,0,newPhase)
    this.setState({dayPhases:dayPhases})
  }
  
  sortPhases=(dayPhases)=>{
    dayPhases.sort((a,b)=>{
      if(a.start>b.start){return 1}
      if(a.start<b.start){return -1}
      return 0
    })
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    switch(type){
      case "add":
        globs.events.publish("savePageEnable",true)
        this.addPhase(vals)
        break
      case "delete":
        globs.events.publish("savePageEnable",true)
        this.deletePhase(vals)
        break
      case "spSel":
        cl(vals)
        let spSel=`${vals.sp}_${vals.ph}`
        cl(spSel)
        this.setState({spSel:spSel})
        break
      case "spUpd":
        globs.events.publish("savePageEnable",true)
        let dayPhases=st.dayPhases.slice(0)
        let vals2={}
        vals2[vals.id]=vals.val
        let val=vals.val
        if(vals.id=="start"){
          let parts=vals.val.split(":")
          val=+parts[1]+60*parts[0]
        }
        dayPhases[vals.ind][vals.id]=val
//         if(vals.id=="start"){this.sortPhases(dayPhases)}
        this.setState({dayPhases:dayPhases})
        break
      case "upd":
        globs.events.publish("savePageEnable",true)
        this.setState(vals)
        break
    }
  }
  
  showPhaseHead=()=>{
    return(
      <tr>
      <th width="50"></th>
      <th width="50"></th>
      <th>Start</th>
      <th>Day Phase Name</th>
      <th>Temp</th>
      <th>Hum</th>
      <th>Light</th>
      <th>Co2</th>
      <th>Soil WC</th>
      <th>Soil EC</th>
      </tr>
    )
  }
  
  showPhasesO=()=>{
    let sty={cursor:"pointer"}
    var showVal=(ph,sp,i,sty,val)=>{
      cl(ph,sp)
      return <td key={i} style={sty}>{val}</td>
    }
    var showRow=(ph,row,nameStart,loHi)=>{
      return row.map((sp,i)=>{
        let text=(i==0)?nameStart:loHi
        let val=(sp=="")?text:ph[sp]
        return showVal(ph[sp],sp,i,sty,val)
        })
    }
    let st=this.state
    let setpoints=[
      ["","name","","tempLo","humLo","lightLo","co2Lo","soilWcLo","soilEcLo"],
      ["","start","","tempHi","humHi","lightHi","co2Hi","soilWcHi","soilEcHi"],
    ]
    let lines=st.dayPhases.map(ph=>{
      let row1=showRow(ph,setpoints[0],"Name:","Lo:")
      let row2=showRow(ph,setpoints[1],"Start:","Hi:")
      return(
        <>
          <tr>{row1}</tr>
          <tr>{row2}</tr>
        </>
      )
    })
    return(
      <div>phases
      <table><tbody>
      {this.showPhaseHead()}
      {lines}
      </tbody></table>
      </div>
      
    )
  }
  
  onBlur=()=>{
    cl("on blur")
    cl(this.state.spSel)
    let st=this.state
    let parts=st.spSel.split("_")
    cl(parts)
    
    if(parts[0]=="start"){
      let dayPhases=st.dayPhases.slice(0)
      this.sortPhases(dayPhases)
      this.setState({dayPhases:dayPhases})
    }else{
      this.setState({spSel:""})
    }
  }
  
  showPhases=()=>{
    var showVal=(ph,sp,i,id)=>{
      let wid=(sp=="name")?150:50
      if(sp=="start"){wid=120}
      let typ=(sp=="start")?"time":"number"
      if(sp=="name"){typ="text"}
      let inSty={padding:0,borderRadius:0,width:wid}
      let valId=`${sp}_${id}`
      if(sp=="start"){
        let hr=Math.floor(ph/60)
        let mn=ph%60
        ph=`${az(hr,2)}:${az(mn,2)}`
//         cl(ph)
      }
      if(st.spSel==valId){
        return <td key={i}>
        <input type={typ} style={inSty}
          value={ph}
          onChange={e=>this.onChange("spUpd",{ind:id,id:sp,val:e.currentTarget.value})}
          onBlur={this.onBlur}
        />
        </td>
      }else{
        return <td key={i} style={sty}
        onClick={e=>this.onChange("spSel",{ph:id,sp:sp})}
        >{ph}</td>
      }
    }
    var showRow=(ph,row,id)=>{
      return row.map((sp,i)=>{
        return showVal(ph[sp],sp,i,id)
        })
    }
    
    let st=this.state
    let setpoints=["start","name","tempLo","humLo","lightLo","co2Lo","soilWcLo","soilEcLo"]
    let sty={cursor:"pointer"}
    let showDelete=(st.dayPhases.length>1)
    let lines=st.dayPhases.map((ph,i)=>{
      let rowHead=[
        <td key={"1a"}>
          {showDelete&&
            <button onClick={(e)=>this.onChange("delete", {ph:i})} type="button" className="material-icons trash" aria-label="delete report">
              delete_outline
            </button>
          }
        </td>,
        <td key={"2a"}>
          <button onClick={(e)=>this.onChange("add", {ph:i})} type="button" className="material-icons-outlined add" aria-label="delete report">
            add
          </button>
        </td>
      ]
      let row1=showRow(ph,setpoints,i)
      return(
          <tr key={i}>{rowHead.concat(row1)}</tr>
      )
    })
    return(
      <div>
      <table><tbody>
      {this.showPhaseHead()}
      {lines}
      </tbody></table>
      </div>
    )
  }
  
  render(){
    if(this.state.loaded){
      let st=this.state
//       cl(st)
      return(
        <div>
          <div className="clearfloat"/>
          <h4>{`Crop: ${st.cropName}`}</h4>
          <label htmlFor="phase-name">Crop Phase Name</label>
          <C18Input00 type="text" id="phase-name" 
            value={st.phaseName||""}
            onChange={e=>this.onChange("upd",{phaseName:e.currentTarget.value})}
          />
          <div className="clearfloat"/><br/>
          <label htmlFor="phase-description">Description</label>
          <textarea id="phase-description"
          value={st.description}
          onChange={e=>this.onChange("upd",{description:e.currentTarget.value})}
          />
          <div className="clearfloat"/><br/>
          <h3>Day Phases:</h3>
          {this.showPhases()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18DayPhases00
