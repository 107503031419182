import React from 'react';
import {cl} from '../../components/utils/utils';

class C18Tasks00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
//     props.parms.newProps(this.newProps)// register newProps function
  }
    
    render(){
    return(
      <div>Tasks</div>
    )
  }
}

export default C18Tasks00;
